import React, { memo } from "react"
import { Link } from "react-router-dom"

import FormRoom from "../../../../elements/FormRoom"

import { ContainerForm, ContainerRoomView, RoomName } from "./RoomView.styles"
import RoomViewSkeleton from "./RoomViewSkeleton"

type Props = {
  brandId: string,
  room: {
    name: string,
  },
  canEdit: boolean,
  loadingSubmit: boolean,
  skeleton: boolean,
  restrictToStaffIdIfAgent: string,
}

const RoomView = ({ skeleton, brandId, room, canEdit, onSubmit, loadingSubmit, restrictToStaffIdIfAgent }: Props) =>
  skeleton ? (
    <RoomViewSkeleton />
  ) : (
    <ContainerRoomView>
      <Link to="/settings/company/rooms/">{`< Back to all rooms`}</Link>
      <RoomName>{room.name}</RoomName>
      <ContainerForm>
        <FormRoom
          brandId={brandId}
          room={room}
          canEdit={canEdit}
          canEditName
          onSubmit={onSubmit}
          loadingSubmit={loadingSubmit}
          restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
        />
      </ContainerForm>
    </ContainerRoomView>
  )

export default memo(RoomView)

import usePlacesAutocomplete from "use-places-autocomplete"
import React, { useMemo, memo, useCallback, useEffect } from "react"
import Select from "react-select"

import generateAreasAndTerritoriesOptions from "../../../helpers/areasAndTerritories/generateAreasAndTerritoriesOptions"
import { loadGoogleMapsApi } from "../../../helpers/googleMapsApi"
import { MultiValueContainer } from "../../ui/SelectOptionWithTitle"

type Props = {
  withAreas: boolean | null,
  withRooms: boolean | null,
  withGoogleSuggestion: boolean | null,
  multiple: boolean,
  placeholder: string,
  disabled: boolean,
  onChange: Function,
  defaultValue: any,
  value: any,
  rooms: [],
  retailers: [],
}

const selectComponents = { MultiValueContainer }

const RoomAreaAndTerritoryPicker = ({
  withAreas = true,
  withRooms = true,
  withGoogleSuggestion = true,
  multiple,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
  areas,
  rooms,
  retailers,
}: Props) => {
  const { suggestions, setValue: setPlacesAutoCompleteValue } = usePlacesAutocomplete({
    debounce: 500,
    callbackName: "initMap",
    requestOptions: {
      types: ["(regions)"],
    },
  })

  const handleChangeSelectInput = useCallback(
    inputValue => {
      if (withGoogleSuggestion) {
        setPlacesAutoCompleteValue(inputValue)
      }
    },
    [setPlacesAutoCompleteValue],
  )

  useEffect(() => {
    if (withGoogleSuggestion) {
      loadGoogleMapsApi()
    }
  }, [withGoogleSuggestion])

  const options = useMemo(() => {
    const generatorParams = {
      withCountries: true,
      withContinents: true,
      withAreas,
      withRooms,
      googleSuggestions: withGoogleSuggestion ? suggestions?.data : [],
      areas,
      rooms,
      retailers,
    }
    return generateAreasAndTerritoriesOptions(generatorParams)
  }, [areas, rooms, retailers, suggestions])
  return (
    <Select
      components={selectComponents}
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      options={options}
      isLoading={suggestions.loading}
      isMulti={multiple}
      placeholder={placeholder}
      onChange={onChange}
      onInputChange={handleChangeSelectInput}
    />
  )
}

RoomAreaAndTerritoryPicker.defaultProps = {
  placeholder: "Select area or territory",
  multiple: false,
}
export default memo(RoomAreaAndTerritoryPicker)

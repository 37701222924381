import React, { Component } from "react"
import { Link } from "react-router-dom"
import Modal from "react-modal"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import moment from "moment-timezone"
import { Button } from "modaresa-commons"

import { meetingApproachItems, meetingTypes, videoToolNames } from "../../../../../config"
import { ReactComponent as CloseIcon } from "../../../../../static/close.svg"
import TimeIcon from "../../../../../static/clock.svg"
import { getRangeHourList } from "../../../../../helpers/date"
import { getStaffLabel } from "../../../../../helpers/users"
import { Flex, MeetingDate, MeetingInfo } from "../../styles"
import { Select, Icon } from "../../../../ui"
import { ClientsList } from "../../../../elements/BookingModalOld/ClientsList"
import {
  AvailableStaff,
  BookingModal,
  BookingModalDialogBody,
  Header,
  MeetingBudget,
} from "../../../../elements/BookingModalOld"
import ModalCancelMeeting from "../../../../elements/ModalCancelMeeting/ModalCancelMeeting"

import {
  Bold,
  ButtonsRow,
  CloseModal,
  Container,
  LoaderContainer,
  ModalLoaderContainer,
  Text,
  VideoURLHolder,
} from "./styles"

import VideoIcon from "static/view.svg"
import { getRetailerByIdOrEmpty, onMissingRetailerIdForClient } from "services/retailers"
import { onToastError, onToastSuccess } from "helpers/toast"

const confirmModalStyles = {
  content: {
    width: "450px",
    height: "auto",
    maxHeight: "500px",
    minHeight: "220px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "40px 30px",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 13,
  },
}

class EditBookingModalContent extends Component {
  constructor(props) {
    super(props)
    const { booking = {}, allStaff, isLargerBooking } = this.props
    const {
      meetingType,
      meetingApproach,
      videoTool,
      room,
      clients,
      host: hostId,
      meetingResp,
      status,
      startTime,
      endTime,
    } = booking

    let newStartTime
    if (booking.appointmentStatus === "delayed") {
      const start = moment.utc(startTime, "X").format("H:mm")
      const newTime = moment(start, "HH:mm")
        .add(booking.delayTime, "minutes")
        .format("HH:mm")

      const end = moment.utc(booking.endTime, "X").format("H:mm")
      if (moment.utc(newTime, "HH:mm").isBefore(moment.utc(end, "HH:mm"))) {
        newStartTime = newTime
      }
    }
    const client = this.props.clients.byId[booking.clients[0]]
    const retailerId = client.retailerId

    const selectedRetailer = getRetailerByIdOrEmpty(
      retailerId,
      this.props.retailers,
      onMissingRetailerIdForClient(client),
    )

    let host = null
    if (hostId) {
      host = status === "reserved" ? null : { value: hostId, label: getStaffLabel(allStaff.byId[hostId]) }
    }

    this.state = {
      openModalDeleteBooking: false,
      isMeetingStaffFetched: false,
      isLargerBooking,
      confirmModal: isLargerBooking,
      hasConfirmed: false,
      meetingType: (meetingType && meetingTypes.find(mt => mt.value === meetingType)) || null,
      meetingApproach:
        (meetingApproach && meetingApproachItems.find(ma => ma.value === meetingApproach)) || meetingApproachItems[0],
      videoTool,
      room: room || null,
      meetingResp:
        meetingResp?.map(id => ({
          value: id,
          label: getStaffLabel(allStaff.byId[id]),
        })) || [],
      host,
      status,
      budget: booking.budget || 0,
      overrideLength: false,
      clients,
      startTime:
        (newStartTime
          ? {
              value: moment.utc(newStartTime, "H:mm").unix(),
              label: newStartTime,
            }
          : null) ||
        (startTime
          ? {
              value: startTime,
              label: moment.utc(startTime, "X").format("HH:mm"),
            }
          : null),
      endTime: endTime ? { value: endTime, label: moment.utc(endTime, "X").format("HH:mm") } : null,
      selectedRetailer,
      updatingTime: false,
      isRequest: false,
      newClients: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isRequestingTimeCheck, timeCheckError, isRequestPending, requestError } = nextProps
    const { updatingTime, pendingStartTime, pendingEndTime, isRequest } = prevState
    if (!isRequestingTimeCheck && updatingTime) {
      if (!timeCheckError) {
        return {
          updatingTime: false,
          pendingStartTime: null,
          pendingEndTime: null,
          startTime: pendingStartTime,
          endTime: pendingEndTime,
        }
      }
      return {
        updatingTime: false,
        pendingStartTime: null,
        pendingEndTime: null,
      }
    }
    if (isRequest && !isRequestPending && !requestError) {
      return { isRequest: false, isChanged: false }
    }
    return null
  }

  componentDidUpdate(prevProps, prevState) {
    const { isRequestPending, requestError, onUnselectBooking } = this.props
    const { isFetchingMeetingStaff } = this.state
    if (prevState.isFetchingMeetingStaff && !isFetchingMeetingStaff) {
      this.setState({ isMeetingStaffFetched: true })
    }
    if (prevState.isRequest && prevProps.isRequestPending && !isRequestPending && !requestError) {
      onUnselectBooking()
    }
  }

  handleChange = (type, value) => {
    if (type === "host") {
      return this.setState({
        [type]: value,
        videoTool: null,
        isChanged: true,
      })
    }
    this.setState({ [type]: value, isChanged: true })
  }

  onDeleteBooking = optionsCancelBooking => {
    const { onDeleteBooking, booking } = this.props
    onDeleteBooking(booking, optionsCancelBooking)
  }

  onSaveChanges = () => {
    const {
      budget,
      meetingResp,
      host,
      startTime,
      endTime,
      newClients,
      pendingStartTime,
      pendingEndTime,
      videoTool,
      meetingType,
      meetingApproach,
    } = this.state
    const { onCheckNewBookingTime, onUpdateBooking, scheduleId, booking, sessionId } = this.props
    if (pendingStartTime && pendingEndTime) {
      if (pendingStartTime?.value <= pendingEndTime?.value || pendingEndTime?.value >= pendingStartTime?.value) {
        onCheckNewBookingTime({
          startTime: pendingStartTime.value,
          endTime: pendingEndTime.value,
          bookingId: booking._id,
          scheduleId,
          sessionId,
          host: host ? host.value : null,
        })
      } else {
        return onToastError("Please make sure the start time of the appointment is before the end time.")
      }
    }
    this.setState({ isRequest: true })
    onUpdateBooking({
      bookingId: booking._id,
      bookingData: {
        budget,
        host: host ? host.value : null,
        meetingResp: meetingResp?.map(member => member.value),
        startTime: startTime.value,
        endTime: endTime.value,
        meetingType: meetingType.value,
        meetingApproach: meetingApproach.value,
        videoTool,
      },
      newClientsIds: newClients.map(newClient => newClient.id),
      scheduleId,
    })

    if (
      (meetingApproach?.value === "video-conference" && booking.meetingApproach === "video-conference") ||
      meetingApproach?.value !== booking.meetingApproach
    ) {
      onToastSuccess(
        booking.clients.length > 1
          ? "An updated meeting confirmation has now been sent to all clients included in the booking"
          : "An updated meeting confirmation has now been sent to the client's inbox",
      )
    }
  }

  onStaffMenuOpen = () => {
    const { clients, startTime, endTime, pendingStartTime, pendingEndTime } = this.state
    const { onFetchAvailableStaff, sessionId, booking, scheduleId } = this.props
    onFetchAvailableStaff({
      bookingEventId: booking._id,
      clients,
      sessionId,
      roomName: booking.room,
      meetingType: booking.meetingType,
      scheduleId,
      startTime: pendingStartTime?.value || startTime.value,
      endTime: pendingEndTime?.value || endTime.value,
      type: booking.type,
    })
  }

  onInputChange = e => {
    const value = e.target.value
    const name = e.target.name
    if (!isNaN(value)) {
      this.setState({
        [name]: +value,
        isChanged: true,
      })
    }
  }

  onChangeTime = (time, type) => {
    const { pendingStartTime, pendingEndTime } = this.state
    const { booking } = this.props
    const bookingLength = booking.endTime - booking.startTime
    if (type === "startTime") {
      this.setState({
        isChanged: true,
        pendingStartTime: time,
        pendingEndTime: !pendingStartTime
          ? {
              value: time.value + bookingLength,
              label: moment.utc(time.value + bookingLength, "X").format("HH:mm"),
            }
          : this.state.pendingEndTime,
      })
    }
    if (type === "endTime") {
      this.setState({
        isChanged: true,
        pendingEndTime: time,
        pendingStartTime: !pendingEndTime
          ? {
              value: time.value - bookingLength,
              label: moment.utc(time.value - bookingLength, "X").format("HH:mm"),
            }
          : this.state.pendingStartTime,
      })
    }
  }

  isButtonDisabled = () => {
    const { updatingTime, meetingResp, isRequest, isChanged, meetingApproach, videoTool } = this.state
    const { isRequestPending, requestError } = this.props
    if (updatingTime || meetingResp.length < 1) {
      return true
    } else if (isRequest) {
      return !(!isRequestPending && requestError)
    } else if (meetingApproach?.value === "video-conference" && !videoTool) {
      return true
    }
    return !isChanged
  }

  onToggleConfirmModal = () => {
    this.setState(prevState => ({
      confirmModal: !prevState.confirmModal,
      hasConfirmed: true,
    }))
  }

  onToggleAbortConfirmModal = () => {
    const { onRequestClose } = this.props

    this.setState(prevState => ({
      confirmModal: !prevState.confirmModal,
      hasConfirmed: false,
    }))

    onRequestClose()
  }

  renderConfirmModal() {
    const { confirmModal } = this.state
    const { isRequestPending } = this.props

    return (
      <Modal
        isOpen={confirmModal}
        style={confirmModalStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.onToggleAbortConfirmModal}
      >
        {isRequestPending && (
          <ModalLoaderContainer>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </ModalLoaderContainer>
        )}
        {confirmModal && (
          <React.Fragment>
            <div>
              <Bold style={{ fontSize: "16px" }}>{"Are you sure you want to edit this meeting?"}</Bold>
            </div>
            <Text>
              <p>
                {
                  "Oops! The meeting you are trying to edit belongs to a booking that contains multiple meetings. Editing this meeting might result in not having all bookings timed in parallel or consecutively."
                }
              </p>
            </Text>
            <ButtonsRow>
              <Button onClick={this.onToggleConfirmModal}>Confirm</Button>
            </ButtonsRow>
          </React.Fragment>
        )}
        <CloseModal style={{ width: "17px", top: "10px", right: "10px" }} onClick={this.onToggleAbortConfirmModal}>
          <CloseIcon fill="#000" height="12px" style={{ display: "block" }} width="100%" />
        </CloseModal>
      </Modal>
    )
  }

  handleOpenModalDeleteBooking = () => {
    this.setState({ openModalDeleteBooking: true })
  }

  handleCloseModalDeleteBooking = () => {
    this.setState({ openModalDeleteBooking: false })
  }

  handleChangeNewClients = newClients => {
    this.setState({ newClients, isChanged: true })
  }

  render() {
    const {
      onRequestClose,
      isOpen,
      booking,
      clients,
      isFetchingMeetingStaff,
      availableStaff,
      availableHosts,
      otherHosts,
      openingHours,
      isRequestPending,
      selectedSession,
      user,
    } = this.props
    const {
      meetingType,
      meetingApproach,
      videoTool,
      room,
      host,
      meetingResp,
      clients: bookingClients,
      selectedRetailer,
      budget,
      endTime,
      startTime,
      updatingTime,
      isLargerBooking,
      hasConfirmed,
      isMeetingStaffFetched,
      openModalDeleteBooking,
    } = this.state

    const dayDate = moment.utc(booking.dayDate, "X").format("dddd, MMMM Do YYYY")

    const timeOptions = getRangeHourList(openingHours.from, openingHours.to)
    const isDisableEdit = booking.appointmentStatus === "arrived"
    const reportUrl = booking.report
      ? `/clients/${booking.clients[0]}/meetings-reports/${booking.report}`
      : `/clients/${booking.clients[0]}/meetings-reports?booking=${booking._id}`
    const retailer = {
      name: selectedRetailer.name,
      origin: {
        city: selectedRetailer.storeAddress.city,
        country: selectedRetailer.storeAddress.country,
      },
      clientId: selectedRetailer.clientId,
    }

    const bookingModalVisible = !isLargerBooking || (isLargerBooking && hasConfirmed)

    // const ignoreVideoToos = ["facetime", "whatsapp"]
    const isVideoMeeting = booking.meetingApproach === "video-conference"
    // && ignoreVideoToos.indexOf(booking.videoTool) === -1

    const sessionHost = booking.host && selectedSession.staff.find(staff => staff.staffId === booking.host)

    let videoMeetingUrl
    if (isVideoMeeting && sessionHost && sessionHost.videoIDs) {
      if (booking.videoTool === "skype") {
        videoMeetingUrl = `skype:${sessionHost.videoIDs[booking.videoTool]}?call`
      } else if (booking.videoTool === "whatsapp") {
        videoMeetingUrl = `https://wa.me/${sessionHost.videoIDs[booking.videoTool]}`
      } else if (booking.videoTool === "facetime") {
        videoMeetingUrl = `facetime:${sessionHost.videoIDs[booking.videoTool]}`
      } else {
        videoMeetingUrl = sessionHost.videoIDs[booking.videoTool]
      }
    }

    return (
      <Container style={{ paddingTop: "25px" }}>
        {this.renderConfirmModal()}
        {bookingModalVisible && (
          <BookingModal isOpen={isOpen} onRequestClose={onRequestClose}>
            {isRequestPending && (
              <LoaderContainer>
                <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
              </LoaderContainer>
            )}
            <Header
              light
              meetingType={meetingType.value}
              meetingApproach={meetingApproach.value}
              retailer={retailer}
              hostId={booking.host}
              user={user}
              room={room}
            />
            <BookingModalDialogBody>
              <ClientsList
                clientsArray={bookingClients.map(id => clients.byId[id])}
                onChangeNewClients={this.handleChangeNewClients}
                canEdit
                brandId={booking.brandId}
              />
              <MeetingInfo>
                <div style={{ width: "60%" }}>
                  <MeetingDate margin="0px">
                    <div>
                      <img alt="icon" src={TimeIcon} />
                    </div>
                    <div style={{ width: "100%" }}>
                      <div style={{ marginBottom: "5px" }}>{dayDate}</div>
                      <Flex>
                        <div style={{ width: "45%" }}>
                          <Select
                            isDisabled={updatingTime || isDisableEdit}
                            isLoading={updatingTime}
                            maxMenuHeight={190}
                            options={timeOptions}
                            value={this.state.pendingStartTime || startTime}
                            onChange={value => this.onChangeTime(value, "startTime")}
                            menuPosition={"fixed"}
                          />
                        </div>
                        {" to "}
                        <div style={{ width: "45%" }}>
                          <Select
                            isDisabled={updatingTime || isDisableEdit}
                            isLoading={updatingTime}
                            maxMenuHeight={190}
                            options={timeOptions}
                            value={this.state.pendingEndTime || endTime}
                            onChange={value => this.onChangeTime(value, "endTime")}
                            menuPosition={"fixed"}
                          />
                        </div>
                      </Flex>
                    </div>
                  </MeetingDate>
                </div>
                <div style={{ width: "40%", paddingLeft: "15px" }}>
                  <Select
                    className="meetingTypes"
                    options={meetingTypes}
                    placeholder=""
                    value={meetingType}
                    onChange={option => {
                      this.handleChange("meetingType", option)
                    }}
                    menuPosition={"fixed"}
                  />
                  <Select
                    className="meetingTypes"
                    options={meetingApproachItems}
                    placeholder="Select approach"
                    value={meetingApproach}
                    onChange={option => {
                      this.handleChange("meetingApproach", option)
                    }}
                    menuPosition={"fixed"}
                  />
                </div>
              </MeetingInfo>
              {videoMeetingUrl && meetingApproach?.value === "video-conference" && (
                <VideoURLHolder>
                  <Icon icon={VideoIcon} margin="0 8px 0 0" width="20px" />
                  <div>
                    <a
                      onClick={e => e.stopPropagation()}
                      href={videoMeetingUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join this meeting on <strong>{videoToolNames[booking.videoTool]}</strong>
                    </a>
                  </div>
                </VideoURLHolder>
              )}
              <MeetingBudget disabled={isDisableEdit} value={budget} onChange={this.onInputChange} />
              <AvailableStaff
                isStaffFetched={isMeetingStaffFetched}
                otherHosts={otherHosts}
                availableHosts={availableHosts}
                availableStaff={availableStaff}
                meetingApproach={meetingApproach.value}
                videoTool={videoTool}
                canChangeVideoTool={true}
                host={host}
                booking={booking}
                isDisableEdit={isDisableEdit}
                isFetchingMeetingStaff={isFetchingMeetingStaff}
                meetingResp={meetingResp}
                onChange={this.handleChange}
                onMenuOpen={this.onStaffMenuOpen}
              />
              <Flex>
                <Button
                  borderColor="#4a4a4a"
                  bgcolor="#4a4a4a"
                  disabled={isDisableEdit}
                  type="button"
                  onClick={this.handleOpenModalDeleteBooking}
                >
                  Delete Booking
                </Button>
                <Button bgcolor="#37475d" borderColor="#37475d" type="button">
                  <Link to={reportUrl}>Meeting Report</Link>
                </Button>
                <Button disabled={this.isButtonDisabled() || isDisableEdit} type="button" onClick={this.onSaveChanges}>
                  Save Changes
                </Button>
              </Flex>
            </BookingModalDialogBody>
            {openModalDeleteBooking && !isRequestPending && (
              <ModalCancelMeeting
                isOpen
                onClose={this.handleCloseModalDeleteBooking}
                onCancelMeeting={this.onDeleteBooking}
              />
            )}
          </BookingModal>
        )}
      </Container>
    )
  }
}

EditBookingModalContent.propTypes = {
  allStaff: PropTypes.object,
  availableHosts: PropTypes.array,
  availableStaff: PropTypes.array,
  booking: PropTypes.object,
  clients: PropTypes.object,
  isFetchingMeetingStaff: PropTypes.bool,
  isOpen: PropTypes.bool,
  isRequestPending: PropTypes.bool,
  isRequestingTimeCheck: PropTypes.bool,
  openingHours: PropTypes.object,
  requestError: PropTypes.string,
  retailers: PropTypes.object,
  scheduleId: PropTypes.string,
  sessionId: PropTypes.string,
  timeCheckError: PropTypes.string,
  onCheckNewBookingTime: PropTypes.func,
  onDeleteBooking: PropTypes.func,
  onFetchAvailableStaff: PropTypes.func,
  onRequestClose: PropTypes.func,
  onUnselectBooking: PropTypes.func,
  onUpdateBooking: PropTypes.func,
}

export default EditBookingModalContent

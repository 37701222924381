import "./styles.css"

import React, { memo } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { Loading } from "modaresa-commons"

import { ActiveSessions } from "./blocks/ActiveSessions"
import { ArchivedSessions } from "./blocks/ArchivedSessions"
import DraftSessions from "./blocks/DraftSessions"
import { SaleSession } from "./blocks/SaleSession"
import WrapperSalesSession from "./blocks/SaleSession/WrapperSalesSession"
import { SessionsLayout } from "./blocks/SessionsLayout"
import { ContainerLoading } from "./Sessions.styles"

type Props = {
  activeSessions: [],
  history: {},
  isAuthed: boolean,
  isDataLoaded: boolean,
  location: {},
  match: {},
  selectedSession: {},
  onSelectSession: Function,
}
const Sessions = ({ isAuthed, isDataLoaded, match, activeSessions, selectedSession, loggedUser }: Props) => {
  if (!isAuthed && !isDataLoaded) {
    return (
      <ContainerLoading>
        <Loading />
      </ContainerLoading>
    )
  }
  const defaultSessionId = selectedSession?._id || activeSessions[0]?._id
  const redirectTo = activeSessions.length ? `${match.path}/${defaultSessionId}` : `${match.path}/new`
  const hasEditorRights =
    loggedUser.hasEditorRights || ["owner", "admin", "agent"].indexOf(loggedUser.modaresaRole) > -1
  return (
    <SessionsLayout hasEditorRights={hasEditorRights}>
      <Switch>
        <Route component={DraftSessions} exact path={`${match.path}/draft`} />
        <Route component={ActiveSessions} exact path={`${match.path}/active`} />
        <Route component={ArchivedSessions} exact path={`${match.path}/archived`} />
        <Route component={SaleSession} exact key="/new" path={`${match.path}/new`} />
        <Route component={WrapperSalesSession} path={`${match.path}/:id/:section?`} />
        <Redirect to={redirectTo} />
      </Switch>
    </SessionsLayout>
  )
}

export default memo(Sessions)

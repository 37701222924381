import { createAsyncAction } from "../../helpers/redux"

export const INVITE_CLIENTS = createAsyncAction("INVITE_CLIENTS")
export const inviteClients = data => ({
  type: INVITE_CLIENTS.REQUEST,
  payload: data,
})

export const SEND_FOLLOWUPS = createAsyncAction("SEND_FOLLOWUPS")
export const sendFollowups = data => ({
  type: SEND_FOLLOWUPS.REQUEST,
  payload: data,
})

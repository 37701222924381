import React, { memo, useCallback, useState } from "react"

import RelouRequestsView from "./RelouRequestsView"

type Props = {
  salesSessionId: string,
  brandId: string,
}

const RelouRequests = ({ salesSessionId, brandId }: Props) => {
  const [minimize, setMinimize] = useState(false)
  const handleToggleMinimize = useCallback(() => setMinimize(!minimize), [minimize])
  return (
    <RelouRequestsView
      salesSessionId={salesSessionId}
      brandId={brandId}
      minimize={minimize}
      onToggleMinimize={handleToggleMinimize}
    />
  )
}

export default memo(RelouRequests)

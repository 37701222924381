import React, { memo } from "react"
import { ButtonToggleModal } from "modaresa-commons"

import ModalAddBrandRoom from "./ModalAddBrandRoom"

type Props = {
  brandId: string,
}

const ButtonAddBrandRoom = ({ brandId }: Props) => (
  <ButtonToggleModal labelButton="Add Room">
    {({ onClose }) => <ModalAddBrandRoom brandId={brandId} onClose={onClose} />}
  </ButtonToggleModal>
)

export default memo(ButtonAddBrandRoom)

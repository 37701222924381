import { Form as BSForm } from "react-bootstrap"
import styled from "styled-components"
export const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position: relative;

  & .row:not(:last-child) {
    display: flex;
    align-items: center;
  }

  & .label__padding {
    padding-top: 10px;
  }

  & .form-group {
    margin-right: 0 !important;
    margin-bottom: 20px !important;
  }

  & .selects-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .selects-time .select-time {
    width: 40%;
  }

  & .react-datepicker__input-container,
  & .react-datepicker__input-container input {
    width: 100%;
    &:disabled {
      background-color: #f2f2f2;
      border: 1px solid #e6e6e6;
      color: hsl(0, 0%, 60%);
    }
  }

  & .react-datepicker__input-container input {
    height: 38px;
    border-radius: 4px;
    border-width: 1px;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    padding-left: 10px;
  }

  & .date-pikers {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
export const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: white;
  padding: 5px 10px 5px 10px;
`
export const Form = styled(BSForm)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ErrorText = styled.div`
  color: ${props => props.theme.primary};
`
export const Footer = styled.div`
  padding: 0 30px 0px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const Icon = styled.img`
  display: inline-block;
  margin-left: 30px;
  width: ${props => props.width};
  height: ${props => props.height};
`
export const AutocompleteContainer = styled.div`
  width: 200px;
  max-height: 400px;
  position: absolute;
  z-index: 10;
  border-radius: 4px;
  border: ${props => props.border || "1px solid hsl(0,0%,80%)"};
  overflow: auto;
`
export const Suggestion = styled.div`
  padding: 10px;
`

export const storeType = {
  franchise: "Retail/Franchise",
  department: "Department Shop",
  multibrand: "Multibrand Shop",
  online: "Online",
  boutique: "Boutique",
}

export const storeTypeOptions = [
  {
    value: "franchise",
    label: "Retail/Franchise",
  },
  {
    value: "department",
    label: "Department Shop",
  },
  {
    value: "multibrand",
    label: "Multibrand Shop",
  },
  {
    value: "online",
    label: "Online",
  },
  {
    value: "boutique",
    label: "Boutique",
  },
]

import React, { memo, useCallback, useEffect, useState } from "react"
import { B, Button } from "modaresa-commons"

import { Container, FormRow } from "../Retailer.styles"

import { RetailerSelect } from "components/ui/Select/RetailerSelect"

type Props = {
  defaultSharedRetailerId: string,
  sharedRetailers: [],
  loadingSharedRetailers: boolean,
  addRetailerBySharedRetailer: Function,
  brand: {
    _id: string,
  },
  onClickNewRetailer: Function,
}
const AddSharedRetailerAsBrandRetailer = ({
  selectSharedRetailerId,
  sharedRetailers,
  loadingSharedRetailers,
  onClickNewRetailer,
  brand,
  addRetailerBySharedRetailer,
  user,
}: Props) => {
  const [selectedSharedRetailer, setSelectedSharedRetailer] = useState(null)
  const handleSelectSharedRetailer = useCallback(option => setSelectedSharedRetailer(option), [])
  const handleConfirmAddSharedRetailerAsBrandRetailer = useCallback(() => {
    addRetailerBySharedRetailer({
      id: selectedSharedRetailer.value,
      brandId: brand._id,
    })
  }, [selectedSharedRetailer, brand, addRetailerBySharedRetailer])
  useEffect(() => {
    if (selectSharedRetailerId) {
      const sharedRetailer = sharedRetailers.find(retailer => retailer._id === selectSharedRetailerId)
      if (sharedRetailer)
        setSelectedSharedRetailer({
          label: `${sharedRetailer.name} (${sharedRetailer.storeAddress.city}, ${sharedRetailer.storeAddress.country})`,
          value: sharedRetailer._id,
        })
    }
  }, [selectSharedRetailerId, sharedRetailers])
  return (
    <Container>
      <div style={{ paddingTop: "25px", paddingLeft: 45 }}>
        <div style={{ width: "40%" }}>
          <RetailerSelect
            isLoading={loadingSharedRetailers}
            sharedRetailers={sharedRetailers}
            value={selectedSharedRetailer}
            onChange={handleSelectSharedRetailer}
            user={user}
            brand={brand}
          />
          <Button
            disabled={!selectedSharedRetailer}
            minWidth="49%"
            style={{ margin: "20px 0 20px 0" }}
            type="button"
            onClick={handleConfirmAddSharedRetailerAsBrandRetailer}
          >
            Confirm
          </Button>
        </div>
        <p>
          {`Please choose one of the retailers in this list.
              We don’t differentiate between doors, so `}
          <strong>
            if one centralised buying team is buying for multiple doors, you choose the same retailer for all those
            different doors.
          </strong>
          {`
              If you want to display the name of a retailer a bit differently in your own CRM later on,
              you can after having added them. (For edits go into ’Retailers > Choose retailer > Edit and then press ‘Save Changes’)
              `}
        </p>
        <br />
        <p>
          <B>NB! </B>
          If you don’t see the name of the retailer or holding that you want to add, you can add it under ‘+ New
          Retailer’.
        </p>
        <FormRow>
          <Button minWidth="49%" type="button" onClick={onClickNewRetailer}>
            + New Retailer
          </Button>
        </FormRow>
      </div>
    </Container>
  )
}

export default memo(AddSharedRetailerAsBrandRetailer)

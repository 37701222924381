import styled from "styled-components"
import { CloseIcon } from "modaresa-commons/dist/components/icons"

export const ContainerBuyerPicker = styled.div`
  width: 100%;
`

export const Clients = styled.div`
  padding: 15px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.gray50};
  margin-bottom: 20px;
`

export const ClientItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 10px 7px;
  border-radius: 4px;
  background-color: ${props => props.theme.gray50};
  margin-bottom: 7px;

  & p {
    margin: 0;
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  margin: 0 8px;
  cursor: pointer;
  transition: 0.2s all ease-in;
  &:hover {
    color: ${p => p.theme.primary};
  }
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

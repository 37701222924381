import { createAsyncAction } from "../../helpers/redux"

export const FETCH_SALE_SESSIONS = createAsyncAction("FETCH_SALE_SESSIONS")
export const fetchSaleSassions = brandId => ({
  type: FETCH_SALE_SESSIONS.REQUEST,
  payload: {
    brandId,
  },
})

export const FETCH_SALE_SESSION = createAsyncAction("FETCH_SALE_SESSION")
export const fetchSaleSession = id => ({
  type: FETCH_SALE_SESSION.REQUEST,
  payload: {
    id,
  },
})

export const SELECT_SESSION = "SELECT_SESSION"
export const selectSession = id => ({
  type: SELECT_SESSION,
  payload: {
    id,
  },
})

export const UNSELECT_SESSION = "UNSELECT_SESSION"
export const unselectSession = () => ({
  type: UNSELECT_SESSION,
})

export const CREATE_SESSION = createAsyncAction("CREATE_SESSION")
export const createSession = data => ({
  type: CREATE_SESSION.REQUEST,
  payload: {
    data,
  },
})

export const UPDATE_SESSION = createAsyncAction("UPDATE_SESSION")
export const updateSession = data => ({
  type: UPDATE_SESSION.REQUEST,
  payload: {
    data,
  },
})

// TODO: This should be split in ARCHIVE_SESSION and DELETE_SESSION
// DELETE_SESSION: If isDraft is true, we completely remove the session from the DB
// ARCHIVE_SESSION: If isDraft is false, we mark it as 'deleted' and display it in the 'archived' category
// See also https://martinfowler.com/bliki/FlagArgument.html
export const DELETE_SESSION = createAsyncAction("DELETE_SESSION")
export const deleteSession = (id, isDraft = false) => ({
  type: DELETE_SESSION.REQUEST,
  payload: {
    id,
    isDraft,
  },
})

export const ACTIVATE_SESSION = createAsyncAction("ACTIVATE_SESSION")
export const activateSession = id => ({
  type: ACTIVATE_SESSION.REQUEST,
  payload: {
    id,
  },
})

export const UPDATE_AVAILABILITIES = createAsyncAction("UPDATE_AVAILABILITIES")
export const updateAvailabilities = data => ({
  type: UPDATE_AVAILABILITIES.REQUEST,
  payload: {
    ...data,
  },
})

export const FETCH_CAPACITY = createAsyncAction("FETCH_CAPACITY")
export const fetchCapacity = id => ({
  type: FETCH_CAPACITY.REQUEST,
  payload: id,
})

export const FETCH_SALE_SESSION_REPORTS = createAsyncAction("FETCH_SALE_SESSION_REPORTS")
export const fetchSaleSessionReport = id => ({
  type: FETCH_SALE_SESSION_REPORTS.REQUEST,
  payload: id,
})

export const ADD_ATTACHMENT = createAsyncAction("ADD_ATTACHMENT")
export const addAttachment = data => ({
  type: ADD_ATTACHMENT.REQUEST,
  payload: data,
})

export const DELETE_ATTACHMENT = createAsyncAction("DELETE_ATTACHMENT")
export const deleteAttachment = data => ({
  type: DELETE_ATTACHMENT.REQUEST,
  payload: { data },
})

export const FETCH_TIMEZONE = createAsyncAction("FETCH_TIMEZONE")
export const fetchTimezone = latLng => ({
  type: FETCH_TIMEZONE.REQUEST,
  payload: latLng,
})

import { gql } from "@apollo/client"

import StaffFragments from "./staff.fragments"

export const FRAGMENT_AREA_STAFFS = gql`
  fragment AreaStaffs on Area {
    id
    meetingResponsible {
      ...StaffBasicInfos
    }
    signee {
      ...StaffBasicInfos
    }
    notifiedStaffs {
      ...StaffBasicInfos
    }
  }
  ${StaffFragments.fragments.basicInfos}
`

export const FRAGMENT_AREA_TERRITORY_PLACE = gql`
  fragment AreaTerritoryPlace on Place {
    name
    country
    googlePlaceId
    formattedAddress
  }
`

export const FRAGMENT_AREA_TERRITORY = gql`
  fragment AreaTerritory on Area {
    territories {
      continents
      countries
      regions {
        ...AreaTerritoryPlace
      }
      counties {
        ...AreaTerritoryPlace
      }
      cities {
        ...AreaTerritoryPlace
      }
      retailers {
        id
        name
      }
    }
  }
  ${FRAGMENT_AREA_TERRITORY_PLACE}
`

const AreaFragments = {
  fragments: {
    staffs: FRAGMENT_AREA_STAFFS,
    territory: FRAGMENT_AREA_TERRITORY,
    territoryPlace: FRAGMENT_AREA_TERRITORY_PLACE,
  },
}

export default AreaFragments

import { useQuery } from "@apollo/client"
import React, { memo } from "react"
import { SkeletonBlock } from "modaresa-commons"

import { BRAND_RETAILERS } from "../../../graphql/queries/brand"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

import TerritoryPickerSelect from "./TerritoryPickerSelect"

type Props = {
  multiple?: boolean,
  placeholder?: string,
  disabled: boolean,
  onChange: Function,
  defaultValue: any,
  value: any,
  excludeCountries: [],
  options: [],
  brandId?: string,
}

const TerritoryPicker = ({
  multiple,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
  excludeCountries,
  options,
  brandId,
}: Props) => {
  const { loading, error, data } = useQuery(BRAND_RETAILERS, {
    variables: {
      id: brandId,
    },
    skip: !!options || !brandId,
  })
  if (options) {
    return (
      <TerritoryPickerSelect
        options={options}
        multiple={multiple}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        excludeCountries={excludeCountries}
      />
    )
  }
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading brand retailers</ErrorLiveChatHelp>
  if (loading) return <SkeletonBlock height="38px" />
  return (
    <TerritoryPickerSelect
      retailers={data?.Brand?.retailers}
      multiple={multiple}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      excludeCountries={excludeCountries}
    />
  )
}

TerritoryPicker.defaultProps = {
  placeholder: "Select territory",
  multiple: false,
  excludeCountries: [],
  brandId: undefined,
}
export default memo(TerritoryPicker)

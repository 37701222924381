import { countries as countriesList } from "../../config"
import continentsList from "../../config/continents"

const generateOptions = {
  continents: continents =>
    continents.map(continentIso => {
      const continentName = continentsList[continentIso]
      return {
        label: continentName,
        title: continentName,
        value: continentIso,
        type: "continent",
      }
    }),
  countries: countries =>
    countries.map(countryIso => {
      const countryObj = countriesList[countryIso]
      return {
        label: countryObj.name,
        title: countryObj.name,
        value: countryIso,
        type: "country",
      }
    }),
  regions: regions =>
    regions.map(region => ({
      label: region.formattedAddress,
      title: region.formattedAddress,
      value: region.googlePlaceId,
      type: "region",
    })),
  counties: counties =>
    counties.map(county => ({
      label: county.formattedAddress,
      title: county.formattedAddress,
      value: county.googlePlaceId,
      type: "county",
    })),
  cities: cities =>
    cities.map(city => ({
      label: city.formattedAddress,
      title: city.formattedAddress,
      value: city.googlePlaceId,
      type: "city",
    })),
  retailers: retailers =>
    retailers.map(retailer => ({
      label: retailer.name,
      title: retailer.name,
      value: retailer.id,
      type: "retailer",
    })),
}

type Props = {
  continents: [string],
  countries: [string],
  regions: [
    {
      name: string,
      country: string,
      formattedAddress: string,
      googlePlaceId: string,
    },
  ],
  counties: [
    {
      name: string,
      country: string,
      formattedAddress: string,
      googlePlaceId: string,
    },
  ],
  cities: [
    {
      name: string,
      country: string,
      formattedAddress: string,
      googlePlaceId: string,
    },
  ],
  retailers: [{ id: string, name: string }],
}
const generateTerritoriesOptionsValuesFromObject = (territories: Props = {}) =>
  Object.keys(generateOptions).reduce(
    (territoriesOptions, field) =>
      territories[field]?.length > 0
        ? territoriesOptions.concat(generateOptions[field](territories[field]))
        : territoriesOptions,
    [],
  )

export default generateTerritoriesOptionsValuesFromObject

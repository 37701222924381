import React, { Component } from "react"
import PropTypes from "prop-types"
import isEmpty from "lodash/isEmpty"
import { Button, Flex, ModalConfirmation } from "modaresa-commons"

import { formatAreaTerritories } from "../../../../../helpers/areas"
import generateTerritoriesValuesFromOptions from "../../../../../helpers/areasAndTerritories/generateTerritoriesValuesFromOptions"
import { DeleteButton, FooterLeft, FooterRight } from "../../../../elements/SessionFooter"
import { countries, meetingTypes, meetingTypesValues } from "../../../../../config"
import { onToastInfo } from "../../../../../helpers/toast"

import { Col, Container, Content, Footer, Table, TableBody, TableHeader } from "./styles"
import AddStaffModal from "./Modal"
import { AllocatedStaffMember } from "./AllocatedStaffMember"

const countryOptions = Object.keys(countries).map(isoCode => ({
  value: isoCode,
  label: countries[isoCode].name,
  type: "country",
}))

const canStaffMemberByAHost = staff => ["areaManager", "nonExecAreaManager"].indexOf(staff.modaresaRole) === -1

const getAllocatedStaffWithoutVideoIDs = (staff, allocatedStaff) =>
  allocatedStaff.allIds
    .map(id => staff.byId[id])
    .filter(staff => staff && (!staff.videoIDs || !Object.keys(staff.videoIDs).length))
    .filter(staff => canStaffMemberByAHost(staff))

const getMeetingTypesWithoutEnoughHosts = (staff, brand, session, areas) => {
  if (session.algorithms.geo) {
    const areasObject = {}
    const type = { buyingAppointment: true }
    if (session.walkthrough.available) {
      type.walkthrough = true
    }
    if (session.businessMeeting.available) {
      type.businessMeeting = true
    }

    areas.forEach(area => {
      areasObject[area._id] = { ...type }
    })

    let index = 0
    const lastIndex = staff.allIds.length - 1
    while (!isEmpty(areasObject) && index <= lastIndex) {
      const staffMemberId = staff.allIds[index]
      const staffMember = staff.byId[staffMemberId]
      staffMember.geographicResponsibility.areas.forEach(areaId => {
        const area = areasObject[areaId]
        if (area) {
          staffMember.availableMeetingTypes.forEach(type => {
            delete area[type]
          })
          if (isEmpty(area)) {
            delete areasObject[areaId]
          }
        }
      })
      index++
    }

    const typesRequiredWithNoAreas = Object.values(areasObject).reduce(
      (typesRequired, areasTypes) => ({ ...typesRequired, ...areasTypes }),
      {},
    )
    return Object.keys(typesRequiredWithNoAreas)
  }
  return []
}
class AllocateStaff extends Component {
  constructor(props) {
    super(props)
    const { staff, session, brand, areaManagers, allStaff } = this.props
    const allocatedStaff = {
      allIds: [],
      byId: {},
    }
    this.roomsOptions = session.rooms.map(room => ({
      value: room.name,
      label: room.name,
    }))
    if (session.staff && session.staff.length > 0) {
      session.staff.forEach(staffMember => {
        allocatedStaff.allIds.push(staffMember.staffId)
        allocatedStaff.byId[staffMember.staffId] = staffMember
      })
    } else {
      allStaff.forEach(id => {
        const staffMember = staff.byId[id]
        const allocatedStaffMember = {
          staffId: id,
          languages: staffMember.langResponsibility || [],
          geographicResponsibility: staffMember.geographicResponsibility,
          roomResponsibility: session.rooms.map(room => room.name),
          videoIDs: staffMember.videoIDs || {},
        }
        allocatedStaffMember.availableMeetingTypes =
          staffMember.modaresaRole === "freelancer"
            ? ["buyingAppointment"]
            : ["businessMeeting", "walkthrough", "buyingAppointment"]
        allocatedStaffMember.availabilities = session.openingHours.map(day => ({
          available: true,
          from: day.from,
          to: day.to,
          date: day.date,
        }))
        allocatedStaff.allIds.push(id)
        allocatedStaff.byId[id] = allocatedStaffMember
      })
    }

    this.areas = session.status === "active" ? session.areas : brand.areas
    this.areasOptions = this.areas.map(area => ({
      value: area._id,
      label: area.name,
      type: "area",
      countries: area.countries,
    }))
    this.geoGroups = [
      { label: "Area", options: this.areasOptions },
      { label: "Countries", options: countryOptions },
    ]

    let geoLimitData = []
    if (brand.geoLimit) {
      geoLimitData =
        session.geoLimitData && session.geoLimitData.length
          ? session.geoLimitData
          : this.areas.map(area => {
              const areaManager = areaManagers.find(am => am.geographicResponsibility.areas[0] === area._id)
              return {
                areaId: area._id,
                staffId: areaManager ? areaManager._id : null, // area.staffId,
                rooms: session.rooms.map(room => ({
                  name: room.name,
                  structure: room.structure.map(day => ({
                    meetingCapacity: day.meetingCapacity,
                    date: day.date,
                    limit: null,
                  })),
                })),
              }
            })
    }

    this.state = {
      expanded: {},
      geoLimitData,
      allocatedStaff,
      errors: {},
      staffModal: false,
      selectedNewStaff: {},
      buttonText: "Next",
      loadingSubmit: false,
    }
  }

  onSubmit = () => {
    this.setState({ loadingSubmit: true })
    const { onSubmit, session, brand, staff } = this.props
    const { geoLimitData, allocatedStaff } = this.state

    const missingHostsForMeetingTypes = getMeetingTypesWithoutEnoughHosts(allocatedStaff, brand, session, this.areas)
    const isEnougthHosts = session.toggleRoomBasedAreas || missingHostsForMeetingTypes.length === 0
    if (isEnougthHosts) {
      if (session.videoMeetings && getAllocatedStaffWithoutVideoIDs(staff, allocatedStaff).length) {
        this.setState({ staffNeedsVideoIDsModal: true, loadingSubmit: false })
        return
      }
      onSubmit({
        geoLimitData,
        staff: allocatedStaff.allIds.map(id => {
          const staffById = allocatedStaff.byId[id]
          const { territories, ...geographicResponsibility } = staffById.geographicResponsibility
          staffById.geographicResponsibility = {
            ...geographicResponsibility,
            territories: formatAreaTerritories(territories || {}),
          }
          return staffById
        }),
      })
    } else {
      onToastInfo({
        mainText: `Stop! You haven't allocated a Meeting Host for all areas. Ensure you have minimum one vendor available \
        and tagged with ${missingHostsForMeetingTypes
          .map(keyType => `'${meetingTypesValues[keyType]}'`)
          .join(",")} for \
        each area before proceeding.`,
      })
    }
    this.setState({ loadingSubmit: false })
    this.setState({ buttonText: "Save changes" })
  }

  onRemoveStaff = id => {
    this.setState(state => {
      const { allocatedStaff } = state
      const { ...byId } = allocatedStaff.byId
      const newIds = allocatedStaff.allIds.filter(elem => elem !== id)
      return {
        allocatedStaff: {
          byId,
          allIds: newIds,
        },
        buttonText: "Save changes",
      }
    })
  }

  geoHandleChange = async (values = [], staffId) => {
    const territoriesOpts = []
    const areas = []
    const roomAreas = []
    values?.forEach(item => {
      switch (item.type) {
        case "area":
          areas.push(item.value)
          break
        case "roomArea":
          roomAreas.push(item.objValue)
          break
        default:
          territoriesOpts.push(item)
          break
      }
    })
    const territories = await generateTerritoriesValuesFromOptions(territoriesOpts)

    this.setState(state => ({
      allocatedStaff: {
        ...state.allocatedStaff,
        byId: {
          ...state.allocatedStaff.byId,
          [staffId]: {
            ...state.allocatedStaff.byId[staffId],
            geographicResponsibility: {
              territories,
              areas,
              roomAreas,
            },
          },
        },
      },
      buttonText: "Save changes",
    }))
  }

  handleChange = (type, value, id) => {
    this.setState(state => ({
      allocatedStaff: {
        ...state.allocatedStaff,
        byId: {
          ...state.allocatedStaff.byId,
          [id]: {
            ...state.allocatedStaff.byId[id],
            [type]: value,
          },
        },
      },
      buttonText: "Save changes",
    }))
  }

  toogleGelLimit = id => {
    this.setState(state => ({
      expanded: { ...state.expanded, [id]: !state.expanded[id] },
    }))
  }

  geoLimHandle = (value, id, dayIdx, roomIdx) => {
    const { session } = this.props

    this.setState(state => {
      const geoLimitData = [...state.geoLimitData]
      const staffIndex = geoLimitData.findIndex(limit => limit.staffId === id)
      if (!session.customizeStructure) {
        geoLimitData[staffIndex].rooms[roomIdx].structure = geoLimitData[staffIndex].rooms[roomIdx].structure.map(
          day => ({
            ...day,
            limit: value,
          }),
        )
      } else {
        geoLimitData[staffIndex].rooms[roomIdx].structure[dayIdx].limit = value
      }
      geoLimitData[staffIndex] = { ...geoLimitData[staffIndex] }
      return {
        geoLimitData,
        buttonText: "Save changes",
      }
    })
  }

  toggleModal = name => {
    this.setState(prevState => ({
      [name]: !prevState[name],
    }))
  }

  addStaffSession = newStaff => {
    const { session, staff } = this.props
    const newStaffArray = Object.keys(newStaff)

    const newMembers = {
      allIds: [],
      byId: {},
    }

    newStaffArray.forEach(id => {
      const staffMember = staff.byId[id]
      const allocatedStaffMember = {
        staffId: id,
        languages: staffMember.languages || [],
        geographicResponsibility: staffMember.geographicResponsibility,
        roomResponsibility:
          staffMember.roomResponsibility.length > 0
            ? staffMember.roomResponsibility
            : session.rooms.map(room => room.name),
        videoIDs: staffMember.videoIDs || {},
      }
      allocatedStaffMember.availableMeetingTypes =
        staffMember.modaresaRole === "freelancer"
          ? [meetingTypes[2].value]
          : [meetingTypes[0].value, meetingTypes[1].value, meetingTypes[2].value]
      allocatedStaffMember.availabilities = session.openingHours.map(day => ({
        available: true,
        from: day.from,
        to: day.to,
        date: day.date,
      }))
      newMembers.allIds.push(id)
      newMembers.byId[id] = allocatedStaffMember
    })

    this.setState(state => ({
      allocatedStaff: {
        byId: {
          ...state.allocatedStaff.byId,
          ...newMembers.byId,
        },
        allIds: [...state.allocatedStaff.allIds, ...newMembers.allIds],
      },
      staffModal: false,
      buttonText: "Save changes",
    }))
  }

  renderRows = () => {
    const { staff, session, brand, userIsAdmin, retailers } = this.props
    const { expanded, allocatedStaff, geoLimitData } = this.state

    return allocatedStaff.allIds.map(id => {
      const staffMember = staff.byId[id]
      if (!staffMember) return null
      const allocatedStaffMember = allocatedStaff.byId[id]
      return (
        <AllocatedStaffMember
          allocatedStaffMember={allocatedStaffMember}
          brand={brand}
          expanded={expanded[id]}
          geoGroups={this.geoGroups}
          geoHandleChange={this.geoHandleChange}
          geoLimHandle={this.geoLimHandle}
          geoLimitData={geoLimitData.find(limit => limit.staffId === id)}
          handleChange={this.handleChange}
          id={id}
          key={staffMember._id}
          readOnly={!userIsAdmin}
          roomsOptions={this.roomsOptions}
          session={session}
          staffMember={staffMember}
          toogleGelLimit={this.toogleGelLimit}
          onRemoveStaff={this.onRemoveStaff}
          areas={this.areas}
          retailers={retailers}
        />
      )
    })
  }

  render() {
    const { session, userIsAgent, userIsAdmin, staff, allStaff, history } = this.props
    const { buttonText, loadingSubmit } = this.state
    const { toggleRoomBasedAreas } = session

    return (
      <Container>
        <Content>
          <Table>
            <TableHeader>
              <Col width="calc(16% - 30px)">Full Name</Col>
              <Col width="12%">Role</Col>
              <Col width="16%">Languages Spoken</Col>
              <Col width="16%">Areas & Territories</Col>
              {!toggleRoomBasedAreas && <Col width="16%">Room Responsibility</Col>}
              <Col width="16%">Can be Booked to</Col>
              <Col width="8%">Geo. AM. Limit</Col>
              <Col style={{ height: "100%" }} width="30px" />
            </TableHeader>
            <TableBody>{this.renderRows()}</TableBody>
          </Table>
        </Content>
        {userIsAdmin && (
          <Footer>
            <Flex column>
              <Button align="flex-end" type="button" onClick={() => this.toggleModal("staffModal")}>
                +Add More Staff
              </Button>
            </Flex>
            <Flex>
              <FooterLeft>
                {!userIsAgent && (
                  <Button type="button" onClick={this.props.onPrevPress}>
                    Previous
                  </Button>
                )}
              </FooterLeft>
              <FooterRight>
                {!userIsAgent && (
                  <DeleteButton type="button" onClick={this.props.onDeleteSession}>
                    Delete Sales Session
                  </DeleteButton>
                )}
                <Button type="button" onClick={this.onSubmit} disabled={loadingSubmit}>
                  {session.status === "active" ? buttonText : "Save changes"}
                </Button>
              </FooterRight>
            </Flex>
          </Footer>
        )}
        <AddStaffModal
          allStaff={allStaff}
          isModal={this.state.staffModal}
          sessionName={this.props.session.name}
          sessionStaff={this.state.allocatedStaff.byId}
          staff={this.props.staff}
          onAddStaff={this.addStaffSession}
          onCloseModal={this.toggleModal}
        />
        <ModalConfirmation
          isOpen={this.state.staffNeedsVideoIDsModal}
          buttonLabelOk="Edit staff"
          onClickOk={() => history.push("/staff")}
          onClose={() => this.setState({ staffNeedsVideoIDsModal: false })}
          hideCancel
        >
          <strong>NB!</strong> The following staff are missing a virtual meeting ID in their profile. Go into their
          respective profiles and add minimum one virtual meeting ID.
          <div style={{ margin: "15px 30px" }}>
            <ul>
              {getAllocatedStaffWithoutVideoIDs(staff, this.state.allocatedStaff).map(staff => (
                <li key={staff._id}>
                  {staff.firstName} {staff.lastName}
                </li>
              ))}
            </ul>
          </div>
          Followingly, you can resume this sales session from 'Draft Sessions' in the left hand menu under SESSION.
        </ModalConfirmation>
      </Container>
    )
  }
}

AllocateStaff.propTypes = {
  allStaff: PropTypes.array,
  areaManagers: PropTypes.array,
  brand: PropTypes.object,
  session: PropTypes.object,
  staff: PropTypes.object,
  userIsAdmin: PropTypes.bool,
  userIsAgent: PropTypes.bool,
  onDeleteSession: PropTypes.func,
  onPrevPress: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default AllocateStaff

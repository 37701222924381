export const getAllNames = (array, list) => {
  let names = ""
  array.forEach(item => {
    if (typeof item === "object") {
      names += `${list[item._id].firstName} ${list[item._id].lastName}, `
    } else {
      names += `${list[item].firstName} ${list[item].lastName}, `
    }
  })
  names = names.slice(0, -2)
  return names
}

import styled from "styled-components"

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 54px);
`
export const Timeline = styled.div`
  margin-top: -2px;
  padding-top: 38px;
  padding-bottom: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 60px;
  border: 2px solid transparent;
  position: sticky;
  min-width: 60px;
  background-color: #fff;
  z-index: 1;
  left: 0;
`

export const Hour = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
  justify-content: center;
  flex-basis: 40px;
`

export const CalendarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 100%;
  overflow-x: auto;
`

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  background-color: ${props => props.theme.gray75}
  align-items: center;
  padding: 0 20px;
  z-index: 2;

  &>div {
    width: 33.33%;
  }
`
export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Icon = styled.img`
  display: block;
  width: auto;
  height: 18px;
  cursor: pointer;
  margin: 0 10px;
  ${props => (props.rotate ? "transform: rotate(180deg)" : "")};
  ${props => (props.hidden ? "visibility: hidden" : "")};
`
export const Date = styled.span``
export const CalendarContent = styled.div`
  display: flex;
  flex: 1;
`

export const RoomCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex};
`
export const RoomHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`

export const RoomSchedule = styled.div`
  display: flex;
  flex: 1;
  border: 2px solid black;
  min-width: ${props => props.minWidth * 80 + 4}px;
`

export const DaySchedule = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #000;
  flex: 1;

  &:not(:last-child) {
    border-right: none;
  }

  & ${RoomSchedule} {
    border: none;
  }

  & ${RoomCol}:not(:last-child) {
    border-right: 2px solid #000;
  }
`

export const Table = styled.div`
  display: flex;
  flex: 1 0 5px;
  flex-direction: column;
  min-width: 80px;
  border-right: ${props => (props.last ? "none" : "2px solid #ccc")}
  position: relative;
`

export const StaffTableHeader = styled.div`
  display: flex;
  height: 200px;
  overflow: hidden;
  min-width: ${props => props.minWidth * 80 + 4}px;
`
export const StaffMemberTableHeader = styled.div`
  display: flex;
  height: 40px;
  min-width: ${props => props.minWidth * 80 + 4}px;
`

export const StaffColumn = styled.div`
  display: flex;
  flex: 1 0 5px;
  flex-direction: column;
  min-width: 80px;
  position: relative;
  border-right: ${props => (props.last ? "none" : "2px solid #ccc")};
`

export const StaffColumnHeader = styled.div`
  display: flex;
  flex: 1 0 5px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 80px;
  position: relative;
`

export const Timeslot = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 40px;
  flex-basis: 40px;
  border-bottom: ${props => (props.even ? "2px solid #ccc" : "3px dotted #ccc")};
`

export const TimeslotStaff = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 40px;
  border-bottom: 3px dotted #ccc;

  &:nth-child(2n) {
    border-bottom: 2px solid #ccc;
  }
`

export const Booklot = styled.div`
  display: flex;
  flex: ${props => props.flex};
  justify-content: center;
  min-height: ${props => props.flex * 40}px;
  flex-basis: 40px;
  border-bottom: 2px solid #ccc;
  ${props => props.blocked && "background-color: #d8d8d8;"}
`

export const Placeholder = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: rgba(167, 12, 71, 0.77);
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  pointer-events: none;
`
export const Block = styled.div`
  display: block;
`
export const MeetingInfo = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.gray50};
  border-radius: 8px;
  padding: 10px;
  margin-bottom: ${props => props.margin || "20px"};

  & .meetingTypes {
    margin-bottom: 5px;
  }
`

export const MeetingDate = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${props => props.margin || "20px"};

  & img {
    display: block;
    margin: 10px;
    height: 25px;
    width: auto;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: ${props => props.justify || "space-between"};
  flex-direction: ${props => (props.column ? "column" : "")};
  align-items: ${props => props.align || "center"};
  margin: ${props => props.margin};

  & .startTime {
    margin-bottom: 5px;
  }
`

export const Override = styled.div`
display: flex;
align-items: center;
padding-left: 14px;
font-size: 15px;
color ${props => props.theme.textBlack};

& input[type=checkbox] {
  margin-top: 0;
  margin-right: 20px;
}
`
export const MeetingClients = styled.div`
  border: 1px solid ${props => props.theme.gray50};
  border-radius: 3px;
  padding: 10px 5px;
  margin-bottom: 20px;
`
export const MeetingBudget = styled.div`
  width: 50%;
  border: 1px solid ${props => props.theme.gray50};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 10px;
  margin-bottom: 20px;
`
export const Bold = styled.span`
  font-weight: 600;
`
export const Rotate = styled.span`
  transform: rotate(-75deg);
  white-space: nowrap;
  max-width: 40px;
  padding-left: 15px;
`
export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;

  ${({ image }) => {
    if (image) {
      return `
    font-size: 14px;
    flex-direction: column
    `
    }
  }}

  & img {
    object-fit: contain;
    width: 75%;
    height: 75%;
  }
`

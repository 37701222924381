import { gql } from "@apollo/client"

export const SALES_SESSION_STAFF_AVAILABILITIES = gql`
  query SalesSessionStaffAvailabilities($salesSessionId: ID!, $staffId: ID!) {
    SalesSessionStaff(salesSessionId: $salesSessionId, staffId: $staffId) {
      id
      availabilities {
        available
        from
        to
      }
    }
  }
`

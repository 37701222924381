import styled from "styled-components"

export const ContainerRoomView = styled.div`
  padding: 30px;
`

export const RoomName = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0;
`

export const ContainerForm = styled.div`
  width: 100%;
  max-width: 704px;
`

import React, { memo, useCallback } from "react"

import PageTitle from "../../../elements/PageTitle/PageTitle"
import ButtonDeleteBuyer from "../../../elements/ButtonDeleteBuyer/ButtonDeleteBuyer"

import BuyersList from "./BuyersList/BuyersList"

type Props = {
  brandId: string,
  retailerStatus: string,
  restrictToStaffId: string,
  withPagination: boolean,
  withFilterAutocomplete: boolean,
}

const AllBuyers = ({
  brandId,
  retailerStatus,
  restrictToStaffId,
  withPagination = false,
  withFilterAutocomplete = true,
}: Props) => {
  const handleRenderActions = useCallback(buyer => <ButtonDeleteBuyer brandId={brandId} buyer={buyer} />, [brandId])
  return (
    <PageTitle title={`All buyers${retailerStatus ? `: ${retailerStatus}s` : ""}`}>
      <BuyersList
        brandId={brandId}
        restrictToStaffId={restrictToStaffId}
        retailerStatus={retailerStatus}
        onRenderActions={handleRenderActions}
        withPagination={withPagination}
        withFilterAutocomplete={withFilterAutocomplete}
      />
    </PageTitle>
  )
}

export default memo(AllBuyers)

import { useMutation } from "@apollo/client"
import { useHistory } from "react-router-dom"
import React, { memo, useCallback, useState } from "react"

import { CREATE_STAFF } from "../../../graphql/mutations/staff"
import getAreasAndTerritoriesValuesFromOptions from "../../../helpers/areasAndTerritories/getAreasAndTerritoriesValuesFromOptions"
import { getGraphQLErrorAsString } from "../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../helpers/toast"
import FormStaffGeneralInfos from "../../elements/FormStaffGeneralInfos"
import PageTitle from "../../elements/PageTitle/PageTitle"
import withLoggedUser from "../../hoc/withLoggedUser"

import { ContainerStaffAddNew, Title } from "./StaffAddNew.styles"

type Props = {
  loggedUser: {
    staff: {
      brand: {
        id: string,
      },
    },
  },
}
const StaffAddNew = ({ loggedUser }: Props) => {
  const history = useHistory()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { brand } = loggedUser.staff
  const [createStaff] = useMutation(CREATE_STAFF)
  const handleSubmit = useCallback(
    async values => {
      setLoadingSubmit(true)
      try {
        const variables = {
          brandId: brand.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          companyPosition: values.companyPosition,
          hasEditorRights: values.hasEditorRights,
          languagesIsoCodes: values.languages.map(lang => lang.value),
          modaresaRole: values.modaresaRole.value,
          officeId: values.office.value,
          phoneNumber: values.phoneNumber,
          areasAndTerritories: await getAreasAndTerritoriesValuesFromOptions(values.areasAndTerritories),
        }
        if (variables.modaresaRole !== "freelancer") {
          variables.doCreateUserAccount = true
        }
        const {
          data: { createStaff: createdStaff },
        } = await createStaff({ variables })
        history.push(`/staff/${createdStaff.id}`)
        onToastSuccess("Staff has been created successfully")
      } catch (e) {
        console.error(e)
        onToastError(`An error occured while creating new staff ${getGraphQLErrorAsString(e)}`)
        setLoadingSubmit(false)
      }
    },
    [history, createStaff, brand],
  )
  return (
    <PageTitle title="Add new staff">
      <ContainerStaffAddNew>
        <Title>Add new staff to {brand.name}</Title>
        <FormStaffGeneralInfos
          brandId={brand.id}
          labelSubmit="+ Add Staff"
          onSubmit={handleSubmit}
          loadingSubmit={loadingSubmit}
          canEditAccessRights
        />
      </ContainerStaffAddNew>
    </PageTitle>
  )
}

export default withLoggedUser(memo(StaffAddNew))

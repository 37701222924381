import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const IconImg = styled.img`
  display: block;
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "auto"};
  margin: ${props => props.margin};
`

export const Icon = props => (
  <div>
    <IconImg height={props.height} margin={props.margin} src={props.icon} width={props.width} />
  </div>
)

Icon.propTypes = {
  height: PropTypes.string,
  icon: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
}

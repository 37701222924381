import { useQuery } from "@apollo/client"
import React, { memo } from "react"
import { AlertDanger } from "modaresa-commons"

import { BRAND_AREAS } from "../../../../../graphql/queries/brand"
import Areas from "../../../../elements/Areas/Areas"
import AreasManager from "../../../../elements/AreasManager/AreasManager"

type Props = {
  brandId: string,
  onChangeAreas: Function,
  onChangeRemainingArea: Function,
  errors: {},
  canEdit: boolean,
}

const BrandAreas = ({ brandId, onChangeAreas, onChangeRemainingArea, errors, canEdit }: Props) => {
  const { loading, error, data } = useQuery(BRAND_AREAS, {
    variables: { id: brandId },
    onCompleted: ({ Brand: { areas, remainingArea } }) => {
      onChangeAreas(areas)
      onChangeRemainingArea(remainingArea)
    },
  })
  if (loading && !data) return <Areas loading />
  if (error) return <AlertDanger>Error while loading brand areas</AlertDanger>

  const { areas, remainingArea, retailers } = data.Brand
  return (
    <AreasManager
      retailers={retailers || []}
      defaultAreas={areas || []}
      defaultRemainingArea={remainingArea || {}}
      onChangeAreas={onChangeAreas}
      onChangeRemainingArea={onChangeRemainingArea}
      errors={errors}
      brandId={brandId}
      canAreasBeModified={canEdit}
      canStaffsBeModified={canEdit}
    />
  )
}

export default memo(BrandAreas)

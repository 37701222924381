import React from "react"
import * as yup from "yup"

import { yupSelectOption } from "../../../../helpers/yup"

export const notificationsFields = [
  {
    name: "meetingCheckin",
    label: "Meeting Responsible Check-ins",
    toggleFrequency: false,
    helpText: (
      <span>
        All Meeting Hosts and Meeting Responsibles allocated to a meeting receive a notification
        <br />
        when a client has been marked as Arrived, Delayed or Cancelled for that given appointment. <br />
        Turning this on disables notifications to appointments you are assigned to as a Meeting Responsible.
      </span>
    ),
  },
  {
    name: "meetingBooked",
    label: "Meetings Booked",
    toggleFrequency: true,
  },
  {
    name: "meetingCancelled",
    label: "Meeting Cancelled",
    toggleFrequency: true,
  },
  {
    name: "meetingChanged",
    label: "Changed Meeting",
    toggleFrequency: true,
  },
  {
    name: "autoMorningRecap",
    label: "Automatic Morning Recap",
    toggleFrequency: false,
  },
  {
    name: "offScheduleRequest",
    label: "Joker Request",
    toggleFrequency: true,
  },
]

export const notificationsSchema = yup.object().shape(
  notificationsFields.map(() =>
    yup.object().shape({
      active: yup.boolean(),
      frequency: yupSelectOption,
    }),
  ),
)

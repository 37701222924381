import { useFormikContext, getIn } from "formik"
import React, { memo, useCallback, useMemo } from "react"
import styled from "styled-components"
import { Error, Input } from "modaresa-commons"
import { TrashIcon } from "modaresa-commons/dist/components/icons"

import BrandRetailerPicker from "../../../../elements/BrandRetailerPicker/BrandRetailerPicker"

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: flex-start;
`

const StyledInput = styled(Input)`
  width: 200px;
  margin-right: 16px;
`

const ContainerPicker = styled.div`
  flex: 1;
`

const ContainerIcon = styled.div`
  font-size: 20px;
  margin-left: 16px;
  width: 32px;
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

type Props = {
  brandId: string,
  competitorGroup: {
    retailers: [],
  },
  competitorGroupIdx: number,
  alreadyUsedRetailers: [
    {
      id: string,
    },
  ],
  readOnly: boolean,
  onClickDelete: Function,
  restrictToStaffIdIfAgent: string,
}

const CompetitorGroupRow = ({
  brandId,
  competitorGroup,
  competitorGroupIdx,
  alreadyUsedRetailers,
  readOnly,
  onClickDelete,
  restrictToStaffIdIfAgent,
}: Props) => {
  const { setFieldValue, errors, touched } = useFormikContext()
  const nameFieldName = `competitorGroups[${competitorGroupIdx}].name`
  const retailersFieldName = `competitorGroups[${competitorGroupIdx}].retailers`
  const handleChangeName = useCallback(
    e => {
      setFieldValue(nameFieldName, e.target.value)
    },
    [nameFieldName, setFieldValue],
  )
  const handleChangeRetailers = useCallback(
    retailers => {
      setFieldValue(retailersFieldName, retailers || [])
    },
    [retailersFieldName, setFieldValue],
  )
  const handleClickDelete = useCallback(() => onClickDelete(competitorGroupIdx), [onClickDelete, competitorGroupIdx])
  const error = useMemo(() => {
    const err = getIn(errors, nameFieldName)
    const touch = getIn(touched, nameFieldName)
    return touch && err ? err : null
  }, [errors, touched, nameFieldName])
  return (
    <>
      <Row>
        <StyledInput
          defaultValue={competitorGroup.name}
          placeholder="Group name"
          disabled={readOnly}
          onChange={handleChangeName}
        />
        <ContainerPicker>
          <BrandRetailerPicker
            brandId={brandId}
            multiple
            placeholder="Select retailers"
            defaultValue={competitorGroup.retailers}
            disabled={readOnly}
            excludeRetailers={alreadyUsedRetailers}
            onChange={handleChangeRetailers}
            restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
          />
        </ContainerPicker>
        {!readOnly && (
          <ContainerIcon onClick={handleClickDelete}>
            <TrashIcon />
          </ContainerIcon>
        )}
      </Row>
      {error && (
        <Error block margin="0 0 16px 0">
          {error}
        </Error>
      )}
    </>
  )
}

export default memo(CompetitorGroupRow)

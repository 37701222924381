import "react-virtualized/styles.css"
import "react-toastify/dist/ReactToastify.css"
import "react-phone-input-2/lib/style.css"
import "rc-tooltip/assets/bootstrap.css"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 14px;
  }

  body {
  }
  html, body {
    height: 100%;
    margin: 0;
  }

  #root {
    font-family: avenir;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  *, *:before, *:after {
    font-family: avenir;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
  }
`

export default GlobalStyle

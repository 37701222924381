import { call, put, select } from "redux-saga/effects"
import Raven from "raven-js"
import moment from "moment-timezone"

import {
  ARRIVED_TO_APPOINTMENT,
  CHECK_NEW_BOOKING_TIME,
  CONFIRM_BOOKING,
  CONFIRM_JOKER_REQUEST,
  DECLINE_JOKER_REQUEST,
  DELAY_APPOINTMENT,
  DELETE_APPOINTMENT,
  DELETE_BOOKING,
  FETCH_AVAILABLE_CLIENTS,
  FETCH_AVAILABLE_HOSTS,
  FETCH_AVAILABLE_STAFF,
  FETCH_CLIENT_CANCELLATIONS,
  FETCH_JOKER_AVAILABLE_STAFF,
  FETCH_JOKER_REQUESTS,
  RESERVE_BOOKING,
  UPDATE_APPOINTMENT_HOST,
  UPDATE_BOOKING,
  UPDATE_BOOKING_TIME,
} from "../actions/schedules"
import * as socketEvents from "../sockets/messageTypes"
import * as Api from "../../helpers/api"
import { takeLatest } from "../../helpers/saga"
import toastTexts from "../../config/toasts"

import { onToastError, onToastInfo } from "./../../helpers/toast"

function* reserveBooking({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, id } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.RESERVE_BOOKING,
        body: {
          data,
          token: accessToken,
          id,
        },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* reserveBookingFlow() {
  yield takeLatest(RESERVE_BOOKING.REQUEST, reserveBooking)
}

function* deleteBooking({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const {
    loggedStaffId,
    bookingEventId,
    options: { sendBuyerCancellationEmail, cancellationReason },
  } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.DELETE_BOOKING,
        body: {
          bookingEventId,
          loggedStaffId,
          sendBuyerCancellationEmail,
          cancellationReason,
          token: accessToken,
        },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* deleteBookingFlow() {
  yield takeLatest(DELETE_BOOKING.REQUEST, deleteBooking)
}

function* confirmBooking({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { bookingId, bookingData, scheduleId } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.CONFIRM_BOOKING,
        body: {
          bookingId,
          id: scheduleId,
          token: accessToken,
          bookingData,
        },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* confirmBookingFlow() {
  yield takeLatest(CONFIRM_BOOKING.REQUEST, confirmBooking)
}

function* updateBooking({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { bookingId, bookingData, scheduleId, newClientsIds } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.UPDATE_BOOKING,
        body: {
          bookingId,
          id: scheduleId,
          token: accessToken,
          bookingData,
          newClientsIds,
        },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* updateBookingFlow() {
  yield takeLatest(UPDATE_BOOKING.REQUEST, updateBooking)
}

function* updateBookingTime({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { startTime, endTime, bookingId, scheduleId } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.UPDATE_BOOKING_TIME,
        body: {
          startTime,
          endTime,
          bookingId,
          token: accessToken,
          id: scheduleId,
        },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* updateBookingTimeFlow() {
  yield takeLatest(UPDATE_BOOKING_TIME.REQUEST, updateBookingTime)
}

function* checkNewBookingTime({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { startTime, endTime, bookingId, scheduleId, sessionId, host } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.UPDATE_CONFIRMED_EVENT_TIME,
        body: {
          startTime,
          host,
          endTime,
          bookingId,
          sessionId,
          token: accessToken,
          id: scheduleId,
        },
      },
    })
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (error.response && error.response.data.message && error.response.data.errorType === "custom") {
      customError = error.response.data.message
    }
    onToastError(customError)
  }
}

function* checkNewBookingTimeFlow() {
  yield takeLatest(CHECK_NEW_BOOKING_TIME.REQUEST, checkNewBookingTime)
}

function* fetchAvailableClients({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { user } = state.user
  const { scheduleId, bookingId, sessionId } = payload
  try {
    const response = yield call(Api.fetchAvailableClients, {
      sessionId,
      bookingId,
      token: accessToken,
      id: scheduleId,
      user,
    })

    yield put({
      type: FETCH_AVAILABLE_CLIENTS.SUCCESS,
      payload: response,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_AVAILABLE_CLIENTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchAvailableClientsFlow() {
  yield takeLatest(FETCH_AVAILABLE_CLIENTS.REQUEST, fetchAvailableClients)
}

function* fetchAvailableStaff({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { scheduleId, sessionId, bookingEventId, clients, roomName, meetingType, startTime, endTime, type } = payload
  try {
    const response = yield call(Api.fetchAvailableStaff, {
      clients,
      meetingType,
      roomName,
      sessionId,
      bookingEventId,
      token: accessToken,
      id: scheduleId,
      startTime,
      endTime,
      type,
    })
    const { availableStaff, availableHosts, otherHosts } = response

    if (availableHosts.length < 1 && otherHosts.length < 1) {
      const toastData = toastTexts.noFreelancers
      onToastInfo(toastData)
    }
    yield put({
      type: FETCH_AVAILABLE_STAFF.SUCCESS,
      payload: {
        availableHosts,
        availableStaff,
        otherHosts,
      },
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_AVAILABLE_STAFF.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchAvailableStaffFlow() {
  yield takeLatest(FETCH_AVAILABLE_STAFF.REQUEST, fetchAvailableStaff)
}

function* deleteAppointment({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { _id } = state.user.user
  const {
    options: { sendBuyerCancellationEmail, cancellationReason },
    bookingId,
  } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.CANCEL_MEETING,
        body: {
          bookingId,
          token: accessToken,
          userId: _id,
          sendBuyerCancellationEmail,
          cancellationReason,
        },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* deleteAppointmentFlow() {
  yield takeLatest(DELETE_APPOINTMENT.REQUEST, deleteAppointment)
}

function* arrivedToAppointment({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { sessionId, bookingId } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.SET_ARRIVED_TO_APPOINTMENT,
        body: {
          bookingId,
          sessionId,
          token: accessToken,
        },
      },
    })
  } catch (error) {
    Raven.captureException(error)
  }
}

function* arrivedToAppointmentFlow() {
  yield takeLatest(ARRIVED_TO_APPOINTMENT.REQUEST, arrivedToAppointment)
}

function* delayAppointment({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { bookingId, sessionId, delayTime } = payload

  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.DELAY_APPOINTMENT,
        body: {
          bookingId,
          sessionId,
          token: accessToken,
          delayTime,
        },
      },
    })
  } catch (error) {
    Raven.captureException(error)
    onToastError()
  }
}

function* delayAppointmentFlow() {
  yield takeLatest(DELAY_APPOINTMENT.REQUEST, delayAppointment)
}

function* fetchAvailableHosts({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { bookingId, sessionId, room } = payload
  const offset = moment().utcOffset()
  try {
    const response = yield call(Api.fetchAvailableHosts, {
      sessionId,
      bookingId,
      token: accessToken,
      room,
      offset,
    })

    yield put({
      type: FETCH_AVAILABLE_HOSTS.SUCCESS,
      payload: response,
    })
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (error.response && error.response.data.message && error.response.data.errorType === "custom") {
      customError = error.response.data.message
    }
    Raven.captureException(error)
    onToastError(customError)

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_AVAILABLE_HOSTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchAvailableHostsFlow() {
  yield takeLatest(FETCH_AVAILABLE_HOSTS.REQUEST, fetchAvailableHosts)
}

function* updateAppointmentHost({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { bookingId, sessionId, host } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.REALLOCATE,
        body: {
          bookingId,
          sessionId,
          host,
          token: accessToken,
        },
      },
    })
  } catch (error) {
    Raven.captureException(error)
  }
}

function* updateAppointmentHostFlow() {
  yield takeLatest(UPDATE_APPOINTMENT_HOST.REQUEST, updateAppointmentHost)
}

function* fetchJokerRequests({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { brandId, sessionId } = payload
  try {
    const requests = yield call(Api.fetchJokerRequests, {
      token: accessToken,
      brandId,
      sessionId,
    })

    yield put({
      type: FETCH_JOKER_REQUESTS.SUCCESS,
      payload: requests,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_JOKER_REQUESTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchJokerRequestsFlow() {
  yield takeLatest(FETCH_JOKER_REQUESTS.REQUEST, fetchJokerRequests)
}

function* confirmJokerRequest({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data } = payload
  try {
    const requests = yield call(Api.confirmJokerRequest, {
      token: accessToken,
      data,
    })

    yield put({
      type: CONFIRM_JOKER_REQUEST.SUCCESS,
      payload: requests,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: CONFIRM_JOKER_REQUEST.FAILURE,
      payload: errosMsg,
    })
  }
}

function* confirmJokerRequestFlow() {
  yield takeLatest(CONFIRM_JOKER_REQUEST.REQUEST, confirmJokerRequest)
}

function* declineJokerRequest({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { requestId } = payload
  try {
    const requests = yield call(Api.declineJokerRequest, {
      token: accessToken,
      requestId,
    })

    yield put({
      type: DECLINE_JOKER_REQUEST.SUCCESS,
      payload: requests,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: DECLINE_JOKER_REQUEST.FAILURE,
      payload: errosMsg,
    })
  }
}

function* declineJokerRequestFlow() {
  yield takeLatest(DECLINE_JOKER_REQUEST.REQUEST, declineJokerRequest)
}

function* fetchJokerAvailableStaff({ payload }) {
  const { requestId } = payload

  try {
    const response = yield call(Api.fetchJokerAvailableStaff, {
      requestId,
    })
    const { availableStaff, availableHosts, otherHosts } = response

    yield put({
      type: FETCH_JOKER_AVAILABLE_STAFF.SUCCESS,
      payload: {
        availableHosts,
        availableStaff,
        otherHosts,
      },
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_JOKER_AVAILABLE_STAFF.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchJokerAvailableStaffFlow() {
  yield takeLatest(FETCH_JOKER_AVAILABLE_STAFF.REQUEST, fetchJokerAvailableStaff)
}

function* fetchClientCancellations({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { brandId, sessionId } = payload
  try {
    const requests = yield call(Api.fetchClientCancellation, {
      token: accessToken,
      brandId,
      sessionId,
    })

    yield put({
      type: FETCH_CLIENT_CANCELLATIONS.SUCCESS,
      payload: requests,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_CLIENT_CANCELLATIONS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchClientCancellationsFlow() {
  yield takeLatest(FETCH_CLIENT_CANCELLATIONS.REQUEST, fetchClientCancellations)
}

export default [
  reserveBookingFlow,
  deleteBookingFlow,
  fetchAvailableClientsFlow,
  fetchAvailableStaffFlow,
  confirmBookingFlow,
  updateBookingTimeFlow,
  checkNewBookingTimeFlow,
  updateBookingFlow,
  deleteAppointmentFlow,
  delayAppointmentFlow,
  fetchAvailableHostsFlow,
  updateAppointmentHostFlow,
  arrivedToAppointmentFlow,
  fetchJokerRequestsFlow,
  confirmJokerRequestFlow,
  declineJokerRequestFlow,
  fetchJokerAvailableStaffFlow,
  fetchClientCancellationsFlow,
]

import React, { memo } from "react"

import {
  AddButton,
  Section,
  SectionContent,
  SectionHeader,
  SectionHeaderTitle,
  UnverifiedSigneesWarning,
} from "./Areas.styles"
import TableAreas from "./blocks/TableAreas"

type Props = {
  brandId: string,
  areas: [],
  remainingArea: {},
  canAreasBeModified: boolean,
  canStaffsBeModified: boolean,
  onClickAddArea: Function,
  onChangeArea: Function,
  onChangeRemainingArea: Function,
  onDeleteArea: Function,
  errors?: {},
  excludeCountries: [],
  loading: boolean,
  territoriesOptions: [],
  warningMessage: string,
}

const Areas = ({
  brandId,
  areas,
  remainingArea,
  canAreasBeModified,
  canStaffsBeModified,
  onClickAddArea,
  onDeleteArea,
  excludeCountries,
  territoriesOptions,
  onChangeArea,
  onChangeRemainingArea,
  errors,
  loading,
  warningMessage,
}: Props) => (
  <Section>
    {warningMessage && <UnverifiedSigneesWarning>{warningMessage}</UnverifiedSigneesWarning>}
    <SectionHeader>
      <SectionHeaderTitle>{`Areas${!loading ? ` (${areas.length})` : ""}`}</SectionHeaderTitle>
      {canAreasBeModified && <AddButton onClick={onClickAddArea}>+</AddButton>}
    </SectionHeader>
    <SectionContent>
      <TableAreas
        loading={loading}
        data={areas}
        remainingArea={remainingArea}
        onChangeArea={onChangeArea}
        onChangeRemainingArea={onChangeRemainingArea}
        canAreasBeModified={canAreasBeModified}
        canStaffsBeModified={canStaffsBeModified}
        brandId={brandId}
        excludeCountries={excludeCountries}
        territoriesOptions={territoriesOptions}
        onDeleteArea={onDeleteArea}
        errors={errors}
      />
    </SectionContent>
  </Section>
)

Areas.defaultProps = {
  areas: [],
  errors: {},
}
export default memo(Areas)

import * as R from "ramda"

export const getAuthStatus = R.path(["auth", "isAuthed"])

export const getRequestPasswordPending = R.path(["auth", "requestResetPasswordPending"])
export const getRequestPasswordError = R.path(["auth", "requestResetPasswordError"])

export const getRequestVerificationStatus = R.path(["auth", "requestVerifyTokenPending"])
export const getRequestVerificationError = R.path(["auth", "requestVerifyTokenError"])
export const getTokenIsVerified = R.path(["auth", "tokenIsVerified"])

export const getRequestResetPasswordEmailStatus = R.path(["auth", "requestResetPasswordEmailPending"])
export const getRequestResetPasswordEmailError = R.path(["auth", "requestResetPasswordEmailError"])
export const getEmailSent = R.path(["auth", "emailSent"])

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { fetchClientCancellations, fetchJokerRequests } from "../../../../../store/actions/schedules"
import {
  getActiveSessions,
  getBrand,
  getClientCancellations,
  getJokerRequests,
  getRequestFetchCancellationsError,
  getRequestFetchCancellationsPending,
  getRequestFetchJokerRequestsError,
  getRequestFetchJokerRequestsPending,
  getSelectedSession,
  getSessionOptions,
} from "../../../../../store/selectors"

import Component from "./Notifications"

const selector = createStructuredSelector({
  brand: getBrand,
  requests: getJokerRequests,
  sessions: getActiveSessions,
  selectedSession: getSelectedSession,
  requestFetchPending: getRequestFetchJokerRequestsPending,
  requestFetchError: getRequestFetchJokerRequestsError,
  clientCancellations: getClientCancellations,
  requestFetchCancellationsPending: getRequestFetchCancellationsPending,
  requestFetchCancellationsError: getRequestFetchCancellationsError,
  sessionOptions: getSessionOptions,
})

const actions = {
  onFetchRequests: fetchJokerRequests,
  onFetchCancellations: fetchClientCancellations,
}

export const Notifications = connect(selector, actions)(Component)

import { useMutation } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import { Button } from "modaresa-commons"

import { UPDATE_BUYER_STATUS } from "../../../graphql/mutations/buyer"
import { onToastError, onToastSuccess } from "../../../helpers/toast"
import { DeleteButton } from "../../ui/DeleteButton"
import { stopPropagation } from "../../../helpers/utils"

import ModalConfirmDelete from "./ModalConfirmDelete"

type Props = {
  brandId: string,
  buyer: {
    id: string,
    fullName: string,
  },
  children: string,
  disabled: boolean,
  className: string,
  onDelete: Function,
}

const ButtonDeleteBuyer = ({ className, buyer, brandId, children, disabled, onDelete }: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updateBuyerStatus] = useMutation(UPDATE_BUYER_STATUS, {
    update: (cache, { data: { updateBuyerStatus: updatedBuyer } }) => {
      cache.modify({
        id: cache.identify({ id: brandId }),
        fields: {
          buyers: (buyersRefs, { readField }) =>
            buyersRefs.filter(buyerRef => updatedBuyer.id !== readField("id", buyerRef)),
        },
      })
    },
  })
  const handleToggleModal = useCallback(state => () => setOpenModal(state), [])
  const handleConfirm = useCallback(
    async meetingCancellationReason => {
      setLoading(true)
      try {
        await updateBuyerStatus({
          variables: { id: buyer.id, status: "archived", meetingCancellationReason },
        })
        onToastSuccess(`${buyer.fullName} has been archived successfully`)
        if (onDelete) {
          onDelete()
        }
      } catch (e) {
        console.error(e)
        onToastError(`Error happened while archiving ${buyer.fullName}`)
      }
      setOpenModal(false)
      setLoading(false)
    },
    [onDelete, updateBuyerStatus, buyer.fullName, buyer.id],
  )
  return (
    <span onClick={stopPropagation}>
      {children ? (
        <Button className={className} disabled={disabled} onClick={handleToggleModal(true)}>
          {children}
        </Button>
      ) : (
        <DeleteButton disabled={disabled} onClick={handleToggleModal(true)} />
      )}
      {openModal && (
        <ModalConfirmDelete
          buyerId={buyer.id}
          onClickConfirm={handleConfirm}
          onCloseModal={handleToggleModal(false)}
          loading={loading}
        />
      )}
    </span>
  )
}

export default memo(ButtonDeleteBuyer)

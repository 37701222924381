import React, { memo, useMemo } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { LayoutWithSideMenu } from "modaresa-commons"

import withLoggedUser from "../../hoc/withLoggedUser"
import MyProfile from "../MyProfile"
import CompanyProfile from "../CompanyProfile"

type Props = {
  loggedUser: {
    staff: {
      modaresaRole: string,
    },
  },
}
const RouteSettings = ({ loggedUser }: Props) => {
  const userIsFreelancer = loggedUser.staff.modaresaRole === "freelancer"
  const userIsAgent = loggedUser.staff.modaresaRole === "agent"
  const restrictToStaffIdIfAgent = userIsAgent ? loggedUser.staff.id : undefined

  const menuItems = useMemo(
    () => [
      {
        key: "profile",
        name: "My Profile",
        to: `/settings/profile`,
      },
      {
        key: "company",
        name: "Company Profile",
        to: `/settings/company`,
      },
    ],
    [],
  )
  return (
    <LayoutWithSideMenu menuItems={menuItems} hideSideMenu={userIsFreelancer}>
      <Switch>
        <Route component={MyProfile} exact path={`/settings/profile/:section?`} />
        {!userIsFreelancer && (
          <Route key="companyProfile" path={`/settings/company/:section?`}>
            <CompanyProfile restrictToStaffIdIfAgent={restrictToStaffIdIfAgent} />
          </Route>
        )}
        <Redirect to="/settings/profile" />
      </Switch>
    </LayoutWithSideMenu>
  )
}
export default withLoggedUser(memo(RouteSettings))

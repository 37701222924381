import { gql } from "@apollo/client"

export const FRAGMENT_NOTIFICATION_FULL = gql`
  fragment NotificationFull on Notification {
    active
    frequency
  }
`

const NotificationFragments = {
  fragments: {
    full: FRAGMENT_NOTIFICATION_FULL,
  },
}

export default NotificationFragments

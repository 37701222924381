import { useField } from "formik"
import Select from "react-select"
import styled from "styled-components"
import React, { memo, useCallback } from "react"
import Switch from "react-switch"
import { theme } from "modaresa-commons"

import { notificationFrequencyOptions } from "../../../../config/notifications"
import FormFieldLayout from "../../../elements/FormFieldLayout"

const ContainerFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 38px;
  padding-left: 16px;
`
const ContainerSelect = styled.div`
  width: 100%;
  padding-left: 16px;
`
type Props = {
  labelWidth: number,
  notification: {
    name: string,
    label: string,
    toggleFrequency: string,
  },
}

const FieldNotification = ({ labelWidth, notification, disabled }: Props) => {
  const nameActive = `${notification.name}.active`
  const nameFrequency = `${notification.name}.frequency`
  const [fieldActive, metaActive, helpersActive] = useField(nameActive)
  const [fieldFrequency, metaFrequency, helpersFrequency] = useField(nameFrequency)
  const handleChangeToggle = useCallback(
    toggleValue => {
      helpersActive.setValue(toggleValue)
    },
    [helpersActive],
  )
  const handleChangeSelect = useCallback(value => helpersFrequency.setValue(value), [helpersFrequency])
  return (
    <FormFieldLayout
      label={notification.label}
      name={nameActive}
      labelWidth={labelWidth}
      error={(metaActive.touched || metaFrequency.touched) && (metaActive.error || metaFrequency.error)}
      helpText={notification.helpText}
    >
      <ContainerFields>
        <Switch
          id={nameActive}
          aria-label={`${notification.name} switch`}
          checked={fieldActive.value}
          checkedIcon={false}
          disabled={disabled}
          handleDiameter={18}
          height={24}
          width={48}
          offColor={theme.secondary}
          uncheckedIcon={false}
          onChange={handleChangeToggle}
          onColor={theme.primary}
        />
        <ContainerSelect>
          {notification.toggleFrequency && (
            <Select
              id={nameFrequency}
              options={notificationFrequencyOptions}
              value={fieldFrequency.value}
              onChange={handleChangeSelect}
              onBlur={fieldFrequency.onBlur}
              isDisabled={disabled || !fieldActive.value}
              placeholder="Select frequency"
            />
          )}
        </ContainerSelect>
      </ContainerFields>
    </FormFieldLayout>
  )
}

export default memo(FieldNotification)

import axios from "axios"

import { REST_API_URL } from "../../config"

export const fetchSchedules = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/schedules?brandId=${brandId}`, config)

  return response.data
}

export const fetchSessionSchedule = async ({ sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/schedules/?sessionId=${sessionId}`, config)

  return response.data[0]
}

export const reserveBooking = async ({ data, id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/schedules/${id}/reserve`, data, config)

  return response.data
}

export const updateBooking = async ({ bookingId, token, id, bookingData }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(`${REST_API_URL}/schedules/${id}/booking/${bookingId}`, { bookingData }, config)

  return response.data
}

export const deleteBooking = async ({ bookingId, id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.delete(`${REST_API_URL}/schedules/${id}/booking/${bookingId}`, config)

  return response.data
}

export const confirmBooking = async ({ bookingId, token, id, bookingData }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(
    `${REST_API_URL}/schedules/${id}/booking/${bookingId}/confirm`,
    { bookingData },
    config,
  )

  return response.data
}

export const fetchAvailableClients = async ({ id, token, sessionId, bookingId, user }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      bookingId,
      sessionId,
    },
  }

  if (user.modaresaRole === "agent") {
    config.params.agentId = user._id
  }

  const response = await axios.get(`${REST_API_URL}/schedules/${id}/clients`, config)

  return response.data
}
export const fetchAvailableStaff = async ({
  id,
  token,
  clients,
  sessionId,
  bookingEventId,
  roomName,
  meetingType,
  startTime,
  endTime,
  type,
}) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      clients: clients.join(","),
      sessionId,
      bookingEventId,
      roomName,
      meetingType,
      startTime,
      endTime,
    },
  }

  if (type === "joker") {
    config.params.type = "joker"
  }

  const response = await axios.get(`${REST_API_URL}/schedules/${id}/meeting_responsibles`, config)

  return response.data
}

export const checkNewBookingTime = async ({ startTime, endTime, bookingId, sessionId, token, host, id }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      startTime,
      endTime,
      sessionId,
      host,
    },
  }

  const response = await axios.get(`${REST_API_URL}/schedules/${id}/booking/${bookingId}/checktime`, config)

  return response.data
}

export const editNewBookingTime = async ({ startTime, endTime, bookingId, token, id }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      startTime,
      endTime,
    },
  }

  const response = await axios.patch(`${REST_API_URL}/schedules/${id}/booking/${bookingId}/time`, {}, config)

  return response.data
}

export const delayAppointment = async ({ bookingId, sessionId, token, delayTime }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { delayTime },
  }
  const response = await axios.get(`${REST_API_URL}/dashboard/${sessionId}/bookings/${bookingId}/delay`, config)

  return response.data
}

export const deleteAppointment = async ({ bookingId, sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/dashboard/${sessionId}/bookings/${bookingId}/cancel`, config)

  return response.data
}

export const arrivedToAppointment = async ({ bookingId, sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/dashboard/${sessionId}/bookings/${bookingId}/arrived`, config)

  return response.data
}

export const fetchAvailableHosts = async ({ sessionId, bookingId, token, room, offset }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      bookingId,
      sessionId,
      offset,
    },
  }
  if (room !== "all") {
    config.params.room = room
  }
  const response = await axios.get(`${REST_API_URL}/dashboard/hosts`, config)
  return response.data
}

export const updateAppointmentHost = async ({ sessionId, bookingId, host, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      host,
    },
  }
  const response = await axios.get(`${REST_API_URL}/dashboard/${sessionId}/bookings/${bookingId}/reallocate`, config)

  return response.data
}

export const reserveJokerBooking = async ({ data, id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/schedules/${id}/reserve`, data, config)

  return response.data
}

import PropTypes from "prop-types"
import React from "react"
import Switch from "react-switch"

const CustomSwitch = props => {
  const { cheked, onswitch, id, className } = props
  return (
    <Switch
      {...props}
      checked={cheked}
      checkedIcon={false}
      handleDiameter={24}
      height={30}
      offColor="#37475d"
      onChange={cheked => {
        onswitch(cheked, id)
      }}
      onColor="#a60c46" // eslint-disable-line
      uncheckedIcon={false}
      width={60}
      className={className}
    />
  )
}

CustomSwitch.propTypes = {
  cheked: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.any,
  onswitch: PropTypes.func,
}

export { CustomSwitch }

import React, { memo } from "react"
import { Link } from "react-router-dom"
import { AutoSizer } from "react-virtualized"
import { Button, TableVirtualizedSkeleton } from "modaresa-commons"

import DataFilter from "../../elements/DataFilter/DataFilter"

import staffSearch, { staffSearchMatchKeys } from "./search"
import { Container, FilterContainer, TableContainer } from "./StaffListView.styles"

type Props = {}

const StaffListSkeleton = ({ isAdmin }: Props) => (
  <Container>
    <FilterContainer>
      <DataFilter
        data={[]}
        dataKeysToSearch={staffSearchMatchKeys}
        searchObj={staffSearch}
        onChange={() => undefined}
        placeholder="Search by fullname, position, office, role, market, editor rights"
      />
      {isAdmin && (
        <Link to="/staff/new">
          <Button>+ Add Staff</Button>
        </Link>
      )}
    </FilterContainer>
    <TableContainer>
      <AutoSizer>
        {({ width, height }) => <TableVirtualizedSkeleton width={width} height={height} nbColumns={6} nbRows={20} />}
      </AutoSizer>
    </TableContainer>
  </Container>
)

export default memo(StaffListSkeleton)

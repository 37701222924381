import React, { useCallback, useState, memo, useMemo } from "react"

import AlgorithmView from "./AlgorithmView"

type Props = {
  algorithms: {},
  onSubmit: Function,
  readOnly: boolean,
  session: {
    status: string,
  },
  onClickPrev: Function,
  onDeleteSession: Function,
}

const Algorithm = ({
  algorithms: defaultAlgorithms,
  onSubmit,
  readOnly,
  session,
  onClickPrev,
  onDeleteSession,
}: Props) => {
  const [algorithms, setAlgorithms] = useState(
    defaultAlgorithms || {
      room: false,
      geo: false,
      lang: false,
      jokerEx: false,
      buttonText: "Next",
    },
  )
  const [buttonText, setButtonText] = useState("Next")
  const handleSubmit = useCallback(() => onSubmit(algorithms), [algorithms, onSubmit])
  const handleChangeAlgo = useCallback(
    (algoField, value) => {
      setAlgorithms({
        ...algorithms,
        [algoField]: value,
      })
      setButtonText("Save Changes")
    },
    [algorithms],
  )

  const label = useMemo(() => (session.status === "active" ? buttonText : "Save changes"), [buttonText, session.status])
  return (
    <AlgorithmView
      isRoomBasedAreas={session.toggleRoomBasedAreas}
      readOnly={readOnly}
      buttonText={label}
      onSubmit={handleSubmit}
      onChangeAlgo={handleChangeAlgo}
      algorithms={algorithms}
      onClickPrev={onClickPrev}
      onDeleteSession={onDeleteSession}
    />
  )
}

export default memo(Algorithm)

import React, { memo, useCallback, useMemo, useState, useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"

import { BRAND_BUYERS } from "../../../../../graphql/queries/brand"

import BuyersListView from "./BuyersListView"
import buyerSearch from "./search"
import { MargedErrorLivechat, MargedLoading } from "./BuyersListView.styles"

type Props = {
  brandId: string,
  restrictToStaffId: string,
  retailerStatus: string,
  status?: string,
  onRenderActions: Function,
  withPagination?: boolean,
  withFilterAutocomplete?: boolean,
}

const PAGE_LIMIT = 20
const PAGE_SCROLL_SIZE = 0.3

const BuyersList = ({
  brandId,
  restrictToStaffId,
  status,
  retailerStatus,
  onRenderActions,
  withPagination,
  withFilterAutocomplete,
}: Props) => {
  const [filteringOptions, setFilteringOptions] = useState([])
  const [buyersPage, setBuyersPage] = useState(0)
  const [listBuyers, setListBuyers] = useState([])
  const [searchString, setSearchString] = useState({ text: "", market: "", priorityGroup: "" })
  const [searchStringInUse, setSearchStringInUse] = useState()

  const history = useHistory()
  const handleRowClick = useCallback(({ rowData: { id } }) => history.push(`/clients/${id}`), [history])
  const handleChangeFilter = useCallback(options => setFilteringOptions(options || []), [])

  const [getBuyers, { loading, error, data }] = useLazyQuery(BRAND_BUYERS)

  const loadBuyers = (newPage, newString = null) => {
    if (!loading) {
      getBuyers({
        variables: {
          id: brandId,
          status,
          restrictToStaffId,
          buyersPage: newPage || 0,
          buyersLimit: withPagination === true ? PAGE_LIMIT : null,
          buyersSearch: newString,
        },
      })
      if (newPage) {
        setBuyersPage(newPage)
      }
      if (newString) {
        setSearchStringInUse(newString)
      }
      if (!newPage || newPage === 0) {
        setListBuyers([])
      }
    }
  }

  // used in case the autocomplete filter is disabled
  const handleOnChangeSearch = useCallback((searchStr, type) => {
    setSearchString(searchStr)
    if (type !== "TEXT") {
      loadBuyers(0, searchStr)
    }
  }, [])

  const hasMorePage = () => {
    if (data) {
      const { Brand } = data
      const { buyers } = Brand || {}
      if (buyers) {
        return buyers.length === PAGE_LIMIT
      }
    }
    return false
  }

  const updateListBuyers = () => {
    if (data) {
      const {
        Brand: { buyers },
      } = data
      if (buyers) {
        setListBuyers([...listBuyers, ...buyers])
      }
    }
  }

  useEffect(() => {
    loadBuyers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading) {
      updateListBuyers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  const buyers = useMemo(
    () => (retailerStatus ? listBuyers.filter(buyer => buyer.retailer?.status === retailerStatus) : listBuyers),
    [listBuyers, retailerStatus],
  )

  const filteredBuyers = useMemo(() => {
    let newFilteredBuyers = [...buyers]
    filteringOptions.forEach(opt => {
      newFilteredBuyers = buyerSearch[opt.key].filter(newFilteredBuyers, opt.value)
    })
    return newFilteredBuyers
  }, [buyers, filteringOptions])

  // used in case the autocomplete filter is disabled
  const handleOnClickSearch = () => {
    loadBuyers(0, searchString)
  }

  const handleOnScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    if (scrollTop > 0) {
      const page = buyersPage
      const pageSize = scrollHeight - clientHeight
      const limit = pageSize - pageSize * PAGE_SCROLL_SIZE
      if (scrollTop > limit && withPagination === true) {
        if (hasMorePage()) {
          loadBuyers(page + 1, searchStringInUse)
        }
      }
    }
  }

  if (loading && listBuyers.length <= 0) return <MargedLoading />

  if (error) {
    return (
      <div>
        <MargedErrorLivechat technicalError={error}>Error while loading buyers</MargedErrorLivechat>
      </div>
    )
  }

  return (
    <BuyersListView
      buyers={buyers}
      filteredBuyers={filteredBuyers}
      onRowClick={handleRowClick}
      onChangeFilter={handleChangeFilter}
      onRenderActions={onRenderActions}
      onScroll={handleOnScroll}
      withFilterAutocomplete={withFilterAutocomplete}
      onClickSearch={handleOnClickSearch}
      onChangeSearch={handleOnChangeSearch}
      searchString={searchString}
    />
  )
}

BuyersList.defaultProps = {
  status: "active",
}
export default memo(BuyersList)

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { getStaffCalendarSchedule } from "../../../../../store/selectors"

import Component from "./StaffCalendar"

const selector = createStructuredSelector({
  staffCalendarSchedule: getStaffCalendarSchedule,
})

const actions = {}

export const StaffCalendar = connect(selector, actions)(Component)

import React, { Component } from "react"
import NumberFormat from "react-number-format"
import PropTypes from "prop-types"
import Select from "react-select"
import { SyncLoader } from "react-spinners"
import XLSX from "xlsx"
import { isEmpty } from "lodash"
import moment from "moment-timezone"
import { Button, Flex, InputWithValidation } from "modaresa-commons"

import { meetingApproachValues } from "../../../../../../config/session.js"
import { meetingTypeLookup } from "../../../../Dashboard/config.js"
import { Multiselect, Textarea } from "../../../../../ui"
import { Bold, Container, Deadline, Divinder, Form, FormRow, Header, Loader, MeetingStaff, NotFound } from "../styles"

import { getUrlParameter } from "helpers/urls"
import { getAllNames } from "helpers/reports"
import { countries } from "config/countries"

const orderStatusOptions = [
  { value: "selection", label: "Selection" },
  { value: "completed", label: "Completed" },
  { value: "toConfirm", label: "To Confirm" },
]

class MeetingReport extends Component {
  constructor(props) {
    super(props)
    const { report } = this.props
    this.bookingId = null
    this.state = {
      values: {
        strengths: (report && report.strengths) || "",
        weaknesses: (report && report.weaknesses) || "",
        otb: (report && report.otb) || "",
        finalBudget: (report && report.finalBudget) || "",
        bestseller: (report && report.bestseller) || "",
        sellThrough: (report && report.sellThrough) || "",
        weakestLink: (report && report.weakestLink) || "",
        otherComments: (report && report.otherComments) || "",
        orderStatus: (report && report.orderStatus) || "",
        season: (report && report.season) || "",
      },
      reportId: (report && report._id) || null,
      errors: {},
      touched: {},
      currentBookingId: null,
      isChanged: false,
      savingRequest: false,
      selected: false,
      isNew: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { report, requestPending, requestError } = nextProps
    const { reportId, savingRequest, isNew } = prevState
    if (report === null) return null
    if (report && reportId !== report._id) {
      return {
        values: {
          strengths: (report && report.strengths) || "",
          weaknesses: (report && report.weaknesses) || "",
          otb: (report && report.otb) || "",
          finalBudget: (report && report.finalBudget) || "",
          bestseller: (report && report.bestseller) || "",
          sellThrough: (report && report.sellThrough) || "",
          weakestLink: (report && report.weakestLink) || "",
          otherComments: (report && report.otherComments) || "",
          orderStatus: (report && report.orderStatus) || "",
          season: (report && report.season) || "",
        },
        errors: {},
        touched: {},
        reportId: report._id,
        isNew: false,
      }
    }
    if (typeof report === "string" && !isNew) {
      return {
        values: {
          strengths: (report && report.strengths) || "",
          weaknesses: (report && report.weaknesses) || "",
          otb: (report && report.otb) || "",
          finalBudget: (report && report.finalBudget) || "",
          bestseller: (report && report.bestseller) || "",
          sellThrough: (report && report.sellThrough) || "",
          weakestLink: (report && report.weakestLink) || "",
          otherComments: (report && report.otherComments) || "",
          orderStatus: (report && report.orderStatus) || "",
          season: (report && report.season) || "",
        },
        errors: {},
        touched: {},
        reportId: null,
        isNew: true,
      }
    }

    if (savingRequest && !requestPending && !requestError) {
      return { savingRequest: false }
    }
    return null
  }

  componentDidMount() {
    const bookingId = getUrlParameter("booking")
    const { currentBookingId } = this.state
    const { match, onFetchReport, onFetchClientBookings, history, onCreateReport } = this.props
    onFetchClientBookings(match.params.id)
    if (match.params.sectionId) {
      onFetchReport(match.params.sectionId)
    } else {
      onCreateReport()
    }

    if (bookingId && !currentBookingId) {
      history.replace(`/clients/${match.params.id}/meetings-reports`)
      this.setState({ currentBookingId: bookingId })
    }
  }

  componentDidUpdate() {
    const { reportRequestPending, bookingsRequestPending, report, clientBookings } = this.props
    const { currentBookingId } = this.state
    if (!reportRequestPending && !bookingsRequestPending && !report && !currentBookingId && clientBookings.length > 0) {
      this.fetchReport(clientBookings[0])
    }
  }

  componentWillUnmount = () => {
    const { unselectReport, onLeaveSocketRoom, match } = this.props
    unselectReport()
    onLeaveSocketRoom({ room: match.params.id, type: "client_bookings" })
  }

  validate = (value, name) => {
    const { values } = this.state
    switch (name) {
      case "strengths":
      case "weaknesses":
      case "otb":
      case "finalBudget":
      case "bestseller":
      case "sellThrough":
      case "orderStatus":
      case "weakestLink":
      case "otherComments":
      case "season":
        return {
          values: { ...values, [name]: value },
        }
      default:
        break
    }
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onSelectChange = value => {
    const { clientBookings, onFetchReport, history, onCreateReport } = this.props
    const currentBooking = clientBookings.find(booking => booking._id === value.value)
    const clientId = currentBooking.clients[0]
    if (currentBooking.report) {
      onFetchReport(currentBooking.report)
      history.replace(`/clients/${clientId}/meetings-reports/${currentBooking.report}`)
      this.setState({ currentBookingId: value.value })
    } else {
      onCreateReport()
      history.replace(`/clients/${clientId}/meetings-reports`)
      this.setState({ currentBookingId: value.value, isNew: false })
    }
  }

  isDisable = () => {
    const { savingRequest, isChanged } = this.state
    const { requestPending, requestError } = this.props

    if (savingRequest) {
      return !(!requestPending && requestError)
    }
    return !isChanged
  }

  onBlur = e => {
    const { touched, isChanged } = this.state
    const name = e.target.name
    touched[name] = true

    this.setState({ touched }, () => {
      if (isChanged && !this.isDisable()) {
        this.onSave()
      }
    })
  }

  onSave(history) {
    const { values, errors, touched, reportId, currentBookingId } = this.state
    const { report, onUpdateReport, addReport, clientBookings } = this.props
    const currentBooking =
      (currentBookingId && clientBookings.find(booking => booking._id === currentBookingId)) || clientBookings[0]
    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }
    this.setState({ touched })
    if (isEmpty(errors)) {
      this.setState({ isChanged: false, savingRequest: true })
      if (reportId) {
        onUpdateReport({ ...report, ...values }, report._id, history)
      } else {
        addReport(
          {
            ...values,
            booking: currentBooking._id,
            session: currentBooking.session._id,
          },
          history,
        )
      }
    }
  }

  submitForm = e => {
    e.preventDefault()
    this.onSave()
  }

  onMoneyInput = (e, name) => {
    const value = e.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onChange = (value, name) => {
    const { touched, values } = this.state
    touched[name] = true
    this.setState({
      values: { ...values, [name]: value.value },
      touched,
      isChanged: true,
    })
  }

  onCheckOutPress = () => {
    const { currentBookingId } = this.state
    const { onCheckOut, onCheckIn, clientBookings, report, history } = this.props
    let currentBooking
    if (report) {
      currentBooking = clientBookings.find(booking => booking._id === report.booking)
    } else {
      currentBooking =
        (currentBookingId && clientBookings.find(booking => booking._id === currentBookingId)) || clientBookings[0]
    }
    if (currentBooking.hostStatus === "checkedIn") {
      const hostId = currentBooking.host._id || currentBooking.host
      onCheckOut(currentBooking._id, currentBooking.session._id, history, hostId)
    } else if (currentBooking.appointmentStatus !== "arrived") {
      onCheckIn(currentBooking._id, currentBooking.session._id)
    }
  }

  onExportSelected = () => {
    const { clientBookings, report, retailer, staff, clients } = this.props
    const { reportId } = this.state
    if (reportId) {
      const bookings = clientBookings.find(booking => booking.report === reportId)
      const aoo = []
      const { host } = bookings
      aoo.push({
        Appointment: 1,
        "Sales Sessions Name": bookings.session.name,
        "Meeting Host": host ? `${host.firstName} ${host.lastName}` : "",
        "Meeting Responsible": getAllNames(bookings.meetingResp, staff.byId),
        Retailer: retailer.name,
        "Buyer Full Name": getAllNames(bookings.clients, clients.byId),
        City: retailer.storeAddress.city,
        Country: countries[retailer.storeAddress.country].name,
        Strengths: report.strengths,
        Weaknesses: report.weaknesses,
        OTB: report.otb,
        "Final Budget": report.finalBudget,
        "Budget Reach Rate": report.sellThrough,
        "Order Status": report.orderStatus,
        Comments: report.otherComments,
        Delayed: bookings.delayTime ? "Yes" : "No",
      })

      const ws = XLSX.utils.json_to_sheet(aoo)
      const wscols = [
        { wch: 12 },
        { wch: 20 },
        { wch: 18 },
        { wch: 19 },
        { wch: 17 },
        { wch: 16 },
        { wch: 16 },
        { wch: 15 },
        { wch: 18 },
        { wch: 18 },
        { wch: 12 },
        { wch: 12 },
        { wch: 16 },
        { wch: 11 },
        { wch: 16 },
      ]
      ws["!cols"] = wscols
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, `Reports`)
      XLSX.writeFile(wb, `${moment().format("DD/MM/YYYY")}-${bookings.session.name}.xlsx`)
    }
  }

  static getCheckoutButtonParams(currentBooking) {
    let bgColor = "#4a4a4a"
    let text = "Check-In"
    let disabled = false
    if (currentBooking.hostStatus === "checkedOut") {
      text = "Checked-Out"
      disabled = true
    } else if (currentBooking.appointmentStatus !== "arrived") {
      text = "Check-In"
      bgColor = "#45cea2"
    } else if (currentBooking.hostStatus === "checkedIn") {
      text = "Check-Out"
    }
    return { bgColor, text, disabled }
  }

  fetchReport = appointment => {
    const { onFetchReport, history, onCreateReport } = this.props
    const clientId = appointment.clients[0]
    if (appointment.report) {
      this.setState({ currentBookingId: appointment._id })
      onFetchReport(appointment.report)
      history.replace(`/clients/${clientId}/meetings-reports/${appointment.report}`)
    } else {
      this.setState({ currentBookingId: appointment._id, isNew: false })
      onCreateReport()
    }
  }

  goToCalendar = (hostId, sessionId) => {
    const { history } = this.props
    const { isChanged } = this.state
    if (isChanged) {
      this.onSave(history)
    } else {
      history.replace(`/calendars?staffId=${hostId}&sessionId=${sessionId}`)
    }
  }

  triggerAutoSave = e => {
    if (this.isDisable()) {
      return null
    }
  }

  renderForm = currentBooking => {
    const { report } = this.props
    const { values, errors, touched } = this.state
    const bookingHost = currentBooking && currentBooking.host
    const bookingRespo = (currentBooking && currentBooking.meetingResp && currentBooking.meetingResp[0]) || {}
    const { bgColor, text, disabled: checkOutDisabled } = MeetingReport.getCheckoutButtonParams(currentBooking)
    return (
      <React.Fragment>
        <Form style={{ paddingTop: "25px", width: "50%" }} onSubmit={this.submitForm}>
          <Divinder>In-Store Season</Divinder>
          <FormRow style={{ width: "100%" }}>
            <span>Season</span>
            <InputWithValidation
              errors={errors.season}
              name="season"
              touched={touched.season}
              value={values.season}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow style={{ width: "100%" }}>
            <span>Sell Through (%)</span>
            <InputWithValidation
              errors={errors.sellThrough}
              name="sellThrough"
              touched={touched.sellThrough}
              value={values.sellThrough}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow style={{ width: "100%" }}>
            <span>Bestseller</span>
            <InputWithValidation
              errors={errors.bestseller}
              name="bestseller"
              touched={touched.bestseller}
              value={values.bestseller}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow style={{ width: "100%" }}>
            <span>Weakest Link</span>
            <InputWithValidation
              errors={errors.weakestLink}
              name="weakestLink"
              touched={touched.weakestLink}
              value={values.weakestLink}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow style={{ alignItems: "flex-start", width: "100%" }}>
            <span style={{ paddingTop: "10px" }}>Comments</span>
            <Textarea
              errors={errors.otherComments}
              name="otherComments"
              touched={touched.otherComments}
              value={values.otherComments}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <Divinder>Current Showroom Collection</Divinder>
          <FormRow style={{ alignItems: "flex-start", width: "100%" }}>
            <span style={{ paddingTop: "10px" }}>Strengths</span>
            <Textarea
              errors={errors.strengths}
              name="strengths"
              touched={touched.strengths}
              value={values.strengths}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow style={{ alignItems: "flex-start", width: "100%" }}>
            <span style={{ paddingTop: "10px" }}>Weaknesses</span>
            <Textarea
              errors={errors.weaknesses}
              name="weaknesses"
              touched={touched.weaknesses}
              value={values.weaknesses}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow style={{ width: "100%" }}>
            <span>OTB</span>
            <NumberFormat
              allowNegative={false}
              customInput={InputWithValidation}
              decimalSeparator=","
              errors={errors.otb}
              name="otb"
              prefix={"€"}
              thousandSeparator="."
              touched={touched.otb}
              value={values.otb}
              onBlur={this.onBlur}
              onValueChange={e => this.onMoneyInput(e, "otb")}
            />
          </FormRow>
          <FormRow style={{ width: "100%" }}>
            <span>Final Budget</span>
            <NumberFormat
              allowNegative={false}
              customInput={InputWithValidation}
              decimalSeparator=","
              errors={errors.finalBudget}
              name="finalBudget"
              prefix={"€"}
              thousandSeparator="."
              touched={touched.finalBudget}
              value={values.finalBudget}
              onBlur={this.onBlur}
              onValueChange={e => this.onMoneyInput(e, "finalBudget")}
            />
          </FormRow>
          <FormRow style={{ width: "100%" }}>
            <span>Order Status</span>
            <Multiselect
              className="BuyerMultiselect"
              errors={errors.orderStatus}
              name="orderStatus"
              options={orderStatusOptions}
              touched={touched.orderStatus}
              value={orderStatusOptions.filter(({ value }) => value === values.orderStatus)}
              onChange={this.onChange}
            />
          </FormRow>
          <FormRow
            style={{
              width: "100%",
              paddingLeft: "140px",
              justifyContent: "space-between",
            }}
          ></FormRow>
        </Form>
        <Flex align="flex-start" column>
          <MeetingStaff>
            <Flex justify="flex-start" style={{ marginBottom: "15px" }}>
              <span style={{ minWidth: "130px", maxWidth: "130px" }}>Meeting Host</span>
              <Bold>
                {bookingHost ? (
                  <Button
                    bgcolor="#4a4a4a"
                    borderColor="#4a4a4a"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "120px",
                    }}
                    title={`${bookingHost.firstName} ${bookingHost.lastName}`}
                    onClick={() => this.goToCalendar(bookingHost._id, currentBooking.session._id)}
                  >
                    {bookingHost.firstName} {bookingHost.lastName}
                  </Button>
                ) : (
                  "No host"
                )}
              </Bold>
            </Flex>
            <Flex justify="flex-start" style={{ marginBottom: "15px" }}>
              <span style={{ minWidth: "130px", maxWidth: "130px" }}>Meeting Responsible</span>
              <Bold>{bookingRespo.firstName + " " + bookingRespo.lastName}</Bold>
            </Flex>
            <Flex justify="flex-start" style={{ marginBottom: "15px" }}>
              <span style={{ minWidth: "130px", maxWidth: "130px" }}>Meeting Type</span>
              <Bold>{meetingTypeLookup[currentBooking.meetingType]}</Bold>
            </Flex>
            <Flex justify="flex-start">
              <span style={{ minWidth: "130px", maxWidth: "130px" }}>Meeting Sub Type</span>
              <Bold>
                {currentBooking.meetingApproach
                  ? meetingApproachValues[currentBooking.meetingApproach]
                  : meetingApproachValues["face-to-face"]}
              </Bold>
            </Flex>
          </MeetingStaff>
          {currentBooking.session.orderDeadline && (
            <Deadline>
              Order deadline:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment.utc(currentBooking.session.orderDeadline, "X").format("dddd, D MMMM YYYY")}
              </span>
            </Deadline>
          )}
          <Button
            borderColor={bgColor}
            bgcolor={bgColor}
            disabled={checkOutDisabled}
            minWidth="48%"
            style={{ whiteSpace: "nowrap", marginTop: "18px", width: "94%" }}
            type="button"
            onClick={this.onCheckOutPress}
          >
            {text}
          </Button>
        </Flex>
      </React.Fragment>
    )
  }

  render() {
    const { currentBookingId, reportId } = this.state
    const { report, clientBookings, reportRequestPending, bookingsRequestPending } = this.props
    const bookingList = clientBookings.map(booking => {
      const retailerName = booking.retailer.name
      const startTime = moment.utc(booking.startTime, "X")
      const endTime = moment.utc(booking.endTime, "X")
      const label = `${retailerName} - ${booking.session.name} - ${booking.room}  -  ${startTime.format(
        "Do MMM YYYY, HH:mm",
      )} - ${endTime.format(" HH:mm")}`
      return {
        value: booking._id,
        label,
        report: booking.report,
      }
    })
    let currentBooking
    if (report === null) return null
    if (report) {
      currentBooking = clientBookings.filter(booking => booking.report === report._id)[0]
    } else if (currentBookingId) {
      currentBooking = clientBookings.filter(booking => booking._id === currentBookingId)[0]
    }
    return (
      <Container style={{ paddingTop: "0", height: "100%" }}>
        <Header>
          <div style={{ width: "50%" }}>
            <Select
              isLoading={bookingsRequestPending}
              options={bookingList}
              value={currentBooking && bookingList.filter(option => option.value === currentBooking._id)}
              onChange={this.onSelectChange}
            />
          </div>
          <div>
            <Button bgcolor="#516CEF" disabled={!reportId} onClick={this.onExportSelected}>
              Export Data
            </Button>
          </div>
        </Header>
        <Flex align="flex-start" style={{ position: "relative", height: "100%" }}>
          {(reportRequestPending || bookingsRequestPending) && (
            <Loader style={{ zIndex: 2 }}>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </Loader>
          )}
          {!currentBooking && clientBookings.length > 0 && (
            <NotFound style={{ width: "100%" }}>
              <span>Select appointment</span>
            </NotFound>
          )}
          {!currentBooking && clientBookings.length === 0 && (
            <NotFound style={{ width: "100%" }}>
              <span>No appointments have ever been taken for this buyer, hence there is nothing to report!</span>
            </NotFound>
          )}
          {!reportRequestPending && currentBooking && this.renderForm(currentBooking)}
        </Flex>
      </Container>
    )
  }
}

MeetingReport.propTypes = {
  addReport: PropTypes.func,
  bookingsRequestPending: PropTypes.bool,
  clientBookings: PropTypes.array,
  clients: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  report: PropTypes.any,
  reportRequestPending: PropTypes.bool,
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  retailer: PropTypes.object,
  search: PropTypes.string,
  staff: PropTypes.object,
  unselectReport: PropTypes.func,
  onCheckIn: PropTypes.func,
  onCheckOut: PropTypes.func,
  onCreateReport: PropTypes.func,
  onFetchClientBookings: PropTypes.func,
  onFetchReport: PropTypes.func,
  onLeaveSocketRoom: PropTypes.func,
  onUpdateReport: PropTypes.func,
}

export { MeetingReport }

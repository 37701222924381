import { useField } from "formik"
import moment from "moment"
import React, { useCallback, memo } from "react"
import DatePicker from "react-datepicker"

import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

type Props = {
  label: string,
  labelWidth: string,
  name: string,
  direction?: "row" | "column",
  onChange: Function,
  disabled: boolean,
  placeholder?: string,
  minDate: string,
}

const FormikDatePicker = ({ onChange, label, labelWidth, name, direction, disabled, minDate, placeholder }: Props) => {
  const [field, meta, helpers] = useField(name)
  const handleChange = useCallback(
    value => {
      helpers.setValue(value)
      if (onChange) {
        onChange(value)
      }
    },
    [onChange, helpers],
  )
  const handleBlur = useCallback(() => helpers.setTouched(true), [helpers])
  return (
    <FormFieldLayout
      label={label}
      labelWidth={labelWidth}
      name={name}
      direction={direction}
      error={meta.touched && meta.error}
    >
      <DatePicker
        dateFormat="DD/MM/YYYY"
        minDate={minDate || moment()}
        placeholderText={placeholder}
        selected={field.value}
        utcOffset={0}
        withPortal
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
      />
    </FormFieldLayout>
  )
}

FormikDatePicker.defaultProps = {
  direction: "row",
  placeholder: "Select a date",
}
export default memo(FormikDatePicker)

import styled from "styled-components"
import { AlertWarning } from "modaresa-commons"

export const Container = styled.div`
  padding: 20px 0 20px 0;

  & .react-tagsinput-tag {
    color: #fff;
    background-color: #516bf0;
    border-color: #516bf0;
  }

  & .react-tagsinput-remove {
    color: #fff;
  }
`

export const Row = styled.div`
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || "space-between"};
  align-items: center;
`

export const Section = styled.div`
  padding: 20px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.gray75};
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const SectionHeaderTitle = styled.h5`
  font-size: 15px;
  font-weight: 500;
  color: ${p => p.theme.black};
  margin: 0;
`

export const SectionContent = styled.div`
  margin-top: 10px;
`
export const AddButton = styled.span`
  color: ${props => props.theme.primary};
  margin-left: 16px;
  cursor: pointer;

  font-size: 30px;
  line-height: 16px;
`
export const DropdownMenu = styled.div`
  background-color: #fff;
  min-width: 200px;
  text-decoration: none;
`
export const DropdownMenuItem = styled.div`
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.gray75};
    text-decoration: none;
  }

  & a {
    color: ${props => props.theme.black};
  }
`

export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
`

export const Col = styled.div`
  width: 100%;
  padding-left: 15px;
  max-width: 250px;
`

export const ErrorText = styled.div`
  color: ${props => props.theme.primary};
`
export const InputContainer = styled.div`
  padding-right: 15px;
  & > input {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 40px;
    padding-left: 15px;
    padding-right: 10px;
  }
`

export const TerritoriesDefinition = styled.span`
  font-weight: 400;
`

export const ThIcon = styled.th`
  text-align: center;
  & svg {
    font-size: 24px;
  }
`

export const Empty = styled.div``

export const UnverifiedSigneesWarning = styled(AlertWarning)`
  margin: 0 0 16px 0;
`

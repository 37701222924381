import React, { Component } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import debounce from "lodash/debounce"
import { Flex } from "modaresa-commons"

import { countries } from "../../../../../config/countries"
import { uniqueList } from "../../../../../helpers/filter"

import { FilterContainer } from "./styles"

const Options = [
  {
    value: "name",
    label: "Retailer",
  },
  { value: "origin", label: "Origin" },
  { value: "buyer", label: "Buyer" },
  { value: "priorityGroup", label: "Priority Group" },
  { value: "meetingApproach", label: "Meeting Format" },
]

const PriorityGroupsOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
]

const MeetingApproachOptions = [
  { value: "face-to-face", label: "Face to Face" },
  { value: "video-conference", label: "Virtual" },
]
export class Filter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filterType: this.props.filterType || "",
      filterValue: this.props.filterValue || "",
      options: [],
    }
  }

  componentWillUnmount() {
    this.props.filter({ filterType: null, filterValue: null })
  }

  filter = value => {
    const { retailers, clients } = this.props
    const { filterType } = this.state
    let options = []
    if (!value) {
      this.setState({ options })
      return
    }

    if (filterType === "name") {
      for (const key in retailers.byId) {
        const name = retailers.byId[key].name
        if (name.toLowerCase().indexOf(value) === 0) {
          options.push({
            value: retailers.byId[key],
            label: name,
          })
        }
      }
    } else if (filterType === "priorityGroup") {
      for (const key in retailers.byId) {
        const group = retailers.byId[key].priorityGroup
        if (+value === group) {
          options.push({
            value: group,
            label: group,
          })

          options = uniqueList(options)
        }
      }
    } else if (filterType === "origin") {
      for (const key in retailers.byId) {
        const country = retailers.byId[key].storeAddress.country
        if (countries[country] && countries[country].name.toLowerCase().indexOf(value) === 0) {
          options.push({
            value: retailers.byId[key],
            label: countries[country].name,
          })

          options = uniqueList(options)
        }
      }
    } else if (filterType === "buyer") {
      for (const key in clients.byId) {
        const firstName = clients.byId[key].firstName

        if (firstName.toLowerCase().indexOf(value) === 0) {
          options.push({
            value: `${firstName.toLowerCase()}`,
            label: `${firstName}`,
          })

          options = uniqueList(options)
        }
      }
    }

    this.setState({ options })
  }

  onChange = (value, name) => {
    this.setState({ [name]: "", options: [] })
    const { filterType } = this.state
    if (value) {
      if (name === "filterType") {
        this.setState({ [name]: value.value })
      } else {
        let filterBy
        if (filterType === "name") {
          filterBy = value.value.name
        } else if (filterType === "origin") {
          filterBy = value.value.storeAddress.country
        } else if (filterType === "buyer") {
          filterBy = value.value
        } else if (filterType === "priorityGroup") {
          filterBy = value.value
        } else if (filterType === "meetingApproach") {
          filterBy = value.value
        }
        this.props.filter({ filterType, filterValue: filterBy })
        this.setState({ [name]: value.value, options: [value] })
      }
    } else {
      this.props.filter({ filterType: null, filterValue: null })
      this.setState({ [name]: "", options: [] })
    }
  }

  onInputChange = (value, action) => {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.filter(value.toLowerCase())
    }
  }

  deboncedFilter = debounce(this.filter, 200, {
    leading: false,
    trailing: true,
  })

  render() {
    const { filterType, filterValue, options } = this.state
    return (
      <Flex align="flex-end" column>
        <FilterContainer>
          <Select
            className="filterType"
            classNamePrefix="filterType"
            isClearable
            options={Options}
            placeholder="Filter By"
            value={Options.filter(({ value }) => value === filterType)}
            onChange={value => this.onChange(value, "filterType")}
          />
          <Select
            className="filter"
            classNamePrefix="filter"
            isClearable
            isDisabled={!filterType}
            isSearchable={filterType !== "priorityGroup" || filterType !== "meetingApproach"}
            options={
              filterType === "priorityGroup"
                ? PriorityGroupsOptions
                : filterType === "meetingApproach"
                ? MeetingApproachOptions
                : options
            }
            placeholder="Filter..."
            value={options.filter(({ value }) => value === filterValue)}
            onChange={value => this.onChange(value, "filterValue")}
            onInputChange={this.onInputChange}
          />
        </FilterContainer>
      </Flex>
    )
  }
}

Filter.propTypes = {
  clients: PropTypes.object,
  filter: PropTypes.func,
  filterType: PropTypes.string,
  filterValue: PropTypes.any,
  retailers: PropTypes.object,
}

export default Filter

import {
  ADD_CLIENT,
  ADD_REPORT,
  CHECK_IN,
  CHECK_OUT,
  CLEAR_FILER_CLIENTS_BY_STATUS,
  CREATE_REPORT,
  CREATE_UPDATE_REQUEST,
  DELETE_CLIENT,
  EXPORT_REPORTS,
  FETCH_CLIENTS,
  FETCH_CLIENT_BOOKINGS,
  FETCH_REPORT,
  FETCH_REPORTS,
  FILER_CLIENTS_BY_STATUS,
  SELECT_BUYER_BY_ID,
  UNSELECT_BUYER,
  UNSELECT_REPORT,
  UNSELECT_REPORTS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_BOOKING,
  UPDATE_REPORT,
  UPLOAD_CLIENTS,
} from "../actions/clients"
import { createReducer } from "../../helpers/redux"

const initialState = {
  clients: {},
  clientsRequestPending: false,
  clientBookings: [],
  reports: [],
  selectedReport: null,
  selected: null,
  requestPending: false,
  requestError: null,
  filterByStatus: null,
  reportRequestPending: false,
  reportRequestError: null,
  bookingsRequestPending: false,
  bookingsRequestError: null,
  reportsRequestPending: false,
  reportsRequestError: null,
  requestUpdateEmailPending: false,
  requestUpdateEmailError: null,
}

const handlers = {
  [FETCH_CLIENTS.REQUEST]: state => ({
    ...state,
    clientsRequestPending: true,
  }),
  [FETCH_CLIENTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    clientsRequestPending: false,
    clients: payload,
  }),
  [FETCH_CLIENTS.FAILURE]: state => ({
    ...state,
    clientsRequestPending: false,
  }),
  [SELECT_BUYER_BY_ID]: (state, { payload }) => ({
    ...state,
    selected: state.clients.byId[payload.id],
  }),
  [UNSELECT_BUYER]: state => ({
    ...state,
    selected: null,
  }),
  [UPDATE_CLIENT.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_CLIENT.SUCCESS]: (state, { payload }) => {
    const { updatedBuyer } = payload
    return {
      ...state,
      clients: {
        ...state.clients,
        byId: {
          ...state.clients.byId,
          [updatedBuyer._id]: {
            ...updatedBuyer,
          },
        },
      },
      requestPending: false,
    }
  },
  [UPDATE_CLIENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [ADD_CLIENT.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [ADD_CLIENT.SUCCESS]: (state, { payload }) => {
    const { newBuyer } = payload
    return {
      ...state,
      clients: {
        ...state.clients,
        byId: {
          ...state.clients.byId,
          [newBuyer._id]: {
            ...newBuyer,
          },
        },
        allIds: [newBuyer._id, ...state.clients.allIds],
      },
      requestPending: false,
    }
  },
  [ADD_CLIENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FILER_CLIENTS_BY_STATUS]: (state, { payload }) => ({
    ...state,
    filterByStatus: payload.status,
  }),
  [CLEAR_FILER_CLIENTS_BY_STATUS]: state => ({
    ...state,
    filterByStatus: null,
  }),
  [ADD_REPORT.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [ADD_REPORT.SUCCESS]: (state, { payload }) => {
    const index = state.clientBookings.findIndex(booking => booking._id === payload.booking)
    const newClientsBookings = [...state.clientBookings]
    newClientsBookings[index].report = payload._id
    return {
      ...state,
      selectedReport: payload,
      clientBookings: newClientsBookings,
      requestPending: false,
    }
  },
  [ADD_REPORT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FETCH_REPORTS.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [FETCH_REPORTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    reports: payload,
    requestPending: false,
  }),
  [FETCH_REPORTS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [UNSELECT_REPORTS]: state => ({
    ...state,
    reports: [],
  }),
  [FETCH_REPORT.REQUEST]: state => ({
    ...state,
    reportRequestPending: true,
    reportRequestError: null,
  }),
  [FETCH_REPORT.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedReport: payload,
    reportRequestPending: false,
  }),
  [FETCH_REPORT.FAILURE]: (state, { payload }) => ({
    ...state,
    reportRequestPending: false,
    reportRequestError: payload,
  }),
  [UNSELECT_REPORT]: state => ({
    ...state,
    selectedReport: null,
  }),
  [FETCH_CLIENT_BOOKINGS.REQUEST]: state => ({
    ...state,
    bookingsRequestPending: true,
    bookingsRequestError: null,
  }),
  [FETCH_CLIENT_BOOKINGS.SUCCESS]: (state, { payload }) => ({
    ...state,
    clientBookings: payload,
    bookingsRequestPending: false,
  }),
  [FETCH_CLIENT_BOOKINGS.FAILURE]: (state, { payload }) => ({
    ...state,
    bookingsRequestPending: false,
    bookingsRequestError: payload,
  }),
  [UPDATE_REPORT.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_REPORT.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedReport: payload,
    requestPending: false,
  }),
  [UPDATE_REPORT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [CHECK_OUT.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [CHECK_IN.SUCCESS]: (state, { payload }) => {
    const bookingId = state.clientBookings.findIndex(booking => booking._id === payload.booking._id)
    const newClientBookings = [...state.clientBookings]
    newClientBookings[bookingId] = {
      ...state.clientBookings[bookingId],
      ...payload.booking,
    }

    return {
      ...state,
      clientBookings: newClientBookings,
      requestPending: false,
    }
  },
  [CHECK_IN.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [CHECK_IN.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [CHECK_OUT.SUCCESS]: (state, { payload }) => {
    const bookingId = state.clientBookings.findIndex(booking => booking._id === payload.booking._id)
    const newClientBookings = [...state.clientBookings]
    newClientBookings[bookingId] = {
      ...state.clientBookings[bookingId],
      ...payload.booking,
    }

    return {
      ...state,
      clientBookings: newClientBookings,
      requestPending: false,
    }
  },
  [CHECK_OUT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [UPDATE_CLIENT_BOOKING]: (state, { payload }) => {
    const bookingId = state.clientBookings.findIndex(booking => booking._id === payload.booking._id)
    const newClientBookings = [...state.clientBookings]
    if (bookingId >= 0) {
      newClientBookings[bookingId] = {
        ...state.clientBookings[bookingId],
        ...payload.booking,
      }
    }

    return {
      ...state,
      clientBookings: newClientBookings,
      requestPending: false,
    }
  },
  [UPLOAD_CLIENTS.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPLOAD_CLIENTS.SUCCESS]: state => ({
    ...state,
    requestPending: false,
  }),
  [UPLOAD_CLIENTS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [EXPORT_REPORTS.REQUEST]: state => ({
    ...state,
    reportsRequestPending: true,
    reportsRequestError: null,
  }),
  [EXPORT_REPORTS.SUCCESS]: state => ({
    ...state,
    reportsRequestPending: false,
  }),
  [EXPORT_REPORTS.FAILURE]: (state, { payload }) => ({
    ...state,
    reportsRequestPending: false,
    reportsRequestError: payload,
  }),
  [DELETE_CLIENT.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [DELETE_CLIENT.SUCCESS]: (state, { payload }) => {
    const { updatedBuyer } = payload
    const selected = state.selected && state.selected._id === updatedBuyer._id ? updatedBuyer : state.selected
    return {
      ...state,
      clients: {
        ...state.clients,
        byId: {
          ...state.clients.byId,
          [updatedBuyer._id]: {
            ...updatedBuyer,
          },
        },
      },
      selected,
      requestPending: false,
    }
  },
  [DELETE_CLIENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [CREATE_REPORT]: state => ({
    ...state,
    selectedReport: "",
  }),
  [CREATE_UPDATE_REQUEST.REQUEST]: state => ({
    ...state,
    requestUpdateEmailPending: true,
    requestUpdateEmailError: null,
  }),
  [CREATE_UPDATE_REQUEST.SUCCESS]: state => ({
    ...state,
    requestUpdateEmailPending: false,
  }),
  [CREATE_UPDATE_REQUEST.FAILURE]: (state, { payload }) => ({
    ...state,
    requestUpdateEmailPending: false,
    requestUpdateEmailError: payload,
  }),
}

export default createReducer(initialState, handlers)

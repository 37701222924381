/* eslint-disable extra-rules/no-commented-out-code */
import React, { memo, useCallback, useState } from "react"
import moment from "moment-timezone"
import { Button } from "modaresa-commons"
import grey from "@material-ui/core/colors/grey"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined"
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined"
import DateRangeIcon from "@material-ui/icons/DateRange"
import { IconButton } from "@material-ui/core"

import { Footer, FooterLeft, FooterRight } from "../../../../elements/SessionFooter"

import {
  Container,
  Area,
  Type,
  TimeRange,
  Content,
  ContainerRestrict,
  RestrictionBlock,
  RestrictionHeader,
  RestrictionTitle,
  RestrictionActions,
  RestrictRule,
  Description,
} from "./Restrictions.styles"
import RestrictionModal from "./RestrictionModal"

const Restrictions = ({ session, isReadOnly, onClickPrev, onSubmit }) => {
  const [restrictionsList, setRestrictionsList] = useState(session.restrictions ?? [])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [restrictItem, setRestrictItem] = useState(null)

  const handleAddRestriction = () => {
    setRestrictItem(null)
    setIsOpenModal(!isOpenModal)
  }

  const handleSaveRestriction = item => {
    if (item.id) {
      restrictionsList[item.id - 1] = item
      setRestrictionsList([...restrictionsList])
    } else {
      setRestrictionsList([...restrictionsList, { ...item, id: restrictionsList.length + 1 }])
    }
    setIsOpenModal(false)
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const getDateFormatted = DateTimestamp => {
    const dateFormat = moment(DateTimestamp, "X")
      .utc()
      .format("DD/MM/yyyy")

    return (
      <TimeRange>
        <DateRangeIcon style={{ color: grey[600], fontSize: "18px", marginRight: "4px" }} />
        {dateFormat}
      </TimeRange>
    )
  }

  const getTimeFormatted = timestamp => {
    const timeFormat = moment(timestamp, "X")
      .utc()
      .format("HH:mm a")

    return (
      <TimeRange>
        <ScheduleOutlinedIcon style={{ color: grey[600], fontSize: "18px", marginRight: "4px" }} />
        {timeFormat}
      </TimeRange>
    )
  }

  const handleSubmit = useCallback(() => onSubmit(restrictionsList), [restrictionsList, onSubmit])

  const handleUpdateRestriction = index => {
    const item = restrictionsList[index]
    setRestrictItem({ ...item, id: item.id ?? index + 1 })
    setIsOpenModal(true)
  }

  const handleDeleteRestriction = index => {
    setRestrictionsList(restrictionsList.filter((_, i) => i !== index))
  }

  const RestrictionItem = ({ index, id, name, type, areas, retailers, startTime, endTime, startDate, endDate }) => {
    if (!areas) {
      return
    }

    const typeLabel = type === "canBook" ? "can book" : "can not book"
    const areaDisplay = [...areas.map(a => a.label), ...retailers.map(r => r.name)]

    return (
      <RestrictionBlock>
        <RestrictionHeader>
          <LockOutlinedIcon />
          <RestrictionTitle>{name}</RestrictionTitle>
          <RestrictionActions>
            <IconButton
              aria-label="update a restriction"
              onClick={() => handleUpdateRestriction(index)}
              component="span"
            >
              <CreateOutlinedIcon style={{ color: grey[600] }} />
            </IconButton>
            <IconButton
              aria-label="delete a restriction"
              onClick={() => handleDeleteRestriction(index)}
              component="span"
            >
              <DeleteOutlinedIcon style={{ color: grey[600] }} />
            </IconButton>
          </RestrictionActions>
        </RestrictionHeader>
        <RestrictRule>
          Only
          <Area>{areaDisplay.join(", ")}</Area>
          <Type>{typeLabel}</Type>
          in All rooms
        </RestrictRule>
        <RestrictRule>
          From {getTimeFormatted(startTime)} <ArrowForwardOutlinedIcon style={{ color: grey[600], fontSize: "18px" }} />
          {getTimeFormatted(endTime)}
        </RestrictRule>
        <RestrictRule>
          Starting from {getDateFormatted(startDate)} until {getDateFormatted(endDate)}
        </RestrictRule>
      </RestrictionBlock>
    )
  }

  return (
    <Container>
      <Content>
        <h4>Booking Restrictions</h4>
        {!isReadOnly && (
          <Description>
            <p>Do you want to restrict certain clients from being able to book on certain days and time slots?</p>
            <Button type="button" onClick={handleAddRestriction}>
              + Add Restriction
            </Button>
          </Description>
        )}
        {restrictionsList && (
          <ContainerRestrict>
            {restrictionsList.map((item, index) => (
              <RestrictionItem key={`restrict-${index}`} index={index} {...item} />
            ))}
          </ContainerRestrict>
        )}
      </Content>
      {!isReadOnly && (
        <Footer>
          <FooterLeft>
            <Button type="button" onClick={onClickPrev}>
              Previous
            </Button>
          </FooterLeft>
          <FooterRight>
            <Button type="button" onClick={handleSubmit}>
              Save Changes
            </Button>
          </FooterRight>
        </Footer>
      )}
      {isOpenModal && (
        <RestrictionModal
          session={session}
          restrictItem={restrictItem}
          close={handleCloseModal}
          save={handleSaveRestriction}
        />
      )}
    </Container>
  )
}

export default memo(Restrictions)

import { call, put, select } from "redux-saga/effects"

import * as Api from "../../helpers/api"
import { ADD_BRAND, UPDATE_BRAND, UPDATE_COMPETITOR_GROUPS } from "../actions/brand"
import { takeLatest } from "../../helpers/saga"

import { onToastError, onToastSuccess } from "./../../helpers/toast"

function* updateBrand({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, id } = payload
  try {
    const response = yield call(Api.updateBrand, {
      data,
      token: accessToken,
      id,
    })

    yield put({ type: UPDATE_BRAND.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: UPDATE_BRAND.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateBrandFlow() {
  yield takeLatest(UPDATE_BRAND.REQUEST, updateBrand)
}

function* addBrand({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  try {
    const response = yield call(Api.addBrand, {
      data: payload,
      token: accessToken,
    })

    yield put({ type: ADD_BRAND.SUCCESS, payload: response })
    onToastSuccess("Brand successfully added")
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: ADD_BRAND.FAILURE,
      payload: errosMsg,
    })
  }
}

function* addBrandFlow() {
  yield takeLatest(ADD_BRAND.REQUEST, addBrand)
}

function* updateCompetitorGroup({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, id } = payload

  try {
    const response = yield call(Api.updateCompetitorGroup, {
      data,
      token: accessToken,
      id,
    })

    yield put({ type: UPDATE_COMPETITOR_GROUPS.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: UPDATE_COMPETITOR_GROUPS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateCompetitorGroupFlow() {
  yield takeLatest(UPDATE_COMPETITOR_GROUPS.REQUEST, updateCompetitorGroup)
}

export default [updateBrandFlow, updateCompetitorGroupFlow, addBrandFlow]

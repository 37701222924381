import React, { useCallback, memo } from "react"
import { useHistory } from "react-router-dom"
import { ModalConfirmation } from "modaresa-commons"

import { Text } from "../Retailer.styles"

type Props = {}

const ModalRetailerCreated = ({ retailer }: Props) => {
  const history = useHistory()
  const handleOk = useCallback(() => {
    history.push(`/clients/new?retailerId=${retailer?._id}`)
  }, [retailer, history])
  const handleClose = useCallback(() => {
    history.push(`/clients/retailers`)
  }, [history])
  return (
    <ModalConfirmation
      isOpen
      title="ADD NEW BUYER"
      buttonLabelOk="Add new Buyer"
      hideCancel
      onClickOk={handleOk}
      onClose={handleClose}
    >
      <Text>
        <p>Perfect, the new retailer has been added successfully to your database.</p>
        <p>{`Now, let's add a buyer for that retailer. Do you now want to add a new buyer for that retailer?`}</p>
      </Text>
    </ModalConfirmation>
  )
}

export default memo(ModalRetailerCreated)

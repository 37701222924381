import * as yup from "yup"

export const videoTools = [
  {
    title: "WhatsApp Number",
    name: "whatsapp",
    placeholder: "+1...",
    validator: /^\+[0-9]+$/,
    error: "The ID has to be a valid phone number (+1...)",
  },
  {
    title: "FaceTime ID (iPhone/iMac)",
    name: "facetime",
    placeholder: "phone number or e-mail address",
    validator: /^((\+[0-9]+)|([^@]+@[^.]+\..+))$/,
    error: "The ID has to be a phone number or e-mail address",
  },
  // :: Disabled Skype and enabled MS Teams, we dont use skype anymore;
  // {
  //   title: "Skype ID",
  //   name: "skype",
  //   placeholder: "live:xxxxxxxxxx",
  //   validator: /^.+$/,
  //   error: "The ID has to be a valid Skype ID",
  // },
  {
    title: "Zoom ID",
    name: "zoom",
    placeholder: "Your personal Meeting ID link",
    validator: /^https:\/\/.+\pwd=.+$/,
    error: "The ID has to be a valid Zoom URL and include the password in the URL.",
  },
  {
    title: "Google Meet link",
    name: "googlemeet",
    placeholder: "https://meet.google.com/...",
    validator: /^https:\/\/.+$/,
    error: "The ID has to be a valid URL",
  },
  {
    title: "Microsoft Teams link",
    name: "msteams",
    placeholder: "https://teams.microsoft.com/...",
    validator: /^https:\/\/.+$/,
    error: "The link has to be a valid URL",
  },
  {
    title: "WhereBy link",
    name: "whereby",
    placeholder: "https://whereby.com/...",
    validator: /^https:\/\/.+$/,
    error: "The ID has to be a valid URL",
  },
]

export const videoToolsSchema = yup.object().shape(
  videoTools.reduce((schema, tool) => {
    schema[tool.name] = yup.string().matches(tool.validator, tool.error)
    return schema
  }, {}),
)

import React, { memo } from "react"
import { Tabs } from "modaresa-commons"

import RelouRequestsList from "./RelouRequestsList"
import {
  ContainerRelouRequests,
  Title,
  TopRightCloseIcon,
  ContainerReopen,
  IconMeetingRequest,
  FlexTabs,
} from "./RelouRequestsView.styles"

type Props = {
  brandId: string,
  salesSessionId: string,
  minimize: boolean,
}

const tabHeaderStyle = {
  height: "auto",
  minHeight: "auto",
}
const RelouRequestsView = ({ brandId, salesSessionId, minimize, onToggleMinimize }: Props) => (
  <ContainerRelouRequests minimize={minimize}>
    {minimize ? (
      <ContainerReopen onClick={onToggleMinimize}>
        <IconMeetingRequest />
      </ContainerReopen>
    ) : (
      <>
        <TopRightCloseIcon onClick={onToggleMinimize} />
        <Title>Relou Requests</Title>
        <FlexTabs tabHeaderStyle={tabHeaderStyle} tabFullWidth>
          <Tabs.TabPane tabKey="pending" tab="Pending">
            <RelouRequestsList
              salesSessionId={salesSessionId}
              brandId={brandId}
              statuses={["pending"]}
              placeholderEmpty="No pending requests"
            />
          </Tabs.TabPane>
          <Tabs.TabPane tabKey="all" tab="All">
            <RelouRequestsList salesSessionId={salesSessionId} brandId={brandId} />
          </Tabs.TabPane>
        </FlexTabs>
      </>
    )}
  </ContainerRelouRequests>
)

export default memo(RelouRequestsView)

import React, { memo } from "react"

import SalesSessionAreasDefault from "./SalesSessionAreasDefault"
import SalesSessionAreasRoomBased from "./SalesSessionAreasRoomBased"

type Props = {
  salesSessionId: string,
  canEdit: boolean,
  isRoomBasedAreas: boolean,
  onSaveChanges: Function,
}

const SalesSessionAreas = ({ salesSessionId, canEdit, isRoomBasedAreas, onSaveChanges }: Props) =>
  isRoomBasedAreas ? (
    <SalesSessionAreasRoomBased salesSessionId={salesSessionId} canEdit={canEdit} onSaveChanges={onSaveChanges} />
  ) : (
    <SalesSessionAreasDefault salesSessionId={salesSessionId} canEdit={canEdit} onSaveChanges={onSaveChanges} />
  )

export default memo(SalesSessionAreas)

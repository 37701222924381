import { useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import React, { useCallback, useState } from "react"
import { AlertWarning } from "modaresa-commons"

import { UPDATE_SALES_SESSION_STAFF_AVAILABILITIES } from "../../../../graphql/mutations/salesSessionStaff"
import { SALES_SESSION_STAFF_AVAILABILITIES } from "../../../../graphql/queries/salesSessionStaff"
import { onToastError, onToastSuccess } from "../../../../helpers/toast"
import ErrorLiveChatHelp from "../../../ui/ErrorLiveChatHelp"

import FormAvailabilities from "./FormAvailabilities"
import FormAvailabilitiesSkeleton from "./FormAvailabilitiesSkeleton"

type Props = {
  readOnly: boolean,
  salesSessionId: string,
  staffId: string,
  labelWidth: string,
}

const StaffAvailabilities = ({ readOnly, salesSessionId, staffId, labelWidth }: Props) => {
  const [isLoadingSaving, setIsLoadingSaving] = useState(false)
  const [updateSalesSessionStaffAvailabilities] = useMutation(UPDATE_SALES_SESSION_STAFF_AVAILABILITIES)
  const handleSubmit = useCallback(
    async values => {
      setIsLoadingSaving(true)
      const variables = {
        salesSessionId,
        staffId,
        availabilities: values.availabilities.map(day => {
          const momentDate = moment.utc(day.date, "X")
          return {
            available: day.available,
            from: momentDate
              .set({
                hours: day.from.value.hours,
                minutes: day.from.value.minutes,
              })
              .unix(),
            to: momentDate
              .set({
                hours: day.to.value.hours,
                minutes: day.to.value.minutes,
              })
              .unix(),
          }
        }),
      }
      try {
        await updateSalesSessionStaffAvailabilities({
          variables,
        })
        onToastSuccess("Availabilities saved successfully")
      } catch (err) {
        onToastError("An error occured while saving availabilities")
      }
      setIsLoadingSaving(false)
    },
    [salesSessionId, staffId, updateSalesSessionStaffAvailabilities],
  )
  const { loading, error, data } = useQuery(SALES_SESSION_STAFF_AVAILABILITIES, {
    variables: {
      salesSessionId,
      staffId,
    },
  })
  if (error) {
    return <ErrorLiveChatHelp technicalError={error}>Error while loading staff availabilities</ErrorLiveChatHelp>
  }
  if (loading) {
    return <FormAvailabilitiesSkeleton labelWidth={labelWidth} />
  }
  if (!data?.SalesSessionStaff?.availabilities?.length)
    return (
      <AlertWarning margin={`0 0 0 ${labelWidth}`} maxWidth="100%">
        This staff member is not allocated to selected session.
      </AlertWarning>
    )
  const { availabilities } = data.SalesSessionStaff
  return (
    <FormAvailabilities
      key={salesSessionId}
      availabilities={availabilities}
      labelWidth={labelWidth}
      onSubmit={handleSubmit}
      loadingSubmit={isLoadingSaving}
      readOnly={readOnly}
    />
  )
}

export default StaffAvailabilities

import { useFormikContext } from "formik"
import React, { useCallback, memo } from "react"
import moment from "moment"

import { hoursFormat24Options } from "../../../../../helpers/date"
import FormikDateRangePicker from "../../../../elements/FormikFields/FormikDateRangePicker"

type Props = {
  disabled: boolean,
}

const FieldSessionDates = ({ disabled }: Props) => {
  const { setFieldValue } = useFormikContext()
  const handleChangeStart = useCallback(() => setFieldValue("openingHours", []), [setFieldValue])
  const handleChangeEnd = useCallback(
    (closingDay, openingDay) => {
      if (!openingDay || !closingDay) return []

      const newOpeningHours = Array.from(moment.range(openingDay, closingDay).by("days"), day => ({
        date: day,
        available: true,
        from: hoursFormat24Options[16],
        to: hoursFormat24Options[34],
      }))
      setFieldValue("openingHours", newOpeningHours)
    },
    [setFieldValue],
  )
  return (
    <FormikDateRangePicker
      label="Opening day"
      labelWidth="208px"
      nameStart="openingDay"
      nameEnd="closingDay"
      placeholderStart="Opening day"
      placeholderEnd="Closing day"
      disabled={disabled}
      onChangeStart={handleChangeStart}
      onChangeEnd={handleChangeEnd}
    />
  )
}

export default memo(FieldSessionDates)

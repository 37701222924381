import { countries } from "../../../../../config"
import { storeType } from "../../../../../config/retailers"
import { doesStringMatch } from "../../../../../helpers/filter"

const retailerSearch = {
  name: {
    match: (retailerName = "", searchValue) => {
      if (doesStringMatch(retailerName.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: retailerName,
            key: "name",
            label: `${retailerName} (name)`,
          },
        ]
      }
      return null
    },
    filter: (retailers, value) => retailers.filter(retailer => retailer.name === value),
  },
  storeAddress: {
    match: (storeAddress, searchValue) => {
      const searchValueLowercase = searchValue.toLowerCase()
      const matchedOptions = []
      if (storeAddress) {
        const { country, city } = storeAddress
        const countryObj = countries[country]
        if (doesStringMatch(countryObj?.name.toLowerCase(), searchValueLowercase)) {
          matchedOptions.push({
            value: country,
            key: "country",
            label: `${countryObj.name} (country)`,
          })
        }
        if (doesStringMatch(city.toLowerCase(), searchValueLowercase)) {
          matchedOptions.push({
            value: city,
            key: "city",
            label: `${city} (city)`,
          })
        }
      }
      return matchedOptions
    },
  },
  country: {
    filter: (retailers, value) => retailers.filter(retailer => retailer.storeAddress?.country === value),
  },
  city: {
    filter: (retailers, value) => retailers.filter(retailer => retailer.storeAddress?.city === value),
  },
  clientId: {
    match: (clientId, searchValue) => {
      if (doesStringMatch(`client id ${clientId}`, searchValue.toLowerCase())) {
        return [
          {
            value: clientId,
            key: "clientId",
            label: `${clientId} (client id)`,
          },
        ]
      }
    },
    filter: (retailers, value) => retailers.filter(retailer => retailer.clientId === value),
  },
  type: {
    match: (type, searchValue) => {
      const labelStoreType = storeType[type]
      if (labelStoreType && doesStringMatch(labelStoreType.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: type,
            key: "type",
            label: `${labelStoreType} (type)`,
          },
        ]
      }
    },
    filter: (retailers, value) => retailers.filter(retailer => retailer.type === value),
  },
  status: {
    match: (status, searchValue) => {
      if (doesStringMatch(status, searchValue.toLowerCase())) {
        return [
          {
            value: status,
            key: "status",
            label: `${status} (status)`,
          },
        ]
      }
    },
    filter: (retailers, value) => retailers.filter(retailer => retailer.status === value),
  },
  priorityGroup: {
    match: (priorityGroup, searchValue) => {
      if (doesStringMatch(`priority group ${priorityGroup}`, searchValue.toLowerCase())) {
        return [
          {
            value: priorityGroup,
            key: "priorityGroup",
            label: `Priority Group ${priorityGroup}`,
          },
        ]
      }
    },
    filter: (retailers, value) => retailers.filter(retailer => retailer.priorityGroup === value),
  },
}

export const retailerSearchMatchKeys = Object.keys(retailerSearch).filter(key => retailerSearch[key].match)
export default retailerSearch

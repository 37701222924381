import { continentsOptions, countryOptions } from "../../config"

const getRoomAreasOptions = rooms =>
  rooms.reduce((options, room) => {
    room.areas.forEach(area => {
      options.push({
        label: `${room.name} > ${area.name}`,
        title: `${room.name} > ${area.name}`,
        objValue: {
          roomId: room.id,
          areaId: area.id,
        },
        value: area.id,
        type: "roomArea",
      })
    })
    return options
  }, [])

const getAreasOptions = areas =>
  areas.map(area => ({
    label: area.name,
    title: area.name,
    value: area.id,
    type: "area",
  }))

const getRetailerOptions = retailers =>
  retailers.map(({ id, name }) => ({
    label: name,
    title: name,
    value: id,
    type: "retailer",
  }))

const mappedGoogleTypes = {
  locality: {
    optSection: "Cities",
    optType: "city",
  },
  administrative_area_level_1: {
    optSection: "Regions / States",
    optType: "region",
  },
  administrative_area_level_2: {
    optSection: "Counties",
    optType: "county",
  },
}
const getOptionTypeFromGoogleType = googleTypes => {
  const foundType = googleTypes.find(type => mappedGoogleTypes[type])
  return foundType && mappedGoogleTypes[foundType]
}

export const getTerritoriesOptionsFromGoogleSuggestions = googleSuggestions => {
  const territoriesOptionsObject = {}
  googleSuggestions.forEach(suggestion => {
    const type = getOptionTypeFromGoogleType(suggestion.types)
    if (!type) return
    const { optSection, optType } = type
    if (!territoriesOptionsObject[optSection]) {
      territoriesOptionsObject[optSection] = []
    }
    territoriesOptionsObject[optSection].push({
      label: suggestion.description,
      title: suggestion.description,
      value: suggestion.place_id,
      type: optType,
    })
  })
  return Object.keys(territoriesOptionsObject).reduce((opts, optSection) => {
    opts.push({
      label: optSection,
      options: territoriesOptionsObject[optSection],
    })
    return opts
  }, [])
}

export const getCountriesOptionsWithoutExclusions = excludeCountries => {
  let countriesOptions = [...countryOptions]
  if (excludeCountries?.length > 0) {
    countriesOptions = countriesOptions.filter(country => !excludeCountries.includes(country.value))
  }
  return countriesOptions
}

type Props = {
  includeAllCountriesOption: boolean,
  withCountries: boolean,
  withContinents: boolean,
  withAreas: boolean,
  withRooms: boolean,
  rooms: [
    {
      id: string,
      name: string,
      areas: [
        {
          id: string,
          name: string,
        },
      ],
    },
  ],
  retailers: [
    {
      id: string,
      name: string,
    },
  ],
  googleSuggestions: [],
  excludeCountries: [string],
}
const generateAreasAndTerritoriesOptions = ({
  includeAllCountriesOption,
  withCountries,
  withContinents,
  withAreas = true,
  withRooms = true,
  areas,
  rooms,
  retailers,
  googleSuggestions,
  excludeCountries,
}: Props = {}) => {
  let optionsSections = []
  if (withAreas && areas?.length > 0) {
    optionsSections.push({
      label: "Areas",
      options: getAreasOptions(areas),
    })
  }
  if (withRooms && rooms?.length > 0) {
    optionsSections.push({
      label: "Room Areas",
      options: getRoomAreasOptions(rooms),
    })
  }
  if (withContinents) {
    optionsSections.push({
      label: "Continents",
      options: continentsOptions,
    })
  }
  if (withCountries) {
    const countriesOptions = getCountriesOptionsWithoutExclusions(excludeCountries)
    if (includeAllCountriesOption && countriesOptions.length > 0) {
      countriesOptions.unshift({
        value: "all",
        label: "All Remaining Countries",
        title: "All Remaining Countries",
      })
    }
    optionsSections.push({ label: "Countries", options: countriesOptions })
  }
  if (googleSuggestions?.length > 0) {
    optionsSections = optionsSections.concat(getTerritoriesOptionsFromGoogleSuggestions(googleSuggestions))
  }
  if (retailers?.length > 0) {
    optionsSections.push({
      label: "Retailers",
      options: getRetailerOptions(retailers),
    })
  }
  return optionsSections
}

export default generateAreasAndTerritoriesOptions

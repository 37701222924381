/* eslint-disable */

import { applyMiddleware, compose, createStore } from "redux"

import { createMigrate, persistReducer, persistStore } from "redux-persist"

import { composeWithDevTools } from "redux-devtools-extension"
import createMySocketMiddleware from "./sockets/middleware"
import createSagaMiddleware from "redux-saga"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"

let persistor = null
const migrations = {
  10: state => {
    persistor.purge()
    return {
      ...state,
    }
  },
}

export default (rootReducer, rootSaga) => {
  const middlewares = [thunk]
  const enhancers = []

  const persistConfig = {
    key: "root",
    version: 10,
    storage,
    whitelist: ["auth"],
    migrate: createMigrate(migrations, { debug: false }),
  }

  // const sagaMonitor = __DEV__ ? console.tron.createSagaMonitor() : null
  const sagaMonitor = null
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

  const socketMiddleware = createMySocketMiddleware()

  middlewares.push(socketMiddleware)
  middlewares.push(sagaMiddleware)
  // activate if needed
  // middlewares.push(logger)

  const composeEnhancers = composeWithDevTools

  enhancers.push(composeEnhancers(applyMiddleware(...middlewares)))

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const createAppropriateStore = createStore
  const store = createAppropriateStore(persistedReducer, compose(...enhancers))

  sagaMiddleware.run(rootSaga)

  persistor = persistStore(store)

  return { persistor, store }
}

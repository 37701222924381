import React, { memo } from "react"
import { Button } from "modaresa-commons"

import Toggler from "../../../../elements/Toggler"

import BrandAreas from "./BrandAreas"
import BrandRoomBasedAreas from "./BrandRoomBasedAreas"
import CheckBrandOwnerEmailVerified from "./CheckBrandOwnerEmailVerified"
import { Container, ContainerButtons } from "./CompanyAreasView.styles"

type Props = {
  brandId: string,
  disableSaveChanges: boolean,
  isRoomBasedAreas: boolean,
  canEdit: boolean,
  rooms: [],
  onChangeRooms: Function,
  onToggleRoomBasedAreas: Function,
  onChangeAreas: Function,
  onChangeRemainingArea: Function,
  onSubmit: Function,
  errors: {},
}
const CompanyAreasView = ({
  brandId,
  disableSaveChanges,
  rooms,
  errors,
  isRoomBasedAreas,
  canEdit,
  onChangeRooms,
  onToggleRoomBasedAreas,
  onChangeAreas,
  onChangeRemainingArea,
  onSubmit,
}: Props) => (
  <Container>
    <CheckBrandOwnerEmailVerified brandId={brandId} />
    <Toggler
      title="Room Based Areas"
      description="Create areas within rooms"
      checked={isRoomBasedAreas}
      onChange={onToggleRoomBasedAreas}
    />
    {isRoomBasedAreas ? (
      <BrandRoomBasedAreas
        brandId={brandId}
        rooms={rooms}
        onChangeRooms={onChangeRooms}
        errors={errors.rooms}
        canEdit={canEdit}
      />
    ) : (
      <BrandAreas
        brandId={brandId}
        onChangeAreas={onChangeAreas}
        onChangeRemainingArea={onChangeRemainingArea}
        errors={errors}
        canEdit={canEdit}
      />
    )}
    <ContainerButtons>
      <Button disabled={disableSaveChanges} type="button" onClick={onSubmit}>
        Save Changes
      </Button>
    </ContainerButtons>
  </Container>
)

export default memo(CompanyAreasView)

import { useQuery } from "@apollo/client"
import React, { memo } from "react"
import { ModalConfirmation } from "modaresa-commons"

import { IS_BOOKING_EVENT_PART_OF_MULTIPLE_BOOKINGS } from "../../../../../graphql/queries/bookingEvent"

import EditBookingModalContent from "./EditBookingModalContent"

type Props = {
  booking: {
    _id: string,
  },
}

const EditBookingModal = ({ booking, ...props }: Props) => {
  const { loading, error, data } = useQuery(IS_BOOKING_EVENT_PART_OF_MULTIPLE_BOOKINGS, {
    variables: {
      bookingEventId: booking._id,
    },
  })
  if (loading) {
    return <ModalConfirmation loading />
  }
  if (error) {
    return <ModalConfirmation error={"error while checking booking event "} />
  }
  const { isBookingEventPartOfMultipleBookingEvents } = data
  return (
    <EditBookingModalContent {...props} booking={booking} isLargerBooking={isBookingEventPartOfMultipleBookingEvents} />
  )
}

export default memo(EditBookingModal)

import { useFormikContext } from "formik"
import { useHistory } from "react-router-dom"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import throttle from "lodash/throttle"

import { countries } from "../../../../../../config"
import { searchByStringValueInArray } from "../../../../../../helpers/string"

import { LiOfList, List, ContainerListMatchingRetailers, Title } from "./ListMatchingRetailers.styles"

type Props = {
  sharedRetailers: [],
  brandRetailers: [],
}

const ListMatchingRetailers = ({ sharedRetailers, brandRetailers }: Props) => {
  const history = useHistory()
  const [query, setQuery] = useState(null)
  const { values } = useFormikContext()
  const handleClickRetailer = useCallback(
    item => () => {
      const brandRetailer = brandRetailers.find(retailer => retailer.sharedRetailerId === item._id)
      if (brandRetailer) {
        history.push(`/clients/retailers/${brandRetailer._id}`)
      } else {
        history.push(`/clients/retailers/new?sharedRetailerId=${item._id}`)
      }
    },
    [history, brandRetailers],
  )
  const handleQuery = useMemo(() => throttle(newQuery => setQuery(newQuery), 300), [])
  useEffect(() => {
    handleQuery(values.name)
  }, [handleQuery, values.name])
  const foundRetailers = useMemo(
    () =>
      query
        ? searchByStringValueInArray({
            array: sharedRetailers,
            query,
            compareKey: "name",
          })
        : [],
    [query, sharedRetailers],
  )
  return (
    (foundRetailers.length && (
      <ContainerListMatchingRetailers>
        <Title>Did you mean ?</Title>
        <List>
          {foundRetailers.map(item => (
            <LiOfList key={item._id} onClick={handleClickRetailer(item)}>
              {item.name} / {item.storeAddress.city} ({countries[item.storeAddress.country]?.name})
            </LiOfList>
          ))}
        </List>
      </ContainerListMatchingRetailers>
    )) ||
    null
  )
}

export default memo(ListMatchingRetailers)

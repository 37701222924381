import request from "superagent"

import { REST_API_URL } from "../../../config"

export const containerTabs = {
  allGroups: { key: "allGroups", value: "All Groups" },
  createNewGroup: { key: "createNewGroup", value: "Create New Group" },
}

export const containerTabsRetailer = {
  retailerInformation: {
    key: "retailerInformation",
    value: "Retailer Information",
  },
  employees: { key: "employees", value: "Employees" },
}

export const retailers = [
  { value: "Selfridges", label: "Selfridges" },
  { value: "Harrods", label: "Harrods" },
  { value: "Printhemps", label: "Printhemps" },
  { value: "Merci", label: "Merci" },
  { value: "Opening sermoni", label: "Opening sermoni" },
]

export const statuses = [
  { value: "prospect", label: "Prospect" },
  { value: "stockist", label: "Stockist" },
]
export const meetingLengthLookup = {
  businessMeetingLength: "businessMeeting",
  walkthroughLength: "walkthrough",
  buyingAppointmentLength: "buyingAppointment",
}
export const meetingTypeLookup = {
  businessMeeting: "Business Meeting",
  walkthrough: "Walkthrough",
  buyingAppointment: "Buying Appointment",
}
export const countries = [
  { value: "it", label: "Italy" },
  { value: "fr", label: "France" },
  { value: "uk", label: "United Kingdom" },
  { value: "de", label: "Germany" },
  { value: "us", label: "USA" },
  { value: "ru", label: "Russia" },
]
export const languages = [
  { value: "it", label: "Italian" },
  { value: "fr", label: "French" },
  { value: "uk", label: "English" },
  { value: "de", label: "German" },
  { value: "ru", label: "Russian" },
]
export const companyPosition = [
  { value: "brandManager", label: "Brand Manager" },
  { value: "salesChief", label: "Sales Chief" },
  { value: "designer", label: "Designer" },
  { value: "intern", label: "Intern(slave)" },
]
export const modaResaRole = [
  { value: "brandAdmin", label: "Brand Admin" },
  { value: "brandStaff", label: "Brand Staff" },
  { value: "freelancer", label: "Freelancer" },
  { value: "agent", label: "Agent" },
]
export const options = {
  allBuyers: {
    key: "allBuyers",
    value: "All Buyers",
    subOption: false,
    containsSubOptions: true,
    collapsed: true,
  },
  stockists: {
    key: "stockists",
    value: "Stockists",
    subOption: true,
    containsSubOptions: false,
    parent: "allBuyers",
  },
  prospects: {
    key: "prospects",
    value: "Prospects",
    subOption: true,
    containsSubOptions: false,
    parent: "allBuyers",
  },
  competitorGroups: {
    key: "competitorGroups",
    value: "Competitor Groups",
    subOption: false,
    containsSubOptions: true,
    collapsed: true,
  },
  ukDeptStores: {
    key: "ukDeptStores",
    value: "UK Dept stores",
    subOption: true,
    containsSubOptions: false,
    parent: "competitorGroups",
  },
  parisStores: {
    key: "parisStores",
    value: "ParisStores",
    subOption: true,
    containsSubOptions: false,
    parent: "competitorGroups",
  },
  priorityGroups: {
    key: "priorityGroups",
    value: "Priority Groups",
    subOption: false,
    containsSubOptions: true,
    collapsed: true,
  },
  mostImportant: {
    key: "mostImportant",
    value: "Most Important",
    subOption: true,
    containsSubOptions: false,
    parent: "priorityGroups",
  },
  mediumImportant: {
    key: "mediumImportant",
    value: "Medium Important",
    subOption: true,
    containsSubOptions: false,
    parent: "priorityGroups",
  },
  leastImportant: {
    key: "leastImportant",
    value: "Least Important",
    subOption: true,
    containsSubOptions: false,
    parent: "priorityGroups",
  },
}

export const getLanguages = salesSession => {
  const languages = []
  for (const _id in salesSession.staff) {
    const staffMember = salesSession.staff[_id]
    staffMember.languages.forEach(isoCode => {
      if (languages.indexOf(isoCode) === -1) {
        languages.push(isoCode)
      }
    })
  }
  return languages
}

export const getAvailableStaffForSlot = (salesSessionId, booking, accessToken) =>
  new Promise((resolve, reject) => {
    request
      .post(`${REST_API_URL}/getAvailableStaffForSlot`)
      .send({ salesSessionId, booking })
      .set("authorization", accessToken)
      .end((err, res) => {
        if (err) {
          reject(err)
        } else if (res.body.success) {
          resolve(res.body.data.staff)
        }
      })
  })

import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import times from "lodash/times"
import { Flex } from "modaresa-commons"

import Booking from "../../Booking"
import {
  Booklot,
  RoomSchedule as RoomScheduleContainer,
  StaffColumn,
  StaffColumnHeader,
  StaffMemberTableHeader,
  Timeslot,
} from "../../../styles.js"

const FIFTEENMINUTES = 900

class StaffMemberTable extends Component {
  tableRefs = []

  // eslint-disable-next-line no-empty-function
  onSlotClick = () => {}

  renderTable = tableIndex => {
    const { sessionSchedule, openingHours, staffSchedule, allocatedStaff, canEditBooking } = this.props
    let member = {}
    const allocatedMember = staffSchedule[allocatedStaff[0].staffId]
    if (allocatedMember) {
      const memberbookings = allocatedMember.bookings
      for (const key in memberbookings) {
        member = {
          ...member,
          [memberbookings[key].startTime]: memberbookings[key],
        }
      }
    }
    const fromStamp = sessionSchedule[tableIndex].from
    const toStamp = sessionSchedule[tableIndex].to
    const start = moment.utc(fromStamp, "X")
    const end = moment.utc(toStamp, "X")
    const startRange = moment
      .utc(openingHours.from, "X")
      .set({ year: start.year(), month: start.month(), date: start.date() })
    const endRange = moment.utc(openingHours.to, "X").set({ year: end.year(), month: end.month(), date: end.date() })
    let startTimeStamp = startRange.unix()

    const diff = endRange.diff(startRange, "hours", true)
    let parts = diff * 4
    let itemIndex = 0

    return times(diff * 4, idx => {
      if (parts <= 0) return null
      if (fromStamp > startTimeStamp || toStamp === startTimeStamp) {
        let diff
        if (fromStamp - startTimeStamp > 0) {
          diff = fromStamp - startTimeStamp
        } else {
          diff = endRange.unix() - startTimeStamp
        }
        const flex = diff / FIFTEENMINUTES
        parts -= flex
        startTimeStamp += diff
        itemIndex += flex
        return <Booklot blocked="true" flex={flex} key={idx} />
      }
      if (member) {
        const element = member[startTimeStamp]
        if (element) {
          const diff = element.endTime - element.startTime
          const flex = diff / FIFTEENMINUTES
          parts -= flex
          startTimeStamp += diff
          itemIndex += flex
          const reportUrl = element.report
            ? `/clients/${element.clients[0]}/meetings-reports/${element.report}`
            : `/clients/${element.clients[0]}/meetings-reports?booking=${element._id}`
          return (
            <Booklot flex={flex} key={idx}>
              <Booking
                booking={element}
                position="relative"
                room={element.room}
                onClick={e => this.props.onBookingClick(reportUrl, e)}
                canEdit={canEditBooking}
              />
            </Booklot>
          )
        }
        if (moment.utc(startTimeStamp).minutes() >= 0) startTimeStamp += FIFTEENMINUTES
        parts -= 1
        itemIndex++
        const timeStamp = startTimeStamp - FIFTEENMINUTES
        return (
          <Timeslot
            even={itemIndex % 4 === 0}
            key={idx}
            onClick={event => this.onSlotClick({ event, member, timeStamp })}
          >
            {""}
          </Timeslot>
        )
      }
      if (moment.utc(startTimeStamp).minutes() >= 0) startTimeStamp += FIFTEENMINUTES
      parts -= 1
      itemIndex++
      const timeStamp = startTimeStamp - FIFTEENMINUTES
      return (
        <Timeslot
          even={itemIndex % 4 === 0}
          key={idx}
          onClick={event => this.onSlotClick({ event, member, timeStamp })}
        >
          {""}
        </Timeslot>
      )
    })
  }

  render() {
    const { sessionSchedule, allocatedStaff } = this.props
    return (
      <Flex
        align="stretch"
        column
        style={{
          marginBottom: 20,
          flex: 1,
          overflow: "auto",
          paddingRight: "20px",
          overflowX: "auto",
          minHeight: "90%",
        }}
      >
        <StaffMemberTableHeader minWidth={sessionSchedule.length}>
          {sessionSchedule.map((member, idx) => (
            <StaffColumnHeader key={idx}>
              <span>{moment.utc(sessionSchedule[idx].date, "X").format("dddd Do, MMMM")}</span>
            </StaffColumnHeader>
          ))}
        </StaffMemberTableHeader>
        <RoomScheduleContainer minWidth={allocatedStaff.length}>
          {sessionSchedule.map((member, idx, arr) => (
            <StaffColumn key={idx} last={idx === arr.length - 1}>
              <React.Fragment>{this.renderTable(idx)}</React.Fragment>
            </StaffColumn>
          ))}
        </RoomScheduleContainer>
      </Flex>
    )
  }
}

StaffMemberTable.propTypes = {
  allocatedStaff: PropTypes.array,
  openingHours: PropTypes.object,
  selectedStaff: PropTypes.object,
  sessionSchedule: PropTypes.array,
  staffSchedule: PropTypes.object,
  onBookingClick: PropTypes.func,
}

export { StaffMemberTable }

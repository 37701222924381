import React, { memo, useCallback, useMemo, useState } from "react"
import Truncate from "react-truncate"
import { I } from "modaresa-commons"

import { countryOptions } from "../../../../config"
import StaffPicker from "../../StaffPicker"

import AreaNotificationsList from "./AreaNotificationsList"
import { ViewAll, CenteredDropdownActions, StyledBooleanStatusIcon, TdIcon } from "./RemainingCountriesRow.styles"

const agentAndManagerRoles = ["owner", "admin", "manager", "agent"]

type Props = {
  brandId: string,
  canStaffsBeModified: boolean,
  onChangeRemainingArea: Function,
  excludeCountries?: [
    {
      label: string,
    },
  ],
  remainingArea?: {
    meetingResponsible: {},
    signee: {},
    notifiedStaffs: [],
    notificationMeetingConfirmation: boolean,
    notificationMeetingReminder: boolean,
  },
}

const RemainingCountriesRow = ({
  brandId,
  canStaffsBeModified,
  onChangeRemainingArea,
  excludeCountries,
  remainingArea,
}: Props) => {
  const [showNotificationsEdition, setShowNotificationsEdition] = useState(false)
  const [showAllRemainingCountries, setShowAllRemainingCountries] = useState(false)
  const {
    meetingResponsible,
    signee,
    notifiedStaffs = [],
    notificationMeetingConfirmation,
    notificationMeetingReminder,
  } = remainingArea
  const handleClickViewAllCountries = useCallback(() => setShowAllRemainingCountries(true), [])
  const handleChangeRemainingAreaField = useCallback(
    field => fieldValue =>
      onChangeRemainingArea({
        ...remainingArea,
        [field]: fieldValue,
      }),
    [onChangeRemainingArea, remainingArea],
  )
  const handleChangeMeetingResponsible = useCallback(
    selectedStaff => {
      const updatedRemainingArea = {
        ...remainingArea,
        meetingResponsible: selectedStaff,
      }
      const isMeetingResponsibleAlreadyNotifiedStaff = notifiedStaffs?.find(staff => staff.id === selectedStaff.id)
      if (!isMeetingResponsibleAlreadyNotifiedStaff) {
        updatedRemainingArea.notifiedStaffs = [...notifiedStaffs, selectedStaff]
      }
      onChangeRemainingArea(updatedRemainingArea)
    },
    [notifiedStaffs, onChangeRemainingArea, remainingArea],
  )
  const handleChangeNotification = useCallback(
    (notificationField, isActive) => handleChangeRemainingAreaField(notificationField)(isActive),
    [handleChangeRemainingAreaField],
  )
  const handleToggleNotificationsEdition = useCallback(() => setShowNotificationsEdition(!showNotificationsEdition), [
    showNotificationsEdition,
  ])

  const actions = useMemo(() => {
    const list = []
    if (canStaffsBeModified) {
      list.push({
        label: "Edit notifications",
        onClick: handleToggleNotificationsEdition,
      })
    }
    return list
  }, [handleToggleNotificationsEdition, canStaffsBeModified])

  const countryOptionsList = useMemo(() => {
    const excludeCountriesNotNull = excludeCountries || []
    const countriesWithExclusions = countryOptions.reduce((countries, country) => {
      if (!excludeCountriesNotNull.includes(country.value)) {
        countries.push(country.label)
      }
      return countries
    }, [])
    return countriesWithExclusions.join(", ") || <I>none</I>
  }, [excludeCountries])
  return (
    <>
      <tr>
        <td width="19%">Remaining countries</td>
        <td>
          {showAllRemainingCountries ? (
            countryOptionsList
          ) : (
            <Truncate
              lines={3}
              ellipsis={
                <span>
                  ... <ViewAll onClick={handleClickViewAllCountries}>View all</ViewAll>
                </span>
              }
            >
              {countryOptionsList}
            </Truncate>
          )}
        </td>
        <td width="12%">
          <StaffPicker
            defaultValue={meetingResponsible}
            disabled={!canStaffsBeModified}
            brandId={brandId}
            roles={agentAndManagerRoles}
            onChange={handleChangeMeetingResponsible}
          />
        </td>
        <td width="12%">
          <StaffPicker
            displayIsEmailVerified
            defaultValue={signee}
            disabled={!canStaffsBeModified}
            brandId={brandId}
            roles={agentAndManagerRoles}
            onChange={handleChangeRemainingAreaField("signee")}
          />
        </td>
        <td width="17%">
          <StaffPicker
            value={notifiedStaffs}
            disabled={!canStaffsBeModified}
            brandId={brandId}
            multiple
            placeholder="Assign staff(s)"
            onChange={handleChangeRemainingAreaField("notifiedStaffs")}
          />
        </td>
        <TdIcon width="32px">
          <StyledBooleanStatusIcon status={notificationMeetingConfirmation} size={18} />
        </TdIcon>
        <TdIcon width="32px">
          <StyledBooleanStatusIcon status={notificationMeetingReminder} size={18} />
        </TdIcon>
        <td>{actions.length > 0 && <CenteredDropdownActions actions={actions} />}</td>
      </tr>
      {showNotificationsEdition && (
        <tr>
          <td colSpan={8}>
            <AreaNotificationsList
              area={remainingArea}
              onChangeNotification={handleChangeNotification}
              onClose={handleToggleNotificationsEdition}
            />
          </td>
        </tr>
      )}
    </>
  )
}

RemainingCountriesRow.defaultProps = {
  remainingArea: {},
  excludeCountries: [],
}
export default memo(RemainingCountriesRow)

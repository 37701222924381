import { useField, useFormikContext } from "formik"
import React, { useCallback, memo } from "react"
import styled from "styled-components"

import BrandPicker from "../../../../elements/BrandPicker/BrandPicker"
import FormFieldLayout from "../../../../elements/FormFieldLayout/FormFieldLayout"

const ContainerSelect = styled.div`
  width: ${p => p.width || "200px"};
`

type Props = {
  brandId: string,
  labelWidth: string,
  options: [any],
  selectWidth: string,
  disabled: boolean,
}

const FieldBrand = ({ labelWidth, selectWidth, disabled }: Props) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta, helpers] = useField("brand")
  const handleChangeSelect = useCallback(
    value => {
      helpers.setValue(value)
      setFieldValue("office", null)
    },
    [helpers, setFieldValue],
  )
  return (
    <FormFieldLayout
      label="Brand*"
      labelWidth={labelWidth}
      name="brand"
      direction="row"
      error={meta.touched && meta.error}
    >
      <ContainerSelect width={selectWidth}>
        <BrandPicker value={field.value} onChange={handleChangeSelect} onBlur={field.onBlur} disabled={disabled} />
      </ContainerSelect>
    </FormFieldLayout>
  )
}

export default memo(FieldBrand)

import React, { memo, useCallback, useMemo } from "react"
import styled from "styled-components"
import { AlertWarning, Flex } from "modaresa-commons"

import { modaResaRoles } from "../../../../config"
import { Select } from "../../../ui"

export const ContainerSelect = styled.div`
  width: 60%;
`

type Props = {
  availableHosts: {
    recommended: [],
    others: [],
  },
  host: string,
  onChange: Function,
}
const MeetingHost = ({ host, availableHosts, onChange }: Props) => {
  const handleGenerateStaffOptions = useCallback(
    staffs =>
      staffs.map(staff => ({
        label: `${staff.fullName} ${modaResaRoles[staff.modaresaRole]?.name}`,
        value: staff,
      })),
    [],
  )
  const hostOptions = useMemo(
    () => [
      {
        value: "- No Meeting Host assigned -",
        label: "- No Meeting Host assigned -",
      },
      {
        label: `Recommended (${availableHosts.recommended.length})`,
        options: handleGenerateStaffOptions(availableHosts.recommended),
      },
      {
        label: `Others (${availableHosts.others.length})`,
        options: handleGenerateStaffOptions(availableHosts.others),
      },
    ],
    [availableHosts, handleGenerateStaffOptions],
  )
  return (
    <div>
      <Flex margin="0 0 20px 0">
        <span>Meeting Host</span>
        <ContainerSelect>
          <Select
            options={hostOptions}
            placeholder="Meeting Host"
            value={host}
            onChange={onChange}
            menuPosition={"fixed"}
          />
        </ContainerSelect>
      </Flex>
      {!availableHosts.recommended.length && !availableHosts.others.length && (
        <AlertWarning margin="0 0 20px 0">No available hosts for the requested time slot</AlertWarning>
      )}
    </div>
  )
}

MeetingHost.defaultProps = {
  availableHosts: {
    recommended: [],
    others: [],
  },
}
export default memo(MeetingHost)

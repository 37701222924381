import React, { Component } from "react"
import { Tabs, TabPane } from "modaresa-commons"
import PropTypes from "prop-types"

import PageTitle from "../../../../elements/PageTitle/PageTitle"

import { Container, NotFound } from "./Retailer.styles"
import RetailerInformation from "./blocks/RetailerInformation"

class Retailer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      savingRequest: false,
    }
  }

  componentDidMount() {
    const { match, retailers, retailersRequestPending, fetchRetailers, user } = this.props

    if (match.params && match.params.id) {
      this.props.selectRetailerByID(match.params.id)
    }

    if (!retailersRequestPending && !retailers?.data) {
      fetchRetailers(user.brandId)
    }

    this.props.fetchSharedRetailers()
  }

  componentWillUnmount = () => {
    this.props.unselectRetailer()
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props

    if (match.params?.id && match.params.id !== prevProps.match.params?.id) {
      this.props.selectRetailerByID(match.params.id)
    }
  }

  render() {
    const {
      selectedRetailer,
      requestPending,
      requestError,
      match: { params },
      history,
      brand,
      addRetailer,
      updateRetailer,
      sharedRetailers,
      retailers,
    } = this.props
    if (params.id && !selectedRetailer) {
      return (
        <Container>
          <NotFound>
            <span>RETAILER NOT FOUND</span>
          </NotFound>
        </Container>
      )
    }

    return (
      <PageTitle title={selectedRetailer?.name || "Retailer"}>
        <Tabs>
          <TabPane tabKey="infos" tab="Retailer Information">
            <RetailerInformation
              key={selectedRetailer._id}
              addRetailer={addRetailer}
              updateRetailer={updateRetailer}
              retailers={retailers}
              sharedRetailers={sharedRetailers}
              brand={brand}
              history={history}
              isNew={false}
              selectedRetailer={selectedRetailer}
              requestPending={requestPending}
              requestError={requestError}
            />
          </TabPane>
        </Tabs>
      </PageTitle>
    )
  }
}

Retailer.propTypes = {
  addRetailer: PropTypes.func,
  addRetailerBySharedRetailer: PropTypes.func,
  brand: PropTypes.object,
  fetchSharedRetailers: PropTypes.func,
  fetchRetailers: PropTypes.func,
  retailersRequestPending: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  requestError: PropTypes.any,
  requestPending: PropTypes.bool,
  selectRetailerByID: PropTypes.func,
  selectedRetailer: PropTypes.object,
  sharedRetailers: PropTypes.array,
  unselectRetailer: PropTypes.func,
  updateRetailer: PropTypes.func,
  user: PropTypes.object,
}

export default Retailer

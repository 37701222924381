import { LOGIN, LOGOUT, RESET_PASSWORD, SEND_RESET_EMAIL, VERIFY_TOKEN } from "../actions/auth"
import { createReducer } from "../../helpers/redux"

const initialState = {
  isAuthed: false,
  accessToken: null,
  user: null,
  emailVerified: false,
  requestResetPasswordPending: false,
  requestResetPasswordError: null,
  requestVerifyTokenPending: false,
  requestVerifyTokenError: null,
  tokenIsVerified: false,
  requestResetPasswordEmailPending: false,
  requestResetPasswordEmailError: null,
  emailSent: false,
}

const handlers = {
  [LOGIN.SUCCESS]: (state, { payload }) => ({
    ...state,
    isAuthed: true,
    ...payload,
  }),
  [LOGOUT]: () => initialState,
  [RESET_PASSWORD.REQUEST]: state => ({
    ...state,
    requestResetPasswordPending: true,
    requestResetPasswordError: null,
  }),
  [RESET_PASSWORD.SUCCESS]: state => ({
    ...state,
    requestResetPasswordPending: false,
  }),
  [RESET_PASSWORD.FAILURE]: (state, { payload }) => ({
    ...state,
    requestResetPasswordPending: false,
    requestResetPasswordError: payload,
  }),
  [VERIFY_TOKEN.REQUEST]: state => ({
    ...state,
    requestVerifyTokenPending: true,
    requestVerifyTokenError: null,
    tokenIsVerified: false,
  }),
  [VERIFY_TOKEN.SUCCESS]: state => ({
    ...state,
    requestVerifyTokenPending: false,
    tokenIsVerified: true,
  }),
  [VERIFY_TOKEN.FAILURE]: (state, { payload }) => ({
    ...state,
    requestVerifyTokenPending: false,
    requestVerifyTokenError: payload,
  }),
  [SEND_RESET_EMAIL.REQUEST]: state => ({
    ...state,
    requestResetPasswordEmailPending: true,
    requestResetPasswordEmailError: null,
    emailSent: false,
  }),
  [SEND_RESET_EMAIL.SUCCESS]: state => ({
    ...state,
    requestResetPasswordEmailPending: false,
    emailSent: true,
  }),
  [SEND_RESET_EMAIL.FAILURE]: (state, { payload }) => ({
    ...state,
    requestResetPasswordEmailPending: false,
    requestResetPasswordEmailError: payload,
  }),
}

export default createReducer(initialState, handlers)

import React, { Component } from "react"
import Select, { components } from "react-select"
import { Link } from "react-router-dom"
import Modal from "react-modal"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import moment from "moment-timezone"
import { theme, Button, Flex } from "modaresa-commons"

import ArrowIcon from "../../../../../static/whiteArrow.svg"
import CalendarIcon from "../../../../../static/calendar.svg"
import TimeIcon from "../../../../../static/clock.svg"
import { appointmentStatusOptions } from "../../../../../config/session"
import { getMinutesList } from "../../../../../helpers/date"
import ModalCancelMeeting from "../../../../elements/ModalCancelMeeting/ModalCancelMeeting"
import noSessionImage from "../../../../../static/Modaresa_VTC-01.png"

import {
  Appointment,
  AppointmentActions,
  AppointmentInfo,
  Appointments,
  Bold,
  ButtonsRow,
  CloseModal,
  Container,
  Date,
  DateContainer,
  Header,
  Icon,
  LoaderContainer,
  ModalBody,
  NotFound,
  Phone,
  SeleteContainer,
  Text,
  Time,
  TimeDivinder,
} from "./styles"

const customStyles = {
  content: {
    width: "450px",
    height: "auto",
    maxHeight: "500px",
    minHeight: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    overflow: "visible",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 13,
  },
}

const timeOptions = getMinutesList()

const loadingIndicatorStyles = provided => ({
  ...provided,
  fontSize: 7,
  color: theme.primary,
})

export class Attendance extends Component {
  constructor(props) {
    super(props)
    const { selectedSession } = this.props

    this.state = {
      selectedDayIndex: 0,
      delayTimeValue: { value: 15, label: "15 min" },
      staffMember: { value: "all", label: "All Staff" },
      selectedRoom: { value: "all", label: "All Rooms" },
      appointmentStatus: { value: "all", label: "All Appointments" },
      selectedSessionValue: selectedSession ? { value: selectedSession._id, label: selectedSession.name } : null,
      seletedAppointmentId: null,
      newHost: null,
      cancelModal: false,
      arrivedModal: false,
      delayedModal: false,
      errorModal: false,
      hostModal: false,
      isRequesting: false,
      hostStatus: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { requestPending, requestError } = nextProps
    const { isRequesting } = prevState
    if (isRequesting && !requestPending && !requestError) {
      return {
        seletedAppointmentId: null,
        cancelModal: false,
        arrivedModal: false,
        delayedModal: false,
        errorModal: false,
        hostModal: false,
        isRequesting: false,
      }
    }
    if (isRequesting && !requestPending && requestError === "Member is busy") {
      return {
        seletedAppointmentId: null,
        cancelModal: false,
        arrivedModal: false,
        delayedModal: false,
        errorModal: true,
        hostModal: false,
        isRequesting: false,
      }
    }
    return null
  }

  onSelectChange = value => {
    this.setState({ delayTimeValue: value })
  }

  componentDidMount() {
    const {
      sessionOptions,
      onFetchSchedule,
      onFetchClients,
      onFetchStaffs,
      onFetchRetailers,
      selectedSession,
      clients,
      staffs,
      retailers,
      user,
      clientsRequestPending,
      staffsRequestPending,
      retailersRequestPending,
    } = this.props

    if (selectedSession && selectedSession.status === "active") {
      onFetchSchedule(selectedSession._id, this.updateDayIndex)
    } else if (sessionOptions[0].options.length > 0) {
      onFetchSchedule(sessionOptions[0].options[0].value, this.updateDayIndex)
      this.setState({ selectedSessionValue: sessionOptions[0].options[0] })

      if (!retailersRequestPending && !retailers?.data) {
        onFetchRetailers(user.brandId)
      }

      if (!clientsRequestPending && !clients?.data) {
        onFetchClients(user.brandId)
      }

      if (!staffsRequestPending && !staffs?.data) {
        onFetchStaffs(user.brandId)
      }
    }
  }

  componentWillUnmount() {
    const { onResetFilter, onLeaveSocketRoom, selectedSchedule } = this.props
    onResetFilter()
    if (selectedSchedule) {
      onLeaveSocketRoom({
        room: selectedSchedule._id,
        type: "calendar",
      })
    }
  }

  onHostsMenuOpen = bookingId => {
    const { onFetchHosts, selectedSession } = this.props
    const { selectedRoom } = this.state
    this.setState({ seletedAppointmentId: bookingId })
    onFetchHosts({
      sessionId: selectedSession._id,
      bookingId,
      room: selectedRoom.value,
    })
  }

  updateDayIndex = openingHours => {
    const today = moment
      .utc()
      .set({ hour: "00", minute: "00", second: "00" })
      .unix()
    const index = openingHours.findIndex(day => day.date === today)
    if (index !== -1) {
      this.setState({ selectedDayIndex: index })
    }
  }

  onSessionChange = session => {
    this.props.onFetchSchedule(session.value, this.updateDayIndex)
    this.props.onResetFilter()
    this.setState({
      selectedDayIndex: 0,
      staffMember: { value: "all", label: "All Staff" },
      selectedRoom: { value: "all", label: "All Rooms" },
      appointmentStatus: { value: "all", label: "All Appointments" },
      selectedSessionValue: session,
    })
  }

  onFilterSelect = (value, name) => {
    this.props.onSelectFilter({ filterType: name, filterValue: value.value })
    this.setState({ [name]: value })
  }

  onPrevClick = () => {
    const { selectedDayIndex } = this.state
    if (selectedDayIndex - 1 >= 0) {
      this.setState({ selectedDayIndex: selectedDayIndex - 1 })
    }
  }

  onNextClick = () => {
    const { selectedDayIndex } = this.state
    const { selectedSchedule } = this.props
    if (selectedDayIndex + 1 < selectedSchedule.schedule.length) {
      this.setState({ selectedDayIndex: selectedDayIndex + 1 })
    }
  }

  onToggleModal = (name, seletedAppointmentId) => {
    if (!name) {
      this.setState({
        cancelModal: false,
        arrivedModal: false,
        delayedModal: false,
        errorModal: false,
        hostModal: false,
      })
    } else {
      this.setState({ [name]: true, seletedAppointmentId })
    }
  }

  onCancel = optionsCancelBookng => {
    const { onDeleteAppointment, selectedSession } = this.props
    const { seletedAppointmentId } = this.state
    this.setState({ isRequesting: true })
    onDeleteAppointment(seletedAppointmentId, selectedSession._id, optionsCancelBookng)
  }

  onArrived = () => {
    const { seletedAppointmentId } = this.state
    const { onArrived, selectedSession } = this.props
    this.setState({ isRequesting: true })
    onArrived(seletedAppointmentId, selectedSession._id)
  }

  onDelayed = () => {
    const { onDelayAppointment, selectedSession } = this.props
    const { delayTimeValue, seletedAppointmentId } = this.state

    this.setState({ isRequesting: true })
    onDelayAppointment({
      bookingId: seletedAppointmentId,
      sessionId: selectedSession._id,
      delayTime: delayTimeValue.value,
    })
  }

  onHostChange = value => {
    this.setState({
      newHost: value.value,
      hostModal: true,
      hostStatus: value.status,
    })
  }

  onChangeHost = () => {
    const { onUpdateHost, selectedSession } = this.props
    const { newHost, seletedAppointmentId } = this.state
    this.setState({ isRequesting: true })
    onUpdateHost(selectedSession._id, seletedAppointmentId, newHost)
  }

  renderModal() {
    const { cancelModal, arrivedModal, delayedModal, errorModal, delayTimeValue, hostModal, hostStatus } = this.state
    const { requestPending } = this.props
    const closeModal = () => this.onToggleModal(null)
    if (cancelModal) {
      return <ModalCancelMeeting loading={requestPending} isOpen onCancelMeeting={this.onCancel} onClose={closeModal} />
    }

    return (
      <Modal isOpen={arrivedModal || delayedModal || errorModal || hostModal} style={customStyles}>
        <ModalBody />
        {requestPending && (
          <LoaderContainer>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </LoaderContainer>
        )}
        {arrivedModal && (
          <React.Fragment>
            <div>
              <Bold style={{ fontSize: "16px" }}>Confirm Arrival Check-in</Bold>
            </div>
            <Text>
              <p>Please confirm checking in this appointment. Once checked-in the action cannot be reversed.</p>
            </Text>
            <ButtonsRow>
              <Button onClick={this.onArrived}>Yes, check-in appointment</Button>
            </ButtonsRow>
          </React.Fragment>
        )}
        {delayedModal && (
          <React.Fragment>
            <div>
              <Bold style={{ fontSize: "16px" }}>Confirm Arrival Check-in</Bold>
            </div>
            <Text>
              <p>
                Please list the number of minutes the client is delayed{" "}
                <span style={{ textDecoration: "underline", fontWeight: "bold" }}>from the original start time</span> of
                booked appointment.
              </p>
            </Text>
            <SeleteContainer>
              <Select options={timeOptions} value={delayTimeValue} onChange={this.onSelectChange} />
            </SeleteContainer>
            <ButtonsRow>
              <Button onClick={this.onDelayed}>Notify Host</Button>
            </ButtonsRow>
          </React.Fragment>
        )}
        {errorModal && (
          <React.Fragment>
            <div>
              <Bold style={{ fontSize: "16px" }}>Oops! Allocated Meeting Host is not available</Bold>
            </div>
            <Text>
              <p>
                {`Looks like the allocated Meeting Host has not been 'Checked Out'
                of the preceding schedule meeting.`}
              </p>
              <p>{`Please call the Meeting Host to check-out or allocate another host
            from the list`}</p>
            </Text>
          </React.Fragment>
        )}
        {hostModal && (
          <React.Fragment>
            <div>
              <Bold style={{ fontSize: "16px" }}>
                {hostStatus !== "pending" ? "Confirm Changing Host" : "NB! Hijacking Meeting Host"}
              </Bold>
            </div>
            <Text>
              <p>
                {hostStatus !== "pending"
                  ? "Please confirm the change to the selected meeting host"
                  : `You are taking a Meeting Host from another appointment, which will stand without a vendor. Make sure to
                allocate a new seller to it from the calendar or from this check-in dashboard upon the client's arrival`}
                .
              </p>
            </Text>
            <ButtonsRow>
              <Button onClick={this.onChangeHost}>Confirm</Button>
            </ButtonsRow>
          </React.Fragment>
        )}
        <CloseModal onClick={closeModal}>X</CloseModal>
      </Modal>
    )
  }

  renderAppointments = () => {
    const {
      attendanceSchedule,
      retailers,
      clients,
      staffs,
      requestHostsPending,
      availableHosts,
      user,
      brand,
      selectedSession,
    } = this.props
    const { selectedDayIndex, seletedAppointmentId } = this.state
    const attencandeToday = attendanceSchedule[selectedDayIndex]

    const isAgent = user.modaresaRole === "agent"
    let agentCountries

    if (!attencandeToday) {
      return null
    }

    if (!staffs?.data) {
      console.warn("staffs undefined, s", staffs)
      return null
    }

    if (isAgent) {
      const area = user.geographicResponsibility.areas[0]
      const brandArea = brand.areas.find(ara => ara._id === area)
      if (brandArea) {
        agentCountries = brandArea.territories.countries
      }
    }
    const ids = []
    for (const propName in attencandeToday.byId) {
      const propValue = attencandeToday.byId[propName]
      propValue.clients.forEach(client => {
        const clientInfo = clients.byId[client]
        const retailer = retailers.byId[clientInfo.retailerId]
        if (retailer) {
          ids.push(client)
        }
      })
    }
    if (!attencandeToday.allIds.length || !ids.length) {
      return <div>No appointment today</div>
    }
    let isFirst = true
    let timeStart = moment
      .utc(attencandeToday.byId[attencandeToday.allIds[0]].startTime, "X")
      .minute(0)
      .unix()

    return attencandeToday.allIds.map(bookingId => {
      const selectedAppointment = attencandeToday.byId[bookingId]
      if (selectedAppointment.status === "reserved") return null
      const client = clients.byId[selectedAppointment.clients[0]]
      let allClientsString = ""
      selectedAppointment.clients.forEach(clientId => {
        const client = clients.byId[clientId]
        allClientsString += `${client.firstName} ${client.lastName} - ${client.position}`
      })

      const retailer = retailers.byId[client.retailerId]

      if (!retailer) {
        console.warn("Retailer undefined, c", client)
        return null
      }

      const staffMember = selectedAppointment.host && staffs.byId[selectedAppointment.host]
      const meetingResp = selectedAppointment.meetingResp && staffs.byId[selectedAppointment.meetingResp[0]]
      const startTime = moment.utc(selectedAppointment.startTime, "X").format("HH:mm")
      const endTime = moment.utc(selectedAppointment.endTime, "X").format("HH:mm")
      const diffTime = selectedAppointment.startTime - timeStart
      const isDisabled = selectedAppointment.appointmentStatus === "arrived"
      const reallocateDisabled = selectedAppointment.hostStatus === "checkedOut"
      const delayDisabled = selectedAppointment.appointmentStatus === "delayed" || selectedAppointment.delayTime
      const delayDuration = timeOptions.find(item => item.value === selectedAppointment.delayTime)

      let newStart
      if (delayDuration) {
        newStart = moment(startTime, "HH:mm")
          .add(delayDuration.value, "minutes")
          .format("HH:mm")
      }
      const retailerCountry = retailer ? retailer.storeAddress.country : null
      let userHasAccess = true

      if (isAgent && !agentCountries?.includes(retailerCountry)) {
        userHasAccess = false
      }

      const Appoinmt = () => (
        <Appointment key={bookingId}>
          <AppointmentInfo>
            <Bold style={{ fontSize: "16px" }}>
              {`${retailer.name} - ${retailer.storeAddress.city}`} <Phone>{retailer.phoneNumber}</Phone>
            </Bold>
            <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.7)" }}>
              {allClientsString.slice(0, -1)}
              <span style={{ color: "black" }}> {`- ${selectedAppointment.room}`}</span>
            </div>
          </AppointmentInfo>
          <Flex align="flex-end" justify="flex-end" style={{ width: "78%" }}>
            <div
              style={{
                width: "100%",
                maxWidth: "350px",
                padding: "0px 10px 0px 20px",
              }}
            >
              <Select
                components={{
                  IndicatorSeparator: () => null,

                  Option: props => (
                    <components.Option {...props}>
                      <Flex align="center" justify="space-between">
                        <span>{props.data.label}</span>
                        <Link to={`/calendars?staffId=${props.data.value}&sessionId=${selectedSession._id}`}>
                          <Icon
                            src={CalendarIcon}
                            style={{
                              margin: 0,
                              marginLeft: "10px",
                              height: "20px",
                            }}
                          />
                        </Link>
                      </Flex>
                    </components.Option>
                  ),
                }}
                isDisabled={reallocateDisabled}
                isLoading={requestHostsPending && seletedAppointmentId === bookingId}
                options={availableHosts.map(({ host, status }) => ({
                  value: host._id,
                  label: `${host.firstName} ${host.lastName}`,
                  status,
                }))}
                styles={{
                  loadingIndicator: loadingIndicatorStyles,
                  option: (styles, { data }) => ({
                    ...styles,
                    color: data.status === "pending" ? theme.primary : theme.teal,
                  }),
                  singleValue: styles => ({
                    ...styles,
                    fontSize: "12px",
                    color: "rgb(151,151,151)",
                  }),
                  control: styles => ({
                    ...styles,
                    borderRadius: "3pt",
                    borderColor: "rgb(155,155,155)",
                  }),
                }}
                value={{
                  value: (staffMember && staffMember._id) || "none",
                  label: staffMember ? `${staffMember.firstName} ${staffMember.lastName}` : "No host",
                }}
                onChange={this.onHostChange}
                onMenuOpen={() => this.onHostsMenuOpen(bookingId)}
              />
            </div>
            <AppointmentActions>
              <Flex align="flex-start" style={{ flexWrap: "wrap" }}>
                <Flex justify="flex-start" style={{ whiteSpace: "nowrap", flexWrap: "wrap" }}>
                  {userHasAccess && selectedAppointment.host && !selectedAppointment.isDisabled && (
                    <Link to={`/calendars?staffId=${selectedAppointment.host}&sessionId=${selectedSession._id}`}>
                      <Icon
                        src={CalendarIcon}
                        style={{
                          margin: 0,
                          marginRight: "10px",
                          height: "20px",
                        }}
                      />
                    </Link>
                  )}
                  {meetingResp && (
                    <>
                      <span style={{ color: "rgb(151,151,151)", fontSize: "16px" }}>
                        {meetingResp.firstName + " " + meetingResp.lastName}
                      </span>{" "}
                      <span
                        style={{
                          whiteSpace: "pre",
                          color: "rgb(151,151,151)",
                          fontSize: "16px",
                        }}
                      >
                        {" " + meetingResp.phoneNumber}
                      </span>{" "}
                    </>
                  )}
                </Flex>
                <Flex align="flex-end" column>
                  <Time style={{ color: "rgb(151,151,151)", fontSize: "16px" }}>
                    <Icon
                      src={TimeIcon}
                      style={{
                        margin: 0,
                        marginRight: "10px",
                        cursor: "initial",
                      }}
                    />
                    {`${startTime} - ${endTime}`}
                  </Time>
                  {/* {delayDisabled && <div>delay: {delayDuration && delayDuration.label}</div>} */}
                  {delayDisabled && <div style={{ color: "red" }}>New arrival {delayDuration && newStart}</div>}
                </Flex>
              </Flex>
              <Flex
                style={{
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  bgcolor="#3a606e"
                  borderColor="#3a606e"
                  disabled={isDisabled}
                  minWidth="100px"
                  style={{ marginTop: "10px", width: "33%" }}
                  onClick={() => this.onToggleModal("cancelModal", bookingId)}
                >
                  Cancelled
                </Button>
                <Button
                  disabled={isDisabled || delayDisabled}
                  minWidth="100px"
                  style={{ marginTop: "10px", width: "33%" }}
                  onClick={() => this.onToggleModal("delayedModal", bookingId)}
                >
                  Delayed
                </Button>
                <Button
                  bgcolor={"#45cea2"}
                  borderColor={"#45cea2"}
                  disabled={isDisabled}
                  minWidth="100px"
                  style={{ marginTop: "10px", width: "33%" }}
                  onClick={() => this.onToggleModal("arrivedModal", bookingId)}
                >
                  Arrived
                </Button>
              </Flex>
            </AppointmentActions>
          </Flex>
        </Appointment>
      )

      if (isFirst || diffTime >= 3600) {
        if (diffTime > 3600) {
          timeStart = diffTime % 3600 !== 0 ? selectedAppointment.startTime - 1800 : selectedAppointment.startTime
        } else {
          timeStart = isFirst ? timeStart : selectedAppointment.startTime
        }
        isFirst = false

        return (
          <React.Fragment key={bookingId}>
            <TimeDivinder>
              <span>{moment.utc(timeStart, "X").format("HH:mm")}</span>
            </TimeDivinder>
            {Appoinmt()}
          </React.Fragment>
        )
      }
      return Appoinmt()
    })
  }

  render() {
    const {
      sessions,
      selectedSession,
      selectedSchedule,
      requestSchedulePending,
      clientsRequestPending,
      staffsRequestPending,
      retailersRequestPending,
      staffs,
      sessionOptions,
    } = this.props
    const activeSessionLength = sessionOptions[0].options.length

    if (sessions.length === 0 && !selectedSession) {
      return (
        <Container>
          <NotFound image>
            <img alt="modaresa" src={noSessionImage} />
            <Link to="/sessions/new">
              <Button>Create my first Sales Session</Button>
            </Link>
          </NotFound>
        </Container>
      )
    }

    const { selectedDayIndex, staffMember, selectedRoom, appointmentStatus, selectedSessionValue } = this.state
    const schedule = selectedSchedule && selectedSchedule.schedule
    const date = schedule && schedule[selectedDayIndex] && moment.utc(schedule[selectedDayIndex].date, "X")

    const isLoading = requestSchedulePending || clientsRequestPending || staffsRequestPending || retailersRequestPending

    return (
      <Container style={{ height: "100%" }}>
        <Header>
          <Flex justify="flex-start" style={{ width: "33%" }}>
            <div style={{ width: 200, marginRight: "10px" }}>
              <Select
                options={
                  selectedSession
                    ? [{ value: "all", label: "All Rooms" }].concat(
                        selectedSession.rooms.map(room => ({
                          value: room.name,
                          label: room.name,
                        })),
                      )
                    : []
                }
                placeholder="Select Room"
                value={selectedRoom}
                onChange={value => this.onFilterSelect(value, "selectedRoom")}
              />
            </div>
            <div style={{ width: 200 }}>
              {staffsRequestPending || !staffs?.data ? (
                <NotFound>
                  <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
                </NotFound>
              ) : (
                <Select
                  options={
                    selectedSession
                      ? [{ value: "all", label: "All Staff" }].concat(
                          selectedSession.staff.map(member => {
                            const staffMember = staffs.byId[member.staffId]
                            if (!staffMember) {
                              return null
                            }
                            return {
                              value: staffMember._id,
                              label: `${staffMember.firstName} ${staffMember.lastName}`,
                            }
                          }),
                        )
                      : []
                  }
                  placeholder="Select Staff"
                  value={staffMember}
                  onChange={value => this.onFilterSelect(value, "staffMember")}
                />
              )}
            </div>
          </Flex>
          <DateContainer style={{ width: "33%" }}>
            {date && (
              <React.Fragment>
                <Icon hidden={selectedDayIndex === 0} src={ArrowIcon} onClick={this.onPrevClick} />
                <Date>{date.format("dddd Do, MMMM")}</Date>
                <Icon
                  hidden={selectedDayIndex === selectedSchedule.schedule.length - 1}
                  rotate="true"
                  src={ArrowIcon}
                  onClick={this.onNextClick}
                />
              </React.Fragment>
            )}
          </DateContainer>
          <Flex justify="flex-end" style={{ width: "33%" }}>
            <div style={{ width: 200, marginRight: "10px" }}>
              <Select
                options={appointmentStatusOptions}
                value={appointmentStatus}
                onChange={value => this.onFilterSelect(value, "appointmentStatus")}
              />
            </div>
            <div style={{ width: 200 }}>
              <Select
                options={sessionOptions}
                placeholder="Select Session"
                value={selectedSessionValue}
                onChange={this.onSessionChange}
              />
            </div>
          </Flex>
        </Header>
        {activeSessionLength === 0 && !selectedSession && !requestSchedulePending && (
          <NotFound>
            <span>NO ACTIVE SALE SESSION</span>
          </NotFound>
        )}
        {isLoading && (
          <NotFound>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </NotFound>
        )}
        {selectedSession && !isLoading && <Appointments>{this.renderAppointments()}</Appointments>}
        {selectedSession && !isLoading && this.renderModal()}
      </Container>
    )
  }
}

Attendance.propTypes = {
  attendanceSchedule: PropTypes.array,
  availableHosts: PropTypes.array,
  brand: PropTypes.object,
  clients: PropTypes.object,
  requestError: PropTypes.string,
  requestHostsPending: PropTypes.bool,
  requestPending: PropTypes.bool,
  requestSchedulePending: PropTypes.bool,
  clientsRequestPending: PropTypes.bool,
  staffsRequestPending: PropTypes.bool,
  retailersRequestPending: PropTypes.bool,
  retailers: PropTypes.object,
  selectedSchedule: PropTypes.object,
  selectedSession: PropTypes.object,
  sessionOptions: PropTypes.array,
  sessions: PropTypes.array,
  staffs: PropTypes.object,
  user: PropTypes.object,
  onArrived: PropTypes.func,
  onDelayAppointment: PropTypes.func,
  onDeleteAppointment: PropTypes.func,
  onFetchHosts: PropTypes.func,
  onFetchSchedule: PropTypes.func,
  onFetchClients: PropTypes.func,
  onFetchStaffs: PropTypes.func,
  onFetchRetailers: PropTypes.func,
  onLeaveSocketRoom: PropTypes.func,
  onResetFilter: PropTypes.func,
  onSelectFilter: PropTypes.func,
  onUpdateHost: PropTypes.func,
}

export default Attendance

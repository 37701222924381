import { gql } from "@apollo/client"

import BrandFragments from "./brand.fragments"
import StaffFragments from "./staff.fragments"

export const BRAND_AREAS = gql`
  query BrandAreas($id: ID!) {
    Brand(id: $id) {
      id
      retailers(status: active) {
        id
        name
      }
      ...BrandAreas
    }
  }
  ${BrandFragments.fragments.areas}
`

export const BRAND_STAFFS = gql`
  query BrandStaff($id: ID!) {
    Brand(id: $id) {
      id
      staffs {
        ...StaffBasicInfos
        modaresaRole
      }
    }
  }
  ${StaffFragments.fragments.basicInfos}
`

export const BRAND_STAFFS_FULL = gql`
  query BrandStaff($id: ID!) {
    Brand(id: $id) {
      id
      toggleRoomBasedAreas
      staffs {
        id
        fullName
        modaresaRole
        market
        companyPosition
        hasEditorRights
        office {
          id
          name
        }
        geographicResponsibility {
          areas {
            id
            name
          }
          roomAreas {
            room {
              id
              name
            }
            area {
              id
              name
            }
          }
          territories {
            continents
            countries
            regions {
              name
            }
            counties {
              name
            }
            cities {
              name
            }
            retailers {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_BRAND_BUYERS = gql`
  query GetBrandBuyers(
    $id: ID!
    $status: DB_STATUS
    $restrictToStaffId: ID
    $restrictToRetailerId: ID
    $excludeBuyerIds: [ID!]
  ) {
    getBrandBuyers(
      id: $id
      status: $status
      restrictToStaffId: $restrictToStaffId
      restrictToRetailerId: $restrictToRetailerId
      excludeBuyerIds: $excludeBuyerIds
    ) {
      id
      fullName
      email
      position
      phoneNumber
      retailer {
        name
      }
    }
  }
`

export const BRAND_BUYERS = gql`
  query BrandBuyers(
    $id: ID!
    $status: DB_STATUS
    $restrictToStaffId: ID
    $buyersPage: Int
    $buyersLimit: Int
    $buyersSearch: BuyerSearch
  ) {
    Brand(id: $id) {
      id
      buyers(
        status: $status
        restrictToStaffId: $restrictToStaffId
        page: $buyersPage
        limit: $buyersLimit
        searchString: $buyersSearch
      ) {
        id
        fullName
        email
        position
        phoneNumber
        competitorGroup
        market
        retailer {
          id
          status
          name
          priorityGroup
          storeAddress {
            country
            city
          }
          meetingResponsible {
            id
            fullName
          }
        }
      }
    }
  }
`

export const BRAND_ROOMS = gql`
  query BrandRooms($id: ID!) {
    Brand(id: $id) {
      id
      rooms {
        id
        name
        hasBlockingList
        typeBlockingList
      }
    }
  }
`

export const BRAND_ROOMS_AREAS = gql`
  query BrandRooms($id: ID!) {
    Brand(id: $id) {
      id
      toggleRoomBasedAreas
      retailers(status: active) {
        id
        name
      }
      ...BrandRoomsAreas
    }
  }
  ${BrandFragments.fragments.roomsAreas}
`

export const BRAND_ROOMS_AND_AREAS = gql`
  query BrandRooms($id: ID!) {
    Brand(id: $id) {
      id
      toggleRoomBasedAreas
      retailers(status: active) {
        id
        name
      }
      ...BrandAreas
      ...BrandRoomsAreas
    }
  }
  ${BrandFragments.fragments.areas}
  ${BrandFragments.fragments.roomsAreas}
`

export const BRAND_RETAILERS = gql`
  query BrandRetailers($id: ID!, $restrictToStaffId: ID) {
    Brand(id: $id) {
      id
      retailers(status: active, restrictToStaffId: $restrictToStaffId) {
        id
        name
        storeAddress {
          city
          country
        }
      }
    }
  }
`

export const BRAND_RETAILERS_INFOS = gql`
  query BrandRetailers($id: ID!, $restrictToStaffId: ID) {
    Brand(id: $id) {
      id
      retailers(status: active, restrictToStaffId: $restrictToStaffId) {
        id
        name
        clientId
        status
        type
        priorityGroup
        nbBuyers
        storeAddress {
          country
          city
        }
      }
    }
  }
`

export const BRAND_OWNER = gql`
  query BrandOwner($id: ID!) {
    Brand(id: $id) {
      id
      name
      owner {
        ...StaffBasicInfos
      }
    }
  }
  ${StaffFragments.fragments.basicInfos}
`

export const BRAND_OFFICES = gql`
  query BrandOffices($id: ID!) {
    Brand(id: $id) {
      id
      offices {
        id
        name
      }
    }
  }
`

export const BRAND_COMPETITOR_GROUPS = gql`
  query BrandCompetitorGroups($id: ID!) {
    Brand(id: $id) {
      id
      competitorGroups {
        id
        name
        retailers {
          id
          name
        }
      }
    }
  }
`

export const BRAND_SALES_SESSION = gql`
  query BrandSalesSession($id: ID!, $statuses: [SALES_SESSION_STATUS!]) {
    Brand(id: $id) {
      id
      sessions(statuses: $statuses) {
        id
        name
        status
        openingDay
        closingDay
      }
    }
  }
`

export const BRAND_NB_CLIENTS = gql`
  query BrandNbClients($id: ID!, $restrictToStaffId: ID) {
    Brand(id: $id) {
      id
      nbBuyers(status: active, restrictToStaffId: $restrictToStaffId)
      nbStockists: nbBuyers(status: active, retailerStatus: stockist, restrictToStaffId: $restrictToStaffId)
      nbProspects: nbBuyers(status: active, retailerStatus: prospect, restrictToStaffId: $restrictToStaffId)
      nbRetailers(status: active, restrictToStaffId: $restrictToStaffId)
    }
  }
`

export const ALL_BRANDS = gql`
  query allBrands {
    allBrands {
      id
      name
    }
  }
`

import React, { memo, useCallback, useEffect, useRef } from "react"
import { CloseIcon } from "modaresa-commons/dist/components/icons"

import { ContainerNotifications, MargedToggler, ContainerClose, Title } from "./AreaNotificationsList.styles"

type Props = {
  area: {
    notificationMeetingConfirmation: boolean,
    notificationMeetingReminder: boolean,
  },
}

const AreaNotificationsList = ({ area, onChangeNotification, onClose }: Props) => {
  const refContainer = useRef(null)
  const handleChangeToggle = useCallback(field => checked => onChangeNotification(field, checked), [
    onChangeNotification,
  ])

  useEffect(() => {
    refContainer.current.scrollIntoView({ behavior: "smooth", block: "center" })
  }, [])
  return (
    <ContainerNotifications ref={refContainer}>
      <ContainerClose onClick={onClose}>
        <Title>Notifications</Title>
        <CloseIcon />
      </ContainerClose>
      <MargedToggler
        title="Meeting Confirmations - Manual"
        description={`This notification is to enable automated confirmations for when
    you or one of your colleagues manually insert an appointment for a client
    in any of your calendars (joker calendar included).`}
        checked={area.notificationMeetingConfirmation}
        onChange={handleChangeToggle("notificationMeetingConfirmation")}
      />
      <MargedToggler
        title="Meeting Reminders"
        description={"Sent out 24H prior to the appointment taken"}
        checked={area.notificationMeetingReminder}
        onChange={handleChangeToggle("notificationMeetingReminder")}
      />
    </ContainerNotifications>
  )
}

export default memo(AreaNotificationsList)

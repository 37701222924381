import { useState, useEffect } from "react"
import isNil from "lodash/isNil"

const useForm = (callback: Function, validate) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const setInitialState = param => {
    setValues(param && !isNil(param.values) ? param.values : {})
    setErrors(param && !isNil(param.errors) ? param.errors : {})
    setIsSubmitting(param && !isNil(param.isSubmitting) ? param.isSubmitting : false)
    setIsSent(param && !isNil(param.isSent) ? param.isSent : false)

    if (param && param.setValidate === true) {
      setErrors(validate(values))
    }
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      callback()
      setInitialState()
    }
  }, [errors, isSubmitting, isSent])

  const handleSubmit = event => {
    if (event) event.preventDefault()
    setErrors(validate(values))
    setIsSent(true)
    setIsSubmitting(true)
  }

  const handleChange = ({ event, name, value }) => {
    const fieldName = event ? event.target.name : name
    const fieldValue = event ? event.target.value : value

    if (event) event.persist()
    setIsSubmitting(false)

    const newValues = { ...values, [fieldName]: fieldValue }
    isSent && setErrors(validate(newValues))
    setValues(values => newValues)
  }

  return {
    handleChange,
    handleSubmit,
    setInitialState,
    values,
    errors,
  }
}

export default useForm

import { Button } from "modaresa-commons"
import styled from "styled-components"

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 60px 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const FooterLeft = styled.div``
export const FooterRight = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 60px;
`

export const DeleteButton = styled(Button)`
  margin-right: 10px;
  background-color: ${props => props.theme.secondary};
  border-color: ${props => props.theme.secondary};
`

import { Formik, Form } from "formik"
import React, { memo, useState } from "react"
import * as yup from "yup"
import { Button } from "modaresa-commons"

import { languagesOptions } from "../../../config"
import { optionsRoles, optionsRolesWithoutOwner } from "../../../config/settings"
import getAreasAndTerritoriesOptionsValuesFromStaff from "../../../helpers/areasAndTerritories/getAreasAndTerritoriesOptionsValuesFromStaffGraphql"
import { isStaffAdmin } from "../../../helpers/users"
import { yupSelectOption } from "../../../helpers/yup"
import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"
import FormikInput from "../FormikFields/FormikInput"
import FormikPhoneNumber from "../FormikFields/FormikPhoneNumber"
import FormikSelect from "../FormikFields/FormikSelect"
import FormikToggle from "../FormikFields/FormikToggle"

import FieldAreasAndTerritories from "./FieldAreasAndTerritories"
import FieldEmail from "./FieldEmail"
import FieldOffices from "./FieldOffices"

type Props = {
  staff: {},
  brandId: {},
  onSubmit: Function,
  readOnly: boolean,
  isEmailDisabled: boolean,
  labelSubmit: string,
  loadingSubmit: boolean,
  showEmailVerification: boolean,
  canEditAccessRights: boolean,
  canEditAreasAndTerritories: boolean,
}

const staffGeneralInfosSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Required"),
  lastName: yup
    .string()
    .trim()
    .required("Required"),
  phoneNumber: yup.string(),
  email: yup
    .string()
    .email()
    .when("modaresaRole", {
      is: modaResaRole => !(modaResaRole?.value === "freelancer"),
      then: yup.string().required("Required"),
    }),
  office: yupSelectOption.required("Required"),
  companyPosition: yup
    .string()
    .trim()
    .required("Required"),
  modaresaRole: yupSelectOption.required("Required"),
  areasAndTerritories: yup.array().of(yupSelectOption),
  languages: yup.array().of(yupSelectOption),
  hasEditorRights: yup.boolean(),
})

const defaultStaffGeneralInfos = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  office: null,
  companyPosition: "",
  modaresaRole: null,
  areasAndTerritories: [],
  languages: [{ label: "English", value: "en" }],
  hasEditorRights: false,
}
const labelWidth = "192px"

const FormStaffGeneralInfos = ({
  staff,
  brandId,
  onSubmit,
  readOnly,
  isEmailDisabled,
  labelSubmit,
  loadingSubmit,
  showEmailVerification,
  canEditAccessRights,
  canEditAreasAndTerritories,
}: Props) => {
  const [initialValues] = useState(() =>
    staff
      ? {
          firstName: staff.firstName,
          lastName: staff.lastName,
          phoneNumber: staff.phoneNumber,
          email: staff.email,
          office: staff.office ? { value: staff.office.id, label: staff.office.name } : undefined,
          companyPosition: staff.companyPosition,
          modaresaRole: staff.modaresaRole ? optionsRoles.find(role => role.value === staff.modaresaRole) : undefined,
          areasAndTerritories: getAreasAndTerritoriesOptionsValuesFromStaff({
            geographicResponsibility: staff.geographicResponsibility,
            toggleRoomBasedAreas: staff.brand?.toggleRoomBasedAreas,
          }),
          languages: staff.languages.map(lang => ({
            label: lang.name,
            value: lang.isoCode,
          })),
          hasEditorRights: staff.hasEditorRights || false,
        }
      : defaultStaffGeneralInfos,
  )
  return (
    <Formik initialValues={initialValues} validateOnBlur validationSchema={staffGeneralInfosSchema} onSubmit={onSubmit}>
      {({ values }) => {
        const isAdmin = isStaffAdmin(values.modaresaRole?.value)
        const isFreelancer = values.modaresaRole?.value === "freelancer"
        const isAgent = values.modaresaRole?.value === "agent"
        return (
          <Form>
            <FormikInput
              direction="row"
              labelWidth={labelWidth}
              label="First Name*"
              name="firstName"
              disabled={readOnly}
            />
            <FormikInput
              direction="row"
              labelWidth={labelWidth}
              label="Last Name*"
              name="lastName"
              disabled={readOnly}
            />
            <FormikPhoneNumber
              direction="row"
              labelWidth={labelWidth}
              label="Phone Number"
              name="phoneNumber"
              disabled={readOnly}
            />
            <FieldEmail
              staffId={staff?.id}
              labelWidth={labelWidth}
              disabledEmail={isEmailDisabled || readOnly}
              disabledEmailVerification={readOnly}
              isEmailVerified={staff?.emailVerified}
              required={!isFreelancer}
              showVerification={showEmailVerification && !isFreelancer}
            />
            <FieldOffices
              brandId={brandId}
              selectWidth="100%"
              labelWidth={labelWidth}
              name="office"
              placeholder="Select office"
              disabled={readOnly}
            />
            <FormikInput
              direction="row"
              labelWidth={labelWidth}
              label="Company Position*"
              name="companyPosition"
              disabled={readOnly}
            />
            <FormikSelect
              label="Modaresa Role*"
              selectWidth="100%"
              labelWidth={labelWidth}
              name="modaresaRole"
              options={optionsRolesWithoutOwner}
              placeholder="Select role"
              disabled={!canEditAccessRights || staff?.modaresaRole === "owner"}
            />
            <FieldAreasAndTerritories
              brandId={brandId}
              labelWidth={labelWidth}
              disabled={!canEditAreasAndTerritories}
            />
            <FormikSelect
              label="Languages Spoken"
              selectWidth="100%"
              labelWidth={labelWidth}
              name="languages"
              options={languagesOptions}
              multiple
              placeholder="Select languages"
              disabled={readOnly}
            />
            {!isAdmin && !isFreelancer && !isAgent && (
              <FormikToggle
                direction="row"
                labelWidth={labelWidth}
                label="Editor Rights"
                name="hasEditorRights"
                helpText="A staff member with editor rights can create sessions and edit staff."
                disabled={!canEditAccessRights}
              />
            )}
            <FormFieldLayout label=" " labelWidth={labelWidth}>
              <Button minWidth="100%" type="submit" disabled={readOnly || loadingSubmit}>
                {labelSubmit}
              </Button>
            </FormFieldLayout>
          </Form>
        )
      }}
    </Formik>
  )
}

FormStaffGeneralInfos.defaultProps = {
  labelSubmit: "Save Changes",
}
export default memo(FormStaffGeneralInfos)

import { combineReducers } from "redux"

import { LOGOUT } from "../actions/auth"

import auth from "./auth"
import brand from "./brand"
import clients from "./clients"
import retailers from "./retailers"
import salesSessions from "./salesSessions"
import schedules from "./schedules"
import sockets from "./sockets"
import staff from "./staff"
import user from "./user"

const appReducer = combineReducers({
  clients,
  staff,
  salesSessions,
  brand,
  user,
  auth,
  schedules,
  retailers,
  sockets,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer

import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import Select from "react-select"
import { Button, Flex } from "modaresa-commons"

import noSessionImage from "../../../../../static/Modaresa_VTC-01.png"

import { CancellationsList } from "./CancellationsList"
import { Container, Header, NotFound, Tab } from "./styles"

export class Requests extends Component {
  constructor(props) {
    super(props)
    const { selectedSession } = this.props
    this.state = {
      tabIndex: 1,
      selectedSession: selectedSession ? { value: selectedSession._id, label: selectedSession.name } : null,
    }
  }

  componentDidMount() {
    const { onFetchCancellations, brand, selectedSession } = this.props
    if (selectedSession) {
      onFetchCancellations(brand._id, selectedSession._id)
    }
  }

  onTabClick = index => {
    const { onFetchRequests, brand, onFetchCancellations } = this.props
    const { selectedSession } = this.state
    if (selectedSession) {
      if (selectedSession.value !== "all") {
        if (index === 0) {
          onFetchRequests(brand._id, selectedSession.value)
        } else {
          onFetchCancellations(brand._id, selectedSession.value)
        }
      } else if (index === 0) {
        onFetchRequests(brand._id)
      } else {
        onFetchCancellations(brand._id)
      }
    }
    this.setState({ tabIndex: index })
  }

  onSessionChange = value => {
    const { onFetchRequests, brand, onFetchCancellations } = this.props
    const { tabIndex } = this.state
    this.setState({ selectedSession: value })
    if (value.value !== "all") {
      if (tabIndex === 0) {
        onFetchRequests(brand._id, value.value)
      } else {
        onFetchCancellations(brand._id, value.value)
      }
    } else if (tabIndex === 0) {
      onFetchRequests(brand._id)
    } else {
      onFetchCancellations(brand._id)
    }
  }

  render() {
    const { sessions, requestFetchCancellationsPending, clientCancellations, sessionOptions } = this.props
    const { selectedSession, tabIndex } = this.state
    const activeSessionLength = sessionOptions[0].options.length
    if (sessions.length === 0 && !selectedSession) {
      return (
        <Container>
          <NotFound image>
            <img alt="modaresa" src={noSessionImage} />
            <Link to="/sessions/new">
              <Button>Create my first Sales Session</Button>
            </Link>
          </NotFound>
        </Container>
      )
    }
    return (
      <Container style={{ height: "100%" }}>
        <Header>
          <Flex justify={"flex-start"} style={{ width: "calc(100% - 250px)" }}>
            <Tab className={tabIndex === 1 ? "active" : ""} onClick={() => this.onTabClick(1)}>
              <span>{`Client Cancellations`}</span>
            </Tab>
          </Flex>
          <div style={{ width: "200px" }}>
            <Select
              options={sessionOptions}
              placeholder="Select Session"
              value={selectedSession}
              onChange={this.onSessionChange}
            />
          </div>
        </Header>
        {activeSessionLength === 0 && !selectedSession && (
          <NotFound>
            <span>NO ACTIVE SALE SESSION</span>
          </NotFound>
        )}
        {tabIndex === 1 && selectedSession && (
          <CancellationsList
            cancellations={clientCancellations}
            requestFetchPending={requestFetchCancellationsPending}
          />
        )}
      </Container>
    )
  }
}

Requests.propTypes = {
  brand: PropTypes.object,
  clientCancellations: PropTypes.array,
  requestFetchCancellationsPending: PropTypes.bool,
  requestFetchPending: PropTypes.bool,
  requests: PropTypes.array,
  selectedSession: PropTypes.object,
  sessionOptions: PropTypes.array,
  sessions: PropTypes.array,
  onFetchCancellations: PropTypes.func,
  onFetchRequests: PropTypes.func,
}

export default Requests

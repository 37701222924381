import PropTypes from "prop-types"
import React from "react"
import Select from "react-select"
import { theme } from "modaresa-commons"

import { SelectContainer, ValidationError } from "components/ui/Select/common"

const control = base => ({
  ...base,
  borderColor: theme.strawberry,
})

const menuList = base => ({
  ...base,
  marginTop: "0px",
})

const Multiselect = props => {
  const { onChange, options, value, name, errors, touched, width } = props
  return (
    <SelectContainer width={width}>
      <Select
        {...props}
        options={options}
        styles={{
          control: errors ? control : "",
          menu: menuList,
        }}
        value={value}
        onChange={value => onChange(value, name)}
      />
      {errors && touched && <ValidationError>{errors}</ValidationError>}
    </SelectContainer>
  )
}
Multiselect.propTypes = {
  errors: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  touched: PropTypes.bool,
  value: PropTypes.any,
  width: PropTypes.string,
  onChange: PropTypes.func,
}

export { Multiselect }

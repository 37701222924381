import axios from "axios"

import { REST_API_URL } from "../../config"

export const fetchSaleSessions = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/sale-sessions?brand=${brandId}`, config)

  return response.data
}

export const fetchSession = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/sale-sessions/${id}`, config)

  return response.data
}

export const createSession = async ({ data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/sale-sessions`, data, config)

  return response.data
}

export const updateSession = async ({ data, id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.put(`${REST_API_URL}/sale-sessions/${id}`, data, config)

  return response.data
}

export const deleteSession = async ({ id, token, query }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.delete(`${REST_API_URL}/sale-sessions/${id}${query}`, config)

  return response.data
}

export const activateSession = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/sale-sessions/${id}/activate`, {}, config)

  return response.data
}

export const inviteClients = async ({ token, clients, sessionId, brandId }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/bookings`, { clients, sessionId, brandId }, config)

  return response.data
}

export const sendFollowups = async ({ token, sessionId, clients }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/bookings/followup`, { sessionId, clients }, config)

  return response.data
}

export const updateAvailabilities = async ({ token, data, sessionId, staffId }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.put(`${REST_API_URL}/sale-sessions/${sessionId}/staff/${staffId}/schedule`, data, config)

  return response.data
}

export const fetchCapacity = async ({ sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const response = await axios.get(`${REST_API_URL}/dashboard/${sessionId}/capacity`, config)

  return response.data
}

export const fetchSessionReport = async ({ sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/reports/export/session/${sessionId}`, config)

  return response.data
}

export const addAttachemntToInvite = async ({ sessionId, token, file }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const data = {
    files: file,
  }

  const response = await axios.post(`${REST_API_URL}/sale-sessions/${sessionId}/attachments`, data, config)

  return response.data
}

export const deleteAttachment = async ({ data }) => {
  const config = {
    params: {
      index: data.index,
    },
    headers: { Authorization: `Bearer ${data.token}` },
  }

  const response = await axios.delete(`${REST_API_URL}/sale-sessions/${data.sessionId}/attachments`, config)

  return response.data
}

import React, { Component } from "react"
import PropTypes from "prop-types"
import isEmpty from "lodash/isEmpty"
import { Button } from "modaresa-commons"

import { ButtonsRow, Form } from "../styles"

class NewPasswordForm extends Component {
  state = {
    values: {
      password: "",
      confirmPassword: "",
    },
    errors: {},
    touched: {},
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "password":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "confirmPassword":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (values.password !== value) {
          errors[name] = `Passwords need to match`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onSubmit = () => {
    const { values, touched, errors } = this.state
    const { onResetPassword } = this.props

    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      onResetPassword(values.password)
    } else {
      this.setState({ errors, touched })
    }
  }

  render() {
    const { errors, touched, values } = this.state
    const { btnText } = this.props
    return (
      <React.Fragment>
        <Form>
          <div>
            <input
              name="password"
              placeholder="New Password"
              type="password"
              value={values.password}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
            {errors.password && touched.password && <span>{errors.password}</span>}
          </div>
          <div>
            <input
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              value={values.confirmPassword}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
            {errors.confirmPassword && touched.confirmPassword && <span>{errors.confirmPassword}</span>}
          </div>
        </Form>
        <ButtonsRow style={{ marginTop: "25px" }}>
          <Button onClick={this.onSubmit}>{btnText}</Button>
        </ButtonsRow>
      </React.Fragment>
    )
  }
}

NewPasswordForm.propTypes = {
  btnText: PropTypes.string,
  onResetPassword: PropTypes.func,
}

export { NewPasswordForm }

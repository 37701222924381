import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import Select from "react-select"
import { SyncLoader } from "react-spinners"
import XLSX from "xlsx"
import moment from "moment-timezone"
import { Button, Flex } from "modaresa-commons"

import noSessionImage from "../../../../../static/Modaresa_VTC-01.png"

import { Showroom } from "./Showroom"
import { Overview } from "./Overview"
import { Catering } from "./Catering"
import { Container, Header, NotFound, Tab } from "./styles"

export class Capacity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tabIndex: 0,
      selectedRoom: { value: "all", label: "All Rooms" },
    }
  }

  componentDidMount() {
    const { onFetchCapacity, selectedSession, sessionOptions } = this.props
    if (selectedSession) {
      onFetchCapacity(selectedSession._id)
    } else if (sessionOptions[0].options.length > 0) {
      onFetchCapacity(sessionOptions[0].options[0].value)
    }
  }

  onSessionChange = session => {
    this.props.onFetchCapacity(session.value)
  }

  onTabClick = index => {
    this.setState({ tabIndex: index })
  }

  onRoomChange = value => {
    this.setState({ selectedRoom: value })
  }

  onExportExcel = () => {
    const { selectedSession, sessionCapacity } = this.props
    const { selectedRoom, tabIndex } = this.state
    const aoo = []
    const capacity = sessionCapacity.map(item => item.filter(day => day.roomName === selectedRoom.value))

    if (tabIndex === 0) {
      capacity.forEach((data, index) => {
        aoo.push({
          "Seles Session Name": index === 0 ? selectedSession.name : "",
          Date: moment.utc(data[0].date, "X").format("DD/MM/YYYY"),
          Breakfast: data[0].breakfast,
          Lunch: data[0].lunch,
          Snacks: data[0].snacks,
          Models: data[0].models,
        })
      })

      const ws = XLSX.utils.json_to_sheet(aoo)
      const wscols = [{ wch: 25 }, { wch: 12 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }]
      ws["!cols"] = wscols
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, `Catering&Models - ${selectedRoom.label}`)
      XLSX.writeFile(
        wb,
        `${moment().format("DD/MM/YYYY")}-${selectedSession.name}-Catering&Models-${selectedRoom.label}.xlsx`,
      )
    }
    if (tabIndex === 1) {
      capacity.forEach((data, index) => {
        const { buyingAppointment, walkthrough, businessMeeting } = data[0].appointments
        const { arrived, cancelled, delayed } = data[0].appointmentsStatus
        const dayEnd = selectedSession.openingHours[index].to
        const todayTime = moment().unix()
        const isDayEnd = todayTime > dayEnd
        aoo.push({
          "Seles Session Name": index === 0 ? selectedSession.name : "",
          Date: moment.utc(data[0].date, "X").format("DD/MM/YYYY"),
          "Total Scheduled Appointments": buyingAppointment + walkthrough + businessMeeting,
          "Buying Appointment": buyingAppointment,
          Walkthrough: walkthrough,
          "Business Meeting": businessMeeting,
          Delayed: isDayEnd ? delayed : "",
          Cancelled: isDayEnd ? cancelled : "",
          Completed: isDayEnd ? arrived : "",
        })
      })
      const ws = XLSX.utils.json_to_sheet(aoo)
      const wscols = [
        { wch: 25 },
        { wch: 12 },
        { wch: 27 },
        { wch: 19 },
        { wch: 12 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]
      ws["!cols"] = wscols
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, `Appointments - ${selectedRoom.label}`)
      XLSX.writeFile(
        wb,
        `${moment().format("DD/MM/YYYY")}-${selectedSession.name}-AppointmentsOverview-${selectedRoom.label}.xlsx`,
      )
    }
  }

  render() {
    const { sessions, selectedSession, requestPending, sessionOptions } = this.props
    const activeSessionLength = sessionOptions[0].options.length

    if (sessions.length === 0 && !selectedSession) {
      return (
        <Container style={{ height: "100vh" }}>
          <NotFound image>
            <img alt="modaresa" src={noSessionImage} />
            <Link to="/sessions/new">
              <Button>Create my first Sales Session</Button>
            </Link>
          </NotFound>
        </Container>
      )
    }

    const { selectedRoom, tabIndex } = this.state
    const { sessionCapacity, brand } = this.props
    const capacity =
      sessionCapacity && sessionCapacity.map(item => item.filter(day => day.roomName === selectedRoom.value))
    return (
      <Container style={{ height: "100%" }}>
        <Header>
          <Flex justify="flex-start" style={{ width: "60%", background: "#fff" }}>
            <Tab className={tabIndex === 0 ? "active" : ""} onClick={() => this.onTabClick(0)}>
              <span>{`Catering & Models`}</span>
            </Tab>
            <Tab className={tabIndex === 1 ? "active" : ""} onClick={() => this.onTabClick(1)}>
              <span>Appointments Overview</span>
            </Tab>
          </Flex>
          <Flex justify="space-between" style={{ width: "40%", paddingLeft: "10px" }}>
            <div style={{ width: "40%", marginRight: "10px" }}>
              <Select
                options={
                  selectedSession
                    ? [{ value: "all", label: "All Rooms" }].concat(
                        selectedSession.rooms.map(room => ({
                          value: room.name,
                          label: room.name,
                        })),
                      )
                    : []
                }
                placeholder="Select Room"
                value={selectedRoom}
                onChange={this.onRoomChange}
              />
            </div>
            <div style={{ width: "40%", marginRight: "10px" }}>
              <Select
                options={sessionOptions}
                placeholder="Select Session"
                value={
                  selectedSession && {
                    value: selectedSession._id,
                    label: selectedSession.name,
                  }
                }
                onChange={this.onSessionChange}
              />
            </div>
            <Button
              bgcolor="#516CEF"
              borderColor="#516CEF"
              minWidth="130px"
              style={{ width: "18%" }}
              onClick={this.onExportExcel}
            >
              Export Report
            </Button>
          </Flex>
        </Header>
        {!requestPending && activeSessionLength === 0 && !selectedSession && (
          <NotFound>
            <span>NO ACTIVE SALE SESSION</span>
          </NotFound>
        )}
        {requestPending && (
          <NotFound>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </NotFound>
        )}
        {!requestPending && selectedSession && sessionCapacity && tabIndex === 0 && <Catering capacity={capacity} />}
        {!requestPending && selectedSession && sessionCapacity && tabIndex === 1 && (
          <Overview capacity={capacity} selectedSession={selectedSession} />
        )}
        {!requestPending && selectedSession && sessionCapacity && tabIndex === 2 && <Showroom brand={brand} />}
      </Container>
    )
  }
}

Capacity.propTypes = {
  brand: PropTypes.object,
  requestPending: PropTypes.bool,
  selectedSession: PropTypes.object,
  sessionCapacity: PropTypes.array,
  sessionOptions: PropTypes.array,
  sessions: PropTypes.array,
  onFetchCapacity: PropTypes.func,
}

export default Capacity

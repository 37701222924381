import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { finishLogin } from "../../../store/actions/auth"

import Component from "./Callback"

const selector = createStructuredSelector({})

const actions = {
  onFinishLogin: finishLogin,
}

export const Callback = connect(selector, actions)(Component)

import { useMutation } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import { ModalConfirmation } from "modaresa-commons"

import { UPDATE_RETAILER_DB_STATUS } from "../../../../graphql/mutations/retailer"
import { onToastError, onToastSuccess } from "../../../../helpers/toast"
import { DeleteButton } from "../../../ui/DeleteButton"
import { stopPropagation } from "../../../../helpers/utils"

type Props = {
  brandId: string,
  retailer: {
    id: string,
    name: string,
  },
}

const ButtonDeleteRetailer = ({ retailer, brandId }: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updateRetailerDbStatus] = useMutation(UPDATE_RETAILER_DB_STATUS)
  const handleToggleModal = useCallback(state => () => setOpenModal(state), [])
  const handleConfirm = useCallback(async () => {
    setLoading(true)
    try {
      await updateRetailerDbStatus({
        variables: { id: retailer.id, dbStatus: "archived" },
      })
      onToastSuccess(`${retailer.name} has been archived successfully`)
    } catch (e) {
      console.error(e)
      onToastError(`Error happened while archiving ${retailer.name}`)
    }
    setOpenModal(false)
    setLoading(false)
  }, [retailer, updateRetailerDbStatus])
  return (
    <span onClick={stopPropagation}>
      <DeleteButton onClick={handleToggleModal(true)} />
      {openModal && (
        <ModalConfirmation
          isOpen
          title={`Delete ${retailer.name}`}
          text="Are you sure that you want to delete this retailer ?"
          onClickOk={handleConfirm}
          onClose={handleToggleModal(false)}
          loading={loading}
        />
      )}
    </span>
  )
}

export default memo(ButtonDeleteRetailer)

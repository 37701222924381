import styled from "styled-components"
import { SwapIcon } from "modaresa-commons/dist/components/icons"

import Booking from "./Booking"

export const ContainerBookings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

export const Flex = styled.div`
  flex: 1;
`

export const IconSwitch = styled(SwapIcon)`
  font-size: 32px;
  flex: 0.5;
`

export const Date = styled.div`
  margin: 8px 0 32px;
  font-size: 16px;
  text-align: center;
`

export const MainBooking = styled(Booking)`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px;
  border: 1px solid ${p => p.theme.main};
  border-radius: 4px;
`

import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist"

import { GRAPHQL_URL } from "../config"
import customFetch from "../helpers/customFetch"

/* When implementing subscriptions is needed
const wsLink = new WebSocketLink({
  uri: SUBSCRIPTIONS_LINK,
  options: {
    reconnect: true,
    reconnectionAttempts: 50,
    timeout: 20000,
    connectionParams: () => {
      const token = localStorage.getItem("modaresa-access-token")
      return { Authorization: token ? `Bearer ${token}` : undefined }
    },
  },
}) */

/* When implementing subscriptions is needed
const queryLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === "OperationDefinition" && operation === "subscription"
  },
  // wsLink,
  httpLink,
)*/

const SCHEMA_VERSION = "1.02" // Must be a string.
const SCHEMA_VERSION_KEY = "apollo-schema-version"

const setupApolloClient = async () => {
  const httpLink = createUploadLink({
    uri: GRAPHQL_URL,
    fetch: customFetch,
    credentials: "same-origin",
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("modaresa-access-token")
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(e => {
        if (!e) return
        const { message: errorMsg } = e
        console.error(errorMsg)
      })
    }
    if (networkError) {
      if (networkError && networkError.bodyText && networkError.bodyText.includes("invalid signature")) {
        /* Fail safely for now, we don't want to show a popup to users with previous invalid tokens */
      } else {
        console.error(networkError)
      }
    }
  })

  const link = ApolloLink.from([authLink, errorLink, httpLink])
  const cache = new InMemoryCache({
    dataIdFromObject: object => object.id,
    typePolicies: {
      Brand: {
        fields: {
          buyers: {
            merge: (existing, incoming) => incoming,
          },
        },
      },
    },
  })

  const persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  })

  const currentVersion = await localStorage.getItem(SCHEMA_VERSION_KEY)
  if (currentVersion === SCHEMA_VERSION) {
    await persistor.restore()
  } else {
    await persistor.purge()
    await localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  }

  return new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  })
}

export default setupApolloClient
//export { wsLink }

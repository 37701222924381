import { gql } from "@apollo/client"

export const UPLOAD_BUYERS_LIST = gql`
  mutation UploadBuyersList($brandId: ID!, $file: Upload!, $onlyPreviewChanges: Boolean) {
    uploadBuyersList(brandId: $brandId, file: $file, onlyPreviewChanges: $onlyPreviewChanges) {
      errors
      changes
      logsNewRetailers
      logsNewSharedRetailers
      logsUnarchiveRetailers
    }
  }
`

import { useMutation, useQuery } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import { AlertDanger } from "modaresa-commons"

import { UPDATE_SALES_SESSION_ROOMS_AREAS } from "../../../../../graphql/mutations/salesSession"
import { SALES_SESSION_ROOMS_AREAS } from "../../../../../graphql/queries/salesSession"
import { formatRemainingAreaInput, formatSalesSessionAreaInput } from "../../../../../helpers/areas"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"

import SalesSessionAreasRoomBasedView from "./SalesSessionAreasRoomBasedView"

type Props = {
  salesSessionId: string,
  canEdit: boolean,
  onSaveChanges: Function,
}

const SalesSessionAreasRoomBased = ({ salesSessionId, canEdit, onSaveChanges }: Props) => {
  const [isChanged, setIsChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [rooms, setRooms] = useState([])
  const [updateSalesSessionRoomsAreas] = useMutation(UPDATE_SALES_SESSION_ROOMS_AREAS)
  const handleChangeRooms = useCallback(updatedRooms => {
    setRooms(updatedRooms)
    setIsChanged(true)
  }, [])
  const handleSubmit = useCallback(async () => {
    setIsLoading(true)

    const formattedRooms = rooms.map(room => ({
      id: room.id,
      areas: room.areas.map(formatSalesSessionAreaInput),
      remainingArea: room.remainingArea && formatRemainingAreaInput(room.remainingArea),
    }))
    const variables = {
      salesSessionId,
      rooms: formattedRooms,
    }
    try {
      await updateSalesSessionRoomsAreas({ variables })
      onToastSuccess("Rooms Areas settings updated successfully!")
      if (onSaveChanges) {
        onSaveChanges()
      }
    } catch (e) {
      console.error(e)
      onToastError("Error while updating rooms areas settings")
    }
    setIsLoading(false)
  }, [rooms, salesSessionId, updateSalesSessionRoomsAreas, onSaveChanges])
  const { loading, error, data } = useQuery(SALES_SESSION_ROOMS_AREAS, {
    variables: {
      id: salesSessionId,
    },
    onCompleted: data => {
      setRooms(data.SalesSession.rooms)
      setIsLoading(false)
    },
  })
  if (loading && !data) return <SalesSessionAreasRoomBasedView isLoadingData />
  if (error) return <AlertDanger>Can't load sales session rooms areas</AlertDanger>
  const { brand, rooms: loadedRooms } = data.SalesSession
  const disableSaveChanges = isLoading
  const labelSaveChanges = isChanged ? "Save changes" : "Next"
  const roomsToDisplay = isLoading ? loadedRooms : rooms
  return (
    <SalesSessionAreasRoomBasedView
      brandId={brand.id}
      rooms={roomsToDisplay}
      canRoomAreaStaffsBeModified={canEdit}
      disableSaveChanges={disableSaveChanges}
      labelSaveChanges={labelSaveChanges}
      onChangeRooms={handleChangeRooms}
      onSaveChanges={handleSubmit}
    />
  )
}

export default memo(SalesSessionAreasRoomBased)

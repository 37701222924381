import React, { memo } from "react"
import { SkeletonBlock } from "modaresa-commons"

import TableRooms from "../../../../elements/TableRooms"

import { ContainerRoomsView, HeaderRoomsView, TotalRooms } from "./RoomsView.styles"

const RoomsView = () => (
  <ContainerRoomsView>
    <HeaderRoomsView>
      <TotalRooms>
        <SkeletonBlock width="150px" margin="9px 0 8px 0" height="20px" />
      </TotalRooms>
    </HeaderRoomsView>
    <TableRooms loading />
  </ContainerRoomsView>
)

export default memo(RoomsView)

import { useMutation, useQuery } from "@apollo/client"
import React, { memo, useCallback, useMemo, useState } from "react"
import { connect } from "react-redux"

import { SWITCH_MEETING_HOSTS } from "../../../graphql/mutations/bookingEvent"
import { GET_PARALLEL_BOOKING_EVENTS } from "../../../graphql/queries/bookingEvent"
import { getGraphQLErrorAsString } from "../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../helpers/toast"
import { updateSwitchMeetingHosts } from "../../../store/actions/schedules"

import ModalSwitchMeetingHostView from "./ModalSwitchMeetingHostView"

type Props = {
  bookingEventId: string,
  onClose: Function,
  onSwitchMeetingHosts: Function,
}

const ModalSwitchMeetingHost = ({ bookingEventId, onClose, onSwitchMeetingHosts }: Props) => {
  const [switchMeetingHosts] = useMutation(SWITCH_MEETING_HOSTS)
  const [bookingEventToSwitch, setBookingEventToSwitch] = useState(null)

  const handleSwitchHosts = useCallback(async () => {
    try {
      const res = await switchMeetingHosts({
        variables: {
          bookingEventIdA: bookingEventId,
          bookingEventIdB: bookingEventToSwitch.id,
        },
      })
      const { bookingEventA, bookingEventB } = res.data.switchBookingEventsMeetingHosts
      onSwitchMeetingHosts({ bookingEventA, bookingEventB })
      onToastSuccess("Meeting hosts switched successfully")
      onClose()
    } catch (e) {
      console.error(e)
      onToastError("An error occured while switching meeting hosts", getGraphQLErrorAsString(e))
    }
  }, [bookingEventToSwitch, bookingEventId, switchMeetingHosts, onClose, onSwitchMeetingHosts])
  const handleChangeBookingEventToSwitch = useCallback(newBookingEvent => setBookingEventToSwitch(newBookingEvent), [])
  const { data, loading, error } = useQuery(GET_PARALLEL_BOOKING_EVENTS, {
    variables: {
      bookingEventId,
    },
  })
  const actions = useMemo(
    () => [
      {
        label: "Swap Hosts",
        onClick: handleSwitchHosts,
        disabled: !bookingEventToSwitch,
        primary: true,
      },
    ],
    [bookingEventToSwitch, handleSwitchHosts],
  )
  if (loading) return <ModalSwitchMeetingHostView loading={loading} />
  if (error) return <ModalSwitchMeetingHostView error={error} />

  const {
    getParallelBookingEvents: { parallelBookingEvents, bookingEvent },
  } = data
  return (
    <ModalSwitchMeetingHostView
      booking={bookingEvent}
      parallelBookingEvents={parallelBookingEvents}
      onClose={onClose}
      actions={actions}
      onChangeBookingEventToSwitch={handleChangeBookingEventToSwitch}
    />
  )
}

const actions = {
  onSwitchMeetingHosts: updateSwitchMeetingHosts,
}
export default memo(connect(() => ({}), actions)(ModalSwitchMeetingHost))

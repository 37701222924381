import { Formik, Form } from "formik"
import React, { memo, useState } from "react"
import { Button } from "modaresa-commons"

import FormFieldLayout from "../../FormFieldLayout/FormFieldLayout"
import FormikInput from "../../FormikFields/FormikInput"

import { videoTools, videoToolsSchema } from "./FormVirtualMeetingIds.fields"

const defaultStaffVideoIds = {
  whatsapp: "",
  facetime: "",
  skype: "",
  zoom: "",
  googlemeet: "",
  msteams: "",
  whereby: "",
}
const labelWidth = "192px"

type Props = {
  videoIds: {},
  onSubmit: {},
  loadingSubmit: boolean,
  readOnly: boolean,
}
const FormVirtualMeetingIds = ({ videoIds, onSubmit, loadingSubmit, readOnly }: Props) => {
  const [initialValues] = useState(() =>
    videoIds
      ? {
          whatsapp: videoIds.whatsapp || "",
          facetime: videoIds.facetime || "",
          skype: videoIds.skype || "",
          zoom: videoIds.zoom || "",
          googlemeet: videoIds.googlemeet || "",
          whereby: videoIds.whereby || "",
          msteams: videoIds.msteams || "",
        }
      : defaultStaffVideoIds,
  )
  return (
    <Formik initialValues={initialValues} validateOnBlur validationSchema={videoToolsSchema} onSubmit={onSubmit}>
      {() => (
        <Form>
          {videoTools.map(tool => (
            <FormikInput
              key={tool.name}
              direction="row"
              labelWidth={labelWidth}
              label={tool.title}
              name={tool.name}
              disabled={readOnly}
              placeholder={tool.placeholder}
            />
          ))}
          <FormFieldLayout label=" " labelWidth={labelWidth}>
            <Button minWidth="100%" type="submit" disabled={readOnly || loadingSubmit}>
              Save changes
            </Button>
          </FormFieldLayout>
        </Form>
      )}
    </Formik>
  )
}

export default memo(FormVirtualMeetingIds)

import { createAsyncAction } from "../../helpers/redux"

export const FETCH_BRAND = createAsyncAction("FETCH_BRAND")
export const fetchBrand = id => ({
  type: FETCH_BRAND.REQUEST,
  payload: { id },
})

export const UPDATE_BRAND = createAsyncAction("UPDATE_BRAND")
export const updateBrand = (data, id) => ({
  type: UPDATE_BRAND.REQUEST,
  payload: { data, id },
})

export const ADD_BRAND = createAsyncAction("ADD_BRAND")
export const addBrand = data => ({
  type: ADD_BRAND.REQUEST,
  payload: data,
})

export const UPDATE_COMPETITOR_GROUPS = createAsyncAction("UPDATE_COMPETITOR_GROUPS")
export const updateCompetitorGroups = (data, id) => ({
  type: UPDATE_COMPETITOR_GROUPS.REQUEST,
  payload: { data, id },
})

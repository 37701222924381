import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { addBrand } from "../../../store/actions/brand"
import { getAllBrands, getBrand, getUserData } from "../../../store/selectors"
import { registerUser } from "../../../store/actions/staff"
import { uploadClients } from "../../../store/actions/clients"

import Component from "./Admin"

const selector = createStructuredSelector({
  brand: getBrand,
  allBrands: getAllBrands,
  user: getUserData,
})

const actions = {
  onAddBrand: addBrand,
  onUploadClients: uploadClients,
  onRegisterUser: registerUser,
}

export const Admin = connect(selector, actions)(Component)

import React, { memo } from "react"
import isEmpty from "lodash/isEmpty"
import { AlertDanger } from "modaresa-commons"

import { timezoneOptions } from "../../../../../config/timezones"
import FormikDatePicker from "../../../../elements/FormikFields/FormikDatePicker"
import FormikInput from "../../../../elements/FormikFields/FormikInput"
import FormikLocationAutocomplete from "../../../../elements/FormikFields/FormikLocationAutocomplete"
import FormikSelect from "../../../../elements/FormikFields/FormikSelect"

import FieldOpeningHours from "./FieldOpeningHours"
import FieldSessionDates from "./FieldSessionDates"
import FieldSessionInfos from "./FieldSessionInfos"
import { ContainerInfos, ContainerBigRow } from "./GeneralFormView.styles"

type Props = {
  readOnly: boolean,
  session: {
    status: "active",
  },
  offices: [],
  onSelectAddress: Function,
  formProps: {},
  showFixMistakes: boolean,
}

const labelWidth = "208px"
const GeneralFormView = ({ showFixMistakes, session, offices, onSelectAddress, readOnly, formProps }: Props) => {
  const isFieldDisabled = readOnly || session.status === "active"
  // const optionsOffice = useMemo(() => offices.map(office => ({ value: office._id, label: office.name })), [offices])
  return (
    <div>
      <ContainerBigRow>
        <FieldSessionInfos disabled={isFieldDisabled} />
      </ContainerBigRow>
      <ContainerInfos>
        <FormikDatePicker
          label="Order Deadline"
          labelWidth={labelWidth}
          name="orderDeadline"
          placeholder="Order Deadline"
          disabled={readOnly}
        />
        <FormikLocationAutocomplete
          label="Showroom Address"
          labelWidth={labelWidth}
          name="address"
          disabled={readOnly}
          placeholder="Showroom address"
          onChange={onSelectAddress}
        />
        <FieldSessionDates disabled={isFieldDisabled} />
        {/* <FormikSelect
          label="Office"
          selectWidth="100%"
          labelWidth={labelWidth}
          name="office"
          options={optionsOffice}
          disabled={isFieldDisabled}
          placeholder="Select office"
        /> */}
        <FormikInput
          label="Directions"
          labelWidth={labelWidth}
          name="directions"
          placeholder="Ex: Floor #, Door Codes"
          disabled={readOnly}
          helpText={
            <span>
              Useful information that will be included in the invitation sent out to your clients,
              <br />
              to prepare them upon arrival at your showroom.
            </span>
          }
        />
        <FormikInput
          label=" "
          labelWidth={labelWidth}
          name="closestStation"
          placeholder="Enter closest metro station"
          disabled={readOnly}
        />
      </ContainerInfos>
      <ContainerBigRow>
        <FormikSelect
          label="Opening Hours"
          disabled={isFieldDisabled}
          selectWidth={"100%"}
          labelWidth={labelWidth}
          name="timezone"
          options={timezoneOptions}
          placeholder="Select timezone"
        />
        <FieldOpeningHours disabled={readOnly} />
      </ContainerBigRow>
      {showFixMistakes && !isEmpty(formProps.errors) && (
        <AlertDanger margin="0 0 16px 208px">Please fix form mistakes and try again</AlertDanger>
      )}
    </div>
  )
}

export default memo(GeneralFormView)

import React from "react"
import { Link } from "react-router-dom"
import { AutoSizer } from "react-virtualized"
import { Button } from "modaresa-commons"

import DataFilter from "../../../../elements/DataFilter/DataFilter"
import TableRetailers from "../../../../elements/TableRetailers"

import retailerSearch, { retailerSearchMatchKeys } from "./retailer.search"
import { ContainerRetailers, ContainerFilter, TableContainer } from "./AllRetailersView.styles"

type Props = {
  retailers: [],
  onRowRetailerClick: Function,
  onChangeFilter: Function,
  onRenderActions: Function,
}

const AllRetailersView = ({ retailers, onRowRetailerClick, onChangeFilter, onRenderActions }: Props) => (
  <ContainerRetailers>
    <ContainerFilter>
      <DataFilter
        data={retailers}
        dataKeysToSearch={retailerSearchMatchKeys}
        searchObj={retailerSearch}
        onChange={onChangeFilter}
        placeholder="Search by name, origin, client id, type, status, priority group"
      />
      <Link to={`/clients/retailers/new`}>
        <Button>+ Add Retailer</Button>
      </Link>
    </ContainerFilter>
    <TableContainer>
      <AutoSizer>
        {({ width, height }) => (
          <TableRetailers
            width={width}
            height={height}
            retailers={retailers}
            onRowClick={onRowRetailerClick}
            onRenderActions={onRenderActions}
          />
        )}
      </AutoSizer>
    </TableContainer>
  </ContainerRetailers>
)

export default AllRetailersView

import React, { memo } from "react"
import { Link } from "react-router-dom"
import { AutoSizer } from "react-virtualized"
import { Button } from "modaresa-commons"

import { isStaffAdmin } from "../../../helpers/users"
import DataFilter from "../../elements/DataFilter/DataFilter"
import TableStaffs from "../../elements/TableStaffs"

import staffSearch, { staffSearchMatchKeys } from "./search"
import StaffListSkeleton from "./StaffListSkeleton"
import { Container, FilterContainer, TableContainer } from "./StaffListView.styles"

type Props = {
  skeleton: boolean,
  filteredStaffs: [],
  staffs: [],
  loggedUser: {},
  onRowClick: Function,
  onRenderActions: Function,
  onChangeFilter: Function,
  toggleRoomBasedAreas: boolean,
}

const StaffListView = ({
  skeleton,
  filteredStaffs,
  staffs,
  loggedUser,
  onRowClick,
  onRenderActions,
  onChangeFilter,
  toggleRoomBasedAreas,
}: Props) => {
  const isAdmin = isStaffAdmin(loggedUser.staff?.modaresaRole)
  if (skeleton) return <StaffListSkeleton isAdmin={isAdmin} />
  return (
    <Container>
      <FilterContainer>
        <DataFilter
          data={staffs}
          dataKeysToSearch={staffSearchMatchKeys}
          searchObj={staffSearch}
          onChange={onChangeFilter}
          placeholder="Search by fullname, position, office, role, market, editor rights"
        />
        {isAdmin && (
          <Link to="/staff/new">
            <Button>+ Add Staff</Button>
          </Link>
        )}
      </FilterContainer>
      <TableContainer>
        <AutoSizer>
          {({ width, height }) => (
            <TableStaffs
              width={width}
              height={height}
              staffs={filteredStaffs || staffs}
              onRowClick={onRowClick}
              onRenderActions={onRenderActions}
              toggleRoomBasedAreas={toggleRoomBasedAreas}
            />
          )}
        </AutoSizer>
      </TableContainer>
    </Container>
  )
}

export default memo(StaffListView)

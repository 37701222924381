import { useQuery } from "@apollo/client"
import React, { useCallback, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"

import { BRAND_STAFFS_FULL } from "../../../graphql/queries/brand"
import { isStaffAdmin } from "../../../helpers/users"
import ButtonDeleteStaff from "../../elements/ButtonDeleteStaff/ButtonDeleteStaff"
import withLoggedUser from "../../hoc/withLoggedUser"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

import staffSearch from "./search"
import StaffListView from "./StaffListView"

type Props = {
  loggedUser: {},
}

const StaffList = ({ loggedUser }: Props) => {
  const [filteringOptions, setFilteringOptions] = useState([])
  const history = useHistory()
  const handleChangeFilter = useCallback(options => setFilteringOptions(options || []), [])
  const handleRowClick = useCallback(({ rowData: { id } }) => history.push(`/staff/${id}`), [history])
  const handleRenderActions = useCallback(
    staff =>
      isStaffAdmin(loggedUser.staff.modaresaRole) && (
        <ButtonDeleteStaff
          disabled={staff.id === loggedUser.staff.id || staff.modaresaRole === "owner"}
          staff={staff}
        />
      ),
    [loggedUser],
  )
  const { loading, error, data } = useQuery(BRAND_STAFFS_FULL, {
    variables: {
      id: loggedUser.staff.brand.id,
    },
  })
  const staffs = useMemo(() => {
    if (!data?.Brand) return []
    return data.Brand.staffs
  }, [data])
  const filteredStaffs = useMemo(() => {
    if (!staffs) return null
    let newFilteredStaffs = [...staffs]
    filteringOptions.forEach(opt => {
      newFilteredStaffs = staffSearch[opt.key].filter(newFilteredStaffs, opt.value)
    })
    return newFilteredStaffs
  }, [staffs, filteringOptions])
  if (loading && !data) return <StaffListView loggedUser={loggedUser} skeleton />
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading staff</ErrorLiveChatHelp>
  return (
    <StaffListView
      filteredStaffs={filteredStaffs}
      staffs={staffs}
      loggedUser={loggedUser}
      onRowClick={handleRowClick}
      onRenderActions={handleRenderActions}
      onChangeFilter={handleChangeFilter}
      toggleRoomBasedAreas={data.Brand.toggleRoomBasedAreas}
    />
  )
}
export default withLoggedUser(StaffList)

import { gql } from "@apollo/client"

import BookingEventFragments from "./bookingEvent.fragments"

export const GET_PARALLEL_BOOKING_EVENTS = gql`
  query getParallelBookingEvents($bookingEventId: ID!) {
    getParallelBookingEvents(bookingEventId: $bookingEventId) {
      bookingEvent {
        ...BookingEventSwitch
        dayDate
        startTime
        endTime
      }
      parallelBookingEvents {
        ...BookingEventSwitch
      }
    }
  }
  ${BookingEventFragments.fragments.switch}
`

export const IS_BOOKING_EVENT_PART_OF_MULTIPLE_BOOKINGS = gql`
  query IsBookingEventPartOfMultipleBookingEvents($bookingEventId: ID!) {
    isBookingEventPartOfMultipleBookingEvents(bookingEventId: $bookingEventId)
  }
`

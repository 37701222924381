import { gql } from "@apollo/client"

import StaffFragments from "../queries/staff.fragments"

export const DELETE_STAFF = gql`
  mutation DeleteStaff($id: ID!) {
    deleteStaff(id: $id) {
      id
      brand {
        id
      }
    }
  }
`

export const UPDATE_STAFF_GENERAL_INFOS = gql`
  mutation UpdateStaffGeneralInfos($id: ID!, $staffGeneralInfos: StaffGeneralInfosInput!) {
    updateStaffGeneralInfos(id: $id, staffGeneralInfos: $staffGeneralInfos) {
      id
      ...StaffFullProfile
    }
  }
  ${StaffFragments.fragments.full}
`

export const UPDATE_STAFF_VIDEO_IDS = gql`
  mutation UpdateStaffVideoIDs($id: ID!, $videoIDs: Json!) {
    updateStaffVideoIDs(id: $id, videoIDs: $videoIDs) {
      id
      videoIDs
    }
  }
`
export const UPDATE_STAFF_NOTIFICATIONS = gql`
  mutation UpdateStaffNotifications($id: ID!, $notifications: StaffNotificationsInput!) {
    updateStaffNotifications(id: $id, notifications: $notifications) {
      id
      ...StaffNotifications
    }
  }
  ${StaffFragments.fragments.notifications}
`

export const UPDATE_STAFF_BUYER_RESPONSIBILITY = gql`
  mutation UpdateStaffBuyerResponsibility($id: ID!, $affinityBuyersIds: [ID!]!, $market: MARKET_CATEGORY) {
    updateStaffBuyerResponsibility(id: $id, affinityBuyersIds: $affinityBuyersIds, market: $market) {
      id
      market
      affinityBuyers {
        id
        fullName
        retailer {
          id
          name
        }
      }
    }
  }
`

export const CREATE_STAFF = gql`
  mutation CreateStaff(
    $doCreateUserAccount: Boolean
    $brandId: ID!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $email: String
    $companyPosition: String!
    $modaresaRole: MODARESA_ROLE!
    $officeId: ID
    $languagesIsoCodes: [String!]!
    $hasEditorRights: Boolean
    $areasAndTerritories: AreasAndTerritoriesInput
    $password: String
  ) {
    createStaff(
      doCreateUserAccount: $doCreateUserAccount
      brandId: $brandId
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      email: $email
      companyPosition: $companyPosition
      modaresaRole: $modaresaRole
      officeId: $officeId
      languagesIsoCodes: $languagesIsoCodes
      hasEditorRights: $hasEditorRights
      areasAndTerritories: $areasAndTerritories
      password: $password
    ) {
      id
      ...StaffFullProfile
    }
  }
  ${StaffFragments.fragments.full}
`

export const CREATE_USER_ACCOUNT_FOR_STAFF = gql`
  mutation CreateUserAccountForStaff($id: ID!) {
    createUserAccountForStaff(id: $id) {
      id
      hasLogin
    }
  }
`

export const CHECK_IF_STAFF_EMAIL_VERIFIED = gql`
  mutation CheckIfStaffEmailVerified($id: ID!) {
    checkIfStaffEmailVerified(id: $id) {
      id
      emailVerified
    }
  }
`

export const VERIFY_STAFF_EMAIL = gql`
  mutation VerifyStaffEmail($id: ID!) {
    verifyStaffEmail(id: $id) {
      id
      pendingVerification
    }
  }
`

import { gql } from "@apollo/client"

import AreaFragments from "./area.fragments"
import RemainingAreaFragments from "./remainingArea.fragments"

export const FRAGMENT_ROOM_AREAS = gql`
  fragment RoomAreas on Room {
    id
    name
    remainingArea {
      ...RemainingAreaStaffs
      notificationMeetingConfirmation
      notificationMeetingReminder
    }
    areas {
      ...AreaStaffs
      ...AreaTerritory
      id
      name
      color
      notificationMeetingConfirmation
      notificationMeetingReminder
    }
  }
  ${AreaFragments.fragments.staffs}
  ${AreaFragments.fragments.territory}
  ${RemainingAreaFragments.fragments.staffs}
`

export const FRAGMENT_SINGLE_ROOM = gql`
  fragment SingleRoom on Room {
    id
    name
    hasBlockingList
    typeBlockingList
    retailers {
      id
      name
      storeAddress {
        city
        country
      }
    }
  }
`

const RoomFragments = {
  fragments: {
    areas: FRAGMENT_ROOM_AREAS,
    single: FRAGMENT_SINGLE_ROOM,
  },
}

export default RoomFragments

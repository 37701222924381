import { useQuery } from "@apollo/client"
import React, { memo, useMemo } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { LayoutWithSideMenu } from "modaresa-commons"

import { BRAND_NB_CLIENTS } from "../../../graphql/queries/brand"
import { isStaffEditor } from "../../../helpers/users"
import withLoggedUser from "../../hoc/withLoggedUser"
import AllBuyers from "../Clients/blocks/AllBuyers"
import AllRetailers from "../Clients/blocks/AllRetailers"
import ArchivedBuyers from "../Clients/blocks/ArchivedBuyers"
import { Buyer } from "../Clients/blocks/Buyer"
import CompetitorGroups from "../Clients/blocks/CompetitorGroups"
import { Retailer } from "../Clients/blocks/Retailer"
import { AddNewRetailer } from "../Clients/blocks/Retailer/AddNewRetailer"

type Props = {
  loggedUser: {
    staff: {
      modaresaRole: string,
      brand: {
        id: string,
      },
    },
  },
}
const RouteClients = ({ loggedUser: { staff } }: Props) => {
  const { id: staffId, modaresaRole, brand } = staff
  const userIsFreelancer = modaresaRole === "freelancer"
  const userIsAgent = modaresaRole === "agent"
  const isEditor = isStaffEditor(staff)
  const variables = {
    id: brand.id,
  }
  if (userIsAgent) {
    variables.restrictToStaffId = staffId
  }
  const { data } = useQuery(BRAND_NB_CLIENTS, { variables })
  const menuItems = useMemo(() => {
    const brand = data?.Brand || {}
    const { nbRetailers, nbBuyers, nbProspects, nbStockists } = brand
    return [
      {
        key: "all",
        name: `All Buyers ${nbBuyers ? `(${nbBuyers})` : ""}`,
        to: `/clients/`,
        exact: true,
        alwaysOpen: true,
        subItems: [
          {
            key: "stockist",
            name: `Stockists ${nbStockists ? `(${nbStockists})` : ""}`,
            to: "/clients/stockist/",
          },
          {
            key: "prospect",
            name: `Prospects ${nbProspects ? `(${nbProspects})` : ""}`,
            to: "/clients/prospect/",
          },
        ],
      },
      {
        key: "retailers",
        name: `Retailers ${nbRetailers ? `(${nbRetailers})` : ""}`,
        to: "/clients/retailers",
      },
      {
        key: "competitors",
        name: "Competitor Groups",
        to: "/clients/competitors",
      },
      {
        key: "archived",
        name: "Archived Buyers",
        to: "/clients/archived",
      },
    ]
  }, [data])

  const restrictToStaffIdIfAgent = userIsAgent ? staffId : undefined
  return (
    <LayoutWithSideMenu menuItems={menuItems} hideSideMenu={userIsFreelancer}>
      {userIsFreelancer ? (
        <Switch>
          <Route component={Buyer} path={`/clients/:id/:section/:sectionId`} />
          <Route component={Buyer} path={`/clients/:id/:section`} />
          <Redirect to={`/calendars`} />
        </Switch>
      ) : (
        <>
          <Switch>
            <Route component={AllRetailers} exact path={`/clients/retailers`}>
              <AllRetailers brandId={brand.id} restrictToStaffId={restrictToStaffIdIfAgent} />
            </Route>
            <Route component={AddNewRetailer} exact key="/new" path={`/clients/retailers/new`} />
            <Route component={Retailer} key="/:id" path={`/clients/retailers/:id`} />
            <Route exact path={`/clients`}>
              <AllBuyers
                brandId={brand.id}
                restrictToStaffId={restrictToStaffIdIfAgent}
                withPagination={true}
                withFilterAutocomplete={false}
              />
            </Route>
            <Route exact path={`/clients/stockist`}>
              <AllBuyers
                brandId={brand.id}
                retailerStatus="stockist"
                restrictToStaffId={restrictToStaffIdIfAgent}
                withPagination={true}
                withFilterAutocomplete={false}
              />
            </Route>
            <Route exact path={`/clients/prospect`}>
              <AllBuyers
                brandId={brand.id}
                retailerStatus="prospect"
                restrictToStaffId={restrictToStaffIdIfAgent}
                withPagination={true}
                withFilterAutocomplete={false}
              />
            </Route>
            <Route exact path={`/clients/competitors`}>
              <CompetitorGroups
                canEdit={isEditor || userIsAgent}
                brandId={brand.id}
                restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
              />
            </Route>
            <Route exact path={`/clients/archived`}>
              <ArchivedBuyers brandId={brand.id} />
            </Route>
            <Route component={Buyer} exact key="/new" path={`/clients/new`} />
            <Route component={Buyer} path={`/clients/:id/:section/:sectionId?`} />
            <Route component={Buyer} path={`/clients/:id/:section`} />
            <Redirect from={`/clients/:id`} to={`/clients/:id/info`} />
            <Redirect to={`/clients/`} />
          </Switch>
        </>
      )}
    </LayoutWithSideMenu>
  )
}
export default withLoggedUser(memo(RouteClients))

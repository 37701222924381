import { ADD_SOCKET_ROOM, REMOVE_SOCKET_ROOM } from "../actions/sockets"
import { createReducer } from "../..//helpers/redux"

const initialState = {
  rooms: {},
}

const handlers = {
  [ADD_SOCKET_ROOM]: (state, { payload }) => {
    const { type, room } = payload
    return {
      ...state,
      rooms: {
        ...state.rooms,
        [type]: room,
      },
    }
  },
  [REMOVE_SOCKET_ROOM]: (state, { payload }) => {
    const { type } = payload
    const oldRooms = { ...state.rooms }
    delete oldRooms[type]
    return {
      ...state,
      rooms: oldRooms,
    }
  },
}

export default createReducer(initialState, handlers)

import React, { memo, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useQuery } from "@apollo/client"

import { BRAND_ROOMS } from "../../../../../graphql/queries/brand"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"

import BrandTableRoomsActions from "./BrandTableRoomsActions"
import RoomsView from "./RoomsView"

type Props = {
  brandId: string,
  canEdit: boolean,
  canView: boolean,
}

const Rooms = ({ brandId, canEdit, canView }: Props) => {
  const history = useHistory()
  const handleRenderTableRoomsActions = useCallback(
    room => <BrandTableRoomsActions canView={canView} canEdit={canEdit} brandId={brandId} room={room} />,
    [canEdit, canView, brandId],
  )
  const handleClickTableRoomsRow = useCallback(
    room => {
      history.push(`/settings/company/rooms/${room.id}`)
    },
    [history],
  )
  const { loading, data, error } = useQuery(BRAND_ROOMS, {
    variables: { id: brandId },
  })
  if (loading && !data) return <RoomsView skeleton />
  if (error)
    return <ErrorLiveChatHelp technicalError={error}>An error occured while loading brand rooms</ErrorLiveChatHelp>
  const { rooms } = data.Brand
  return (
    <RoomsView
      rooms={rooms}
      brandId={brandId}
      canEdit={canEdit}
      onRenderTableRoomsActions={handleRenderTableRoomsActions}
      onClickTableRoomsRow={canView && handleClickTableRoomsRow}
    />
  )
}

export default memo(Rooms)

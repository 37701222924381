import React, { memo, useCallback } from "react"

import PageTitle from "../../../elements/PageTitle/PageTitle"

import ButtonRestoreBuyer from "./ButtonRestoreBuyer"
import BuyersList from "./BuyersList/BuyersList"

type Props = {
  brandId: string,
}

const ArchivedBuyers = ({ brandId }: Props) => {
  const handleRenderActions = useCallback(buyer => <ButtonRestoreBuyer brandId={brandId} buyer={buyer} />, [brandId])
  return (
    <PageTitle title="Archived buyers">
      <BuyersList brandId={brandId} status="archived" onRenderActions={handleRenderActions} />
    </PageTitle>
  )
}

export default memo(ArchivedBuyers)

import { useMutation } from "@apollo/client"
import React, { memo, useCallback } from "react"
import { ModalConfirmation } from "modaresa-commons"

import { DELETE_BRAND_ROOM } from "../../../graphql/mutations/room"
import { onToastError } from "../../../helpers/toast"
import useIsMounted from "../../hooks/useIsMounted"

type Props = {
  brandId: string,
  roomId: string,
  onClose: Function,
}

const ModalDeleteBrandRoom = ({ brandId, roomId, onClose }: Props) => {
  const isMounted = useIsMounted()
  const [deleteBrandRoom] = useMutation(DELETE_BRAND_ROOM, {
    update: (cache, { data: { deleteBrandRoom: deletedRoom } }) => {
      cache.modify({
        id: cache.identify({ id: brandId }),
        fields: {
          rooms: (roomsRefs, { readField }) => roomsRefs.filter(roomRef => deletedRoom.id !== readField("id", roomRef)),
        },
      })
    },
  })
  const handleClickOk = useCallback(async () => {
    try {
      await deleteBrandRoom({
        variables: {
          brandId,
          roomId,
        },
      })
      if (isMounted.current) {
        onClose()
      }
    } catch (err) {
      console.error(err)
      onToastError("An error occured while deleting room")
    }
  }, [deleteBrandRoom, brandId, roomId, onClose, isMounted])
  return (
    <ModalConfirmation
      text="Are you sure you want to delete this room?"
      isOpen
      onClose={onClose}
      onClickOk={handleClickOk}
    />
  )
}

export default memo(ModalDeleteBrandRoom)

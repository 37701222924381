export default {
  ad: "eu",
  ae: "as",
  af: "as",
  ag: "na",
  ai: "na",
  al: "eu",
  am: "as",
  an: "na",
  ao: "af",
  aq: "an",
  ar: "sa",
  as: "oc",
  at: "eu",
  au: "oc",
  aw: "na",
  az: "as",
  ba: "eu",
  bb: "na",
  bd: "as",
  be: "eu",
  bf: "af",
  bg: "eu",
  bh: "as",
  bi: "af",
  bj: "af",
  bm: "na",
  bn: "as",
  bo: "sa",
  br: "sa",
  bs: "na",
  bt: "as",
  bw: "af",
  by: "eu",
  bz: "na",
  ca: "na",
  cc: "as",
  cd: "af",
  cf: "af",
  cg: "af",
  ch: "eu",
  ci: "af",
  ck: "oc",
  cl: "sa",
  cm: "af",
  cn: "as",
  co: "sa",
  cr: "na",
  cu: "na",
  cv: "af",
  cx: "as",
  cy: "as",
  cz: "eu",
  de: "eu",
  dj: "af",
  dk: "eu",
  dm: "na",
  do: "na",
  dz: "af",
  ec: "sa",
  ee: "eu",
  eg: "af",
  eh: "af",
  er: "af",
  es: "eu",
  et: "af",
  fi: "eu",
  fj: "oc",
  fk: "sa",
  fm: "oc",
  fo: "eu",
  fr: "eu",
  ga: "af",
  gb: "eu",
  gd: "na",
  ge: "as",
  gf: "sa",
  gg: "eu",
  gh: "af",
  gi: "eu",
  gl: "na",
  gm: "af",
  gn: "af",
  gp: "na",
  gq: "af",
  gr: "eu",
  gs: "an",
  gt: "na",
  gu: "oc",
  gw: "af",
  gy: "sa",
  hk: "as",
  hn: "na",
  hr: "eu",
  ht: "na",
  hu: "eu",
  id: "as",
  ie: "eu",
  il: "as",
  im: "eu",
  in: "as",
  io: "as",
  iq: "as",
  ir: "as",
  is: "eu",
  it: "eu",
  je: "eu",
  jm: "na",
  jo: "as",
  jp: "as",
  ke: "af",
  kg: "as",
  kh: "as",
  ki: "oc",
  km: "af",
  kn: "na",
  kp: "as",
  kr: "as",
  kw: "as",
  ky: "na",
  kz: "as",
  la: "as",
  lb: "as",
  lc: "na",
  li: "eu",
  lk: "as",
  lr: "af",
  ls: "af",
  lt: "eu",
  lu: "eu",
  lv: "eu",
  ly: "af",
  ma: "af",
  mc: "eu",
  md: "eu",
  me: "eu",
  mg: "af",
  mh: "oc",
  mk: "eu",
  ml: "af",
  mm: "as",
  mn: "as",
  mo: "as",
  mp: "oc",
  mq: "na",
  mr: "af",
  ms: "na",
  mt: "eu",
  mu: "af",
  mv: "as",
  mw: "af",
  mx: "na",
  my: "as",
  mz: "af",
  na: "af",
  nc: "oc",
  ne: "af",
  nf: "oc",
  ng: "af",
  ni: "na",
  nl: "eu",
  no: "eu",
  np: "as",
  nr: "oc",
  nu: "oc",
  nz: "oc",
  om: "as",
  pa: "na",
  pe: "sa",
  pf: "oc",
  pg: "oc",
  ph: "as",
  pk: "as",
  pl: "eu",
  pm: "na",
  pn: "oc",
  pr: "na",
  ps: "as",
  pt: "eu",
  pw: "oc",
  py: "sa",
  qa: "as",
  re: "af",
  ro: "eu",
  rs: "eu",
  ru: "eu",
  rw: "af",
  sa: "as",
  sb: "oc",
  sc: "af",
  sd: "af",
  se: "eu",
  sg: "as",
  sh: "af",
  si: "eu",
  sj: "eu",
  sk: "eu",
  sl: "af",
  sm: "eu",
  sn: "af",
  so: "af",
  sr: "sa",
  st: "af",
  sv: "na",
  sy: "as",
  sz: "af",
  tc: "na",
  td: "af",
  tf: "an",
  tg: "af",
  th: "as",
  tj: "as",
  tk: "oc",
  tm: "as",
  tn: "af",
  to: "oc",
  tr: "as",
  tt: "na",
  tv: "oc",
  tw: "as",
  tz: "af",
  ua: "eu",
  ug: "af",
  us: "na",
  uy: "sa",
  uz: "as",
  vc: "na",
  ve: "sa",
  vg: "na",
  vi: "na",
  vn: "as",
  vu: "oc",
  wf: "oc",
  ws: "oc",
  ye: "as",
  yt: "af",
  za: "af",
  zm: "af",
  zw: "af",
}

import { call, put, select } from "redux-saga/effects"
import { toast } from "react-toastify"

import * as Api from "../../helpers/api"
import {
  CHECK_IF_VERIFIED,
  CREATE_STAFF_MEMBER,
  DELETE_STAFF,
  FETCH_AREA_MANAGERS,
  FETCH_STAFF,
  FETCH_STAFF_AVAILABLE_CLIENTS,
  GIVE_ACCESS,
  REGISTER_USER,
  UPDATE_STAFF_MEMBER,
  VERIFY_EMAIL,
} from "../actions/staff"
import { UPDATE_USER } from "../actions/user"
import { takeLatest } from "../../helpers/saga"

import { onToastError, onToastSuccess } from "./../../helpers/toast"

import { normalizeData } from "helpers/normalize"

function* createStaffMember({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data } = payload
  try {
    const response = yield call(Api.createStaffMember, {
      data,
      token: accessToken,
    })

    yield put({
      type: CREATE_STAFF_MEMBER.SUCCESS,
      payload: response,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: CREATE_STAFF_MEMBER.FAILURE,
      payload: errosMsg,
    })
  }
}

function* createStaffMemberFlow() {
  yield takeLatest(CREATE_STAFF_MEMBER.REQUEST, createStaffMember)
}

function* updateStaffMember({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, id } = payload
  try {
    const response = yield call(Api.updateStaffMember, {
      data,
      token: accessToken,
      id,
    })

    yield put({
      type: UPDATE_STAFF_MEMBER.SUCCESS,
      payload: response,
    })
    if (state.user.user._id === response._id) {
      yield put({
        type: UPDATE_USER,
        payload: response,
      })
    }
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: UPDATE_STAFF_MEMBER.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateStaffMemberFlow() {
  yield takeLatest(UPDATE_STAFF_MEMBER.REQUEST, updateStaffMember)
}

function* checkIfVerified({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { id } = payload
  try {
    const response = yield call(Api.checkIfVerified, {
      token: accessToken,
      id,
    })

    yield put({
      type: CHECK_IF_VERIFIED.SUCCESS,
      payload: response,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: CHECK_IF_VERIFIED.FAILURE,
      payload: errosMsg,
    })
  }
}

function* checkIfVerifiedFlow() {
  yield takeLatest(CHECK_IF_VERIFIED.REQUEST, checkIfVerified)
}

function* verifyEmail({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { id } = payload
  try {
    const response = yield call(Api.verifyEmail, {
      token: accessToken,
      id,
    })

    toast("Email is sent successfully", {
      hideProgressBar: true,
      className: "def-toast",
      autoClose: 2000,
    })
    yield put({
      type: VERIFY_EMAIL.SUCCESS,
      payload: response,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: VERIFY_EMAIL.FAILURE,
      payload: errosMsg,
    })
  }
}

function* verifyEmailFlow() {
  yield takeLatest(VERIFY_EMAIL.REQUEST, verifyEmail)
}

function* registerUser({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { user } = state.user
  const { data } = payload
  try {
    const response = yield call(Api.registerUser, {
      data,
      token: accessToken,
      inviter: `${user.firstName} ${user.lastName}`,
    })

    yield put({
      type: REGISTER_USER.SUCCESS,
      payload: response,
    })

    if (state.user.user.modaresaRole === "superAdmin") {
      onToastSuccess("User created")
    }
  } catch (error) {
    console.error(error)
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: REGISTER_USER.FAILURE,
      payload: errosMsg,
    })
  }
}

function* registerUserFlow() {
  yield takeLatest(REGISTER_USER.REQUEST, registerUser)
}

function* giveAccess({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { user } = state.user
  const { data, id } = payload
  try {
    const response = yield call(Api.giveAccess, {
      id,
      data,
      token: accessToken,
      inviter: `${user.firstName} ${user.lastName}`,
    })

    yield put({
      type: GIVE_ACCESS.SUCCESS,
      payload: response,
    })

    onToastSuccess("Staff member has access")
  } catch (error) {
    onToastError("Oops, you must insert an email address before you can give access.")

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: GIVE_ACCESS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* giveAccessFlow() {
  yield takeLatest(GIVE_ACCESS.REQUEST, giveAccess)
}

function* fetchStaffAvailableClients({ payload }) {
  try {
    const response = yield call(Api.staffAvailableClients, {
      staffId: payload.id,
    })

    yield put({
      type: FETCH_STAFF_AVAILABLE_CLIENTS.SUCCESS,
      payload: response,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_STAFF_AVAILABLE_CLIENTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchStaffAvailableClientsFlow() {
  yield takeLatest(FETCH_STAFF_AVAILABLE_CLIENTS.REQUEST, fetchStaffAvailableClients)
}

function* fetchBrandStaffs({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { brandId } = payload
  try {
    const response = yield call(Api.fetchBrandStaff, {
      brandId,
      token: accessToken,
    })

    const staffs = normalizeData(response)

    yield put({ type: FETCH_STAFF.SUCCESS, payload: staffs })
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: FETCH_STAFF.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchBrandStaffsFlow() {
  yield takeLatest(FETCH_STAFF.REQUEST, fetchBrandStaffs)
}

function* deleteStaff({ payload }) {
  const { staffId } = payload
  try {
    const response = yield call(Api.deleteStaff, {
      staffId,
    })

    yield put({
      type: DELETE_STAFF.SUCCESS,
      payload: response.updatedMember,
    })
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: DELETE_STAFF.FAILURE,
      payload: errosMsg,
    })
  }
}

function* deleteStaffFlow() {
  yield takeLatest(DELETE_STAFF.REQUEST, deleteStaff)
}

function* fetchAreaManagers({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  try {
    const response = yield call(Api.fetchAreaManagers, {
      brandId: payload.brandId,
      token: accessToken,
    })

    yield put({
      type: FETCH_AREA_MANAGERS.SUCCESS,
      payload: response,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_AREA_MANAGERS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchAreaManagersFlow() {
  yield takeLatest(FETCH_AREA_MANAGERS.REQUEST, fetchAreaManagers)
}

export default [
  checkIfVerifiedFlow,
  createStaffMemberFlow,
  updateStaffMemberFlow,
  verifyEmailFlow,
  registerUserFlow,
  giveAccessFlow,
  fetchStaffAvailableClientsFlow,
  fetchBrandStaffsFlow,
  deleteStaffFlow,
  fetchAreaManagersFlow,
]

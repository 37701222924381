import axios from "axios"

import { REST_API_URL } from "../../config"

export const createStaffMember = async ({ data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/staff`, data, config)

  return response.data
}

export const updateStaffMember = async ({ data, id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(`${REST_API_URL}/staff/${id}`, data, config)

  return response.data
}

export const checkIfVerified = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/staff/${id}/email/status`, config)

  return response.data
}

export const verifyEmail = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/staff/${id}/email/verify`, config)

  return response.data
}

export const registerUser = async ({ data, token, inviter }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const body = {
    inviter,
    data,
  }

  const response = await axios.post(`${REST_API_URL}/staff/register/new`, body, config)

  return response.data
}

export const giveAccess = async ({ id, data, token, inviter }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const body = {
    inviter,
    data,
  }

  const response = await axios.patch(`${REST_API_URL}/staff/register/new/${id}`, body, config)

  return response.data
}

export const staffAvailableClients = async ({ staffId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const response = await axios.get(`${REST_API_URL}/staff/${staffId}/available_clients`, config)

  return response.data
}

export const deleteStaff = async ({ staffId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const response = await axios.delete(`${REST_API_URL}/staff/${staffId}`, config)

  return response.data
}

export const fetchAreaManagers = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/staff/areaManagers?brandId=${brandId}`, config)

  return response.data
}

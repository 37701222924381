import "react-datepicker/dist/react-datepicker.css"
import { useMutation } from "@apollo/client"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Moment from "moment-timezone"
import { extendMoment } from "moment-range"
import { Button } from "modaresa-commons"

import markets from "../../../../../config/markets"
import seasons from "../../../../../config/seasons"
import { DO_OPENING_HOURS_HAVE_ALL_EXISTING_BOOKING_EVENTS_WITHIN } from "../../../../../graphql/mutations/salesSession"
import { onToastError } from "../../../../../helpers/toast"
import { DeleteButton, FooterLeft, FooterRight } from "../../../../elements/SessionFooter"
import { hoursFormat24OptionsAsObject } from "../../../../../helpers/date"

import GeneralForm from "./GeneralForm"
import ModalOpeningHoursIncompatibility from "./ModalOpeningHoursIncompatibility"
import { Container, Footer } from "./styles"

const moment = extendMoment(Moment)

type Props = {
  fetchedTimezone: string,
  offices: [],
  readOnly: boolean,
  session: {},
  onDeleteSession: Function,
  onFetchTimezone: Function,
  onSubmit: Function,
}
const General = ({
  session,
  offices,
  onDeleteSession,
  readOnly,
  onFetchTimezone,
  onSubmit,
  fetchedTimezone,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModalOpeningHoursIncompatibility, setShowModalOpeningHoursIncompatibility] = useState(false)
  const [missingBookingEventsByOpeningHour, setMissingBookingEventsByOpeningHour] = useState([])
  const formRef = useRef(null)
  const [doOpeningHoursHaveAllExistingBookingEventsWithin] = useMutation(
    DO_OPENING_HOURS_HAVE_ALL_EXISTING_BOOKING_EVENTS_WITHIN,
  )
  const [sessionGeneralSettings] = useState(() => {
    let openingHours = []
    if (session.openingHours) {
      openingHours = session.openingHours.map(day => {
        const from = moment.utc(day.from, "X")
        const fromNumerical = from.hours() + from.minutes() / 60
        const to = moment.utc(day.to, "X")
        const toNumerical = to.hours() + to.minutes() / 60

        return {
          available: day.available,
          date: moment.utc(day.date, "X"),
          from: hoursFormat24OptionsAsObject[fromNumerical],
          to: hoursFormat24OptionsAsObject[toNumerical],
        }
      })
    }

    const foundOffice = session.office && offices.find(({ value }) => session.office._id === value)

    return {
      name: session.name || "",
      market: (session.market && markets.find(({ value }) => value === session.market)) || null,
      season: (session.season && seasons.find(({ value }) => value === session.season)) || null,
      address: session.address || "",
      office: (foundOffice && { value: foundOffice._id, label: foundOffice.name }) || null,
      openingDay: (session.openingDay && moment.utc(session.openingDay, "X")) || null,
      closingDay: (session.closingDay && moment.utc(session.closingDay, "X")) || null,
      orderDeadline: (session.orderDeadline && moment.utc(session.orderDeadline, "X")) || null,
      directions: session.directions || "",
      closestStation: session.closestStation || "",
      timezone:
        (session.timezone && {
          value: session.timezone,
          label: session.timezone,
        }) ||
        null,
      openingHours,
    }
  })

  const handleCloseModal = useCallback(() => setShowModalOpeningHoursIncompatibility(false), [])
  const handleFormSubmit = useCallback(
    async values => {
      setMissingBookingEventsByOpeningHour([])
      setShowModalOpeningHoursIncompatibility(false)
      const formattedValues = {
        address: values.address,
        name: values.name,
        closingDay: values.closingDay.unix(),
        openingDay: values.openingDay.unix(),
        orderDeadline: values.orderDeadline.unix(),
        market: values.market.value,
        season: values.season.value,
        timezone: values.timezone.value,
        office: offices[0]?._id,
        directions: values.directions,
        closestStation: values.closestStation,
        openingHours: values.openingHours.map(day => ({
          available: day.available,
          date: day.date.set({ hours: 0, minutes: 0 }).unix(),
          from: day.date
            .set({
              hours: day.from.value.hours,
              minutes: day.from.value.minutes,
            })
            .unix(),
          to: day.date
            .set({
              hours: day.to.value.hours,
              minutes: day.to.value.minutes,
            })
            .unix(),
        })),
      }

      if (session.status === "active") {
        try {
          setIsLoading(true)
          const {
            data: { doOpeningHoursHaveAllExistingBookingEventsWithin: payload },
          } = await doOpeningHoursHaveAllExistingBookingEventsWithin({
            variables: {
              salesSessionId: session._id,
              openingHours: formattedValues.openingHours,
            },
          })
          setIsLoading(false)
          if (!payload.haveAll) {
            setShowModalOpeningHoursIncompatibility(true)
            setMissingBookingEventsByOpeningHour(payload.missingBookingEventsByOpeningHour)
            return false
          }
        } catch (e) {
          console.error(e)
          onToastError("An error occured while checking bookings")
          setIsLoading(false)
          return false
        }
      }
      onSubmit(formattedValues, true)
    },
    [session, onSubmit, doOpeningHoursHaveAllExistingBookingEventsWithin],
  )
  const handleClickSavesChanges = useCallback(() => {
    formRef.current.handleFormikSubmit()
  }, [])

  useEffect(() => {
    if (fetchedTimezone && session.status !== "active") {
      formRef.current.handleFormikSetField("timezone", {
        value: fetchedTimezone,
        label: fetchedTimezone,
      })
    }
  }, [fetchedTimezone, session.status])

  return (
    <Container>
      {showModalOpeningHoursIncompatibility && (
        <ModalOpeningHoursIncompatibility
          missingBookingEventsByOpeningHour={missingBookingEventsByOpeningHour}
          onClose={handleCloseModal}
        />
      )}
      <GeneralForm
        ref={formRef}
        session={session}
        sessionGeneralSettings={sessionGeneralSettings}
        offices={offices}
        onSubmit={handleFormSubmit}
        onFetchTimezone={onFetchTimezone}
        readOnly={readOnly}
      />
      {!readOnly && (
        <Footer>
          <FooterLeft />
          <FooterRight>
            {session.extra.filledSessionData.general && (
              <DeleteButton type="button" onClick={onDeleteSession}>
                Delete Sales Session
              </DeleteButton>
            )}
            <Button type="button" disabled={isLoading} onClick={handleClickSavesChanges}>
              {isLoading ? "Saving..." : "Save changes"}
            </Button>
          </FooterRight>
        </Footer>
      )}
    </Container>
  )
}

export default General

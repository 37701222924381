import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import times from "lodash/times"
import { Flex } from "modaresa-commons"

import Booking from "../../Booking"
import {
  Booklot,
  RoomSchedule as RoomScheduleContainer,
  Rotate,
  StaffColumn,
  StaffColumnHeader,
  StaffTableHeader,
  Timeslot,
} from "../../../styles.js"

const FIFTEENMINUTES = 900

class StaffTable extends Component {
  tableRefs = []

  // eslint-disable-next-line no-empty-function
  onSlotClick = () => {}

  renderTable = (tableIndex, roomName, member) => {
    const {
      openingHours: { from, to },

      staffSchedule,
      selectedDay,
      selectedDayIndex,
      canEditBooking,
    } = this.props
    let staffBook
    const staffmember = staffSchedule[member.staffId]
    if (roomName === "All Rooms") {
      if (staffmember) {
        for (const key in staffmember.bookings) {
          if (staffmember.bookings[key].dayDate === selectedDay) {
            staffBook = {
              ...staffBook,
              [staffmember.bookings[key].startTime]: staffmember.bookings[key],
            }
          }
        }
      }
    } else if (staffmember && staffmember.rooms[roomName]) {
      staffmember.rooms[roomName][selectedDayIndex] &&
        staffmember.rooms[roomName][selectedDayIndex].forEach(book => {
          staffBook = {
            ...staffBook,
            [staffmember.bookings[book].startTime]: staffmember.bookings[book],
          }
        })
    }

    const start = moment.utc(from, "X")
    let startTimeStamp = from
    const end = moment.utc(to, "X")
    const diff = end.diff(start, "hours", true)
    let parts = diff * 4
    let itemIndex = 0
    if (staffBook) {
      return times(diff * 4, idx => {
        if (parts <= 0) return null
        const element = staffBook[startTimeStamp]
        if (element) {
          const diff = element.endTime - element.startTime
          const flex = diff / FIFTEENMINUTES
          parts -= flex
          startTimeStamp += diff
          itemIndex += flex
          const reportUrl = element.report
            ? `/clients/${element.clients[0]}/meetings-reports/${element.report}`
            : `/clients/${element.clients[0]}/meetings-reports?booking=${element._id}`
          return (
            <Booklot flex={flex} key={idx}>
              <Booking
                booking={element}
                position="relative"
                room={element.room}
                onClick={e => this.props.onBookingClick(reportUrl, e)}
                canEdit={canEditBooking}
              />
            </Booklot>
          )
        }
        if (moment.utc(startTimeStamp).minutes() >= 0) startTimeStamp += FIFTEENMINUTES
        parts -= 1
        itemIndex++
        const timeStamp = startTimeStamp - FIFTEENMINUTES
        return (
          <Timeslot
            even={itemIndex % 4 === 0}
            key={idx}
            onClick={event => this.onSlotClick({ event, roomName, member, timeStamp })}
          >
            {""}
          </Timeslot>
        )
      })
    }
    return times(diff * 4, idx => {
      if (moment.utc(startTimeStamp).minutes() >= 0) startTimeStamp += FIFTEENMINUTES
      const timeStamp = startTimeStamp - FIFTEENMINUTES
      return (
        <Timeslot
          even={(idx + 1) % 4 === 0}
          key={idx}
          onClick={event => this.onSlotClick({ event, roomName, member, timeStamp })}
        >
          {""}
        </Timeslot>
      )
    })
  }

  render() {
    const { room, staff, allocatedStaff } = this.props
    return (
      <Flex
        align="stretch"
        column
        style={{
          marginBottom: 20,
          flex: 1,
          paddingRight: "20px",
          minHeight: "90%",
        }}
      >
        <StaffTableHeader minWidth={allocatedStaff.length}>
          {allocatedStaff.map((member, idx) => (
            <StaffColumnHeader key={idx}>
              <Rotate>
                {staff.byId[member.staffId].firstName} {staff.byId[member.staffId].lastName}
              </Rotate>
              <span>{idx + 1}</span>
            </StaffColumnHeader>
          ))}
        </StaffTableHeader>
        <RoomScheduleContainer minWidth={allocatedStaff.length}>
          {allocatedStaff.map((member, idx, arr) => (
            <StaffColumn key={idx} last={idx === arr.length - 1}>
              <React.Fragment>{this.renderTable(idx, room.label, member)}</React.Fragment>
            </StaffColumn>
          ))}
        </RoomScheduleContainer>
      </Flex>
    )
  }
}

StaffTable.propTypes = {
  allocatedStaff: PropTypes.array,
  openingHours: PropTypes.object,
  room: PropTypes.object,
  selectedDay: PropTypes.number,
  selectedDayIndex: PropTypes.number,
  staff: PropTypes.object,
  staffSchedule: PropTypes.object,
  onBookingClick: PropTypes.func,
  onSlotClick: PropTypes.func,
}

export { StaffTable }

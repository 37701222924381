import React, { memo } from "react"
import moment from "moment"
import { B, Ul, Error } from "modaresa-commons"

type Props = {
  eventsByOpeningHour: {
    openingHour: {
      available: boolean,
      date: number,
      from: number,
      to: number,
    },
    events: [],
  },
}

const MissingBookingEventsByOpeningHour = ({ eventsByOpeningHour: { openingHour, events } }: Props) => {
  const date = moment.utc(openingHour.date, "X")
  const from = moment.utc(openingHour.from, "X")
  const to = moment.utc(openingHour.to, "X")
  const singular = events.length === 1
  return (
    <div>
      <div>
        {date.format("ddd, MMM Do")}{" "}
        {openingHour.available ? (
          <Error>
            from {from.format("HH:mm")} to {to.format("HH:mm")}
          </Error>
        ) : (
          <Error>is deactivated</Error>
        )}
      </div>
      <Ul>
        {openingHour.available ? (
          events.map(event => {
            const eventFrom = moment.utc(event.startTime, "X")
            const eventTo = moment.utc(event.endTime, "X")
            return (
              <li>
                With <B>{event.clients.map(client => client.fullName).join(", ")}</B> at {eventFrom.format("HH:mm")}-
                {eventTo.format("HH:mm")}
              </li>
            )
          })
        ) : (
          <li>
            There {singular ? "is" : "are"} {events.length} booking event
            {singular ? "" : "s"}
          </li>
        )}
      </Ul>
    </div>
  )
}

export default memo(MissingBookingEventsByOpeningHour)

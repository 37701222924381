const territoriesFields = ["continents", "countries", "regions", "counties", "cities", "retailers"]
const isEmptyTerritories = territory => {
  if (!territory) return true

  for (const territoryField of territoriesFields) {
    if (territory[territoryField]?.length > 0) {
      return false
    }
  }
  return true
}

export default isEmptyTerritories

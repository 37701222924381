import styled from "styled-components"

export const ContainerCompetitorGroups = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`

export const HeaderCompetitorGroups = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
`
export const HeaderCompetitorGroupsTitle = styled.h5``

export const AddButton = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${props => props.theme.primary};
  margin-left: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  border-radius: 50%;
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

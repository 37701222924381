import React, { memo, useCallback } from "react"
import { useField } from "formik"

import BuyerPicker from "../../BuyerPicker"
import FormFieldLayout from "../../FormFieldLayout/FormFieldLayout"

type Props = {
  brandId: string,
  staffId: string,
  labelWidth: string,
  disabled: boolean,
  restrictBuyersToStaffAreas: boolean,
}

const FieldAffinityBuyers = ({ staffId, brandId, labelWidth, disabled, restrictBuyersToStaffAreas }: Props) => {
  const [field, meta, helpers] = useField("affinityBuyers")
  const handleChange = useCallback(
    values => {
      if (!values) {
        return helpers.setValue([])
      }
      helpers.setValue(values)
    },
    [helpers],
  )

  return (
    <FormFieldLayout
      label="Specific Buyer Assignation"
      labelWidth={labelWidth}
      name="affinityBuyers"
      direction="row"
      error={meta.touched && meta.error}
      helpText={
        <span>
          Lock the allocation of one or multiple buyer(s) to this staff member.
          <br /> This will override all other sales sessions algorithms and only suggest meeting
          <br />
          matches restricted to this staff member.
        </span>
      }
    >
      <BuyerPicker
        brandId={brandId}
        multiple
        disabled={disabled}
        defaultValue={field.value}
        onChange={handleChange}
        restrictToStaffId={restrictBuyersToStaffAreas ? staffId : undefined}
      />
    </FormFieldLayout>
  )
}

export default memo(FieldAffinityBuyers)

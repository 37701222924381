import { useField } from "formik"
import React, { useCallback, memo } from "react"
import styled from "styled-components"
import Switch from "react-switch"
import { theme } from "modaresa-commons"

import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

const ContainerSwitch = styled.div`
  height: 40px;
  padding-top: 7px;
`

type Props = {
  label: string,
  labelWidth: string,
  name: string,
  direction?: "row" | "column",
  onChange: Function,
  disabled: boolean,
  helpText: string,
  placeholder: string,
}

const FormikToggle = ({ onChange, label, labelWidth, name, direction, disabled, helpText }: Props) => {
  const [field, meta, helpers] = useField(name)
  const handleChange = useCallback(
    toggleValue => {
      helpers.setValue(toggleValue)
      if (onChange) {
        onChange(toggleValue)
      }
    },
    [onChange, helpers],
  )
  return (
    <FormFieldLayout
      label={label}
      labelWidth={labelWidth}
      name={name}
      direction={direction}
      error={meta.touched && meta.error}
      helpText={helpText}
    >
      <ContainerSwitch>
        <Switch
          id={name}
          aria-label={`${name} switch`}
          checked={field.value}
          checkedIcon={false}
          disabled={disabled}
          handleDiameter={18}
          height={24}
          width={48}
          offColor={theme.secondary}
          uncheckedIcon={false}
          onChange={handleChange}
          onColor={theme.primary}
        />
      </ContainerSwitch>
    </FormFieldLayout>
  )
}

FormikToggle.defaultProps = {
  direction: "row",
}
export default memo(FormikToggle)

import { Form, useFormikContext } from "formik"
import React, { memo, useCallback, useMemo } from "react"
import { Button } from "modaresa-commons"

import { generateId } from "../../../../../helpers/idGenerator"

import CompetitorGroupRow from "./CompetitorGroupRow"
import {
  AddButton,
  HeaderCompetitorGroups,
  HeaderCompetitorGroupsTitle,
  ContainerButtons,
} from "./FormCompetitorGroups.styles"

type Props = {
  brandId: {},
  readOnly: boolean,
  loadingSubmit: boolean,
  restrictToStaffIdIfAgent: string,
}

const FormCompetitorGroupsFields = ({ brandId, readOnly, loadingSubmit, restrictToStaffIdIfAgent }: Props) => {
  const {
    values: { competitorGroups },
    setFieldValue,
  } = useFormikContext()
  const handleClickAddGroup = useCallback(() => {
    const updatedCompetitorGroups = [...competitorGroups]
    updatedCompetitorGroups.push({
      name: "",
      retailers: [],
      id: generateId(),
    })
    setFieldValue("competitorGroups", updatedCompetitorGroups)
  }, [competitorGroups, setFieldValue])
  const handleClickDeleteGroup = useCallback(
    idx => {
      const updatedCompetitorGroups = [...competitorGroups]
      updatedCompetitorGroups.splice(idx, 1)
      setFieldValue("competitorGroups", updatedCompetitorGroups)
    },
    [competitorGroups, setFieldValue],
  )
  const alreadyUsedRetailers = useMemo(
    () => competitorGroups.reduce((list, group) => list.concat(group.retailers), []),
    [competitorGroups],
  )
  return (
    <Form>
      <HeaderCompetitorGroups>
        <HeaderCompetitorGroupsTitle>Groups ({competitorGroups.length})</HeaderCompetitorGroupsTitle>
        {!readOnly && <AddButton onClick={handleClickAddGroup}>+</AddButton>}
      </HeaderCompetitorGroups>
      {competitorGroups.map((group, idx) => (
        <CompetitorGroupRow
          key={group.id}
          brandId={brandId}
          competitorGroup={group}
          competitorGroupIdx={idx}
          alreadyUsedRetailers={alreadyUsedRetailers}
          readOnly={readOnly}
          onClickDelete={handleClickDeleteGroup}
          restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
        />
      ))}
      <ContainerButtons>
        <Button type="submit" loading={loadingSubmit} disabled={readOnly}>
          Save Changes
        </Button>
      </ContainerButtons>
    </Form>
  )
}

export default memo(FormCompetitorGroupsFields)

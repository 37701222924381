import React, { memo, useState } from "react"
import moment from "moment"
import { Formik, Form } from "formik"
import * as yup from "yup"
import { Button } from "modaresa-commons"

import { hoursFormat24OptionsAsObject } from "../../../../helpers/date"
import FormFieldLayout from "../../FormFieldLayout/FormFieldLayout"

import FieldAvailabilities from "./FieldAvailabilities"

const availabilitiesSchema = yup.object().shape({
  availabilities: yup.array(),
})

type Props = {
  availabilities: [
    {
      available: boolean,
      from: string,
      to: string,
    },
  ],
  onSubmit: {},
  loadingSubmit: boolean,
  readOnly: boolean,
  labelWidth: string,
}
const FormAvailabilities = ({ availabilities, onSubmit, loadingSubmit, readOnly, labelWidth }: Props) => {
  const [initialValues] = useState(() => ({
    availabilities: availabilities.map(day => {
      const from = moment.utc(day.from, "X")
      const fromNumerical = from.hours() + from.minutes() / 60
      const to = moment.utc(day.to, "X")
      const toNumerical = to.hours() + to.minutes() / 60

      return {
        available: day.available,
        date: from.set({ hours: 0, minutes: 0 }).unix(),
        from: hoursFormat24OptionsAsObject[fromNumerical],
        to: hoursFormat24OptionsAsObject[toNumerical],
      }
    }),
  }))
  return (
    <Formik initialValues={initialValues} validateOnBlur validationSchema={availabilitiesSchema} onSubmit={onSubmit}>
      {() => (
        <Form>
          <FieldAvailabilities labelWidth={labelWidth} disabled={readOnly} />
          <FormFieldLayout label=" " labelWidth={labelWidth}>
            <Button minWidth="100%" type="submit" disabled={readOnly || loadingSubmit}>
              Save changes
            </Button>
          </FormFieldLayout>
        </Form>
      )}
    </Formik>
  )
}

FormAvailabilities.defaultProps = {
  availabilities: [],
}
export default memo(FormAvailabilities)

import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  max-width: 800px;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 60px;
`
export const Title = styled.h2`
  margin-bottom: 24px;
  margin-top: 0;
`

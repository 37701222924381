import React, { memo } from "react"

import RoomAreas from "../RoomAreas/RoomAreas"

import RoomBasedAreasManagerSkeleton from "./RoomBasedAreasManagerSkeleton"
import { ContainerFilterInput, FilterInput, ErrorEmpty } from "./RoomBasedAreasManagerView.styles"

type Props = {
  loading: boolean,
  brandId: string,
  rooms: [],
  onChangeRoom: Function,
  filterValue: string,
  onChangeFilter: Function,
  errors: {},
  canAreasBeModified: boolean,
  canStaffsBeModified: boolean,
  retailers: [],
}

const RoomBasedAreasManagerView = ({
  loading,
  brandId,
  rooms,
  onChangeRoom,
  filterValue,
  onChangeFilter,
  errors,
  canAreasBeModified,
  canStaffsBeModified,
  retailers,
}: Props) => {
  const noRooms = !rooms || rooms.length === 0
  return loading ? (
    <RoomBasedAreasManagerSkeleton />
  ) : (
    <div>
      {noRooms && !filterValue ? (
        <ErrorEmpty>Please add at least one room</ErrorEmpty>
      ) : (
        <>
          <ContainerFilterInput>
            <FilterInput onChange={onChangeFilter} placeholder="Filter by room name" />
          </ContainerFilterInput>
          {noRooms ? (
            <ErrorEmpty>No result found for {filterValue}</ErrorEmpty>
          ) : (
            rooms.map(room => (
              <RoomAreas
                key={room.id}
                brandId={brandId}
                room={room}
                onChange={onChangeRoom}
                errors={errors?.[room.id]}
                canAreasBeModified={canAreasBeModified}
                canStaffsBeModified={canStaffsBeModified}
                retailers={retailers}
              />
            ))
          )}
        </>
      )}
    </div>
  )
}

export default memo(RoomBasedAreasManagerView)

import { useQuery } from "@apollo/client"
import React, { memo, useMemo } from "react"
import Select from "react-select"

import { BRAND_OFFICES } from "../../../graphql/queries/brand"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

type Props = {
  brandId: string,
  disabled: boolean,
  value: string,
  onChange: Function,
  onBlur: Function,
  placeholder: string,
}

const BrandOfficePicker = ({ brandId, disabled, value, onChange, onBlur, placeholder }: Props) => {
  const { loading, error, data } = useQuery(BRAND_OFFICES, {
    variables: { id: brandId },
  })
  const optionsOffices = useMemo(() => {
    if (!data?.Brand?.offices) {
      return []
    }
    return data.Brand.offices.map(office => ({
      value: office.id,
      label: office.name,
    }))
  }, [data])
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading brand offices</ErrorLiveChatHelp>
  return (
    <Select
      isLoading={loading}
      options={optionsOffices}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isDisabled={disabled}
      placeholder={placeholder}
    />
  )
}

BrandOfficePicker.defaultProps = {
  placeholder: "Select office",
}
export default memo(BrandOfficePicker)

import React, { useCallback, useRef } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Dropdown, { DropdownContent, DropdownTrigger } from "react-simple-dropdown"

import { modaResaRoles } from "../../../config"

import { MenuDefault } from "./blocks/MenuDefault"
import MenuFreelancer from "./blocks/MenuFreelancer"
import {
  ConnectedUser,
  ConnectedUserFullname,
  ConnectedUserHasEditorRights,
  ConnectedUserRole,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
} from "./styles"

import settingsIcon from "static/settings-work-tool.svg"
import mR from "static/mR.png"
import "./styles.css"

const _Topbar = ({ dbUser, user, location }) => {
  const dropdownRef = useRef(null)
  const handleLinkClick = useCallback(() => dropdownRef.current.hide(), [])
  const userRole = user?.modaresaRole
  const isCalendar = location.pathname === "/calendars"
  const isConnectedUserFreelancer = userRole === "freelancer"
  const isSuperAdmin = dbUser.roles[0] === "superAdmin"
  return (
    <div className="top-bar">
      <div className="logo">
        <Link to={userRole === "freelancer" ? "/calendars" : "/"}>
          <img alt="logo" src={mR} />
        </Link>
      </div>
      <div className="menu-tabs">
        {isConnectedUserFreelancer ? <MenuFreelancer /> : <MenuDefault isCurrentPageCalendar={isCalendar} />}
      </div>
      <div className="right-menu-options">
        {user && (
          <ConnectedUser>
            <ConnectedUserFullname>
              {user.firstName} {user.lastName}
            </ConnectedUserFullname>
            <ConnectedUserRole>
              {user.hasEditorRights && <ConnectedUserHasEditorRights>Editor - </ConnectedUserHasEditorRights>}
              {modaResaRoles[userRole]?.name}
            </ConnectedUserRole>
          </ConnectedUser>
        )}
        <Dropdown ref={dropdownRef}>
          <DropdownTrigger>
            <Icon height="25px" src={settingsIcon} style={{ padding: "0 5px", minWidth: "30px" }} />
          </DropdownTrigger>
          <DropdownContent>
            <DropdownMenu border style={{ minWidth: "300px" }} onClick={handleLinkClick}>
              {isSuperAdmin ? (
                <Link to="/logout">
                  <DropdownMenuItem>
                    <div>Log Out</div>
                  </DropdownMenuItem>
                </Link>
              ) : (
                <>
                  <Link to={"/settings/profile"}>
                    <DropdownMenuItem style={{ display: "flex", alignItems: "center" }}>My Profile</DropdownMenuItem>
                  </Link>
                  <Link to={`/calendars?staffId=${user._id}`}>
                    <DropdownMenuItem style={{ display: "flex", alignItems: "center" }}>My Calendar</DropdownMenuItem>
                  </Link>
                  <Link to="/logout">
                    <DropdownMenuItem>
                      <div>Log Out</div>
                    </DropdownMenuItem>
                  </Link>
                  <Link to={` `}>
                    <DropdownMenuItem>Help</DropdownMenuItem>
                  </Link>
                </>
              )}
            </DropdownMenu>
          </DropdownContent>
        </Dropdown>
      </div>
    </div>
  )
}
export const Topbar = connect(state => ({
  user: state.user.user,
  dbUser: state.user.dbUser,
  brand: state.brand,
}))(_Topbar)

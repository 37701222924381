import { useField } from "formik"
import React, { memo, useCallback } from "react"

import BrandRetailerPicker from "../BrandRetailerPicker"
import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

type Props = {
  labelWidth: string,
  disabled: boolean,
  brandId: string,
  restrictToStaffIdIfAgent: string,
}

const FieldRetailers = ({ labelWidth, disabled, brandId, restrictToStaffIdIfAgent }: Props) => {
  const [field, meta, helpers] = useField("retailers")
  const handleChangeSelect = useCallback(value => helpers.setValue(value || []), [helpers])
  return (
    <FormFieldLayout
      label="Retailers"
      labelWidth={labelWidth}
      name={"retailers"}
      direction="row"
      error={meta.touched && meta.error}
    >
      <BrandRetailerPicker
        value={field.value}
        brandId={brandId}
        multiple
        placeholder="Select retailers"
        disabled={disabled}
        onChange={handleChangeSelect}
        restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
      />
    </FormFieldLayout>
  )
}

export default memo(FieldRetailers)

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import moment from "moment-timezone"
import times from "lodash/times"
import { Button, Flex } from "modaresa-commons"

import { languages, languagesOptions, meetingTypes } from "../../../../../config"
import DeleteIcon from "../../../../../static/noun_Trash_839970.svg"
import arrowIcon from "../../../../../static/arrow-down-sign-to-navigate.svg"
import { modaResaRoles } from "../../../../../config/settings"
import SalesSessionRoomAreaAndTerritoryPicker from "../../../../elements/SalesSessionRoomAreaAndTerritoryPicker"
import { mapRetailersToGeo } from "../../../../../helpers/utils"
import getAreasAndTerritoriesOptionsValuesFromStaff from "../../../../../helpers/areasAndTerritories/getAreasAndTerritoriesOptionsValuesFromStaff"

import { DeleteIcon as BucketIcon, Col, GeoLimit, Icon, RoomGeo, StaffRow } from "./styles"

class AllocatedStaffMember extends PureComponent {
  constructor(props) {
    super(props)
    const {
      allocatedStaffMember,
      retailers,
      areas,
      session: { toggleRoomBasedAreas, rooms },
    } = props

    // todo to change with graphql to avoid this
    const geographicResponsibilityWithRetailers = mapRetailersToGeo(
      allocatedStaffMember.geographicResponsibility,
      retailers,
    )

    this.defaultGeographicalResponsibilityValues = getAreasAndTerritoriesOptionsValuesFromStaff({
      geographicResponsibility: geographicResponsibilityWithRetailers,
      toggleRoomBasedAreas,
      areas,
      rooms,
    })
  }

  onDeleteStaff = id => {
    this.props.onRemoveStaff(id)
  }

  toogleGel = id => {
    this.props.toogleGelLimit(id)
  }

  handleChange = (type, value, id) => {
    this.props.handleChange(type, value, id)
  }

  handleGeo = (values, id) => {
    this.props.geoHandleChange(values, id)
  }

  geoLimHandle = (value, id, dayIdx, roomIdx) => {
    this.props.geoLimHandle(value, id, dayIdx, roomIdx)
  }

  renderLangSelect = allocatedStaffMember => {
    const { readOnly } = this.props
    const languageValues = []

    allocatedStaffMember.languages.forEach(lang => {
      if (languages[lang]) {
        languageValues.push({
          value: languages[lang].isoCode,
          label: languages[lang].name,
        })
      }
    })

    return (
      <Select
        classNamePrefix={"select"}
        closeOnSelect={false}
        isDisabled={readOnly}
        isMulti
        options={languagesOptions}
        placeholder="Select Languages"
        value={languageValues}
        onChange={values => {
          const formattedValues = (values || []).map(item => item.value)
          this.handleChange("languages", formattedValues, allocatedStaffMember.staffId)
        }}
      />
    )
  }

  renderRoomSelect = (role, rooms, allocatedStaffMember) => {
    const { session, readOnly } = this.props
    return (
      <Select
        classNamePrefix={"select"}
        closeOnSelect={false}
        isDisabled={!session.algorithms.room || role === "areaManager" || readOnly}
        isMulti
        options={this.props.roomsOptions}
        placeholder="Select Room"
        value={allocatedStaffMember.roomResponsibility?.map(room => ({
          value: room,
          label: room,
        }))}
        onChange={values => {
          const formattedValues = values?.map(item => item.value)
          this.handleChange("roomResponsibility", formattedValues, allocatedStaffMember.staffId)
        }}
      />
    )
  }

  renderTerritoriesSelect = (role, allocatedStaffMember) => {
    const {
      readOnly,
      session: { _id },
    } = this.props
    return (
      <SalesSessionRoomAreaAndTerritoryPicker
        disabled={readOnly}
        salesSessionId={_id}
        multiple
        placeholder="Select areas & territories"
        defaultValue={this.defaultGeographicalResponsibilityValues}
        onChange={values => this.handleGeo(values, allocatedStaffMember.staffId)}
      />
    )
  }

  render() {
    const { staffMember, allocatedStaffMember, expanded, session, brand, id, geoLimitData, readOnly } = this.props
    const { modaresaRole } = staffMember
    const { toggleRoomBasedAreas, algorithms: { geo } = {} } = session
    const isAreasCountriesDisabled = !geo && modaresaRole !== "agent"
    return (
      <React.Fragment key={staffMember._id}>
        <StaffRow key={staffMember._id}>
          <Col text width="calc(16% - 30px)">
            {staffMember.firstName} {staffMember.lastName}
          </Col>
          <Col style={{ whiteSpace: "initial" }} text width="12%">
            {(modaResaRoles[staffMember.modaresaRole] && modaResaRoles[staffMember.modaresaRole].name) ||
              staffMember.modaresaRole}
          </Col>
          <Col width="16%">{this.renderLangSelect(allocatedStaffMember)}</Col>
          <Col width="16%" text={isAreasCountriesDisabled}>
            {isAreasCountriesDisabled ? (
              <>All areas</>
            ) : (
              this.renderTerritoriesSelect(modaresaRole, allocatedStaffMember)
            )}
          </Col>
          {!toggleRoomBasedAreas && (
            <Col width="16%">{this.renderRoomSelect(modaresaRole, session.rooms, allocatedStaffMember)}</Col>
          )}
          <Col width="16%">
            <Select
              classNamePrefix={"select"}
              closeOnSelect={false}
              isClearable={false}
              isDisabled={readOnly}
              isMulti
              options={meetingTypes}
              placeholder="Select meeting types"
              value={allocatedStaffMember.availableMeetingTypes
                .map(type => meetingTypes.find(item => item.value === type))
                .filter(({ value }) => {
                  if (!session[value]) {
                    return true
                  }
                  return session[value]?.available
                })}
              onChange={values => {
                const formattedValues = (values || []).map(item => item.value)
                this.handleChange("availableMeetingTypes", formattedValues, id)
              }}
            />
          </Col>
          <Col style={{ overflow: "hidden" }} width="8%">
            {brand.geoLimit && staffMember.modaresaRole === "areaManager" ? (
              <Flex justify="center">
                <Button minWidth="30px" type="button" onClick={() => this.toogleGel(id)}>
                  <Icon height="auto" src={arrowIcon} width="18px" />
                </Button>
              </Flex>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  minWidth: "30px",
                  paddingTop: "8px",
                }}
              >
                N/A
              </div>
            )}
          </Col>
          <Col width="30px">
            <Button
              bgcolor="transparent"
              borderColor="transparent"
              disabled={readOnly}
              icon="true"
              minWidth="20px"
              style={{ marginTop: "-3px" }}
              onClick={() => this.onDeleteStaff(id)}
            >
              <BucketIcon src={DeleteIcon} />
            </Button>
          </Col>
        </StaffRow>
        {expanded && (
          <GeoLimit>
            {geoLimitData &&
              geoLimitData.rooms.map((room, roomIdx) => (
                <RoomGeo key={room.name} style={{ width: `${100 / geoLimitData.rooms.length}%` }}>
                  <div style={{ padding: "8px 15px 0 0", minWidth: "140px" }}>{room.name}</div>
                  <Flex align="flex-start" column>
                    {session.customizeStructure ? (
                      room.structure.map((day, dayIdx) => {
                        const limitOptions = times(day.meetingCapacity, idx => ({
                          value: idx + 1,
                          label: `${idx + 1}/${day.meetingCapacity}`,
                        }))
                        const value =
                          day.limit === null
                            ? null
                            : {
                                value: day.limit,
                                label: `${day.limit}/${day.meetingCapacity}`,
                              }
                        return (
                          <Flex key={day.date} style={{ marginBottom: "5px" }}>
                            <div style={{ width: "130px", paddingRight: "15px" }}>
                              {moment.utc(day.date, "X").format("ddd, MMM Do")}
                            </div>
                            <div style={{ width: "100px" }}>
                              <Select
                                classNamePrefix={"select"}
                                options={limitOptions}
                                placeholder=""
                                value={value}
                                onChange={({ value }) => this.geoLimHandle(value, id, dayIdx, roomIdx)}
                              />
                            </div>
                          </Flex>
                        )
                      })
                    ) : (
                      <div style={{ width: "100px" }}>
                        <Select
                          options={times(room.structure[0].meetingCapacity, idx => ({
                            value: idx + 1,
                            label: `${idx + 1}/${room.structure[0].meetingCapacity}`,
                          }))}
                          placeholder=""
                          value={
                            room.structure[0].limit === null
                              ? null
                              : {
                                  value: room.structure[0].limit,
                                  label: `${room.structure[0].limit}/${room.structure[0].meetingCapacity}`,
                                }
                          }
                          onChange={({ value }) => this.geoLimHandle(value, id, 0, roomIdx)}
                        />
                      </div>
                    )}
                  </Flex>
                </RoomGeo>
              ))}
          </GeoLimit>
        )}
      </React.Fragment>
    )
  }
}

AllocatedStaffMember.propTypes = {
  allocatedStaffMember: PropTypes.object,
  brand: PropTypes.object,
  expanded: PropTypes.bool,
  geoGroups: PropTypes.array,
  geoHandleChange: PropTypes.func,
  geoLimHandle: PropTypes.func,
  geoLimitData: PropTypes.object,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  roomsOptions: PropTypes.array,
  session: PropTypes.object,
  staffMember: PropTypes.object,
  toogleGelLimit: PropTypes.func,
  onRemoveStaff: PropTypes.func,
}

export { AllocatedStaffMember }

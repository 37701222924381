import React, { memo } from "react"
import { Error } from "modaresa-commons"

const fieldToLogs = [
  {
    title: "Changes",
    key: "changes",
  },
  {
    title: "Unarchived Retailers",
    key: "logsUnarchiveRetailers",
  },
  {
    title: "New Shared Retailers",
    key: "logsNewSharedRetailers",
  },
  {
    title: "New Retailers",
    key: "logsNewRetailers",
  },
]

type Props = {
  modifications: {
    errors: [],
    changes: [],
    logsNewRetailers: [],
    logsNewSharedRetailers: [],
    logsUnarchiveRetailers: [],
  },
}
const AddClientsModifications = ({ modifications }: Props) => (
  <div>
    <h2>Errors</h2>
    <div>
      {modifications.errors.map((error, idx) => (
        <Error block key={idx}>
          {error}
        </Error>
      ))}
    </div>
    {fieldToLogs.map(field => {
      const modificationArray = modifications[field.key]
      if (!modificationArray) return <div key={field.key}>Missing {field.title}</div>
      return (
        <div key={field.key}>
          <h2>
            {field.title} ({modificationArray.length})
          </h2>
          <div>
            {modificationArray.map((log, idx) => (
              <div key={idx}>{log}</div>
            ))}
          </div>
        </div>
      )
    })}
  </div>
)

export default memo(AddClientsModifications)

import styled from "styled-components"
import { BooleanStatusIcon } from "modaresa-commons"

import DropdownActions from "../../DropdownActions"

export const Container = styled.div`
  padding: 20px 0 20px 0;
  & .react-tagsinput-tag {
    color: #fff;
    background-color: #516bf0;
    border-color: #516bf0;
  }

  & .react-tagsinput-remove {
    color: #fff;
  }
`

export const Row = styled.div`
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || "space-between"};
  align-items: center;
`

export const AreaContainer = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`

export const SelectContainer = styled.div`
  width: 100%;

  & .areaSelect__multi-value {
    font-size: 16px;
    min-height: 35px;
    display: flex;
    align-items: center;
  }

  & .areaSelect__multi-value__remove {
    min-height: 35px;
  }
`

export const DropdownContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  & .dropdown__content {
    margin-top: 10px;
  }
`
export const DropdownMenu = styled.div`
  background-color: #fff;
  min-width: 200px;
  text-decoration: none;
`
export const DropdownMenuItem = styled.div`
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.gray75};
    text-decoration: none;
  }

  & a {
    color: ${props => props.theme.black};
  }
`

export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
`

export const Col = styled.div`
  width: 100%;
  padding-left: 15px;
  max-width: 250px;
`

export const AreaColor = styled.div`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  text-align: center;
  color: #333;
  margin-right: 8px;
  cursor: pointer;
  background-color: ${props => props.bgColor || props.theme.teal};
  &:hover {
    text-decoration: none;
  }
`
export const ErrorText = styled.div`
  color: ${props => props.theme.primary};
`
export const InputContainer = styled.div`
  padding-right: 15px;
  & > input {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 40px;
    padding-left: 15px;
    padding-right: 10px;
  }
`

export const CenteredDropdownActions = styled(DropdownActions)`
  height: 38px;
  width: 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const ContainerAreaName = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`

export const Error = styled.div`
  color: ${p => p.theme.danger};
`

export const StyledBooleanStatusIcon = styled(BooleanStatusIcon)`
  margin-top: 8px;
`

export const TdIcon = styled.td`
  text-align: center;
`

import { HelpTooltip, Button, Flex as DivFlex } from "modaresa-commons"
import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import { isNil } from "ramda"

import { Block, Flex, MeetingClients, MeetingDate, MeetingInfo } from "../../styles"
import {
  AvailableStaff,
  BookingModal,
  BookingModalDialogBody,
  BookingModalHeader,
  MeetingBudget,
} from "../../../../elements/BookingModalOld"
import { Select } from "../../../../ui"
import { meetingApproachItems, meetingTypes } from "../../../../../config"
import TimeIcon from "../../../../../static/clock.svg"
import { getRangeHourList } from "../../../../../helpers/date"
import { getStaffLabel } from "../../../../../helpers/users"

import { EmptyRetailer, getRetailerByIdOrEmpty, onMissingRetailerIdForClient } from "services/retailers"
import { onToastError } from "helpers/toast"

class NewBookingModal extends Component {
  constructor(props) {
    super(props)
    const { booking = {}, session } = this.props
    const {
      meetingType,
      meetingApproach,
      videoTool,
      room,
      clients,
      host,
      meetingResp,
      status,
      startTime,
      endTime,
    } = booking

    const meetingApproachOptions = []
    if (!session.disableFaceToFaceMeetings) {
      meetingApproachOptions.push({
        label: "Face-to-Face",
        value: "face-to-face",
      })
    }
    if (session.videoMeetings) {
      meetingApproachOptions.push({
        label: "Virtual Meeting",
        value: "video-conference",
      })
    }

    let stateMeetingApproach = meetingApproach && meetingApproachItems.find(ma => ma.value === meetingApproach)
    if (!stateMeetingApproach && meetingApproachOptions.length === 1) {
      stateMeetingApproach = meetingApproachOptions[0]
    }
    this.state = {
      meetingType: (meetingType && meetingTypes.find(mt => mt.value === meetingType)) || null,
      meetingApproach: stateMeetingApproach,
      videoTool,
      room: room || null,
      clients: clients || [],
      meetingResp: meetingResp || [],
      host: status === "reserved" ? null : host,
      status,
      budget: 0,
      startTime: startTime
        ? {
            value: startTime,
            label: moment.utc(startTime, "X").format("HH:mm"),
          }
        : null,
      endTime: endTime ? { value: endTime, label: moment.utc(endTime, "X").format("HH:mm") } : null,
      booking,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.booking !== prevState.booking && prevState.updatingTime) {
      const { onFetchAvailableClients, scheduleId, booking, session } = nextProps
      const { startTime, endTime } = nextProps.booking
      onFetchAvailableClients({
        scheduleId,
        sessionId: session._id,
        bookingId: booking._id,
      })
      return {
        clients: [],
        meetingResp: [],
        host: null,
        booking: nextProps.booking,
        startTime: {
          value: startTime,
          label: moment.utc(startTime, "X").format("HH:mm"),
        },
        endTime: {
          value: endTime,
          label: moment.utc(endTime, "X").format("HH:mm"),
        },
      }
    }
    const { availableStaff } = nextProps
    if (availableStaff && availableStaff[0] !== prevState.meetingResp) {
      const [meetingResp] = availableStaff
      return {
        meetingResp: meetingResp
          ? [
              {
                value: meetingResp._id,
                label: getStaffLabel(meetingResp),
              },
            ]
          : [],
      }
    }
    return null
  }

  componentDidMount() {
    const { onFetchAvailableClients, scheduleId, booking, session } = this.props
    onFetchAvailableClients({
      scheduleId,
      sessionId: session._id,
      bookingId: booking._id,
    })
  }

  filterClient = (option, value) => {
    const { clients } = this.state
    if (clients.length > 0) {
      if (clients[0].retailerId === option.data.retailerId) {
        const words = value.split(" ")
        return words.reduce((acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()), true)
      }
    } else {
      const words = value.split(" ")
      return words.reduce((acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()), true)
    }
  }

  onChangeTime = (time, type) => {
    if (type === "startTime") {
      this.setState(
        {
          startTime: {
            value: time.value,
            label: moment.utc(time.value, "X").format("HH:mm"),
          },
        },
        () => this.fetchAvailableStaff(),
      )
    }
    if (type === "endTime") {
      this.setState(
        {
          endTime: {
            value: time.value,
            label: moment.utc(time.value, "X").format("HH:mm"),
          },
        },
        () => this.fetchAvailableStaff(),
      )
    }
  }

  fetchAvailableStaff = _clients => {
    if (!this.props.onFetchAvailableStaff) return

    const { session, scheduleId, booking } = this.props
    const { clients, meetingType, startTime, endTime } = this.state

    const clientList = !isNil(_clients) ? _clients : clients

    if (clientList.length > 0) {
      this.setState(
        {
          host: null,
        },
        () =>
          this.props.onFetchAvailableStaff({
            clients: clientList?.map(client => client.value),
            sessionId: session._id,
            roomName: booking.room,
            meetingType: meetingType.value,
            scheduleId,
            startTime: startTime.value,
            endTime: endTime.value,
            type: booking.type,
          }),
      )
    }
  }

  handleChange = (type, value, action) => {
    if (type === "clients" && action === "select-option") {
      this.fetchAvailableStaff(value)
    }
    if (type === "clients" && action === "clear") {
      return this.setState({
        clients: [],
        host: null,
        meetingResp: [],
      })
    }
    if (type === "meetingApproach" || type === "host") {
      return this.setState({
        [type]: value,
        videoTool: null,
      })
    }

    this.setState({ [type]: value })
  }

  onInputChange = e => {
    const value = e.target.value
    const name = e.target.name
    if (!isNaN(value)) {
      this.setState({
        [name]: +value,
      })
    }
  }

  getClientLabel = client => {
    const { retailers } = this.props
    const retailer = getRetailerByIdOrEmpty(client.retailerId, retailers, onMissingRetailerIdForClient(client))
    const clientDetails = `${client.firstName} ${client.lastName}, ${client.position} - ${client.email}`
    if (retailer === EmptyRetailer) {
      return clientDetails
    }
    return `${clientDetails} (${retailer.name})`
  }

  onBook = () => {
    const {
      budget,
      clients,
      meetingResp,
      host,
      meetingType,
      meetingApproach,
      videoTool,
      startTime,
      endTime,
    } = this.state
    const { onConfirmBooking, scheduleId, booking, onUpdateTime } = this.props

    if (startTime.value < endTime.value) {
      onUpdateTime({
        startTime: startTime.value,
        endTime: endTime.value,
        bookingId: booking._id,
        scheduleId,
      })
    } else {
      return onToastError("Please make sure the start time of the appointment is before the end time.")
    }

    onConfirmBooking({
      bookingId: booking._id,
      bookingData: {
        budget,
        meetingType: meetingType.value,
        meetingApproach: meetingApproach.value,
        startTime: startTime.value,
        endTime: endTime.value,
        videoTool,
        host: host.value,
        meetingResp: meetingResp?.map(member => member.value),
        clients: clients.map(client => client.value),
      },
      scheduleId,
    })
  }

  render() {
    const {
      onRequestClose,
      isOpen,
      isFetchingClients,
      availableClients,
      availableHosts,
      availableStaff,
      otherHosts,
      isFetchingMeetingStaff,
      booking,
      openingHours,
    } = this.props

    const {
      meetingType,
      meetingApproach,
      videoTool,
      clients,
      host,
      meetingResp,
      budget,
      startTime,
      endTime,
      isMeetingStaffFetched,
    } = this.state

    const isVideoMeeting = meetingApproach && meetingApproach.value === "video-conference"

    let hosts = availableHosts
    if (isVideoMeeting) {
      hosts = hosts.filter(host => host.videoIDs && Object.values(host.videoIDs).filter(id => id).length)
    }

    const availableHostsArray = hosts.map(host => ({
      value: host._id,
      label: getStaffLabel(host),
    }))
    availableHostsArray.unshift({
      value: "- No Meeting Host assigned -",
      label: "- No Meeting Host assigned -",
    })

    const dayDate = moment.utc(booking.dayDate, "X").format("dddd, MMMM Do YYYY")
    const timeOptions = getRangeHourList(openingHours.from, openingHours.to)
    const isButtonDisabled =
      !meetingApproach || clients.length < 1 || meetingResp.length < 1 || !host || (isVideoMeeting && !videoTool)
    return (
      <BookingModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <BookingModalHeader dark>
          New Booking <Block>-</Block>
        </BookingModalHeader>
        <BookingModalDialogBody>
          <MeetingInfo>
            <div style={{ width: "60%" }}>
              <MeetingDate>
                <div>
                  <img src={TimeIcon} alt="clock" />
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ marginBottom: "5px" }}>{dayDate}</div>
                  <Flex>
                    <div style={{ width: "45%" }}>
                      <Select
                        options={timeOptions}
                        value={startTime}
                        onChange={value => this.onChangeTime(value, "startTime")}
                        menuPosition={"fixed"}
                      />
                    </div>
                    {" to "}
                    <div style={{ width: "45%" }}>
                      <Select
                        options={timeOptions}
                        value={endTime}
                        onChange={value => this.onChangeTime(value, "endTime")}
                        menuPosition={"fixed"}
                      />
                    </div>
                  </Flex>
                </div>
              </MeetingDate>
              {/*
              Asked by Steph to hide this checkbox:

              Steph - 2022/08/31
              I'm just thinking that we should hide it anyway, because it's annoying for our users to have to tick "override" everytime they want to extend over 30 minutes.
              What I meant is that let's remove it completely from the view, and just let the users by default be able to choose over 30 minutes if they want to. See what I mean?

              <Override>
                <input
                  disabled={meetingType.value !== "buyingAppointment"}
                  name="overrideLength"
                  type="checkbox"
                  value={overrideLength}
                  onChange={this.onCheckboxChange}
                />
                <span>Override Standard Lengths</span>
              </Override>
              */}
            </div>
            <div style={{ width: "40%", paddingLeft: "15px" }}>
              <Flex column>
                <Button
                  align="flex-end"
                  bgcolor="#37475d"
                  borderColor="#37475d"
                  disabled
                  margin="0 0 20px 0"
                  type="button"
                >
                  Disable Timeslot
                </Button>
              </Flex>
              <Select
                className="meetingTypes"
                options={meetingTypes}
                placeholder=""
                value={meetingType}
                onChange={option => {
                  this.handleChange("meetingType", option)
                }}
                menuPosition={"fixed"}
              />
              <Select
                className="meetingTypes"
                options={meetingApproachItems}
                placeholder="Select approach"
                value={meetingApproach}
                onChange={option => {
                  this.handleChange("meetingApproach", option)
                }}
                menuPosition={"fixed"}
              />
            </div>
          </MeetingInfo>
          <MeetingClients>
            <DivFlex justify="flex-start" style={{ marginBottom: "5px" }}>
              <span>Select client(s):</span>
              <HelpTooltip
                text={
                  <span>
                    If you cannot find a client in the list, it is because the Geo.Limitation for the area that
                    <br />
                    your client belongs to has been reached to the maximum. Please choose another timing in the
                    calendar.
                    <br />
                    Contact your showroom manager/wholesale coordinator if you have any questions regarding
                    Geo.Limitation.
                  </span>
                }
              />
            </DivFlex>
            <div>
              <Select
                closeOnSelect={false}
                filterOption={this.filterClient}
                isDisabled={isFetchingClients}
                isLoading={isFetchingClients}
                isMulti
                options={availableClients.map(client => ({
                  value: client._id,
                  retailerId: client.retailerId,
                  label: this.getClientLabel(client),
                }))}
                placeholder="Clients"
                value={clients}
                onChange={(options, { action }) => {
                  this.handleChange("clients", options || [], action)
                }}
                menuPosition={"fixed"}
              />
            </div>
          </MeetingClients>
          <MeetingBudget value={budget} onChange={this.onInputChange} />
          <Flex margin="0 0 20px 0">
            <span>Meeting Resp.</span>
            <div style={{ width: "60%" }}>{meetingResp.length > 0 ? meetingResp[0].label : "-"}</div>
          </Flex>
          <AvailableStaff
            isStaffFetched={isMeetingStaffFetched}
            isFetchingMeetingStaff={isFetchingMeetingStaff}
            isDisableEdit={clients.length === 0}
            otherHosts={otherHosts}
            availableHosts={availableHosts}
            availableStaff={availableStaff}
            meetingApproach={meetingApproach?.value}
            videoTool={videoTool}
            canChangeVideoTool={true}
            host={host}
            meetingResp={meetingResp}
            onChange={this.handleChange}
            onMenuOpen={this.onStaffMenuOpen}
          />
          <Flex column>
            <Button align="flex-end" disabled={isButtonDisabled} type="button" onClick={this.onBook}>
              Book Now
            </Button>
          </Flex>
        </BookingModalDialogBody>
      </BookingModal>
    )
  }
}

NewBookingModal.propTypes = {
  availableClients: PropTypes.array,
  availableHosts: PropTypes.array,
  availableStaff: PropTypes.array,
  booking: PropTypes.object,
  isFetchingClients: PropTypes.bool,
  isFetchingMeetingStaff: PropTypes.bool,
  isOpen: PropTypes.bool,
  openingHours: PropTypes.object,
  retailers: PropTypes.object,
  scheduleId: PropTypes.string,
  session: PropTypes.object,
  onConfirmBooking: PropTypes.func,
  onFetchAvailableClients: PropTypes.func,
  onFetchAvailableStaff: PropTypes.func,
  onRequestClose: PropTypes.func,
  onUpdateTime: PropTypes.func,
}

export default NewBookingModal

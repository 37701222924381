import React, { memo } from "react"
import { Tabs, TabPane } from "modaresa-commons"

import { isStaffAdmin, isStaffAtLeastManager } from "../../../helpers/users"
import withLoggedUser from "../../hoc/withLoggedUser"

import Availabilities from "./Availabilities"
import BuyerResponsibility from "./BuyerResponsibility"
import General from "./General"
import Notifications from "./Notifications/Notifications"
import { Container } from "./StaffProfile.styles"
import StaffProfileHeader from "./StaffProfileHeader"
import StaffProfileSkeleton from "./StaffProfileSkeleton"
import VirtualMeetingIds from "./VirtualMeetingIds"

type Props = {
  loggedUser: {
    staff: {
      modaresaRole: string,
    },
  },
  staff: {},
  onChangeTab: Function,
  activeSection: string,
  showNotificationsTab: boolean,
  canEdit: boolean,
}
const StaffProfile = ({
  skeleton,
  loggedUser,
  staff,
  onChangeTab,
  activeSection,
  showNotificationsTab,
  canEdit,
}: Props) => {
  const isFreelancer = loggedUser.staff.modaresaRole === "freelancer"
  if (skeleton)
    return (
      <StaffProfileSkeleton
        isLoggedUserFreelancer={isFreelancer}
        showNotificationsTab={showNotificationsTab}
        activeSection={activeSection}
        onChangeTab={onChangeTab}
      />
    )
  const isEditor = loggedUser.staff.hasEditorRights
  const isAtLeastManager = isStaffAtLeastManager(loggedUser.staff.modaresaRole)
  const canEditAccessRights = isStaffAdmin(loggedUser.staff.modaresaRole)
  const canEditAreasAndTerritories = isEditor || isAtLeastManager
  const canEditStaff = canEditAccessRights || isEditor || canEdit
  const readOnly = !canEditStaff
  return (
    <Container>
      <StaffProfileHeader staff={staff} />
      <Tabs activeKey={activeSection} onChange={onChangeTab}>
        {!isFreelancer && [
          <TabPane key="1" tabKey="general" tab="General">
            <General
              loggedUser={loggedUser}
              staff={staff}
              readOnly={readOnly}
              canEditAccessRights={canEditAccessRights}
              canEditAreasAndTerritories={canEditAreasAndTerritories}
            />
          </TabPane>,
          <TabPane key="2" tabKey="buyer-responsibility" tab="Buyer Responsibility">
            <BuyerResponsibility staff={staff} readOnly={readOnly} restrictBuyersToStaffAreas={!isAtLeastManager} />
          </TabPane>,
          showNotificationsTab && (
            <TabPane key="3" tabKey="notifications" tab="Notifications">
              <Notifications staff={staff} />
            </TabPane>
          ),
        ]}
        <TabPane tabKey="availabilities" tab="Availabilities">
          <Availabilities staff={staff} readOnly={readOnly} />
        </TabPane>
        <TabPane tabKey="video-ids" tab="Virtual Meeting IDs">
          <VirtualMeetingIds staff={staff} readOnly={readOnly} />
        </TabPane>
      </Tabs>
    </Container>
  )
}
export default withLoggedUser(memo(StaffProfile))

import React from "react"

import { RoomSchedule as RoomScheduleContainer } from "../../styles.js"

import { Schedule } from "./Schedule"

type Props = {
  dayIndex: number,
  meetingLength: number,
  range: [],
  room: {},
  roomType: string,
  roomRelou: {},
  sessionSchedule: {},
  onSlotClick: Function,
  onBookingClick: Function,
  canEditBooking: boolean,
}

const RoomWeeklySchedule = ({
  dayIndex,
  meetingLength,
  range,
  room,
  roomType,
  roomRelou,
  sessionSchedule,
  onSlotClick,
  onBookingClick,
  canEditBooking,
}: Props) => (
  <RoomScheduleContainer>
    <Schedule
      dayIndex={dayIndex}
      meetingLength={meetingLength}
      range={range}
      room={room}
      roomType={roomType}
      hasRelous={!!roomRelou}
      sessionSchedule={sessionSchedule}
      onBookingClick={onBookingClick}
      onSlotClick={onSlotClick}
      canEditBooking={canEditBooking}
    />
    {roomRelou && (
      <Schedule
        dayIndex={dayIndex}
        meetingLength={meetingLength}
        range={range}
        room={roomRelou}
        roomType="joker"
        sessionSchedule={sessionSchedule}
        onBookingClick={onBookingClick}
        onSlotClick={onSlotClick}
        canEditBooking={canEditBooking}
      />
    )}
  </RoomScheduleContainer>
)
export { RoomWeeklySchedule }

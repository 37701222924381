import React, { memo } from "react"
import { Route, Switch } from "react-router-dom"

import Room from "./Room"
import Rooms from "./Rooms"

type Props = {
  salesSessionId: string,
  salesSessionName: string,
  brandId: string,
  canEdit: boolean,
  onClickNext: Function,
}

const SalesSessionRooms = ({ salesSessionId, salesSessionName, brandId, canEdit, onSubmit }: Props) => (
  <Switch>
    <Route exact path={`/sessions/:id/rooms/:roomId`}>
      <Room salesSessionId={salesSessionId} salesSessionName={salesSessionName} brandId={brandId} canEdit={canEdit} />
    </Route>
    <Route exact path={`/sessions/:id/rooms/`}>
      <Rooms salesSessionId={salesSessionId} canEdit={canEdit} onClickNext={onSubmit} />
    </Route>
  </Switch>
)

export default memo(SalesSessionRooms)

import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactPhoneInput from "react-phone-input-2"
import { Button, Flex, InputWithValidation } from "modaresa-commons"

import {
  ControlBlock,
  ControlButton,
  Form,
  FormRow,
  Image,
  ImageContainer,
  InputImage,
  InputImageLabel,
  PageContainer,
} from "./styles"

import { emailRegex } from "helpers/regex"

class CompanyGeneral extends Component {
  constructor(props) {
    super(props)
    const { brand } = this.props
    this.state = {
      values: {
        name: (brand && brand.name) || "",
        address: (brand && brand.address) || "",
        phoneNumber: (brand && brand.phoneNumber) || "",
        contactEmail: (brand && brand.contactEmail) || "",
        orgNumber: (brand && brand.orgNumber) || "",
      },
      errors: {},
      touched: {},
      imageUrl:
        (brand &&
          brand.imageUrl &&
          "https://s3-eu-west-1.amazonaws.com/modaresabrandsdev/brands_logos/" + brand.imageUrl) ||
        "",
      isChanged: false,
      savingRequest: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { savingRequest } = prevState
    const { requestPending, requestError } = nextProps

    if (savingRequest && !requestPending && !requestError) {
      return { savingRequest: false }
    }
    return null
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "name":
      case "address":
      case "orgNumber":
        if (value.trim().length > 0) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Required`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "contactEmail":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (emailRegex.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "phoneNumber":
        if (value.trim().length < 3) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (value.trim().length < 8) {
          errors[name] = `Invalid phone number`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  onInputChange = e => {
    if (typeof e === "string") {
      this.setState({ ...this.validate(e, "phoneNumber"), isChanged: true })
    } else {
      const name = e.target.name
      const value = e.target.value
      this.setState({ ...this.validate(value, name), isChanged: true })
    }
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  addImage = e => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.setState({ imageUrl: reader.result, isChanged: true })
      }
    }
    e.target.value = ""
  }

  removeImage = e => {
    e.preventDefault()
    this.setState({ imageUrl: "", isChanged: true })
  }

  onSaveChange = () => {
    const { values, imageUrl } = this.state
    this.props.onSaveChanges({ ...values, imageUrl })
    this.setState({ isChanged: false, savingRequest: true })
  }

  isDisable = () => {
    const { savingRequest, isChanged } = this.state
    const { requestPending, requestError } = this.props

    if (savingRequest) {
      return !(!requestPending && requestError)
    }
    return !isChanged
  }

  render() {
    const { userIsAgent } = this.props
    const { errors, values, touched, imageUrl } = this.state
    return (
      <PageContainer>
        <Flex align="flex-start" justify="flex-start">
          <InputImageLabel noborder={imageUrl ? "transparent" : ""}>
            <span>Upload Image</span>
            <InputImage accept="image/*" disabled={userIsAgent} type="file" onChange={this.addImage} />
            {imageUrl && (
              <ImageContainer>
                <Image src={imageUrl} />
              </ImageContainer>
            )}
            <ControlBlock className={imageUrl ? "control-container" : "control-container hidden"}>
              <ControlButton>Edit</ControlButton>
              <ControlButton onClick={this.removeImage}>Delete</ControlButton>
            </ControlBlock>
          </InputImageLabel>
          <div>
            <Form>
              <FormRow>
                <span>Company Name</span>
                <InputWithValidation
                  disabled
                  errors={errors.name}
                  name="name"
                  touched={touched.name}
                  value={values.name}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
              <FormRow>
                <span>Address (Office 1)</span>
                <InputWithValidation
                  disabled={userIsAgent}
                  errors={errors.address}
                  name="address"
                  touched={touched.address}
                  value={values.address}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
              <FormRow>
                <span>Phone Number</span>
                <InputWithValidation.InputContainer>
                  <ReactPhoneInput
                    buttonStyle={errors.phoneNumber && touched.phoneNumber && { borderColor: "red" }}
                    country={"fr"}
                    disabled={userIsAgent}
                    inputExtraProps={{
                      name: "phoneNumber",
                    }}
                    inputStyle={errors.phoneNumber && touched.phoneNumber && { borderColor: "red" }}
                    enableSearch
                    disableSearchIcon
                    searchStyle={{ width: "95%" }}
                    value={values.phoneNumber}
                    onBlur={this.onBlur}
                    onChange={this.onInputChange}
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <InputWithValidation.ValidationError>{errors.phoneNumber}</InputWithValidation.ValidationError>
                  )}
                </InputWithValidation.InputContainer>
              </FormRow>
              <FormRow>
                <span>Admin Email</span>
                <InputWithValidation
                  disabled={userIsAgent}
                  errors={errors.contactEmail}
                  name="contactEmail"
                  touched={touched.contactEmail}
                  value={values.contactEmail}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
              <FormRow>
                <span>Organisation Number</span>
                <InputWithValidation
                  disabled={userIsAgent}
                  errors={errors.orgNumber}
                  name="orgNumber"
                  touched={touched.orgNumber}
                  value={values.orgNumber}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
            </Form>
          </div>
        </Flex>
        <Button
          align="flex-end"
          disabled={this.isDisable() || userIsAgent}
          margin="0 35px 35px 0"
          minWidth="150px"
          onClick={this.onSaveChange}
        >
          Save Changes
        </Button>
      </PageContainer>
    )
  }
}

CompanyGeneral.propTypes = {
  brand: PropTypes.object,
  match: PropTypes.object,
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  userIsAgent: PropTypes.bool,
  onSaveChanges: PropTypes.func,
}

export default CompanyGeneral

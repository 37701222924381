export const modaResaRoles = {
  agent: { name: "Agent" },
  manager: { name: "Manager" },
  freelancer: { name: "Freelancer" },
  receptionist: { name: "Receptionist" },
  nonExecAreaManager: { name: "Non executive Area Manager" },
  areaManager: { name: "Area Manager" },
  admin: { name: "Admin" },
  owner: { name: "Owner" },
}

export const optionsRoles = [
  { value: "owner", label: "Owner" },
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
  { value: "agent", label: "Agent" },
  { value: "freelancer", label: "Freelancer" },
]
export const optionsRolesWithoutOwner = optionsRoles.filter(role => role.value !== "owner")

export const collectionLookup = {
  readyToWear: "Ready To Wear",
  accessories: "Accessories",
  shoes: "Shoes",
  leatherGoods: "Leather Goods",
  joker: "Joker",
  bags: "Bags",
}
export const seasonsLookup = {
  summerSpring: "Summer Spring",
  preSummerSpring: "Pre-Summer Spring",
  perSummerSpring: "Pre-Summer Spring",
  autumWinter: "Autum Winter",
  preAutumWinter: "Pre-Autum Winter",
}
export const marketLookup = {
  women: "Women",
  men: "Men",
  womenMen: "Women & Men",
}

export const optionsBlockingListTypes = [
  {
    label: "Whitelist",
    value: "whitelist",
  },
  {
    label: "Blacklist",
    value: "blacklist",
  },
]

export const blockingListTypes = {
  whitelist: {
    label: "Whitelist",
    help: `Retailers <strong><u>ONLY</u></strong> on this list <strong>CAN</strong> view and book this room.`,
  },
  blacklist: {
    label: "Blacklist",
    help: `Retailers on this list <strong>CANNOT</strong> view and book this room.`,
  },
}

export const APP_NAME = "ModaResa"

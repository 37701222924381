import React, { Component } from "react"
import { Link } from "react-router-dom"
import Modal from "react-modal"
import PropTypes from "prop-types"
import forEach from "lodash/forEach"
import { Button, Flex } from "modaresa-commons"

import { modaResaRoles } from "../../../../../config/settings"

import { Bold, ButtonsRow, CloseModal, ModalBody } from "./styles"

const customStyles = {
  content: {
    width: "450px",
    height: "auto",
    maxHeight: "500px",
    minHeight: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 2,
  },
}

class AddStaffModal extends Component {
  state = {
    selectedNewStaff: {},
  }

  toggleNewStaff = id => {
    this.setState(state => {
      if (!state.selectedNewStaff[id]) {
        return {
          selectedNewStaff: {
            ...state.selectedNewStaff,
            [id]: true,
          },
        }
      } else if (state.selectedNewStaff[id]) {
        return {
          selectedNewStaff: {
            ...state.selectedNewStaff,
            [id]: false,
          },
        }
      }
    })
  }

  addStaff = () => {
    const { selectedNewStaff } = this.state
    const staffToAdd = Object.fromEntries(Object.entries(selectedNewStaff).filter(item => item[1] === true))
    this.props.onAddStaff(staffToAdd)
    this.setState({ selectedNewStaff: {} })
  }

  render() {
    const { selectedNewStaff } = this.state
    const { isModal, staff, sessionStaff, allStaff } = this.props
    const freeStaffList = []
    const newStaff = allStaff.filter(id => !sessionStaff[id])

    forEach(newStaff, value => {
      const member = staff.byId[value]
      freeStaffList.push(
        <Flex justify="flex-start" key={value}>
          <input
            checked={this.state.selectedNewStaff[member._id] === true || false}
            id={value}
            name={member._id}
            style={{ margin: "0 10px 0 0 " }}
            type="checkbox"
            onChange={() => this.toggleNewStaff(member._id)}
          />
          {`${member.firstName} ${member.lastName} - ${modaResaRoles[member.modaresaRole].name}`}
        </Flex>,
      )
    })

    return (
      <Modal isOpen={isModal} style={customStyles}>
        <ModalBody>
          <div>
            <Bold style={{ fontSize: "16px" }}>Add new Staff to {this.props.sessionName}</Bold>
          </div>
          {(freeStaffList.length > 0 && (
            <Flex align="stretch" column style={{ margin: "15px 0" }}>
              {freeStaffList}
            </Flex>
          )) || (
            <div style={{ margin: "15px 0" }}>
              All staff has been added to current sales session. If you need to add new staff members to the system,
              please go to Staff to invite them to Modaresa.
            </div>
          )}
        </ModalBody>
        <ButtonsRow>
          {(freeStaffList.length > 0 && (
            <Button
              disabled={!(Object.keys(selectedNewStaff).filter(key => selectedNewStaff[key] === true).length > 0)}
              onClick={this.addStaff}
            >
              Add Staff to Session
            </Button>
          )) || (
            <Link to={`/staff/new`}>
              <Button>I want to add new Staff</Button>
            </Link>
          )}
        </ButtonsRow>
        <CloseModal onClick={() => this.props.onCloseModal("staffModal")}>X</CloseModal>
      </Modal>
    )
  }
}

AddStaffModal.propTypes = {
  allStaff: PropTypes.array,
  isModal: PropTypes.bool,
  sessionName: PropTypes.string,
  sessionStaff: PropTypes.object,
  staff: PropTypes.object,
  onAddStaff: PropTypes.func,
  onCloseModal: PropTypes.func,
}

export default AddStaffModal

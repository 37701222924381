import React, { memo, useCallback } from "react"
import { useQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"

import { SALES_SESSION_ROOMS } from "../../../../../graphql/queries/salesSession"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"

import RoomsView from "./RoomsView"
import SalesSessionTableRoomsActions from "./SalesSessionTableRoomsActions"

type Props = {
  salesSessionId: string,
  onClickNext: Function,
}

const Rooms = ({ salesSessionId, onClickNext }: Props) => {
  const history = useHistory()
  const handleRenderTableRoomsActions = useCallback(room => <SalesSessionTableRoomsActions room={room} />, [])
  const handleClickTableRoomsRow = useCallback(
    room => {
      history.push(`/sessions/${salesSessionId}/rooms/${room.id}`)
    },
    [salesSessionId, history],
  )
  const { loading, data, error } = useQuery(SALES_SESSION_ROOMS, {
    variables: { id: salesSessionId },
  })
  if (loading && !data) return <RoomsView skeleton />
  if (error)
    return (
      <ErrorLiveChatHelp technicalError={error}>An error occured while loading sales session rooms</ErrorLiveChatHelp>
    )
  const { rooms } = data.SalesSession
  return (
    <RoomsView
      rooms={rooms}
      onRenderTableRoomsActions={handleRenderTableRoomsActions}
      onClickTableRoomsRow={handleClickTableRoomsRow}
      onClickNext={onClickNext}
    />
  )
}

export default memo(Rooms)

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import moment from "moment-timezone"
import times from "lodash/times"
import { Flex, Input } from "modaresa-commons"

import { standardDuration } from "./config"

const capacity = times(35, n => ({ value: n + 1, label: `${n + 1}` }))
const jokerOptions = times(5, n => ({ value: n + 1, label: `${n + 1}` }))

/*
const models = [
  { value: 0.05, label: "0.05" },
  { value: 0.1, label: "0.10" },
  { value: 0.15, label: "0.15" },
  { value: 0.2, label: "0.20" },
  { value: 0.25, label: "0.25" },
  { value: 0.3, label: "0.30" },
  { value: 0.35, label: "0.35" },
  { value: 0.4, label: "0.40" },
  { value: 0.45, label: "0.45" },
  { value: 0.5, label: "0.50" },
  { value: 0.55, label: "0.55" },
  { value: 0.6, label: "0.60" },
  { value: 0.65, label: "0.65" },
  { value: 0.7, label: "0.70" },
  { value: 0.75, label: "0.75" },
  { value: 0.8, label: "0.80" },
  { value: 0.85, label: "0.85" },
  { value: 0.9, label: "0.90" },
  { value: 0.95, label: "0.95" },
  { value: 1.0, label: "1.00" },
]
*/
class RoomStructure extends PureComponent {
  handleRoomChange = (type, field, value) => {
    const { dayIndex, roomIndex, onRoomChange } = this.props
    onRoomChange(roomIndex, type, field, value, dayIndex)
  }

  render() {
    const { structure, dayIndex, showDates, isDisabled, readOnly, videoUrl } = this.props

    return (
      <Flex key={dayIndex} margin="0 0 10px 0" style={{ padding: "0 15px" }}>
        {(showDates && <div style={{ width: "20%" }}>{moment.utc(structure.date, "X").format("ddd, MMM Do")}</div>) || (
          <div style={{ width: "18%" }}>
            <Input
              isDisabled={readOnly}
              placeholder="https://..."
              value={videoUrl}
              onChange={e => this.handleRoomChange("videoUrl", null, e.target.value)}
            />
          </div>
        )}
        <div style={{ width: "17%" }}>
          <Select
            closeMenuOnScroll
            isDisabled={readOnly}
            isOptionDisabled={option => (isDisabled ? option.value < structure.meetingCapacity : false)}
            menuPortalTarget={document.body}
            options={capacity}
            placeholder=""
            value={{
              value: structure.meetingCapacity,
              label: structure.meetingCapacity,
            }}
            onChange={option => this.handleRoomChange("meetingCapacity", null, option.value)}
          />
        </div>
        <div style={{ width: "15%" }}>
          <Select
            closeMenuOnScroll
            isDisabled={readOnly}
            isOptionDisabled={option => (isDisabled ? option.value < structure.jokers : false)}
            menuPortalTarget={document.body}
            options={jokerOptions}
            placeholder=""
            value={{ value: structure.jokers, label: structure.jokers }}
            onChange={option => this.handleRoomChange("jokers", null, option.value)}
          />
        </div>
        <div style={{ width: "15%" }}>
          <Select
            closeMenuOnScroll
            isDisabled={readOnly}
            menuPortalTarget={document.body}
            options={standardDuration}
            placeholder=""
            value={standardDuration.filter(dur => dur.value === structure.length)}
            onChange={option => this.handleRoomChange("length", null, option.value)}
          />
        </div>
        {/*}
        <div style={{ width: "15%" }}>
          <Select
            closeMenuOnScroll
            isDisabled={readOnly}
            menuPortalTarget={document.body}
            options={models}
            placeholder=""
            value={{ value: structure.models, label: structure.models }}
            onChange={option =>
              this.handleRoomChange("models", null, option.value)
            }
          />
        </div>*/}
      </Flex>
    )
  }
}

RoomStructure.propTypes = {
  dayIndex: PropTypes.number,
  isDisabled: PropTypes.bool,
  meals: PropTypes.object,
  readOnly: PropTypes.bool,
  roomIndex: PropTypes.number,
  showDates: PropTypes.bool,
  videoUrl: PropTypes.string,
  structure: PropTypes.object,
  onRoomChange: PropTypes.func,
}

RoomStructure.defaultProps = {
  showDates: false,
}

export { RoomStructure }

import { gql } from "@apollo/client"

export const SWITCH_MEETING_HOSTS = gql`
  mutation SwitchBookingEventsMeetingHosts($bookingEventIdA: ID!, $bookingEventIdB: ID!) {
    switchBookingEventsMeetingHosts(bookingEventIdA: $bookingEventIdA, bookingEventIdB: $bookingEventIdB) {
      bookingEventA {
        id
        scheduleId
        dayDate
        room
        tableIndex
        type
        host {
          id
        }
      }
      bookingEventB {
        id
        scheduleId
        dayDate
        room
        tableIndex
        type
        host {
          id
        }
      }
    }
  }
`

import { useField } from "formik"
import React, { memo, useCallback } from "react"
import { Button } from "modaresa-commons"

import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

type Props = {
  disabled: boolean,
  labelWidth: string,
}

const FieldButtonAddBlockingList = ({ labelWidth, disabled }: Props) => {
  const [field, , helpers] = useField("hasBlockingList")
  const toggleBlockingList = useCallback(() => helpers.setValue(!field.value), [field, helpers])
  return (
    <FormFieldLayout label=" " labelWidth={labelWidth}>
      <Button disabled={disabled} type="button" onClick={toggleBlockingList}>
        {field.value === true ? "Remove" : "Add"} blocking list
      </Button>
    </FormFieldLayout>
  )
}

export default memo(FieldButtonAddBlockingList)

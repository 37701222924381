import { createAsyncAction } from "../../helpers/redux"

export const FETCH_CLIENTS = createAsyncAction("FETCH_CLIENTS")
export const fetchClients = brandId => ({
  type: FETCH_CLIENTS.REQUEST,
  payload: { brandId },
})

export const FIND_BUYERS = createAsyncAction("FIND_BUYERS")
export const findBuyers = (queryParams, callbackSuccess, callbackFailure) => ({
  type: FIND_BUYERS.REQUEST,
  payload: { queryParams, callbackSuccess, callbackFailure },
})

export const FIND_SHARED_BUYERS = createAsyncAction("FIND_SHARED_BUYERS")
export const findSharedBuyers = (queryParams, callbackSuccess, callbackFailure) => ({
  type: FIND_SHARED_BUYERS.REQUEST,
  payload: { queryParams, callbackSuccess, callbackFailure },
})

export const SELECT_BUYER_BY_ID = "SELECT_BUYER_BY_ID"
export const selectBuyerMember = id => ({
  type: SELECT_BUYER_BY_ID,
  payload: {
    id,
  },
})

export const UNSELECT_BUYER = "UNSELECT_BUYER"
export const unselectBuyerMember = () => ({
  type: UNSELECT_BUYER,
})

export const ADD_CLIENT = createAsyncAction("ADD_CLIENT")
export const addClient = (data, callbackSuccess) => ({
  type: ADD_CLIENT.REQUEST,
  payload: {
    data,
    callbackSuccess,
  },
})
export const UPDATE_CLIENT = createAsyncAction("UPDATE_CLIENT")
export const updateClient = (data, callbackSuccess) => ({
  type: UPDATE_CLIENT.REQUEST,
  payload: data,
  callbackSuccess,
})

export const ADD_BUYER_WITH_NEW_DEFAULT_RETAILER = createAsyncAction("ADD_BUYER_WITH_NEW_DEFAULT_RETAILER")
export const addBuyerWithNewDefaultRetailer = (sharedRetailerId, brandId, buyerData, callbackSuccess) => ({
  type: ADD_BUYER_WITH_NEW_DEFAULT_RETAILER.REQUEST,
  sharedRetailerId,
  brandId,
  buyerData,
  callbackSuccess,
})

export const FILER_CLIENTS_BY_STATUS = "FILER_CLIENTS_BY_STATUS"
export const filterClients = status => ({
  type: FILER_CLIENTS_BY_STATUS,
  payload: {
    status,
  },
})

export const ADD_REPORT = createAsyncAction("ADD_REPORT")
export const addReport = (data, history) => ({
  type: ADD_REPORT.REQUEST,
  payload: {
    data,
    history,
  },
})

export const CHECK_IN = createAsyncAction("CHECK_IN")
export const checkIn = (bookingId, sessionId) => ({
  type: CHECK_IN.REQUEST,
  payload: {
    sessionId,
    bookingId,
  },
})

export const CHECK_OUT = createAsyncAction("CHECK_OUT")
export const checkOut = (bookingId, sessionId, history, staffId) => ({
  type: CHECK_OUT.REQUEST,
  payload: {
    sessionId,
    bookingId,
    history,
    staffId,
  },
})

export const UPDATE_REPORT = createAsyncAction("UPDATE_REPORT")
export const updateReport = (data, reportId, history) => ({
  type: UPDATE_REPORT.REQUEST,
  payload: {
    data,
    reportId,
    history,
  },
})

export const FETCH_REPORTS = createAsyncAction("FETCH_REPORTS")
export const fetchReports = clientId => ({
  type: FETCH_REPORTS.REQUEST,
  payload: {
    clientId,
  },
})

export const UNSELECT_REPORTS = "UNSELECT_REPORTS"
export const unselectReports = () => ({
  type: UNSELECT_REPORTS,
})

export const FETCH_REPORT = createAsyncAction("FETCH_REPORT")
export const fetchReport = reportId => ({
  type: FETCH_REPORT.REQUEST,
  payload: {
    reportId,
  },
})

export const UNSELECT_REPORT = "UNSELECT_REPORT"
export const unselectReport = () => ({
  type: UNSELECT_REPORT,
})

export const FETCH_CLIENT_BOOKINGS = createAsyncAction("FETCH_CLIENT_BOOKINGS")
export const fetctClientBookings = clientId => ({
  type: FETCH_CLIENT_BOOKINGS.REQUEST,
  payload: {
    clientId,
  },
})

export const UPLOAD_CLIENTS = createAsyncAction("UPLOAD_CLIENTS")
export const uploadClients = (file, brandId) => ({
  type: UPLOAD_CLIENTS.REQUEST,
  payload: {
    file,
    brandId,
  },
})

export const EXPORT_REPORTS = createAsyncAction("EXPORT_REPORTS")
export const exportReports = clientId => ({
  type: EXPORT_REPORTS.REQUEST,
  payload: {
    clientId,
  },
})

export const CREATE_REPORT = "CREATE_REPORT"
export const createReport = () => ({
  type: CREATE_REPORT,
})

export const CREATE_UPDATE_REQUEST = createAsyncAction("CREATE_UPDATE_REQUEST")
export const createUpdateRequest = data => ({
  type: CREATE_UPDATE_REQUEST.REQUEST,
  payload: data,
})

export const UPDATE_CLIENT_BOOKING = "UPDATE_CLIENT_BOOKING"

export const DELETE_CLIENT = createAsyncAction("DELETE_CLIENT")
export const deleteClient = clientId => ({
  type: DELETE_CLIENT.REQUEST,
  payload: {
    clientId,
  },
})

export const CLEAR_FILER_CLIENTS_BY_STATUS = "CLEAR_FILER_CLIENTS_BY_STATUS"
export const clearFilterClients = () => ({
  type: CLEAR_FILER_CLIENTS_BY_STATUS,
})

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Raven from "raven-js"
import { cloneDeep } from "lodash"

import { RoomSchedule } from "../RoomSchedule"
import { CalendarContainer, RoomCol, RoomHeader } from "../../styles.js"

import { getUrlParameter } from "helpers/urls"

class MasterCalendar extends PureComponent {
  state = { mounted: false }

  roomRefs = []

  componentDidMount() {
    const bookingId = getUrlParameter("booking")
    const day = getUrlParameter("day")
    const { attendanceSchedule: schedule, selectedDayIndex, goDay, onBookingClick } = this.props
    if (bookingId && day.length > 0) {
      onBookingClick(schedule[day].byId[bookingId], "userHasAccess")
      if (+day !== selectedDayIndex) {
        goDay(+day)
      }
      window.history.pushState("", "", "/calendars")
    }
    this.setState({ mounted: true })
  }

  mapTableWithClients = (table, room) => {
    const { clients } = this.props
    const filteredTables = table.filter(item => {
      const archivedClients = item.clients
        .filter(client => {
          const existClient = clients.byId[client]
          if (!existClient) return item
          return existClient.dbStatus === "archived"
        })
        .sort((a, b) => a > b)
      const sortclients = item.clients.sort((a, b) => a > b)
      if (archivedClients.length) {
        for (let i = 0; i < archivedClients.length; i++) {
          if (sortclients[i] !== archivedClients[i]) {
            return item
          }
        }
      } else {
        return item
      }
      Raven.captureMessage(
        `Booking event ${room.roomName} has archived clients ${archivedClients}. Remove the clients from the event or unarchive them`,
      )
      return false
    })
    return filteredTables
  }

  renderRooms = () => {
    const { schedule, relouSchedule, onSlotClick, selectedSession, selectedDayIndex, canEditBooking } = this.props

    return schedule.daySchedule.map(storeRoom => {
      const meetingLength = selectedSession.rooms.find(roomStructure => roomStructure.name === storeRoom.roomName)
        .structure[selectedDayIndex].length

      const room = cloneDeep(storeRoom)
      room.tables = room.tables.map(table => this.mapTableWithClients(table, room))

      const roomRelou = relouSchedule.daySchedule.find(relouRoom => relouRoom.roomName === room.roomName)
      let clonedRoomRelou = null
      if (roomRelou) {
        clonedRoomRelou = cloneDeep(roomRelou)
        clonedRoomRelou.tables = clonedRoomRelou.tables.map(table => this.mapTableWithClients(table, clonedRoomRelou))
      }

      return (
        <RoomCol flex={room.tables.length} key={room.roomName}>
          <RoomHeader>{room.roomName}</RoomHeader>
          <RoomSchedule
            meetingLength={meetingLength}
            mounted={this.state.mounted}
            openingHours={this.props.openingHours}
            room={room}
            roomType="normal"
            roomRelou={clonedRoomRelou}
            onBookingClick={this.props.onBookingClick}
            onSlotClick={onSlotClick}
            onSlotEnter={this.props.onSlotEnter}
            canEditBooking={canEditBooking}
          />
        </RoomCol>
      )
    })
  }

  render() {
    const { schedule } = this.props
    if (!schedule) {
      return <div>No calendar</div>
    }
    return <CalendarContainer onMouseLeave={this.props.onScheduleLeave}>{this.renderRooms()}</CalendarContainer>
  }
}

MasterCalendar.propTypes = {
  attendanceSchedule: PropTypes.array,
  goDay: PropTypes.func,
  openingHours: PropTypes.object,
  schedule: PropTypes.object,
  selectedDayIndex: PropTypes.any,
  selectedSession: PropTypes.object,
  onBookingClick: PropTypes.func,
  onScheduleLeave: PropTypes.func,
  onSlotClick: PropTypes.func,
  onSlotEnter: PropTypes.func,
}

export { MasterCalendar }

import { gql } from "@apollo/client"

import AreaFragments from "./area.fragments"
import RemainingAreaFragments from "./remainingArea.fragments"
import StaffFragments from "./staff.fragments"

export const GET_STAFF_CURRENT_AREAS_ASSIGNMENTS = gql`
  query GetStaffCurrentAreasAssignments($staffId: ID!) {
    getStaffCurrentAreasAssignments(staffId: $staffId) {
      settingsAreas {
        ...AreaStaffs
        name
      }
      settingsRemainingArea {
        ...RemainingAreaStaffs
      }
      settingsRooms {
        id
        name
        areas {
          ...AreaStaffs
          name
        }
        remainingArea {
          ...RemainingAreaStaffs
        }
      }
      activeSessions {
        id
        name
        areas {
          ...AreaStaffs
          name
        }
        remainingArea {
          ...RemainingAreaStaffs
        }
        rooms {
          id
          name
          areas {
            ...AreaStaffs
            name
          }
          remainingArea {
            ...RemainingAreaStaffs
          }
        }
      }
    }
  }
  ${AreaFragments.fragments.staffs}
  ${RemainingAreaFragments.fragments.staffs}
`

export const STAFF_FULL_PROFILE = gql`
  query StaffFullProfile($id: ID!) {
    Staff(id: $id) {
      id
      ...StaffFullProfile
    }
  }
  ${StaffFragments.fragments.full}
`

import Modal from "react-modal"
import styled from "styled-components"
if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root")

export const BookingModalContainer = styled(Modal)`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%;
  position: absolute;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  outline: none;
  justify-content: center;
  align-items: center;
`
export const BookingModalDialog = styled.div`
  background-color: #fff;
  position: relative;
  padding: 25px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.veryLightGray};
  max-height: 90vh;
  overflow: auto;
`
export const CloseIcon = styled.div`
  position: absolute;
  height: 1em;
  width: 1em;
  line-height: 1em;
  text-align: center;
  color: ${props => props.theme.berry};
  right: 10px;
  top: 10px;
  cursor: pointer;
`
export const BookingModalHeader = styled.div`
  padding: 20px 20px 0 20px;
  height: 100px;
  background-color: ${props => {
    if (props.dark) return props.theme.secondary
    if (props.light) return props.theme.primary
  }};
  color: #fff;
  border-radius: 4px;
`

export const BookingModalDialogBody = styled.div`
  padding-top: 20px;
`

export const Bold = styled.span`
  font-weight: bold;
`
export const Budget = styled.div`
  width: 50%;
  border: 1px solid ${props => props.theme.gray50};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 10px;
  margin-bottom: 20px;
`

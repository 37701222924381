import React, { useCallback, useState, memo } from "react"
import { Formik, Form } from "formik"
import { Link } from "react-router-dom"
import * as yup from "yup"
import { Button, ButtonSecondary } from "modaresa-commons"

import { priorityGroupList, statusList } from "../../../../../../config"
import { storeTypeOptions } from "../../../../../../config/retailers"
import FormFieldLayout from "../../../../../elements/FormFieldLayout/FormFieldLayout"
import FormikInput from "../../../../../elements/FormikFields/FormikInput"
import FormikPhoneNumber from "../../../../../elements/FormikFields/FormikPhoneNumber"
import FormikSelect from "../../../../../elements/FormikFields/FormikSelect"
import { yupSelectOption } from "../../../../../../helpers/yup"

import ListMatchingRetailers from "./ListMatchingRetailers"
import {
  CompetitorGroup,
  Container,
  ContainerForm,
  Divider,
  ContainerButtons,
  StyledLink,
} from "./RetailerInformationForm.styles"
import RetailerStoreAddress from "./RetailerStoreAddress"

const retailerInformationFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Required"),
  type: yupSelectOption.required("Required !"),
  priorityGroup: yupSelectOption.required("Required !"),
  clientId: yup
    .number()
    .typeError("Only numbers")
    .required("Required"),
  status: yupSelectOption.required("Required !"),
  storeAddress: yup
    .object()
    .shape({
      city: yup.string().required(),
      country: yup.string().required(),
    })
    .required("Must have a city"),
})

type Props = {
  isNew: boolean,
  onSubmit: Function,
  initialValues: {},
  sharedRetailers: [],
  brandRetailers: [],
}

const labelWidth = "140px"
const RetailerInformationForm = ({ isNew, brandRetailers, sharedRetailers, initialValues, onSubmit }: Props) => {
  const [showMatchingRetailers, setShowMatchingRetailers] = useState(false)
  const handleChangeStoreName = useCallback(() => setShowMatchingRetailers(true), [])
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validationSchema={retailerInformationFormSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <ContainerForm>
              <FormikInput
                direction="row"
                labelWidth={labelWidth}
                label="Store Name*"
                name="name"
                onChange={handleChangeStoreName}
              />
              {showMatchingRetailers && (
                <ListMatchingRetailers brandRetailers={brandRetailers} sharedRetailers={sharedRetailers} />
              )}
              <FormikSelect
                label="Store Type*"
                labelWidth={labelWidth}
                name="type"
                selectWidth="50%"
                options={storeTypeOptions}
              />
              <RetailerStoreAddress />
              <FormikPhoneNumber label="Phone Number" labelWidth={labelWidth} name="phoneNumber" />
              <FormikInput direction="row" labelWidth={labelWidth} label="Client ID*" name="clientId" />
              <FormikInput direction="row" labelWidth={labelWidth} label="Website" name="website" />
            </ContainerForm>
            <Divider>Retailer Attributes</Divider>
            <ContainerForm>
              <FormikSelect
                label="Priority Group*"
                labelWidth={labelWidth}
                name="priorityGroup"
                selectWidth="50%"
                options={priorityGroupList}
              />
              {!isNew && (
                <FormFieldLayout
                  label="Competitor Group"
                  labelWidth={labelWidth}
                  name="competitorGroup"
                  direction="row"
                >
                  <CompetitorGroup>
                    {`${values.competitorGroup?.name || "N/A"} - `}
                    <Link to="/clients/competitors">change</Link>
                  </CompetitorGroup>
                </FormFieldLayout>
              )}
              <FormikSelect
                label="Status*"
                labelWidth={labelWidth}
                name="status"
                selectWidth="50%"
                options={statusList}
              />
              <ContainerButtons>
                {isNew ? (
                  <Button minWidth="50%" type="submit">
                    + Add Retailer
                  </Button>
                ) : (
                  <>
                    <Button minWidth="49%" type="submit">
                      Save Changes
                    </Button>
                    <StyledLink to={`/clients/new?retailerId=${values.retailerId}`}>
                      <ButtonSecondary minWidth="100%" type="submit">
                        Add Buyer
                      </ButtonSecondary>
                    </StyledLink>
                  </>
                )}
              </ContainerButtons>
            </ContainerForm>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default memo(RetailerInformationForm)

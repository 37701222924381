import axios from "axios"

import { REST_API_URL } from "../../config"

export * from "./session"
export * from "./schedule"
export * from "./brand"
export * from "./staff"
export * from "./clients"
export * from "./retailer"
export * from "./jokerRequest"

export const login = async creds => {
  const response = await axios.post(`${REST_API_URL}/auth/login`, creds)
  return response.data
}

export const resetPassword = async data => {
  const response = await axios.post(`${REST_API_URL}/auth/resetpassword`, data)
  return response.data
}

export const sendResetEmail = async data => {
  const response = await axios.post(`${REST_API_URL}/auth/resetPassword/email`, data)
  return response.data
}

export const verifyToken = async data => {
  const response = await axios.post(`${REST_API_URL}/auth/resetpassword/verifytoken`, data)
  return response.data
}

export const fetchUser = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/user/${id}`, config)

  return response.data
}

export const fetchStaff = async ({ query, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: query,
  }
  const response = await axios.get(`${REST_API_URL}/staff`, config)

  return response.data
}

export const fetchBrand = async ({ token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/brands`, config)

  return response.data
}

export const fetchBrandStaff = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/staff?brandId=${brandId}`, config)

  return response.data
}

export const fetchClients = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/buyers?brandId=${brandId}`, config)

  return response.data
}

export const fetchRetailers = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/retailers?brandId=${brandId}`, config)

  return response.data
}

export const fetchTimezone = async ({ timestamp, lat, lng }) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&language=en&key=AIzaSyC3boOLqNgMBUDAzCemBK_B0-yO8u7VfmU`,
  )

  return response.data
}

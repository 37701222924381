import { countries, marketsByValue } from "../../../../../config"
import { doesStringMatch } from "../../../../../helpers/filter"

const buyerSearch = {
  fullName: {
    match: (buyerFullname = "", searchValue) => {
      if (doesStringMatch(buyerFullname.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: buyerFullname,
            key: "fullName",
            label: `${buyerFullname} (full name)`,
          },
        ]
      }
      return null
    },
    filter: (buyers, value) => buyers.filter(buyer => buyer.fullName === value),
  },
  email: {
    match: (buyerEmail = "", searchValue) => {
      if (doesStringMatch(buyerEmail.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: buyerEmail,
            key: "email",
            label: `${buyerEmail} (email)`,
          },
        ]
      }
      return null
    },
    filter: (buyers, value) => buyers.filter(buyer => buyer.email === value),
  },
  retailer: {
    match: (buyerRetailer, searchValue) => {
      if (!buyerRetailer) return null
      const searchValueLowercase = searchValue.toLowerCase()
      const matchedOptions = []
      const { name, storeAddress, priorityGroup, meetingResponsible } = buyerRetailer
      if (doesStringMatch(name.toLowerCase(), searchValueLowercase)) {
        matchedOptions.push({
          value: name,
          key: "retailer.name",
          label: `${name} (retailer)`,
        })
      }
      if (storeAddress) {
        const { country, city } = storeAddress
        const countryObj = countries[country]
        if (doesStringMatch(countryObj?.name.toLowerCase(), searchValueLowercase)) {
          matchedOptions.push({
            value: country,
            key: "retailer.country",
            label: `${countryObj.name} (country)`,
          })
        }
        if (doesStringMatch(city.toLowerCase(), searchValueLowercase)) {
          matchedOptions.push({
            value: city,
            key: "retailer.city",
            label: `${city} (city)`,
          })
        }
      }
      if (meetingResponsible) {
        const { fullName, id } = meetingResponsible
        if (doesStringMatch(fullName.toLowerCase(), searchValueLowercase)) {
          matchedOptions.push({
            value: id,
            key: "meetingResponsible",
            label: `${fullName} (manager)`,
          })
        }
      }
      if (doesStringMatch(`priority group ${priorityGroup}`, searchValueLowercase)) {
        matchedOptions.push({
          value: priorityGroup,
          key: "priorityGroup",
          label: `Priority Group ${priorityGroup}`,
        })
      }
      return matchedOptions
    },
  },
  "retailer.name": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.name === value),
  },
  "retailer.country": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.storeAddress?.country === value),
  },
  "retailer.city": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.storeAddress?.city === value),
  },
  market: {
    match: (buyerMarket, searchValue) => {
      const buyerMarketLabel = marketsByValue[buyerMarket]
      if (!buyerMarketLabel) return null
      if (doesStringMatch(buyerMarketLabel.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: buyerMarket,
            key: "market",
            label: `${buyerMarketLabel} (market)`,
          },
        ]
      }
      return null
    },
    filter: (buyers, value) => buyers.filter(buyer => buyer.market === value),
  },
  meetingResponsible: {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.meetingResponsible?.id === value),
  },
  priorityGroup: {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.priorityGroup === value),
  },
}

export const buyerSearchMatchKeys = Object.keys(buyerSearch).filter(key => buyerSearch[key].match)
export default buyerSearch

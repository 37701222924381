import moment from "moment-timezone"

import { pad } from "./utils"

export const getHourList = (format = "24") => {
  const today = moment()
    .utc()
    .set({ hour: 0, minute: 0, second: 0 })
  const hoursList = []
  for (let i = 1; i <= 48; i++) {
    const value = format === "24" ? today.utc().format("HH:mm") : today.utc().format("hh:mm A")
    hoursList.push({ value, label: value, timestamp: today.unix() })
    today.add(30, "minutes")
  }
  return hoursList
}

export const generateHoursOptions = addHalfHours => {
  const hoursList = []
  const generateHourValue = (hours, minutes) => ({
    hours,
    minutes,
    numerical: hours + minutes / 60,
    formatted: `${pad(hours, 2)}:${pad(minutes, 2)}`,
  })

  for (let i = 0; i < 24; i++) {
    const hourValue = generateHourValue(i, 0)
    hoursList.push({ value: hourValue, label: hourValue.formatted })
    if (addHalfHours) {
      const hourAndHalfValue = generateHourValue(i, 30)
      hoursList.push({
        value: hourAndHalfValue,
        label: hourAndHalfValue.formatted,
      })
    }
  }
  return hoursList
}
export const hoursFormat24Options = generateHoursOptions(true)
export const hoursFormat24OptionsAsObject = hoursFormat24Options.reduce((obj, opt) => {
  obj[opt.value.numerical] = opt
  return obj
}, {})

export const getRangeHourList = (startStamp, endStamp) => {
  const start = moment.utc(startStamp, "X")
  const end = moment.utc(endStamp, "X")
  const hoursList = []
  const diff = end.diff(start, "hours", true)
  for (let i = 1; i <= diff * 4 + 1; i++) {
    const label = start.utc().format("HH:mm")
    hoursList.push({ value: start.unix(), label })
    start.add(15, "minutes")
  }
  return hoursList
}

export const getMinutesList = () => {
  let min = 15
  const today = moment()
    .utc()
    .set({ hour: 0, minute: 15, second: 0 })
  const minutesList = []

  for (let i = 1; i <= 20; i++) {
    const minutes = today.minute()
    const hour = today.hour()

    const getTimeString = () => {
      if (!hour) {
        return `${minutes} min`
      }
      if (hour > 1 && minutes > 0) {
        return `${hour} hours ${minutes} min`
      } else if (hour > 1) {
        return `${hour} hours`
      }

      if (hour === 1 && minutes > 0) {
        return `${hour} hour ${minutes} min`
      } else if (hour === 1) {
        return `${hour} hour`
      }
    }

    minutesList.push({ value: min, label: getTimeString() })
    today.add(15, "minutes")
    min += 15
  }
  return minutesList
}

export const getTime = time => {
  const [hours, minutes] = time.split(":")
  return { hours, minutes }
}

export const setTodayDate = timestamp => {
  const todayDate = moment().format("YYYY:MM:DD")
  const [year, month, date] = todayDate.split(":")
  return moment
    .utc(timestamp, "X")
    .set({ year, month: month - 1, date })
    .unix()
}

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { getBookingFilterType, getBookingFilterValue, getClients, getRetailers } from "../../../../../store/selectors"
import { filterBookings } from "../../../../../store/actions/schedules"

import Component from "./FIlter"

const selector = createStructuredSelector({
  retailers: getRetailers,
  filterType: getBookingFilterType,
  filterValue: getBookingFilterValue,
  clients: getClients,
})

const actions = {
  filter: filterBookings,
}

export const Filter = connect(selector, actions)(Component)

import {
  CHECK_IF_VERIFIED,
  CLEAR_AREA_MANAGER,
  CREATE_STAFF_MEMBER,
  DELETE_STAFF,
  FETCH_AREA_MANAGERS,
  FETCH_STAFF,
  FETCH_STAFF_AVAILABLE_CLIENTS,
  GIVE_ACCESS,
  REGISTER_USER,
  SELECT_STAFF_MEMBER,
  UNSELECT_STAFF_MEMBER,
  UPDATE_STAFF_MEMBER,
  VERIFY_EMAIL,
} from "../actions/staff"
import { createReducer } from "../../helpers/redux"

const initialState = {
  staff: {},
  staffRequestPending: false,
  selectedMember: null,
  requestPending: false,
  requestError: null,
  verificationRequestPending: false,
  verificationRequestError: null,
  staffAvailableClients: [],
  requestStaffClientsPending: false,
  requestStaffClientsError: null,
  areaManagers: null,
  requestFetchAMPending: false,
  requestFetchAMError: null,
  verifiedRequestPending: false,
  verifiedRequestError: null,
}

const handlers = {
  [FETCH_STAFF.REQUEST]: state => ({
    ...state,
    staffRequestPending: true,
  }),
  [FETCH_STAFF.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: payload,
    staffRequestPending: false,
  }),
  [FETCH_STAFF.FAILURE]: state => ({
    ...state,
    staffRequestPending: false,
  }),
  [SELECT_STAFF_MEMBER.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedMember: state.staff.byId[payload.id],
  }),
  [UNSELECT_STAFF_MEMBER]: state => ({
    ...state,
    selectedMember: null,
  }),
  [CREATE_STAFF_MEMBER.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [CREATE_STAFF_MEMBER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [CREATE_STAFF_MEMBER.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: {
      ...state.staff,
      byId: {
        ...state.staff.byId,
        [payload._id]: {
          ...payload,
        },
      },
      allIds: [payload._id, ...state.staff.allIds],
    },
    selectedMember: payload,
    requestPending: false,
  }),
  [UPDATE_STAFF_MEMBER.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_STAFF_MEMBER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [UPDATE_STAFF_MEMBER.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: {
      ...state.staff,
      byId: {
        ...state.staff.byId,
        [payload._id]: {
          ...payload,
        },
      },
    },
    selectedMember: payload,
    requestPending: false,
  }),
  [CHECK_IF_VERIFIED.REQUEST]: state => ({
    ...state,
    verifiedRequestPending: true,
    verifiedRequestError: null,
  }),
  [CHECK_IF_VERIFIED.SUCCESS]: (state, { payload }) => {
    const { verified, updatedStaffMember } = payload
    if (verified) {
      return {
        ...state,
        staff: {
          ...state.staff,
          byId: {
            ...state.staff.byId,
            [updatedStaffMember._id]: {
              ...updatedStaffMember,
            },
          },
        },
        selectedMember: updatedStaffMember,
        verifiedRequestPending: false,
      }
    }
    return {
      ...state,
      verifiedRequestPending: false,
    }
  },
  [CHECK_IF_VERIFIED.FAILURE]: (state, { payload }) => ({
    ...state,
    verifiedRequestPending: false,
    verifiedRequestError: payload,
  }),
  [VERIFY_EMAIL.REQUEST]: state => ({
    ...state,
    verificationRequestPending: true,
    verificationRequestError: null,
  }),
  [VERIFY_EMAIL.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: {
      ...state.staff,
      byId: {
        ...state.staff.byId,
        [payload._id]: {
          ...payload,
        },
      },
    },
    selectedMember: payload,
    verificationRequestPending: false,
  }),
  [VERIFY_EMAIL.FAILURE]: (state, { payload }) => ({
    ...state,
    verificationRequestPending: false,
    verificationRequestError: payload,
  }),
  [REGISTER_USER.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [REGISTER_USER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [REGISTER_USER.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: {
      ...state.staff,
      byId: {
        ...state.staff.byId,
        [payload._id]: {
          ...payload,
        },
      },
      allIds: [payload._id, ...state.staff.allIds],
    },
    selectedMember: payload,
    requestPending: false,
  }),
  [GIVE_ACCESS.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [GIVE_ACCESS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [GIVE_ACCESS.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: {
      ...state.staff,
      byId: {
        ...state.staff.byId,
        [payload._id]: {
          ...payload,
        },
      },
    },
    selectedMember: payload,
    requestPending: false,
  }),
  [FETCH_STAFF_AVAILABLE_CLIENTS.REQUEST]: state => ({
    ...state,
    requestStaffClientsPending: true,
    requestStaffClientsError: null,
  }),
  [FETCH_STAFF_AVAILABLE_CLIENTS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestStaffClientsPending: false,
    requestStaffClientsError: payload,
  }),
  [FETCH_STAFF_AVAILABLE_CLIENTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    staffAvailableClients: payload,
    requestStaffClientsPending: false,
  }),
  [DELETE_STAFF.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [DELETE_STAFF.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [DELETE_STAFF.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: {
      ...state.staff,
      byId: {
        ...state.staff.byId,
        [payload._id]: {
          ...payload,
        },
      },
    },
    requestPending: false,
  }),
  [FETCH_AREA_MANAGERS.REQUEST]: state => ({
    ...state,
    requestFetchAMPending: true,
    requestFetchAMError: null,
  }),
  [FETCH_AREA_MANAGERS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestFetchAMPending: false,
    requestFetchAMError: payload,
  }),
  [FETCH_AREA_MANAGERS.SUCCESS]: (state, { payload }) => ({
    ...state,
    areaManagers: payload,
    requestFetchAMPending: false,
  }),
  [CLEAR_AREA_MANAGER]: state => ({
    ...state,
    areaManagers: null,
  }),
}

export default createReducer(initialState, handlers)

import React, { memo } from "react"
import styled from "styled-components"
import { Ul, ModalConfirmation } from "modaresa-commons"

import MissingBookingEventsByOpeningHour from "./MissingBookingEventsByOpeningHour"

const Help = styled.div`
  margin: 16px 0;
`
const ContainerReview = styled.div`
  margin-bottom: 16px;
`

type Props = {
  missingBookingEventsByOpeningHour: [
    {
      openingHour: {},
      events: [],
    },
  ],
  onClose: Function,
}

const ModalOpeningHoursIncompatibility = ({ missingBookingEventsByOpeningHour, onClose }: Props) => (
  <ModalConfirmation title="Opening hours need changes" hideCancel onClose={onClose} isOpen>
    <Help>You can't deactivate / reduce time range for days that already have booked appointments within.</Help>
    <p>Please review booking events:</p>
    <ContainerReview>
      <Ul>
        {missingBookingEventsByOpeningHour.map(eventsByOpeningHour => (
          <li>
            <MissingBookingEventsByOpeningHour eventsByOpeningHour={eventsByOpeningHour} />
          </li>
        ))}
      </Ul>
    </ContainerReview>
  </ModalConfirmation>
)

export default memo(ModalOpeningHoursIncompatibility)

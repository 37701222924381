import { gql, useMutation } from "@apollo/client"
import React, { useCallback, useMemo, memo, useState } from "react"
import { Button } from "modaresa-commons"

import { SEND_SALES_SESSION_BOOKING_INVITATIONS } from "../../../../../graphql/mutations/salesSession"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"

import ModalConfirmSendingInvitations from "./ModalConfirmSendingInvitations"

type Props = {
  salesSessionId: string,
  selectedBuyersToInvite: {},
  disabled: boolean,
  color: string,
  onSend: Function,
  buyers: [],
}

const ButtonSendInvites = ({ salesSessionId, selectedBuyersToInvite, disabled, color, onSend, buyers }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sendSalesSessionBookingInvitations] = useMutation(SEND_SALES_SESSION_BOOKING_INVITATIONS, {
    update: (cache, { data: { sendSalesSessionBookingInvitations: newInvitedBuyers } }) => {
      cache.modify({
        id: cache.identify({ id: salesSessionId }),
        fields: {
          invitedBuyers: (invitedBuyersRef, { readField }) => {
            const newInvitedBuyersRef = [...invitedBuyersRef]
            newInvitedBuyers.forEach(newInvitedBuyer => {
              const newInvitedBuyerRef = cache.writeFragment({
                data: newInvitedBuyer,
                fragment: gql`
                  fragment NewInvitedBuyer on InvitedBuyer {
                    id
                    status
                    inviteStatus
                    buyerId
                  }
                `,
              })
              if (!invitedBuyersRef.some(ref => readField("id", ref) === newInvitedBuyer.id)) {
                newInvitedBuyersRef.push(newInvitedBuyerRef)
              }
            })
            return newInvitedBuyersRef
          },
        },
      })
    },
  })
  const handleToggleModal = useCallback(state => () => setShowModal(state), [])
  const buyersIds = useMemo(() => Object.keys(selectedBuyersToInvite), [selectedBuyersToInvite])
  const handleClickSendInvites = useCallback(async () => {
    setShowModal(false)
    setIsLoading(true)
    try {
      await sendSalesSessionBookingInvitations({
        variables: {
          salesSessionId,
          buyersIds,
        },
      })
      onSend()
      onToastSuccess("Invitations sent!")
    } catch (e) {
      console.error(e)
      onToastError("An error occured while sending emails")
    }
    setIsLoading(false)
  }, [sendSalesSessionBookingInvitations, salesSessionId, buyersIds, onSend])
  const nbInvites = buyersIds.length
  const isDisabled = disabled || nbInvites === 0
  return (
    <>
      <Button
        bgcolor={color}
        borderColor={color}
        disabled={isDisabled}
        onClick={handleToggleModal(true)}
        loading={isLoading}
      >
        Send Invites ({nbInvites})
      </Button>
      {showModal && (
        <ModalConfirmSendingInvitations
          title="Confirm Sending Invites"
          buttonLabelOk="Send Invites"
          onClickOk={handleClickSendInvites}
          onClose={handleToggleModal(false)}
          buyers={buyers}
          selectedBuyers={selectedBuyersToInvite}
        />
      )}
    </>
  )
}

export default memo(ButtonSendInvites)

import { isNil } from "lodash"

export const normalizeData = (dataArray, attribute = "_id") => {
  if (isNil(dataArray)) {
    dataArray = []
  }

  const allIds = []
  const byId = {}
  dataArray.forEach(item => {
    byId[item[attribute]] = item
    allIds.push(item[attribute])
  })
  return { byId, allIds, data: dataArray }
}

export const normalizeSchedules = schedules => {
  if (isNil(schedules)) {
    schedules = []
  }
  const allIds = []
  const byId = {}
  const idsBySession = {}
  schedules.forEach(item => {
    byId[item._id] = item
    allIds.push(item._id)
    idsBySession[item.sessionId] = item._id
  })
  return { byId, allIds }
}

import { useHistory } from "react-router-dom"
import { useMutation } from "@apollo/client"
import React, { memo, useCallback, useEffect, useState } from "react"

import { CHECK_IF_STAFF_EMAIL_VERIFIED, UPDATE_STAFF_GENERAL_INFOS } from "../../../../graphql/mutations/staff"
import getAreasAndTerritoriesValuesFromOptions from "../../../../helpers/areasAndTerritories/getAreasAndTerritoriesValuesFromOptions"
import { getGraphQLErrorAsString } from "../../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../../helpers/toast"
import ButtonDeleteStaff from "../../ButtonDeleteStaff/ButtonDeleteStaff"
import FormStaffGeneralInfos from "../../FormStaffGeneralInfos"

import ButtonGiveAccess from "./ButtonGiveAccess"
import { ContainerForm, ContainerGeneral } from "./General.styles"

type Props = {
  loggedUser: {},
  staff: {
    id: string,
  },
  canEditAccessRights: boolean,
  canEditAreasAndTerritories: boolean,
  readOnly: boolean,
}

const General = ({ staff, loggedUser, readOnly, canEditAccessRights, canEditAreasAndTerritories }: Props) => {
  const history = useHistory()
  const [updateStaffProfile] = useMutation(UPDATE_STAFF_GENERAL_INFOS)
  const [checkIfStaffEmailVerified] = useMutation(CHECK_IF_STAFF_EMAIL_VERIFIED)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const isEmailDisabled = staff.emailVerified || staff.hasLogin
  const handleSubmitStaffGeneralInfos = useCallback(
    async values => {
      setLoadingSubmit(true)
      const { modaresaRole } = values
      const areasAndTerritories = await getAreasAndTerritoriesValuesFromOptions(values.areasAndTerritories)
      try {
        const variables = {
          id: staff.id,
          staffGeneralInfos: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: isEmailDisabled ? undefined : values.email,
            companyPosition: values.companyPosition,
            languagesIsoCodes: values.languages.map(lang => lang.value),
            officeId: values.office.value,
            phoneNumber: values.phoneNumber,
          },
        }
        if (canEditAccessRights) {
          variables.staffGeneralInfos.hasEditorRights = values.hasEditorRights
          variables.staffGeneralInfos.modaresaRole = modaresaRole.value === "owner" ? undefined : modaresaRole.value
        }
        if (canEditAreasAndTerritories) {
          variables.staffGeneralInfos.areasAndTerritories = areasAndTerritories
        }
        await updateStaffProfile({ variables })
        onToastSuccess("Staff general infos has been updated successfully")
      } catch (e) {
        console.error(e)
        onToastError(`An error occured while updating staff general infos. ${getGraphQLErrorAsString(e)}`)
      }
      setLoadingSubmit(false)
    },
    [staff.id, updateStaffProfile, canEditAccessRights, canEditAreasAndTerritories, isEmailDisabled],
  )
  const handleDeleteStaff = useCallback(() => {
    onToastSuccess(`Staff ${staff.firstName} ${staff.lastName} has been deleted successfully`)
    history.push("/staff/")
  }, [history, staff])

  useEffect(() => {
    if (!staff.emailVerified && staff.pendingVerification) {
      checkIfStaffEmailVerified({ variables: { id: staff.id } }).catch(() => console.warn("Couldn't verify email"))
    }
  }, [checkIfStaffEmailVerified, staff.pendingVerification, staff.id, staff.emailVerified])
  return (
    <ContainerGeneral>
      <ContainerForm>
        <FormStaffGeneralInfos
          staff={staff}
          brandId={staff.brand.id}
          isEmailDisabled={isEmailDisabled}
          showEmailVerification
          readOnly={readOnly}
          canEditAccessRights={canEditAccessRights}
          canEditAreasAndTerritories={canEditAreasAndTerritories}
          onSubmit={handleSubmitStaffGeneralInfos}
          loadingSubmit={loadingSubmit}
        />
        {staff && !staff.hasLogin && <ButtonGiveAccess staffId={staff.id}>Give Access</ButtonGiveAccess>}
      </ContainerForm>
      {!readOnly && staff.id !== loggedUser.staff.id && (
        <ButtonDeleteStaff disabled={staff.modaresaRole === "owner"} staff={staff} onDelete={handleDeleteStaff}>
          Delete staff
        </ButtonDeleteStaff>
      )}
    </ContainerGeneral>
  )
}

export default memo(General)

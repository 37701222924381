import React, { memo, useCallback } from "react"
import Select from "react-select"

import Booking from "./Booking"

type Props = {
  bookingEvents: [],
}

const customStyles = {
  control: base => ({
    ...base,
    height: 80,
    minHeight: 80,
  }),
  valueContainer: base => ({
    ...base,
    height: 80,
    minHeight: 80,
  }),
}

const BookingEventPicker = ({ bookingEvents, onChange }: Props) => {
  const handleGetOptionValue = useCallback(option => option, [])
  const handleGetOptionLabel = useCallback(booking => <Booking booking={booking} />, [])
  const handleFilterOption = useCallback((option, inputValue) => {
    const { host, retailer, room } = option.value
    let isIncludedIn = ""
    if (retailer) {
      isIncludedIn += `${retailer.name} `
      if (retailer.storeAddress) {
        isIncludedIn += `${retailer.storeAddress.city}, ${retailer.storeAddress.country} `
      }
    }
    if (room) {
      isIncludedIn += `${room} `
    }
    if (host) {
      isIncludedIn += `${host.fullName} `
    }
    return isIncludedIn.toLowerCase().includes(inputValue.toLowerCase())
  }, [])
  return (
    <Select
      isSearchable
      getOptionValue={handleGetOptionValue}
      getOptionLabel={handleGetOptionLabel}
      filterOption={handleFilterOption}
      options={bookingEvents}
      onChange={onChange}
      placeholder="Select booking event"
      styles={customStyles}
    />
  )
}

export default memo(BookingEventPicker)

import * as R from "ramda"
import { createSelector } from "reselect"

export const getStaff = R.path(["staff", "staff"])
export const getStaffRequestPending = R.path(["staff", "staffRequestPending"])
export const getShortAreaManagers = R.path(["staff", "areaManagers"])

export const getSelectedMember = R.path(["staff", "selectedMember"])
export const getStaffRequestStatus = R.path(["staff", "requestPending"])
export const getStaffRequestError = R.path(["staff", "requestError"])

export const getStaffAvailableClients = R.path(["staff", "staffAvailableClients"])
export const getRequestStaffClientsPending = R.path(["staff", "requestStaffClientsPending"])
export const getRequestStaffClientsError = R.path(["staff", "requestStaffClientsError"])

export const getVerifyEmailPending = R.path(["staff", "verificationRequestPending"])
export const getVerifyEmailError = R.path(["staff", "verificationRequestError"])

export const getRequestFetchAMPending = R.path(["staff", "requestFetchAMPending"])
export const getRequestFetchAMError = R.path(["staff", "requestFetchAMError"])

export const getAreaManagers = createSelector([getStaff], staff => {
  const areaManagers = []
  if (staff && staff.allIds) {
    staff.allIds.forEach(memberId => {
      const member = staff.byId[memberId]
      if (member && member.modaresaRole === "areaManager") areaManagers.push(member)
    })
  }
  return areaManagers
})

export const getAllStaff = createSelector([getStaff], staff => {
  const allStaff = staff.allIds && staff.allIds.filter(id => staff.byId[id].isDeleted !== true)
  return allStaff
})

import React, { useCallback, useMemo, useState, memo } from "react"
import { theme } from "modaresa-commons"

import generateAreasAndTerritoriesOptions from "../../../helpers/areasAndTerritories/generateAreasAndTerritoriesOptions"
import { generateId } from "../../../helpers/idGenerator"
import { removeArrayDuplicateByProperty } from "../../../helpers/utils"
import Areas from "../Areas"

type Props = {
  defaultAreas: [],
  defaultRemainingArea: {},
  onChangeAreas: [],
  onChangeRemainingArea: {},
  errors: {},
  brandId: string,
  canAreasBeModified: boolean,
  canStaffsBeModified: boolean,
  retailers: [],
}

const AreasManager = ({
  defaultAreas,
  defaultRemainingArea,
  onChangeAreas,
  onChangeRemainingArea,
  errors,
  brandId,
  canAreasBeModified,
  canStaffsBeModified,
  retailers,
}: Props) => {
  const [areas, setAreas] = useState(defaultAreas || [])
  const [remainingArea, setRemainingArea] = useState(defaultRemainingArea || {})
  const handleAreaChange = useCallback(
    (rowIndex, newArea) => {
      const newAreas = areas.map((oldArea, index) => (index === rowIndex ? newArea : oldArea))
      setAreas(newAreas)
      onChangeAreas(newAreas)
    },
    [areas, onChangeAreas],
  )
  const handleChangeRemainingArea = useCallback(
    newRemainingArea => {
      setRemainingArea(newRemainingArea)
      onChangeRemainingArea(newRemainingArea)
    },
    [onChangeRemainingArea],
  )

  const handleAddArea = useCallback(
    () =>
      setAreas(oldAreas => [
        ...oldAreas,
        {
          name: "",
          countries: [],
          color: theme.teal,
          id: generateId(),
          notifiedStaffs: [],
          notificationMeetingConfirmation: true,
          notificationMeetingReminder: true,
        },
      ]),
    [],
  )
  const handleDeleteArea = useCallback(
    index => {
      const newAreas = [...areas]
      newAreas.splice(index, 1)
      setAreas(newAreas)
      onChangeAreas(newAreas)
    },
    [onChangeAreas, areas],
  )

  const excludeCountries = useMemo(
    () =>
      areas.reduce(
        (exclusions, area) => (area.territories ? exclusions.concat(area.territories.countries) : exclusions),
        [],
      ),
    [areas],
  )

  const territoriesOptions = useMemo(() => {
    const generatorParams = {
      includeAllCountriesOption: true,
      withCountries: true,
      withContinents: true,
      retailers,
      excludeCountries,
    }
    return generateAreasAndTerritoriesOptions(generatorParams)
  }, [excludeCountries, retailers])

  const warningMessage = useMemo(() => {
    const unverifiedSignees = areas.reduce((signees, area) => {
      if (area.signee && !area.signee.emailVerified) {
        signees.push(area.signee)
      }
      return signees
    }, [])
    if (remainingArea && remainingArea.signee && !remainingArea.signee.emailVerified) {
      unverifiedSignees.push(remainingArea.signee)
    }
    return unverifiedSignees.length
      ? `Please verify signee(s) email of '${removeArrayDuplicateByProperty(unverifiedSignees, "id")
          .map(signee => `${signee.firstName} ${signee.lastName}`)
          .join("','")}' to allow Modaresa sending invitation under signee's name and email.`
      : null
  }, [areas, remainingArea])
  return (
    <Areas
      brandId={brandId}
      areas={areas}
      remainingArea={remainingArea}
      excludeCountries={excludeCountries}
      territoriesOptions={territoriesOptions}
      canAreasBeModified={canAreasBeModified}
      canStaffsBeModified={canStaffsBeModified}
      onChangeArea={handleAreaChange}
      onClickAddArea={handleAddArea}
      errors={errors}
      warningMessage={warningMessage}
      onDeleteArea={handleDeleteArea}
      onChangeRemainingArea={handleChangeRemainingArea}
    />
  )
}

export default memo(AreasManager)

import React, { memo, useCallback, useMemo } from "react"
import Select from "react-select"
import { useQuery } from "@apollo/client"

import { GET_BRAND_BUYERS } from "../../../graphql/queries/brand"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

import { RetailerName } from "./styles"

type Props = {
  brandId: string,
  roles: [],
  multiple: boolean,
  placeholder?: boolean,
  disabled: boolean,
  defaultValue: any,
  value: any,
  displayIsEmailVerified: boolean,
  restrictToStaffId: string,
  restrictToRetailerId: string,
  excludeBuyerIds: [string],
}

const BuyerPicker = ({
  brandId,
  multiple,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
  restrictToStaffId,
  restrictToRetailerId,
  excludeBuyerIds,
}: Props) => {
  const handleGetOptionValue = useCallback(option => `${option.fullName} ${option.retailer.name}`, [])
  const handleGetOptionLabel = useCallback(
    option => (
      <span>
        {option.fullName} <RetailerName>({option.retailer.name})</RetailerName>
      </span>
    ),
    [],
  )
  const { loading, error, data } = useQuery(GET_BRAND_BUYERS, {
    variables: { id: brandId, status: "active", restrictToStaffId, restrictToRetailerId, excludeBuyerIds },
  })
  const options = useMemo(() => {
    if (!data?.getBrandBuyers) return []
    return data.getBrandBuyers
  }, [data])
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error</ErrorLiveChatHelp>

  return (
    <Select
      isLoading={loading}
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      options={options}
      isMulti={multiple}
      placeholder={placeholder}
      onChange={onChange}
      getOptionValue={handleGetOptionValue}
      getOptionLabel={handleGetOptionLabel}
    />
  )
}

BuyerPicker.defaultProps = {
  placeholder: "Select buyer",
}
export default memo(BuyerPicker)

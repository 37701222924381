import React, { memo } from "react"
import ReactModal from "react-modal"
import styled from "styled-components"

export const BookingModalContainer = styled(ReactModal)`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%;
  position: absolute;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  outline: none;
  justify-content: center;
  align-items: center;
`
export const BookingModalDialog = styled.div`
  background-color: #fff;
  position: relative;
  padding: 25px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.veryLightGray};
  max-height: 90vh;
  overflow: auto;
`
export const CloseIcon = styled.div`
  position: absolute;
  height: 1em;
  width: 1em;
  line-height: 1em;
  text-align: center;
  color: ${props => props.theme.berry};
  right: 10px;
  top: 10px;
  cursor: pointer;
`

const customStyles = {
  overlay: {
    zIndex: 21,
  },
}

type Props = {
  children: any,
  className: string,
  isOpen: boolean,
  onRequestClose: Function,
}
const Modal = ({ children, className, isOpen, onRequestClose }: Props) => (
  <BookingModalContainer className={className} isOpen={isOpen} style={customStyles} onRequestClose={onRequestClose}>
    <BookingModalDialog>
      <CloseIcon onClick={onRequestClose}>&#10006;</CloseIcon>
      {children}
    </BookingModalDialog>
  </BookingModalContainer>
)
export default memo(Modal)

import styled from "styled-components"

export const HoverActions = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  color: white;
  transition: 0.2s all ease-in;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px 4px 0 4px;
  font-size: 20px;
`

export const Container = styled.div`
  border-radius: 4px;
  padding: 8px;
  ${p => `
    width: ${p.width ? `${p.width}px` : "100%"};
    height: ${p.height ? `${p.height - 2}px` : "100%"};
    top: ${p.top}px;
    left: ${p.left}px;
    background-color: ${p.color || p.theme.gray50};
    position: ${p.position || "absolute"};
    ${
      p.color === "white"
        ? `
      color: black;
      border: 1px solid black;
    `
        : `
      color: white;
    `
    }
    ${
      p.isClickable
        ? `
      cursor: pointer;
      &:hover {
        & ${HoverActions} {
          display: flex;
        }
      }
    `
        : `
      cursor: initial;
    `
    };

  `}
`

export const RetailerInfo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  pointer-events: none;
`

export const RoomInfo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  font-weight: 300;
`

export const WrapperInfos = styled.div`
  overflow: hidden;
  height: 100%;
`

export const ContainerAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
`

import { withApollo } from "@apollo/client/react/hoc"
import { Link, Redirect } from "react-router-dom"
import React, { Component } from "react"
import PropTypes from "prop-types"
import isEmpty from "lodash/isEmpty"
import { Button, Flex } from "modaresa-commons"

import PageTitle from "../../elements/PageTitle/PageTitle"
import SmllLogo from "../../../static/mR2.png"

import { ButtonsRow, Form, MrLogo, SmallContainer, Text } from "./styles"

import { emailRegex } from "helpers/regex"

class Login extends Component {
  state = {
    values: {
      email: "",
      password: "",
    },
    errors: {},
    touched: {},
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "email":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (emailRegex.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "password":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onSubmit = async () => {
    const { values, touched, errors } = this.state
    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      this.props.onLogin(values)
    } else {
      this.setState({ errors, touched })
    }
  }

  render() {
    const { errors, touched, values } = this.state
    const { requestPending, isAuthed, client } = this.props
    if (isAuthed) {
      client.resetStore()
      return <Redirect to="/calendars" />
    }

    return (
      <PageTitle title="Log-In">
        <Flex style={{ minHeight: "100vh" }}>
          <SmallContainer className="agreement">
            <MrLogo style={{ marginBottom: "20px" }}>
              <img alt="modaresa logo" src={SmllLogo} />
            </MrLogo>
            <Text margin="0px 0px 20px 0px" style={{ textAlign: "center" }}>
              {`Welcome onboard ModaResa, scheduling will never be the same again.`}
            </Text>
            <Form>
              <div>
                <input
                  autoComplete="username"
                  name="email"
                  placeholder="Email"
                  type="text"
                  value={values.email}
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
                {errors.email && touched.email && <span>{errors.email}</span>}
              </div>
              <div>
                <input
                  autoComplete="current-password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  value={values.password}
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
                {errors.password && touched.password && <span>{errors.password}</span>}
              </div>
            </Form>
            <div style={{ textAlign: "center" }}>
              <span>
                Forgot your password?{" "}
                <Link style={{ color: "inherit", textDecoration: "underline" }} to={"/user/email"}>
                  Reset here
                </Link>
                .
              </span>
            </div>
            <ButtonsRow style={{ marginTop: "25px" }}>
              <Button disabled={requestPending} onClick={this.onSubmit}>
                Login
              </Button>
            </ButtonsRow>
          </SmallContainer>
        </Flex>
      </PageTitle>
    )
  }
}

Login.propTypes = {
  requestPending: PropTypes.bool,
  onLogin: PropTypes.func,
}

export default withApollo(Login)

import React, { memo } from "react"
import { Button } from "modaresa-commons"

import RoomBasedAreasManager from "../../../../elements/RoomBasedAreasManager"

import { ContainerButtons } from "./SalesSessionAreasDefaultView.styles"

type Props = {
  brandId: boolean,
  isLoadingData: boolean,
  disableSaveChanges: boolean,
  canRoomAreaStaffsBeModified: boolean,
  rooms: [],
  onChangeRooms: Function,
  onSaveChanges: Function,
}

const SalesSessionAreasRoomBasedView = ({
  brandId,
  isLoadingData,
  rooms,
  canRoomAreaStaffsBeModified,
  disableSaveChanges,
  onChangeRooms,
  onSaveChanges,
}: Props) => (
  <div>
    {isLoadingData ? (
      <RoomBasedAreasManager loading />
    ) : (
      <RoomBasedAreasManager
        rooms={rooms}
        brandId={brandId}
        onChangeRooms={onChangeRooms}
        canStaffsBeModified={canRoomAreaStaffsBeModified}
      />
    )}
    <ContainerButtons>
      <Button disabled={disableSaveChanges} onClick={onSaveChanges}>
        Save changes
      </Button>
    </ContainerButtons>
  </div>
)

export default memo(SalesSessionAreasRoomBasedView)

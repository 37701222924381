import { all, call, cancelled, put, select } from "redux-saga/effects"

import * as Api from "../../helpers/api"
import { parseJwt } from "../../helpers/auth"
import { FETCH_DB_DATA, FETCH_USER, FETCH_DB_USER } from "../actions/user"
import { LOGIN, LOGOUT, RESET_PASSWORD, SEND_RESET_EMAIL, VERIFY_TOKEN, SETUP_AUTH_TOKEN } from "../actions/auth"
import { onToastError, onToastSuccess } from "../../helpers/toast"
import { FETCH_BRAND } from "../actions/brand"
import { FETCH_CLIENTS } from "../actions/clients"
import { FETCH_RETAILERS } from "../actions/retailers"
import { FETCH_SALE_SESSIONS } from "../actions/salesSessions"
import { FETCH_STAFF } from "../actions/staff"
import { JOIN_SOCKET_ROOM } from "../sockets/messageTypes"
import { normalizeData } from "../../helpers/normalize"
import { takeLatest } from "../../helpers/saga"

function* authorize({ payload }) {
  try {
    const response = yield call(Api.login, payload)
    yield put({ type: LOGIN.SUCCESS, payload: response })
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (error.response && error.response.data.message && error.response.data.errorType === "auth") {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({ type: LOGIN.FAILURE, payload: errosMsg })
  } finally {
    yield cancelled()
  }
}

function* loginFlow() {
  yield takeLatest(LOGIN.REQUEST, authorize)
}

function* fetchDBData() {
  const state = yield select()
  const {
    accessToken,
    user: { _id },
  } = state.auth

  try {
    const user = yield call(Api.fetchUser, { token: accessToken, id: _id })
    yield put({ type: FETCH_DB_USER.SUCCESS, payload: { user } })
    if (user && user.roles[0] === "staff") {
      const [staffMember] = yield call(Api.fetchStaff, {
        token: accessToken,
        query: { userLoginId: _id },
      })
      if (staffMember?.isDeleted) {
        onToastError("Staff member has been removed")
        return yield put({ type: LOGOUT })
      }
      yield put({ type: FETCH_USER.SUCCESS, payload: { staffMember } })
      const brandId = staffMember.brand || staffMember.brandId
      const dbData = yield all([
        call(Api.fetchBrand, { token: accessToken }),
        call(Api.fetchSaleSessions, { token: accessToken, brandId }),
        //call(Api.fetchBrandStaff, { token: accessToken, brandId }),
        //call(Api.fetchClients, { token: accessToken, brandId }),
        //call(Api.fetchRetailers, { token: accessToken, brandId }),
      ])

      const sessions = normalizeData(dbData[1])
      //const staff = normalizeData(dbData[2])
      //const clients = normalizeData(dbData[3])
      //const retailers = normalizeData(dbData[2])
      yield put({
        type: "SEND_WEBSOCKET_MESSAGE",
        payload: {
          eventType: JOIN_SOCKET_ROOM,
          body: { room: brandId, type: "brand" },
        },
      })
      yield put({
        type: FETCH_BRAND.SUCCESS,
        payload: {
          brand: dbData[0].find(brand => brand._id === brandId),
          allBrands: dbData[0],
        },
      })
      yield put({ type: FETCH_SALE_SESSIONS.SUCCESS, payload: sessions })
      //yield put({ type: FETCH_STAFF.SUCCESS, payload: staff })
      //yield put({ type: FETCH_CLIENTS.SUCCESS, payload: clients })
      //yield put({ type: FETCH_RETAILERS.SUCCESS, payload: retailers })
      /* global $crisp */
      $crisp.push(["set", "user:nickname", `${staffMember.firstName} ${staffMember.lastName}`])
      $crisp.push(["set", "user:email", [`${staffMember.email}`]])
      $crisp.push(["set", "session:segments", [[`${staffMember.modaresaRole}`]]])
    }

    yield put({ type: FETCH_DB_DATA.SUCCESS })
  } catch (error) {
    onToastError()

    yield put({ type: FETCH_DB_DATA.ERROR })
  }
}

function* fetchDBDataFlow() {
  yield takeLatest([LOGIN.SUCCESS, FETCH_DB_DATA.REQUEST], fetchDBData)
}

function* resetPassword({ payload }) {
  try {
    const { data, history } = payload
    yield call(Api.resetPassword, data)
    yield put({ type: RESET_PASSWORD.SUCCESS })
    onToastSuccess("Password has been changed")
    yield call(history.push, "/login")
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (error.response && error.response.data.message && error.response.data.errorType === "custom") {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({ type: RESET_PASSWORD.FAILURE, payload: errosMsg })
  }
}

function* resetPasswordFlow() {
  yield takeLatest(RESET_PASSWORD.REQUEST, resetPassword)
}

function* verifyToken({ payload }) {
  try {
    const { data } = payload
    yield call(Api.verifyToken, data)
    yield put({ type: VERIFY_TOKEN.SUCCESS })
  } catch (error) {
    const { history } = payload
    let customError = "Oops, something went wrong"
    if (error.response && error.response.data.message && error.response.data.errorType === "custom") {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({ type: VERIFY_TOKEN.FAILURE, payload: errosMsg })
    yield call(history.push, "/login")
  }
}

function* verifyTokenFlow() {
  yield takeLatest(VERIFY_TOKEN.REQUEST, verifyToken)
}

function* sendResetPasswordEmail({ payload }) {
  try {
    const { data, history } = payload

    const response = yield call(Api.sendResetEmail, data)

    yield put({ type: SEND_RESET_EMAIL.SUCCESS })
    onToastSuccess(`Reset password email has been sent to ${response.userEmail}`)
    yield call(history.push, "/login")
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (error.response && error.response.data.message && error.response.data.errorType === "custom") {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({ type: SEND_RESET_EMAIL.FAILURE, payload: errosMsg })
  }
}

function* sendResetPasswordEmailFlow() {
  yield takeLatest(SEND_RESET_EMAIL.REQUEST, sendResetPasswordEmail)
}

function* saveAccessTokenFlow() {
  yield takeLatest(LOGIN.SUCCESS, ({ payload: { accessToken } }) => {
    localStorage.setItem("modaresa-access-token", accessToken)
  })
}

function* setupAuthToken({ payload: token }) {
  const { userId } = parseJwt(token)
  const loginPayload = {
    accessToken: token,
    user: {
      _id: userId,
    },
  }
  yield put({ type: LOGOUT })
  yield put({ type: LOGIN.SUCCESS, payload: loginPayload })
  yield put({ type: SETUP_AUTH_TOKEN.SUCCESS })
}

function* setupAuthTokenFlow() {
  yield takeLatest(SETUP_AUTH_TOKEN.REQUEST, setupAuthToken)
}

export default [
  loginFlow,
  fetchDBDataFlow,
  resetPasswordFlow,
  verifyTokenFlow,
  sendResetPasswordEmailFlow,
  saveAccessTokenFlow,
  setupAuthTokenFlow,
]

import styled from "styled-components"

export const FilterContainer = styled.div`
  display: flex;
  width: 350px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin: 10px 20px;

  & .filterType {
    width: 150px;
  }

  & .filterType__control,
  & .filter__control {
    border: none !important;
    box-shadow: none !important;
  }

  & .filterType__control {
    border-right: 1px solid #d8d8d8 !important;
    border-radius: 4px 0 0 4px !important;
  }

  & .filterType__indicator-separator,
  & .filter__dropdown-indicator,
  & .filter__indicator-separator {
    display: none;
  }

  & .filter {
    width: 200px;
  }
`

import { useQuery } from "@apollo/client"
import Select from "react-select"
import React, { memo, useCallback, useMemo } from "react"

import { ALL_BRANDS } from "../../../graphql/queries/brand"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

type Props = {
  placeholder?: boolean,
  disabled: boolean,
  defaultValue: any,
  value: any,
  onChange: Function,
  multiple: boolean,
}

const BrandPicker = ({ placeholder, disabled, onChange, defaultValue, value, multiple }: Props) => {
  const handleGetOptionValue = useCallback(option => option.id, [])
  const handleGetOptionLabel = useCallback(option => <span>{option.name}</span>, [])
  const { loading, error, data } = useQuery(ALL_BRANDS)
  const options = useMemo(() => {
    if (!data?.allBrands) return []
    return data.allBrands
  }, [data])
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error</ErrorLiveChatHelp>

  return (
    <Select
      isLoading={loading}
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      options={options}
      isMulti={multiple}
      placeholder={placeholder}
      onChange={onChange}
      getOptionValue={handleGetOptionValue}
      getOptionLabel={handleGetOptionLabel}
    />
  )
}

BrandPicker.defaultProps = {
  placeholder: "Select brand",
}
export default memo(BrandPicker)

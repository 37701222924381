import Dropdown, { DropdownContent, DropdownTrigger } from "react-simple-dropdown"
import { PulseLoader, SyncLoader } from "react-spinners"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import Modal from "react-modal"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"
import moment from "moment-timezone"
import { Button, Flex } from "modaresa-commons"

import closeIcon from "../../../../../static/cancel-music.svg"
import fileIcon from "../../../../../static/Paperclip_binder.svg"
import { marketsByValue } from "../../../../../config/session"
import EditIcon from "../../../../../static/Edit.svg"
import { Filter } from "../../../../ui"
import { onToastError } from "../../../../../helpers/toast"
import { setOptionList } from "../../../../../helpers/filter"
import uploadIcon from "../../../../../static/upload2.svg"

import {
  Bold,
  ButtonsRow,
  ChooseFiles,
  CloseModal,
  Container,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  ModalBody,
  Row,
  Table,
  TableBody,
  TableHeader,
  Text,
  UploadFile,
  UploadFileInput,
} from "./styles"

export const seasonsKey = {
  preSummerSpring: "Pre-Summer Spring",
  summerSpring: "Summer Spring",
  preAutumnWinter: "Pre-Autumn Winter",
  autumnWinter: "Autumn Winter",
}

const customStyles = {
  content: {
    width: "500px",
    height: "auto",
    maxHeight: "500px",
    minHeight: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "40px",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 13,
  },
}

class SessionList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sessions: this.props.sessions,
      copyClients: [],
      options: [],
      isTags: false,
      tags: [],
      deleted: false,
      files: [],
      sessionId: "",
      modalIsOpen: false,
      isAdditingAtthachment: false,
      isDeletePending: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      addAttachementRequestStatus,
      sessions,
      addAttachementRequestError,
      deleteAttachementRequestStatus,
      deleteAttachementRequestError,
    } = nextProps
    const { isAdditingAtthachment, isDeletePending, sessionId, files: oldFiles } = prevState

    if (nextProps.sessions.length !== prevState.sessions.length && prevState.deleted) {
      return { sessions, deleted: false }
    }
    if (isAdditingAtthachment && !addAttachementRequestStatus && !addAttachementRequestError) {
      return {
        sessions,
        modalIsOpen: false,
        files: [],
        isAdditingAtthachment: false,
      }
    }
    if (isDeletePending !== null && !deleteAttachementRequestStatus && deleteAttachementRequestError) {
      return { isDeletePending: null }
    }

    if (isDeletePending !== null) {
      const session = sessions.find(sssn => sssn.id === sessionId)
      if (session && session.attachments.length < oldFiles.length) {
        return {
          isDeletePending: null,
          files: (session && session.attachments) || [],
          sessions,
        }
      }
    }
    return null
  }

  tagChoose = (selected, e) => {
    if (!selected || selected.length === 0) {
      this.setState({
        sessions: this.props.sessions,
        options: [],
        isTags: false,
        tags: [],
      })
    } else if (e.action === "select-option") {
      const { sessions } = this.state
      const column = e.option.key
      const value = column === "country" ? e.option.iso : e.option.value

      const filterResult = this.filteredClients(value, sessions, column)

      this.setState({
        sessions: filterResult.filteredClients,
        copyClients: filterResult.filteredClients,
        options: filterResult.optionsList,
        isTags: true,
        tags: selected,
      })
    } else if (e.action === "remove-value" || e.action === "pop-value") {
      let sessions = []
      let options = []
      for (let i = 0; i < selected.length; i++) {
        if (i === 0) {
          sessions = this.props.sessions
        }

        const column = selected[i].key
        const value = column === "country" ? selected[i].iso : selected[i].value
        const filterResult = this.filteredClients(value, sessions, column)
        sessions = filterResult.filteredClients
        options = filterResult.optionsList
      }
      this.setState({
        sessions,
        options,
        isTags: selected.length !== 0,
        tags: selected,
      })
    }
  }

  filterTable = e => {
    let sessions
    const { isTags, copyClients } = this.state
    const value = e.toLowerCase()
    if (isTags) {
      sessions = this.state.sessions
    } else {
      sessions = this.props.sessions
    }

    if (value.length === 0) {
      if (isTags) {
        this.setState({ sessions: copyClients, options: [] })
      } else {
        this.setState({ sessions, options: [] })
      }
    } else if (value.length > 0) {
      if (sessions.length === 0) sessions = copyClients
      const filterResult = this.filteredClients(value, sessions)
      this.setState({
        sessions: filterResult.filteredClients,
        options: filterResult.optionsList,
      })
    }
  }

  deboncedFilter = debounce(this.filterTable, 200, {
    leading: false,
    trailing: true,
  })

  filteredClients = (value, sessions, column) => {
    const { offices } = this.props
    const List = []
    const filteredClients = sessions.filter(item => {
      if (column) {
        if (column === "market") {
          if (value.toLowerCase() === "men" || value.toLowerCase() === "women") {
            if (
              item[column].toLowerCase() === value.toLowerCase() ||
              item[column].toLowerCase() === "womenMen".toLowerCase()
            ) {
              return item
            }
          } else if (item[column].toLowerCase() === value.toLowerCase()) {
            return item
          }
        } else if (item[column].toString().toLowerCase() === value.toString().toLowerCase()) {
          return item
        }
      } else {
        for (const key in item) {
          if (key.match("/|status|_id|timezone|/i")) {
            continue
          } else if (key === "season") {
            if (
              seasonsKey[item[key]]
                .toString()
                .toLowerCase()
                .indexOf(value) === 0
            ) {
              List.push({
                value: item[key],
                key,
                label: `${seasonsKey[item[key]]} (${key})`,
              })
              return item
            }
          } else if (key === "market") {
            if (
              "men".indexOf(value.toLowerCase()) === 0 &&
              (marketsByValue[item[key]].toString().toLowerCase() === "men" || item[key] === "womeMen")
            ) {
              List.push({
                value: item[key],
                key,
                label: `${marketsByValue[item[key]]} (${key})`,
              })
              return item
            } else if (
              "women".indexOf(value.toLowerCase()) === 0 &&
              (marketsByValue[item[key]].toString().toLowerCase() === "women" || item[key] === "womenMen")
            ) {
              List.push({
                value: item[key],
                key,
                label: `${marketsByValue[item[key]]} (${key})`,
              })
              return item
            } else if (
              marketsByValue[item[key]]
                .toString()
                .toLowerCase()
                .indexOf(value) === 0
            ) {
              List.push({
                value: item[key],
                key,
                label: `${marketsByValue[item[key]]} (${key})`,
              })
              return item
            }
          } else if (key === "openingDay" || key === "closingDay") {
            const date = moment
              .utc(item[key], "X")
              .tz(item.timezone)
              .format("DD/MM/YYYY")
            if (date.toLowerCase().indexOf(value) === 0) {
              List.push({
                value: item[key],
                key,
                label: `${date} (${key})`,
              })
              return item
            }
          } else if (key === "office") {
            const office = offices.filter(office => office._id === item.office)[0]
            if (office.name.toLowerCase().indexOf(value) === 0) {
              List.push({
                value: item[key],
                key,
                label: `${office.name} (${key})`,
              })
              return item
            }
          } else if (
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .indexOf(value) === 0
          ) {
            List.push({
              value: item[key],
              key,
              label: `${item[key]} (${key})`,
            })
            return item
          }
        }
      }
      return null
    })
    return { filteredClients, optionsList: setOptionList(List) }
  }

  onDeleteSession = id => {
    const { onDeleteSession } = this.props
    onDeleteSession(id)
    this.setState({ deleted: true })
  }

  addFile = e => {
    const file = e.target.files[0]
    if (file.size > 25000000) {
      onToastError("Maximum size of file is 25MB")
    }
    if (file && file.size < 250000000) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.setState(prevState => ({
          files: [...prevState.files, { fileName: file.name, fileData: reader.result }],
        }))
      }
    }
    e.target.value = ""
  }

  toggleModal = id => {
    if (id) {
      const { sessions } = this.props
      const session = sessions.find(session => session.id === id)
      this.setState({
        modalIsOpen: true,
        sessionId: id,
        files: session.attachments,
      })
    } else {
      this.setState({ modalIsOpen: false, sessionId: "", files: [] })
    }
  }

  deleteFile = index => {
    const { onDeleteAttachment } = this.props
    this.setState(prevState => {
      const { files, sessionId } = prevState
      const newFiles = [...files]
      const deletedFile = newFiles[index]

      if (deletedFile.url) {
        onDeleteAttachment({ sessionId, index })
        return { isDeletePending: index }
      }
      return { files: newFiles }
    })
  }

  onUploadFiles = () => {
    const { sessionId, files } = this.state
    this.setState({ isAdditingAtthachment: true })
    this.props.onAddFile({ id: sessionId, file: files })
    this.setState({ isAdditingAtthachment: true })
  }

  renderModal() {
    const { modalIsOpen, files, isDeletePending } = this.state
    const { addAttachementRequestStatus, deleteAttachementRequestStatus } = this.props

    return (
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <ModalBody>
          <SyncLoader
            className="spinner"
            color={"#a60c46"}
            loading={addAttachementRequestStatus}
            size={15}
            sizeUnit={"px"}
          />
          <div>
            <Bold>Upload files to attach to invitations sent out to your clients</Bold>
          </div>
          <Text>
            <p>You can upload up to 2 attachments, in PDF of JPEG.</p>
          </Text>
          <Flex justify="flex-start" style={{ marginBottom: "20px" }}>
            <div>
              <UploadFile>
                <ChooseFiles bgcolor={files && files.length > 1 ? "#d8d8d8" : "#FFCAB1"}>Choose Files</ChooseFiles>
                <UploadFileInput
                  accept=".pdf, .jpeg"
                  disabled={files && files.length > 1}
                  type="file"
                  onChange={this.addFile}
                />
              </UploadFile>
            </div>
            <Flex align="flex-start" column style={{ color: "#b1b1b1", width: "100%" }}>
              {!files.length && <div>No files chosen</div>}
              {files.map((file, index) => (
                <Flex key={index} style={{ width: "100%" }}>
                  <span>{`${index + 1}. ${file.fileName}`}</span>
                  {isDeletePending === index && deleteAttachementRequestStatus ? (
                    <div>
                      <PulseLoader color={"#a60c46"} size={5} sizeUnit={"px"} />
                    </div>
                  ) : (
                    <Icon
                      disabled={isDeletePending !== null}
                      height="13px"
                      src={closeIcon}
                      onClick={() => this.deleteFile(index)}
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
          <ButtonsRow>
            <Button onClick={this.onUploadFiles}>Upload Files to Session</Button>
          </ButtonsRow>
          <CloseModal onClick={() => this.toggleModal(null)}>
            <Icon src={closeIcon} />
          </CloseModal>
        </ModalBody>
      </Modal>
    )
  }

  renderRows = () => {
    const { offices, type, onExportReports } = this.props
    const { sessions } = this.state
    return sessions.map(session => {
      const office = offices.filter(office => office._id === session.office)[0]
      return (
        <Row key={session.id}>
          <div style={{ width: "17%" }}>{session.name}</div>
          <div style={{ width: "13%" }}>{seasonsKey[session.season]}</div>
          <div style={{ width: "13%" }}>{marketsByValue[session.market]}</div>
          <div style={{ width: "13%" }}>{session.address}</div>
          <div style={{ width: "13%" }}>
            {moment
              .utc(session.openingDay, "X")
              .tz(session.timezone)
              .format("DD/MM/YYYY")}
          </div>
          <div style={{ width: "13%" }}>
            {moment
              .utc(session.closingDay, "X")
              .tz(session.timezone)
              .format("DD/MM/YYYY")}
          </div>
          <div style={{ width: "13%" }}>{office.name}</div>
          {type === "active" && (
            <Flex
              justify="center"
              style={{ width: "13%", cursor: "pointer" }}
              onClick={() => this.toggleModal(session.id)}
            >
              {session.attachments &&
                session.attachments.map((file, index) => (
                  <Icon height={"25px"} key={index} src={fileIcon} style={{ marginRight: "10px" }} />
                ))}
              {((session.attachments && session.attachments.length < 2) || !session.attachments) && (
                <Icon height={"25px"} src={uploadIcon} style={{ marginRight: "10px" }} />
              )}
            </Flex>
          )}
          <div style={{ width: "5%" }}>
            <Dropdown style={{ width: "100%" }}>
              <DropdownTrigger
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Icon src={EditIcon} />
              </DropdownTrigger>
              <DropdownContent>
                <DropdownMenu>
                  {type === "active" && (
                    <React.Fragment>
                      <Link to={`/sessions/${session.id}`}>
                        <DropdownMenuItem>Edit</DropdownMenuItem>
                      </Link>
                      <DropdownMenuItem onClick={() => onExportReports(session.id)}>Download Report</DropdownMenuItem>
                    </React.Fragment>
                  )}
                  {type === "archived" && (
                    <React.Fragment>
                      <Link to={`/calendars?sessionId=${session.id}`}>
                        <DropdownMenuItem>View Past Calendar</DropdownMenuItem>
                      </Link>
                      <DropdownMenuItem onClick={() => onExportReports(session.id)}>Download Report</DropdownMenuItem>
                    </React.Fragment>
                  )}
                  {type === "draft" && (
                    <React.Fragment>
                      <Link to={`/sessions/${session.id}`}>
                        <DropdownMenuItem>Resume</DropdownMenuItem>
                      </Link>
                      {session.canBeEditedByLoggedUser && (
                        <DropdownMenuItem onClick={() => this.onDeleteSession(session.id)}>Delete</DropdownMenuItem>
                      )}
                    </React.Fragment>
                  )}
                </DropdownMenu>
              </DropdownContent>
            </Dropdown>
          </div>
        </Row>
      )
    })
  }

  render() {
    const { options, tags } = this.state
    const { type } = this.props
    return (
      <Container>
        <div style={{ position: "relative", zIndex: 2, margin: "10px 20px" }}>
          <Filter
            placeholder="Search sales session by name, season, market, location, date, office"
            className={"filterSelect"}
            isMulti
            isSearchable={!(tags.length === 3)}
            options={options}
            value={tags}
            onChange={this.tagChoose}
            onFilter={this.deboncedFilter}
          />
        </div>
        <Table style={{ height: "100%" }}>
          <TableHeader>
            <div style={{ width: "17%" }}>Sale Session Name</div>
            <div style={{ width: "13%" }}>Season</div>
            <div style={{ width: "13%" }}>Market</div>
            <div style={{ width: "13%" }}>Location</div>
            <div style={{ width: "13%" }}>Start Date</div>
            <div style={{ width: "13%" }}>End Date</div>
            <div style={{ width: "13%" }}>Office</div>
            {type === "active" && <div style={{ width: "13%" }}>Linesheets/Lookbook</div>}
            <div style={{ width: "5%" }} />
          </TableHeader>
          <TableBody>{this.renderRows()}</TableBody>
        </Table>
        {this.renderModal()}
      </Container>
    )
  }
}

SessionList.propTypes = {
  addAttachementRequestError: PropTypes.any,
  addAttachementRequestStatus: PropTypes.bool,
  deleteAttachementRequestError: PropTypes.any,
  deleteAttachementRequestStatus: PropTypes.bool,
  match: PropTypes.object,
  offices: PropTypes.array,
  sessions: PropTypes.array,
  type: PropTypes.string,
  userIsAgent: PropTypes.bool,
  onAddFile: PropTypes.func,
  onDeleteAttachment: PropTypes.func,
  onDeleteSession: PropTypes.func,
  onExportReports: PropTypes.func,
}

export { SessionList }

import { useMutation } from "@apollo/client"
import React, { useCallback, useState } from "react"

import { CREATE_STAFF } from "../../../../../graphql/mutations/staff"
import { getGraphQLErrorAsString } from "../../../../../helpers/error"
import { generateId } from "../../../../../helpers/idGenerator"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"
import PageTitle from "../../../../elements/PageTitle/PageTitle"

import FormStaff from "./FormStaff"
import { Container, ContentContainer, Title } from "./styles"

const AddStaff = () => {
  const [formKey, setFormKey] = useState(generateId())
  const [createStaff] = useMutation(CREATE_STAFF)
  const handleSubmit = useCallback(
    async values => {
      const modaresaRole = values.modaresaRole.value
      const variables = {
        doCreateUserAccount: modaresaRole !== "freelancer",
        brandId: values.brand.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        phoneNumber: values.phoneNumber,
        officeId: values.office.value,
        companyPosition: values.companyPosition,
        modaresaRole,
        languagesIsoCodes: ["en"],
      }

      try {
        await createStaff({ variables })
        onToastSuccess("Staff added successfully to brand")
        setFormKey(generateId())
      } catch (e) {
        console.error(e)
        onToastError("An error occured while adding staff to brand", getGraphQLErrorAsString(e))
      }
    },
    [createStaff],
  )

  return (
    <PageTitle title="Add staff to brand">
      <ContentContainer>
        <Title>Add staff to brand</Title>
        <Container>
          <FormStaff key={formKey} onSubmit={handleSubmit} />
        </Container>
      </ContentContainer>
    </PageTitle>
  )
}

export default AddStaff

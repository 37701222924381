import React, { memo, useMemo } from "react"
import { Table, HelpTooltip } from "modaresa-commons"

import { blockingListTypes } from "../../../config/settings"
import { stopPropagation } from "../../../helpers/utils"

type Props = {
  data: [],
  renderActions: Function,
  loading: boolean,
  onClickRow: Function,
}

const TableRooms = ({ data, loading, renderActions, onClickRow }: Props) => {
  const columns = useMemo(() => {
    const cols = [
      { Header: "Room Name", accessor: "name" },
      {
        Header: (
          <span>
            Retailers Access
            <HelpTooltip text="Which retailers can view and book rooms" />
          </span>
        ),
        accessor: "hasBlockingList",
        Cell: ({
          row: {
            original: { hasBlockingList, typeBlockingList },
          },
        }) => <span>{hasBlockingList ? blockingListTypes[typeBlockingList]?.label : "All retailers"}</span>,
      },
    ]
    if (renderActions) {
      cols.push({
        id: "actions",
        collapse: true,
        Cell: ({ row: { original } }) => <span onClick={stopPropagation}>{renderActions(original)}</span>,
      })
    }
    return cols
  }, [renderActions])
  return <Table columns={columns} data={data} loading={loading} onClickRow={onClickRow} />
}

export default memo(TableRooms)

/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable extra-rules/no-commented-out-code */
import { useQuery } from "@apollo/client"
import React, { memo, useCallback, useMemo, useState } from "react"
import debounce from "lodash/debounce"
import { AlertDanger } from "modaresa-commons"
import MUIDataTable from "mui-datatables"
import { Chip, CircularProgress } from "@material-ui/core"
import styled from "styled-components"

import { ALL_USERS } from "../../../../../graphql/queries/user"

import ActionConnectAs from "components/screens/Admin/blocks/UsersList/blocks/ActionConnectAs"
import { isStaffEditor } from "helpers/users"

const UsersList = () => {
  const [nbByPages, setNbByPages] = useState()
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(0)

  // const handleChangePageSize = useCallback(nbEntries => {
  //   setNbByPages(nbEntries)
  //   setPage(0)
  // }, [])
  // const handleChangePage = useCallback(newPage => setPage(newPage), [])
  // const handleThrottleSearch = useMemo(
  //   () =>
  //     debounce(e => {
  //       setSearchQuery(e.target.value)
  //       setPage(0)
  //     }, 300),
  //   [],
  // )
  // const handleChangeSearch = useCallback(
  //   e => {
  //     e.persist()
  //     handleThrottleSearch(e)
  //   },
  //   [handleThrottleSearch],
  // )
  const variables = useMemo(() => {
    const vars = { skip: page * nbByPages, limit: nbByPages }
    if (searchQuery) {
      vars.filter = {
        email: searchQuery,
      }
    }
    return vars
  }, [page, nbByPages, searchQuery])
  const { loading, error, data } = useQuery(ALL_USERS, {
    variables,
  })
  const tableData = useMemo(() => {
    if (!data) return []
    return data.allUsers.filter(u => u.roles[0] !== "superAdmin" && u.staff) // only display staff members
  }, [data])

  const columns = [
    {
      name: "id",
      options: {
        display: "excluded",
        sort: false,
        filter: false,
      },
    },
    {
      name: "staff.brand.name",
      label: "Brand",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) =>
          (value && <Chip label={value?.charAt(0).toUpperCase() + value?.slice(1)} />) ||
          (tableData[tableMeta.rowIndex].roles[0] === "superAdmin" && "Super Admin"),
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "staff.modaresaRole",
      label: "Role",
      options: {
        filter: true,
        sort: true,

        filterOptions: {
          renderValue: value => (value && value?.charAt(0).toUpperCase() + value?.slice(1)) || "Super Admin",
        },

        customFilterListRender: value =>
          (value && <div>{value?.charAt(0).toUpperCase() + value?.slice(1)}</div>) || "Super Admin",

        customBodyRender: (value, tableMeta, updateValue) =>
          (value === "owner" && <Chip label={value?.charAt(0).toUpperCase() + value?.slice(1)} />) ||
          (value === "admin" && <Chip label={value?.charAt(0).toUpperCase() + value?.slice(1)} />) ||
          (value === "manager" && <Chip label={value?.charAt(0).toUpperCase() + value?.slice(1)} />) ||
          (value === "agent" && <Chip label={value?.charAt(0).toUpperCase() + value?.slice(1)} />) ||
          (value === "freelancer" && <Chip label={value?.charAt(0).toUpperCase() + value?.slice(1)} />) ||
          (tableData[tableMeta.rowIndex].roles[0] === "superAdmin" && <Chip label={"Super Admin"} />),
      },
    },
    {
      name: "staff",
      label: "Editor Rights",
      options: {
        sort: false,
        filter: true,
        customBodyRender: (value, tableMeta) =>
          isStaffEditor(value) ? (
            <ion-icon name="md-checkmark" style={{ color: "green" }}></ion-icon>
          ) : (
            <ion-icon name="md-close" style={{ color: "red" }}></ion-icon>
          ),
        customFilterListOptions: { render: v => `Has Editor Rights` },
        filterType: "checkbox",
        filterOptions: {
          names: [true],
          logic(active, filterVal, row) {
            return !isStaffEditor(active)
          },
          renderValue: val => {
            if (val === true) {
              return "Yes"
            }
          },
        },
      },
    },
    {
      name: "id",
      label: "Connect As",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <ActionConnectAs user={tableData.find(user => user.id === value)} />
        ),
      },
    },
  ]

  const options = {
    enableNestedDataAccess: ".",
    filterType: "checkbox",
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    selectableRows: false,
    selectableRowsOnClick: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    disableToolbarSelect: true,
    sortThirdClickReset: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 50, 100],
    sortOrder: {
      name: "staff.brand.name",
      direction: "asc",
    },
  }

  if (error) return <AlertDanger>Error while loading users</AlertDanger>
  if (loading)
    return (
      <div style={{ display: "flex", flex: 1, height: "100%", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress color="secondary" />
      </div>
    )
  return (
    <div style={{ height: "100%", paddingBottom: 100 }}>
      <MUIDataTable data={tableData} columns={columns} options={options} />
    </div>
  )
}

export default memo(UsersList)

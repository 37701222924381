import React, { useCallback, useEffect, useMemo, useRef, useState, memo } from "react"
import { theme } from "modaresa-commons"

import { getTooltipCoordinatesFromEvent } from "../../../../../helpers/utils"
import ButtonSwitchMeetingHost from "../../../../elements/ButtonSwitchMeetingHost"
import { Preview } from "../BookingPreview"

import ButtonGoToMeetingReport from "./ButtonGoToMeetingReport"
import { Container, RetailerInfo, RoomInfo, WrapperInfos, HoverActions, ContainerAction } from "./styles"

type Props = {
  booking: {},
  user: {},
  retailer: {
    storeAddress: {
      city: string,
      country: string,
    },
    name: string,
  },
  room: string,
  color: string,
  onClick: Function,
  width: number,
  height: number,
  top: number,
  left: number,
  position: string,
  canEdit: boolean,
}
const Booking = ({
  user,
  booking,
  retailer,
  room,
  color,
  onClick,
  width,
  height,
  top,
  left,
  position,
  canEdit,
}: Props) => {
  const [showBookingPreview, setShowBooking] = useState(false)
  const [previewCoords, setPreviewCoords] = useState({
    top: "100%",
    left: "0px",
  })
  const refTimeout = useRef(null)

  const handleMouseEnterContainer = useCallback(event => {
    const previewCoords = getTooltipCoordinatesFromEvent(event)
    refTimeout.current = setTimeout(() => {
      setShowBooking(true)
      setPreviewCoords(previewCoords)
    }, 250)
  }, [])
  const handleMouseLeaveContainer = useCallback(() => {
    setShowBooking(false)
    if (refTimeout.current) {
      clearTimeout(refTimeout.current)
    }
  }, [])
  const title = useMemo(() => {
    if (!retailer) return null
    const { storeAddress } = retailer
    return `${retailer.name} - ${storeAddress.city}, ${storeAddress.country}`
  }, [retailer])

  const containerColor = useMemo(() => {
    if (booking.status === "reserved") return theme.gray50
    if (booking.isDisabled) return theme.disabledBooking
    if (color) return color
    return theme.primary
  }, [color, booking])
  const isClickable = useMemo(() => containerColor !== theme.disabledBooking, [containerColor])

  const containerFunctions = useMemo(
    () =>
      isClickable
        ? {
            onClick,
            onMouseEnter: handleMouseEnterContainer,
            onMouseLeave: handleMouseLeaveContainer,
          }
        : {},
    [isClickable, onClick, handleMouseEnterContainer, handleMouseLeaveContainer],
  )

  const actions = useMemo(() => {
    const list = [
      {
        key: "meeting-report",
        component: <ButtonGoToMeetingReport booking={booking} />,
      },
    ]
    if (canEdit) {
      list.push({
        key: "switch",
        tooltip: "Switch Meeting Host",
        component: <ButtonSwitchMeetingHost bookingEventId={booking._id} />,
      })
    }
    return list
  }, [canEdit, booking])

  useEffect(
    () => () => {
      if (refTimeout.current) {
        clearTimeout(refTimeout.current)
      }
    },
    [],
  )

  const canSee = user.modaresaRole !== "agent" || user._id === booking.host

  return (
    <Container
      width={width}
      height={height}
      top={top}
      left={left}
      position={position}
      onClick={containerFunctions.onClick}
      onMouseEnter={containerFunctions.onMouseEnter}
      onMouseLeave={containerFunctions.onMouseLeave}
      color={containerColor}
      isClickable={isClickable}
    >
      <WrapperInfos>
        {title && canSee && <RetailerInfo>{title}</RetailerInfo>}
        {room && <RoomInfo>{room}</RoomInfo>}
        {isClickable && (
          <HoverActions>
            {actions.map(action => (
              <ContainerAction key={action.key}>{action.component}</ContainerAction>
            ))}
          </HoverActions>
        )}
      </WrapperInfos>
      {booking.status !== "reserved" && showBookingPreview && <Preview booking={booking} coords={previewCoords} />}
    </Container>
  )
}

export default memo(Booking)

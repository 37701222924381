import { useFormikContext } from "formik"
import React, { memo, useCallback, useMemo } from "react"
import Select from "react-select"
import { Input } from "modaresa-commons"

import markets from "../../../../../config/markets"
import seasons from "../../../../../config/seasons"
import FormFieldLayout from "../../../../elements/FormFieldLayout/FormFieldLayout"

import { ContainerFields, Field } from "./FieldSessionInfos.styles"

type Props = {
  disabled: boolean,
}

const FieldSessionInfos = ({ disabled }: Props) => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = useFormikContext()
  const error = useMemo(() => {
    const errorGenerated = ["name", "season", "market"].reduce(
      (acc, field) => (touched[field] && errors[field] ? `${acc}${errors[field]}` : acc),
      "",
    )
    return errorGenerated === "" ? null : errorGenerated
  }, [errors, touched])

  const handleChangeSelect = useCallback(field => value => setFieldValue(field, value), [setFieldValue])
  return (
    <FormFieldLayout label="Sales Session Name" labelWidth="208px" name="name" error={error}>
      <ContainerFields>
        <Field>
          <Input
            id="name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            disabled={disabled}
            placeholder="Name"
          />
        </Field>
        <Field>
          <Select
            isDisabled={disabled}
            name="season"
            options={seasons}
            placeholder="Season"
            value={values.season}
            onBlur={handleBlur}
            onChange={handleChangeSelect("season")}
          />
        </Field>
        <Field>
          <Select
            isDisabled={disabled}
            name="market"
            options={markets}
            placeholder="Market"
            value={values.market}
            onBlur={handleBlur}
            onChange={handleChangeSelect("market")}
          />
        </Field>
      </ContainerFields>
    </FormFieldLayout>
  )
}

export default memo(FieldSessionInfos)

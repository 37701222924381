import React, { memo } from "react"
import { NavLink } from "react-router-dom"

const MenuFreelancer = () => (
  <>
    <NavLink activeClassName="route-active" className="route tab" to={"/calendars"}>
      CALENDARS
    </NavLink>
    <NavLink activeClassName="route-active" className="route tab" to="/settings/profile">
      SETTINGS
    </NavLink>
  </>
)

export default memo(MenuFreelancer)

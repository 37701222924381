import styled from "styled-components"

export const ContainerRoomsView = styled.div`
  display: flex;
  flex-direction: column;
`
export const HeaderRoomsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
`
export const TotalRooms = styled.div`
  flex: 1;
  font-size: 15px;
  min-height: 37px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

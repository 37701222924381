import styled from "styled-components"

export const ContainerForm = styled.div`
  width: 100%;
  max-width: 704px;
  padding-right: 24px;
`

export const ContainerGeneral = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
`

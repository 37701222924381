import generateTerritoriesOptionsValuesFromObject from "./generateTerritoriesOptionsValuesFromObject"

type Props = {
  geographicResponsibility: {
    areas: [],
    roomAreas: [],
  },
  toggleRoomBasedAreas: boolean,
  areas: [{ _id: string, name: string }],
  rooms: [
    {
      _id: string,
      name: string,
      areas: [
        {
          _id: string,
          name: string,
        },
      ],
    },
  ],
}

const getAreasAndTerritoriesOptionsValuesFromStaff = ({
  geographicResponsibility = {},
  toggleRoomBasedAreas,
}: Props = {}) => {
  if (!geographicResponsibility) return []
  let options = []
  if (toggleRoomBasedAreas) {
    if (geographicResponsibility.roomAreas) {
      geographicResponsibility.roomAreas.forEach(roomArea => {
        options.push({
          objValue: {
            roomId: roomArea.room.id,
            areaId: roomArea.area.id,
          },
          value: roomArea.area.id,
          label: `${roomArea.room.name} > ${roomArea.area.name}`,
          title: `${roomArea.room.name} > ${roomArea.area.name}`,
          type: "roomArea",
        })
      })
    }
  } else {
    if (geographicResponsibility.areas) {
      geographicResponsibility.areas.forEach(area => {
        if (area) {
          options.push({
            label: area.name,
            title: area.name,
            value: area.id,
            type: "area",
          })
        }
      })
    }
  }
  if (geographicResponsibility.territories) {
    const territoriesOpts = generateTerritoriesOptionsValuesFromObject(geographicResponsibility.territories)
    options = options.concat(territoriesOpts)
  }
  return options
}

export default getAreasAndTerritoriesOptionsValuesFromStaff

import styled from "styled-components"

export const SelectContainer = styled.div`
  position: relative;
  color: ${props => props.theme.textBlack};
  width: ${props => props.width || "100%"};
`

export const ValidationError = styled.div`
  position: absolute;
  color: ${props => props.theme.primary}
  font-size: 12px;
  bottom: 0;
  transform: translateY(100%);
  left: 0px;
`

const continents = {
  af: "Africa",
  na: "North America",
  oc: "Oceania",
  an: "Antarctica",
  as: "Asia",
  eu: "Europe",
  sa: "South America",
}

export default continents

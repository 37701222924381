import styled from "styled-components"

export const Container = styled.div`
  & .react-tagsinput-tag {
    color: #fff;
    background-color: #516bf0;
    border-color: #516bf0;
  }

  & .react-tagsinput-remove {
    color: #fff;
  }
`

export const Row = styled.div`
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || "space-between"};
  align-items: center;
`

export const Section = styled.div`
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  background-color: ${props => props.theme.gray75};
`
export const DropdownMenu = styled.div`
  background-color: #fff;
  min-width: 200px;
  text-decoration: none;
`
export const DropdownMenuItem = styled.div`
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.gray75};
    text-decoration: none;
  }

  & a {
    color: ${props => props.theme.black};
  }
`

export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
`

export const Col = styled.div`
  width: 100%;
  padding-left: 15px;
  max-width: 250px;
`

export const ErrorText = styled.div`
  color: ${props => props.theme.primary};
`
export const InputContainer = styled.div`
  padding-right: 15px;
  & > input {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 40px;
    padding-left: 15px;
    padding-right: 10px;
  }
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 40px;
  margin-bottom: 128px;
`

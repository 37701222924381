import {
  ARRIVED_TO_APPOINTMENT,
  CHECK_NEW_BOOKING_TIME,
  CONFIRM_BOOKING,
  CONFIRM_JOKER_REQUEST,
  CREATE_BOOKING,
  DECLINE_JOKER_REQUEST,
  DELAY_APPOINTMENT,
  DELETE_APPOINTMENT,
  DELETE_BOOKING,
  FETCH_AVAILABLE_CLIENTS,
  FETCH_AVAILABLE_HOSTS,
  FETCH_AVAILABLE_STAFF,
  FETCH_CLIENT_CANCELLATIONS,
  FETCH_JOKER_AVAILABLE_STAFF,
  FETCH_JOKER_REQUESTS,
  FETCH_SCHEDULE,
  FETCH_SCHEDULES,
  FILTER_APPOINTMENT,
  FILTER_BOOKINGS,
  REPLACE_EVENT,
  RESERVE_BOOKING,
  RESERVE_JOKER_BOOKING,
  RESET_FILTER_APPOINTMENT,
  SELECT_BOOKING,
  UNSELECT_BOOKING,
  UNSELECT_SCHEDULE,
  UPDATED_CALENDAR,
  UPDATED_CALENDAR_SCHEDULE,
  UPDATED_EVENTS,
  UPDATE_APPOINTMENT_HOST,
  UPDATE_BOOKING,
  UPDATE_BOOKING_TIME,
  UPDATE_CALENDAR_BOOKING,
  SWITCH_MEETING_HOSTS,
} from "../actions/schedules"
import { createReducer } from "../..//helpers/redux"

const initialState = {
  schedules: {},
  selectedBooking: null,
  selectedSchedule: null,
  requestPending: false,
  requestError: null,
  bookingAvailableClients: [],
  bookingAvailableHosts: [],
  bookingOtherHosts: [],
  bookingAvailableStaff: [],
  isFetchingAvailableClients: false,
  isFetchingAvailableMeetingStaff: false,
  requestCheckTime: false,
  requestCheckTimeError: null,
  bookingFilterType: null,
  bookingFilterValue: null,
  bookingUpdateRequestPending: true,
  bookingUpdateRequestError: null,
  appointmentRequestPending: false,
  appointmentRequestError: null,
  appointmentFilter: {},
  appointmentAvailableHosts: [],
  isFetchingAvailableHosts: false,
  requestAvailableHostsError: null,
  requestSchedulePending: false,
  requestScheduleError: null,
  jokerRequests: null,
  requestFetchJokerRequestsPending: false,
  requestFetchJokerRequestsError: null,
  requestJokerRequestsPending: false,
  requestJokerRequestsError: null,
  jokerAvailableHosts: [],
  jokerOtherHosts: [],
  jokerAvailableStaff: [],
  cancellations: null,
  requestFetchCancellationsPending: false,
  requestFetchCancellationsError: null,
}

const getBookingIndexes = (schedule, booking) => {
  const scheduleType = booking.type === "joker" ? "jokerSchedule" : "schedule"
  const dayIndex = schedule[scheduleType].findIndex(daySchedule => daySchedule.date === booking.dayDate)
  const roomIndex = schedule[scheduleType][dayIndex].daySchedule.findIndex(room => room.roomName === booking.room)
  const bookingIndex = schedule[scheduleType][dayIndex].daySchedule[roomIndex].tables[booking.tableIndex].findIndex(
    bkng => bkng._id === booking._id,
  )
  return { dayIndex, roomIndex, bookingIndex, scheduleType }
}

const addEvent = (schedule, event) => {
  const scheduleType = event.type === "joker" ? "jokerSchedule" : "schedule"
  const dayIndex = schedule[scheduleType].findIndex(daySchedule => daySchedule.date === event.dayDate)
  const roomIndex = schedule[scheduleType][dayIndex].daySchedule.findIndex(room => room.roomName === event.room)
  schedule[scheduleType][dayIndex].daySchedule[roomIndex].tables[event.tableIndex].push(event)
}
const addEvents = (oldSchedule, events) => {
  const newSchedule = { ...oldSchedule }
  events.forEach(event => {
    addEvent(newSchedule, event)
  })
  return newSchedule
}

const replaceEvent = (schedule, newEvent, updateBookingEventFunction) => {
  const { dayIndex, roomIndex, bookingIndex, scheduleType } = getBookingIndexes(schedule, newEvent)
  const oldEvent = schedule[scheduleType][dayIndex].daySchedule[roomIndex].tables[newEvent.tableIndex][bookingIndex]
  schedule[scheduleType][dayIndex].daySchedule[roomIndex].tables[newEvent.tableIndex][
    bookingIndex
  ] = updateBookingEventFunction ? updateBookingEventFunction(oldEvent, newEvent) : newEvent
}
const replaceEvents = (oldSchedule, events) => {
  const newSchedule = { ...oldSchedule }
  events.forEach(event => {
    replaceEvent(newSchedule, event)
  })
  return newSchedule
}

const removeEvents = (schedule, events) => {
  const newSchedule = { ...schedule }
  events.forEach(event => {
    let bookingIndex, oldTableIndex
    const scheduleType = event.type === "joker" ? "jokerSchedule" : "schedule"
    const dayIndex = schedule[scheduleType].findIndex(daySchedule => daySchedule.date === event.dayDate)
    const roomIndex = schedule[scheduleType][dayIndex].daySchedule.findIndex(room => room.roomName === event.room)
    schedule[scheduleType][dayIndex].daySchedule[roomIndex].tables.find((table, index) => {
      oldTableIndex = index
      bookingIndex = table.findIndex(bkng => bkng._id === event._id)
      return bookingIndex > -1
    })

    newSchedule[scheduleType][dayIndex].daySchedule[roomIndex].tables[oldTableIndex].splice(bookingIndex, 1)
  })
  return newSchedule
}

const handlers = {
  [FETCH_SCHEDULES.SUCCESS]: (state, { payload }) => ({
    ...state,
    schedules: payload,
  }),
  [FETCH_SCHEDULE.REQUEST]: state => ({
    ...state,
    requestSchedulePending: true,
    requestScheduleError: null,
  }),
  [FETCH_SCHEDULE.SUCCESS]: (state, { payload }) => ({
    ...state,
    requestSchedulePending: false,
    selectedSchedule: payload.schedule,
  }),
  [FETCH_SCHEDULE.FAILURE]: (state, { payload }) => ({
    ...state,
    requestSchedulePending: false,
    requestScheduleError: payload,
  }),
  [RESERVE_BOOKING.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [RESERVE_BOOKING.SUCCESS]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = addEvents(state.selectedSchedule, [booking])
    }
    return {
      ...state,
      selectedSchedule: updatedSchedule,
      selectedBooking: booking,
    }
  },
  [CREATE_BOOKING]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = addEvents(state.selectedSchedule, [booking])
    }
    return {
      ...state,
      selectedSchedule: updatedSchedule,
    }
  },
  [RESERVE_BOOKING.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [RESERVE_JOKER_BOOKING.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [RESERVE_JOKER_BOOKING.SUCCESS]: (state, { payload }) => {
    const { schedule, booking } = payload
    return {
      ...state,
      selectedSchedule: schedule,
      selectedBooking: booking,
    }
  },
  [RESERVE_JOKER_BOOKING.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [DELETE_BOOKING.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [DELETE_BOOKING.SUCCESS]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = removeEvents(state.selectedSchedule, [payload.booking])
    }
    return {
      ...state,
      requestPending: false,
      selectedSchedule: updatedSchedule,
      selectedBooking: null,
    }
  },
  [DELETE_BOOKING.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [CONFIRM_BOOKING.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATED_EVENTS]: (state, { payload }) => {
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && payload.updatedEvents[0].scheduleId === updatedSchedule._id) {
      updatedSchedule = replaceEvents(state.selectedSchedule, payload.updatedEvents)
    }
    return {
      ...state,
      selectedSchedule: updatedSchedule,
    }
  },
  [UPDATED_CALENDAR]: (state, { payload }) => {
    const { newEvents = [], removedEvents = [] } = payload

    let updatedSchedule = { ...state.selectedSchedule }
    if (state.selectedSchedule) {
      if (newEvents.length > 0 && newEvents[0].scheduleId === state.selectedSchedule._id) {
        updatedSchedule = addEvents(updatedSchedule, newEvents)
      }
      if (removedEvents.length > 0 && removedEvents[0].scheduleId === state.selectedSchedule._id) {
        updatedSchedule = removeEvents(updatedSchedule, removedEvents)
      }
    }
    return {
      ...state,
      selectedSchedule: updatedSchedule,
    }
  },
  [SWITCH_MEETING_HOSTS]: (state, { payload }) => {
    const { bookingEventA, bookingEventB } = payload
    const updatedSchedule = { ...state.selectedSchedule }
    if (updatedSchedule) {
      if (bookingEventA.scheduleId === updatedSchedule._id) {
        bookingEventA._id = bookingEventA.id
        replaceEvent(updatedSchedule, bookingEventA, (oldEvent, newEvent) => ({
          ...oldEvent,
          host: newEvent.host.id,
        }))
      }
      if (bookingEventB.scheduleId === updatedSchedule._id) {
        bookingEventB._id = bookingEventB.id
        replaceEvent(updatedSchedule, bookingEventB, (oldEvent, newEvent) => ({
          ...oldEvent,
          host: newEvent.host.id,
        }))
      }
    }
    return {
      ...state,
      selectedSchedule: updatedSchedule,
    }
  },
  [UPDATE_BOOKING.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_BOOKING.SUCCESS]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = replaceEvents(state.selectedSchedule, [booking])
    }
    return {
      ...state,
      requestPending: false,
      selectedSchedule: updatedSchedule,
      selectedBooking: booking,
    }
  },
  [UPDATE_BOOKING.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [SELECT_BOOKING]: (state, { payload }) => ({
    ...state,
    selectedBooking: payload,
  }),
  [UNSELECT_BOOKING]: state => ({
    ...state,
    selectedBooking: null,
    bookingAvailableClients: [],
    bookingAvailableHosts: [],
    bookingOtherHosts: [],
    bookingAvailableStaff: [],
  }),
  [FETCH_AVAILABLE_CLIENTS.REQUEST]: state => ({
    ...state,
    isFetchingAvailableClients: true,
    requestError: null,
  }),
  [FETCH_AVAILABLE_CLIENTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingAvailableClients: false,
    bookingAvailableClients: payload,
  }),
  [FETCH_AVAILABLE_CLIENTS.FAILURE]: (state, { payload }) => ({
    ...state,
    isFetchingAvailableClients: false,
    requestError: payload,
  }),
  [FETCH_AVAILABLE_STAFF.REQUEST]: state => ({
    ...state,
    isFetchingAvailableMeetingStaff: true,
    requestError: null,
  }),
  [FETCH_AVAILABLE_STAFF.SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingAvailableMeetingStaff: false,
    bookingAvailableHosts: payload.availableHosts,
    bookingOtherHosts: payload.otherHosts,
    bookingAvailableStaff: payload.availableStaff,
  }),
  [FETCH_AVAILABLE_STAFF.FAILURE]: (state, { payload }) => ({
    ...state,
    isFetchingAvailableMeetingStaff: false,
    requestError: payload,
  }),
  [UPDATE_BOOKING_TIME.SUCCESS]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = replaceEvents(state.selectedSchedule, [booking])
    }
    return {
      ...state,
      selectedSchedule: updatedSchedule,
      selectedBooking: booking,
    }
  },
  [CHECK_NEW_BOOKING_TIME.REQUEST]: state => ({
    ...state,
    requestCheckTime: true,
    requestCheckTimeError: null,
  }),
  [CHECK_NEW_BOOKING_TIME.SUCCESS]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (state.selectedSchedule && booking.scheduleId === state.selectedSchedule._id) {
      updatedSchedule = { ...state.selectedSchedule }
      updatedSchedule = removeEvents(updatedSchedule, [booking])
      updatedSchedule = addEvents(updatedSchedule, [booking])
    }
    return {
      ...state,
      requestCheckTime: false,
      selectedSchedule: updatedSchedule,
      requestCheckTimeError: null,
    }
  },
  [CHECK_NEW_BOOKING_TIME.FAILURE]: (state, { payload }) => ({
    ...state,
    requestCheckTime: false,
    requestCheckTimeError: payload,
  }),
  [REPLACE_EVENT]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (state.selectedSchedule && booking.scheduleId === state.selectedSchedule._id) {
      updatedSchedule = { ...state.selectedSchedule }
      updatedSchedule = removeEvents(updatedSchedule, [booking])
      updatedSchedule = addEvents(updatedSchedule, [booking])
    }
    return {
      ...state,
      selectedSchedule: updatedSchedule,
    }
  },
  [FILTER_BOOKINGS]: (state, { payload }) => {
    const { filterType, filterValue } = payload
    return {
      ...state,
      bookingFilterType: filterType,
      bookingFilterValue: filterValue,
    }
  },
  [FILTER_APPOINTMENT]: (state, { payload }) => {
    const { filterType, filterValue } = payload
    return {
      ...state,
      appointmentFilter: {
        ...state.appointmentFilter,
        [filterType]: filterValue,
      },
    }
  },
  [RESET_FILTER_APPOINTMENT]: state => ({
    ...state,
    appointmentFilter: {},
  }),
  [DELETE_APPOINTMENT.REQUEST]: state => ({
    ...state,
    appointmentRequestPending: true,
    appointmentRequestError: null,
  }),
  [DELETE_APPOINTMENT.SUCCESS]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = removeEvents(state.selectedSchedule, [payload.booking])
    }
    return {
      ...state,
      appointmentRequestPending: false,
      selectedSchedule: updatedSchedule,
    }
  },
  [DELETE_APPOINTMENT.FAILURE]: (state, { payload }) => ({
    ...state,
    appointmentRequestPending: false,
    appointmentRequestError: payload,
  }),
  [ARRIVED_TO_APPOINTMENT.REQUEST]: state => ({
    ...state,
    appointmentRequestPending: true,
    appointmentRequestError: null,
  }),
  [ARRIVED_TO_APPOINTMENT.SUCCESS]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = replaceEvents(state.selectedSchedule, [payload.booking])
    }
    return {
      ...state,
      appointmentRequestPending: false,
      selectedSchedule: updatedSchedule,
    }
  },
  [ARRIVED_TO_APPOINTMENT.FAILURE]: (state, { payload }) => ({
    ...state,
    appointmentRequestPending: false,
    appointmentRequestError: payload,
  }),
  [DELAY_APPOINTMENT.REQUEST]: state => ({
    ...state,
    appointmentRequestPending: true,
    appointmentRequestError: null,
  }),
  [DELAY_APPOINTMENT.SUCCESS]: (state, { payload }) => {
    const booking = payload.updatedEvents[0]
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = replaceEvents(state.selectedSchedule, payload.updatedEvents)
    }
    return {
      ...state,
      appointmentRequestPending: false,
      selectedSchedule: updatedSchedule,
    }
  },
  [DELAY_APPOINTMENT.FAILURE]: (state, { payload }) => ({
    ...state,
    appointmentRequestPending: false,
    appointmentRequestError: payload,
  }),
  [FETCH_AVAILABLE_HOSTS.REQUEST]: state => ({
    ...state,
    isFetchingAvailableHosts: true,
    requestAvailableHostsError: null,
    appointmentAvailableHosts: [],
  }),
  [FETCH_AVAILABLE_HOSTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingAvailableHosts: false,
    appointmentAvailableHosts: payload.availableHosts,
  }),
  [FETCH_AVAILABLE_HOSTS.FAILURE]: (state, { payload }) => ({
    ...state,
    isFetchingAvailableHosts: false,
    requestAvailableHostsError: payload,
  }),
  [UPDATE_APPOINTMENT_HOST.REQUEST]: state => ({
    ...state,
    appointmentRequestPending: true,
    appointmentRequestError: null,
  }),
  [UPDATE_APPOINTMENT_HOST.SUCCESS]: (state, { payload }) => {
    const { updatedEvents } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && updatedEvents[0].scheduleId === updatedSchedule._id) {
      updatedSchedule = replaceEvents(state.selectedSchedule, payload.updatedEvents)
    }
    return {
      ...state,
      appointmentRequestPending: false,
      selectedSchedule: updatedSchedule,
    }
  },
  [UPDATE_APPOINTMENT_HOST.FAILURE]: (state, { payload }) => ({
    ...state,
    appointmentRequestPending: false,
    appointmentRequestError: payload,
  }),
  [UNSELECT_SCHEDULE]: state => ({
    ...state,
    selectedSchedule: null,
  }),
  [UPDATED_CALENDAR_SCHEDULE]: (state, { payload }) => {
    const { schedule, removedBooking } = payload
    const { selectedSchedule, selectedBooking } = state
    return {
      ...state,
      selectedBooking: selectedBooking && selectedBooking._id === removedBooking ? null : selectedBooking,
      selectedSchedule: selectedSchedule && selectedSchedule._id === schedule._id ? schedule : selectedSchedule,
    }
  },
  [UPDATE_CALENDAR_BOOKING]: (state, { payload }) => {
    const { booking } = payload
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = replaceEvents(state.selectedSchedule, [payload.booking])
    }
    return {
      ...state,
      selectedSchedule: updatedSchedule,
    }
  },
  [FETCH_JOKER_REQUESTS.REQUEST]: state => ({
    ...state,
    requestFetchJokerRequestsPending: true,
    requestFetchJokerRequestsError: null,
  }),
  [FETCH_JOKER_REQUESTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    requestFetchJokerRequestsPending: false,
    jokerRequests: payload,
  }),
  [FETCH_JOKER_REQUESTS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestFetchJokerRequestsPending: false,
    requestFetchJokerRequestsError: payload,
  }),
  [CONFIRM_JOKER_REQUEST.REQUEST]: state => ({
    ...state,
    requestJokerRequestsPending: true,
    requestJokerRequestsError: null,
  }),
  [CONFIRM_JOKER_REQUEST.SUCCESS]: (state, { payload }) => {
    const { joker, requestId } = payload
    const newRequests = [...state.jokerRequests]
    const index = newRequests.findIndex(request => request._id === requestId)
    newRequests[index] = joker
    return {
      ...state,
      requestJokerRequestsPending: false,
      jokerRequests: newRequests,
    }
  },
  [CONFIRM_JOKER_REQUEST.FAILURE]: (state, { payload }) => ({
    ...state,
    requestJokerRequestsPending: false,
    requestJokerRequestsError: payload,
  }),
  [DECLINE_JOKER_REQUEST.REQUEST]: state => ({
    ...state,
    requestJokerRequestsPending: true,
    requestJokerRequestsError: null,
  }),
  [DECLINE_JOKER_REQUEST.SUCCESS]: (state, { payload }) => {
    const newRequests = [...state.jokerRequests]
    const index = newRequests.findIndex(request => request._id === payload._id)
    newRequests[index] = payload
    return {
      ...state,
      requestJokerRequestsPending: false,
      jokerRequests: newRequests,
    }
  },
  [DECLINE_JOKER_REQUEST.FAILURE]: (state, { payload }) => ({
    ...state,
    requestJokerRequestsPending: false,
    requestJokerRequestsError: payload,
  }),
  [FETCH_JOKER_AVAILABLE_STAFF.REQUEST]: state => ({
    ...state,
    isFetchingAvailableMeetingStaff: true,
    requestError: null,
  }),
  [FETCH_JOKER_AVAILABLE_STAFF.SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingAvailableMeetingStaff: false,
    jokerAvailableHosts: payload.availableHosts,
    jokerOtherHosts: payload.otherHosts,
    jokerAvailableStaff: payload.availableStaff,
  }),
  [FETCH_JOKER_AVAILABLE_STAFF.FAILURE]: (state, { payload }) => ({
    ...state,
    isFetchingAvailableMeetingStaff: false,
    requestError: payload,
  }),
  [FETCH_CLIENT_CANCELLATIONS.REQUEST]: state => ({
    ...state,
    requestFetchCancellationsPending: true,
    requestFetchCancellationsError: null,
  }),
  [FETCH_CLIENT_CANCELLATIONS.SUCCESS]: (state, { payload }) => ({
    ...state,
    requestFetchCancellationsPending: false,
    cancellations: payload,
  }),
  [FETCH_CLIENT_CANCELLATIONS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestFetchCancellationsPending: false,
    requestFetchCancellationsError: payload,
  }),
}

export default createReducer(initialState, handlers)

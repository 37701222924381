import React, { memo } from "react"
import { Link } from "react-router-dom"
import { SkeletonBlock } from "modaresa-commons"
import styled from "styled-components"

import { ContainerForm, ContainerRoomView, RoomName } from "./RoomView.styles"

const Spacing = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const RoomViewSkeleton = () => (
  <ContainerRoomView>
    <Link to="/settings/company/rooms/">{`< Back to all rooms`}</Link>
    <RoomName>
      <SkeletonBlock width="100px" height="28px" />
    </RoomName>
    <ContainerForm>
      <Spacing>
        <SkeletonBlock height="21px" width="51px" margin="0 141px 0 0" display="inline-block" />
        <SkeletonBlock height="38px" width="calc(100% - 192px)" display="inline-block" />
      </Spacing>
      <SkeletonBlock width="130px" height="38px" margin="0 0 16px 192px" />
      <SkeletonBlock width="calc(100% -192px)" height="38px" margin="0 0 16px 192px" />
    </ContainerForm>
  </ContainerRoomView>
)

export default memo(RoomViewSkeleton)

import { Link } from "react-router-dom"
import React, { Component } from "react"
import { values as getObjectValues, isEmpty, isNil } from "lodash"
import Modal from "react-modal"
import PropTypes from "prop-types"
import Raven from "raven-js"
import ReactPhoneInput from "react-phone-input-2"
import { SyncLoader } from "react-spinners"
import { Button, Flex, InputWithValidation } from "modaresa-commons"

import RestoreBuyerButton from "../../ButtonRestoreBuyer"
import { ReactComponent as CloseIcon } from "../../../../../../static/close.svg"
import { Multiselect } from "../../../../../ui"
import {
  Bold,
  ButtonsRow,
  CloseModal,
  Container,
  Form,
  FormGroup,
  FormRow,
  InputsContainer,
  ModalLoaderContainer,
  Text,
} from "../styles"
import ButtonDeleteBuyer from "../../../../../elements/ButtonDeleteBuyer/ButtonDeleteBuyer"
import { markets } from "../../../../../../config/session"

import { RetailerSelect, RetailerTypes, setOptionFromRetailer } from "components/ui/Select/RetailerSelect"
import { emailRegex } from "helpers/regex"
import { onToastSuccess } from "helpers/toast"

const emailChangeStyles = {
  content: {
    width: "450px",
    height: "auto",
    maxHeight: "500px",
    minHeight: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    overflow: "visible",
    "padding-top": "50px",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 21,
  },
}

const confirmModalStyles = {
  content: {
    width: "450px",
    height: "auto",
    maxHeight: "500px",
    minHeight: "220px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "40px 30px",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 13,
  },
}

class BuyerInformation extends Component {
  constructor(props) {
    super(props)
    const { selectedBuyer, retailers, brand } = this.props

    const competitorGroup = []

    this.state = {
      values: {
        retailer: (selectedBuyer && retailers.byId[selectedBuyer.retailerId]) || "",
        brandId: brand._id,
        email: (selectedBuyer && selectedBuyer.email) || "",
        firstName: (selectedBuyer && selectedBuyer.firstName) || "",
        lastName: (selectedBuyer && selectedBuyer.lastName) || "",
        phoneNumber: (selectedBuyer && selectedBuyer.phoneNumber) || "",
        position: (selectedBuyer && selectedBuyer.position) || "",
        market: (selectedBuyer && selectedBuyer.market) || "womenMen",
        competitorGroup: (selectedBuyer && selectedBuyer.competitorGroup && competitorGroup[0].name) || "",
      },
      errors: {},
      touched: {},
      infos: {},
      isRetailerSetFromBuyerEmail: false,
      isChanged: false,
      isRequest: false,
      changeEmailModal: false,
      newEmail: "",
      isSendingRequest: false,
      isDelete: false,
      archiveBuyerModal: false,
      restoreBuyerModal: false,
      archivedBuyer: null,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { isSendingRequest } = state
    const { requestUpdateEmailError, requestUpdateEmailPending } = props
    if (isSendingRequest && !requestUpdateEmailError && !requestUpdateEmailPending) {
      return {
        isSendingRequest: false,
        changeEmailModal: false,
      }
    }
    return null
  }

  componentDidMount() {
    this.props.fetchSharedRetailers()
  }

  componentDidUpdate() {
    const { isDelete } = this.state
    const { requestPending, requestError } = this.props
    if (isDelete && !requestPending && !requestError) {
      this.props.history.push("/clients")
    }
  }

  validate = (value, name) => {
    const { values, errors } = this.state

    switch (name) {
      case "retailer":
        delete errors[name]
        if (!value) {
          errors[name] = "Required"
        }
        return {
          values: { ...values, [name]: value },
          errors,
        }
      case "firstName":
      case "position":
      case "market":
        if (value.trim().length > 0) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Required`
        return {
          values: { ...values, [name]: value },
          errors,
        }
      case "email":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (emailRegex.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "newEmail":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            newEmail: value,
            errors,
          }
        } else if (emailRegex.test(value) === true) {
          delete errors[name]
          return {
            newEmail: value,
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          newEmail: value,
          errors,
        }
      case "lastName":
      case "phoneNumber":
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }
      default:
        break
    }
  }

  onEmailChange = (value, name = "email", onNoBuyerOrSharedBuyer) => {
    const { touched } = this.state

    const newSubState = this.validate(value, name)
    const emailIsValid = !newSubState.errors[name]
    this.setState({ ...newSubState, isRetailerSetFromBuyerEmail: false })
    if (emailIsValid) {
      newSubState.touched = { ...touched, [name]: true }
      this.findBuyerOrSharedBuyerAndPopulateForm(value, onNoBuyerOrSharedBuyer)
    }
    newSubState.infos = {
      ...newSubState.infos,
      email: null,
    }
    this.setState({ ...newSubState, isRetailerSetFromBuyerEmail: false })
  }

  onInputChange = e => {
    if (typeof e === "string") {
      this.setState({ ...this.validate(e, "phoneNumber"), isChanged: true })
    } else {
      const name = e.target.name
      const value = e.target.value
      switch (name) {
        case "newEmail":
          this.setState({ ...this.validate(value, name) })
          break
        case "email":
          this.onEmailChange(value)
          break
        default:
          this.setState({ ...this.validate(value, name), isChanged: true })
      }
    }
  }

  onSelectChange = (value, name) => {
    const { touched, values } = this.state
    this.validate(value.value, name)
    if (name === "retailer") {
      touched[name] = true
      this.setState({
        values: {
          ...values,
          [name]: value,
          isChanged: true,
        },
        touched,
      })
    } else {
      touched[name] = true
      this.setState({
        values: { ...values, [name]: value.value },
        touched,
        isChanged: true,
      })
    }
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  createBuyerAndMaybeRetailer = values => {
    const { brand } = this.props
    const { retailer, ...buyerData } = values
    const sanitizedBuyerData = BuyerInformation.sanitizeBuyerData(buyerData)
    switch (retailer.__retailerType) {
      case RetailerTypes.RETAILER:
        this.addBuyerWithExistingRetailer(retailer.value, sanitizedBuyerData)
        break
      case RetailerTypes.SHARED_RETAILER:
        this.props.addBuyerWithNewDefaultRetailer(retailer.value, brand._id, sanitizedBuyerData, () => {
          this.props.history.push("/clients")
        })
        break
      default:
        console.error("Unknown retailer type")
        Raven.captureMessage("Unknown retailer type")
    }
  }

  notifyBuyerFound = (queryParams, response) => {
    const { infos, errors } = this.state
    if (response.length > 1) {
      Raven.captureMessage(`More than one shared buyer found for email ${queryParams.email}`)
      console.error(`More than one shared buyer found for email ${queryParams.email}`)
      return
    }
    const [buyer] = response

    const updatedState = {
      infos: {
        ...infos,
        email: null,
      },
      errors: {
        ...errors,
      },
      isRetailerSetFromBuyerEmail: false,
    }

    if (buyer.dbStatus === "archived") {
      updatedState.restoreBuyerModal = true
      updatedState.archivedBuyer = buyer
      updatedState.errors.email = (
        <span>
          You already have a buyer with this email in your CRM with status archived.{" "}
          <Link to={`/clients/${buyer._id}`}>See profile</Link>
        </span>
      )
    } else {
      updatedState.errors.email = (
        <span>
          You already have a buyer with this email in your CRM. <Link to={`/clients/${buyer._id}`}>See profile</Link>
        </span>
      )
    }
    this.setState(updatedState)
  }

  findBuyerOrSharedBuyerAndPopulateForm(value, onNoBuyerOrSharedBuyer) {
    const { brand } = this.props

    this.props.findBuyers({ email: value, brandId: brand._id }, this.notifyBuyerFound, () => {
      this.props.findSharedBuyers(
        { email: value },
        this.notifySharedBuyerFoundAndPopulateFields,
        onNoBuyerOrSharedBuyer,
      )
    })
  }

  notifySharedBuyerFoundAndPopulateFields = (queryParams, response) => {
    const { infos, values, errors } = this.state
    if (response.length > 1) {
      Raven.captureMessage(`More than one shared buyer found for email ${queryParams.email}`)
      console.error(`More than one shared buyer found for email ${queryParams.email}`)
      return
    }
    const [sharedBuyer] = response
    try {
      const retailerOption = this.getRetailerOptionFromSharedBuyer(sharedBuyer)
      this.setState({
        infos: {
          ...infos,
          email: "Looks like we know this person - here's the info we have!",
        },
        values: {
          ...values,
          retailer: retailerOption,
          firstName: sharedBuyer.firstName,
          lastName: sharedBuyer.lastName,
        },
        isRetailerSetFromBuyerEmail: !isNil(retailerOption),
      })
    } catch (error) {
      Raven.captureMessage(error)
      console.error(error)
      this.setState({
        errors: {
          ...errors,
          email: "We are experiencing an error with this email. Please contact us!",
        },
      })
    }
  }

  getRetailerOptionFromSharedBuyer(sharedBuyer) {
    let retailerOption = null
    const { sharedRetailers, retailers } = this.props
    const [sharedRetailer] = sharedRetailers.filter(sharedRetailer => sharedRetailer._id === sharedBuyer.retailerId)
    const isRetailerSetFromBuyerEmail = !isNil(sharedRetailer)
    if (isRetailerSetFromBuyerEmail) {
      const denormalizedRetailers = getObjectValues(retailers.byId)
      const [retailer] = denormalizedRetailers.filter(
        retailer => retailer.dbStatus !== "archived" && retailer.sharedRetailerId === sharedRetailer._id,
      )
      retailerOption = isNil(retailer)
        ? setOptionFromRetailer({
            ...sharedRetailer,
            __retailerType: RetailerTypes.SHARED_RETAILER,
          })
        : setOptionFromRetailer({
            ...retailer,
            __retailerType: RetailerTypes.RETAILER,
          })
    } else {
      throw new Error(
        `Shared buyer ${sharedBuyer.email} (${sharedBuyer._id}) has a non-existing shared retailer ${sharedBuyer.retailerId}`,
      )
    }
    return retailerOption
  }

  addBuyerWithExistingRetailer(retailerId, buyerData) {
    buyerData.retailerId = retailerId
    this.props.addClient(buyerData, () => {
      onToastSuccess(`Congrats! You can now invite ${buyerData.firstName} ${buyerData.lastName} to your sales session.`)
      this.props.history.push("/clients")
    })
  }

  static sanitizeBuyerData(buyerData) {
    return {
      ...buyerData,
      phoneNumber: buyerData.phoneNumber === "+" ? "" : buyerData.phoneNumber,
    }
  }

  submitForm = e => {
    e.preventDefault()
    const { values, errors, touched } = this.state
    const { selectedBuyer, isNew } = this.props
    for (const key in values) {
      // The email field has a particular behavior handled by onEmailChange
      if (key !== "email") {
        this.validate(values[key], key)
      }
      touched[key] = true
    }
    this.setState({ touched })

    if (isEmpty(errors)) {
      if (isNew) {
        if (!values.competitorGroup) {
          values.competitorGroup = null
        }
        this.setState({ isChanged: false, isRequest: true })
        this.createBuyerAndMaybeRetailer(values)
      } else {
        if (!values.competitorGroup) {
          values.competitorGroup = null
        }
        this.setState({ isChanged: false, isRequest: true })
        this.props.onUpdateClient(
          {
            data: {
              ...values,
              phoneNumber: values.phoneNumber === "+" ? " " : values.phoneNumber,
            },
            id: selectedBuyer._id,
          },
          () => {
            onToastSuccess("This buyer was successfully updated")
            this.props.history.push("/clients")
          },
        )
      }
    }
  }

  onDelete = () => {
    this.props.history.push("/clients")
  }

  onRestoredBuyer = () => {
    this.onToggleRestoreBuyerModal()
    this.props.history.push("/clients")
  }

  onToggleRestoreBuyerModal = () => {
    this.setState(prevState => ({
      restoreBuyerModal: !prevState.restoreBuyerModal,
    }))
  }

  renderRestoreBuyerModal() {
    const { brand } = this.props
    const { restoreBuyerModal, archivedBuyer } = this.state

    if (!archivedBuyer) return null
    const { email, firstName, lastName } = archivedBuyer

    return (
      <Modal
        isOpen={restoreBuyerModal}
        style={confirmModalStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.onToggleRestoreBuyerModal}
      >
        <>
          <div>
            <Bold style={{ fontSize: "16px" }}>{"Restore buyer"}</Bold>
          </div>
          <Text>
            <p>{"You previously had this buyer stored in your CRM.  Do you want to restore this buyer?"}</p>
            <p>{`${email} - ${firstName} ${lastName}`}</p>
          </Text>
          <ButtonsRow>
            <RestoreBuyerButton brandId={brand._id} buyer={archivedBuyer} onRestore={this.onRestoredBuyer} />
          </ButtonsRow>
        </>
        <CloseModal style={{ width: "17px", top: "10px", right: "10px" }} onClick={this.onToggleRestoreBuyerModal}>
          <CloseIcon fill="#000" height="12px" style={{ display: "block" }} width="100%" />
        </CloseModal>
      </Modal>
    )
  }

  onCreateEmailChangeRequest = () => {
    const { newEmail } = this.state
    const { onCreateRequest, selectedBuyer } = this.props
    this.setState({ isSendingRequest: true })
    onCreateRequest({
      clientId: selectedBuyer._id,
      newValue: newEmail,
      prevValue: selectedBuyer.email,
      type: "clientEmail",
      fieldName: "email",
    })
  }

  onOpenRequestEmailChangeModal = () => {
    this.setState({
      changeEmailModal: true,
    })
  }

  onCloseRequestEmailChangeModal = () => {
    this.setState({
      changeEmailModal: false,
    })
  }

  renderRequestEmailChangeModal() {
    const { changeEmailModal, errors, touched, newEmail } = this.state
    const { requestUpdateEmailPending } = this.props
    return (
      <Modal isOpen={changeEmailModal} style={emailChangeStyles} onRequestClose={this.onCloseRequestEmailChangeModal}>
        {requestUpdateEmailPending && (
          <ModalLoaderContainer>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </ModalLoaderContainer>
        )}
        <CloseModal style={{ width: "12px", top: "20px", right: "20px" }} onClick={this.onCloseRequestEmailChangeModal}>
          <CloseIcon fill="#000" height="12px" style={{ display: "block" }} width="100%" />
        </CloseModal>
        <React.Fragment>
          <div>
            <InputWithValidation
              errors={errors.newEmail}
              name="newEmail"
              placeholder={"Enter new email address"}
              touched={touched.newEmail}
              value={newEmail}
              width="100%"
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </div>
          <p>The email will be updated within 30 minutes.</p>
          <Flex>
            <Button disabled={!newEmail || errors.newEmail} onClick={this.onCreateEmailChangeRequest}>
              Confirm Email Change
            </Button>
          </Flex>
        </React.Fragment>
      </Modal>
    )
  }

  render() {
    const {
      values,
      errors,
      touched,
      infos,
      isChanged,
      isRequest,
      isRetailerSetFromBuyerEmail,
      restoreBuyerModal,
    } = this.state
    const {
      isNew,
      requestError,
      requestPending,
      selectedBuyer,
      retailers,
      sharedRetailers,
      sharedRetailerRequestPending,
      brand,
      user,
    } = this.props
    const changeRequests = selectedBuyer && selectedBuyer.changeRequests

    const denormalizedRetailers = getObjectValues(retailers.byId)
    return (
      <Container style={{ paddingTop: "25px" }}>
        {this.renderRequestEmailChangeModal()}
        {this.renderRestoreBuyerModal()}
        <Form onSubmit={this.submitForm}>
          <FormGroup>
            <FormRow style={{ width: "calc(50% + 190px)", minWidth: "690px" }}>
              <span>Email</span>
              <InputWithValidation
                disabled={!isNew}
                errors={errors.email}
                info={infos.email}
                name="email"
                placeholder={"example@example.com"}
                touched={touched.email}
                value={values.email}
                width="calc(100% - 330px)"
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {!isNew ? (
                <Button
                  disabled={changeRequests && changeRequests.clientEmail}
                  minWidth="180px"
                  style={{
                    marginLeft: "10px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  type="button"
                  onClick={this.onOpenRequestEmailChangeModal}
                >
                  {changeRequests && changeRequests.clientEmail ? "Email request pending" : "Change Email"}
                </Button>
              ) : (
                <Button
                  minWidth="180px"
                  disabled={!touched.email || errors.email}
                  style={{
                    marginLeft: "10px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  type="button"
                  onClick={() => {
                    this.onEmailChange(values.email, "email", () => {
                      const { infos } = this.state
                      this.setState({
                        infos: {
                          ...infos,
                          email: "New kid in town! Fill the info below",
                        },
                      })
                    })
                  }}
                >
                  Search Buyer
                </Button>
              )}
            </FormRow>
          </FormGroup>
          <FormGroup>
            <FormRow>
              <span>Full Name</span>
              <InputsContainer style={{ width: "calc(100% - 140px)" }}>
                <InputWithValidation
                  errors={errors.firstName}
                  name="firstName"
                  placeholder={"First Name"}
                  touched={touched.firstName}
                  value={values.firstName}
                  width="48%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
                <InputWithValidation
                  errors={errors.lastName}
                  name="lastName"
                  placeholder={"Last Name"}
                  touched={touched.lastName}
                  value={values.lastName}
                  width="48%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </InputsContainer>
            </FormRow>
            {isNew && !restoreBuyerModal ? (
              <FormRow style={{ position: "relative", zIndex: "15" }}>
                <span>Retailer</span>
                <Flex style={{ width: "calc(100% - 140px)" }}>
                  <RetailerSelect
                    isDisabled={isRetailerSetFromBuyerEmail}
                    className="BuyerMultiselect"
                    isLoading={sharedRetailerRequestPending}
                    value={values.retailer}
                    sharedRetailers={sharedRetailers}
                    retailers={denormalizedRetailers}
                    user={user}
                    brand={brand}
                    errors={errors.retailer}
                    name="retailer"
                    touched={touched.retailer}
                    onChange={this.onSelectChange}
                  />
                </Flex>
              </FormRow>
            ) : (
              <React.Fragment>
                <FormRow style={{ width: "calc(50% + 160px)", minWidth: "660px" }}>
                  <span>Store Name</span>
                  <InputWithValidation
                    disabled
                    value={
                      values.retailer
                        ? `${values.retailer.name} (${values.retailer.storeAddress.city}, ${values.retailer.storeAddress.country})`
                        : ""
                    }
                    width="calc(100% - 300px)"
                  />
                </FormRow>
                <FormRow>
                  <span>Retailer Id</span>
                  <InputWithValidation
                    disabled
                    value={values.retailer ? values.retailer.clientId : ""}
                    width="calc(100% - 140px)"
                  />
                </FormRow>
              </React.Fragment>
            )}
          </FormGroup>
          <FormGroup>
            <FormRow>
              <span>Job Position</span>
              <InputWithValidation
                errors={errors.position}
                name="position"
                placeholder={"Job Position"}
                touched={touched.position}
                value={values.position}
                width="calc(100% - 140px)"
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
            </FormRow>
            <FormRow>
              <span>Market</span>
              <Multiselect
                className="BuyerMultiselect"
                errors={errors.market}
                name="market"
                options={markets}
                touched={touched.market}
                value={markets.filter(({ value }) => value === values.market)}
                width={"200px"}
                onChange={this.onSelectChange}
              />
            </FormRow>
            <FormRow>
              <span>Phone Number</span>
              <InputWithValidation.InputContainer>
                <ReactPhoneInput
                  buttonStyle={errors.phoneNumber && touched.phoneNumber && { borderColor: "red" }}
                  country={"fr"}
                  inputExtraProps={{
                    name: "phoneNumber",
                  }}
                  inputStyle={errors.phoneNumber && touched.phoneNumber && { borderColor: "red" }}
                  enableSearch
                  disableSearchIcon
                  searchStyle={{ width: "95%" }}
                  value={values.phoneNumber}
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <InputWithValidation.ValidationError>{errors.phoneNumber}</InputWithValidation.ValidationError>
                )}
              </InputWithValidation.InputContainer>
            </FormRow>
          </FormGroup>
          <FormGroup>
            {isNew && (
              <FormRow>
                <Button
                  disabled={isRequest ? (!requestPending && requestError ? false : !isChanged) : !isChanged}
                  minWidth="275px"
                  type="submit"
                >
                  + Add Buyer
                </Button>
              </FormRow>
            )}
            {selectedBuyer && selectedBuyer.dbStatus === "active" && (
              <FormRow>
                <ButtonDeleteBuyer
                  buyer={{ id: selectedBuyer._id, fullName: selectedBuyer.firstName }}
                  brandId={brand._id}
                  onDelete={this.onDelete}
                >
                  Delete Buyer
                </ButtonDeleteBuyer>
                <Button
                  disabled={isRequest ? (!requestPending && requestError ? false : !isChanged) : !isChanged}
                  type="submit"
                >
                  Save Changes
                </Button>
              </FormRow>
            )}
          </FormGroup>
        </Form>
      </Container>
    )
  }
}

BuyerInformation.propTypes = {
  addClient: PropTypes.func,
  addBuyerWithNewDefaultRetailer: PropTypes.func,
  availableRetailers: PropTypes.array,
  brand: PropTypes.object,
  findBuyers: PropTypes.func,
  findSharedBuyers: PropTypes.func,
  history: PropTypes.object,
  isNew: PropTypes.bool,
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  requestUpdateEmailError: PropTypes.any,
  requestUpdateEmailPending: PropTypes.bool,
  retailers: PropTypes.object,
  fetchSharedRetailers: PropTypes.func,
  sharedRetailers: PropTypes.array,
  sharedRetailerRequestPending: PropTypes.bool,
  selectedBuyer: PropTypes.object,
  onCreateRequest: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdateClient: PropTypes.func,
}

export default BuyerInformation

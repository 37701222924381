import React, { memo, useCallback, useMemo, useState } from "react"
import { TwitterPicker } from "react-color"
import Dropdown, { DropdownContent, DropdownTrigger } from "react-simple-dropdown"
import { Input, palette } from "modaresa-commons"

import generateTerritoriesOptionsValuesFromObject from "../../../../helpers/areasAndTerritories/generateTerritoriesOptionsValuesFromObject"
import generateTerritoriesValuesFromOptions from "../../../../helpers/areasAndTerritories/generateTerritoriesValuesFromOptions"
import StaffPicker from "../../StaffPicker"
import TerritoryPicker from "../../TerritoryPicker"

import AreaNotificationsList from "./AreaNotificationsList"
import {
  AreaColor,
  CenteredDropdownActions,
  Error,
  ContainerAreaName,
  StyledBooleanStatusIcon,
  TdIcon,
} from "./AreaRow.styles"

type Props = {
  brandId: string,
  index: number,
  area: {
    color: string,
    name: string,
    countries: [string],
  },
  canAreasBeModified: boolean,
  canStaffsBeModified: boolean,
  onChangeArea: Function,
  onDeleteArea: Function,
  excludeCountries: [],
  territoriesOptions: [],
  error: string,
}

const agentAndManagerRoles = ["owner", "admin", "manager", "agent"]
const AreaRow = ({
  brandId,
  area,
  canAreasBeModified,
  canStaffsBeModified,
  onChangeArea,
  onDeleteArea,
  index,
  excludeCountries,
  territoriesOptions,
  error,
}: Props) => {
  const {
    color,
    name,
    territories,
    meetingResponsible,
    signee,
    notifiedStaffs,
    notificationMeetingReminder,
    notificationMeetingConfirmation,
  } = area
  const [showNotificationsEdition, setShowNotificationsEdition] = useState(false)
  const [territoriesOptionsValues, setTerritoriesOptionsValues] = useState(
    generateTerritoriesOptionsValuesFromObject(territories),
  )
  const handleChangeAreaField = useCallback(
    (index, field, value) =>
      onChangeArea(index, {
        ...area,
        [field]: value,
      }),
    [area, onChangeArea],
  )
  const handleChangeName = useCallback(e => handleChangeAreaField(index, "name", e.target.value), [
    handleChangeAreaField,
    index,
  ])
  const handleTerritoryChange = useCallback(
    async territoriesOpts => {
      setTerritoriesOptionsValues(territoriesOpts)
      handleChangeAreaField(index, "territories", await generateTerritoriesValuesFromOptions(territoriesOpts))
    },
    [handleChangeAreaField, index],
  )
  const handleChangeColor = useCallback(color => handleChangeAreaField(index, "color", color.hex), [
    handleChangeAreaField,
    index,
  ])
  const handleChangeStaff = useCallback(field => selectedStaff => handleChangeAreaField(index, field, selectedStaff), [
    handleChangeAreaField,
    index,
  ])
  const handleChangeMeetingResponsible = useCallback(
    selectedStaff => {
      const updatedArea = {
        ...area,
        meetingResponsible: selectedStaff,
      }
      const isMeetingResponsibleAlreadyNotifiedStaff = notifiedStaffs.find(staff => staff.id === selectedStaff.id)
      if (!isMeetingResponsibleAlreadyNotifiedStaff) {
        updatedArea.notifiedStaffs = [...notifiedStaffs, selectedStaff]
      }
      onChangeArea(index, updatedArea)
    },
    [area, notifiedStaffs, onChangeArea, index],
  )
  const handleChangeNotification = useCallback(
    (notificationField, isActive) => handleChangeAreaField(index, notificationField, isActive),
    [index, handleChangeAreaField],
  )
  const handleToggleNotificationsEdition = useCallback(() => setShowNotificationsEdition(!showNotificationsEdition), [
    showNotificationsEdition,
  ])

  const actions = useMemo(() => {
    const list = []
    if (canStaffsBeModified) {
      list.push({
        label: "Edit notifications",
        onClick: handleToggleNotificationsEdition,
      })
    }
    if (canAreasBeModified) {
      list.push({
        label: "Delete area",
        onClick: () => onDeleteArea(index),
      })
    }
    return list
  }, [handleToggleNotificationsEdition, canAreasBeModified, canStaffsBeModified, onDeleteArea, index])
  return (
    <>
      <tr>
        <td width="19%">
          <ContainerAreaName>
            <Dropdown disabled={!canStaffsBeModified}>
              <DropdownTrigger>
                <AreaColor bgColor={color} />
              </DropdownTrigger>
              <DropdownContent>
                <TwitterPicker
                  color={color}
                  colors={palette}
                  triangle="top-right"
                  onChangeComplete={handleChangeColor}
                />
              </DropdownContent>
            </Dropdown>
            <Input
              placeholder="Enter Area Name"
              type="text"
              defaultValue={name}
              disabled={!canAreasBeModified}
              onBlur={handleChangeName}
            />
          </ContainerAreaName>
        </td>
        <td>
          <TerritoryPicker
            multiple
            brandId={brandId}
            disabled={!canAreasBeModified}
            excludeCountries={excludeCountries}
            options={territoriesOptions}
            value={territoriesOptionsValues}
            onChange={handleTerritoryChange}
            placeholder="Select territories"
          />
        </td>
        <td width="12%">
          <StaffPicker
            defaultValue={meetingResponsible}
            disabled={!canStaffsBeModified}
            brandId={brandId}
            roles={agentAndManagerRoles}
            onChange={handleChangeMeetingResponsible}
          />
        </td>
        <td width="12%">
          <StaffPicker
            displayIsEmailVerified
            defaultValue={signee}
            disabled={!canStaffsBeModified}
            brandId={brandId}
            roles={agentAndManagerRoles}
            onChange={handleChangeStaff("signee")}
          />
        </td>
        <td width="17%">
          <StaffPicker
            value={notifiedStaffs}
            disabled={!canStaffsBeModified}
            brandId={brandId}
            multiple
            placeholder="Assign staff(s)"
            onChange={handleChangeStaff("notifiedStaffs")}
          />
        </td>
        <TdIcon width="32px">
          <StyledBooleanStatusIcon status={notificationMeetingConfirmation} size={18} />
        </TdIcon>
        <TdIcon width="32px">
          <StyledBooleanStatusIcon status={notificationMeetingReminder} size={18} />
        </TdIcon>
        <td>{actions.length > 0 && <CenteredDropdownActions actions={actions} />}</td>
      </tr>
      {error && (
        <tr>
          <td colSpan={8}>
            <Error>{error}</Error>
          </td>
        </tr>
      )}
      {showNotificationsEdition && (
        <tr>
          <td colSpan={8}>
            <AreaNotificationsList
              area={area}
              onChangeNotification={handleChangeNotification}
              onClose={handleToggleNotificationsEdition}
            />
          </td>
        </tr>
      )}
    </>
  )
}

export default memo(AreaRow)

import React, { Component } from "react"
import ContainerDimensions from "react-container-dimensions"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import times from "lodash/times"

import Booking from "../Booking"
import { calculateBookingSize } from "../../../../../helpers/calendar"
import { Table, Timeslot, Book } from "../../styles.js"

class Schedule extends Component {
  tableRefs = []

  onSlotClick = ({ tableIndex, roomName, timeStamp, type }) => {
    const { onSlotClick } = this.props
    onSlotClick({ tableIndex, roomName, timeStamp, type })
  }

  renderBookings = (table, roomRef, tableIndex) => {
    const {
      openingHours: { from, to },
      onBookingClick,
      canEditBooking,
    } = this.props
    const { height } = this.tableRefs[tableIndex].getBoundingClientRect()
    let day
    let mounth
    let start
    if (table[0]) {
      day = moment.utc(table[0].dayDate, "X").get("date")
      mounth = moment.utc(table[0].dayDate, "X").get("month")
      start = moment.utc(from, "X").set({ date: day, month: mounth })
    } else {
      start = moment.utc(from, "X")
    }
    const duration = moment.duration(moment.utc(to, "X").diff(moment.utc(from, "X")))
    const dayLength = (duration._data.hours * 60 + duration._data.minutes) / 60
    return table.map(booking => {
      const left = 0
      let { top, height: blockHeight } = calculateBookingSize(booking, start, dayLength, height)
      const durationOfBooking = moment.utc(booking.endTime, "X").diff(moment.utc(booking.startTime, "X"), "minutes")
      const step = durationOfBooking / 15

      const newStart = moment.utc(booking.startTime, "X").format("H:mm")
      const end = moment.utc(booking.endTime, "X").format("H:mm")
      const newTime = moment(newStart, "HH:mm")
        .add(booking.delayTime, "minutes")
        .format("HH:mm")

      if (
        booking.appointmentStatus === "delayed" &&
        booking.delayTime % 15 === 0 &&
        this.props.meetingLength > 15 &&
        moment.utc(newTime, "HH:mm").isBefore(moment.utc(end, "HH:mm"))
      ) {
        const unit = booking.delayTime / 15 //1
        blockHeight -= (blockHeight * unit) / step
        if (step !== unit && step > unit) {
          top += unit * (blockHeight / (step - unit))
        }
      }
      return (
        <Booking
          canEdit={canEditBooking}
          booking={booking}
          height={blockHeight}
          key={booking._id}
          left={left}
          top={top}
          onClick={e => onBookingClick(booking, e)}
        />
      )
    })
  }

  renderTable = (table, tableIndex, roomName, type) => {
    const {
      openingHours: { from, to },
    } = this.props
    const start = moment.utc(from, "X")
    const end = moment.utc(to, "X")

    const diff = end.diff(start, "hours", true)
    start.subtract(15, "m")
    return times(diff * 4, idx => {
      start.add(15, "m")
      const timeStamp = start.unix()
      return (
        <Timeslot
          type={type}
          even={(idx + 1) % 4 === 0}
          key={idx}
          onClick={e =>
            this.onSlotClick({
              event: e,
              tableIndex,
              roomName,
              timeStamp,
              type,
            })
          }
        >
          <Book>Book</Book>
        </Timeslot>
      )
    })
  }

  render() {
    const { room, roomType, hasRelous } = this.props
    return (
      <>
        {room.tables.map((table, idx, arr) => (
          <Table key={idx} last={idx === arr.length - 1} ref={el => (this.tableRefs[idx] = el)} hasRelous={hasRelous}>
            <ContainerDimensions>
              {() => (
                <>
                  {this.renderTable(table, idx, room.roomName, roomType)}
                  {this.props.mounted && this.renderBookings(table, this.roomRef, idx)}
                </>
              )}
            </ContainerDimensions>
          </Table>
        ))}
      </>
    )
  }
}

Schedule.propTypes = {
  meetingLength: PropTypes.number,
  mounted: PropTypes.bool,
  openingHours: PropTypes.object,
  room: PropTypes.object,
  onBookingClick: PropTypes.func,
  onSlotClick: PropTypes.func,
}

export { Schedule }

import React, { memo, useMemo } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { LayoutWithSideMenu } from "modaresa-commons"

import { isStaffEditor } from "../../../helpers/users"
import withLoggedUser from "../../hoc/withLoggedUser"
import Staff from "../Staff"
import StaffAddNew from "../StaffAddNew/StaffAddNew"
import StaffList from "../StaffList"

type Props = {
  loggedUser: {
    staff: {
      modaresaRole: string,
    },
  },
}
const RouteSettings = ({ loggedUser }: Props) => {
  const isEditor = isStaffEditor(loggedUser.staff)
  const menuItems = useMemo(() => {
    const items = [
      {
        key: "all",
        name: "All",
        to: `/staff/`,
        exact: true,
      },
    ]
    if (isEditor) {
      items.push({
        key: "new",
        name: "+ Add Staff",
        to: `/staff/new`,
      })
    }
    return items
  }, [isEditor])

  return (
    <LayoutWithSideMenu menuItems={menuItems}>
      <Switch>
        <Route component={StaffList} exact path={`/staff/`} />
        {isEditor && <Route component={StaffAddNew} exact key="/new" path={`/staff/new`} />}
        <Route component={Staff} exact key="/:id" path={`/staff/:id/:section?`} />
        <Redirect to={`/staff/`} />
      </Switch>
    </LayoutWithSideMenu>
  )
}
export default withLoggedUser(memo(RouteSettings))

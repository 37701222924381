import styled from "styled-components"

export const List = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 700px;
`
export const LiOfList = styled.div`
  padding: 5px;
  width: 260px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid #e2dfdf;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: rgb(166, 12, 70);
`

export const ContainerListMatchingRetailers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
`

export const Title = styled.div`
  width: 140px;
  font-size: 15px;
  line-height: 24px;
  color: ${p => p.theme.primary};
`

import React, { memo } from "react"
import { Modal } from "modaresa-commons"

import ModalBody from "./ModalBody"

type Props = {
  loading: boolean,
  error: boolean,
  booking: {
    _id: string,
    dayDate: number,
    startTime: number,
    endTime: number,
  },
  parallelBookingEvents: [],
  onClose: Function,
  actions: [],
  onChangeBookingEventToSwitch: Function,
}

const ModalSwitchMeetingHost = ({
  loading,
  error,
  booking,
  parallelBookingEvents,
  onClose,
  actions,
  onChangeBookingEventToSwitch,
}: Props) => (
  <Modal loading={loading} error={error} title="Meeting Swap" isOpen onClose={onClose} width="550px" actions={actions}>
    <p>Do you need to swap meetings in between two matching hosts?</p>
    <ModalBody
      booking={booking}
      parallelBookingEvents={parallelBookingEvents}
      onChangeBookingEventToSwitch={onChangeBookingEventToSwitch}
    />
  </Modal>
)

ModalSwitchMeetingHost.defaultProps = {
  parallelBookingEvents: [],
}
export default memo(ModalSwitchMeetingHost)

import React, { memo, useCallback, useState } from "react"
import { AlertDanger, Button, Loading, Flex } from "modaresa-commons"

import MeetingHost from "./blocks/MeetingHost"
import BookingDate from "./blocks/BookingDate"
import BuyersList from "./blocks/BuyersList"
import Header from "./blocks/Header"
import MeetingBudget from "./blocks/MeetingBudget"
import Modal from "./blocks/Modal"

type Props = {
  bookingRequest: {},
  loading: boolean,
  error: boolean,
  onClose: Function,
  buttons?: [
    {
      label: string,
      onClick: Function,
      bgcolor: string,
      disabled: boolean,
    },
  ],
  onChangeRequest: Function,
}

const BookingModal = ({
  bookingRequest: defaultBookingRequest,
  loading,
  error,
  onClose,
  buttons,
  onChangeRequest,
}: Props) => {
  const [bookingRequest, setBookingRequest] = useState(defaultBookingRequest)
  const handleChangeField = useCallback(
    field => value => {
      const updatedRequest = {
        ...bookingRequest,
        [field]: value,
      }
      setBookingRequest(updatedRequest)
      if (onChangeRequest) {
        onChangeRequest(updatedRequest)
      }
    },
    [bookingRequest, onChangeRequest],
  )
  const handleChangeHost = useCallback(opt => handleChangeField("host")(opt.value), [handleChangeField])
  const handleClickButton = useCallback(onClick => () => onClick(bookingRequest), [bookingRequest])
  if (loading) {
    return (
      <Modal isOpen onRequestClose={onClose}>
        <Loading />
      </Modal>
    )
  } else if (error) {
    return (
      <Modal isOpen onRequestClose={onClose}>
        <AlertDanger>{error}</AlertDanger>
      </Modal>
    )
  }
  const {
    buyers,
    dayDate,
    startTime,
    endTime,
    budget,
    meetingType,
    meetingApproach,
    retailer,
    status,
    room,
    availableHosts,
  } = bookingRequest
  return (
    <Modal isOpen onRequestClose={onClose}>
      <Header
        meetingType={meetingType}
        meetingApproach={meetingApproach}
        retailer={retailer}
        room={room}
        status={status}
      />
      <BuyersList buyers={buyers} />
      <BookingDate dayDate={dayDate} startTime={startTime} endTime={endTime} />
      <MeetingBudget budget={budget} onChange={handleChangeField("budget")} />
      <MeetingHost availableHosts={availableHosts} onChange={handleChangeHost} />
      <Flex>
        {buttons.map(button => (
          <Button
            key={button.label}
            bgcolor={button.bgcolor}
            disabled={button.disabled}
            type="button"
            onClick={handleClickButton(button.onClick)}
          >
            {button.label}
          </Button>
        ))}
      </Flex>
    </Modal>
  )
}

BookingModal.defaultProps = {
  buttons: [],
}
export default memo(BookingModal)

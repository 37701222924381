import {
  HelpTooltip,
  Button,
  ButtonSecondary,
  Input,
  Flex,
  InputWithValidation,
  ModalConfirmation,
} from "modaresa-commons"
import React, { Component } from "react"
import { Form } from "react-bootstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import isEmpty from "lodash/isEmpty"

import { getHourList } from "../../../../../helpers/date"
import { getRoomSettings } from "../../../../../helpers/session"
import LunchIcon from "../../../../../static/lunch.svg"
import DeleteIcon from "../../../../../static/noun_Trash_839970.svg"
import AppleIcon from "../../../../../static/apple.svg"
import BrkfstIcon from "../../../../../static/bkfst.svg"
import { DeleteButton, FooterLeft, FooterRight } from "../../../../elements/SessionFooter"
import { validateShowroomStructure } from "../../../../../helpers/validation"

import { RoomStructure } from "./RoomStructure"
import { mealTypes, standardDuration } from "./config"
import { DeleteIcon as BucketIcon, Container, Content, Divinder, ErrorText, Footer, Icon } from "./styles"

const hoursList = getHourList("24")

class ShowroomStructure extends Component {
  changed = false

  constructor(props) {
    super(props)
    const { session, defaultRooms } = this.props
    const meals = {}
    mealTypes.forEach(({ type }) => {
      const mealData = session && session.meals && session.meals[type]
      meals[type] = {
        available: mealData ? mealData.available : false,
        from: mealData ? { value: mealData.from, label: mealData.from } : null,
        to: mealData ? { value: mealData.to, label: mealData.to } : null,
      }
    })
    this.state = {
      walkthrough: {
        available: session.walkthrough ? session.walkthrough.available : false,
        length:
          session.walkthrough && session.walkthrough.length !== 0
            ? {
                value: session.walkthrough.length,
                label: `${session.walkthrough.length} min`,
              }
            : null,
      },
      businessMeeting: {
        available: session.businessMeeting ? session.businessMeeting.available : false,
        length:
          session.businessMeeting && session.businessMeeting.length !== 0
            ? {
                value: session.businessMeeting.length,
                label: `${session.businessMeeting.length} min`,
              }
            : null,
      },
      meals,
      customBuyingAppointment: session.customBuyingAppointment || false,
      customizeStructure: session.customizeStructure || false,
      videoMeetings: session.videoMeetings || false,
      faceToFaceMeetings: !session.disableFaceToFaceMeetings,
      rooms:
        (session.rooms.length > 0 && session.rooms) ||
        defaultRooms.map(room => ({
          name: room.name,
          structure: getRoomSettings(session.openingHours),
          areas: room.areas,
          remainingArea: room.remainingArea,
          hasBlockingList: room.hasBlockingList,
          typeBlockingList: room.typeBlockingList,
          retailersIds: room.retailersIds,
        })),
      errors: {},
      buttonText: "Next",
    }
  }

  onSubmit = () => {
    const errors = validateShowroomStructure(this.state)

    if (isEmpty(errors)) {
      const { ...values } = this.state
      const formattedValues = {
        walkthrough: {
          available: values.walkthrough.available,
          length: values.walkthrough.available ? values.walkthrough.length.value : 0,
        },
        businessMeeting: {
          available: values.businessMeeting.available,
          length: values.businessMeeting.available ? values.businessMeeting.length.value : 0,
        },
        customBuyingAppointment: values.customBuyingAppointment,
        customizeStructure: values.customizeStructure,
        videoMeetings: values.videoMeetings,
        disableFaceToFaceMeetings: !values.faceToFaceMeetings,
        meals: {},
        rooms: values.rooms,
      }
      mealTypes.forEach(({ type }) => {
        const mealData = values.meals[type]
        formattedValues.meals[type] = {
          available: mealData.available,
          from: mealData.available ? mealData.from.value : "",
          to: mealData.available ? mealData.to.value : "",
        }
      })

      this.props.onSubmit(formattedValues, this.changed)
    }
    this.setState({ errors, buttonText: "Save changes" })
  }

  onRemoveRoom = roomIndex => {
    this.changed = true
    this.setState(state => {
      const rooms = state.rooms.filter((room, index) => index !== roomIndex)
      return {
        rooms,
        buttonText: "Save changes",
      }
    })
  }

  handleMealsChange = (type, field, value) => {
    this.setState(state => {
      const meals = {
        ...state.meals,
        [type]: { ...state.meals[type], [field]: value },
      }
      return {
        meals,
        buttonText: "Save changes",
      }
    })
  }

  handleChange = (type, value) => {
    if (type === "customizeStructure" && value === false) {
      this.setState(state => {
        const rooms = state.rooms.map(room => {
          const structure = room.structure.map(day => ({
            ...room.structure[0],
            date: day.date,
          }))
          return { ...room, structure }
        })
        return {
          [type]: value,
          rooms,
        }
      })
    } else if (type === "videoMeetings") {
      const { staff } = this.props

      let hasIDs = false
      for (const id in staff.byId) {
        if (staff.byId[id].videoIDs && Object.keys(staff.byId[id].videoIDs).length) {
          hasIDs = true
          break
        }
      }

      if (!hasIDs) {
        this.setState({ videoMeetingsModalVisible: true })
      } else if (!value) {
        this.setState({
          [type]: value,
          faceToFaceMeetings: true,
        })
      } else {
        this.setState({ [type]: value })
      }
    } else if (type === "faceToFaceMeetings" && !value) {
      this.setState({
        [type]: value,
        videoMeetings: true,
      })
    } else this.setState({ [type]: value, buttonText: "Save changes" })
  }

  handleOtherMeetings = (type, field, value) => {
    this.setState(state => ({
      [type]: { ...state[type], [field]: value },
      buttonText: "Save changes",
    }))
  }

  handleRoomChange = (roomIndex, type, field, value, dayIndex) => {
    const { customizeStructure } = this.state
    if (type === "meetingCapacity") this.changed = true
    this.setState(state => {
      const rooms = state.rooms.map((room, idx) => {
        if (idx === roomIndex) {
          if (type === "videoUrl") {
            return { ...room, videoUrl: value || undefined }
          }
          const structure = room.structure.map((day, idx) => {
            if (!customizeStructure || idx === dayIndex) {
              return type === "meals" ? { ...day, [type]: { ...day[type], [field]: value } } : { ...day, [type]: value }
            }
            return day
          })
          return { ...room, structure }
        }
        return room
      })
      return {
        rooms,
        buttonText: "Save changes",
      }
    })
  }

  handleRoomNameChange = (roomIndex, name) => {
    this.setState(state => {
      const rooms = state.rooms.map((room, idx) => {
        if (idx === roomIndex) {
          return { ...room, name }
        }
        return room
      })
      return {
        rooms,
        buttonText: "Save changes",
      }
    })
  }

  goToStaff = () => {
    this.props.history.push("/staff")
  }

  renderRooms = () => {
    const { customizeStructure, meals } = this.state
    const { session, readOnly } = this.props
    const isDisabled = session.status === "active"
    const nbRemainingRooms = this.state.rooms.length
    return this.state.rooms.map((room, roomIdx) => (
      <Flex align="flex-start" className="border" key={roomIdx}>
        <Flex justify="flex-start" style={{ width: "13%", minHeight: "38px" }}>
          <span>{room.name}</span>
        </Flex>
        <div style={{ width: "84%" }}>
          {customizeStructure ? (
            <React.Fragment>
              <Flex margin="0 0 10px 0" style={{ padding: "0 15px" }}>
                <div style={{ width: "31%" }}>
                  Video URL
                  <HelpTooltip
                    text={
                      <span>
                        Input a link to video content that you want to share with your clients. <br />
                        It will be included in the Meeting Reminder email the buyer receives the morning prior to their
                        meeting.
                      </span>
                    }
                  />
                </div>
                <div style={{ width: "69%" }}>
                  <Input
                    isDisabled={readOnly}
                    placeholder="https://..."
                    value={room.videoUrl}
                    onChange={e => this.handleRoomChange(roomIdx, "videoUrl", null, e.target.value)}
                  />
                </div>
              </Flex>
              {room.structure.map((day, idx) => (
                <RoomStructure
                  dayIndex={idx}
                  isDisabled={isDisabled}
                  key={idx}
                  meals={meals}
                  readOnly={readOnly}
                  roomIndex={roomIdx}
                  showDates={customizeStructure}
                  structure={day}
                  onRoomChange={this.handleRoomChange}
                  onRoomNameChange={this.handleRoomNameChange}
                />
              ))}
            </React.Fragment>
          ) : (
            <RoomStructure
              dayIndex={0}
              isDisabled={isDisabled}
              meals={meals}
              readOnly={readOnly}
              roomIndex={roomIdx}
              structure={room.structure[0]}
              videoUrl={room.videoUrl}
              onRoomChange={this.handleRoomChange}
              onRoomNameChange={this.handleRoomNameChange}
            />
          )}
        </div>
        <div style={{ width: "3%" }}>
          <ButtonSecondary
            disabled={nbRemainingRooms <= 1 || isDisabled || readOnly}
            icon="true"
            minWidth="100%"
            onClick={() => this.onRemoveRoom(roomIdx)}
          >
            <BucketIcon src={DeleteIcon} />
          </ButtonSecondary>
        </div>
      </Flex>
    ))
  }

  renderMeals = () => {
    const { meals, errors } = this.state
    const { readOnly } = this.props
    const icons = [BrkfstIcon, LunchIcon, AppleIcon]
    return mealTypes.map((meal, mealIndex) => (
      <div style={{ position: "relative" }} key={meal.type}>
        <Flex margin="0 0 10px 0">
          <Flex style={{ width: "25%" }}>
            <Form.Check id={`meal-type-${meal.type}`}>
              <Form.Check.Input
                checked={meals[meal.type].available}
                disabled={readOnly}
                onChange={e => this.handleMealsChange(meal.type, "available", e.target.checked)}
              />
              <Form.Check.Label style={{ flex: 1, lineHeight: "20px", whiteSpace: "nowrap" }}>
                <Icon
                  src={icons[mealIndex]}
                  style={{ margin: "-5px 6px 0 0" }}
                  data-effect="solid"
                  height="20px"
                  width="20px"
                />
                {meal.label}
              </Form.Check.Label>
            </Form.Check>
            <HelpTooltip
              text={
                <span>
                  ModaResa counts how many you should expect for ${meal.type}, by counting all guests and meeting host
                  inside a given meeting. We count meetings that either:
                  <p>a) Start between the start time and end time, and finish before or after the end time</p>
                  <p>
                    b) Start before the start time and end after end time (ex: you set 12 - 14 and meeting lasts 11 -
                    15)
                  </p>
                </span>
              }
            />
          </Flex>
          <Flex style={{ width: "70%" }}>
            <div style={{ width: "45%" }}>
              <Select
                isDisabled={!meals[meal.type].available || readOnly}
                name="timezone"
                options={hoursList}
                placeholder=""
                value={meals[meal.type].available ? meals[meal.type].from : null}
                onChange={option => this.handleMealsChange(meal.type, "from", option)}
              />
            </div>
            <div style={{ width: "45%" }}>
              <Select
                isDisabled={!meals[meal.type].available || readOnly}
                name="timezone"
                options={hoursList}
                placeholder=""
                value={meals[meal.type].available ? meals[meal.type].to : null}
                onChange={option => this.handleMealsChange(meal.type, "to", option)}
              />
            </div>
          </Flex>
        </Flex>
        <InputWithValidation.ValidationError>
          {errors.meals && errors.meals[meal.type]}
        </InputWithValidation.ValidationError>
      </div>
    ))
  }

  render() {
    const { session, onDeleteSession, onPrevPress, readOnly } = this.props
    const {
      errors,
      walkthrough,
      businessMeeting,
      customizeStructure,
      videoMeetings,
      faceToFaceMeetings,
      buttonText,
    } = this.state
    const isSessionActive = session.status === "active"
    return (
      <Container>
        <Divinder>Buying Appointments</Divinder>
        <Content>
          <Flex align="stretch" style={{ minHeight: "38px" }}>
            <Form.Check
              id="customize-room-strucutre"
              checked={customizeStructure}
              disabled={readOnly}
              label={`Customize showroom structure for each opening day`}
              onChange={e => {
                this.changed = true
                this.handleChange("customizeStructure", e.target.checked)
              }}
              style={{ flex: 2 }}
            />
            <Form.Check
              id="enable-video-meetings"
              checked={videoMeetings}
              disabled={readOnly || isSessionActive}
              label={`Enable Virtual Meetings`}
              onChange={e => {
                this.changed = true
                this.handleChange("videoMeetings", e.target.checked)
              }}
              style={{ flex: 1 }}
            />
            <Form.Check
              id="enable-face-to-face-meetings"
              checked={faceToFaceMeetings}
              disabled={readOnly || isSessionActive}
              label={`Enable Face-to-Face Meetings`}
              onChange={e => {
                this.changed = true
                this.handleChange("faceToFaceMeetings", e.target.checked)
              }}
              style={{ flex: 1 }}
            />
          </Flex>

          <Flex align="stretch" className="roomTable" column margin="20px 0 0 0">
            <Flex className="border">
              <div style={{ width: "13%" }}>
                Room Name
                <HelpTooltip
                  text={
                    <span>
                      Name of product categories, lines or brands that your clients can choose from while making a
                      booking.
                    </span>
                  }
                />
              </div>
              <Flex style={{ width: "84%", padding: "0 15px" }}>
                {(customizeStructure && <div style={{ width: "20%" }} />) || (
                  <div style={{ width: "18%" }}>
                    Video URL
                    <HelpTooltip
                      text={
                        <span>
                          Input a link to video content that you want to share with your clients.
                          <br /> It will be included in the Meeting Reminder email the buyer receives the morning prior
                          to their meeting.
                        </span>
                      }
                    />
                  </div>
                )}
                <div style={{ width: "17%" }}>
                  Meeting Capacity
                  <HelpTooltip
                    text={
                      <span>
                        How many buying appointments can you host in the same time for one given product category or
                        collection? <br /> In other words, how many retailers can you host in the same time, for that
                        given room?
                      </span>
                    }
                  />
                </div>
                <div style={{ width: "15%" }}>
                  Relou
                  <HelpTooltip
                    text={
                      <span>
                        A Relou is a back-up meeting slot which is used in cases there are absolutely no successful
                        meeting options for the buyers. <br /> Tell us how many back-up slots you have in your showroom,
                        for each product category.
                      </span>
                    }
                  />
                </div>
                <div style={{ width: "15%" }}>Buying Appt. Length</div>
              </Flex>
              <div style={{ width: "3%" }} />
            </Flex>
            {this.renderRooms()}
          </Flex>
        </Content>
        <Divinder>Other meeting types</Divinder>
        <Content>
          <Flex align="flex-start">
            <div style={{ width: "47%" }}>
              <Flex margin="0 0 10px 0">
                <Form.Check
                  checked={walkthrough.available}
                  className="test"
                  disabled={readOnly}
                  label={`Walkthrough`}
                  onChange={e => this.handleOtherMeetings("walkthrough", "available", e.target.checked)}
                />
                <div style={{ width: "40%" }}>
                  <Select
                    isDisabled={!walkthrough.available || readOnly}
                    name="timezone"
                    options={standardDuration}
                    placeholder=""
                    value={
                      walkthrough.available && walkthrough.length
                        ? standardDuration.filter(dur => dur.value === walkthrough.length.value)
                        : null
                    }
                    onChange={option => this.handleOtherMeetings("walkthrough", "length", option)}
                  />
                  <ErrorText>{errors.walkthrough}</ErrorText>
                </div>
              </Flex>
              <Flex margin="0 0 10px 0">
                <Form.Check
                  checked={businessMeeting.available}
                  disabled={readOnly}
                  label={`Business Meeting`}
                  onChange={e => this.handleOtherMeetings("businessMeeting", "available", e.target.checked)}
                />
                <div style={{ width: "40%" }}>
                  <Select
                    isDisabled={!businessMeeting.available || readOnly}
                    name="timezone"
                    options={standardDuration}
                    placeholder=""
                    value={businessMeeting.available ? businessMeeting.length : null}
                    onChange={option => this.handleOtherMeetings("businessMeeting", "length", option)}
                  />
                  <ErrorText>{errors.businessMeeting}</ErrorText>
                </div>
              </Flex>
            </div>
            <div style={{ width: "47%" }}>{this.renderMeals()}</div>
          </Flex>
          <ModalConfirmation
            isOpen={this.state.videoMeetingsModalVisible}
            buttonLabelOk="Go to staff"
            hideCancel
            onClickOk={() => this.goToStaff()}
            onClose={() => this.setState({ videoMeetingsModalVisible: false })}
          >
            Oops, looks like you don't have any Virtual Meeting IDs listed on your staff! Go straight to your
            STAFF-list, and add the IDs you know so far.
          </ModalConfirmation>
        </Content>
        {!readOnly && (
          <Footer>
            <FooterLeft>
              <Button type="button" onClick={onPrevPress}>
                Previous
              </Button>
            </FooterLeft>
            <FooterRight>
              <DeleteButton type="button" onClick={onDeleteSession}>
                Delete Sales Session
              </DeleteButton>
              <Button type="button" onClick={this.onSubmit}>
                {isSessionActive ? buttonText : "Save changes"}
              </Button>
            </FooterRight>
          </Footer>
        )}
      </Container>
    )
  }
}

ShowroomStructure.propTypes = {
  history: PropTypes.object,
  staff: PropTypes.object,
  defaultRooms: PropTypes.array,
  readOnly: PropTypes.bool,
  session: PropTypes.object,
  onDeleteSession: PropTypes.func,
  onPrevPress: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ShowroomStructure

import { gql } from "@apollo/client"

export const ALL_RELOU_REQUESTS = gql`
  query AllRelouRequests($brandId: ID!, $sessionId: ID, $statuses: [RELOU_REQUEST_STATUS]) {
    allRelouRequests(brandId: $brandId, sessionId: $sessionId, statuses: $statuses) {
      id
      createdAt
      dayDate
      room {
        id
        name
      }
      startTime
      endTime
      buyers {
        id
        fullName
        retailer {
          name
        }
      }
      status
    }
  }
`

export const RELOU_REQUEST = gql`
  query RelouRequest($id: ID!) {
    RelouRequest(id: $id) {
      id
      createdAt
      dayDate
      meetingType
      meetingApproach
      room {
        id
        name
      }
      startTime
      endTime
      retailer {
        id
        name
        storeAddress {
          formattedAddress
        }
      }
      buyers {
        id
        fullName
        email
        position
        phoneNumber
      }
      status
      availableHosts {
        recommended {
          id
          fullName
          modaresaRole
        }
        others {
          id
          fullName
          modaresaRole
        }
      }
    }
  }
`

import React, { memo, useCallback, useState } from "react"

import RoomView from "./RoomAreasView"

type Props = {
  room?: {},
  brandId: string,
  onChange: Function,
  errors: {
    areas: {
      id: string,
    },
  },
  canAreasBeModified: boolean,
  canStaffsBeModified: boolean,
  skeleton: boolean,
  retailers: [],
}

const RoomAreas = ({
  brandId,
  room,
  onChange,
  errors,
  canAreasBeModified,
  canStaffsBeModified,
  skeleton,
  retailers,
}: Props) => {
  const [showAreas, setShowAreas] = useState(true)
  const handleChangeRoomField = useCallback(
    field => newFieldData => {
      onChange({ ...room, [field]: newFieldData })
    },
    [onChange, room],
  )
  const handleToggleShowAreas = useCallback(() => setShowAreas(!showAreas), [showAreas])
  return (
    <RoomView
      skeleton={skeleton}
      brandId={brandId}
      room={room}
      showAreas={showAreas}
      onToggleShowAreas={handleToggleShowAreas}
      onChangeAreas={handleChangeRoomField("areas")}
      onChangeRemainingArea={handleChangeRoomField("remainingArea")}
      errors={errors}
      canAreasBeModified={canAreasBeModified}
      canStaffsBeModified={canStaffsBeModified}
      retailers={retailers}
    />
  )
}

RoomAreas.defaultProps = {
  room: {},
}
export default memo(RoomAreas)

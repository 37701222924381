import { gql } from "@apollo/client"

import RoomFragments from "../queries/room.fragments"

export const UPDATE_BRAND_ROOM = gql`
  mutation UpdateBrandRoom($brandId: ID!, $roomInput: RoomInput!) {
    updateBrandRoom(brandId: $brandId, roomInput: $roomInput) {
      ...SingleRoom
    }
  }
  ${RoomFragments.fragments.single}
`

export const ADD_BRAND_ROOM = gql`
  mutation AddBrandRoom($brandId: ID!, $name: String!) {
    addBrandRoom(brandId: $brandId, name: $name) {
      ...SingleRoom
    }
  }
  ${RoomFragments.fragments.single}
`

export const DELETE_BRAND_ROOM = gql`
  mutation DeleteBrandRoom($brandId: ID!, $roomId: ID!) {
    deleteBrandRoom(brandId: $brandId, roomId: $roomId) {
      id
    }
  }
`

export const UPDATE_SALES_SESSION_ROOM = gql`
  mutation UpdateSalesSessionRoom($salesSessionId: ID!, $salesSessionRoomInput: SalesSessionRoomInput!) {
    updateSalesSessionRoom(salesSessionId: $salesSessionId, salesSessionRoomInput: $salesSessionRoomInput) {
      ...SingleRoom
    }
  }
  ${RoomFragments.fragments.single}
`

import React from "react"
import ReactSelect from "react-select"
import { theme } from "modaresa-commons"

const loadingIndicatorStyles = provided => ({
  ...provided,
  fontSize: 7,
  color: theme.primary,
})

export const Select = props => (
  <ReactSelect
    menuPortalTarget={document.body}
    menuPlacement="auto"
    {...props}
    styles={{
      loadingIndicator: loadingIndicatorStyles,
      menuPortal: base => ({ ...base, zIndex: 9999 }),
      ...(props.styles || {}),
    }}
  />
)

import { LEAVE_SOCKET_ROOM } from "../sockets/messageTypes"
import { createAsyncAction } from "../../helpers/redux"

export const FETCH_SCHEDULES = createAsyncAction("FETCH_SCHEDULES")
export const fetchSchedules = brandId => ({
  type: FETCH_SCHEDULES.REQUEST,
  payload: {
    brandId,
  },
})

export const FETCH_SCHEDULE = createAsyncAction("FETCH_SCHEDULE")
export const fetchSchedule = (sessionId, callback) => ({
  type: FETCH_SCHEDULE.REQUEST,
  payload: {
    sessionId,
    callback,
  },
})

export const SELECT_BOOKING = "SELECT_BOOKING"
export const selectBooking = booking => ({
  type: SELECT_BOOKING,
  payload: booking,
})

export const UNSELECT_BOOKING = "UNSELECT_BOOKING"
export const unselectBooking = () => ({
  type: UNSELECT_BOOKING,
})

export const UNSELECT_SCHEDULE = "UNSELECT_SCHEDULE"
export const unselectShedule = () => ({
  type: UNSELECT_SCHEDULE,
})

export const RESERVE_BOOKING = createAsyncAction("RESERVE_BOOKING")
export const reserveBooking = (id, data) => ({
  type: RESERVE_BOOKING.REQUEST,
  payload: {
    data,
    id,
  },
})

export const DELETE_BOOKING = createAsyncAction("DELETE_BOOKING")
export const deleteBooking = ({ bookingEventId, loggedStaffId, options = {} }) => ({
  type: DELETE_BOOKING.REQUEST,
  payload: {
    bookingEventId,
    loggedStaffId,
    options,
  },
})

export const CONFIRM_BOOKING = createAsyncAction("CONFIRM_BOOKING")
export const confirmBooking = data => ({
  type: CONFIRM_BOOKING.REQUEST,
  payload: data,
})

export const UPDATE_BOOKING = createAsyncAction("UPDATE_BOOKING")
export const updateBooking = data => ({
  type: UPDATE_BOOKING.REQUEST,
  payload: data,
})
export const CREATE_BOOKING = "CREATE_BOOKING"

export const cacheAddBooking = data => ({
  type: CREATE_BOOKING,
  payload: data,
})

export const FETCH_AVAILABLE_CLIENTS = createAsyncAction("FETCH_AVAILABLE_CLIENTS")
export const fetchAvailableClients = data => ({
  type: FETCH_AVAILABLE_CLIENTS.REQUEST,
  payload: data,
})

export const FETCH_AVAILABLE_STAFF = createAsyncAction("FETCH_AVAILABLE_STAFF")
export const fetchAvailableStaff = data => ({
  type: FETCH_AVAILABLE_STAFF.REQUEST,
  payload: data,
})

export const CHECK_NEW_BOOKING_TIME = createAsyncAction("CHECK_NEW_BOOKING_TIME")
export const checkNewBookingTime = data => ({
  type: CHECK_NEW_BOOKING_TIME.REQUEST,
  payload: data,
})

export const UPDATE_BOOKING_TIME = createAsyncAction("UPDATE_BOOKING_TIME")
export const updateBookingTime = data => ({
  type: UPDATE_BOOKING_TIME.REQUEST,
  payload: data,
})

export const FILTER_BOOKINGS = "FILTER_BOOKINGS"
export const filterBookings = booking => ({
  type: FILTER_BOOKINGS,
  payload: booking,
})

export const FILTER_APPOINTMENT = "FILTER_APPOINTMENT"
export const filterAppointment = filter => ({
  type: FILTER_APPOINTMENT,
  payload: filter,
})

export const RESET_FILTER_APPOINTMENT = "RESET_FILTER_APPOINTMENT"
export const resetFilterAppointment = () => ({
  type: RESET_FILTER_APPOINTMENT,
})

export const DELAY_APPOINTMENT = createAsyncAction("DELAY_APPOINTMENT")
export const delayAppoinment = data => ({
  type: DELAY_APPOINTMENT.REQUEST,
  payload: data,
})

export const DELETE_APPOINTMENT = createAsyncAction("DELETE_APPOINTMENT")
export const deleteAppointment = (bookingId, sessionId, options) => ({
  type: DELETE_APPOINTMENT.REQUEST,
  payload: {
    sessionId,
    bookingId,
    options,
  },
})

export const ARRIVED_TO_APPOINTMENT = createAsyncAction("ARRIVED_TO_APPOINTMENT")
export const arrivedToAppointment = (bookingId, sessionId) => ({
  type: ARRIVED_TO_APPOINTMENT.REQUEST,
  payload: {
    sessionId,
    bookingId,
  },
})

export const FETCH_AVAILABLE_HOSTS = createAsyncAction("FETCH_AVAILABLE_HOSTS")
export const fetchAvailableHosts = data => ({
  type: FETCH_AVAILABLE_HOSTS.REQUEST,
  payload: data,
})

export const UPDATE_APPOINTMENT_HOST = createAsyncAction("UPDATE_APPOINTMENT_HOST")
export const updateAppoinmentHost = (sessionId, bookingId, host) => ({
  type: UPDATE_APPOINTMENT_HOST.REQUEST,
  payload: {
    sessionId,
    bookingId,
    host,
  },
})

export const FETCH_JOKER_REQUESTS = createAsyncAction("FETCH_JOKER_REQUESTS")
export const fetchJokerRequests = (brandId, sessionId) => ({
  type: FETCH_JOKER_REQUESTS.REQUEST,
  payload: {
    brandId,
    sessionId,
  },
})

export const CONFIRM_JOKER_REQUEST = createAsyncAction("CONFIRM_JOKER_REQUEST")
export const confirmJokerRequest = data => ({
  type: CONFIRM_JOKER_REQUEST.REQUEST,
  payload: {
    data,
  },
})

export const DECLINE_JOKER_REQUEST = createAsyncAction("DECLINE_JOKER_REQUEST")
export const declineJokerRequest = requestId => ({
  type: DECLINE_JOKER_REQUEST.REQUEST,
  payload: {
    requestId,
  },
})

export const RESERVE_JOKER_BOOKING = createAsyncAction("RESERVE_JOKER_BOOKING")
export const reserveJokerBooking = (id, data) => ({
  type: RESERVE_JOKER_BOOKING.REQUEST,
  payload: {
    data,
    id,
  },
})
export const CONFIRM_JOKER_BOOKING = createAsyncAction("CONFIRM_JOKER_BOOKING")
export const confirmJokerBooking = data => ({
  type: CONFIRM_BOOKING.REQUEST,
  payload: data,
})

export const FETCH_JOKER_AVAILABLE_STAFF = createAsyncAction("FETCH_JOKER_AVAILABLE_STAFF")
export const fetchJokerAvailableStaff = data => ({
  type: FETCH_JOKER_AVAILABLE_STAFF.REQUEST,
  payload: data,
})
export const UPDATED_EVENTS = "UPDATED_EVENTS"
export const REPLACE_EVENT = "REPLACE_EVENT"
export const UPDATED_CALENDAR = "UPDATED_CALENDAR"
export const UPDATED_CALENDAR_SCHEDULE = "UPDATED_CALENDAR_SCHEDULE"
export const UPDATE_CALENDAR_BOOKING = "UPDATE_CALENDAR_BOOKING"
export const leaveSocketRoom = ({ room, type }) => ({
  type: "SEND_WEBSOCKET_MESSAGE",
  payload: {
    eventType: LEAVE_SOCKET_ROOM,
    body: { room, type },
  },
})

export const FETCH_CLIENT_CANCELLATIONS = createAsyncAction("FETCH_CLIENT_CANCELLATIONS")
export const fetchClientCancellations = (brandId, sessionId) => ({
  type: FETCH_CLIENT_CANCELLATIONS.REQUEST,
  payload: {
    brandId,
    sessionId,
  },
})

export const SWITCH_MEETING_HOSTS = "SWITCH_MEETING_HOSTS"
export const updateSwitchMeetingHosts = ({ bookingEventA, bookingEventB }) => ({
  type: SWITCH_MEETING_HOSTS,
  payload: {
    bookingEventA,
    bookingEventB,
  },
})

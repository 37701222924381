import React, { memo } from "react"
import { Section } from "modaresa-commons"

import ButtonAddBrandRoom from "../../../../elements/ButtonAddBrandRoom"
import TableRooms from "../../../../elements/TableRooms"

import { ContainerRoomsView, HeaderRoomsView, TotalRooms } from "./RoomsView.styles"
import RoomsViewSkeleton from "./RoomsViewSkeleton"

type Props = {
  rooms: [],
  canEdit: boolean,
  brandId: string,
  skeleton: boolean,
  onRenderTableRoomsActions: Function,
  onClickTableRoomsRow: Function,
}
const RoomsView = ({ rooms, canEdit, brandId, skeleton, onRenderTableRoomsActions, onClickTableRoomsRow }: Props) =>
  skeleton ? (
    <RoomsViewSkeleton />
  ) : (
    <ContainerRoomsView>
      <Section padding="30px 20px">
        Segment your showroom into the booking rooms you want the buyers to be able to book by.
        <br />
        For example by product category, lines or different brands.
      </Section>
      <HeaderRoomsView>
        <TotalRooms>{rooms.length} Rooms</TotalRooms>
        {canEdit && (
          <div>
            <ButtonAddBrandRoom brandId={brandId} />
          </div>
        )}
      </HeaderRoomsView>
      <TableRooms data={rooms} renderActions={onRenderTableRoomsActions} onClickRow={onClickTableRoomsRow} />
    </ContainerRoomsView>
  )

export default memo(RoomsView)

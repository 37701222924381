import React from "react"

import { RoomSchedule as RoomScheduleContainer } from "../../styles.js"

import { Schedule } from "./Schedule"

type Props = {
  meetingLength: number,
  mounted: boolean,
  openingHours: [],
  room: {},
  roomType: string,
  onBookingClick: Function,
  onSlotClick: Function,
  onSlotEnter: Function,
  canEditBooking: boolean,
}
const RoomSchedule = ({
  meetingLength,
  mounted,
  openingHours,
  room,
  roomRelou,
  roomType,
  onBookingClick,
  onSlotClick,
  onSlotEnter,
  canEditBooking,
}: Props) => (
  <RoomScheduleContainer>
    <Schedule
      meetingLength={meetingLength}
      mounted={mounted}
      openingHours={openingHours}
      room={room}
      roomType={roomType}
      onBookingClick={onBookingClick}
      onSlotClick={onSlotClick}
      onSlotEnter={onSlotEnter}
      hasRelous={roomRelou?.tables?.length}
      canEditBooking={canEditBooking}
    />
    {roomRelou && (
      <Schedule
        meetingLength={meetingLength}
        mounted={mounted}
        openingHours={openingHours}
        room={roomRelou}
        roomType="joker"
        onBookingClick={onBookingClick}
        onSlotClick={onSlotClick}
        onSlotEnter={onSlotEnter}
        canEditBooking={canEditBooking}
      />
    )}
  </RoomScheduleContainer>
)

export { RoomSchedule }

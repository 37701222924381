import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import times from "lodash/times"
import { Flex } from "modaresa-commons"

import { Hour, NotFound, RoomHeader, Timeline } from "../../styles.js"

import { StaffMemberTable } from "./components/StaffMemberTable"
import { StaffTable } from "./components/StaffTable"

class StaffCalendar extends PureComponent {
  renderHours = ({ from, to }) => {
    let start = moment.utc(from, "X").hour()
    const duration = moment.duration(moment.utc(to, "X").diff(moment.utc(from, "X")))
    const diffHour = (duration._data.hours * 60 + duration._data.minutes) / 60
    const diff = Math.ceil(diffHour)
    const isHalfHour = !((diffHour ^ 0) === diffHour)

    start--
    return times(diff * 2, idx => {
      if (isHalfHour && idx === diff * 2 - 1) {
        return null
      }
      if ((idx + 1) % 2 !== 0) {
        start++
        return <Hour key={idx}>{`${start}:00`}</Hour>
      }
      return <Hour key={idx} />
    })
  }

  render() {
    const {
      room,

      staff,
      openingHours,
      staffCalendarSchedule,
      selectedDay,
      selectedStaff,
      sessionSchedule,
      selectedDayIndex,
      canEditBooking,
    } = this.props
    if (!room) return <div>StaffCalendar Error</div>
    const NewAllocatedStaff = this.props.allocatedStaff.find(elem => elem.staffId === selectedStaff.value)
      ? [this.props.allocatedStaff.find(elem => elem.staffId === selectedStaff.value)]
      : this.props.allocatedStaff

    const range = {}
    sessionSchedule.forEach(item => {
      if (!range.from) {
        range.from = item.from
      } else {
        const nextFrom = moment.utc(item.from, "X")
        const prevFrom = moment.utc(range.from, "X")
        const diffHours = prevFrom.hours() - nextFrom.hours()
        const diffMinutes = prevFrom.minutes() - nextFrom.minutes()
        if (diffHours > 0 || (diffHours >= 0 && diffMinutes > 0)) {
          range.from = item.from
        }
      }
      if (!range.to) {
        range.to = item.to
      } else {
        const nextTo = moment.utc(item.to, "X")
        const prevTo = moment.utc(range.to, "X")
        const diffHours = nextTo.hours() - prevTo.hours()
        const diffMinutes = nextTo.minutes() - prevTo.minutes()
        if (diffHours > 0 || (diffHours >= 0 && diffMinutes > 0)) {
          range.to = item.to
        }
      }
    })
    const now = moment.utc()
    const fromHours = moment.utc(range.from, "X").hours()
    const fromMinutes = moment.utc(range.from, "X").minutes()
    const toHours = moment.utc(range.to, "X").hours()
    const toMinutes = moment.utc(range.to, "X").minutes()
    const from = now.clone().set({ hours: fromHours, minutes: fromMinutes })
    const to = now.clone().set({ hours: toHours, minutes: toMinutes })
    const timeRange = {
      to,
      from,
    }
    if (selectedStaff.value !== "all" && selectedStaff.value !== NewAllocatedStaff[0].staffId) {
      return (
        <NotFound style={{ width: "100%", margin: "auto" }}>
          <div>{`${selectedStaff.label} is not allocated to selected sales session`}</div>
        </NotFound>
      )
    }
    return (
      <div style={{ flex: 1, maxWidth: "100%" }}>
        {selectedStaff.value !== "all" && <RoomHeader>{selectedStaff.label}</RoomHeader>}
        <Flex
          align="stretch"
          justify="flex-start"
          style={{
            flex: 1,
            maxWidth: "100%",
            overflowX: "auto",
            minHeight: "90%",
          }}
        >
          {NewAllocatedStaff.length > 1 ? (
            <Timeline
              style={{
                paddingTop: 198,
              }}
            >
              {this.renderHours(openingHours)}
            </Timeline>
          ) : (
            <Timeline>{this.renderHours(timeRange)}</Timeline>
          )}

          {selectedStaff.value === "all" ? (
            <StaffTable
              allocatedStaff={NewAllocatedStaff}
              openingHours={this.props.openingHours}
              room={room}
              selectedDay={selectedDay}
              selectedDayIndex={selectedDayIndex}
              staff={staff}
              staffSchedule={staffCalendarSchedule}
              onBookingClick={this.props.onBookingClick}
              canEditBooking={canEditBooking}
            />
          ) : (
            <StaffMemberTable
              allocatedStaff={NewAllocatedStaff}
              openingHours={range}
              room={room}
              selectedDay={selectedDay}
              selectedStaff={selectedStaff}
              sessionSchedule={this.props.sessionSchedule}
              staff={staff}
              staffSchedule={staffCalendarSchedule}
              onBookingClick={this.props.onBookingClick}
              canEditBooking={canEditBooking}
            />
          )}
        </Flex>
      </div>
    )
  }
}

StaffCalendar.propTypes = {
  allocatedStaff: PropTypes.array,
  openingHours: PropTypes.object,
  room: PropTypes.object,
  selectedDay: PropTypes.number,
  selectedDayIndex: PropTypes.number,
  selectedStaff: PropTypes.object,
  sessionSchedule: PropTypes.array,
  staff: PropTypes.object,
  staffCalendarSchedule: PropTypes.object,
  onBookingClick: PropTypes.func,
  onSlotClick: PropTypes.func,
}

export default StaffCalendar

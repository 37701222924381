import { useState, useEffect } from "react"

const useComponentDidMount = (callback: Function) => {
  const [componentDidMount, setComponentDidMount] = useState(false)

  useEffect(() => {
    if (callback) {
      callback()
    }
    setComponentDidMount(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return componentDidMount
}

export default useComponentDidMount

import React from "react"
import { components } from "react-select"
import styled from "styled-components"

// to allow ellipsis from react-select to work
const WrapperMultiValueContainer = styled.div`
  min-width: 0;
`
export const MultiValueContainer = ({ children, data, innerProps, selectProps }) => (
  <WrapperMultiValueContainer title={data.title}>
    <components.MultiValueContainer children={children} data={data} innerProps={innerProps} selectProps={selectProps} />
  </WrapperMultiValueContainer>
)

import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import styled from "styled-components"

import { logout, login } from "../../../helpers/auth"
import { setupAuthToken } from "../../../store/actions/auth"

const MargedDiv = styled.div`
  margin: 64px;
`

const ConnectAsComponent = ({ onSetupAuthToken }) => {
  const client = useApolloClient()
  const history = useHistory()
  const { token } = useParams()
  useEffect(() => {
    async function setupToken() {
      if (token) {
        const controllerToken = localStorage.getItem("modaresa-access-token")
        localStorage.setItem("controller-token", controllerToken)
        await logout(client)
        await login({ token, client })
        onSetupAuthToken(token)
      } else {
        const controllerToken = localStorage.getItem("controller-token")
        await logout(client)
        await login({ token: controllerToken, client })
        localStorage.removeItem("controller-token")
        onSetupAuthToken(controllerToken)
      }
      history.push("/")
    }
    setupToken()
  }, [history, token, client, onSetupAuthToken])
  return <MargedDiv>Connecting as...</MargedDiv>
}

const actions = {
  onSetupAuthToken: setupAuthToken,
}
const ConnectAs = connect(() => ({}), actions)(ConnectAsComponent)
export default ConnectAs

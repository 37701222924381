/* eslint-disable */
import React, { memo, useEffect } from "react"
import { APP_NAME } from "../../../config/settings"

type Props = {
  title: string,
  children: any,
  noTransform: boolean,
}
const PageTitle = ({ title, children, noTransform }: Props) => {
  useEffect(() => {
    document.title = `${title}${noTransform ? "" : ` | ${APP_NAME}`}`
  }, [title, noTransform])
  return <>{children}</>
}

export default memo(PageTitle)

import React, { memo } from "react"
import { useParams } from "react-router-dom"

import { SaleSession } from "./index"

type Props = {
  history: {},
  location: {},
  match: {},
}

const WrapperSalesSession = ({ history, location, match }: Props) => {
  const { id } = useParams()
  return <SaleSession key={id} history={history} location={location} match={match} />
}

export default memo(WrapperSalesSession)

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import Component from "./Preview"

import { getClients, getRetailers, getSelectedSession, getStaff } from "store/selectors"

const selector = createStructuredSelector({
  retailers: getRetailers,
  allStaff: getStaff,
  clients: getClients,
  selectedSession: getSelectedSession,
})

export const Preview = connect(selector, null)(Component)

import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import { videoToolNames } from "../../../../../config"

import { PreviewBody, PreviewContainer, PreviewHeader, RoleTitle } from "./styles"

import Hanger from "static/hanger.svg"
import { getRetailerByIdOrEmpty, onMissingRetailerIdForClient } from "services/retailers"
import ClientsIcon from "static/buyingAppointment.svg"
import { Flex } from "components/screens/Calendars/styles"
import HostIcon from "static/seller.svg"
import { Icon } from "components/ui"
import StarIcon from "static/star.svg"
import TimeIcon from "static/clock.svg"
import VideoIcon from "static/view.svg"
import VideoCall from "static/videocall.svg"
import MeetingIcon from "static/meeting.svg"

class Preview extends Component {
  constructor(props) {
    super(props)
    const { booking } = this.props

    const clientId = booking.clients[0]
    const client = this.props.clients.byId[clientId]

    const selectedRetailer = getRetailerByIdOrEmpty(
      client.retailerId,
      props.retailers,
      onMissingRetailerIdForClient(client),
    )

    this.state = {
      selectedRetailer,
    }
  }

  render() {
    const { selectedRetailer } = this.state
    const { booking, clients, allStaff, coords, selectedSession } = this.props
    let newTime
    if (booking.appointmentStatus === "delayed") {
      const start = moment.utc(booking.startTime, "X").format("H:mm")

      newTime = moment(start, "HH:mm")
        .add(booking.delayTime, "minutes")
        .format("HH:mm")
    }

    const ignoreVideoToos = ["facetime", "whatsapp"]
    const isVideoMeeting =
      booking.meetingApproach === "video-conference" && ignoreVideoToos.indexOf(booking.videoTool) === -1

    const sessionHost = booking.host && selectedSession.staff.find(staff => staff.staffId === booking.host)

    let videoMeetingUrl
    if (isVideoMeeting && sessionHost && sessionHost.videoIDs) {
      if (booking.videoTool === "skype") {
        videoMeetingUrl = `skype:${sessionHost.videoIDs[booking.videoTool]}?call`
      } else {
        videoMeetingUrl = sessionHost.videoIDs[booking.videoTool]
      }
    }
    return (
      <PreviewContainer style={coords}>
        <PreviewHeader>
          {selectedRetailer.name},{selectedRetailer.storeAddress.city} - {selectedRetailer.clientId}
        </PreviewHeader>
        <PreviewBody>
          <Flex align="flex-start" justify="flex-start" margin="0 0 10px 0">
            <Icon icon={Hanger} margin="5px 8px 0 0" width="18px" />
            <Flex column justify="flex-start" align="flex-start">
              <RoleTitle>Room</RoleTitle>
              <div key={booking._id}>{booking.room}</div>
            </Flex>
          </Flex>
          <Flex align="flex-start" justify="flex-start" margin="0 0 10px 0">
            <Icon icon={ClientsIcon} margin="5px 8px 0 0" width="20px" />
            <Flex column justify="flex-start" align="flex-start">
              <RoleTitle>Buyer(s)</RoleTitle>
              {booking.clients.map(id => {
                const client = clients.byId[id]
                return (
                  <div key={id}>
                    {client.firstName} {client.lastName}
                  </div>
                )
              })}
            </Flex>
          </Flex>
          <Flex align="flex-start" justify="flex-start" margin="0 0 10px 0">
            <Icon icon={StarIcon} margin="5px 8px 0 0" width="20px" />
            <Flex column justify="flex-start" align="flex-start">
              <RoleTitle>Responsible</RoleTitle>
              {booking.meetingResp.length ? (
                booking.meetingResp?.map(id => {
                  const staff = allStaff.byId[id]
                  return <div key={id}>{staff ? `${staff.firstName} ${staff.lastName}` : "deleted staff"}</div>
                })
              ) : (
                <div>- No Meeting Responsible -</div>
              )}
            </Flex>
          </Flex>
          <Flex justify="flex-start" align="flex-start" margin="0 0 10px 0">
            <Icon icon={HostIcon} margin="5px 8px 0 0" width="20px" />
            <Flex column justify="flex-start" align="flex-start">
              <RoleTitle>Host</RoleTitle>
              {booking.host && booking.host !== "- No Meeting Host assigned -" ? (
                <div>
                  {allStaff.byId[booking.host].firstName} {allStaff.byId[booking.host].lastName}
                </div>
              ) : (
                <div>- No Host -</div>
              )}
            </Flex>
          </Flex>
          <Flex justify="flex-start" align="flex-start" margin="0 0 10px 0">
            <Icon
              icon={(booking.meetingApproach === "face-to-face" && MeetingIcon) || VideoCall}
              margin="5px 8px 0 0"
              width="20px"
            />
            <Flex column justify="flex-start" align="flex-start">
              <RoleTitle>Meeting Format</RoleTitle>
              {(booking.meetingApproach === "face-to-face" && "Face to Face") ||
                (booking.meetingApproach === "video-conference" && booking.videoTool === "whatsapp" && "WhatsApp") ||
                (booking.videoTool === "facetime" && "FaceTime") ||
                (booking.videoTool === "skype" && "Skype") ||
                (booking.videoTool === "zoom" && "Zoom") ||
                (booking.videoTool === "googlemeet" && "Google Meet") ||
                (booking.videoTool === "msteams" && "Microsoft Teams") ||
                (booking.videoTool === "whereby" && "WhereBy")}
            </Flex>
          </Flex>
          <Flex justify="flex-start">
            <Icon icon={TimeIcon} margin="0 8px 0 0" width="20px" />
            <div>
              {`${moment.utc(booking.startTime, "X").format("H:mm")} - ${moment
                .utc(booking.endTime, "X")
                .format("H:mm")}`}
            </div>
          </Flex>
          {newTime && <div style={{ color: "red" }}>New arrival {newTime}</div>}
          {videoMeetingUrl && (
            <Flex justify="flex-start" align="flex-start" margin="10px 0 0 0">
              <Icon icon={VideoIcon} margin="0 8px 0 0" width="20px" />
              <div>
                <a onClick={e => e.stopPropagation()} href={videoMeetingUrl} target="_blank" rel="noopener noreferrer">
                  Join this meeting on <strong>{videoToolNames[booking.videoTool]}</strong>
                </a>
              </div>
            </Flex>
          )}
        </PreviewBody>
      </PreviewContainer>
    )
  }
}

Preview.propTypes = {
  allStaff: PropTypes.object,
  booking: PropTypes.object,
  clients: PropTypes.object,
  coords: PropTypes.object,
  retailers: PropTypes.object,
}

export default Preview

import React from "react"
import styled from "styled-components"
import { SkeletonBlock } from "modaresa-commons"

export const ContainerRelouRequestSkeleton = styled.div`
  width: 100%;
  height: 137px;
  border-bottom: 1px solid ${p => p.theme.gray50};
  border-top: 1px solid ${p => p.theme.gray50};
  padding: 8px 12px;
`

const RelouRequestSkeleton = () => (
  <ContainerRelouRequestSkeleton>
    <SkeletonBlock height="21px" width="64px" />
    <SkeletonBlock height="20px" width="100px" margin="2px 0 0 0" />
    <SkeletonBlock height="40px" width="80px" margin="8px 0 0 auto" />
  </ContainerRelouRequestSkeleton>
)

export default RelouRequestSkeleton

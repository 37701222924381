import { useMutation } from "@apollo/client"
import { useField } from "formik"
import React, { memo, useCallback, useState } from "react"
import styled from "styled-components"
import { Input, Button } from "modaresa-commons"

import { VERIFY_STAFF_EMAIL } from "../../../graphql/mutations/staff"
import { getGraphQLErrorAsString } from "../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../helpers/toast"
import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

const ContainerEmail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const MargedButton = styled(Button)`
  margin-left: 16px;
`

type Props = {
  staffId: string,
  labelWidth: string,
  disabledEmail: boolean,
  disabledEmailVerification: boolean,
  isEmailVerified: boolean,
  required: boolean,
  showVerification: boolean,
}

const FieldEmail = ({
  staffId,
  labelWidth,
  disabledEmail,
  disabledEmailVerification,
  isEmailVerified,
  required,
  showVerification,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [verifyStaffEmail] = useMutation(VERIFY_STAFF_EMAIL)
  const [field, meta] = useField("email")
  const handleChange = useCallback(
    e => {
      field.onChange(e)
    },
    [field],
  )
  const handleClickVerify = useCallback(
    async e => {
      e.preventDefault()
      setIsLoading(true)
      try {
        await verifyStaffEmail({ variables: { id: staffId } })
        onToastSuccess("A verification email has been sent.")
      } catch (err) {
        console.error(err)
        onToastError("An error occured while verifying staff email." + getGraphQLErrorAsString(err))
      }
      setIsLoading(false)
    },
    [verifyStaffEmail, staffId],
  )
  return (
    <FormFieldLayout
      label={`E-mail${required ? "*" : ""}`}
      labelWidth={labelWidth}
      name="email"
      direction="row"
      error={meta.touched && meta.error}
    >
      <ContainerEmail>
        <Input
          id={"email"}
          onChange={handleChange}
          onBlur={field.onBlur}
          value={field.value}
          disabled={disabledEmail}
        />
        {showVerification && (
          <MargedButton
            disabled={disabledEmailVerification || isEmailVerified || isLoading}
            onClick={handleClickVerify}
          >
            {isEmailVerified ? "Verified" : "Verify"}
          </MargedButton>
        )}
      </ContainerEmail>
    </FormFieldLayout>
  )
}

export default memo(FieldEmail)

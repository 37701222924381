import styled from "styled-components"

export const ContainerStaffAddNew = styled.div`
  width: 100%;
  max-width: 584px;
  padding: 24px;
`

export const Title = styled.h2`
  margin-bottom: 24px;
  margin-top: 0;
`

import { useQuery } from "@apollo/client"
import Select from "react-select"
import React, { memo, useCallback, useMemo } from "react"
import styled from "styled-components"

import { BRAND_RETAILERS } from "../../../graphql/queries/brand"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

export const StoreAddress = styled.span`
  color: ${p => p.theme.gray200};
`

export const Country = styled.span`
  text-transform: uppercase;
`

type Props = {
  className: string,
  brandId: string,
  placeholder?: boolean,
  disabled: boolean,
  defaultValue: any,
  value: any,
  onChange: Function,
  multiple: boolean,
  excludeRetailers: [
    {
      id: string,
      name: string,
    },
  ],
}

const BrandRetailerPicker = ({
  className,
  brandId,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
  multiple,
  excludeRetailers,
  restrictToStaffIdIfAgent,
}: Props) => {
  const handleGetOptionValue = useCallback(option => `${option.id} ${option.name}`, [])
  const handleGetOptionLabel = useCallback(
    option => (
      <span>
        {option.name}{" "}
        {option.storeAddress && (
          <StoreAddress>
            (<span>{`${option.storeAddress.city}, `}</span>
            <Country>{option.storeAddress.country}</Country>)
          </StoreAddress>
        )}
      </span>
    ),
    [],
  )
  const { loading, error, data } = useQuery(BRAND_RETAILERS, {
    variables: { id: brandId, restrictToStaffId: restrictToStaffIdIfAgent },
  })
  const options = useMemo(() => {
    if (!data?.Brand) return []
    const retailers = data.Brand.retailers
    return excludeRetailers
      ? retailers.filter(retailer => !excludeRetailers.find(excludedRetailer => excludedRetailer.id === retailer.id))
      : retailers
  }, [data, excludeRetailers])
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error</ErrorLiveChatHelp>

  return (
    <Select
      className={className}
      isLoading={loading}
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      options={options}
      isMulti={multiple}
      placeholder={placeholder}
      onChange={onChange}
      getOptionValue={handleGetOptionValue}
      getOptionLabel={handleGetOptionLabel}
    />
  )
}

BrandRetailerPicker.defaultProps = {
  placeholder: "Select retailer",
}
export default memo(BrandRetailerPicker)

import React, { memo } from "react"

import Areas from "../../../../elements/Areas/Areas"
import Toggler from "../../../../elements/Toggler"

const CompanyAreasSkeleton = () => (
  <div>
    <Toggler
      title="Room Based Areas"
      description="Create areas within rooms"
      checked={false}
      onChange={() => undefined}
    />
    <Areas loading />
  </div>
)

export default memo(CompanyAreasSkeleton)

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  getActiveSessions,
  getBrand,
  getCapacityRequestError,
  getCapacityRequestStatus,
  getSelectedSession,
  getSessionCapacity,
  getSessionOptions,
} from "../../../../../store/selectors"
import { fetchCapacity } from "../../../../../store/actions/salesSessions"

import Component from "./Capacity"

const selector = createStructuredSelector({
  brand: getBrand,
  sessions: getActiveSessions,
  selectedSession: getSelectedSession,
  sessionCapacity: getSessionCapacity,
  requestPending: getCapacityRequestStatus,
  requestError: getCapacityRequestError,
  sessionOptions: getSessionOptions,
})

const actions = {
  onFetchCapacity: fetchCapacity,
}

export const Capacity = connect(selector, actions)(Component)

import { useMutation, useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import React, { memo, useCallback, useState } from "react"
import { AlertDanger } from "modaresa-commons"

import { UPDATE_BRAND_ROOM } from "../../../../../graphql/mutations/room"
import { BRAND_ROOM } from "../../../../../graphql/queries/room"
import { getGraphQLErrorAsString } from "../../../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"

import RoomView from "./RoomView"

type Props = {
  brandId: string,
  canEdit: boolean,
  restrictToStaffIdIfAgent: string,
}
const Room = ({ brandId, canEdit, restrictToStaffIdIfAgent }: Props) => {
  const [isLoading, setIsloading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [updateBrandRoom] = useMutation(UPDATE_BRAND_ROOM)
  const { id: roomId } = useParams()
  const handleSubmitFormRoom = useCallback(
    async values => {
      setIsloading(true)
      const variables = {
        brandId,
        roomInput: {
          id: roomId,
          name: values.name,
          hasBlockingList: values.hasBlockingList,
          typeBlockingList: values.typeBlockingList,
          retailersIds: values.retailers.map(retailer => retailer.id),
        },
      }
      try {
        await updateBrandRoom({ variables })
        onToastSuccess(
          `Room settings ${
            values.hasBlockingList ? "and blocking list " : ""
          }updated. These changes will be effective for future sales sessions.`,
        )
      } catch (e) {
        onToastError("An error occured while updating room." + getGraphQLErrorAsString(e))
      }
      setIsloading(false)
    },
    [roomId, updateBrandRoom, brandId],
  )
  const { loading, error, data } = useQuery(BRAND_ROOM, {
    variables: { brandId, roomId },
    onCompleted: () => {
      setInitialLoading(true)
    },
  })
  if (loading && !initialLoading) return <RoomView skeleton />
  if (error)
    return <ErrorLiveChatHelp technicalError={error}>An error occured while loading brand room</ErrorLiveChatHelp>
  const { getBrandRoom: room } = data
  if (!room) {
    return <AlertDanger>Room doesn't exist or has been deleted</AlertDanger>
  }
  return (
    <RoomView
      brandId={brandId}
      room={room}
      canEdit={canEdit}
      restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
      onSubmit={handleSubmitFormRoom}
      loadingSubmit={isLoading}
    />
  )
}

export default memo(Room)

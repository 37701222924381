import { useHistory } from "react-router-dom"
import { useMutation } from "@apollo/client"
import React, { memo, useCallback } from "react"
import { Button } from "@material-ui/core"
import styled from "styled-components"
import { theme } from "modaresa-commons"

import { CONNECT_AS } from "../../../../../../graphql/mutations/user"
import { onToastError } from "../../../../../../helpers/toast"

type Props = {
  id: string,
  email: string,
}
const StyledButton = styled(Button)`
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.05), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  &:hover {
    background-color: ${theme.primary} !important;
    color: #fff;
  }
`

const ActionConnectAs = ({ user: { id, email, roles, firstName } }: Props) => {
  const history = useHistory()
  const [connectAs] = useMutation(CONNECT_AS)
  const handleOnClickConnectAs = useCallback(async () => {
    try {
      const {
        data: { connectAs: authPayload },
      } = await connectAs({
        variables: { userId: id },
      })
      const role = roles[0]
      const { token } = authPayload
      if (role === "staff") {
        history.push(`/connect-as/${token}`)
      } else if (role === "buyer") {
        window.open(`${process.env.REACT_APP_BOOKING_URL}/connect-as/${token}`)
      }
    } catch (e) {
      onToastError(`An error happened while trying to connect to ${email}`)
    }
  }, [connectAs, email, history, id, roles])
  return (
    <StyledButton variant="outlined" onClick={handleOnClickConnectAs}>
      Connect As {firstName}
    </StyledButton>
  )
}

export default memo(ActionConnectAs)

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  getActiveSessions,
  getAuthStatus,
  getDataLoadedStatus,
  getSelectedSession,
  getUserData,
} from "../../../store/selectors"
import { selectSession } from "../../../store/actions/salesSessions"

import Component from "./Sessions"

const selector = createStructuredSelector({
  isDataLoaded: getDataLoadedStatus,
  isAuthed: getAuthStatus,
  loggedUser: getUserData,
  activeSessions: getActiveSessions,
  selectedSession: getSelectedSession,
})

const actions = {
  onSelectSession: selectSession,
}

export const Sessions = connect(selector, actions)(Component)

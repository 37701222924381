import React, { Component } from "react"
import NumberFormat from "react-number-format"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import { Button, Flex } from "modaresa-commons"

import EyeIcon from "../../../../../../static/view.svg"
import { Icon, Loader, LoaderContainer, NotFound, Row, Table, TableBody, TableHeader } from "../styles"
import { marketsByValue } from "../../../../../../config/session"

export const seasonsKey = {
  preSummerSpring: "Pre-Summer Spring",
  summerSpring: "Summer Spring",
  preAutumnWinter: "Pre-Autumn Winter",
  autumnWinter: "Autumn Winter",
}

class HistoryOfMeetings extends Component {
  componentDidMount() {
    const { match, onFetchReports } = this.props
    onFetchReports(match.params.id)
  }

  onChoose = id => {
    const { match } = this.props
    this.props.history.push(`/clients/${match.params.id}/meetings-reports/${id}`)
  }

  renderString = () => {
    const { reports } = this.props
    return reports.map(report => (
      <Row key={report._id} onClick={() => this.onChoose(report._id)}>
        <div style={{ width: "14%" }}>
          <NumberFormat
            decimalSeparator=","
            displayType={"text"}
            prefix={"€"}
            thousandSeparator="."
            value={report.otb}
          />
        </div>
        <div style={{ width: "14%" }}>
          <NumberFormat
            decimalSeparator=","
            displayType={"text"}
            prefix={"€"}
            thousandSeparator="."
            value={report.finalBudget}
          />
        </div>
        <div style={{ width: "14%" }}>{seasonsKey[report.session.season]}</div>
        <div style={{ width: "14%" }}>{marketsByValue[report.session.market]}</div>
        <div style={{ width: "14%" }}>{report.room}</div>
        <div style={{ width: "14%" }}>
          {report.meetingResp?.length
            ? `${report.meetingResp[0].firstName} ${report.meetingResp[0].lastName}`
            : "No Responsible"}
        </div>
        <div style={{ width: "14%" }}>
          {report.host ? `${report.host.firstName} ${report.host.lastName}` : "No host"}
        </div>
        <div style={{ width: "4%" }}>
          <Icon src={EyeIcon} />
        </div>
      </Row>
    ))
  }

  render() {
    const { reports, requestPending } = this.props
    if (requestPending) {
      return (
        <LoaderContainer>
          <Loader>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </Loader>
        </LoaderContainer>
      )
    }
    return reports.length === 0 ? (
      <NotFound>
        <span>There are no meeting reports</span>
      </NotFound>
    ) : (
      <Flex align="strengt" column justify="flex-start" style={{ height: "100%" }}>
        <Table>
          <TableHeader>
            <div style={{ width: "14%" }}>OTB</div>
            <div style={{ width: "14%" }}>Confirmed Budget</div>
            <div style={{ width: "14%" }}>Season</div>
            <div style={{ width: "14%" }}>Market</div>
            <div style={{ width: "14%" }}>Room</div>
            <div style={{ width: "14%" }}>Allocated Manager</div>
            <div style={{ width: "14%" }}>Meeting Host</div>
            <div style={{ width: "4%" }} />
          </TableHeader>
          <TableBody>{this.renderString()}</TableBody>
        </Table>
        <Flex align="flex-end" column style={{ padding: "15px 30px", minHeight: "67px" }}>
          <Button bgcolor="#516CEF" onClick={this.props.onExportReports}>
            Export Data
          </Button>
        </Flex>
      </Flex>
    )
  }
}

export { HistoryOfMeetings }

HistoryOfMeetings.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  reports: PropTypes.array,
  requestPending: PropTypes.bool,
  onExportReports: PropTypes.func,
  onFetchReports: PropTypes.func,
}

import { createAsyncAction } from "../../helpers/redux"

export const FETCH_RETAILERS = createAsyncAction("FETCH_RETAILERS")
export const fetchRetailers = brandId => ({
  type: FETCH_RETAILERS.REQUEST,
  payload: { brandId },
})

export const SELECT_RETAILER_BY_ID = "SELECT_RETAILER_BY_ID"
export const selectRetailerMember = id => ({
  type: SELECT_RETAILER_BY_ID,
  payload: {
    id,
  },
})

export const ADD_RETAILER = createAsyncAction("ADD_RETAILER")
export const addRetailer = data => ({
  type: ADD_RETAILER.REQUEST,
  payload: {
    data,
  },
})

export const DELETE_RETAILER = createAsyncAction("DELETE_RETAILER")
export const deleteRetailer = (retailerId, buyersIds) => ({
  type: DELETE_RETAILER.REQUEST,
  payload: {
    retailerId,
    buyersIds,
  },
})

export const UPDATE_RETAILER = createAsyncAction("UPDATE_RETAILER")
export const updateRetailer = (data, id) => ({
  type: UPDATE_RETAILER.REQUEST,
  payload: {
    data,
    id,
  },
})

export const UNSELECT_RETAILER = "UNSELECT_RETAILER"
export const unselectRetailerMember = () => ({
  type: UNSELECT_RETAILER,
})

export const FETCH_SHARED_RETAILERS = createAsyncAction("FETCH_SHARED_RETAILERS")
export const fetchSharedRetailers = () => ({
  type: FETCH_SHARED_RETAILERS.REQUEST,
})
export const ADD_RETAILER_BY_SRETAILER_ID = createAsyncAction("ADD_RETAILER_BY_SRETAILER_ID")
export const addRetailerBySharedRetailer = payload => ({
  type: ADD_RETAILER_BY_SRETAILER_ID.REQUEST,
  payload,
})

export const FETCH_CITIES = createAsyncAction("FETCH_CITIES")
export const fetchCities = payload => ({
  type: FETCH_CITIES.REQUEST,
  payload,
})

import styled from "styled-components"

export const Filter = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  min-height: 40px;

  & .filterSelect {
    width: 100%;
  }
`

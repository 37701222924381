import { gql } from "@apollo/client"

import AreaFragments from "./area.fragments"
import RemainingAreaFragments from "./remainingArea.fragments"

export const FRAGMENT_SALES_SESSION_AREAS = gql`
  fragment SalesSessionAreas on SalesSession {
    areas {
      ...AreaStaffs
      ...AreaTerritory
      id
      name
      color
      notificationMeetingConfirmation
      notificationMeetingReminder
    }
  }
  ${AreaFragments.fragments.staffs}
  ${AreaFragments.fragments.territory}
  ${RemainingAreaFragments.fragments.staffs}
`

const SalesSessionFragments = {
  fragments: {
    areas: FRAGMENT_SALES_SESSION_AREAS,
  },
}

export default SalesSessionFragments

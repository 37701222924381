import React, { useEffect, useState } from "react"
import { ApolloProvider } from "@apollo/client"
import { ToastContainer } from "react-toastify"

import GlobalStyle from "../../constants/globalStyle"
import "./styles.css"
import setupApolloClient from "../../graphql/graphqlClient"
import { loadGoogleMapsApi } from "../../helpers/googleMapsApi"
import loading from "../../static/loading.svg"

import { Routes } from "./Routes"

const App = () => {
  const [apolloClient, setApolloClient] = useState(null)
  useEffect(() => {
    const getApolloClient = async () => {
      const newClient = await setupApolloClient()
      setApolloClient(newClient)
    }
    getApolloClient()
    loadGoogleMapsApi()
    window.$crisp = []
    window.CRISP_WEBSITE_ID = "c6a02827-001f-4560-ad18-062fc2956ad0"
    ;(function() {
      const d = document
      const s = d.createElement("script")

      s.src = "https://client.crisp.chat/l.js"
      s.async = 1
      d.getElementsByTagName("head")[0].appendChild(s)
    })()
    window.$crisp.push(["safe", true])
  }, [])

  if (!apolloClient) {
    return (
      <div className="loading-container">
        <img alt="loading" className="loading-circle" src={loading} />
      </div>
    )
  }
  return (
    <ApolloProvider client={apolloClient}>
      <GlobalStyle />
      <Routes />
      <ToastContainer className={"custom-toast-container"} />
    </ApolloProvider>
  )
}

export default App

import React, { memo } from "react"
import Dropdown, { DropdownContent, DropdownTrigger } from "react-simple-dropdown"

import { ActionButton, ActionIcon, ContainerActions } from "./styles"

type Props = {
  disabled: boolean,
  className: string,
  actions?: [
    {
      label: string,
      onClick: Function,
    },
  ],
}

const DropdownActions = ({ disabled, className, actions }: Props) => (
  <span className={className}>
    <Dropdown disabled={disabled}>
      <DropdownTrigger>
        <ActionIcon disabled={disabled} />
      </DropdownTrigger>
      <DropdownContent>
        <ContainerActions>
          {actions.map(({ label, onClick }) => (
            <ActionButton key={label} onClick={onClick}>
              {label}
            </ActionButton>
          ))}
        </ContainerActions>
      </DropdownContent>
    </Dropdown>
  </span>
)

DropdownActions.defaultProps = {
  actions: [],
}
export default memo(DropdownActions)

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { addAttachment, deleteAttachment, fetchSaleSessionReport } from "../../../../../store/actions/salesSessions"
import {
  getAddAttachementRequestError,
  getAddAttachementRequestStatus,
  getBrand,
  getDeleteAttachementRequestError,
  getDeleteAttachementRequestStatus,
  getUserData,
} from "../../../../../store/selectors"

import Component from "./ActiveSessions"

const selector = createStructuredSelector({
  user: getUserData,
  brand: getBrand,
  requestPending: getAddAttachementRequestStatus,
  requestError: getAddAttachementRequestError,
  addAttachementRequestStatus: getAddAttachementRequestStatus,
  addAttachementRequestError: getAddAttachementRequestError,
  deleteAttachementRequestStatus: getDeleteAttachementRequestStatus,
  deleteAttachementRequestError: getDeleteAttachementRequestError,
})

const actions = {
  onDeleteAttachment: deleteAttachment,
  onExportReports: fetchSaleSessionReport,
  onAddFile: addAttachment,
}

export const ActiveSessions = connect(selector, actions)(Component)

import React, { createContext, useReducer } from "react"

export const CalendarsContext = createContext()

const calendarsReducer = (state, action) => {
  switch (action.type) {
    case "setHoverRelouRequest": {
      return {
        ...state,
        hoverRelouRequest: action.relouRequest,
      }
    }
    case "setSelectedRelouRequest": {
      return {
        ...state,
        selectedRelouRequest: action.relouRequest,
      }
    }
    default:
      return state
  }
}

const initialState = {
  hoverRelouRequest: null,
  selectedRelouRequest: null,
}

type Props = {
  children: any,
}
const CalendarsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(calendarsReducer, initialState)
  const providerValue = {
    state,
    dispatch,
  }
  return <CalendarsContext.Provider value={providerValue}>{children}</CalendarsContext.Provider>
}

export default CalendarsProvider

import styled from "styled-components"
import { VerticalThreeDotsIcon } from "modaresa-commons/dist/components/icons"

export const ActionIcon = styled(VerticalThreeDotsIcon)`
  font-size: 20px;
  ${p =>
    p.disabled
      ? `
    color: ${p.theme.disabled};
  `
      : `
    color: ${p.theme.black};
    cursor: pointer;
  `}
`

export const ActionButton = styled.div`
  display: block;
  padding: 8px 8px;
  transition: 0.2s background-color ease-in;
  cursor: pointer;
  &:hover {
    background: ${p => p.theme.gray};
  }
  width: 100%;
`

export const ContainerActions = styled.div`
  background-color: white;
  width: 150px;
  border: 1px solid ${p => p.theme.gray};
  border-radius: 3px;
`

import React, { useCallback, useState } from "react"
import { Section } from "modaresa-commons"
import styled from "styled-components"

import FormFieldLayout from "../../FormFieldLayout/FormFieldLayout"
import SalesSessionPicker from "../../SalesSessionPicker"

import StaffAvailabilities from "./StaffAvailabilities"

const ContainerForm = styled.div`
  padding: 30px;
  max-width: 872px;
  width: 100%;
`

type Props = {
  staff: {
    brand: {
      id: string,
    },
  },
  readOnly: boolean,
}

const labelWidth = "192px"
const Availabilities = ({ staff, readOnly }: Props) => {
  const [salesSession, setSalesSession] = useState(null)
  const handleSelectSalesSession = useCallback(selectedSalesSession => setSalesSession(selectedSalesSession), [])
  const salesSessionStatuses = ["active"]

  return (
    <div>
      <Section title="Staff member suddenly unavailable during a sales session/market?">
        Appointments where the user has already been allocated on a given day must be manually changed from that user’s
        Calendar (Staff-View) before being able to turn off the availability on that given day.
      </Section>
      <ContainerForm>
        <FormFieldLayout label="Sales Session" labelWidth={labelWidth} name="salesSession">
          <SalesSessionPicker
            brandId={staff.brand.id}
            statuses={salesSessionStatuses}
            selectFirstValue
            onChange={handleSelectSalesSession}
            value={salesSession}
          />
        </FormFieldLayout>
        {salesSession && (
          <StaffAvailabilities
            salesSessionId={salesSession.id}
            staffId={staff.id}
            labelWidth={labelWidth}
            readOnly={readOnly}
          />
        )}
      </ContainerForm>
    </div>
  )
}

export default Availabilities

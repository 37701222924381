import { connect } from "react-redux"

import { unselectSession } from "../../../../../store/actions/salesSessions"

import Component from "./SessionsLayout"

const actions = {
  onUnselectSession: unselectSession,
}

export const SessionsLayout = connect(() => ({}), actions)(Component)

import axios from "axios"

import { REST_API_URL } from "../../config"

export const updateBrand = async ({ data, id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(`${REST_API_URL}/brands/${id}`, data, config)

  return response.data
}

export const updateCompetitorGroup = async ({ data, token, id }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(`${REST_API_URL}/brands/${id}/competitors`, data, config)

  return response.data
}

export const addBrand = async ({ data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/brands`, data, config)

  return response.data
}

import { Formik, Form } from "formik"
import React, { memo, useState } from "react"
import { Button } from "modaresa-commons"

import { notificationFrequencyOptions } from "../../../../config/notifications"
import FormFieldLayout from "../../FormFieldLayout/FormFieldLayout"

import FieldNotification from "./FieldNotification"
import { notificationsFields, notificationsSchema } from "./FormNotifications.fields"

const labelWidth = "192px"

type Props = {
  notifications: {},
  onSubmit: {},
  loadingSubmit: boolean,
}

const initNotificationField = ({ active, frequency }) => ({
  active,
  frequency: notificationFrequencyOptions.find(opt => opt.value === frequency),
})
const FormNotifications = ({ notifications, onSubmit, loadingSubmit }: Props) => {
  const [initialValues] = useState(() => ({
    meetingCheckin: initNotificationField(
      notifications.meetingCheckin || {
        active: true,
        frequency: "daily",
      },
    ),
    meetingBooked: initNotificationField(
      notifications.meetingBooked || {
        active: true,
        frequency: null,
      },
    ),
    meetingCancelled: initNotificationField(
      notifications.meetingCancelled || {
        active: true,
        frequency: null,
      },
    ),
    meetingChanged: initNotificationField(
      notifications.meetingChanged || {
        active: true,
        frequency: null,
      },
    ),
    autoMorningRecap: initNotificationField(
      notifications.autoMorningRecap || {
        active: true,
        frequency: "daily",
      },
    ),
    offScheduleRequest: initNotificationField(
      notifications.offScheduleRequest || {
        active: true,
        frequency: null,
      },
    ),
  }))
  return (
    <Formik initialValues={initialValues} validateOnBlur validationSchema={notificationsSchema} onSubmit={onSubmit}>
      {() => (
        <Form>
          {notificationsFields.map(notificationField => (
            <FieldNotification key={notificationField.name} labelWidth={labelWidth} notification={notificationField} />
          ))}
          <FormFieldLayout label=" " labelWidth={labelWidth}>
            <Button minWidth="100%" type="submit" disabled={loadingSubmit}>
              Save changes
            </Button>
          </FormFieldLayout>
        </Form>
      )}
    </Formik>
  )
}

export default memo(FormNotifications)

import { useQuery } from "@apollo/client"
import React, { memo } from "react"

import { GET_STAFF_CURRENT_AREAS_ASSIGNMENTS } from "../../../graphql/queries/staff"

import ModalConfirmDeleteStaffView from "./ModalConfirmDeleteStaffView"

type Props = {
  staff: {
    id: string,
  },
  onClose: Function,
  onConfirmDelete: Function,
  isLoadingDeletion: boolean,
}

const ModalConfirmDeleteStaff = ({ staff, onClose, onConfirmDelete, isLoadingDeletion }: Props) => {
  const { loading, error, data } = useQuery(GET_STAFF_CURRENT_AREAS_ASSIGNMENTS, {
    variables: { staffId: staff.id },
  })
  if (loading) return <ModalConfirmDeleteStaffView loading />
  if (error)
    return <ModalConfirmDeleteStaffView error="Error while loading current staff assignments" onClose={onClose} />
  const { getStaffCurrentAreasAssignments } = data
  const { settingsAreas, settingsRemainingArea, settingsRooms, activeSessions } = getStaffCurrentAreasAssignments
  const assignments =
    (settingsAreas.length > 0 || settingsRemainingArea || settingsRooms.length > 0 || activeSessions.length > 0) &&
    getStaffCurrentAreasAssignments
  return (
    <ModalConfirmDeleteStaffView
      staff={staff}
      onClose={onClose}
      onConfirmDelete={onConfirmDelete}
      assignments={assignments}
      loading={isLoadingDeletion}
    />
  )
}

export default memo(ModalConfirmDeleteStaff)

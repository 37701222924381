import { useQuery } from "@apollo/client"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { Loading } from "modaresa-commons"

import { ME_LOGGED_STAFF_AVAILABLE_SESSIONS } from "../../../../../graphql/queries/user"
import PageTitle from "../../../../elements/PageTitle/PageTitle"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"
import { SessionList } from "../SessionList"

const ArchivedSessions = ({ match, onExportReports, brand }) => {
  const { data, error, loading } = useQuery(ME_LOGGED_STAFF_AVAILABLE_SESSIONS, {
    variables: {
      sessionStatus: "active",
      sessionIsPast: true,
    },
  })
  const sessions = useMemo(() => data?.me.staff.availableSessions || [], [data])
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading active sessions</ErrorLiveChatHelp>
  if (loading && !data) return <Loading />
  return (
    <PageTitle title="Archived sessions">
      <SessionList
        match={match}
        offices={brand.offices}
        sessions={sessions}
        type="archived"
        onExportReports={onExportReports}
      />
    </PageTitle>
  )
}

ArchivedSessions.propTypes = {
  brand: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  onExportReports: PropTypes.func,
}

export default ArchivedSessions

import { useField } from "formik"
import React, { useCallback, memo } from "react"
import { Input } from "modaresa-commons"

import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

type Props = {
  label: string,
  labelWidth: string,
  name: string,
  direction?: "row" | "column",
  onChange: Function,
  disabled: boolean,
  helpText: string,
  placeholder: string,
  inputType: string,
  inputAutocomplete: string,
}

const FormikInput = ({
  onChange,
  label,
  labelWidth,
  name,
  direction,
  disabled,
  helpText,
  placeholder,
  inputType,
  inputAutocomplete,
}: Props) => {
  const [field, meta] = useField(name)
  const handleChange = useCallback(
    e => {
      field.onChange(e)
      if (onChange) {
        onChange(e)
      }
    },
    [onChange, field],
  )
  return (
    <FormFieldLayout
      label={label}
      labelWidth={labelWidth}
      name={name}
      direction={direction}
      error={meta.touched && meta.error}
      helpText={helpText}
    >
      <Input
        id={name}
        onChange={handleChange}
        onBlur={field.onBlur}
        value={field.value}
        disabled={disabled}
        placeholder={placeholder}
        type={inputType}
        autocomplete={inputAutocomplete}
      />
    </FormFieldLayout>
  )
}

FormikInput.defaultProps = {
  direction: "row",
  inputType: "text",
}
export default memo(FormikInput)

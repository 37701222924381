import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
  padding-top: 0px;
  position: relative;
  box-sizing: border-box;

  flex: 1;
  overflow-y: auto;

  & .form-check label {
    margin-bottom: 0px;
  }

  & .form-check {
    height: 25px;
    display: flex;
    align-items: center;
  }

  & .form-check input {
    height: 20px;
    min-width: 20px;
    margin-top: 0;
    margin-right: 10px;
  }

  & .roomTable .border {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  & .roomTable img {
    margin: 0;
    margin-left: 10px;
  }

  & .roomTable img.no_margin {
    margin: 0;
  }

  & .roomTable input[type="checkbox"] {
    margin: 0;
  }
`

export const Content = styled.div`
  padding-bottom: 10px;
  padding: 0 30px 100px 30px;
`

export const Divinder = styled.div`
  display: flex;
  align-items: flex-end;
  height: 60px;
  min-height: 60px;
  padding-bottom: 10px;
  padding-left: 30px;
  margin-bottom: 25px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  font-weight: 600;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
export const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: white;
  padding: 5px 10px 5px 10px;
`
export const ErrorText = styled.div`
  color: ${props => props.theme.primary};
`
export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 200px;
  right: 0;
  min-height: 37px;
  padding: 10px 60px 30px 30px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const Icon = styled.img`
  display: inline-block;
  margin-left: 30px;
  width: ${props => props.width};
  height: ${props => props.height};
`

export const DeleteIcon = styled.img`
  display: block;
  height: 18px;
  width: auto;
`

export const Bold = styled.span`
  font-weight: bold;
`
export const ModalBody = styled.div`
  & .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255);
  }
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`
export const CloseModal = styled.span`
  position: absolute;
  color: ${props => props.theme.strawberry};
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
`

import styled from "styled-components"

export const Clients = styled.div`
  padding: 15px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.gray50};
  margin-bottom: 20px;
`
export const ClientsList = styled.div``
export const ClientItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 10px 7px;
  border-radius: 4px;
  background-color: ${props => props.theme.gray50};
  margin-bottom: 7px;

  & p {
    margin: 0;
  }
`
export const InfoText = styled.p``
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const Container = styled.div`
  width: 100%;
  padding-top: 54px;
  display: flex;
  flex-direction: column;
  background-color: purple;

  & .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255);
  }

  & .dropdown__content {
    top: calc (100% + 20px);
    background: #fff;
    width: 200px;
    padding: 20px;
    border: 1px solid #f5f5f5;

    & button {
      height: 25px;
      margin-top: 10px;
      padding: 0 15px;
    }
  }
`

export const Bold = styled.div`
  font-weight: bold;
`

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`

export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`
export const CloseModal = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.strawberry};
`

export const Text = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`
export const ModalLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const VideoURLHolder = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.gray50};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  margin-bottom: 20px;
  > div > a {
    text-decoration: none;
  }
`

import { useMutation, useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import React, { memo, useCallback, useMemo, useState } from "react"
import { AlertDanger } from "modaresa-commons"

import { UPDATE_SALES_SESSION_ROOM } from "../../../../../graphql/mutations/room"
import { SALES_SESSION_ROOM } from "../../../../../graphql/queries/room"
import { getGraphQLErrorAsString } from "../../../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"

import RoomView from "./RoomView"

type Props = {
  brandId: string,
  salesSessionId: string,
  salesSessionName: string,
  canEdit: boolean,
}
const Room = ({ brandId, salesSessionId, salesSessionName, canEdit }: Props) => {
  const [isLoading, setIsloading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [updateSalesSessionRoom] = useMutation(UPDATE_SALES_SESSION_ROOM)
  const { roomId } = useParams()
  const handleSubmitFormRoom = useCallback(
    async values => {
      setIsloading(true)
      const variables = {
        salesSessionId,
        salesSessionRoomInput: {
          id: roomId,
          hasBlockingList: values.hasBlockingList,
          typeBlockingList: values.typeBlockingList,
          retailersIds: values.retailers.map(retailer => retailer.id),
        },
      }
      try {
        await updateSalesSessionRoom({ variables })
        onToastSuccess(`Room settings updated for ${salesSessionName}`)
      } catch (e) {
        onToastError("An error occured while updating room." + getGraphQLErrorAsString(e))
      }
      setIsloading(false)
    },
    [roomId, updateSalesSessionRoom, salesSessionId, salesSessionName],
  )
  const urlGoBack = useMemo(() => `/sessions/${salesSessionId}/rooms`, [salesSessionId])
  const { loading, error, data } = useQuery(SALES_SESSION_ROOM, {
    variables: { salesSessionId, roomId },
    onCompleted: () => {
      setInitialLoading(true)
    },
  })
  if (loading && !initialLoading) return <RoomView skeleton />
  if (error)
    return (
      <ErrorLiveChatHelp technicalError={error}>An error occured while loading sales session room</ErrorLiveChatHelp>
    )
  const { getSalesSessionRoom: room } = data
  if (!room) {
    return <AlertDanger>Sales session room doesn't exist or has been deleted</AlertDanger>
  }
  return (
    <RoomView
      brandId={brandId}
      room={room}
      canEdit={canEdit}
      onSubmit={handleSubmitFormRoom}
      loadingSubmit={isLoading}
      urlGoBack={urlGoBack}
    />
  )
}

export default memo(Room)

import usePlacesAutocomplete from "use-places-autocomplete"
import React, { useMemo, memo, useCallback, useEffect, useState } from "react"
import Select from "react-select"

import generateAreasAndTerritoriesOptions, {
  getCountriesOptionsWithoutExclusions,
  getTerritoriesOptionsFromGoogleSuggestions,
} from "../../../helpers/areasAndTerritories/generateAreasAndTerritoriesOptions"
import { loadGoogleMapsApi } from "../../../helpers/googleMapsApi"

type Props = {
  multiple: boolean,
  placeholder: string,
  disabled: boolean,
  onChange: Function,
  defaultValue: any,
  value: any,
  options: [],
  retailers: [],
  excludeCountries: [],
}

const TerritoryPickerSelect = ({
  multiple,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
  retailers,
  options,
  excludeCountries,
}: Props) => {
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue)
  const { suggestions, setValue: setPlacesAutoCompleteValue } = usePlacesAutocomplete({
    debounce: 500,
    callbackName: "initMap",
    requestOptions: {
      types: ["(regions)"],
    },
  })

  const handleChangeSelectInput = useCallback(inputValue => setPlacesAutoCompleteValue(inputValue), [
    setPlacesAutoCompleteValue,
  ])
  const handleSelectAction = useCallback((selectedOptions, { action, option }, excludeCountries) => {
    switch (action) {
      case "select-option":
        if (option?.value === "all") {
          const remainingCountriesOpts = getCountriesOptionsWithoutExclusions(excludeCountries)
          return selectedOptions.filter(opt => opt.value !== "all").concat(remainingCountriesOpts)
        }
        return selectedOptions
      case "clear":
        return []
      case "remove-value":
      case "pop-value":
      default:
        return selectedOptions || []
    }
  }, [])
  const handleTerritoryChange = useCallback(
    (selectedOptions, selectedOption) => {
      const newSelectedOptions = handleSelectAction(selectedOptions, selectedOption, excludeCountries)
      if (value === undefined) {
        setUncontrolledValue(newSelectedOptions)
      }
      onChange(newSelectedOptions)
    },
    [value, onChange, handleSelectAction, excludeCountries],
  )

  useEffect(() => {
    loadGoogleMapsApi()
  }, [])

  const selectOptions = useMemo(() => {
    if (options) {
      return suggestions?.data?.length > 0
        ? options.concat(getTerritoriesOptionsFromGoogleSuggestions(suggestions.data))
        : options
    }
    const generatorParams = {
      includeAllCountriesOption: multiple,
      withCountries: true,
      withContinents: true,
      retailers,
      googleSuggestions: suggestions?.data,
      excludeCountries,
    }
    return generateAreasAndTerritoriesOptions(generatorParams)
  }, [multiple, options, excludeCountries, retailers, suggestions])
  const selectValue = value === undefined ? uncontrolledValue : value
  return (
    <Select
      value={selectValue}
      isDisabled={disabled}
      options={selectOptions}
      isLoading={suggestions.loading}
      isMulti={multiple}
      placeholder={placeholder}
      onChange={handleTerritoryChange}
      onInputChange={handleChangeSelectInput}
    />
  )
}

TerritoryPickerSelect.defaultProps = {
  placeholder: "Select territory",
  multiple: false,
  defaultValue: undefined,
  // eslint-disable-next-line
  onChange: () => {},
}
export default memo(TerritoryPickerSelect)

import { gql } from "@apollo/client"

export const UPDATE_BUYER_STATUS = gql`
  mutation UpdateBuyerStatus($id: ID!, $status: DB_STATUS, $meetingCancellationReason: String) {
    updateBuyerStatus(id: $id, status: $status, meetingCancellationReason: $meetingCancellationReason) {
      id
      status
    }
  }
`

export const RESTORE_BUYER = gql`
  mutation RestoreBuyer($id: ID!) {
    restoreBuyer(id: $id) {
      id
      status
      retailer {
        id
        status
      }
    }
  }
`

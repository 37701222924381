import React, { useCallback, useState, memo } from "react"
import Tooltip from "rc-tooltip"
import { SwapIcon } from "modaresa-commons/dist/components/icons"

import { stopPropagation } from "../../../helpers/utils"

import ModalSwitchMeetingHost from "./ModalSwitchMeetingHost"

type Props = {
  bookingEventId: string,
}

const ButtonSwitchMeetingHost = ({ bookingEventId }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const handleClick = useCallback(event => {
    event.stopPropagation()
    setShowModal(true)
  }, [])
  const handleClose = useCallback(() => setShowModal(false), [])
  return (
    <>
      <Tooltip overlay="Meeting Swap" placement="top">
        <SwapIcon onClick={handleClick} />
      </Tooltip>
      {showModal && (
        <div onClick={stopPropagation}>
          <ModalSwitchMeetingHost bookingEventId={bookingEventId} onClose={handleClose} />
        </div>
      )}
    </>
  )
}

export default memo(ButtonSwitchMeetingHost)

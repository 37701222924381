import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const FormRow = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  margin-bottom: 17px;
  font-size: 15px;
  min-height: 40px;
  color: ${props => props.theme.textBlack};

  & > span {
    min-width: 140px;
  }

  & .BuyerMultiselect {
    min-width: 200px;
  }

  & .react-tel-input .selected-flag .arrow {
    display: none;
  }
`

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;
`

export const Bold = styled.span`
  font-weight: bold;
`
export const Text = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`

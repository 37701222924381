import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  padding: 0 0 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Content = styled.div`
  overflow-y: auto;
  padding: 24px;
`

export const Description = styled.div`
  padding: 12px;
`

export const ContainerRestrict = styled.div`
  margin: 14px;
  width: 40%;
  min-width: 500px;
`

export const RestrictionBlock = styled.div`
  margin: 16px 0;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 16px;
`

export const RestrictionHeader = styled.div`
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  font-size: 16px;
  line-height: 42px;
`

export const RestrictionTitle = styled.span`
  margin-left: 12px;
`

export const RestrictionActions = styled.div`
  float: right;
  width: 80px;
  display: inline-grid;
  grid-auto-flow: column;
`

export const RestrictRule = styled.div`
  margin: 14px 0;
`

export const Area = styled.span`
  margin: 0 6px;
  font-weight: bold;
`

export const Type = styled.span`
  margin-right: 6px;
  font-style: oblique;
`

export const Room = styled.span`
  margin: 0 1px;
`

export const TimeRange = styled.span`
  font-weight: bold;
  margin: 0 6px;
`

export const meetingTypes = [
  { value: "businessMeeting", label: "Business Meeting" },
  { value: "walkthrough", label: "Walkthrough" },
  { value: "buyingAppointment", label: "Buying Appointment" },
]

export const meetingTypesValues = {
  businessMeeting: "Business Meeting",
  walkthrough: "Walkthrough",
  buyingAppointment: "Buying Appointment",
}

export const meetingApproachValues = {
  "face-to-face": "Face-to-Face",
  "video-conference": "Virtual Meeting",
}

export const meetingApproaches = Object.keys(meetingApproachValues)

export const meetingApproachItems = meetingApproaches.map(approach => ({
  value: approach,
  label: meetingApproachValues[approach],
}))

export const markets = [
  { value: "men", label: "Men" },
  { value: "women", label: "Women" },
  { value: "womenMen", label: "Men & Women" },
]

export const marketsByValue = {
  men: "Men",
  women: "Women",
  womenMen: "Men & Women",
}

export const priorityGroupList = [
  { value: 1, label: "Priority group 1" },
  { value: 2, label: "Priority group 2" },
  { value: 3, label: "Priority group 3" },
]

export const statusList = [
  { value: "stockist", label: "Stockist" },
  { value: "prospect", label: "Prospect" },
]

export const inviteStatuses = {
  invited: { label: "Invited", color: "#37475d", value: "invited" },
  booked: { label: "Booked", color: "#45cea2", value: "booked" },
  inviteOpened: { label: "Invite Opened", color: "#ffbf69", value: "inviteOpened" },
  joker: { label: "Relou Request", color: "#fc5d41", value: "joker" },
  followedUp: { label: "Followed Up", color: "#37475d", value: "followedUp" },
  cancelled: { label: "Cancelled", color: "#4a4a4a", value: "cancelled" },
  notInvited: { label: "Not Invited", color: "#9b9b9b", value: "notInvited" },
}

export const appointmentStatusOptions = [
  { value: "all", label: "All Appointments" },
  { value: "arrived", label: "Arrived" },
  { value: "delayed", label: "Delayed" },
  { value: "pending", label: "Pending" },
]

export const status = {
  confirm: { name: "Confirm", bg: "#516BEF" },
  pending: { name: "Review", bg: "#516BEF" },
  booked: { name: "Booked", bg: "#45cea2" },
  declined: { name: "Declined", bg: "#37475d" },
  nojoker: { name: "No Joker", bg: "#4a4a4a" },
}

export const videoToolNames = {
  whatsapp: "WhatsApp",
  facetime: "FaceTime",
  skype: "Skype",
  zoom: "Zoom",
  googlemeet: "Google Meet",
  msteams: "Microsoft Teams",
  whereby: "WhereBy",
}

import auth0 from "auth0-js"

import { AUTH_URL } from "../../../config"
import history from "../../../history"

// eslint-disable-next-line import/no-mutable-exports
export let auth = null

class Auth {
  auth0 = new auth0.WebAuth({
    domain: "modaresa.eu.auth0.com",
    clientID: "h7ic2KIuvEojwyTmhyHr86FWhFAogMeR",
    redirectUri: AUTH_URL,
    audience: "https://modaresa.eu.auth0.com/api/v2/",
    responseType: "token id_token",
    scope: "openid profile email read:users read:current_user",
  })

  login() {
    this.auth0.authorize({
      scope: "openid profile email read:users read:current_user",
    })
  }

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getUserId = this.getUserId.bind(this)
    this.isEmailVerified = this.isEmailVerified.bind(this)
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
        history.replace("/")
      } else if (err) {
        history.replace("/")
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  setSession(authResult) {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
    localStorage.setItem("access_token", authResult.accessToken)
    localStorage.setItem("id_token", authResult.idToken)
    localStorage.setItem("expires_at", expiresAt)
    localStorage.setItem("userId", authResult.idTokenPayload.sub)
    localStorage.setItem("email_verified", authResult.idTokenPayload.email_verified)
    // navigate to the home route
    history.replace("/")
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem("access_token")
    localStorage.removeItem("id_token")
    localStorage.removeItem("expires_at")
    localStorage.removeItem("userId")
    localStorage.removeItem("email_verified")
    // navigate to the home route
    history.replace("/")
    // eslint-disable-next-line
    auth = null
    window.location.reload()
  }

  // eslint-disable-next-line class-methods-use-this
  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"))
    return new Date().getTime() < expiresAt
  }

  // eslint-disable-next-line class-methods-use-this
  getUserId() {
    return localStorage.getItem("userId")
  }

  // eslint-disable-next-line class-methods-use-this
  isEmailVerified() {
    const email_verified = localStorage.getItem("email_verified")
    return email_verified === "true"
  }

  // eslint-disable-next-line class-methods-use-this
  getAccessToken() {
    return localStorage.getItem("access_token")
  }
}

export const initializeAuth = () => {
  auth = new Auth()
}

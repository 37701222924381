import React, { memo, useCallback } from "react"
import Tooltip from "rc-tooltip"
import { Column, Table } from "react-virtualized"
import styled from "styled-components"
import { WarningIcon } from "modaresa-commons/dist/components/icons"
import moment from "moment"

import { inviteStatuses, marketsByValue } from "../../../../../config"

import { Icon } from "components/ui"
import VideoCall from "static/videocall.svg"
import MeetingIcon from "static/meeting.svg"
import BookingIcon from "static/booking.svg"

export const StatusBlock = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  min-height: 35px;
  border-radius: 2px;
  line-height: 34px;
  background-color: ${props => props.bgColor || props.theme.secondary};
`
export const ColoredWarningIcon = styled(WarningIcon)`
  color: ${p => p.theme.warning};
  font-size: 22px;
`

export const BookingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
  min-height: 35px;
  border-radius: 2px;
  line-height: 34px;
`

export const BookingInfoItem = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: #1e1e1e;
  min-height: 35px;
  border-radius: 2px;
  line-height: 25px;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #f0f0f0;
`

export const BookingInfoItemContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  border-radius: 2px;
`

export const BookingTimeContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  justify-content: space-between;
`

type Props = {
  width: number,
  height: number,
  buyers: [],
  selectedBuyers: {},
  onRowClick: Function,
  onToggleSelectAll: Function,
}

const TableInviteClients = ({
  width,
  height,
  buyers,
  selectedBuyers,
  onRowClick,
  onToggleSelectAll,
  salesSessionBookings,
}: Props) => {
  const handleRowGetter = useCallback(({ index }) => buyers[index], [buyers])
  const handleRowClick = useCallback(
    ({ rowData: rowBuyer }) => {
      if (rowBuyer.isBookableToSession.isBookable) {
        onRowClick(rowBuyer, selectedBuyers[rowBuyer.id])
      }
    },
    [onRowClick, selectedBuyers],
  )
  const handleRowClassName = useCallback(
    ({ index }) => {
      const rowBuyer = buyers[index]
      if (rowBuyer && !rowBuyer.isBookableToSession.isBookable) {
        return "disabledRow"
      }
      return "evenRow"
    },
    [buyers],
  )
  const handleToggleSelectAll = useCallback(e => onToggleSelectAll(e.target.checked, buyers), [
    onToggleSelectAll,
    buyers,
  ])
  const handleRenderIsSelected = useCallback(
    ({ rowData: { id, isBookableToSession } }) => (
      <input
        type="checkbox"
        checked={selectedBuyers[id] || false}
        readOnly
        disabled={!isBookableToSession.isBookable}
      />
    ),
    [selectedBuyers],
  )
  const handleRenderCompanyName = useCallback(({ rowData: { retailer } }) => retailer?.name, [])
  const handleRenderStatus = useCallback(({ rowData: { invitation } }) => {
    const statusData = (invitation && inviteStatuses[invitation.status]) || inviteStatuses.notInvited
    return <StatusBlock bgColor={statusData.color}>{statusData.label}</StatusBlock>
  }, [])
  const handleRenderBuyerMarket = useCallback(({ rowData: { market } }) => marketsByValue[market], [])
  const handleRenderOrigin = useCallback(
    ({ rowData: { retailer } }) =>
      retailer?.storeAddress && `${retailer.storeAddress.city}, ${retailer.storeAddress.country.toUpperCase()}`,
    [],
  )
  const handleRenderIsBookableToSession = useCallback(({ rowData: { isBookableToSession } }) => {
    if (!isBookableToSession.isBookable || isBookableToSession.reason) {
      return (
        <Tooltip placement="left" overlay={isBookableToSession.reason}>
          <span>
            <ColoredWarningIcon />
          </span>
        </Tooltip>
      )
    }
    return null
  }, [])

  const handleSalesSessionBuyerBookings = useCallback(({ rowData }) => {
    if (!rowData.isBookableToSession.isBookable || rowData.isBookableToSession.reason) {
      const buyerBookings = salesSessionBookings.filter(booking =>
        booking.clients.some(client => client.id === rowData.id),
      )
      return (
        <Tooltip
          placement="left"
          overlay={() => (
            <BookingInfoContainer>
              {buyerBookings.length} {buyerBookings.length === 1 ? "Booking" : "Bookings"}
              <BookingInfoItemContainer>
                {buyerBookings.map(booking => (
                  <BookingInfoItem key={booking.id}>
                    <div>{booking.room}</div>
                    <BookingTimeContainer>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Icon
                          icon={(booking.meetingApproach === "face-to-face" && MeetingIcon) || VideoCall}
                          margin="0 4px 0 0"
                          width="20px"
                        />
                        {moment.utc(booking.startTime, "X").format("LL")}
                      </div>
                      <div>{`${moment.utc(booking.startTime, "X").format("H:mm")} - ${moment
                        .utc(booking.endTime, "X")
                        .format("H:mm")}`}</div>
                    </BookingTimeContainer>
                    <div>
                      {booking.clients.length > 1 &&
                        "Group booking with ( " +
                          booking.clients
                            .map(client => buyers.filter(buyer => buyer.id === client.id)[0].fullName.trim())
                            .join(", ") +
                          " )"}
                    </div>
                  </BookingInfoItem>
                ))}
              </BookingInfoItemContainer>
            </BookingInfoContainer>
          )}
        >
          <span>
            <Icon icon={BookingIcon} margin="0 8px 0 0" width="20px" />{" "}
          </span>
        </Tooltip>
      )
    }
    return null
  }, [])
  return (
    <Table
      className="Buyers-Table"
      gridClassName="Buyers-Table__grid"
      headerClassName="Buyers-Table__header"
      headerHeight={60}
      height={height}
      rowClassName={handleRowClassName}
      rowCount={buyers.length}
      rowGetter={handleRowGetter}
      rowHeight={50}
      width={width}
      onRowClick={handleRowClick}
    >
      <Column
        dataKey="check"
        label={<input type="checkbox" onClick={handleToggleSelectAll} />}
        width={30}
        cellRenderer={handleRenderIsSelected}
      />
      <Column dataKey="retailer.name" label="Company" width={250} cellRenderer={handleRenderCompanyName} />
      <Column dataKey="fullName" label="Name" width={250} />
      <Column dataKey="status" label="Status" width={180} cellRenderer={handleRenderStatus} />
      <Column dataKey="position" label="Position" width={180} />
      <Column dataKey="market" label="market" width={150} cellRenderer={handleRenderBuyerMarket} />
      <Column dataKey="email" label="E-mail" width={350} />
      <Column cellRenderer={handleRenderOrigin} dataKey="origin" label="Origin" width={150} />
      <Column
        cellRenderer={handleRenderIsBookableToSession}
        dataKey="isBookableToSession.isBookable"
        label=""
        width={30}
      />
      <Column
        cellRenderer={handleSalesSessionBuyerBookings}
        dataKey="isBookableToSession.isBookable"
        label=""
        width={30}
      />
    </Table>
  )
}

TableInviteClients.defaultProps = {
  buyers: [],
}
export default memo(TableInviteClients)

import * as R from "ramda"
import { createSelector } from "reselect"
import moment from "moment-timezone"

import { getUserData } from "store/selectors/user"

export const getSaleSessions = R.path(["salesSessions", "salesSessions"])
export const getSelectedSessionId = R.path(["salesSessions", "selectedSessionId"])

export const getSessionRequestStatus = R.path(["salesSessions", "requestPending"])
export const getSessionRequestError = R.path(["salesSessions", "requestError"])
export const getInviteRequestStatus = R.path(["salesSessions", "inviteRequestPending"])
export const getFollowUpRequestStatus = R.path(["salesSessions", "followUpRequestPending"])
export const getInviteRequestError = R.path(["salesSessions", "inviteError"])

export const getCapacityRequestStatus = R.path(["salesSessions", "requestCapacityPending"])
export const getCapacityRequestError = R.path(["salesSessions", "requestCapacityError"])

export const getAddAttachementRequestStatus = R.path(["salesSessions", "AddAttachementRequestPending"])
export const getAddAttachementRequestError = R.path(["salesSessions", "AddAttachementRequestError"])

export const getDeleteAttachementRequestStatus = R.path(["salesSessions", "deleteAttachementRequestPending"])
export const getDeleteAttachementRequestError = R.path(["salesSessions", "deleteAttachementRequestError"])

export const getSelectedSession = R.path(["salesSessions", "selectedSession"])
export const getSessionCapacity = R.path(["salesSessions", "selectedSessionCapacity"])

export const getFetchedTimezone = R.path(["salesSessions", "timezone"])

export const getDraftSessions = createSelector([getSaleSessions], sessions => {
  const filtered = []
  sessions.allIds.forEach(id => {
    const session = sessions.byId[id]
    if (session.status === "draft") filtered.push(session)
  })
  return filtered
})

export const getSortedSessions = createSelector([getSaleSessions], sessions => {
  const filtered = []
  sessions.allIds.forEach(id => {
    const session = sessions.byId[id]
    if (session.status === "active") filtered.push(session)
  })

  const sortedSession = filtered.sort((sessionA, sessionB) => {
    if (sessionA.openingDay > sessionB.openingDay) return -1
    if (sessionA.openingDay < sessionB.openingDay) return 1
    return 0
  })

  return sortedSession
})

export const getActiveSessions = createSelector([getSortedSessions, getUserData], (sessions, user) => {
  const activeSessions = []
  sessions.forEach(session => {
    const lastDay = moment.utc(session.closingDay, "X")
    const today = moment.utc()
    if (lastDay.isSameOrAfter(today, "day")) {
      if (user.modaresaRole === "agent" && session.staff.some(staff => staff.staffId === user._id)) {
        activeSessions.push(session)
      }
      if (user.modaresaRole !== "agent") {
        activeSessions.push(session)
      }
    }
  })
  return activeSessions
})

export const getArchivedSessions = createSelector([getSortedSessions, getUserData], (sessions, user) => {
  const archivedSession = []
  sessions.forEach(session => {
    const lastDay = moment.utc(session.closingDay, "X")
    const today = moment.utc()
    if (lastDay.isBefore(today, "day")) {
      if (user.modaresaRole === "agent" && session.staff.some(staff => staff.staffId === user._id)) {
        archivedSession.push(session)
      }
      if (user.modaresaRole !== "agent") {
        archivedSession.push(session)
      }
    }
  })
  return archivedSession
})

export const getSessionOptions = createSelector(
  [getActiveSessions, getArchivedSessions],
  (activeSessions, archivedSessions) => {
    const activeSession = activeSessions.map(session => ({ value: session._id, label: session.name })).reverse()
    const archivedSession = archivedSessions.map(session => ({
      value: session._id,
      label: session.name,
    }))

    const sessionOptions = [
      { label: "Active", options: activeSession },
      { label: "Archived", options: archivedSession },
    ]

    return sessionOptions
  },
)

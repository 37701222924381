import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  deleteBooking,
  fetchSchedule,
  leaveSocketRoom,
  reserveBooking,
  reserveJokerBooking,
  selectBooking,
  unselectBooking,
  unselectShedule,
} from "../../../store/actions/schedules"
import {
  getAttendanceSchedule,
  getClients,
  getClientsRequestStatus,
  getFilteredSchedule,
  getRetailers,
  getRetailersRequestPending,
  getScheduleRequestError,
  getScheduleRequestStatus,
  getSelectedBooking,
  getSelectedSession,
  getSessionOptions,
  getSortedSessions,
  getStaff,
  getStaffCalendarSchedule,
  getStaffRequestPending,
  getUserData,
} from "../../../store/selectors"

import Component from "./Calendars"

import { fetchClients } from "store/actions/clients"
import { fetchRetailers } from "store/actions/retailers"
import { fetchStaffs } from "store/actions/staff"

const selector = createStructuredSelector({
  sessions: getSortedSessions,
  selectedBooking: getSelectedBooking,
  selectedSchedule: getFilteredSchedule,
  selectedSession: getSelectedSession,
  attendanceSchedule: getAttendanceSchedule,
  staff: getStaff,
  staffCalendarSchedule: getStaffCalendarSchedule,
  user: getUserData,
  scheduleRequestStatus: getScheduleRequestStatus,
  scheduleRequestError: getScheduleRequestError,
  sessionOptions: getSessionOptions,
  retailers: getRetailers,
  clients: getClients,
  staffsRequestPending: getStaffRequestPending,
  retailersRequestPending: getRetailersRequestPending,
  clientsRequestPending: getClientsRequestStatus,
})

const actions = {
  onDeleteBooking: deleteBooking,
  onReserveBookingSlot: reserveBooking,
  onReserveJokerBookingSlot: reserveJokerBooking,
  onFetchSchedule: fetchSchedule,
  onFetchClients: fetchClients,
  onFetchStaffs: fetchStaffs,
  onFetchRetailers: fetchRetailers,
  onLeaveSocketRoom: leaveSocketRoom,
  onSelectBooking: selectBooking,
  onUnselectBooking: unselectBooking,
  onUnselectSchedule: unselectShedule,
}

export const Calendars = connect(selector, actions)(Component)

import omitBy from "lodash/omitBy"

import {
  ACTIVATE_SESSION,
  ADD_ATTACHMENT,
  CREATE_SESSION,
  DELETE_ATTACHMENT,
  DELETE_SESSION,
  FETCH_CAPACITY,
  FETCH_SALE_SESSION,
  FETCH_SALE_SESSIONS,
  FETCH_SALE_SESSION_REPORTS,
  FETCH_TIMEZONE,
  SELECT_SESSION,
  UNSELECT_SESSION,
  UPDATE_AVAILABILITIES,
  UPDATE_SESSION,
} from "../actions/salesSessions"
import { INVITE_CLIENTS, SEND_FOLLOWUPS } from "../actions/invites"
import { FETCH_SCHEDULE } from "../actions/schedules"
import { createReducer } from "../..//helpers/redux"

const initialState = {
  salesSessions: {},
  selectedSessionId: null,
  selectedSession: null,
  requestPending: false,
  requestError: null,
  inviteRequestPending: false,
  followUpRequestPending: false,
  inviteError: null,
  selectedSessionCapacity: null,
  requestCapacityPending: false,
  requestCapacityError: null,
  AddAttachementRequestPending: false,
  AddAttachementRequestError: null,
  deleteAttachementRequestPending: false,
  deleteAttachementRequestError: null,
  timezoneFetchPending: false,
  timezone: null,
}

const updateSession = (state, payload, requestFieldName = "requestPending") => {
  const {
    name,
    season,
    market,
    address,
    openingDay,
    timezone,
    closingDay,
    office,
    status,
    _id,
    staff,
    attachments,
  } = payload
  return {
    ...state,
    salesSessions: {
      ...state.salesSessions,
      byId: {
        ...state.salesSessions.byId,
        [payload._id]: {
          name,
          season,
          market,
          address,
          openingDay,
          timezone,
          closingDay,
          office,
          status,
          _id,
          staff,
          attachments,
        },
      },
    },
    selectedSession: payload,
    [requestFieldName]: false,
  }
}

const updateSessionList = (state, payload, requestFieldName = "requestPending") => {
  const {
    name,
    season,
    market,
    address,
    openingDay,
    timezone,
    closingDay,
    office,
    status,
    _id,
    staff,
    attachments,
  } = payload
  return {
    ...state,
    salesSessions: {
      ...state.salesSessions,
      byId: {
        ...state.salesSessions.byId,
        [payload._id]: {
          name,
          season,
          market,
          address,
          openingDay,
          timezone,
          closingDay,
          office,
          status,
          _id,
          staff,
          attachments,
        },
      },
    },
    [requestFieldName]: false,
  }
}

const handlers = {
  [FETCH_SALE_SESSIONS.SUCCESS]: (state, { payload }) => ({
    ...state,
    salesSessions: payload,
  }),
  [SELECT_SESSION]: (state, { payload }) => ({
    ...state,
    selectedSessionId: payload.id,
  }),
  [CREATE_SESSION.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [CREATE_SESSION.SUCCESS]: (state, { payload }) => ({
    ...state,
    salesSessions: {
      ...state.salesSessions,
      byId: {
        ...state.salesSessions.byId,
        [payload._id]: {
          ...payload,
        },
      },
      allIds: [payload._id, ...state.salesSessions.allIds],
    },
    selectedSession: payload,
    requestPending: false,
  }),
  [CREATE_SESSION.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [UPDATE_SESSION.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_SESSION.SUCCESS]: (state, { payload }) => updateSession(state, payload),
  [UPDATE_SESSION.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [ACTIVATE_SESSION.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [ACTIVATE_SESSION.SUCCESS]: (state, { payload }) => updateSession(state, payload),
  [ACTIVATE_SESSION.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [DELETE_SESSION.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [DELETE_SESSION.SUCCESS]: (state, { payload }) => ({
    ...state,
    salesSessions: {
      ...state.salesSessions,
      byId: omitBy(state.salesSessions.byId, session => session._id === payload._id),
      allIds: state.salesSessions.allIds.filter(id => id !== payload._id),
    },
    requestPending: false,
  }),
  [DELETE_SESSION.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FETCH_SALE_SESSION.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [FETCH_SALE_SESSION.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedSession: payload,
    requestPending: false,
  }),
  [FETCH_SALE_SESSION.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FETCH_SCHEDULE.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedSession: payload.session,
  }),
  [UNSELECT_SESSION]: state => ({
    ...state,
    selectedSession: null,
  }),
  [INVITE_CLIENTS.REQUEST]: state => ({
    ...state,
    inviteRequestPending: true,
    inviteError: null,
  }),
  [INVITE_CLIENTS.SUCCESS]: (state, { payload }) => updateSession(state, payload, "inviteRequestPending"),
  [INVITE_CLIENTS.FAILURE]: (state, { payload }) => ({
    ...state,
    inviteRequestPending: false,
    inviteError: payload,
  }),
  [SEND_FOLLOWUPS.REQUEST]: state => ({
    ...state,
    followUpRequestPending: true,
    inviteError: null,
  }),
  [SEND_FOLLOWUPS.SUCCESS]: (state, { payload }) => updateSession(state, payload, "followUpRequestPending"),
  [SEND_FOLLOWUPS.FAILURE]: (state, { payload }) => ({
    ...state,
    followUpRequestPending: false,
    inviteError: payload,
  }),
  [UPDATE_AVAILABILITIES.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_AVAILABILITIES.SUCCESS]: (state, { payload }) => updateSession(state, payload),
  [UPDATE_AVAILABILITIES.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FETCH_CAPACITY.REQUEST]: state => ({
    ...state,
    requestCapacityPending: true,
    requestCapacityError: null,
  }),
  [FETCH_CAPACITY.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedSessionCapacity: payload.capacity,
    selectedSession: payload.session,
    requestCapacityPending: false,
  }),
  [FETCH_CAPACITY.FAILURE]: (state, { payload }) => ({
    ...state,
    requestCapacityPending: false,
    requestCapacityError: payload,
  }),
  [FETCH_SALE_SESSION_REPORTS.REQUEST]: state => state,
  [ADD_ATTACHMENT.REQUEST]: state => ({
    ...state,
    AddAttachementRequestPending: true,
    AddAttachementRequestError: null,
  }),
  [ADD_ATTACHMENT.SUCCESS]: (state, { payload }) => updateSession(state, payload, "AddAttachementRequestPending"),
  [ADD_ATTACHMENT.FAILURE]: (state, { payload }) => ({
    ...state,
    AddAttachementRequestPending: false,
    AddAttachementRequestError: payload,
  }),
  [DELETE_ATTACHMENT.REQUEST]: state => ({
    ...state,
    deleteAttachementRequestPending: true,
    deleteAttachementRequestError: null,
  }),
  [DELETE_ATTACHMENT.SUCCESS]: (state, { payload }) =>
    updateSessionList(state, payload, "deleteAttachementRequestPending"),
  [DELETE_ATTACHMENT.FAILURE]: (state, { payload }) => ({
    ...state,
    deleteAttachementRequestPending: false,
    deleteAttachementRequestError: payload,
  }),
  [FETCH_TIMEZONE.REQUEST]: state => ({
    ...state,
    timezoneFetchPending: true,
  }),
  [FETCH_TIMEZONE.SUCCESS]: (state, { payload }) => {
    const { timeZoneId } = payload
    return {
      ...state,
      timezone: timeZoneId,
      timezoneFetchPending: false,
    }
  },
  [FETCH_TIMEZONE.FAILURE]: state => ({
    ...state,
    timezoneFetchPending: false,
  }),
}

export default createReducer(initialState, handlers)

import React, { Component } from "react"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import isEmpty from "lodash/isEmpty"
import { Flex } from "modaresa-commons"

import PageTitle from "../../../elements/PageTitle/PageTitle"
import { Loader, MrLogo, SmallContainer, Text } from "../styles"
import { NewPasswordForm } from "../NewPasswordForm"
import SmllLogo from "../../../../static/mR2.png"

import { getUrlParameter } from "helpers/urls"

class CreatePassword extends Component {
  state = {
    resetToken: "",
    errors: {},
    touched: {},
  }

  componentDidMount() {
    const { history, onVerifyToken } = this.props
    const resetToken = getUrlParameter("token")
    if (!(resetToken.length > 0)) {
      history.push("/login")
    } else {
      onVerifyToken({ resetToken }, history)
      this.setState({ resetToken })
      history.replace("/user/createpassword")
    }
  }

  onSubmit = password => {
    const { values, touched, errors, resetToken } = this.state
    const { onResetPassword, history } = this.props

    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      onResetPassword({ password, resetToken }, history)
    } else {
      this.setState({ errors, touched })
    }
  }

  render() {
    const { requestPending, requestVerificationPendeing, isTokenVerified } = this.props
    if (!isTokenVerified && !requestVerificationPendeing) return null
    return (
      <PageTitle title="Create your password">
        <Flex style={{ minHeight: "100vh" }}>
          <SmallContainer className="agreement">
            {(requestPending || requestVerificationPendeing) && (
              <Loader style={{ zIndex: 3 }}>
                <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
              </Loader>
            )}
            <MrLogo style={{ marginBottom: "20px" }}>
              <img alt="modaresa logo" src={SmllLogo} />
            </MrLogo>
            <Text margin="0px 0px 20px 0px" style={{ textAlign: "center" }}>
              {`Create Your password below and you're ready to start`}
            </Text>
            <NewPasswordForm btnText={"Create Password"} onResetPassword={this.onSubmit} />
          </SmallContainer>
        </Flex>
      </PageTitle>
    )
  }
}

CreatePassword.propTypes = {
  history: PropTypes.object,
  isTokenVerified: PropTypes.bool,
  requestPending: PropTypes.bool,
  requestVerificationPendeing: PropTypes.bool,
  onResetPassword: PropTypes.func,
  onVerifyToken: PropTypes.func,
}

export default CreatePassword

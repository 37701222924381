import React, { memo } from "react"
import styled from "styled-components"
import { B, Flex } from "modaresa-commons"

import { meetingApproachValues, meetingTypesValues, status as statusList } from "../../../../config"

export const BookingModalHeader = styled.div`
  padding: 20px 20px 0 20px;
  height: 100px;
  background-color: ${p => p.bgColor};
  color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
`

type Props = {
  dark: boolean,
  light: boolean,
  meetingType: string,
  retailer: {},
  room: {
    name: string,
  },
  status: string,
}

const Header = ({ retailer, room, meetingType, meetingApproach, status }: Props) => {
  const statusData = statusList[status]
  return (
    <BookingModalHeader bgColor={statusData?.bg}>
      <Flex align="flex-start">
        <div>
          {retailer && (
            <p>
              <B>
                {retailer.name} - {retailer.storeAddress.formattedAddress}
              </B>
            </p>
          )}
          <p>
            {room ? room.name : "No room"}
            {meetingType && ` - ${meetingTypesValues[meetingType]}`}
            {meetingApproach && ` - ${meetingApproachValues[meetingApproach]}`}
          </p>
        </div>
        {retailer?.clientId && (
          <div>
            <B>Retailer ID - {retailer.clientId}</B>
          </div>
        )}
      </Flex>
    </BookingModalHeader>
  )
}

export default memo(Header)

import { useMutation } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import styled from "styled-components"
import { Button } from "modaresa-commons"

import { CREATE_USER_ACCOUNT_FOR_STAFF } from "../../../../graphql/mutations/staff"
import { getGraphQLErrorAsString } from "../../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../../helpers/toast"

export const MargedGiveAccessButton = styled(Button)`
  margin-left: 192px;
  width: calc(100% - 192px);
`

type Props = {
  staffId: string,
}

const ButtonGiveAccess = ({ staffId }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [createUserAccountForStaff] = useMutation(CREATE_USER_ACCOUNT_FOR_STAFF)
  const handleGiveAccess = useCallback(async () => {
    setIsLoading(true)
    try {
      await createUserAccountForStaff({ variables: { id: staffId } })
      onToastSuccess("Access given for staff successfully")
    } catch (e) {
      console.error(e)
      onToastError(`An error occured while giving access to staff.${getGraphQLErrorAsString(e)}`)
      setIsLoading(false)
    }
  }, [createUserAccountForStaff, staffId])
  return (
    <MargedGiveAccessButton type="button" onClick={handleGiveAccess} disabled={isLoading}>
      Give Access
    </MargedGiveAccessButton>
  )
}

export default memo(ButtonGiveAccess)

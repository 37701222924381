export const countries = {
  af: { name: "Afghanistan", isoCode: "af", countryCode: "+93" },
  al: { name: "Albania", isoCode: "al", countryCode: "+355" },
  dz: { name: "Algeria", isoCode: "dz", countryCode: "+213" },
  as: { name: "American Samoa", isoCode: "as", countryCode: "+1-684" },
  ad: { name: "Andorra", isoCode: "ad", countryCode: "+376" },
  ao: { name: "Angola", isoCode: "ao", countryCode: "+244" },
  ai: { name: "Anguilla", isoCode: "ai", countryCode: "+1-264" },
  aq: { name: "Antarctica", isoCode: "aq", countryCode: "+672" },
  ag: { name: "Antigua and Barbuda", isoCode: "ag", countryCode: "+1-268" },
  ar: { name: "Argentina", isoCode: "ar", countryCode: "+54" },
  am: { name: "Armenia", isoCode: "am", countryCode: "+374" },
  aw: { name: "Aruba", isoCode: "aw", countryCode: "+297" },
  au: { name: "Australia", isoCode: "au", countryCode: "+61" },
  at: { name: "Austria", isoCode: "at", countryCode: "+43" },
  az: { name: "Azerbaijan", isoCode: "az", countryCode: "+994" },
  bs: { name: "Bahamas", isoCode: "bs", countryCode: "+1-242" },
  bh: { name: "Bahrain", isoCode: "bh", countryCode: "+973" },
  bd: { name: "Bangladesh", isoCode: "bd", countryCode: "+880" },
  bb: { name: "Barbados", isoCode: "bb", countryCode: "+1-246" },
  by: { name: "Belarus", isoCode: "by", countryCode: "+375" },
  be: { name: "Belgium", isoCode: "be", countryCode: "+32" },
  bz: { name: "Belize", isoCode: "bz", countryCode: "+501" },
  bj: { name: "Benin", isoCode: "bj", countryCode: "+229" },
  bm: { name: "Bermuda", isoCode: "bm", countryCode: "+1-441" },
  bt: { name: "Bhutan", isoCode: "bt", countryCode: "+975" },
  bo: { name: "Bolivia", isoCode: "bo", countryCode: "+591" },
  ba: { name: "Bosnia and Herzegovina", isoCode: "ba", countryCode: "+387" },
  bw: { name: "Botswana", isoCode: "bw", countryCode: "+267" },
  br: { name: "Brazil", isoCode: "br", countryCode: "+55" },
  bn: { name: "Brunei", isoCode: "bn", countryCode: "+673" },
  bg: { name: "Bulgaria", isoCode: "bg", countryCode: "+359" },
  bf: { name: "Burkina Faso", isoCode: "bf", countryCode: "+226" },
  bi: { name: "Burundi", isoCode: "bi", countryCode: "+257" },
  kh: { name: "Cambodia", isoCode: "kh", countryCode: "+855" },
  cm: { name: "Cameroon", isoCode: "cm", countryCode: "+237" },
  ca: { name: "Canada", isoCode: "ca", countryCode: "+1" },
  cv: { name: "Cape Verde", isoCode: "cv", countryCode: "+238" },
  ky: { name: "Cayman Islands", isoCode: "ky", countryCode: "+1-345" },
  cf: { name: "Central African Republic", isoCode: "cf", countryCode: "+236" },
  td: { name: "Chad", isoCode: "td", countryCode: "+235" },
  cl: { name: "Chile", isoCode: "cl", countryCode: "+56" },
  cn: { name: "China", isoCode: "cn", countryCode: "+86" },
  cx: { name: "Christmas Island", isoCode: "cx", countryCode: "+53" },
  cc: { name: "Cocos (Keeling) Islands", isoCode: "cc", countryCode: "+61" },
  co: { name: "Colombia", isoCode: "co", countryCode: "+57" },
  km: { name: "Comoros", isoCode: "km", countryCode: "+269" },
  cd: { name: "Congo", isoCode: "cd", countryCode: "+243" },
  cg: { name: "Congo", isoCode: "cg", countryCode: "+242" },
  ck: { name: "Cook Islands", isoCode: "ck", countryCode: "+682" },
  cr: { name: "Costa Rica", isoCode: "cr", countryCode: "+506" },
  ci: { name: "Cote DIvoire(Ivory Coast)", isoCode: "ci", countryCode: "+225" },
  hr: { name: "Croatia", isoCode: "hr", countryCode: "+385" },
  cu: { name: "Cuba", isoCode: "cu", countryCode: "+53" },
  cy: { name: "Cyprus", isoCode: "cy", countryCode: "+357" },
  cz: { name: "Czech Republic", isoCode: "cz", countryCode: "+420" },
  dk: { name: "Denmark", isoCode: "dk", countryCode: "+45" },
  dj: { name: "Djibouti", isoCode: "dj", countryCode: "+253" },
  dm: { name: "Dominica", isoCode: "dm", countryCode: "+1-767" },
  do: { name: "Dominican Republic", isoCode: "do", countryCode: "+1-809  " },
  tp: { name: "East Timor", isoCode: "tp", countryCode: "+670" },
  ec: { name: "Ecuador", isoCode: "ec", countryCode: "+593 " },
  eg: { name: "Egypt", isoCode: "eg", countryCode: "+20" },
  sv: { name: "El Salvador", isoCode: "sv", countryCode: "+503" },
  gq: { name: "Equatorial Guinea", isoCode: "gq", countryCode: "+240" },
  er: { name: "Eritrea", isoCode: "er", countryCode: "+291" },
  ee: { name: "Estonia", isoCode: "ee", countryCode: "+372" },
  et: { name: "Ethiopia", isoCode: "et", countryCode: "+251" },
  fk: { name: "Falkland Islands", isoCode: "fk", countryCode: "+500" },
  fo: { name: "Faroe Islands", isoCode: "fo", countryCode: "+298" },
  fj: { name: "Fiji", isoCode: "fj", countryCode: "+679" },
  fi: { name: "Finland", isoCode: "fi", countryCode: "+358" },
  fr: { name: "France", isoCode: "fr", countryCode: "+33" },
  gf: { name: "French Guiana", isoCode: "gf", countryCode: "+594" },
  pf: { name: "French Polynesia", isoCode: "pf", countryCode: "+689" },
  ga: { name: "Gabon", isoCode: "ga", countryCode: "+241" },
  gm: { name: "Gambia", isoCode: "gm", countryCode: "+220" },
  ge: { name: "Georgia", isoCode: "ge", countryCode: "+995" },
  de: { name: "Germany", isoCode: "de", countryCode: "+49" },
  gh: { name: "Ghana", isoCode: "gh", countryCode: "+233" },
  gi: { name: "Gibraltar", isoCode: "gi", countryCode: "+350" },
  gr: { name: "Greece", isoCode: "gr", countryCode: "+30" },
  gl: { name: "Greenland", isoCode: "gl", countryCode: "+299" },
  gd: { name: "Grenada", isoCode: "gd", countryCode: "+1-473" },
  gp: { name: "Guadeloupe", isoCode: "gp", countryCode: "+590" },
  gu: { name: "Guam", isoCode: "gu", countryCode: "+1-671" },
  gt: { name: "Guatemala", isoCode: "gt", countryCode: "+502" },
  gn: { name: "Guinea", isoCode: "gn", countryCode: "+224" },
  gw: { name: "Guinea-Bissau", isoCode: "gw", countryCode: "+245" },
  gy: { name: "Guyana", isoCode: "gy", countryCode: "+592" },
  ht: { name: "Haiti", isoCode: "ht", countryCode: "+509" },
  hn: { name: "Honduras", isoCode: "hn", countryCode: "+504" },
  hk: { name: "Hong Kong", isoCode: "hk", countryCode: "+852" },
  hu: { name: "Hungary", isoCode: "hu", countryCode: "+36" },
  is: { name: "Iceland", isoCode: "is", countryCode: "+354" },
  in: { name: "India", isoCode: "in", countryCode: "+91" },
  id: { name: "Indonesia", isoCode: "id", countryCode: "+62" },
  ir: { name: "Iran", isoCode: "ir", countryCode: "+98" },
  iq: { name: "Iraq", isoCode: "iq", countryCode: "+964" },
  ie: { name: "Ireland", isoCode: "ie", countryCode: "+353" },
  il: { name: "Israel", isoCode: "il", countryCode: "+972" },
  it: { name: "Italy", isoCode: "it", countryCode: "+39" },
  jm: { name: "Jamaica", isoCode: "jm", countryCode: "+1-876" },
  jp: { name: "Japan", isoCode: "jp", countryCode: "+81" },
  jo: { name: "Jordan", isoCode: "jo", countryCode: "+962" },
  kz: { name: "Kazakstan", isoCode: "kz", countryCode: "+7" },
  ke: { name: "Kenya", isoCode: "ke", countryCode: "+254" },
  ki: { name: "Kiribati", isoCode: "ki", countryCode: "+686" },
  kp: { name: "North Korea", isoCode: "kp", countryCode: "+850" },
  kr: { name: "South Korea", isoCode: "kr", countryCode: "+82" },
  kw: { name: "Kuwait", isoCode: "kw", countryCode: "+965" },
  kg: { name: "Kyrgyzstan", isoCode: "kg", countryCode: "+996" },
  la: { name: "Laos", isoCode: "la", countryCode: "+856" },
  lv: { name: "Latvia", isoCode: "lv", countryCode: "+371" },
  lb: { name: "Lebanon", isoCode: "lb", countryCode: "+961" },
  ls: { name: "Lesotho", isoCode: "ls", countryCode: "+266" },
  lr: { name: "Liberia", isoCode: "lr", countryCode: "+231" },
  ly: { name: "Libya", isoCode: "ly", countryCode: "+218" },
  li: { name: "Liechtenstein", isoCode: "li", countryCode: "+423" },
  lt: { name: "Lithuania", isoCode: "lt", countryCode: "+370" },
  lu: { name: "Luxembourg", isoCode: "lu", countryCode: "+352" },
  mo: { name: "Macau", isoCode: "mo", countryCode: "+853" },
  mk: { name: "Macedonia", isoCode: "mk", countryCode: "+389" },
  mg: { name: "Madagascar", isoCode: "mg", countryCode: "+261" },
  mw: { name: "Malawi", isoCode: "mw", countryCode: "+265" },
  my: { name: "Malaysia", isoCode: "my", countryCode: "+60" },
  mv: { name: "Maldives", isoCode: "mv", countryCode: "+960" },
  ml: { name: "Mali", isoCode: "ml", countryCode: "+223" },
  mt: { name: "Malta", isoCode: "mt", countryCode: "+356" },
  mh: { name: "Marshall Islands", isoCode: "mh", countryCode: "+692" },
  mq: { name: "Martinique", isoCode: "mq", countryCode: "+596" },
  mr: { name: "Mauritania", isoCode: "mr", countryCode: "+222" },
  mu: { name: "Mauritius", isoCode: "mu", countryCode: "+230" },
  yt: { name: "Mayotte", isoCode: "yt", countryCode: "+269" },
  mx: { name: "Mexico", isoCode: "mx", countryCode: "+52" },
  fm: { name: "Micronesia", isoCode: "fm", countryCode: "+691" },
  md: { name: "Moldova", isoCode: "md", countryCode: "+373" },
  mc: { name: "Monaco", isoCode: "mc", countryCode: "+377" },
  me: { name: "Montenegro", isoCode: "me", countryCode: "+382" },
  mn: { name: "Mongolia", isoCode: "mn", countryCode: "+976" },
  ms: { name: "Montserrat", isoCode: "ms", countryCode: "+1-664" },
  ma: { name: "Morocco", isoCode: "ma", countryCode: "+212" },
  mz: { name: "Mozambique", isoCode: "mz", countryCode: "+258" },
  mm: { name: "Myanmar", isoCode: "mm", countryCode: "+95" },
  na: { name: "Namibia", isoCode: "na", countryCode: "+264" },
  nr: { name: "Nauru", isoCode: "nr", countryCode: "+674" },
  np: { name: "Nepal", isoCode: "np", countryCode: "+977" },
  nl: { name: "Netherlands", isoCode: "nl", countryCode: "+31" },
  an: { name: "Netherlands Antilles", isoCode: "an", countryCode: "+599" },
  nc: { name: "New Caledonia", isoCode: "nc", countryCode: "+687" },
  nz: { name: "New Zealand", isoCode: "nz", countryCode: "+64" },
  ni: { name: "Nicaragua", isoCode: "ni", countryCode: "+505" },
  ne: { name: "Niger", isoCode: "ne", countryCode: "+227" },
  ng: { name: "Nigeria", isoCode: "ng", countryCode: "+234" },
  nu: { name: "Niue", isoCode: "nu", countryCode: "+683" },
  nf: { name: "Norfolk Island", isoCode: "nf", countryCode: "+672" },
  mp: {
    name: "Northern Mariana Islands",
    isoCode: "mp",
    countryCode: "+1-670",
  },
  no: { name: "Norway", isoCode: "no", countryCode: "+47" },
  om: { name: "Oman", isoCode: "om", countryCode: "+968" },
  pk: { name: "Pakistan", isoCode: "pk", countryCode: "+92" },
  pw: { name: "Palau", isoCode: "pw", countryCode: "+680" },
  ps: { name: "Palestinian State", isoCode: "ps", countryCode: "+970" },
  pa: { name: "Panama", isoCode: "pa", countryCode: "+507" },
  pg: { name: "Papua New Guinea", isoCode: "pg", countryCode: "+675" },
  py: { name: "Paraguay", isoCode: "py", countryCode: "+595" },
  pe: { name: "Peru", isoCode: "pe", countryCode: "+51" },
  ph: { name: "Philippines", isoCode: "ph", countryCode: "+63" },
  pl: { name: "Poland", isoCode: "pl", countryCode: "+48" },
  pt: { name: "Portugal", isoCode: "pt", countryCode: "+351" },
  pr: { name: "Puerto Rico", isoCode: "pr", countryCode: "+1-787" },
  qa: { name: "Qatar", isoCode: "qa", countryCode: "+974 " },
  re: { name: "Reunion", isoCode: "re", countryCode: "+262" },
  ro: { name: "Romania", isoCode: "ro", countryCode: "+40" },
  rs: { name: "Serbia", isoCode: "rs", countryCode: "+381" },
  ru: { name: "Russian Federation", isoCode: "ru", countryCode: "+7" },
  rw: { name: "Rwanda", isoCode: "rw", countryCode: "+250" },
  sh: { name: "St Helena", isoCode: "sh", countryCode: "+290" },
  kn: { name: "St Kitts and Nevis", isoCode: "kn", countryCode: "+1-869" },
  lc: { name: "St Lucia", isoCode: "lc", countryCode: "+1-758" },
  pm: { name: "St Pierre and Miquelon", isoCode: "pm", countryCode: "+508" },
  vc: { name: "St Vincent, Grenadines", isoCode: "vc", countryCode: "+1-784" },
  ws: { name: "Samoa", isoCode: "ws", countryCode: "+685" },
  sm: { name: "San Marino", isoCode: "sm", countryCode: "+378" },
  st: { name: "Sao Tome and Principe", isoCode: "st", countryCode: "+239" },
  sa: { name: "Saudi Arabia", isoCode: "sa", countryCode: "+966" },
  sn: { name: "Senegal", isoCode: "sn", countryCode: "+221" },
  sc: { name: "Seychelles", isoCode: "sc", countryCode: "+248" },
  sl: { name: "Sierra Leone", isoCode: "sl", countryCode: "+232" },
  sg: { name: "Singapore", isoCode: "sg", countryCode: "+65" },
  sk: { name: "Slovakia", isoCode: "sk", countryCode: "+421" },
  si: { name: "Slovenia", isoCode: "si", countryCode: "+386" },
  sb: { name: "Solomon Islands", isoCode: "sb", countryCode: "+677" },
  so: { name: "Somalia", isoCode: "so", countryCode: "+252" },
  za: { name: "South Africa", isoCode: "za", countryCode: "+27" },
  es: { name: "Spain", isoCode: "es", countryCode: "+34" },
  lk: { name: "Sri Lanka", isoCode: "lk", countryCode: "+94" },
  sd: { name: "Sudan", isoCode: "sd", countryCode: "+249" },
  sr: { name: "Suriname", isoCode: "sr", countryCode: "+597" },
  sj: { name: "Svalbard", isoCode: "sj", countryCode: "+47" },
  sz: { name: "Swaziland", isoCode: "sz", countryCode: "+268" },
  se: { name: "Sweden", isoCode: "se", countryCode: "+46" },
  ch: { name: "Switzerland", isoCode: "ch", countryCode: "+41" },
  sy: { name: "Syria", isoCode: "sy", countryCode: "+963" },
  tw: { name: "Taiwan", isoCode: "tw", countryCode: "+886" },
  tj: { name: "Tajikistan", isoCode: "tj", countryCode: "+992" },
  tz: { name: "Tanzania", isoCode: "tz", countryCode: "+255" },
  th: { name: "Thailand", isoCode: "th", countryCode: "+66" },
  tk: { name: "Tokelau", isoCode: "tk", countryCode: "+690" },
  to: { name: "Tonga", isoCode: "to", countryCode: "+676" },
  tt: { name: "Trinidad and Tobago", isoCode: "tt", countryCode: "+1-868" },
  tn: { name: "Tunisia", isoCode: "tn", countryCode: "+216" },
  tr: { name: "Turkey", isoCode: "tr", countryCode: "+90" },
  tm: { name: "Turkmenistan", isoCode: "tm", countryCode: "+993" },
  tc: {
    name: "Turks and Caicos Islands",
    isoCode: "tc",
    countryCode: "+1-649",
  },
  tv: { name: "Tuvalu", isoCode: "tv", countryCode: "+688" },
  ug: { name: "Uganda", isoCode: "ug", countryCode: "+256" },
  ua: { name: "Ukraine", isoCode: "ua", countryCode: "+380" },
  ae: { name: "United Arab Emirates", isoCode: "ae", countryCode: "+971" },
  gb: { name: "United Kingdom", isoCode: "gb", countryCode: "+44" },
  us: { name: "United States", isoCode: "us", countryCode: "+1" },
  uy: { name: "Uruguay", isoCode: "uy", countryCode: "+598" },
  uz: { name: "Uzbekistan", isoCode: "uz", countryCode: "+998" },
  vu: { name: "Vanuatu", isoCode: "vu", countryCode: "+678" },
  va: { name: "Vatican City State", isoCode: "va", countryCode: "+418" },
  ve: { name: "Venezuela", isoCode: "ve", countryCode: "+58" },
  vn: { name: "Vietnam", isoCode: "vn", countryCode: "+84" },
  vi: {
    name: "Virgin Islands, British",
    isoCode: "vi",
    countryCode: "+1-284",
  },
  vq: { name: "Virgin Islands, US", isoCode: "vq", countryCode: "+1-340" },
  wf: {
    name: "Wallis and Futuna Islands",
    isoCode: "wf",
    countryCode: "+681",
  },
  ye: { name: "Yemen", isoCode: "ye", countryCode: "+967" },
  zm: { name: "Zambia", isoCode: "zm", countryCode: "+260" },
  zw: { name: "Zimbabwe", isoCode: "zw", countryCode: "+263" },
}

import { Formik } from "formik"
import Raven from "raven-js"
import React, { forwardRef, useCallback, useImperativeHandle, useRef, memo, useState } from "react"
import { getGeocode, getLatLng } from "use-places-autocomplete"
import * as yup from "yup"

import { yupSelectOption } from "../../../../../helpers/yup"

import GeneralFormView from "./GeneralFormView"

type Props = {
  session: {},
  sessionGeneralSettings: {},
  offices: [],
  onSubmit: Function,
  readOnly: boolean,
}

const sessionGeneralFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Name is required."),
  market: yupSelectOption.required("Market is required."),
  season: yupSelectOption.required("Season is required."),
  orderDeadline: yup.mixed().required("Order deadline is required."),
  address: yup
    .string()
    .trim()
    .required("Address is required."),
  // office: yupSelectOption.required("Office is required."),
  openingDay: yup.mixed().required("Date range is required."),
  closingDay: yup.mixed().required("Date range is required."),
  timezone: yupSelectOption.required("Timezone is required."),
  directions: yup
    .string()
    .trim()
    .required("This field is required."),
  closestStation: yup
    .string()
    .trim()
    .required("This field is required."),
  openingHours: yup
    .array()
    .test(
      "available-days",
      "At least 1 day should be available",
      values => values.length && values.some(day => day.available),
    ),
})

const GeneralForm = forwardRef(
  ({ session, sessionGeneralSettings, offices, onSubmit, onFetchTimezone, readOnly }: Props, ref) => {
    const formikRef = useRef(null)
    const [showFixMistakes, setShowFixMistakes] = useState(false)
    const handleSelectAddress = useCallback(
      async address => {
        try {
          const geocodes = await getGeocode({ address: address.description })
          const latLng = await getLatLng(geocodes[0])
          onFetchTimezone(latLng)
        } catch (error) {
          Raven.captureException(error)
          console.error("Error", error)
        }
      },
      [onFetchTimezone],
    )
    const handleFormikSubmit = useCallback(() => {
      setShowFixMistakes(true)
      formikRef.current.handleSubmit()
    }, [])
    const handleFormikSetField = useCallback((field, value) => {
      formikRef.current.setFieldValue(field, value)
    }, [])
    useImperativeHandle(
      ref,
      () => ({
        handleFormikSubmit,
        handleFormikSetField,
      }),
      [handleFormikSetField, handleFormikSubmit],
    )
    return (
      <Formik
        enableReinitialize
        initialValues={sessionGeneralSettings}
        innerRef={formikRef}
        validationSchema={sessionGeneralFormSchema}
        validateOnBlur
        onSubmit={onSubmit}
      >
        {formProps => (
          <GeneralFormView
            showFixMistakes={showFixMistakes}
            readOnly={readOnly}
            session={session}
            offices={offices}
            onSelectAddress={handleSelectAddress}
            formProps={formProps}
          />
        )}
      </Formik>
    )
  },
)

export default memo(GeneralForm)

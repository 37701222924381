import React, { memo } from "react"
import { AutoSizer } from "react-virtualized"
import { theme } from "modaresa-commons"

import DataFilter from "../../../../elements/DataFilter/DataFilter"
import TabFooterButtons from "../TabFooterButtons"

import ButtonSendFollowUps from "./ButtonSendFollowUps"
import ButtonSendInvites from "./ButtonSendInvites"
import buyerInviteSearch, { buyerInviteSearchMatchKeys } from "./BuyersInvites.search"
import { Container, HeaderContainer, TableContainer } from "./InviteClientsView.styles"
import TableInviteClients from "./TableInviteClients"

type Props = {
  salesSessionId: string,
  buyers: [],
  filteredBuyers: [],
  readOnly: boolean,
  selectedBuyers: {},
  selectedBuyersToInvite: {},
  selectedBuyersToFollowUp: {},
  onClickPrev: Function,
  onClickActivateSession: Function,
  onClickDeleteSession: Function,
  isSessionActive: boolean,
  onClickRowBuyer: Function,
  onToggleSelectAll: Function,
  onChangeFilter: Function,
  onSendFollowUps: Function,
  onSendInvites: Function,
}

const InviteClientsView = ({
  salesSessionId,
  buyers,
  filteredBuyers,
  selectedBuyers,
  selectedBuyersToInvite,
  selectedBuyersToFollowUp,
  readOnly,
  onClickPrev,
  onClickActivateSession,
  onClickDeleteSession,
  isSessionActive,
  onClickRowBuyer,
  onToggleSelectAll,
  onChangeFilter,
  onSendFollowUps,
  onSendInvites,
  salesSessionBookings,
}: Props) => {
  const buttonColor = isSessionActive ? theme.primary : theme.secondary
  return (
    <Container>
      <HeaderContainer>
        <DataFilter
          data={filteredBuyers}
          dataKeysToSearch={buyerInviteSearchMatchKeys}
          searchObj={buyerInviteSearch}
          placeholder={"Search buyer by name, status, position, market, email, origin, priority group, retailer status"}
          onChange={onChangeFilter}
        />
        <div>
          <ButtonSendFollowUps
            salesSessionId={salesSessionId}
            color={buttonColor}
            disabled={!isSessionActive}
            selectedBuyersToFollowUp={selectedBuyersToFollowUp}
            buyers={buyers}
            onSend={onSendFollowUps}
          />
          <ButtonSendInvites
            salesSessionId={salesSessionId}
            color={buttonColor}
            disabled={!isSessionActive}
            selectedBuyersToInvite={selectedBuyersToInvite}
            buyers={buyers}
            onSend={onSendInvites}
          />
        </div>
      </HeaderContainer>
      <TableContainer>
        <AutoSizer>
          {({ width, height }) => (
            <TableInviteClients
              width={width}
              height={height}
              buyers={filteredBuyers}
              selectedBuyers={selectedBuyers}
              onRowClick={onClickRowBuyer}
              onToggleSelectAll={onToggleSelectAll}
              salesSessionBookings={salesSessionBookings}
            />
          )}
        </AutoSizer>
      </TableContainer>
      {!readOnly && (
        <TabFooterButtons
          onNext={onClickActivateSession}
          onPrev={onClickPrev}
          onDelete={onClickDeleteSession}
          hideNext={isSessionActive}
          labelNext="Activate Sales Session"
        />
      )}
    </Container>
  )
}
export default memo(InviteClientsView)

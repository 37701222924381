import React, { memo, useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Tabs, TabPane } from "modaresa-commons"

import PageTitle from "../../../../../elements/PageTitle/PageTitle"
import { getUrlParameter } from "../../../../../../helpers/urls"

import AddSharedRetailerAsBrandRetailer from "./AddSharedRetailerAsBrandRetailer"
import RetailerInformation from "./RetailerInformation"

type Props = {
  addRetailer: Function,
  addRetailerBySharedRetailer: Function,
  brand: {},
  citiesOptions: [],
  citiesRequestPending: boolean,
  fetchCities: Function,
  fetchSharedRetailers: Function,
  history: {},
  match: {},
  requestError: any,
  requestPending: boolean,
  selectRetailerByID: Function,
  selectedRetailer: {},
  sharedRetailerRequestPending: boolean,
  sharedRetailers: [],
  unselectRetailer: Function,
  updateRetailer: Function,
  user: {},
}

const AddNewRetailer = ({
  fetchSharedRetailers,
  selectedRetailer,
  requestPending,
  requestError,
  history,
  brand,
  citiesOptions,
  addRetailer,
  updateRetailer,
  sharedRetailerRequestPending,
  sharedRetailers,
  fetchCities,
  citiesRequestPending,
  retailers,
  addRetailerBySharedRetailer,
  user,
}: Props) => {
  const location = useLocation()
  const [showRetailerList, setShowRetailerList] = useState(true)
  const [sharedRetailerIdFromUrl, setSharedRetailerIdFromUrl] = useState(undefined)
  const handleClickNewRetailer = useCallback(() => setShowRetailerList(false), [])
  useEffect(() => {
    fetchSharedRetailers()
  }, [fetchSharedRetailers])
  useEffect(() => {
    const sharedRetailerId = getUrlParameter("sharedRetailerId", location.search)
    setSharedRetailerIdFromUrl(sharedRetailerId)
    setShowRetailerList(true)
  }, [location])

  if (showRetailerList) {
    return (
      <PageTitle title="Add shared retailer">
        <AddSharedRetailerAsBrandRetailer
          selectSharedRetailerId={sharedRetailerIdFromUrl}
          sharedRetailers={sharedRetailers}
          loadingSharedRetailers={sharedRetailerRequestPending}
          addRetailerBySharedRetailer={addRetailerBySharedRetailer}
          brand={brand}
          onClickNewRetailer={handleClickNewRetailer}
          user={user}
        />
      </PageTitle>
    )
  }

  return (
    <PageTitle title="Add new retailer">
      <Tabs>
        <TabPane tabKey="add-retailer" tab="Add New Retailer">
          <RetailerInformation
            isNew
            retailers={retailers}
            addRetailer={addRetailer}
            sharedRetailers={sharedRetailers}
            brand={brand}
            citiesOptions={citiesOptions}
            citiesRequestPending={citiesRequestPending}
            fetchCities={fetchCities}
            history={history}
            requestError={requestError}
            requestPending={requestPending}
            selectedRetailer={selectedRetailer}
            updateRetailer={updateRetailer}
          />
        </TabPane>
      </Tabs>
    </PageTitle>
  )
}

export default memo(AddNewRetailer)

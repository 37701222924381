import styled from "styled-components"

export const ContainerInfos = styled.div`
  width: 100%;
  max-width: 512px;
`

export const ContainerBigRow = styled.div`
  width: 100%;
  max-width: 1024px;
`

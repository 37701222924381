import styled from "styled-components"
import { Tabs } from "modaresa-commons"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 104px;
  bottom: 0;
  background: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ModalLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`
export const Bold = styled.span`
  font-weight: bold;
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`
export const CloseModal = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.strawberry};
`

export const Text = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`
export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "25px"};
  width: auto;
  margin: 0 auto;
`

export const FullHeightTabs = styled(Tabs)`
  height: 100%;
`

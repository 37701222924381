import React, { memo, useCallback } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Button } from "modaresa-commons"
import { MaskIcon } from "modaresa-commons/dist/components/icons"

const FixedButton = styled(Button)`
  position: fixed;
  bottom: 32px;
  left: 32px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledMaskIcon = styled(MaskIcon)`
  color: white;
  font-size: 22px;
  margin-right: 8px;
`

const ButtonStopConnectAs = () => {
  const history = useHistory()
  const controllerToken = localStorage.getItem("controller-token")
  const handleClickBackToAdmin = useCallback(() => history.push("/connect-as"), [history])
  if (!controllerToken) return null
  return (
    <FixedButton onClick={handleClickBackToAdmin}>
      <StyledMaskIcon />
      Back to admin
    </FixedButton>
  )
}

export default memo(ButtonStopConnectAs)

import styled from "styled-components"
import { Section } from "modaresa-commons"

import Toggler from "../../../../elements/Toggler"

export const Container = styled.div`
  width: 100%;
  padding: 0 0 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const Content = styled.div`
  overflow-y: auto;
`
export const AlgosContainer = styled.div`
  padding: 0;
`
export const Icon = styled.img`
  display: inline-block;
  margin-left: 30px;
  width: ${props => props.width};
  height: ${props => props.height};
`

export const RoomBasedAreasToggler = styled(Toggler)`
  color: #516bef;
`
export const AlgorithmSection = styled(Section)`
  ${p =>
    p.color
      ? `
    color: ${p.color};
  `
      : ""}
  & p {
    margin-bottom: 0;
  }
`

import { createAsyncAction } from "../../helpers/redux"

export const FETCH_STAFF = createAsyncAction("FETCH_STAFF")
export const fetchStaffs = brandId => ({
  type: FETCH_STAFF.REQUEST,
  payload: {
    brandId,
  },
})

export const SELECT_STAFF_MEMBER = createAsyncAction("SELECT_STAFF_MEMBER")
export const fetchStaffMember = id => ({
  type: SELECT_STAFF_MEMBER.SUCCESS,
  payload: {
    id,
  },
})

export const UNSELECT_STAFF_MEMBER = "UNSELECT_STAFF_MEMBER"
export const unselectStaffMember = () => ({
  type: UNSELECT_STAFF_MEMBER,
})

export const CREATE_STAFF_MEMBER = createAsyncAction("CREATE_STAFF_MEMBER")
export const createStaffMember = data => ({
  type: CREATE_STAFF_MEMBER.REQUEST,
  payload: {
    data,
  },
})

export const UPDATE_STAFF_MEMBER = createAsyncAction("UPDATE_STAFF_MEMBER")
export const updateStaffMember = (data, id) => ({
  type: UPDATE_STAFF_MEMBER.REQUEST,
  payload: {
    data,
    id,
  },
})

export const CHECK_IF_VERIFIED = createAsyncAction("CHECK_IF_VERIFIED")
export const verifiedCheck = id => ({
  type: CHECK_IF_VERIFIED.REQUEST,
  payload: {
    id,
  },
})

export const VERIFY_EMAIL = createAsyncAction("VERIFY_EMAIL")
export const verifyEmail = id => ({
  type: VERIFY_EMAIL.REQUEST,
  payload: {
    id,
  },
})

export const REGISTER_USER = createAsyncAction("REGISTER_USER")
export const registerUser = data => ({
  type: REGISTER_USER.REQUEST,
  payload: {
    data,
  },
})

export const GIVE_ACCESS = createAsyncAction("GIVE_ACCESS")
export const giveAcccess = (id, data) => ({
  type: GIVE_ACCESS.REQUEST,
  payload: {
    data,
    id,
  },
})

export const FETCH_STAFF_AVAILABLE_CLIENTS = createAsyncAction("FETCH_STAFF_AVAILABLE_CLIENTS")
export const fetchStaffAvailableClients = id => ({
  type: FETCH_STAFF_AVAILABLE_CLIENTS.REQUEST,
  payload: {
    id,
  },
})

export const DELETE_STAFF = createAsyncAction("DELETE_STAFF")
export const deleteStaff = staffId => ({
  type: DELETE_STAFF.REQUEST,
  payload: {
    staffId,
  },
})

export const FETCH_AREA_MANAGERS = createAsyncAction("FETCH_AREA_MANAGERS")
export const fetchAreaManagers = brandId => ({
  type: FETCH_AREA_MANAGERS.REQUEST,
  payload: {
    brandId,
  },
})

export const CLEAR_AREA_MANAGER = "CLEAR_AREA_MANAGER"
export const clearAreaManagers = () => ({
  type: CLEAR_AREA_MANAGER,
})

import styled from "styled-components"

export const ContainerHeader = styled.div`
  padding: 16px 30px 0 30px;
`

export const MainInfo = styled.div``
export const SubInfo = styled.div``
export const FullName = styled.span`
  font-size: 20px;
  font-weight: 500;
`
export const CompanyPosition = styled.span`
  margin-left: 16px;
  color: ${p => p.theme.gray200};
`
export const Email = styled.span`
  color: ${p => p.theme.gray200};
`
export const Phone = styled.span`
  color: ${p => p.theme.gray200};
  margin-left: 16px;
`
export const Value = styled.span`
  margin-left: 4px;
  color: ${p => p.theme.black};
`

import { gql } from "@apollo/client"

import NotificationFragments from "./notification.fragments"
import TerritoriesFragments from "./territories.fragments"

export const FRAGMENT_STAFF_BASIC_INFOS = gql`
  fragment StaffBasicInfos on Staff {
    id
    firstName
    lastName
    email
    emailVerified
  }
`

export const FRAGMENT_STAFF_NOTIFICATIONS = gql`
  fragment StaffNotifications on Staff {
    notifications {
      meetingCheckin {
        ...NotificationFull
      }
      meetingBooked {
        ...NotificationFull
      }
      meetingCancelled {
        ...NotificationFull
      }
      meetingChanged {
        ...NotificationFull
      }
      autoMorningRecap {
        ...NotificationFull
      }
      offScheduleRequest {
        ...NotificationFull
      }
    }
  }
  ${NotificationFragments.fragments.full}
`

export const FRAGMENT_STAFF_FULL_PROFILE = gql`
  fragment StaffFullProfile on Staff {
    firstName
    lastName
    email
    emailVerified
    pendingVerification
    hasLogin
    modaresaRole
    phoneNumber
    companyPosition
    hasEditorRights
    videoIDs
    market
    affinityBuyers {
      id
      fullName
      retailer {
        id
        name
      }
    }
    office {
      id
      name
    }
    languages {
      name
      isoCode
    }
    brand {
      id
      toggleRoomBasedAreas
    }
    geographicResponsibility {
      areas {
        id
        name
      }
      roomAreas {
        room {
          id
          name
        }
        area {
          id
          name
        }
      }
      territories {
        ...FullTerritories
      }
    }
    ...StaffNotifications
  }
  ${FRAGMENT_STAFF_NOTIFICATIONS}
  ${TerritoriesFragments.fragments.full}
`

const StaffFragments = {
  fragments: {
    notifications: FRAGMENT_STAFF_NOTIFICATIONS,
    basicInfos: FRAGMENT_STAFF_BASIC_INFOS,
    full: FRAGMENT_STAFF_FULL_PROFILE,
  },
}

export default StaffFragments

import { useMutation, useQuery } from "@apollo/client"
import React, { useCallback, useMemo, useState } from "react"
import { connect } from "react-redux"

import { status as statusList } from "../../../config"
import { CONFIRM_RELOU_REQUEST, DECLINE_RELOU_REQUEST } from "../../../graphql/mutations/relouRequest"
import { RELOU_REQUEST } from "../../../graphql/queries/relouRequest"
import { getGraphQLErrorAsString } from "../../../helpers/error"
import { onToastError } from "../../../helpers/toast"
import { cacheAddBooking } from "../../../store/actions/schedules"
import BookingModal from "../BookingModal"

type Props = {
  relouRequestId: string,
  onClose: Function,
}
const RelouRequestConfirmModal = ({ relouRequestId, onClose, onConfirm }: Props) => {
  const [relouRequest, setRelouRequest] = useState(null)
  const [loadingAction, setIsLoadingAction] = useState(false)
  const [confirmRequest] = useMutation(CONFIRM_RELOU_REQUEST)
  const [declineRequest] = useMutation(DECLINE_RELOU_REQUEST)

  const handleChangeRequest = useCallback(updatedRequest => setRelouRequest(updatedRequest), [])
  const handleDecline = useCallback(
    async relouRequestToSave => {
      setIsLoadingAction(true)
      try {
        await declineRequest({ variables: { id: relouRequestToSave.id } })
        onClose()
      } catch (err) {
        setIsLoadingAction(false)
        console.error(err)
        onToastError("An error occured while declining relou request")
      }
    },
    [declineRequest, onClose],
  )
  const handleConfirm = useCallback(
    async relouRequestToSave => {
      setIsLoadingAction(true)
      const variables = {
        id: relouRequestToSave.id,
        budget: relouRequestToSave.budget && Number.parseInt(relouRequestToSave.budget, 10),
        hostId: relouRequestToSave.host?.id,
      }
      try {
        const {
          data: {
            confirmRelouRequest: { bookingEvent },
          },
        } = await confirmRequest({ variables })
        // todo temporary save to calendar, remove with refacto of calendar
        const newBooking = {
          ...bookingEvent,
          _id: bookingEvent.id,
          brandId: bookingEvent.brand?.id,
          clients: bookingEvent.clients.map(({ id }) => id),
          host: bookingEvent.host?.id,
          meetingResp: bookingEvent.meetingResp.map(staff => staff.id),
          scheduleId: bookingEvent.schedule?.id,
        }
        onConfirm({ booking: newBooking })
        // end
        onClose()
      } catch (err) {
        setIsLoadingAction(false)
        console.error(err)
        onToastError(getGraphQLErrorAsString(err))
      }
    },
    [confirmRequest, onClose, onConfirm],
  )

  const { loading, error, data } = useQuery(RELOU_REQUEST, {
    variables: { id: relouRequestId },
    onCompleted: ({ RelouRequest }) => {
      setRelouRequest(RelouRequest)
    },
  })
  const buttons = useMemo(() => {
    if (!relouRequest) return []
    const { status, host } = relouRequest
    const buttonsList = [
      {
        label: "Decline",
        bgcolor: "#4a4a4a",
        onClick: handleDecline,
        disabled: loadingAction,
      },
    ]
    if (status === "pending") {
      buttonsList.push({
        label: "Confirm",
        bgcolor: statusList.pending.bg,
        onClick: handleConfirm,
        disabled: loadingAction || !host,
      })
    }
    return buttonsList
  }, [loadingAction, relouRequest, handleDecline, handleConfirm])
  if (loading && !data) {
    return <BookingModal key="loading" loading onClose={onClose} />
  }
  if (error) {
    console.error(error)
    return <BookingModal error={error} />
  }
  const { RelouRequest } = data
  return (
    <BookingModal
      key={RelouRequest.id}
      bookingRequest={RelouRequest}
      onClose={onClose}
      buttons={buttons}
      onChangeRequest={handleChangeRequest}
    />
  )
}

const mapStateToProps = () => ({})
const actions = {
  onConfirm: cacheAddBooking,
}
export default connect(mapStateToProps, actions)(RelouRequestConfirmModal)

import React, { memo } from "react"
import styled from "styled-components"
import { SkeletonBlock } from "modaresa-commons"

import AreaRow from "./AreaRow"
import RemainingCountriesRow from "./RemainingCountriesRow"
import TableAreasHeaders from "./TableAreasHeaders"

const TableStyled = styled.table`
  width: 100%;
  font-size: 14px;
  color: ${p => p.theme.black};
  border-collapse: separate;
  border-spacing: 8px 16px;
  margin: 0 -8px;
  & thead {
    & th {
      font-weight: 500;
      padding-left: 0;
      vertical-align: top;
      height: 32px;
      font-size: 15px;
      &:first-child {
        padding-left: 0;
      }
    }
  }
  & tbody {
    & tr {
      vertical-align: top;
    }
  }
`

const TableSkeletonCell = styled(SkeletonBlock)`
  height: 20px;
  width: 90%;
`

type Props = {
  data: [],
  remainingArea: {},
  loading: boolean,
  brandId: string,
  onChangeArea: Function,
  onDeleteArea: Function,
  onChangeRemainingArea: Function,
  canAreasBeModified: Function,
  canStaffsBeModified: Function,
  excludeCountries: [],
  territoriesOptions: [],
  errors: {
    areas: [string],
  },
}

const TableAreas = ({
  data,
  remainingArea,
  loading,
  brandId,
  onChangeArea,
  onDeleteArea,
  canAreasBeModified,
  canStaffsBeModified,
  excludeCountries,
  territoriesOptions,
  onChangeRemainingArea,
  errors,
}: Props) => (
  <TableStyled>
    <TableAreasHeaders />
    <tbody>
      {loading ? (
        [...Array(3)].map((_, nb) => (
          <tr key={`row${nb}`}>
            {[...Array(7)].map((_, colNb) => (
              <td key={`row${nb}${colNb}`}>
                <TableSkeletonCell />
              </td>
            ))}
          </tr>
        ))
      ) : (
        <>
          {!data || data.length === 0 ? (
            <tr>
              <td>No areas</td>
            </tr>
          ) : (
            <>
              {data.map((row, rowIndex) => (
                <AreaRow
                  key={row.id}
                  area={row}
                  index={rowIndex}
                  excludeCountries={excludeCountries}
                  territoriesOptions={territoriesOptions}
                  brandId={brandId}
                  canAreasBeModified={canAreasBeModified}
                  canStaffsBeModified={canStaffsBeModified}
                  onChangeArea={onChangeArea}
                  onDeleteArea={onDeleteArea}
                  error={errors.areas?.[rowIndex]}
                />
              ))}
            </>
          )}
          <RemainingCountriesRow
            remainingArea={remainingArea}
            excludeCountries={excludeCountries}
            brandId={brandId}
            canStaffsBeModified={canStaffsBeModified}
            onChangeRemainingArea={onChangeRemainingArea}
          />
        </>
      )}
    </tbody>
  </TableStyled>
)

export default memo(TableAreas)

import { Form, Formik } from "formik"
import React, { memo, useState } from "react"
import * as yup from "yup"
import { Button, Divider } from "modaresa-commons"

import { blockingListTypes, optionsBlockingListTypes } from "../../../config/settings"
import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"
import FormikInput from "../FormikFields/FormikInput"
import FormikRadioToggle from "../FormikFields/FormikRadioToggle"

import FieldButtonAddBlockingList from "./FieldButtonAddBlockingList"
import FieldRetailers from "./FieldRetailers"

const roomSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Required"),
  hasBlockingList: yup.boolean(),
  typeBlockingList: yup.string(),
  retailers: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      name: yup.string(),
    }),
  ),
})

const labelWidth = "192px"

type Props = {
  brandId: string,
  room: {
    name: string,
    hasBlockingList: boolean,
    typeBlockingList: "whitelist" | "blacklist",
    retailers: [],
  },
  loadingSubmit: boolean,
  canEdit: boolean,
  canEditName: boolean,
  onSubmit: boolean,
  restrictToStaffIdIfAgent: string,
}
const FormRoom = ({
  brandId,
  room,
  loadingSubmit,
  canEdit,
  canEditName,
  onSubmit,
  restrictToStaffIdIfAgent,
}: Props) => {
  const [initialValues] = useState({
    name: room.name,
    hasBlockingList: room.hasBlockingList,
    typeBlockingList: room.typeBlockingList || "whitelist",
    retailers: room.retailers,
  })
  const readOnly = !canEdit
  const disableNameEdit = readOnly || !canEditName
  return (
    <Formik initialValues={initialValues} validateOnBlur validationSchema={roomSchema} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <FormikInput
            direction="row"
            labelWidth={labelWidth}
            label="Room Name*"
            name="name"
            disabled={disableNameEdit}
          />
          <FieldButtonAddBlockingList disabled={readOnly} labelWidth={labelWidth} />
          {values.hasBlockingList && (
            <div>
              <Divider>Blocking list</Divider>
              <FormikRadioToggle
                disabled={readOnly}
                direction="row"
                name="typeBlockingList"
                label="Type*"
                labelWidth={labelWidth}
                options={optionsBlockingListTypes}
                description={blockingListTypes[values.typeBlockingList]?.help}
              />
              <FieldRetailers
                disabled={readOnly}
                labelWidth={labelWidth}
                brandId={brandId}
                restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
              />
            </div>
          )}
          <FormFieldLayout label=" " labelWidth={labelWidth}>
            <Button minWidth="100%" type="submit" disabled={readOnly || loadingSubmit}>
              Save changes
            </Button>
          </FormFieldLayout>
        </Form>
      )}
    </Formik>
  )
}

export default memo(FormRoom)

import { call, put, select } from "redux-saga/effects"

import * as Api from "../../helpers/api"
import { FETCH_SCHEDULE } from "../actions/schedules"
import { JOIN_SOCKET_ROOM } from "../sockets/messageTypes"
import { takeLatest } from "../../helpers/saga"

import { onToastError } from "./../../helpers/toast"

function* fetchSchedule({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { sessionId, callback } = payload
  try {
    const schedule = yield call(Api.fetchSessionSchedule, {
      token: accessToken,
      sessionId,
    })
    const session = yield call(Api.fetchSession, {
      token: accessToken,
      id: sessionId,
    })

    yield put({ type: FETCH_SCHEDULE.SUCCESS, payload: { schedule, session } })
    if (callback) {
      callback(session.openingHours)
    }
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: JOIN_SOCKET_ROOM,
        body: { room: schedule._id, type: "calendar" },
      },
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_SCHEDULE.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchScheduleFlow() {
  yield takeLatest(FETCH_SCHEDULE.REQUEST, fetchSchedule)
}

export default [fetchScheduleFlow]

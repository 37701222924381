import { Tabs, ModalConfirmation, Spinner } from "modaresa-commons"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import debounce from "lodash/debounce"

import PageTitle from "../../../../elements/PageTitle/PageTitle"
import Algorithm from "../04-Algorithm"
import AllocateStaff from "../07-AllocateStaff"
import General from "../01-General"
import InviteClients from "../08-InviteClients"
import SalesSessionAreas from "../05-SalesSessionAreas"
import SalesSessionRooms from "../03-SalesSessionRooms/SalesSessionRooms"
import ShowroomStructure from "../02-ShowroomStructure"
import Restrictions from "../06-Restrictions"

import { Container, FullHeightTabs, LoaderContainer } from "./styles"

const sections = ["general", "structure", "rooms", "algorithm", "areas", "restrictions", "staff", "clients"]
const sessionTemplate = {
  staff: [],
  clients: [],
  status: "preDraft",
  walkthrough: { available: false, length: 0 },
  businessMeeting: { available: false, length: 0 },
  extra: {
    filledSessionData: {
      general: false,
      structure: false,
      rooms: false,
      algorithm: false,
      restrictions: false,
      staff: false,
      clients: false,
    },
  },
}

class SaleSession extends Component {
  constructor(props) {
    super(props)
    const { selectedSession, brand } = this.props
    const session = selectedSession || { ...sessionTemplate, brand: brand._id }

    this.state = {
      activeSectionIndex: 0,
      session,
      pendingSectionChange: false,
      deleteModal: false,
      isDelete: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedSession && prevState.session._id !== nextProps.selectedSession._id) {
      return { session: nextProps.selectedSession }
    }
    return null
  }

  componentDidMount() {
    const {
      onFetchClients,
      onFetchStaffs,
      onFetchRetailers,
      match,
      clientsRaw,
      allStaff,
      retailers,
      clientsRequestPending,
      staffsRequestPending,
      retailersRequestPending,
      user,
    } = this.props

    if (match.params && match.params.id) {
      this.props.onFetchSession(match.params.id)
      const defaultSection = match.params.section && sections.indexOf(match.params.section)
      if (defaultSection) {
        this.setState({ activeSectionIndex: defaultSection })
      }
    }

    if (!retailersRequestPending && !retailers.data) {
      onFetchRetailers(user.brandId)
    }

    if (!clientsRequestPending && !clientsRaw?.data) {
      onFetchClients(user.brandId)
    }

    if (!staffsRequestPending && !allStaff) {
      onFetchStaffs(user.brandId)
    }
  }

  componentWillUnmount = () => {
    this.props.onUnselectSession()
  }

  componentDidUpdate(prevProps) {
    const { requestError, requestPending, history, selectedSession } = this.props
    const { isDelete } = this.state
    if (prevProps.requestPending && !requestPending) {
      if (!requestError && this.state.pendingSectionChange) {
        this.setState(state => {
          const newTabIndex = state.activeSectionIndex + 1
          history.replace(`/sessions/${selectedSession._id}/${sections[newTabIndex]}`)
          return {
            activeSectionIndex: newTabIndex,
            pendingSectionChange: false,
          }
        })
      }
    }
    if (isDelete && !requestPending && !requestError) {
      this.props.history.replace("/sessions/active")
    }
  }

  onChangeTab = newTab => {
    const { history, selectedSession } = this.props
    if (!selectedSession) {
      return
    }
    history.replace(`/sessions/${selectedSession._id}/${newTab}`)
    this.setState({ activeSectionIndex: sections.indexOf(newTab) })
  }

  onSubmitGeneral = (values, changed) => {
    this.setState({
      pendingSectionChange: true,
    })
    const { onCreateSession, onUpdateSession, selectedSession, user } = this.props
    if (!selectedSession) {
      const session = { ...this.state.session, ...values, createdByStaffId: user._id }
      session.extra.filledSessionData.general = true
      onCreateSession(session)
    } else {
      const session = { ...selectedSession, ...values }
      if (changed && selectedSession.status === "draft") {
        session.extra.filledSessionData = {
          general: true,
          structure: false,
          rooms: false,
          algorithm: false,
          restrictions: false,
          areas: false,
          staff: false,
          clients: false,
        }
        session.staff = []
        session.rooms = []
        session.clients = []
        session.customBuyingAppointment = false
        session.customizeStructure = false
        session.meals = null
        session.algorithms = null
        session.geoLimitData = []
        session.walkthrough = { available: false, length: 0 }
        session.businessMeeting = { available: false, length: 0 }
      }
      onUpdateSession(session)
    }
  }

  onSubmitStructure = (values, changed) => {
    this.setState({
      pendingSectionChange: true,
    })
    const { onUpdateSession, selectedSession } = this.props
    if (selectedSession) {
      const session = { ...selectedSession, ...values }
      session.extra.filledSessionData.structure = true
      if (changed && selectedSession.status === "draft") {
        session.geoLimitData = []
        session.extra.filledSessionData.clients = false
        session.extra.filledSessionData.staff = false
      }
      onUpdateSession(session)
    }
  }

  onSubmitRooms = () => {
    this.setState({
      pendingSectionChange: true,
    })
    const { onUpdateSession, selectedSession } = this.props
    if (selectedSession) {
      const session = {
        _id: selectedSession._id,
        extra: {
          ...selectedSession.extra,
          rooms: true,
        },
      }
      session.extra.filledSessionData.rooms = true
      onUpdateSession(session)
    }
  }

  onSubmitAlgorithm = algorithms => {
    this.setState({
      pendingSectionChange: true,
    })
    const { onUpdateSession, selectedSession } = this.props
    if (selectedSession) {
      const session = {
        ...selectedSession,
        algorithms,
      }
      if (session.extra.filledSessionData.staff && selectedSession.algorithms.geoLimit && !algorithms.geoLimit) {
        session.geoLimitData = session.geoLimitData.map(area => ({
          ...area,
          rooms: area.rooms.map(room => ({
            name: room.name,
            structure: room.structure.map(day => ({
              meetingCapacity: day.meetingCapacity,
              date: day.date,
              limit: null,
            })),
          })),
        }))
      }
      session.extra.filledSessionData.algorithm = true
      onUpdateSession(session)
    }
  }

  onSubmitAreas = () => {
    this.setState({
      pendingSectionChange: true,
    })
    const { onUpdateSession, selectedSession } = this.props
    if (selectedSession) {
      const session = {
        _id: selectedSession._id,
        extra: {
          ...selectedSession.extra,
          areas: true,
        },
      }
      session.extra.filledSessionData.areas = true
      onUpdateSession(session)
    }
  }

  onSubmitRestrictions = restrictions => {
    this.setState({
      pendingSectionChange: true,
    })
    const { onUpdateSession, selectedSession } = this.props
    if (selectedSession) {
      const session = {
        _id: selectedSession._id,
        restrictions,
        extra: {
          ...selectedSession.extra,
        },
      }
      onUpdateSession(session)
    }
  }

  onSubmitStaff = data => {
    this.setState({
      pendingSectionChange: true,
    })
    const { onUpdateSession, selectedSession } = this.props
    if (selectedSession) {
      const session = { ...selectedSession, ...data }
      session.extra.filledSessionData.staff = true
      onUpdateSession(session)
    }
  }

  onActivateSession = () => {
    const { onActivateSession, selectedSession } = this.props
    onActivateSession(selectedSession._id)
  }

  onPrev = () => {
    this.setState(state => ({
      activeSectionIndex: state.activeSectionIndex === 0 ? 0 : state.activeSectionIndex - 1,
    }))
  }

  onDeleteSession = () => {
    const { selectedSession, onDeleteSession } = this.props
    this.setState({ isDelete: true })
    const isDraft = selectedSession.status === "draft"
    onDeleteSession(selectedSession._id, isDraft)
  }

  onInviteClients = ({ clients }) => {
    const { onInviteClients, selectedSession, brand } = this.props
    onInviteClients({
      clients,
      sessionId: selectedSession._id,
      brandId: brand._id,
    })
  }

  onSendFollowups = ({ clients }) => {
    const { selectedSession, onSendFollowups } = this.props
    onSendFollowups({
      sessionId: selectedSession._id,
      clients,
    })
  }

  onToggleModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }))
  }

  deboncedActiveSession = debounce(this.onActivateSession, 1000, {
    leading: true,
    trailing: false,
  })

  render() {
    const { activeSectionIndex, deleteModal } = this.state
    const {
      staff,
      brand,
      selectedSession,
      match: { params },
      retailers,
      requestPending,
      staffsRequestPending,
      clientsRequestPending,
      retailersRequestPending,
      areaManagers,
      user,
      allStaff,
      fetchTimezone,
      fetchedTimezone,
      history,
    } = this.props

    const activeSection = sections[activeSectionIndex]
    const session = selectedSession || this.state.session
    const {
      name,
      extra: { filledSessionData },
    } = session
    const { hasEditorRights, modaresaRole } = user
    const isNewSession = !selectedSession
    const isAgent = modaresaRole === "agent"
    const isAdmin = ["owner", "admin"].indexOf(modaresaRole) > -1
    const isActiveSession = session.status === "active"
    const readOnly = !(
      isAdmin ||
      hasEditorRights ||
      (isNewSession && isAgent) ||
      (!isNewSession && session.createdByStaffId === user._id)
    )

    const isLoading = requestPending || clientsRequestPending || staffsRequestPending || retailersRequestPending

    return (
      <PageTitle title={name || "Session"}>
        <Container id="spiner">
          <ModalConfirmation
            isOpen={deleteModal}
            loading={requestPending}
            title="Delete Sales Session"
            text="Are you sure that you want to delete this sales session?"
            buttonLabelOk="Confirm"
            onClickOk={this.onDeleteSession}
            onClose={this.onToggleModal}
          />
          <Spinner isOpen={this.state.pendingSectionChange} />
          {params.id && !selectedSession && isLoading ? (
            <LoaderContainer>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </LoaderContainer>
          ) : (
            <FullHeightTabs activeKey={activeSection} onChange={this.onChangeTab}>
              <Tabs.TabPane tabKey="general" tab="1. General">
                <General
                  fetchedTimezone={fetchedTimezone}
                  offices={brand.offices}
                  readOnly={readOnly}
                  session={session}
                  onDeleteSession={this.onToggleModal}
                  onFetchTimezone={fetchTimezone}
                  onSubmit={this.onSubmitGeneral}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tabKey="structure" tab="2. Showroom Structure" disabled={!filledSessionData.general}>
                <ShowroomStructure
                  staff={staff}
                  allStaff={allStaff}
                  defaultRooms={brand.roomsNew}
                  readOnly={readOnly}
                  session={session}
                  onDeleteSession={this.onToggleModal}
                  onPrevPress={this.onPrev}
                  onSubmit={this.onSubmitStructure}
                  history={history}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tabKey="rooms" tab="3. Rooms" disabled={!filledSessionData.structure}>
                <SalesSessionRooms
                  salesSessionId={session._id}
                  salesSessionName={session.name}
                  brandId={brand._id}
                  canEdit={!readOnly}
                  onSubmit={this.onSubmitRooms}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tabKey="algorithm" tab="4. Algorithm" disabled={!filledSessionData.rooms}>
                <Algorithm
                  algorithms={session.algorithms}
                  readOnly={readOnly}
                  session={session}
                  onDeleteSession={this.onToggleModal}
                  onClickPrev={this.onPrev}
                  onSubmit={this.onSubmitAlgorithm}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tabKey="areas" tab="5. Areas" disabled={!filledSessionData.algorithm}>
                {selectedSession && (
                  <SalesSessionAreas
                    salesSessionId={selectedSession._id}
                    isRoomBasedAreas={selectedSession.toggleRoomBasedAreas}
                    canEdit={!readOnly}
                    onSaveChanges={this.onSubmitAreas}
                  />
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tabKey="restrictions" tab="6. Restrictions" disabled={!filledSessionData.areas}>
                {selectedSession && (
                  <Restrictions
                    session={session}
                    retailers={retailers}
                    brand={brand}
                    readOnly={readOnly}
                    onClickPrev={this.onPrev}
                    onSubmit={this.onSubmitRestrictions}
                  />
                )}
              </Tabs.TabPane>
              <Tabs.TabPane
                tabKey="staff"
                tab="7. Allocated Staff"
                disabled={!filledSessionData.areas && !isActiveSession}
              >
                <AllocateStaff
                  history={history}
                  allStaff={allStaff}
                  areaManagers={areaManagers}
                  brand={brand}
                  session={session}
                  retailers={retailers}
                  staff={staff}
                  userIsAdmin={!readOnly}
                  userIsAgent={isAgent}
                  onDeleteSession={this.onToggleModal}
                  onPrevPress={this.onPrev}
                  onSubmit={this.onSubmitStaff}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tabKey="clients" tab="8. Invite Buyers" disabled={!filledSessionData.staff}>
                <InviteClients
                  salesSessionId={session._id}
                  brandId={brand._id}
                  session={session}
                  restrictToStaffId={isAgent && user._id}
                  market={session.market}
                  isSessionActive={session.status === "active"}
                  readOnly={readOnly}
                  onInviteClients={this.onInviteClients}
                  onSendFollowups={this.onSendFollowups}
                  onClickDeleteSession={this.onToggleModal}
                  onClickActivateSession={this.deboncedActiveSession}
                  onClickPrev={this.onPrev}
                />
              </Tabs.TabPane>
            </FullHeightTabs>
          )}
        </Container>
      </PageTitle>
    )
  }
}

SaleSession.propTypes = {
  allStaff: PropTypes.array,
  areaManagers: PropTypes.array,
  brand: PropTypes.object,
  clients: PropTypes.array,
  clientsRaw: PropTypes.object,
  fetchTimezone: PropTypes.func,
  fetchedTimezone: PropTypes.string,
  followUpRequestPending: PropTypes.bool,
  clientsRequestPending: PropTypes.bool,
  staffsRequestPending: PropTypes.bool,
  history: PropTypes.object,
  inviteError: PropTypes.string,
  inviteRequestPending: PropTypes.bool,
  match: PropTypes.object,
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  retailers: PropTypes.object,
  retailersRequestPending: PropTypes.bool,
  selectedSession: PropTypes.object,
  staff: PropTypes.object,
  user: PropTypes.object,
  onActivateSession: PropTypes.func,
  onCreateSession: PropTypes.func,
  onDeleteSession: PropTypes.func,
  onFetchSession: PropTypes.func,
  onFetchClients: PropTypes.func,
  onFetchStaffs: PropTypes.func,
  onFetchRetailers: PropTypes.func,
  onInviteClients: PropTypes.func,
  onSendFollowups: PropTypes.func,
  onUnselectSession: PropTypes.func,
  onUpdateSession: PropTypes.func,
}

export default SaleSession

import axios from "axios"

import { REST_API_URL } from "../../config"

export const addClient = async ({ data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/buyers`, data, config)

  return response.data
}

export const updateClient = async ({ buyerId, data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(`${REST_API_URL}/buyers/${buyerId}`, data, config)

  return response.data
}

export const addReport = async ({ data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/reports`, data, config)

  return response.data
}

export const updateReport = async ({ reportId, data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(`${REST_API_URL}/reports/${reportId}`, data, config)

  return response.data
}

export const findBuyers = async ({ queryParams, token }) => {
  const config = {
    params: queryParams,
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/buyers`, config)

  return response.data
}

export const findSharedBuyers = async ({ queryParams, token }) => {
  const config = {
    params: queryParams,
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/sharedBuyers`, config)

  return response.data
}

export const fetchReports = async ({ clientId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/reports?clientId=${clientId}`, config)

  return response.data
}

export const fetchClientBookings = async ({ clientId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/schedules/bookings/client/${clientId}`, config)

  return response.data
}

export const fetchReport = async ({ reportId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/reports/${reportId}`, config)

  return response.data
}

export const checkOut = async ({ sessionId, bookingId, token, offset }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      bookingId,
      sessionId,
      offset,
    },
  }
  const response = await axios.get(`${REST_API_URL}/dashboard/${sessionId}/bookings/${bookingId}/checkout`, config)

  return response.data
}

export const checkIn = async ({ bookingId, sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      type: "full",
    },
  }
  const response = await axios.get(`${REST_API_URL}/dashboard/${sessionId}/bookings/${bookingId}/arrived`, config)

  return response.data
}

export const uploadClients = async ({ file, token, brandId }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const body = {
    file: Buffer.from(file),
    brandId,
  }
  const response = await axios.post(`${REST_API_URL}/buyers/bulk/upload`, body, config)
  return response.data
}

export const fetchClientReport = async ({ clientId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/reports/export/client/${clientId}`, config)

  return response.data
}

export const deleteClient = async ({ clientId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.delete(`${REST_API_URL}/buyers/${clientId}`, config)

  return response.data
}

export const createUpdateRequest = async ({ type, staffId, clientId, fieldName, newValue, prevValue, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const body = {
    type,
    staffId,
    clientId,
    fieldName,
    newValue,
    prevValue,
  }
  const response = await axios.post(`${REST_API_URL}/changeRequests`, body, config)
  return response.data
}

import styled from "styled-components"
import { SkeletonBlock } from "modaresa-commons"

export const RoomHeader = styled.div`
  padding: 16px 30px;
  border-top: 1px solid ${p => p.theme.gray};
  background-color: ${p => p.theme.gray75};
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RoomName = styled.h4`
  margin: 0;
  color: #516bf0;
  font-weight: bold;
`

export const ToggleDisplay = styled.span`
  margin-left: 16px;
  color: ${p => p.theme.main};
  cursor: pointer;
  line-height: 19px;
  &:hover {
    text-decoration: underline;
  }
`

export const RoomNameSkeleton = styled(SkeletonBlock)`
  height: 20px;
  max-width: 150px;
`

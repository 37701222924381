import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  addRetailer,
  addRetailerBySharedRetailer,
  fetchSharedRetailers,
  selectRetailerMember,
  unselectRetailerMember,
  updateRetailer,
} from "../../../../../store/actions/retailers"
import {
  getBrand,
  getRetailerRequestError,
  getRetailerRequestStatus,
  getRetailers,
  getSelectedRetailer,
  getSharedRetailerRequestStatus,
  getSharedRetailers,
  getUserData,
} from "../../../../../store/selectors"

import Component from "./blocks/AddNewRetailer"

const selector = createStructuredSelector({
  user: getUserData,
  brand: getBrand,
  retailers: getRetailers,
  selectedRetailer: getSelectedRetailer,
  requestPending: getRetailerRequestStatus,
  requestError: getRetailerRequestError,
  sharedRetailers: getSharedRetailers,
  sharedRetailerRequestPending: getSharedRetailerRequestStatus,
})

const actions = {
  selectRetailerByID: selectRetailerMember,
  unselectRetailer: unselectRetailerMember,
  addRetailer,
  addRetailerBySharedRetailer,
  updateRetailer,
  fetchSharedRetailers,
}

export const AddNewRetailer = connect(selector, actions)(Component)

import React, { memo } from "react"

import { CompanyPosition, ContainerHeader, FullName, MainInfo } from "./StaffProfileHeader.styles"

type Props = {
  staff: {
    firstName: string,
    lastName: string,
    companyPosition: string,
    email: string,
    phoneNumber: string,
  },
}

const StaffProfileHeader = ({ staff }: Props) => (
  <ContainerHeader>
    <MainInfo>
      <FullName>
        {staff.firstName} {staff.lastName}
      </FullName>
      <CompanyPosition>{staff.companyPosition}</CompanyPosition>
    </MainInfo>
  </ContainerHeader>
)

export default memo(StaffProfileHeader)

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { getRequestLoginPending } from "../../../store/selectors"
import { login } from "../../../store/actions/auth"

import _Login from "./Login"

const selector = createStructuredSelector({
  requestPending: getRequestLoginPending,
})

const actions = {
  onLogin: login,
}

export const Login = connect(selector, actions)(_Login)

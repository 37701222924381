import { useQuery } from "@apollo/client"
import React from "react"
import { AlertDanger, OverlayLoading } from "modaresa-commons"

import { ME_LOGGED_STAFF } from "../../graphql/queries/user"

type Props = {
  hideLoading?: boolean,
}

const withLoggedUser = (WrappedComponent, { hideLoading, ignoreError }: Props = {}) => props => {
  const { loading, error, data = {} } = useQuery(ME_LOGGED_STAFF, {
    fetchPolicy: "cache-first",
  })
  if (loading && !hideLoading) {
    return <OverlayLoading />
  }
  if (error && !ignoreError) {
    console.error(error)
    return <AlertDanger>Authenticated User Error</AlertDanger>
  }
  const { me } = data
  return <WrappedComponent {...props} loggedUser={me} />
}

export default withLoggedUser

import React from "react"
import { Switch, Route } from "react-router-dom"

import Room from "./Room"
import Rooms from "./Rooms"

type Props = {
  brandId: string,
  canEdit: boolean,
  restrictToStaffIdIfAgent: string,
}

const CompanyRooms = ({ brandId, canEdit, canView, restrictToStaffIdIfAgent }: Props) => (
  <Switch>
    {canView && (
      <Route exact path={`/settings/company/rooms/:id`}>
        <Room brandId={brandId} canEdit={canEdit} restrictToStaffIdIfAgent={restrictToStaffIdIfAgent} />
      </Route>
    )}
    <Route exact path={`/settings/company/rooms`}>
      <Rooms brandId={brandId} canEdit={canEdit} canView={canView} />
    </Route>
  </Switch>
)

export default CompanyRooms

import React, { Component } from "react"
import PropTypes from "prop-types"
import Select from "react-select"

import { Filter as FilterContainer } from "./styles"

const styles = {
  placeholder: inlineCss => ({
    ...inlineCss,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    overflow: "hidden",
    paddingRight: "16px",
  }),
}
class Filter extends Component {
  state = {
    inputValue: "",
  }

  onInputChange = (value, action) => {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      this.setState({ inputValue: value })
      this.props.onFilter(value)
    }
  }

  render() {
    const { inputValue } = this.state
    return (
      <FilterContainer>
        <Select {...this.props} inputValue={inputValue} onInputChange={this.onInputChange} styles={styles} />
      </FilterContainer>
    )
  }
}

Filter.propTypes = {
  onFilter: PropTypes.func,
}

export { Filter }

import styled from "styled-components"
import { Loading } from "modaresa-commons"

import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const MargedErrorLivechat = styled(ErrorLiveChatHelp)`
  margin-top: 70px;
  cursor: pointer;
`

export const MargedLoading = styled(Loading)`
  margin-top: 70px;
`
export const TableContainer = styled.div`
  height: 100%;
`

export const DropdownMenuItem = styled.div`
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.gray75};
    text-decoration: none;
  }

  & a {
    color: ${props => props.theme.black};
  }
`
export const DropdownMenu = styled.div`
  background-color: #fff;
  min-width: 200px;
  text-decoration: none;
  ${({ border, theme }) =>
    border &&
    `border: 2px solid ${theme.veryLightGray};
  border-radius: 3px;

  & ${DropdownMenuItem}:not(:last-child) {
    border-bottom: 1px solid ${theme.veryLightGray}
  }`}
`
export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 10px 20px;
`
export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
`

export const Bold = styled.span`
  font-weight: bold;
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`
export const CloseModal = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.strawberry};
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`
export const Text = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`

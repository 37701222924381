import { useField } from "formik"
import React, { useCallback } from "react"
import styled from "styled-components"
import Select from "react-select"

import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

const ContainerSelect = styled.div`
  width: ${p => p.width || "200px"};
`

type Props = {
  label: string,
  labelWidth: string,
  name: string,
  direction?: "row" | "column",
  options: [any],
  selectWidth: string,
  disabled: boolean,
  placeholder: string,
  multiple: boolean,
  helpText: string,
}

const FormikSelect = ({
  label,
  labelWidth,
  name,
  options,
  direction,
  selectWidth,
  disabled,
  placeholder,
  multiple,
  helpText,
}: Props) => {
  const [field, meta, helpers] = useField(name)
  const handleChangeSelect = useCallback(value => helpers.setValue(value), [helpers])
  return (
    <FormFieldLayout
      label={label}
      labelWidth={labelWidth}
      name={name}
      direction={direction}
      error={meta.touched && meta.error}
      helpText={helpText}
    >
      <ContainerSelect width={selectWidth}>
        <Select
          id={name}
          options={options}
          value={field.value}
          onChange={handleChangeSelect}
          onBlur={field.onBlur}
          isDisabled={disabled}
          placeholder={placeholder}
          isMulti={multiple}
        />
      </ContainerSelect>
    </FormFieldLayout>
  )
}

FormikSelect.defaultProps = {
  direction: "row",
}
export default FormikSelect

import React, { memo } from "react"
import { Link } from "react-router-dom"

import FormRoom from "../../../../elements/FormRoom"

import { ContainerForm, ContainerRoomView, RoomName } from "./RoomView.styles"
import RoomViewSkeleton from "./RoomViewSkeleton"

type Props = {
  brandId: string,
  room: {
    name: string,
  },
  canEdit: boolean,
  loadingSubmit: boolean,
  skeleton: boolean,
  urlGoBack: string,
}

const RoomView = ({ skeleton, brandId, room, canEdit, onSubmit, loadingSubmit, urlGoBack }: Props) =>
  skeleton ? (
    <RoomViewSkeleton />
  ) : (
    <ContainerRoomView>
      <Link to={urlGoBack}>{`< Back to all rooms`}</Link>
      <RoomName>{room.name}</RoomName>
      <ContainerForm>
        <FormRoom brandId={brandId} room={room} canEdit={canEdit} onSubmit={onSubmit} loadingSubmit={loadingSubmit} />
      </ContainerForm>
    </ContainerRoomView>
  )

export default memo(RoomView)

import React, { memo } from "react"
import { Ul } from "modaresa-commons"

const generateAssignmentsSentence = ({ meetingResponsible, signee, notifiedStaffs }, staffId) => {
  const assignements = []
  if (meetingResponsible?.id === staffId) {
    assignements.push("Meeting responsible")
  }
  if (signee?.id === staffId) {
    assignements.push("Signee")
  }
  if (notifiedStaffs.find(notifiedStaff => notifiedStaff.id === staffId)) {
    assignements.push("Notified Staff")
  }
  return assignements.join(", ")
}

type Props = {
  staffId: string,
  areas: [],
  remainingArea: {},
}

const renderAreas = (areas, staffId) =>
  areas.map(area => <li key={area.id}>{`As a ${generateAssignmentsSentence(area, staffId)} for ${area.name}`}</li>)

const renderRemainingArea = (remainingArea, staffId) =>
  remainingArea && (
    <li key="settings-remaining-area">{`As a ${generateAssignmentsSentence(
      remainingArea,
      staffId,
    )} for remaining area`}</li>
  )
const StaffAssignmentsList = ({ staffId, areas, remainingArea, rooms }: Props) => (
  <Ul>
    {renderAreas(areas, staffId)}
    {renderRemainingArea(remainingArea, staffId)}
    {rooms &&
      rooms.map(room => (
        <li key={room.id}>
          Room: {room.name}
          <Ul>
            {renderAreas(room.areas, staffId)}
            {renderRemainingArea(room.remainingArea, staffId)}
          </Ul>
        </li>
      ))}
  </Ul>
)

export default memo(StaffAssignmentsList)

import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 54px);
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  min-height: 75px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: ${({ theme }) => theme.primary};
  justify-content: space-between;
`

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;

  ${({ image }) => {
    if (image) {
      return `
    font-size: 14px;
    flex-direction: column
    `
    }
  }}

  & img {
    object-fit: contain;
    width: 75%;
    height: 75%;
  }
`

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`

export const CloseModal = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.strawberry};
`

export const ModalBody = styled.div`
  & .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255);
  }
`
export const Bold = styled.span`
  font-weight: bold;
`
export const Text = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`
export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img`
  display: block;
  width: auto;
  height: 18px;
  cursor: pointer;
  margin: 0 10px;
  ${props => (props.rotate ? "transform: rotate(180deg)" : "")};
  ${props => (props.hidden ? "visibility: hidden" : "")};
`
export const Date = styled.span`
  color: #fff;
`

export const Appointment = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px solid ${({ theme }) => theme.veryLightGray};
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 20px 20px 15px 30px;
  min-height: 100px;
  font-size: 16px;
`

export const Appointments = styled.div`
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 60px;
  padding-right: 30px;
`
export const AppointmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: space-between;
`
export const AppointmentActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  max-width: 430px;
  justify-content: space-between;
`

export const Phone = styled.span`
  padding-left: 15px;
`

export const Time = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 490px;
`
export const TimeDivinder = styled.div`
  position: relative;
  border: 1px solid #dadfea;
  margin-bottom: 10px;

  & span {
    font-size: 16px;
    position: absolute;
    left: -8px;
    transform: translate(-100%, -50%);
  }
`
export const SeleteContainer = styled.div`
  width: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

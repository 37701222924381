import { useQuery } from "@apollo/client"
import React, { useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import { AlertDanger } from "modaresa-commons"

import { STAFF_FULL_PROFILE } from "../../../graphql/queries/staff"
import PageTitle from "../../elements/PageTitle/PageTitle"
import StaffProfile from "../../elements/StaffProfile"
import withLoggedUser from "../../hoc/withLoggedUser"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

type Props = {
  loggedUser: {
    staff: {
      id: string,
    },
  },
}

const MyProfile = ({ loggedUser }: Props) => {
  const { section } = useParams()
  const history = useHistory()
  const handleChangeTab = useCallback(
    newSection => {
      history.push(`/settings/profile/${newSection}`)
    },
    [history],
  )
  const { loading, error, data } = useQuery(STAFF_FULL_PROFILE, {
    variables: { id: loggedUser.staff.id },
  })
  if (loading && !data)
    return (
      <PageTitle title="My Profile">
        <StaffProfile skeleton showNotificationsTab activeSection={section} onChangeTab={handleChangeTab} />
      </PageTitle>
    )
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading staff</ErrorLiveChatHelp>
  const { Staff } = data
  if (!Staff) return <AlertDanger>This staff doesn't exist or has been deleted</AlertDanger>
  return (
    <PageTitle title={`My Profile`}>
      <StaffProfile canEdit showNotificationsTab activeSection={section} staff={Staff} onChangeTab={handleChangeTab} />
    </PageTitle>
  )
}

export default withLoggedUser(MyProfile)

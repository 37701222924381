export const getRoomSettings = timePeriod =>
  timePeriod.map(day => ({
    date: day.date,
    meetingCapacity: 1,
    jokers: 1,
    length: 60,
    models: 0.5,
    meals: {
      breakfast: false,
      lunch: false,
      snacks: false,
    },
  }))

import { gql } from "@apollo/client"

import AreaFragments from "../queries/area.fragments"
import BrandFragments from "../queries/brand.fragments"
import RemainingAreaFragments from "../queries/remainingArea.fragments"

export const UPDATE_BRAND_SETTINGS = gql`
  mutation UpdateBrandSettings(
    $brandId: ID!
    $areas: [AreaInput]
    $remainingArea: RemainingAreaInput
    $toggleRoomBasedAreas: Boolean
    $rooms: [RoomInput]
  ) {
    updateBrandSettings(
      brandId: $brandId
      areas: $areas
      remainingArea: $remainingArea
      toggleRoomBasedAreas: $toggleRoomBasedAreas
      rooms: $rooms
    ) {
      id
      toggleRoomBasedAreas
      remainingArea {
        ...RemainingAreaStaffs
        notificationMeetingConfirmation
        notificationMeetingReminder
      }
      areas {
        ...AreaStaffs
        ...AreaTerritory
        id
        name
        color
        notificationMeetingConfirmation
        notificationMeetingReminder
      }
      ...BrandRoomsAreas
    }
  }
  ${AreaFragments.fragments.staffs}
  ${AreaFragments.fragments.territory}
  ${RemainingAreaFragments.fragments.staffs}
  ${BrandFragments.fragments.roomsAreas}
`

export const UPDATE_BRAND_COMPETITOR_GROUPS = gql`
  mutation UpdateBrandCompetitorGroups($brandId: ID!, $competitorGroups: [BrandCompetitorGroupInput!]!) {
    updateBrandCompetitorGroups(brandId: $brandId, competitorGroups: $competitorGroups) {
      id
      competitorGroups {
        id
        name
        retailers {
          id
          name
        }
      }
    }
  }
`

import { call, put, select } from "redux-saga/effects"

import * as Api from "../../helpers/api"
import * as socketEvents from "../sockets/messageTypes"
import { takeLatest } from "../../helpers/saga"

import { onToastError } from "./../../helpers/toast"

import { normalizeData } from "helpers/normalize"
import {
  ADD_RETAILER,
  ADD_RETAILER_BY_SRETAILER_ID,
  DELETE_RETAILER,
  FETCH_CITIES,
  FETCH_RETAILERS,
  FETCH_SHARED_RETAILERS,
  UPDATE_RETAILER,
} from "store/actions/retailers"

function* createRetailer({ payload }) {
  const { data } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.CREATE_RETAILER,
        body: { data },
      },
    })
  } catch (error) {
    const customError = "Oops, something went wrong"
    onToastError(customError)
  }
}

function* createRetailerFlow() {
  yield takeLatest(ADD_RETAILER.REQUEST, createRetailer)
}

function* deleteRetailer({ payload }) {
  const { retailerId } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.DELETE_RETAILER,
        body: { id: retailerId },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* deleteRetailerFlow() {
  yield takeLatest(DELETE_RETAILER.REQUEST, deleteRetailer)
}

function* updateRetailer({ payload }) {
  const { data, id } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.UPDATE_RETAILER,
        body: { data, id },
      },
    })
  } catch (error) {
    const customError = "Oops, something went wrong"
    onToastError(customError)
  }
}

function* updateRetailerFlow() {
  yield takeLatest(UPDATE_RETAILER.REQUEST, updateRetailer)
}

function* fetchSharedRetailers() {
  const state = yield select()
  const { accessToken } = state.auth
  try {
    const response = yield call(Api.fetchSharedRetailers, {
      token: accessToken,
    })
    yield put({ type: FETCH_SHARED_RETAILERS.SUCCESS, payload: response })
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (error && (error.errorType === "custom" || error.errorType === "db")) {
      customError = error.message
    }
    onToastError(customError)

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_SHARED_RETAILERS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchSharedRetailersFlow() {
  yield takeLatest(FETCH_SHARED_RETAILERS.REQUEST, fetchSharedRetailers)
}

function* fetchRetailers({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { brandId } = payload
  try {
    const response = yield call(Api.fetchRetailers, {
      brandId,
      token: accessToken,
    })

    const retailers = normalizeData(response)

    yield put({ type: FETCH_RETAILERS.SUCCESS, payload: retailers })
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: FETCH_RETAILERS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchRetailersFlow() {
  yield takeLatest(FETCH_RETAILERS.REQUEST, fetchRetailers)
}

export function* addRetailerBySharedRetailer({ payload }) {
  const { id, brandId } = payload
  const data = { id, brandId }
  yield put({
    type: "SEND_WEBSOCKET_MESSAGE",
    payload: {
      eventType: socketEvents.CREATE_RETAILER_BY_SHARED_RETAILER,
      body: { data },
    },
  })
}

function* addRetailerBySharedRetailerFlow() {
  yield takeLatest(ADD_RETAILER_BY_SRETAILER_ID.REQUEST, addRetailerBySharedRetailer)
}

function* fetchCities({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { countryCode } = payload

  try {
    const response = yield call(Api.fetchCities, {
      token: accessToken,
      countryCode,
    })
    yield put({ type: FETCH_CITIES.SUCCESS, payload: response })
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (error && (error.errorType === "custom" || error.errorType === "db")) {
      customError = error.message
    }
    onToastError(customError)

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_CITIES.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchCitiesFlow() {
  yield takeLatest(FETCH_CITIES.REQUEST, fetchCities)
}

export default [
  createRetailerFlow,
  updateRetailerFlow,
  deleteRetailerFlow,
  fetchSharedRetailersFlow,
  fetchRetailersFlow,
  addRetailerBySharedRetailerFlow,
  fetchCitiesFlow,
]

import forEach from "lodash/forEach"
import groupBy from "lodash/groupBy"
import isEmpty from "lodash/isEmpty"

const isValidPeriod = (from, to) => from < to

const mealTypes = ["breakfast", "lunch", "snacks"]

export const validateShowroomStructure = values => {
  const errors = {}
  const { walkthrough, businessMeeting, meals, rooms } = values
  if (walkthrough.available && !walkthrough.length) errors.walkthrough = "Select a length"
  if (businessMeeting.available && !businessMeeting.length) errors.businessMeeting = "Select a length"
  const mealsErrors = {}
  mealTypes.forEach(type => {
    const meal = meals[type]
    if (meal.available) {
      if (!meal.from) mealsErrors[type] = "Select start time"
      else if (!meal.to) mealsErrors[type] = "Select end time"
      else if (!isValidPeriod(meal.from.value, meal.to.value)) mealsErrors[type] = "Invalid time period"
    }
  })
  if (!isEmpty(mealsErrors)) errors.meals = mealsErrors
  rooms.forEach((room, index) => {
    if (!room.name) errors[`rooms.${index}`] = "Name is required"
  })
  if (rooms.length < 1) errors.rooms = "Add at least 1 room"

  return errors
}

export const validateStaffMember = (values, extraData) => {
  const errors = {}
  const { roomResponsibility, modaresaRole, geographicResponsibility } = values
  const { roomDelim } = extraData
  if (roomDelim && (modaresaRole === "manager" || modaresaRole === "freelancer")) {
    if (roomResponsibility.length < 1 && geographicResponsibility.countries.length > 0)
      errors.rooms = "Room is Required"
  }
  return errors
}

export const validateCompanyStructure = values => {
  const errors = {}
  const { roomDelim, rooms, areas, countryOptions } = values
  if (rooms.length < 1) {
    errors.rooms = "Create Rooms"
  }
  if (roomDelim) {
    rooms.forEach(room => {
      let linkedCount = 0
      areas.forEach(area => {
        if (area.room && area.room.value === room) linkedCount += 1
      })
      if (linkedCount < 1) {
        errors[`rooms.usage`] = "All rooms should be used"
      }
    })
    const groupedByRoom = groupBy(areas, "room.value")
    forEach(groupedByRoom, (areas, key) => {
      const countriesList = areas.map(area => area.countries)
      const results = containsDuplicates(countriesList)

      if (results.length > 0) {
        errors[`rooms.${key}`] = `Already added: ${results.map(result => result.label)}`
      }
    })
  }
  areas.forEach((area, index) => {
    if (!area.name) errors[`areas.${index}.name`] = "Name is Required"
    if (area.countries.length < 1) errors[`areas.${index}.countries`] = "Countries are Required"
    if (!area.color) errors[`areas.${index}.color`] = "Color is Required"
    if (roomDelim && !area.room) errors[`areas.${index}.room`] = "Room is Required"
  })

  let countriesLeft = countryOptions
  if (countriesLeft.length > 1) {
    countriesLeft.shift()

    countriesLeft = countriesLeft.map(country => country.label.trim()).join(", ")

    errors.allCountriesShouldbeAllocated = `NB! There are still countries that have not been allocated to an area. Please add ${countriesLeft} to their area and press ‘Save Changes’ again.`
  }

  return errors
}

export const validateCompetitorGroup = values => {
  const errors = {}
  const { competitorGroups } = values
  if (competitorGroups.length < 1) {
    errors.groups = "Create Group"
  }
  competitorGroups.forEach((group, index) => {
    if (!group.name) errors[`groups.${index}.name`] = "Name is Required"
    if (!group.retailers || group.retailers.length < 1) errors[`groups.${index}.retailers`] = "Retailers are Required"
  })
  return errors
}

class MapCnt extends Map {
  cnt(iterable) {
    const set = new Set(iterable)
    for (const item of set) {
      // eslint-disable-next-line prefer-const
      let { country = item, count = 0 } = this.get(item) || 0
      ++count
      this.set(item, { country, count })
    }
  }
}

function containsDuplicates(...allArrays) {
  const cntObj = new MapCnt()
  for (const array of allArrays) {
    cntObj.cnt(array)
  }
  const output = []
  for (const [, value] of cntObj.entries()) {
    if (value.count > 1) {
      output.push(value.country)
    }
  }
  return output
}

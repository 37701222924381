import { gql } from "@apollo/client"

export const FRAGMENT_PLACE_INFOS = gql`
  fragment PlaceInfos on Place {
    name
    country
    formattedAddress
    googlePlaceId
  }
`
export const FRAGMENT_FULL_TERRITORIES = gql`
  fragment FullTerritories on Territories {
    continents
    countries
    regions {
      ...PlaceInfos
    }
    counties {
      ...PlaceInfos
    }
    cities {
      ...PlaceInfos
    }
    retailers {
      id
      name
    }
  }
  ${FRAGMENT_PLACE_INFOS}
`

const TerritoriesFragments = {
  fragments: {
    full: FRAGMENT_FULL_TERRITORIES,
    place: FRAGMENT_PLACE_INFOS,
  },
}

export default TerritoriesFragments

import { isNil, orderBy } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import Select from "react-select"
import { theme } from "modaresa-commons"

import { SelectContainer, ValidationError } from "components/ui/Select/common"
import { normalizeData } from "helpers/normalize"

export const RetailerTypes = Object.freeze({
  SHARED_RETAILER: "SHARED_RETAILER",
  RETAILER: "RETAILER",
})

const loadingIndicatorStyles = provided => ({
  ...provided,
  fontSize: 7,
  color: theme.primary,
})

const control = base => ({
  ...base,
  borderColor: theme.strawberry,
})

const menuList = base => ({
  ...base,
  marginTop: "0px",
})

export const setOptionFromRetailer = ({ _id, name, storeAddress, __retailerType }) => ({
  value: _id,
  label: `${name} (${storeAddress.city}, ${storeAddress.country})`,
  __retailerType,
})

/**
 * Mix retailers
 *
 * For each shared retailer, if there is a corresponding retailer in the retailers list
 * use it, otherwise use the shared retailer
 *
 * Add the relevant type from RetailerTypes to the returned data
 *
 * @param {array} retailers
 * @param {array} sharedRetailers
 */
function mixRetailers(retailers, sharedRetailers) {
  if (isNil(sharedRetailers)) {
    return []
  }

  if (isNil(retailers)) {
    return sharedRetailers.map(sharedRetailer => ({
      __retailerType: RetailerTypes.SHARED_RETAILER,
      ...sharedRetailer,
    }))
  }

  const nonArchivedRetailers = retailers.filter(retailer => retailer.dbStatus !== "archived")
  const retailersNormalized = normalizeData(nonArchivedRetailers, "sharedRetailerId")
  const mixedRetailers = sharedRetailers.map(sharedRetailer => {
    const existingRetailer = retailersNormalized.byId[sharedRetailer._id]
    return !isNil(existingRetailer)
      ? { __retailerType: RetailerTypes.RETAILER, ...existingRetailer }
      : { __retailerType: RetailerTypes.SHARED_RETAILER, ...sharedRetailer }
  })

  return mixedRetailers
}

const RetailerSelect = props => {
  const {
    errors,
    touched,
    name,
    isLoading,
    selectedRetailer,
    retailers,
    sharedRetailers,
    onChange,
    user,
    brand,
    ...other
  } = props
  const mixedRetailers = mixRetailers(retailers, sharedRetailers)
  const orderedMixesRetailers = orderBy(mixedRetailers, [retailer => retailer.name.toLowerCase()])
  const isUserAgent = user.modaresaRole === "agent"
  const userAssignedAreas = user.geographicResponsibility?.areas

  const userAssignedBrandAreas =
    userAssignedAreas.length > 0
      ? brand.areas
          .filter(area => userAssignedAreas.some(userArea => userArea === area._id))
          .map(area => area.territories.countries)
      : null

  const retailersUserCanAddTo = isUserAgent
    ? orderedMixesRetailers.filter(retailer =>
        userAssignedBrandAreas
          ? userAssignedBrandAreas?.some(country =>
              country.some(country => country === retailer?.storeAddress?.country),
            )
          : true,
      )
    : orderedMixesRetailers

  return (
    <SelectContainer>
      <Select
        isLoading={isLoading}
        options={retailersUserCanAddTo.map(setOptionFromRetailer)}
        placeholder="Select a retailer"
        styles={{
          loadingIndicator: loadingIndicatorStyles,
          control: errors ? control : "",
          menu: menuList,
        }}
        value={selectedRetailer}
        onChange={value => onChange(value, name)}
        {...other}
      />
      {errors && touched && <ValidationError>{errors}</ValidationError>}
    </SelectContainer>
  )
}

RetailerSelect.propTypes = {
  isLoading: PropTypes.bool,
  selectedRetailer: PropTypes.object,
  sharedRetailers: PropTypes.array,
  onChange: PropTypes.func,
}

export { RetailerSelect }

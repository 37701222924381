import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  arrivedToAppointment,
  checkNewBookingTime,
  delayAppoinment,
  deleteAppointment,
  fetchAvailableHosts,
  fetchSchedule,
  filterAppointment,
  leaveSocketRoom,
  resetFilterAppointment,
  updateAppoinmentHost,
} from "../../../../../store/actions/schedules"
import {
  getActiveSessions,
  getAppointmentAvailableHosts,
  getAppointmentRequestError,
  getAppointmentRequestStatus,
  getAvailableHostsFetchingStatus,
  getBrand,
  getClients,
  getFilteredSchedule,
  getFiltertAttendanceSchedule,
  getRetailers,
  getRetailersRequestPending,
  getScheduleRequestStatus,
  getClientsRequestStatus,
  getStaffRequestStatus,
  getSelectedSession,
  getSessionOptions,
  getStaff,
  getUserData,
} from "../../../../../store/selectors"

import Component from "./Attendance"

import { fetchClients } from "store/actions/clients"
import { fetchStaffs } from "store/actions/staff"
import { fetchRetailers } from "store/actions/retailers"

const selector = createStructuredSelector({
  user: getUserData,
  brand: getBrand,
  retailers: getRetailers,
  clients: getClients,
  sessions: getActiveSessions,
  selectedSchedule: getFilteredSchedule,
  selectedSession: getSelectedSession,
  staffs: getStaff,
  attendanceSchedule: getFiltertAttendanceSchedule,
  requestSchedulePending: getScheduleRequestStatus,
  clientsRequestPending: getClientsRequestStatus,
  retailersRequestPending: getRetailersRequestPending,
  staffsRequestPending: getStaffRequestStatus,
  requestPending: getAppointmentRequestStatus,
  requestError: getAppointmentRequestError,
  requestHostsPending: getAvailableHostsFetchingStatus,
  availableHosts: getAppointmentAvailableHosts,
  sessionOptions: getSessionOptions,
})

const actions = {
  onCheckNewBookingTime: checkNewBookingTime,
  onDeleteAppointment: deleteAppointment,
  onDelayAppointment: delayAppoinment,
  onUpdateHost: updateAppoinmentHost,
  onArrived: arrivedToAppointment,
  onFetchSchedule: fetchSchedule,
  onFetchClients: fetchClients,
  onFetchStaffs: fetchStaffs,
  onFetchRetailers: fetchRetailers,
  onLeaveSocketRoom: leaveSocketRoom,
  onSelectFilter: filterAppointment,
  onResetFilter: resetFilterAppointment,
  onFetchHosts: fetchAvailableHosts,
}

export const Attendance = connect(selector, actions)(Component)

import styled from "styled-components"
import { BooleanStatusIcon } from "modaresa-commons"

import DropdownActions from "../../DropdownActions"

export const ViewAll = styled.span`
  cursor: pointer;
  color: ${p => p.theme.main};
  &:hover {
    text-decoration: underline;
  }
`
export const CenteredDropdownActions = styled(DropdownActions)`
  height: 38px;
  width: 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const StyledBooleanStatusIcon = styled(BooleanStatusIcon)`
  margin-top: 8px;
`

export const TdIcon = styled.td`
  text-align: center;
`

import { call, put, select, take } from "redux-saga/effects"
import Raven from "raven-js"
import XLSX from "xlsx"
import moment from "moment-timezone"

import * as Api from "../../helpers/api"
import * as socketEvents from "../sockets/messageTypes"
import {
  ADD_BUYER_WITH_NEW_DEFAULT_RETAILER,
  ADD_CLIENT,
  ADD_REPORT,
  CHECK_IN,
  CHECK_OUT,
  CREATE_UPDATE_REQUEST,
  DELETE_CLIENT,
  EXPORT_REPORTS,
  FETCH_CLIENTS,
  FETCH_CLIENT_BOOKINGS,
  FETCH_REPORT,
  FETCH_REPORTS,
  FIND_BUYERS,
  FIND_SHARED_BUYERS,
  UPDATE_CLIENT,
  UPDATE_REPORT,
  UPLOAD_CLIENTS,
} from "../actions/clients"
import { takeLatest } from "../../helpers/saga"

import { onToastError, onToastSuccess } from "./../../helpers/toast"

import { ADD_RETAILER_BY_SRETAILER_ID } from "store/actions/retailers"
import { addRetailerBySharedRetailer } from "store/sagas/retailers"
import { normalizeData } from "helpers/normalize"

function* addClient({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, callbackSuccess } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.CREATE_BUYER,
        body: { data, token: accessToken },
      },
    })

    if (typeof callbackSuccess === "function") {
      yield take(ADD_CLIENT.SUCCESS)
      callbackSuccess()
    }
  } catch (error) {
    onToastError()
  }
}

function* addClientFlow() {
  yield takeLatest(ADD_CLIENT.REQUEST, addClient)
}

function* addBuyerWithNewDefaultRetailer({ sharedRetailerId, brandId, buyerData, callbackSuccess }) {
  yield* addRetailerBySharedRetailer({
    payload: {
      id: sharedRetailerId,
      brandId,
    },
  })
  const { payload: retailer } = yield take(ADD_RETAILER_BY_SRETAILER_ID.SUCCESS)
  const { _id: retailerId } = retailer
  buyerData.retailerId = retailerId
  yield* addClient({ payload: { data: buyerData } })
  yield take(ADD_CLIENT.SUCCESS)
  onToastSuccess(
    `Congrats! You can now invite ${buyerData.firstName} ${buyerData.lastName} to your sales session, and ${retailer.name} was added to your stockists list.`,
  )
  if (typeof callbackSuccess === "function") {
    callbackSuccess()
  }
}

function* addBuyerWithNewDefaultRetailerFlow() {
  yield takeLatest(ADD_BUYER_WITH_NEW_DEFAULT_RETAILER.REQUEST, addBuyerWithNewDefaultRetailer)
}

function* updateClient({ payload, callbackSuccess }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, id } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.UPDATE_BUYER,
        body: { data, token: accessToken, id },
      },
    })
    yield take(UPDATE_CLIENT.SUCCESS)
    if (typeof callbackSuccess === "function") {
      callbackSuccess(payload)
    }
  } catch (error) {
    onToastError()
  }
}

function* updateClientFlow() {
  yield takeLatest(UPDATE_CLIENT.REQUEST, updateClient)
}

function* fetchClients({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { brandId } = payload
  try {
    const response = yield call(Api.fetchClients, {
      brandId,
      token: accessToken,
    })

    const clients = normalizeData(response)

    yield put({ type: FETCH_CLIENTS.SUCCESS, payload: clients })
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: FETCH_CLIENTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchClientsFlow() {
  yield takeLatest(FETCH_CLIENTS.REQUEST, fetchClients)
}

function* findBuyers({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { queryParams, callbackSuccess, callbackFailure } = payload
  try {
    const response = yield call(Api.findBuyers, {
      queryParams,
      token: accessToken,
    })

    yield put({ type: FIND_BUYERS.SUCCESS, payload: response })
    if (response.length > 0) {
      typeof callbackSuccess === "function" && callbackSuccess(queryParams, response)
    } else {
      typeof callbackFailure === "function" && callbackFailure()
    }
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: FIND_BUYERS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* findBuyersFlow() {
  yield takeLatest(FIND_BUYERS.REQUEST, findBuyers)
}

function* findSharedBuyers({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { queryParams, callbackSuccess, callbackFailure } = payload
  try {
    const response = yield call(Api.findSharedBuyers, {
      queryParams,
      token: accessToken,
    })

    yield put({ type: FIND_SHARED_BUYERS.SUCCESS, payload: response })
    if (response.length > 0) {
      typeof callbackSuccess === "function" && callbackSuccess(queryParams, response)
    } else {
      typeof callbackFailure === "function" && callbackFailure()
    }
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: FIND_SHARED_BUYERS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* findSharedBuyersFlow() {
  yield takeLatest(FIND_SHARED_BUYERS.REQUEST, findSharedBuyers)
}

function* addReport({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, history } = payload
  try {
    const response = yield call(Api.addReport, { data, token: accessToken })

    yield put({ type: ADD_REPORT.SUCCESS, payload: response })
    if (history) {
      history.replace(`/calendars?staffId=${response.host}&sessionId=${response.session}`)
    } else {
      onToastSuccess("Report was successfully created.")
    }
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: ADD_REPORT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* addReportFlow() {
  yield takeLatest(ADD_REPORT.REQUEST, addReport)
}

function* updateReport({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, reportId, history } = payload
  try {
    const response = yield call(Api.updateReport, {
      data,
      reportId,
      token: accessToken,
    })

    yield put({ type: UPDATE_REPORT.SUCCESS, payload: response })

    if (history) {
      history.replace(`/calendars?staffId=${response.host}&sessionId=${response.session}`)
    } else {
      onToastSuccess("Report was successfully updated.")
    }
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: UPDATE_REPORT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateReportFlow() {
  yield takeLatest(UPDATE_REPORT.REQUEST, updateReport)
}

function* fetchReports({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { clientId } = payload
  try {
    const response = yield call(Api.fetchReports, {
      clientId,
      token: accessToken,
    })

    yield put({ type: FETCH_REPORTS.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: FETCH_REPORTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchReportsFlow() {
  yield takeLatest(FETCH_REPORTS.REQUEST, fetchReports)
}

function* fetchReport({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { reportId } = payload

  try {
    const response = yield call(Api.fetchReport, {
      reportId,
      token: accessToken,
    })

    yield put({ type: FETCH_REPORT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: FETCH_REPORT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchReportFlow() {
  yield takeLatest(FETCH_REPORT.REQUEST, fetchReport)
}

function* fetchClientBookins({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { clientId } = payload
  try {
    const response = yield call(Api.fetchClientBookings, {
      clientId,
      token: accessToken,
    })

    yield put({ type: FETCH_CLIENT_BOOKINGS.SUCCESS, payload: response })
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.JOIN_SOCKET_ROOM,
        body: { room: clientId, type: "client_bookings" },
      },
    })
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: FETCH_CLIENT_BOOKINGS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchClientBookinsFlow() {
  yield takeLatest(FETCH_CLIENT_BOOKINGS.REQUEST, fetchClientBookins)
}

function* checkOut({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { bookingId, sessionId, history, staffId } = payload
  const offset = moment().utcOffset()
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.CHECKOUT_HOST,
        body: { sessionId, bookingId, token: accessToken, offset },
      },
    })
    yield call(history.push, `/calendars?staffId=${staffId}&sessionId=${sessionId}`)
  } catch (error) {
    Raven.captureException(error)
  }
}
function* checkOutFlow() {
  yield takeLatest(CHECK_OUT.REQUEST, checkOut)
}

function* checkIn({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { bookingId, sessionId } = payload

  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.CHECKIN_HOST,
        body: { bookingId, sessionId, token: accessToken },
      },
    })
  } catch (error) {
    Raven.captureException(error)
  }
}
function* checkInFlow() {
  yield takeLatest(CHECK_IN.REQUEST, checkIn)
}

function* uploadClients({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { file, brandId } = payload

  try {
    const response = yield call(Api.uploadClients, {
      file,
      token: accessToken,
      brandId,
    })

    yield put({ type: UPLOAD_CLIENTS.SUCCESS, payload: response })
    onToastSuccess("Clients and Retailers added")
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: UPLOAD_CLIENTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* uploadClientsFlow() {
  yield takeLatest(UPLOAD_CLIENTS.REQUEST, uploadClients)
}

function* exportReports({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { clientId } = payload
  try {
    const response = yield call(Api.fetchClientReport, {
      clientId,
      token: accessToken,
    })

    XLSX.writeFile(response.wb, response.name)
  } catch (error) {
    onToastError()

    const errosMsg = (error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: EXPORT_REPORTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* exportReportsFlow() {
  yield takeLatest(EXPORT_REPORTS.REQUEST, exportReports)
}

function* deleteClient({ payload }) {
  const { clientId } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.DELETE_BUYER,
        body: { id: clientId },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* deleteClientFlow() {
  yield takeLatest(DELETE_CLIENT.REQUEST, deleteClient)
}

function* createUpdateRequest({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { user } = state.user
  try {
    const response = yield call(Api.createUpdateRequest, {
      ...payload,
      staffId: user._id,
      token: accessToken,
    })
    yield put({ type: CREATE_UPDATE_REQUEST.SUCCESS })
    yield put({ type: UPDATE_CLIENT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      (error.response && error.response.data.errors && error.response.data.errors.message) || error.message
    yield put({
      type: CREATE_UPDATE_REQUEST.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateEmailFlow() {
  yield takeLatest(CREATE_UPDATE_REQUEST.REQUEST, createUpdateRequest)
}

export default [
  exportReportsFlow,
  addClientFlow,
  addBuyerWithNewDefaultRetailerFlow,
  updateClientFlow,
  addReportFlow,
  fetchReportsFlow,
  fetchReportFlow,
  fetchClientBookinsFlow,
  fetchClientsFlow,
  findBuyersFlow,
  findSharedBuyersFlow,
  updateReportFlow,
  checkOutFlow,
  checkInFlow,
  uploadClientsFlow,
  deleteClientFlow,
  updateEmailFlow,
]

import PropTypes from "prop-types"
import React from "react"
import { AlertWarning, Input, Flex } from "modaresa-commons"

import {
  meetingApproachValues,
  meetingTypesValues,
  status as statusList,
  videoToolNames,
} from "../../../config/session"
import { getStaffLabel } from "../../../helpers/users"
import { Select } from "../../ui"

import {
  Bold,
  BookingModalContainer,
  BookingModalDialog,
  BookingModalDialogBody,
  BookingModalHeader,
  Budget,
  CloseIcon,
} from "./styles"

const customStyles = {
  overlay: {
    zIndex: 21,
  },
}

const BookingModal = ({ children, className, isOpen, onRequestClose }) => (
  <BookingModalContainer className={className} isOpen={isOpen} style={customStyles} onRequestClose={onRequestClose}>
    <BookingModalDialog>
      <CloseIcon onClick={onRequestClose}>&#10006;</CloseIcon>
      {children}
    </BookingModalDialog>
  </BookingModalContainer>
)

BookingModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
}

const Header = ({ user, hostId, retailer, room, meetingType, meetingApproach, status, light, dark }) => (
  <BookingModalHeader dark={dark} light={light} style={{ background: statusList[status] && statusList[status].bg }}>
    <Flex align="flex-start">
      <div>
        {(user.modaresaRole !== "agent" || hostId === user._id) && (
          <p>
            <Bold>
              {retailer.name} - {retailer.origin.city}, {retailer.origin.country.toUpperCase()}
            </Bold>
          </p>
        )}
        <p>
          {room}
          {meetingType && ` - ${meetingTypesValues[meetingType]}`}
          {meetingApproach && ` - ${meetingApproachValues[meetingApproach]}`}
        </p>
      </div>
      {retailer.clientId && user.modaresaRole !== "agent" && (
        <div>
          <Bold>Retailer ID - {retailer.clientId}</Bold>
        </div>
      )}
    </Flex>
  </BookingModalHeader>
)

Header.propTypes = {
  dark: PropTypes.bool,
  light: PropTypes.bool,
  meetingType: PropTypes.string,
  retailer: PropTypes.object,
  room: PropTypes.string,
  status: PropTypes.string,
}

const MeetingBudget = ({ value, onChange, disabled }) => (
  <Budget>
    <span>Expected Budget</span>
    <Input disabled={disabled} name="budget" placeholder="Optional €" value={value} width="50%" onChange={onChange} />
  </Budget>
)

MeetingBudget.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
}

const AvailableStaff = ({
  isStaffFetched,
  isDisableEdit,
  isFetchingMeetingStaff,
  meetingApproach,
  videoTool,
  canChangeVideoTool,
  availableHosts,
  otherHosts,
  host,
  onChange,
  onMenuOpen,
  booking,
}) => {
  const isVideoMeeting = meetingApproach === "video-conference"
  let hosts = availableHosts || []
  let others = otherHosts || []
  if (isVideoMeeting) {
    hosts = hosts.filter(host => host.videoIDs && Object.values(host.videoIDs).filter(id => id).length)
    others = otherHosts.filter(host => host.videoIDs && Object.values(host.videoIDs).filter(id => id).length)
  }
  const availableHostsArray = hosts.map(member => ({
    value: member._id,
    label: getStaffLabel(member),
    videoTools: member.videoIDs,
  }))
  const otherHostsArray = others.map(member => ({
    value: member._id,
    label: getStaffLabel(member),
  }))

  const findHost = () => {
    if (!host) return null
    return hosts.find(h => h._id === host.value) || others.find(h => h._id === host.value) || null
  }

  const selectedHost = findHost()
  const availableVideoIDs = (selectedHost && selectedHost.videoIDs) || {}
  const videoToolsAvailable = Object.keys(availableVideoIDs).reduce((options, tool) => {
    if (availableVideoIDs[tool]) {
      options.push({
        value: tool,
        label: videoToolNames[tool],
      })
    }
    return options
  }, [])

  let selectedVideoTool = null
  if (isVideoMeeting && videoTool) {
    selectedVideoTool = {
      value: videoTool,
      label: videoToolNames[videoTool],
    }
  }

  const hostOptions = [
    {
      value: "- No Meeting Host assigned -",
      label: "- No Meeting Host assigned -",
    },
    {
      label: `Recommended (${availableHostsArray.length})`,
      options: availableHostsArray,
    },
    {
      label: `Others (${otherHostsArray.length})`,
      options: otherHostsArray,
    },
  ]

  const formatOptionLabel = item => (
    <div style={{ display: "flex" }}>
      <div>{item.label}</div>
      <div style={{ marginLeft: "10px", color: item.value === booking?.host ? "#CCC" : "#4CAF50" }}>
        {item.label !== "- No Meeting Host assigned -" &&
          item?.videoTools &&
          item?.videoTools[booking?.videoTool] !== "" &&
          videoToolNames[booking?.videoTool]}
      </div>
    </div>
  )

  return (
    <div>
      {isStaffFetched && !availableHostsArray.length && !otherHostsArray.length && (
        <AlertWarning margin="0 0 20px 0">No available hosts for the requested time slot</AlertWarning>
      )}
      <Flex margin="0 0 20px 0">
        <span>Meeting Host</span>
        <div style={{ width: "60%" }}>
          <Select
            isDisabled={isDisableEdit}
            isLoading={isFetchingMeetingStaff}
            options={hostOptions}
            placeholder="Meeting Host"
            value={host}
            formatOptionLabel={formatOptionLabel}
            onChange={option => {
              onChange("host", option)
            }}
            onMenuOpen={onMenuOpen}
            menuPosition={"fixed"}
          />
        </div>
      </Flex>
      {host && isVideoMeeting && (
        <Flex margin="0 0 20px 0">
          <span>Video Tool</span>
          <div style={{ width: "60%" }}>
            {canChangeVideoTool ? (
              <Select
                isDisabled={isDisableEdit}
                isLoading={isFetchingMeetingStaff}
                options={videoToolsAvailable}
                placeholder="Video Tool"
                value={selectedVideoTool}
                onChange={option => {
                  onChange("videoTool", option.value)
                }}
                onMenuOpen={onMenuOpen}
                menuPosition={"fixed"}
              />
            ) : (
              videoToolNames[videoTool]
            )}
          </div>
        </Flex>
      )}
    </div>
  )
}

AvailableStaff.propTypes = {
  availableHosts: PropTypes.array,
  availableStaff: PropTypes.array,
  host: PropTypes.any,
  isDisableEdit: PropTypes.bool,
  isFetchingMeetingStaff: PropTypes.bool,
  meetingResp: PropTypes.any,
  onChange: PropTypes.func,
  onMenuOpen: PropTypes.func,
  isStaffFetched: PropTypes.bool,
}

export { BookingModalHeader, BookingModal, BookingModalDialogBody, Header, MeetingBudget, AvailableStaff }

import React, { memo } from "react"
import { ButtonToggleModal } from "modaresa-commons"

import ModalDeleteBrandRoom from "./ModalDeleteBrandRoom"

type Props = {
  brandId: string,
  roomId: string,
}

const ButtonDeleteBrandRoom = ({ brandId, roomId, children }: Props) => (
  <ButtonToggleModal componentButton={children} labelButton="Delete">
    {({ onClose }) => <ModalDeleteBrandRoom onClose={onClose} brandId={brandId} roomId={roomId} />}
  </ButtonToggleModal>
)
export default memo(ButtonDeleteBrandRoom)

import { gql } from "@apollo/client"

export const FRAGMENT_BOOKING_EVENT_SWITCH = gql`
  fragment BookingEventSwitch on BookingEvent {
    id
    room
    retailer {
      id
      name
      storeAddress {
        city
        country
      }
    }
    host {
      id
      fullName
    }
  }
`

const BookingEventFragments = {
  fragments: {
    switch: FRAGMENT_BOOKING_EVENT_SWITCH,
  },
}

export default BookingEventFragments

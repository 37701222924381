import styled from "styled-components"

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const TableContainer = styled.div`
  height: 100%;
`
export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 10px 20px;
`

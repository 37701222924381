import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SkeletonContainerTabContent = styled.div`
  padding: 30px;
`

export const SkeletonContainerHeader = styled.div`
  padding: 16px 30px 0 30px;
`

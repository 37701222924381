import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  activateSession,
  createSession,
  deleteSession,
  fetchSaleSession,
  fetchTimezone,
  unselectSession,
  updateSession,
} from "../../../../../store/actions/salesSessions"
import {
  getAllStaff,
  getAreaManagers,
  getBrand,
  getFetchedTimezone,
  getFilteredSessionClients,
  getClients,
  getClientsRequestStatus,
  getStaffRequestStatus,
  getFollowUpRequestStatus,
  getInviteRequestError,
  getInviteRequestStatus,
  getRetailers,
  getSelectedSession,
  getSessionRequestError,
  getSessionRequestStatus,
  getStaff,
  getUserData,
  getRetailersRequestPending,
} from "../../../../../store/selectors"
import { inviteClients, sendFollowups } from "../../../../../store/actions/invites"

import Component from "./SaleSession"

import { fetchClients } from "store/actions/clients"
import { fetchStaffs } from "store/actions/staff"
import { fetchRetailers } from "store/actions/retailers"

const selector = createStructuredSelector({
  allStaff: getAllStaff,
  user: getUserData,
  areaManagers: getAreaManagers,
  clients: getFilteredSessionClients,
  clientsRaw: getClients,
  clientsRequestPending: getClientsRequestStatus,
  staffsRequestPending: getStaffRequestStatus,
  brand: getBrand,
  staff: getStaff,
  retailers: getRetailers,
  retailersRequestPending: getRetailersRequestPending,
  selectedSession: getSelectedSession,
  requestPending: getSessionRequestStatus,
  requestError: getSessionRequestError,
  inviteRequestPending: getInviteRequestStatus,
  followUpRequestPending: getFollowUpRequestStatus,
  inviteError: getInviteRequestError,
  fetchedTimezone: getFetchedTimezone,
})

const actions = {
  fetchTimezone,
  onActivateSession: activateSession,
  onFetchSession: fetchSaleSession,
  onFetchClients: fetchClients,
  onFetchStaffs: fetchStaffs,
  onFetchRetailers: fetchRetailers,
  onCreateSession: createSession,
  onDeleteSession: deleteSession,
  onUpdateSession: updateSession,
  onUnselectSession: unselectSession,
  onInviteClients: inviteClients,
  onSendFollowups: sendFollowups,
}

export const SaleSession = connect(selector, actions)(Component)

import React, { useEffect, memo } from "react"
import { Redirect } from "react-router-dom"
import { useApolloClient } from "@apollo/client"

import { logout } from "../../../helpers/auth"

type Props = {
  onRESTLogout: Function,
}
const Logout = ({ onRESTLogout }: Props) => {
  const client = useApolloClient()
  useEffect(() => {
    onRESTLogout()
    if (window.$crisp) {
      window.$crisp.push(["do", "session:reset", [false]])
    }
    logout(client)
  }, [onRESTLogout, client])
  return <Redirect to="/login" />
}

export default memo(Logout)

import { useField } from "formik"
import React, { useCallback, memo, useMemo } from "react"
import { RadioToggle } from "modaresa-commons"
import styled from "styled-components"

import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

export const Description = styled.div`
  margin-top: 8px;
`

type Props = {
  label: string,
  labelWidth: string,
  name: string,
  direction?: "row" | "column",
  onChange: Function,
  disabled: boolean,
  helpText: string,
  options: [
    {
      value: any,
      label: string,
    },
  ],
  description: string,
}

const FormikRadioToggle = ({
  onChange,
  label,
  labelWidth,
  name,
  direction,
  disabled,
  helpText,
  options,
  description,
}: Props) => {
  const [field, meta, helpers] = useField(name)
  const handleChange = useCallback(
    newValue => {
      helpers.setValue(newValue)
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange, helpers],
  )
  const htmlDescription = useMemo(() => description && { __html: description }, [description])
  return (
    <FormFieldLayout
      label={label}
      labelWidth={labelWidth}
      name={name}
      direction={direction}
      error={meta.touched && meta.error}
      helpText={helpText}
    >
      <RadioToggle id={name} onChange={handleChange} value={field.value} disabled={disabled} options={options} />
      {htmlDescription && <Description dangerouslySetInnerHTML={htmlDescription} />}
    </FormFieldLayout>
  )
}

FormikRadioToggle.defaultProps = {
  direction: "row",
  inputType: "text",
}
export default memo(FormikRadioToggle)

import { modaResaRoles } from "../config"

export const getStaffLabel = member => {
  if (member) {
    return `${member.firstName} ${member.lastName}, ${modaResaRoles[member.modaresaRole]?.name}`
  }
  return "- No Meeting Host assigned -"
}

export const getClientLabel = client => `${client.firstName} ${client.lastName}, ${client.position} - ${client.email}`

export const isStaffEditor = staff => staff.hasEditorRights || ["owner", "admin"].indexOf(staff.modaresaRole) > -1

export const isStaffAdmin = role => ["owner", "admin"].indexOf(role) > -1
export const isStaffAtLeastManager = role => ["owner", "admin", "manager"].indexOf(role) > -1

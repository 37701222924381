import React, { useMemo } from "react"
import { Route, Switch } from "react-router-dom"
import { LayoutWithSideMenu } from "modaresa-commons"

import AddClients from "./blocks/Clients"
import AddStaff from "./blocks/Staff"
import { Brand } from "./blocks/Brand"
import UsersList from "./blocks/UsersList"

type Props = {
  match: {
    path: string,
  },
  allBrands: Function,
  onAddBrand: Function,
  onUploadClients: Function,
  onRegisterUser: Function,
}

const _Admin = ({ match, onAddBrand, allBrands, onUploadClients }: Props) => {
  const menuItems = useMemo(
    () => [
      {
        key: "users",
        name: "Users",
        to: "/admin/",
        exact: true,
      },
      {
        key: "add-brand",
        name: "Add Brand",
        to: "/admin/add-brand",
        exact: true,
      },
      {
        key: "add-staff",
        name: "Add Staff",
        to: "/admin/add-staff",
        exact: true,
      },
      {
        key: "add-clients",
        name: "Add Clients",
        to: "/admin/add-clients",
        exact: true,
      },
    ],
    [],
  )
  return (
    <LayoutWithSideMenu menuItems={menuItems}>
      <Switch>
        <Route exact path={`${match.path}/add-brand`} render={props => <Brand {...props} onAddBrand={onAddBrand} />} />
        <Route
          path={`${match.path}/add-clients`}
          render={props => <AddClients {...props} allBrands={allBrands} onUploadClients={onUploadClients} />}
        />
        <Route path={`${match.path}/add-staff`} component={AddStaff} />
        <Route path={`${match.path}/`} component={UsersList} />
      </Switch>
    </LayoutWithSideMenu>
  )
}
export default _Admin

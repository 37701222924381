import React, { PureComponent } from "react"
import PropTypes from "prop-types"

import { RoomCol, RoomHeader } from "../../styles.js"
import { RoomSchedule } from "../RoomSchedule"

class RoomCalendar extends PureComponent {
  state = { mounted: false }

  tableRefs = []

  componentDidMount() {
    this.setState({ mounted: true })
  }

  render() {
    const { room, onSlotClick, meetingLength, canEditBooking } = this.props
    if (!room) return <div>RoomCalendar Error</div>

    return (
      <RoomCol flex={room.tables.length} key={room.roomName} onMouseLeave={this.props.onScheduleLeave}>
        <RoomHeader />
        <RoomSchedule
          meetingLength={meetingLength}
          mounted={this.state.mounted}
          openingHours={this.props.openingHours}
          room={room}
          roomType="normal"
          onBookingClick={this.props.onBookingClick}
          onSlotClick={onSlotClick}
          onSlotEnter={this.props.onSlotEnter}
          canEditBooking={canEditBooking}
        />
      </RoomCol>
    )
  }
}
RoomCalendar.propTypes = {
  meetingLength: PropTypes.number,
  openingHours: PropTypes.object,
  room: PropTypes.object,
  onBookingClick: PropTypes.func,
  onScheduleLeave: PropTypes.func,
  onSlotClick: PropTypes.func,
  onSlotEnter: PropTypes.func,
}

export { RoomCalendar }

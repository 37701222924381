import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import times from "lodash/times"
import { Flex } from "modaresa-commons"

import { RoomWeeklySchedule } from "../RoomWeeklySchedule"
import { CalendarContainer, DaySchedule, Hour, RoomCol, RoomHeader, Timeline } from "../../styles.js"

class WeeklyCalendar extends PureComponent {
  renderHours = ({ from, to }) => {
    let start = moment.utc(from, "X").hour()
    const duration = moment.duration(moment.utc(to, "X").diff(moment.utc(from, "X")))
    const diffHour = (duration._data.hours * 60 + duration._data.minutes) / 60
    const diff = Math.ceil(diffHour)
    const isHalfHour = !((diffHour ^ 0) === diffHour)
    start--
    return times(diff * 2, idx => {
      if (isHalfHour && idx === diff * 2 - 1) {
        return null
      }
      if ((idx + 1) % 2 !== 0) {
        start++
        return <Hour key={idx}>{`${start}:00`}</Hour>
      }
      return <Hour key={idx} />
    })
  }

  renderRooms = (day, range, dayIndex, relouDay) => {
    const { onSlotClick, selectedSession, selectedDayIndex, selectedRoom, roomType, canEditBooking } = this.props

    return day.daySchedule.map(room => {
      const meetingLength = selectedSession.rooms.find(roomStructure => roomStructure.name === room.roomName).structure[
        selectedDayIndex
      ].length

      if (selectedRoom.type === "master") {
        const roomRelou = relouDay.daySchedule.find(relouRoom => relouRoom.roomName === room.roomName)

        return (
          <RoomCol flex={room.tables.length} key={room.roomName}>
            <RoomHeader style={{ borderBottom: "2px solid black" }}>{room.roomName}</RoomHeader>
            <RoomWeeklySchedule
              dayIndex={dayIndex}
              meetingLength={meetingLength}
              range={range}
              room={room}
              roomType={roomType}
              roomRelou={roomRelou}
              sessionSchedule={this.props.selectedSession.openingHours[dayIndex]}
              onBookingClick={this.props.onBookingClick}
              onSlotClick={onSlotClick}
              canEditBooking={canEditBooking}
            />
          </RoomCol>
        )
      }
      if (selectedRoom.type === "joker") {
        return (
          <RoomCol flex={room.tables.length} key={room.roomName}>
            <RoomHeader style={{ borderBottom: "2px solid black" }}>{room.roomName}</RoomHeader>
            <RoomWeeklySchedule
              dayIndex={dayIndex}
              meetingLength={meetingLength}
              range={range}
              room={room}
              roomType="joker"
              sessionSchedule={this.props.selectedSession.openingHours[dayIndex]}
              onBookingClick={this.props.onBookingClick}
              onSlotClick={onSlotClick}
              canEditBooking={canEditBooking}
            />
          </RoomCol>
        )
      }
      if (room.roomName === selectedRoom.label) {
        return (
          <RoomCol flex={room.tables.length} key={room.roomName}>
            <RoomHeader style={{ borderBottom: "2px solid black" }}>{room.roomName}</RoomHeader>
            <RoomWeeklySchedule
              dayIndex={dayIndex}
              meetingLength={meetingLength}
              range={range}
              room={room}
              roomType="normal"
              sessionSchedule={this.props.selectedSession.openingHours[dayIndex]}
              onBookingClick={this.props.onBookingClick}
              onSlotClick={onSlotClick}
              canEditBooking={canEditBooking}
            />
          </RoomCol>
        )
      }
      return null
    })
  }

  renderDays = range => {
    const { schedule, firstDayofRandIndex, selectedSchedule, selectedRoom } = this.props
    let relouDay = null
    const firstDayRange = firstDayofRandIndex
    const lastDayofRandIndex = firstDayRange + 6

    return schedule.map((day, dayIndex) => {
      if (dayIndex >= firstDayRange && dayIndex <= lastDayofRandIndex) {
        if (selectedRoom.type === "master") {
          relouDay = selectedSchedule.jokerSchedule.find(rDay => rDay.date === day.date)
        }
        return (
          <DaySchedule key={day.date}>
            <RoomHeader style={{ width: "100%", borderBottom: "2px solid black" }}>
              {moment.utc(day.date, "X").format("dddd Do, MMMM")}
            </RoomHeader>
            <Flex>{this.renderRooms(day, range, dayIndex, relouDay)}</Flex>
          </DaySchedule>
        )
      }
      return null
    })
  }

  render() {
    const { selectedSession } = this.props
    const range = {}
    selectedSession.openingHours.forEach(item => {
      if (!range.from) {
        range.from = item.from
      } else {
        const nextFrom = moment.utc(item.from, "X")
        const prevFrom = moment.utc(range.from, "X")
        const diffHours = prevFrom.hours() - nextFrom.hours()
        const diffMinutes = prevFrom.minutes() - nextFrom.minutes()
        if (diffHours > 0 || (diffHours >= 0 && diffMinutes > 0)) {
          range.from = item.from
        }
      }
      if (!range.to) {
        range.to = item.to
      } else {
        const nextTo = moment.utc(item.to, "X")
        const prevTo = moment.utc(range.to, "X")
        const diffHours = nextTo.hours() - prevTo.hours()
        const diffMinutes = nextTo.minutes() - prevTo.minutes()
        if (diffHours > 0 || (diffHours >= 0 && diffMinutes > 0)) {
          range.to = item.to
        }
      }
    })

    const now = moment.utc()
    const fromHours = moment.utc(range.from, "X").hours()
    const fromMinutes = moment.utc(range.from, "X").minutes()
    const toHours = moment.utc(range.to, "X").hours()
    const toMinutes = moment.utc(range.to, "X").minutes()
    const from = now.clone().set({ hours: fromHours, minutes: fromMinutes })
    const to = now.clone().set({ hours: toHours, minutes: toMinutes })
    const timeRange = {
      to,
      from,
    }

    return (
      <Flex
        align="stretch"
        justify="flex-start"
        style={{
          flex: 1,
          maxWidth: "100%",
          overflowX: "auto",
        }}
      >
        <Timeline style={{ paddingTop: 78 }}>{this.renderHours(timeRange)}</Timeline>
        <CalendarContainer style={{ overflow: "initial" }}>{this.renderDays(range)}</CalendarContainer>
      </Flex>
    )
  }
}

WeeklyCalendar.propTypes = {
  firstDayofRandIndex: PropTypes.number,
  openingHours: PropTypes.object,
  schedule: PropTypes.array,
  selectedDayIndex: PropTypes.number,
  selectedRoom: PropTypes.object,
  selectedSchedule: PropTypes.object,
  selectedSession: PropTypes.object,
  onBookingClick: PropTypes.func,
  onSlotClick: PropTypes.func,
}

export { WeeklyCalendar }

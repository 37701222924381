import { useMutation } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import { Button } from "modaresa-commons"

import { SEND_SALES_SESSION_BOOKING_FOLLOWUPS } from "../../../../../graphql/mutations/salesSession"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"

import ModalConfirmSendingInvitations from "./ModalConfirmSendingInvitations"

type Props = {
  salesSessionId: string,
  disabled: boolean,
  color: string,
  selectedBuyersToFollowUp: {},
  onSend: Function,
  buyers: [],
}

const ButtonSendFollowUps = ({ salesSessionId, selectedBuyersToFollowUp, color, disabled, onSend, buyers }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sendSalesSessionBookingFollowUps] = useMutation(SEND_SALES_SESSION_BOOKING_FOLLOWUPS)
  const handleToggleModal = useCallback(state => () => setShowModal(state), [])
  const handleClickSendFollowUps = useCallback(async () => {
    setShowModal(false)
    setIsLoading(true)

    const invitedBuyersIds = buyers.reduce((invitedBuyersIdsList, buyer) => {
      const foundBuyer = selectedBuyersToFollowUp[buyer.id]
      if (foundBuyer && buyer.invitation) {
        invitedBuyersIdsList.push(buyer.invitation.id)
      }
      return invitedBuyersIdsList
    }, [])
    try {
      await sendSalesSessionBookingFollowUps({
        variables: {
          salesSessionId,
          invitedBuyersIds,
        },
      })
      onSend()
      onToastSuccess("Invitations sent!")
    } catch (e) {
      console.error(e)
      onToastError("An error occured while sending emails")
    }
    setIsLoading(false)
  }, [buyers, selectedBuyersToFollowUp, sendSalesSessionBookingFollowUps, salesSessionId, onSend])
  const nbFollowUps = Object.keys(selectedBuyersToFollowUp).length
  const isDisabled = disabled || nbFollowUps === 0
  return (
    <>
      <Button
        margin="0 10px 0 0"
        bgcolor={color}
        borderColor={color}
        disabled={isDisabled}
        loading={isLoading}
        onClick={handleToggleModal(true)}
      >
        Send Follow-Ups ({nbFollowUps})
      </Button>
      {showModal && (
        <ModalConfirmSendingInvitations
          title="Confirm Sending Follow-Ups"
          buttonLabelOk="Send Follow-Ups"
          onClickOk={handleClickSendFollowUps}
          onClose={handleToggleModal(false)}
          buyers={buyers}
          selectedBuyers={selectedBuyersToFollowUp}
        />
      )}
    </>
  )
}

export default memo(ButtonSendFollowUps)

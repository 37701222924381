import React, { memo } from "react"
import styled from "styled-components"

export const Host = styled.div`
  color: ${p => p.theme.main};
`

type Props = {
  booking: {
    retailer: {
      name: string,
    },
    host: {
      fullName: string,
    },
    room: string,
  },
  className: string,
}

const Booking = ({ booking: { retailer, host, room }, className }: Props) => (
  <div className={className}>
    <div className="ellipsis">
      {retailer.name} ({retailer.storeAddress.city}, {retailer.storeAddress.country})
    </div>
    <div>{room}</div>
    <Host>{host ? host.fullName : "- No host -"}</Host>
  </div>
)

export default memo(Booking)

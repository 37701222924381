import React, { memo } from "react"
import { Button } from "modaresa-commons"

import Areas from "../../../../elements/Areas"
import AreasManager from "../../../../elements/AreasManager/AreasManager"

import { ContainerButtons } from "./SalesSessionAreasDefaultView.styles"

type Props = {
  brandId: boolean,
  isLoadingData: boolean,
  disableSaveChanges: boolean,
  labelSaveChanges: string,
  canAreaStaffsBeModified: boolean,
  areas: [],
  remainingArea: {},
  onChangeAreas: Function,
  onChangeRemainingArea: Function,
  onSaveChanges: Function,
}

const SalesSessionAreasDefaultView = ({
  brandId,
  areas,
  remainingArea,
  canAreaStaffsBeModified,
  labelSaveChanges,
  disableSaveChanges,
  isLoadingData,
  onChangeAreas,
  onChangeRemainingArea,
  onSaveChanges,
}: Props) => (
  <div>
    {isLoadingData ? (
      <Areas loading />
    ) : (
      <AreasManager
        brandId={brandId}
        defaultAreas={areas}
        defaultRemainingArea={remainingArea}
        onChangeAreas={onChangeAreas}
        onChangeRemainingArea={onChangeRemainingArea}
        canStaffsBeModified={canAreaStaffsBeModified}
      />
    )}
    <ContainerButtons>
      <Button disabled={disableSaveChanges} onClick={onSaveChanges}>
        {labelSaveChanges}
      </Button>
    </ContainerButtons>
  </div>
)

export default memo(SalesSessionAreasDefaultView)

import { useMutation, gql } from "@apollo/client"
import React, { memo, useCallback, useMemo, useState } from "react"
import { Input, Modal } from "modaresa-commons"

import { ADD_BRAND_ROOM } from "../../../graphql/mutations/room"
import { getGraphQLErrorAsString } from "../../../helpers/error"
import { onToastError } from "../../../helpers/toast"
import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

type Props = {
  brandId: string,
  onClose: Function,
}

const ModalAddBrandRoom = ({ brandId, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState("")
  const [addBrandRoom] = useMutation(ADD_BRAND_ROOM, {
    update: (cache, { data: { addBrandRoom: newRoom } }) => {
      cache.modify({
        id: cache.identify({ id: brandId }),
        fields: {
          rooms: (roomsRefs, { readField }) => {
            const newRoomRef = cache.writeFragment({
              data: newRoom,
              fragment: gql`
                fragment NewRoom on Room {
                  id
                  name
                  hasBlockingList
                  typeBlockingList
                  retailers {
                    id
                    name
                  }
                }
              `,
            })
            if (roomsRefs.some(ref => readField("id", ref) === newRoom.id)) {
              return roomsRefs
            }

            return [...roomsRefs, newRoomRef]
          },
        },
      })
    },
  })
  const handleChangeName = useCallback(e => setName(e.target.value), [])
  const handleAddRoom = useCallback(async () => {
    setIsLoading(true)
    try {
      await addBrandRoom({
        variables: {
          brandId,
          name,
        },
      })
      onClose()
    } catch (err) {
      console.error(err)
      onToastError("An error occured while adding a new room." + getGraphQLErrorAsString(err))
      setIsLoading(false)
    }
  }, [brandId, name, addBrandRoom, onClose])
  const actions = useMemo(
    () => [
      {
        onClick: handleAddRoom,
        label: "Add",
        primary: true,
        disabled: name === "",
        loading: isLoading,
      },
      {
        onClick: onClose,
        label: "Cancel",
      },
    ],
    [handleAddRoom, name, onClose, isLoading],
  )
  return (
    <Modal isOpen title="Add new brand room" onClose={onClose} actions={actions}>
      <FormFieldLayout name="name" label="Name*" labelWidth="100px">
        <Input id="name" placeholder="Room name" onChange={handleChangeName} />
      </FormFieldLayout>
    </Modal>
  )
}

export default memo(ModalAddBrandRoom)

import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 128px;
`
export const Tbody = styled.tbody`
  overflow: visible;
`

export const TRow = styled.tr`
  display: flex;
  flex-direction: row;
`
export const DropdownContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`
export const DropdownMenuItem = styled.div`
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.gray75};
    text-decoration: none;
  }
`
export const DropdownMenu = styled.div`
  background-color: #fff;
  min-width: 200px;
  text-decoration: none;
  ${({ border, theme }) =>
    border &&
    `border: 2px solid ${theme.veryLightGray};
  border-radius: 3px;

  & ${DropdownMenuItem}:not(:last-child) {
    border-bottom: 1px solid ${theme.veryLightGray}
  }`};
`
export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none;
      opacity: 0.4;
  `}
`
export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin: 10px;
`
export const Table = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const LoaderContainer = styled.div`
  height: 100%;
  position: relative;
`

export const TableBody = styled.div`
  height: 100%;
`

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  background-color: #f7f7f7;
  padding-left: 30px;
  padding-right: 30px;
  position: sticky;
  top: 0;
  z-index: 1;

  & > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  border-bottom: 1px solid #efebeb;
  padding-left: 30px;
  padding-right: 30px;

  & > div:not(:last-child) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }
`
export const UploadFile = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UploadFileInput = styled.input`
  display: none !important;
`
export const ModalBody = styled.div`
  & .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255);
  }
`
export const Bold = styled.span`
  font-weight: bold;
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`

export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`

export const CloseModal = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 17px;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.strawberry};
`

export const Text = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`
export const ChooseFiles = styled.span`
  min-width: 200px;
  height: 40px;
  border-radius: 3px;
  cursor: pointer;
  background-color: ${({ bgcolor }) => bgcolor};
  color: white;
  padding: 5px 10px 5px 10px;
  font-weight: normal;
  line-height: 30px;
  margin-right: 20px;
  text-align: center;
`

import { useQuery } from "@apollo/client"
import React, { memo, useCallback, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { Loading } from "modaresa-commons"

import { BRAND_RETAILERS_INFOS } from "../../../../../graphql/queries/brand"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"
import ButtonDeleteRetailer from "../ButtonDeleteRetailer"

import AllRetailersView from "./AllRetailersView"
import retailerSearch from "./retailer.search"

type Props = {
  brandId: string,
  restrictToStaffId: string,
}

const AllRetailers = ({ brandId, restrictToStaffId }: Props) => {
  const [filteringOptions, setFilteringOptions] = useState([])
  const history = useHistory()
  const handleChangeFilter = useCallback(options => setFilteringOptions(options || []), [])
  const handleRowRetailerClick = useCallback(
    ({ rowData: retailer }) => {
      history.push(`/clients/retailers/${retailer.id}`)
    },
    [history],
  )
  const handleRenderActions = useCallback(retailer => <ButtonDeleteRetailer brandId={brandId} retailer={retailer} />, [
    brandId,
  ])
  const { loading, data, error } = useQuery(BRAND_RETAILERS_INFOS, {
    variables: { id: brandId, restrictToStaffId },
  })
  const filteredRetailers = useMemo(() => {
    if (!data?.Brand?.retailers) return []
    const { retailers } = data.Brand
    let newFilteredRetailers = [...retailers]
    filteringOptions.forEach(opt => {
      newFilteredRetailers = retailerSearch[opt.key].filter(newFilteredRetailers, opt.value)
    })
    return newFilteredRetailers
  }, [data, filteringOptions])
  if (loading && !data) return <Loading />
  if (error)
    return <ErrorLiveChatHelp technicalError={error}>An error occured when fetching retailers</ErrorLiveChatHelp>
  return (
    <AllRetailersView
      retailers={filteredRetailers}
      onRowRetailerClick={handleRowRetailerClick}
      onChangeFilter={handleChangeFilter}
      onRenderActions={handleRenderActions}
    />
  )
}

export default memo(AllRetailers)

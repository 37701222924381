import { getDetails } from "use-places-autocomplete"

const formatPlaceFromGooglePlaceId = async placeId => {
  const place = await getDetails({ placeId })
  if (!place) {
    throw Error(`place doesnt exists for place_id: '${placeId}'`)
  }
  const country = place.address_components[place.address_components.length - 1]
  return {
    name: place.name,
    country: country.short_name.toLowerCase(),
    formattedAddress: place.formatted_address,
    googlePlaceId: placeId,
  }
}

const mappedOptionType = {
  continent: {
    territoryField: "continents",
    getValue: opt => opt.value,
  },
  country: {
    territoryField: "countries",
    getValue: opt => opt.value,
  },
  region: {
    territoryField: "regions",
    getValue: opt => formatPlaceFromGooglePlaceId(opt.value),
  },
  county: {
    territoryField: "counties",
    getValue: opt => formatPlaceFromGooglePlaceId(opt.value),
  },
  city: {
    territoryField: "cities",
    getValue: opt => formatPlaceFromGooglePlaceId(opt.value),
  },
  retailer: {
    territoryField: "retailers",
    getValue: opt => ({ id: opt.value, name: opt.label }),
  },
}

type Props = {
  options: [
    {
      label: string,
      value: string,
      type: string,
    },
  ],
}
const generateTerritoriesValuesFromOptions = async (options: Props = []) => {
  const territories = {
    continents: [],
    countries: [],
    regions: [],
    counties: [],
    cities: [],
    retailers: [],
  }

  for (const option of options) {
    const { type, value, label } = option
    const mappedOption = mappedOptionType[type]
    if (!mappedOption) {
      throw new Error(`unknown type '${type}' for value '${value} (${label})'`)
    }
    const { territoryField, getValue } = mappedOption
    territories[territoryField].push(await getValue(option))
  }
  return territories
}

export default generateTerritoriesValuesFromOptions

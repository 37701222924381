import { useQuery } from "@apollo/client"
import moment from "moment"
import React, { memo, useCallback, useMemo, useState } from "react"
import { B, ModalConfirmation } from "modaresa-commons"

import { GET_BUYER_BOOKING_EVENTS } from "../../../graphql/queries/buyer"
import { Textarea } from "../../ui/Textarea"

import { EventList } from "./ModalConfirmDelete.styles"

type Props = {
  onClickConfirm: Function,
  onCloseModal: Function,
  loading: boolean,
  buyerId: string,
}
const ModalConfirmDelete = ({ buyerId, onClickConfirm, onCloseModal, loading }: Props) => {
  const [meetingCancellationReason, setMeetingCancellationReason] = useState("")
  const handleChangeCancellationReason = useCallback(e => setMeetingCancellationReason(e.target.value), [])
  const handleClickOk = useCallback(() => onClickConfirm(meetingCancellationReason), [
    onClickConfirm,
    meetingCancellationReason,
  ])
  const { loading: loadingBuyerBookings, error, data } = useQuery(GET_BUYER_BOOKING_EVENTS, {
    variables: {
      id: buyerId,
      timePeriod: "future",
    },
  })
  const futureBookingEvents = useMemo(() => data?.Buyer.bookingEvents, [data])
  return (
    <ModalConfirmation
      error={error}
      isOpen
      title={`Archive Buyer`}
      onClickOk={handleClickOk}
      onClose={onCloseModal}
      loading={loadingBuyerBookings || loading}
    >
      <div>
        <div>Are you sure you want to archive this buyer? </div>
        {futureBookingEvents?.length > 0 && (
          <div>
            <div>The client has an upcoming appointment for :</div>
            <EventList>
              {futureBookingEvents.map(event => {
                const day = moment.utc(event.dayDate, "X").format("DD MMM")
                const start = moment.utc(event.startTime, "X").format("HH:mm")
                const end = moment.utc(event.endTime, "X").format("HH:mm")
                return (
                  <li>
                    {event.session?.name} (<B>{event.room}</B>) -{" "}
                    <span>
                      {day}, {start} - {end}
                    </span>
                  </li>
                )
              })}
            </EventList>
            <div>
              Archiving the client will automatically cancel the meeting in the calendar and notify the client of such.
            </div>
            <div>
              <p>Add a reason for cancellation (optional)</p>
              <Textarea width="100%" rows={4} onChange={handleChangeCancellationReason} />
            </div>
          </div>
        )}
      </div>
    </ModalConfirmation>
  )
}

export default memo(ModalConfirmDelete)

import { gql } from "@apollo/client"

export const UPDATE_SALES_SESSION_STAFF_AVAILABILITIES = gql`
  mutation UpdateSalesSessionStaffAvailabilities(
    $salesSessionId: ID!
    $staffId: ID!
    $availabilities: [StaffAvailabilityInput!]!
  ) {
    updateSalesSessionStaffAvailabilities(
      salesSessionId: $salesSessionId
      staffId: $staffId
      availabilities: $availabilities
    ) {
      id
    }
  }
`

import styled from "styled-components"
import { Button } from "modaresa-commons"

export const ContainerAddClients = styled.div`
  padding: 30px;
`
export const ContainerButtons = styled.div`
  margin: 24px 0;
  & ${Button} {
    margin: 0 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`
export const ContainerBrandPicker = styled.div`
  margin-bottom: 24px;
  width: 800px;
  & h2 {
    margin-top: 0;
  }
`

export const FileUploading = styled.div`
  margin: 8px 0;
  font-style: italic;
`

import React, { memo, useCallback } from "react"
import Select from "react-select"
import { Error } from "modaresa-commons"

import { hoursFormat24Options } from "../../../../../helpers/date"
import { CustomSwitch } from "../../../../ui/Switch"

import { ContainerRange, ContainerRow, Date, ContainerDateSelect, To } from "./RowOpeningDay.styles"

type Props = {
  day: {
    available: boolean,
    date: any,
  },
  index: number,
  isSessionActive: boolean,
  disabled: boolean,
  error: string,
  minHour: any,
  maxHour: any,
  arrayHelpers: {
    replace: Function,
  },
}

const RowOpeningDay = ({ day, disabled, error, minHour, maxHour, arrayHelpers, index }: Props) => {
  const handleToggleDayAvaibility = useCallback(
    toggleValue => {
      arrayHelpers.replace(index, {
        ...day,
        available: toggleValue,
      })
    },
    [arrayHelpers, day, index],
  )
  const handleChangeStart = useCallback(
    option => {
      arrayHelpers.replace(index, { ...day, from: option })
    },
    [arrayHelpers, day, index],
  )
  const handleChangeEnd = useCallback(
    option => {
      arrayHelpers.replace(index, { ...day, to: option })
    },
    [arrayHelpers, day, index],
  )
  const isStartOptionDisabled = useCallback(
    option => option.value.numerical < minHour || option.value.numerical > maxHour,
    [minHour, maxHour],
  )
  const isEndOptionDisabled = useCallback(
    option => option.value.numerical < day.from.value?.numerical || option.value.numerical > maxHour,
    [day, maxHour],
  )
  return (
    <div>
      <ContainerRow>
        <Date>{day.date.format("ddd, MMM Do")}</Date>
        <CustomSwitch cheked={day.available} disabled={disabled} onswitch={handleToggleDayAvaibility} />
        <ContainerRange>
          <ContainerDateSelect>
            <Select
              isDisabled={disabled || !day.available}
              isOptionDisabled={isStartOptionDisabled}
              options={hoursFormat24Options}
              placeholder="Opening Hour"
              value={day.from}
              onChange={handleChangeStart}
            />
          </ContainerDateSelect>
          <To>to</To>
          <ContainerDateSelect>
            <Select
              isDisabled={disabled || !day.available}
              isOptionDisabled={isEndOptionDisabled}
              options={hoursFormat24Options}
              placeholder="Closing Hour"
              value={day.to}
              onChange={handleChangeEnd}
            />
          </ContainerDateSelect>
        </ContainerRange>
      </ContainerRow>
      {error && <Error>{error}</Error>}
    </div>
  )
}

export default memo(RowOpeningDay)

import { useQuery } from "@apollo/client"
import Select from "react-select"
import React, { memo, useCallback, useMemo } from "react"
import { AlertDanger } from "modaresa-commons"

import { BRAND_STAFFS } from "../../../graphql/queries/brand"
import { capitalizeFirstLetter } from "../../../helpers/string"

import { WarningEmail } from "./styles"

type Props = {
  brandId: String,
  roles: [],
  multiple: boolean,
  placeholder?: boolean,
  disabled: boolean,
  defaultValue: any,
  value: any,
  displayIsEmailVerified: boolean,
}

const StaffPicker = ({
  brandId,
  roles,
  multiple,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
  displayIsEmailVerified,
}: Props) => {
  const handleGetOptionValue = useCallback(option => option.id, [])
  const handleGetOptionLabel = useCallback(
    option => {
      const fullName = `${option.firstName} ${option.lastName?.[0]}.`
      return displayIsEmailVerified && !option.emailVerified ? (
        <span>
          {fullName} <WarningEmail>(email non-verified)</WarningEmail>
        </span>
      ) : (
        fullName
      )
    },
    [displayIsEmailVerified],
  )
  const { error, data } = useQuery(BRAND_STAFFS, {
    variables: { id: brandId },
  })
  const options = useMemo(() => {
    if (!data?.Brand) return []
    const { staffs } = data.Brand
    if (roles) {
      const optionsSeparator = roles.reduce((acc, role) => {
        acc[role] = {
          label: capitalizeFirstLetter(role),
          options: [],
        }
        return acc
      }, {})
      const optionsByRoles = staffs.reduce((acc, staff) => {
        if (acc[staff.modaresaRole]) {
          acc[staff.modaresaRole].options.push(staff)
        }
        return acc
      }, optionsSeparator)
      return Object.values(optionsByRoles)
    }
    return staffs
  }, [data, roles])
  if (error) return <AlertDanger>Error</AlertDanger>

  return (
    <Select
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      options={options}
      isMulti={multiple}
      placeholder={placeholder}
      onChange={onChange}
      getOptionValue={handleGetOptionValue}
      getOptionLabel={handleGetOptionLabel}
    />
  )
}

StaffPicker.defaultProps = {
  placeholder: "Assign staff",
}
export default memo(StaffPicker)

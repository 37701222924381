import { Formik, Form } from "formik"
import React, { memo, useState } from "react"
import * as yup from "yup"
import { Button } from "modaresa-commons"

import { optionsRoles } from "../../../../../config/settings"
import { yupSelectOption } from "../../../../../helpers/yup"
import FormFieldLayout from "../../../../elements/FormFieldLayout/FormFieldLayout"
import FormikInput from "../../../../elements/FormikFields/FormikInput"
import FormikPhoneNumber from "../../../../elements/FormikFields/FormikPhoneNumber"
import FormikSelect from "../../../../elements/FormikFields/FormikSelect"

import FieldBrand from "./FieldBrand"
import FieldOffices from "./FieldOffices"

type Props = {
  onSubmit: Function,
  labelSubmit: string,
  loadingSubmit: boolean,
}

const staffGeneralInfosSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required("Required"),
  lastName: yup
    .string()
    .trim()
    .required("Required"),
  phoneNumber: yup.string(),
  email: yup
    .string()
    .email()
    .when("modaresaRole", {
      is: modaResaRole => !(modaResaRole?.value === "freelancer"),
      then: yup.string().required("Required"),
    }),
  companyPosition: yup
    .string()
    .trim()
    .required("Required"),
  modaresaRole: yupSelectOption.required("Required"),
  password: yup.string().required("Required"),
  office: yupSelectOption.required("Required"),
  brand: yup
    .object()
    .nullable()
    .required("Required"),
})

const defaultStaffGeneralInfos = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  companyPosition: "",
  modaresaRole: null,
  password: "",
  office: null,
  brand: null,
}
const labelWidth = "192px"

const FormStaff = ({ onSubmit, labelSubmit, loadingSubmit }: Props) => {
  const [initialValues] = useState(defaultStaffGeneralInfos)
  return (
    <Formik initialValues={initialValues} validateOnBlur validationSchema={staffGeneralInfosSchema} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <FieldBrand labelWidth={labelWidth} selectWidth="100%" />
          {values.brand && <FieldOffices brandId={values.brand.id} labelWidth={labelWidth} selectWidth="100%" />}
          <FormikInput direction="row" labelWidth={labelWidth} label="First Name*" name="firstName" />
          <FormikInput direction="row" labelWidth={labelWidth} label="Last Name*" name="lastName" />
          <FormikPhoneNumber direction="row" labelWidth={labelWidth} label="Phone Number" name="phoneNumber" />
          <FormikInput
            direction="row"
            labelWidth={labelWidth}
            label="Email*"
            name="email"
            inputAutocomplete="username"
          />
          <FormikInput direction="row" labelWidth={labelWidth} label="Company Position*" name="companyPosition" />
          <FormikSelect
            label="Modaresa Role*"
            selectWidth="100%"
            labelWidth={labelWidth}
            name="modaresaRole"
            options={optionsRoles}
            placeholder="Select role"
          />
          <FormikInput
            direction="row"
            labelWidth={labelWidth}
            label="Password*"
            name="password"
            inputType="password"
            inputAutocomplete="new-password"
          />
          <FormFieldLayout label=" " labelWidth={labelWidth}>
            <Button minWidth="100%" type="submit" disabled={loadingSubmit}>
              {labelSubmit}
            </Button>
          </FormFieldLayout>
        </Form>
      )}
    </Formik>
  )
}

FormStaff.defaultProps = {
  labelSubmit: "Add Staff",
}
export default memo(FormStaff)

import { Tabs } from "modaresa-commons"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"

import PageTitle from "../../../../elements/PageTitle/PageTitle"

import { BuyerIsArchived, Container, FullHeightTabs, NotFound } from "./styles"
import BuyerInformation from "./Tabs/BuyerInformation"
import { HistoryOfMeetings } from "./Tabs/History"
import { MeetingReport } from "./Tabs/MeetingReport"

class Buyer extends Component {
  constructor(props) {
    super(props)
    const selectedBuyer = this.props.selectedBuyer

    this.state = {
      activeSectionIndex: 0,
      selectedBuyer,
      selectedReport: null,
    }
  }

  componentDidMount() {
    const {
      match,
      onFetchRetailers,
      retailers,
      retailersRequestPending,
      user,
      clients,
      onFetchClients,
      clientsRequestPending,
    } = this.props

    if (match.params && match.params.id) {
      if (!clientsRequestPending && !clients?.allIds) {
        onFetchClients(user.brandId)
      } else {
        this.props.selectBuyerByID(match.params.id)
      }
    }

    if (!retailersRequestPending && !retailers?.data) {
      onFetchRetailers(user.brandId)
    }
  }

  componentWillUnmount = () => {
    this.props.unselectBuyer()
  }

  componentDidUpdate = prevProps => {
    const { match, clients, selectedBuyer } = this.props
    if (clients?.allIds && !selectedBuyer) {
      this.props.selectBuyerByID(match.params.id)
    }
  }

  onTabClick = tabKey => {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props
    if (id) {
      history.push(`/clients/${id}/${tabKey}`)
    }
  }

  onExportReports = () => {
    const { onExportReports, selectedBuyer } = this.props
    onExportReports(selectedBuyer._id)
  }

  render() {
    const {
      selectedBuyer,
      retailers,
      retailersRequestPending,
      clientsRequestPending,
      addClient,
      match,
      match: { params },
      requestPending,
      requestError,
      history,
      reports: reprts,
      onFetchReports,
      onFetchReport,
      seletedReport,
      onFetchClientBookings,
      onLeaveSocketRoom,
      clientBookings: clintBookngs,
      reportRequestPending,
      bookingsRequestPending,
      onUpdateReport,
      unselectReport,
      addReport,
      sharedRetailers,
      sharedRetailerRequestPending,
      onCheckIn,
      onCheckOut,
      location,
      user,
      clients,
      staff,
      onUpdateClient,
      availableRetailers,
      onDelete,
      onCreateReport,
      onCreateRequest,
      requestUpdateEmailPending,
      requestUpdateEmailError,
    } = this.props

    if (clientsRequestPending || retailersRequestPending) {
      return (
        <Container>
          <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
        </Container>
      )
    }

    const isNewBuyer = !params.id
    const isLoggedUserFreelancer = user.modaresaRole === "freelancer"
    if (params.id && !selectedBuyer) {
      return (
        <Container>
          <NotFound>
            <span>CLIENT NOT FOUND</span>
          </NotFound>
        </Container>
      )
    }
    let reports
    let clientBookings
    if (isLoggedUserFreelancer) {
      reports = reprts.filter(report => report.host?._id === user._id)
      clientBookings = clintBookngs.filter(report => report.host?._id === user._id)
    } else {
      reports = reprts
      clientBookings = clintBookngs
    }

    return (
      <PageTitle title={selectedBuyer ? `${selectedBuyer.firstName} ${selectedBuyer.lastName}` : "Buyer"}>
        {selectedBuyer?.dbStatus === "archived" && <BuyerIsArchived>This buyer is currently archived</BuyerIsArchived>}
        <Container>
          <FullHeightTabs activeKey={params.section || "info"} onChange={this.onTabClick}>
            {!isLoggedUserFreelancer && (
              <Tabs.TabPane tabKey="info" tab="Buyer Information">
                <BuyerInformation
                  addClient={addClient}
                  addBuyerWithNewDefaultRetailer={this.props.addBuyerWithNewDefaultRetailer}
                  availableRetailers={availableRetailers}
                  brand={this.props.brand}
                  fetchSharedRetailers={this.props.fetchSharedRetailers}
                  findBuyers={this.props.findBuyers}
                  findSharedBuyers={this.props.findSharedBuyers}
                  history={history}
                  isNew={isNewBuyer}
                  user={user}
                  requestError={requestError}
                  requestPending={requestPending}
                  requestUpdateEmailError={requestUpdateEmailError}
                  requestUpdateEmailPending={requestUpdateEmailPending}
                  retailers={retailers}
                  selectRetailer={this.props.selectRetailerByID}
                  selectedBuyer={selectedBuyer}
                  sharedRetailers={sharedRetailers}
                  sharedRetailerRequestPending={sharedRetailerRequestPending}
                  onCreateRequest={onCreateRequest}
                  onDelete={onDelete}
                  onUpdateClient={onUpdateClient}
                />
              </Tabs.TabPane>
            )}
            {!isNewBuyer && (
              <>
                <Tabs.TabPane tabKey="meetings-history" tab="History of Meetings">
                  <HistoryOfMeetings
                    history={history}
                    match={match}
                    reports={reports}
                    requestError={requestError}
                    requestPending={requestPending}
                    onExportReports={this.onExportReports}
                    onFetchReports={onFetchReports}
                    onSelectReport={this.onSelectReport}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tabKey="meetings-reports" tab="Meeting Reports">
                  <MeetingReport
                    addReport={addReport}
                    bookingsRequestPending={bookingsRequestPending}
                    clientBookings={clientBookings}
                    clients={clients}
                    history={history}
                    match={match}
                    report={seletedReport}
                    reportRequestPending={reportRequestPending}
                    requestError={requestError}
                    requestPending={requestPending}
                    retailer={retailers.byId[selectedBuyer.retailerId]}
                    search={location.search}
                    staff={staff}
                    unselectReport={unselectReport}
                    onCheckIn={onCheckIn}
                    onCheckOut={onCheckOut}
                    onCreateReport={onCreateReport}
                    onFetchClientBookings={onFetchClientBookings}
                    onFetchReport={onFetchReport}
                    onLeaveSocketRoom={onLeaveSocketRoom}
                    onUpdateReport={onUpdateReport}
                  />
                </Tabs.TabPane>
              </>
            )}
          </FullHeightTabs>
        </Container>
      </PageTitle>
    )
  }
}

Buyer.propTypes = {
  addClient: PropTypes.func,
  addBuyerWithNewDefaultRetailer: PropTypes.func,
  addReport: PropTypes.func,
  availableRetailers: PropTypes.array,
  bookingsRequestPending: PropTypes.bool,
  brand: PropTypes.object,
  clientBookings: PropTypes.array,
  clients: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  reportRequestPending: PropTypes.bool,
  reports: PropTypes.array,
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  requestUpdateEmailError: PropTypes.any,
  requestUpdateEmailPending: PropTypes.bool,
  retailers: PropTypes.object,
  retailersRequestPending: PropTypes.bool,
  fetchSharedRetailers: PropTypes.func,
  findBuyers: PropTypes.func,
  findSharedBuyers: PropTypes.func,
  sharedRetailers: PropTypes.array,
  sharedRetailerRequestPending: PropTypes.bool,
  selectBuyerByID: PropTypes.func,
  selectRetailerByID: PropTypes.func,
  selectedBuyer: PropTypes.object,
  seletedReport: PropTypes.any,
  staff: PropTypes.object,
  unselectBuyer: PropTypes.func,
  unselectReport: PropTypes.func,
  user: PropTypes.object,
  onCheckIn: PropTypes.func,
  onCheckOut: PropTypes.func,
  onCreateReport: PropTypes.func,
  onCreateRequest: PropTypes.func,
  onDelete: PropTypes.func,
  onExportReports: PropTypes.func,
  onFetchClientBookings: PropTypes.func,
  onFetchRetailers: PropTypes.func,
  clientsRequestPending: PropTypes.bool,
  onFetchClients: PropTypes.func,
  onFetchReport: PropTypes.func,
  onFetchReports: PropTypes.func,
  onLeaveSocketRoom: PropTypes.func,
  onUpdateClient: PropTypes.func,
  onUpdateReport: PropTypes.func,
}

export default Buyer

import { useQuery } from "@apollo/client"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { Loading } from "modaresa-commons"

import { ME_LOGGED_STAFF_AVAILABLE_SESSIONS } from "../../../../graphql/queries/user"
import { getBrand, getUserData } from "../../../../store/selectors"
import PageTitle from "../../../elements/PageTitle/PageTitle"
import ErrorLiveChatHelp from "../../../ui/ErrorLiveChatHelp"
import { deleteSession } from "../../../../store/actions/salesSessions"

import { SessionList } from "./SessionList"

const DraftSessionsComponent = ({ match, onDeleteSession, brand, user }) => {
  const { data, error, loading } = useQuery(ME_LOGGED_STAFF_AVAILABLE_SESSIONS, {
    variables: {
      sessionStatus: "draft",
    },
  })
  const sessions = useMemo(() => data?.me.staff.availableSessions || [], [data])
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading active sessions</ErrorLiveChatHelp>
  if (loading && !data) return <Loading />
  const userIsAgent = user.modaresaRole === "agent"
  return (
    <PageTitle title="Draft sessions">
      <SessionList
        match={match}
        offices={brand.offices}
        sessions={sessions}
        type="draft"
        userIsAgent={userIsAgent}
        onDeleteSession={onDeleteSession}
      />
    </PageTitle>
  )
}

DraftSessionsComponent.propTypes = {
  brand: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  onDeleteSession: PropTypes.func,
}

const selector = createStructuredSelector({
  user: getUserData,
  brand: getBrand,
})

const actions = {
  // TODO: See comment on deleteSession action to see why this is bad but unavoidable if we don't refactor
  onDeleteSession: id => deleteSession(id, true),
}

export { DraftSessionsComponent }
export default connect(selector, actions)(DraftSessionsComponent)

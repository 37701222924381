import { Formik } from "formik"
import React, { memo, useState } from "react"
import * as yup from "yup"
import { Section } from "modaresa-commons"

import { ContainerCompetitorGroups } from "./FormCompetitorGroups.styles"
import FormCompetitorGroupsFields from "./FormCompetitorGroupsFields"

type Props = {
  competitorGroups: {},
  brandId: string,
  onSubmit: Function,
  readOnly: boolean,
  loadingSubmit: boolean,
  restrictToStaffIdIfAgent: string,
}

const competitorGroupsSchema = yup.object().shape({
  competitorGroups: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .required("Name is required!"),
      retailers: yup.array().of(
        yup.object().shape({
          id: yup.string().required(),
          name: yup.string().required(),
        }),
      ),
    }),
  ),
})

const FormCompetitorGroups = ({
  brandId,
  competitorGroups,
  onSubmit,
  readOnly,
  loadingSubmit,
  restrictToStaffIdIfAgent,
}: Props) => {
  const [initialValues] = useState({
    competitorGroups: competitorGroups || [],
  })
  return (
    <>
      <Section title="Competitor Groups">
        All buyers belonging to a retailer will not be able to be booked for appointments in the same time as all buyers
        belonging to all the OTHER retailers of the same Competitor Group.
      </Section>
      <ContainerCompetitorGroups>
        <Formik
          initialValues={initialValues}
          validateOnBlur
          validationSchema={competitorGroupsSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <FormCompetitorGroupsFields
              brandId={brandId}
              loadingSubmit={loadingSubmit}
              readOnly={readOnly}
              restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
            />
          )}
        </Formik>
      </ContainerCompetitorGroups>
    </>
  )
}

export default memo(FormCompetitorGroups)

import React, { memo, useCallback, useContext, useState } from "react"
import moment from "moment"

import RelouRequestConfirmModal from "../../../../elements/RelouRequestConfirmModal"
import { CalendarsContext } from "../../CalendarsProvider"
import { status as statuses } from "../../../../../config/session"

import {
  ContainerRequest,
  ContainerBuyerInfo,
  ContainerTime,
  ButtonStatus,
  Fullname,
  RetailerName,
  Date,
  Hours,
  Room,
  DateSent,
  ContainerInfos,
} from "./RelouRequest.styles"

type Props = {
  relouRequest: {
    createdAt: string,
    dayDate: number,
    room: {
      id: string,
      name: string,
    },
    startTime: number,
    endTime: number,
    buyers: {
      id: string,
      fullName: string,
    },
    status: string,
  },
}

const RelouRequest = ({ relouRequest }: Props) => {
  const { createdAt, buyers, dayDate, startTime, endTime, room, status } = relouRequest
  const {
    state: { selectedRelouRequest },
    dispatch,
  } = useContext(CalendarsContext)
  const [showModal, setShowModal] = useState(false)
  const isRelouRequestSelected = selectedRelouRequest?.id === relouRequest.id
  const handleClickConfirm = useCallback(e => {
    e.stopPropagation()
    setShowModal(true)
  }, [])
  const handleCloseModal = useCallback(() => setShowModal(false), [])
  const handleClickSelectRequest = useCallback(() => {
    dispatch({
      type: "setSelectedRelouRequest",
      relouRequest: isRelouRequestSelected ? null : relouRequest,
    })
  }, [isRelouRequestSelected, dispatch, relouRequest])

  const [mainBuyer = {}] = buyers
  const statusData = statuses[status]
  const momentStartTime = moment.utc(startTime, "X")
  const momentEndTime = moment.utc(endTime, "X")
  const momentDate = moment.utc(dayDate, "X")
  const momentCreatedAt = moment.utc(createdAt)
  return (
    <>
      <ContainerRequest isSelected={isRelouRequestSelected} onClick={handleClickSelectRequest}>
        <DateSent>{momentCreatedAt.format("DD/MM/YY [at] HH:mm")}</DateSent>
        <ContainerInfos>
          <ContainerBuyerInfo>
            <Fullname>{mainBuyer.fullName}</Fullname>
            <RetailerName>{mainBuyer.retailer?.name}</RetailerName>
            <Room>Room: {room.name}</Room>
          </ContainerBuyerInfo>
          <ContainerTime>
            <Date>{momentDate.format("ddd DD MMM")}</Date>
            <Hours>
              {momentStartTime.format("HH:mm")} - {momentEndTime.format("HH:mm")}
            </Hours>
            <ButtonStatus bgcolor={statusData.bg} onClick={status === "pending" ? handleClickConfirm : undefined}>
              {statusData.name}
            </ButtonStatus>
          </ContainerTime>
        </ContainerInfos>
      </ContainerRequest>
      {showModal && <RelouRequestConfirmModal relouRequestId={relouRequest.id} onClose={handleCloseModal} />}
    </>
  )
}

export default memo(RelouRequest)

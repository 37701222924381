import moment from "moment-timezone"
export const calculateBookingSize = (booking, dayStart, dayLength, tableHeight) => {
  const bookingStart = moment.utc(booking.startTime, "X")
  const bookingEnd = moment.utc(booking.endTime, "X")
  const diffFromStart = bookingStart.diff(dayStart, "hours", true)
  const diffFromEnd = bookingEnd.diff(dayStart, "hours", true)
  const top = Math.floor((tableHeight * diffFromStart) / dayLength)
  const height = Math.floor((tableHeight * diffFromEnd) / dayLength) - top
  return { top, height }
}

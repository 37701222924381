import styled from "styled-components"

export const PreviewContainer = styled.div`
  position: absolute;
  /* max-height: 300px; */
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  background-color: ${props => props.theme.primary};
  box-shadow: 0px 0px 5px #333333;
  padding: 10px 5px;
  box-sizing: border-box;
  font-size: 12px;
  z-index: 10;
  border-radius: 4px;
`
export const PreviewHeader = styled.div`
  color: #fff;
`

export const PreviewBody = styled.div`
  background-color: #fff;
  color: #4a4a4a;
  height: 100%;
  padding: 10px;
  height: 100%;
  font-size: 14px;
`

export const RoleTitle = styled.div`
  color: ${p => p.theme.gray200};
  font-size: 12px;
`

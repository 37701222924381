import { useMutation } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import styled from "styled-components"
import { Section } from "modaresa-commons"

import { UPDATE_STAFF_NOTIFICATIONS } from "../../../../graphql/mutations/staff"
import { onToastError, onToastSuccess } from "../../../../helpers/toast"

import FormNotifications from "./FormNotifications"

const ContainerForm = styled.div`
  width: 100%;
  max-width: 734px;
  padding: 30px 24px 30px 30px;
`
const Container = styled.div``

type Props = {
  staff: {
    id: string,
    notifications: {},
  },
}
const Notifications = ({ staff }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [updateStaffNotification] = useMutation(UPDATE_STAFF_NOTIFICATIONS)
  const handleSetNotificationVariable = useCallback(
    notification => ({
      active: notification.active,
      frequency: notification.frequency?.value,
    }),
    [],
  )
  const handleSaveNotifications = useCallback(
    async values => {
      const variables = {
        id: staff.id,
        notifications: {
          autoMorningRecap: handleSetNotificationVariable(values.autoMorningRecap),
          meetingBooked: handleSetNotificationVariable(values.meetingBooked),
          meetingCancelled: handleSetNotificationVariable(values.meetingCancelled),
          meetingChanged: handleSetNotificationVariable(values.meetingChanged),
          meetingCheckin: handleSetNotificationVariable(values.meetingCheckin),
          offScheduleRequest: handleSetNotificationVariable(values.offScheduleRequest),
        },
      }
      setIsLoading(true)
      try {
        await updateStaffNotification({ variables })
        onToastSuccess("Notifications settings saved successfully")
      } catch (e) {
        console.error(e)
        onToastError("An error occured while saving notifications settings")
      }
      setIsLoading(false)
    },
    [staff.id, updateStaffNotification, handleSetNotificationVariable],
  )
  return (
    <Container>
      <Section>Toggle on/off e-mails notifications you want to receive.</Section>
      <ContainerForm>
        <FormNotifications
          notifications={staff.notifications}
          onSubmit={handleSaveNotifications}
          loadingSubmit={isLoading}
        />
      </ContainerForm>
    </Container>
  )
}

export default memo(Notifications)

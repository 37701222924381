import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import Component from "./Buyer"

import {
  addBuyerWithNewDefaultRetailer,
  addClient,
  addReport,
  checkIn,
  checkOut,
  createReport,
  createUpdateRequest,
  deleteClient,
  exportReports,
  fetchReport,
  fetchReports,
  fetctClientBookings,
  fetchClients,
  findBuyers,
  findSharedBuyers,
  selectBuyerMember,
  unselectBuyerMember,
  unselectReport,
  updateClient,
  updateReport,
} from "store/actions/clients"
import {
  getAvailableRetailers,
  getBookingsRequestError,
  getBookingsRequestStatus,
  getBrand,
  getClientBookings,
  getClientRequestError,
  getClientRequestStatus,
  getClients,
  getClientsRequestStatus,
  getReport,
  getReportRequestError,
  getReportRequestStatus,
  getReports,
  getRequestUpdateEmailError,
  getRequestUpdateEmailPending,
  getRetailersRequestPending,
  getRetailers,
  getSelectedBuyer,
  getSharedRetailerRequestStatus,
  getSharedRetailers,
  getStaff,
  getUserData,
} from "store/selectors"
import { fetchRetailers, fetchSharedRetailers } from "store/actions/retailers"
import { leaveSocketRoom } from "store/actions/schedules"

const selector = createStructuredSelector({
  user: getUserData,
  retailers: getRetailers,
  retailersRequestPending: getRetailersRequestPending,
  sharedRetailers: getSharedRetailers,
  sharedRetailerRequestPending: getSharedRetailerRequestStatus,
  availableRetailers: getAvailableRetailers,
  availableRetailersRequestPending: getRetailersRequestPending,
  reports: getReports,
  seletedReport: getReport,
  clients: getClients,
  clientsRequestPending: getClientsRequestStatus,
  staff: getStaff,
  clientBookings: getClientBookings,
  selectedBuyer: getSelectedBuyer,
  brand: getBrand,
  requestPending: getClientRequestStatus,
  requestError: getClientRequestError,
  reportRequestPending: getReportRequestStatus,
  reportRequestError: getReportRequestError,
  bookingsRequestPending: getBookingsRequestStatus,
  bookingsRequestError: getBookingsRequestError,
  requestUpdateEmailPending: getRequestUpdateEmailPending,
  requestUpdateEmailError: getRequestUpdateEmailError,
})

const actions = {
  selectBuyerByID: selectBuyerMember,
  unselectBuyer: unselectBuyerMember,
  addClient,
  addBuyerWithNewDefaultRetailer,
  addReport,
  fetchSharedRetailers,
  findBuyers,
  findSharedBuyers,
  onFetchRetailers: fetchRetailers,
  onFetchClients: fetchClients,
  onFetchReports: fetchReports,
  onFetchReport: fetchReport,
  onFetchClientBookings: fetctClientBookings,
  onLeaveSocketRoom: leaveSocketRoom,
  onUpdateReport: updateReport,
  unselectReport,
  onCreateReport: createReport,
  onCheckOut: checkOut,
  onCheckIn: checkIn,
  onExportReports: exportReports,
  onUpdateClient: updateClient,
  onDelete: deleteClient,
  onCreateRequest: createUpdateRequest,
}

export const Buyer = connect(selector, actions)(Component)

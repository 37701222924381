import { call, put, select } from "redux-saga/effects"
import XLSX from "xlsx"
import moment from "moment-timezone"

import * as Api from "../../helpers/api"
import {
  ACTIVATE_SESSION,
  ADD_ATTACHMENT,
  CREATE_SESSION,
  DELETE_ATTACHMENT,
  DELETE_SESSION,
  FETCH_CAPACITY,
  FETCH_SALE_SESSION,
  FETCH_SALE_SESSION_REPORTS,
  FETCH_TIMEZONE,
  UPDATE_AVAILABILITIES,
  UPDATE_SESSION,
} from "../actions/salesSessions"
import { INVITE_CLIENTS, SEND_FOLLOWUPS } from "../actions/invites"
import { takeLatest } from "../../helpers/saga"

import { onToastError } from "./../../helpers/toast"

function* createSession({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data } = payload
  try {
    const response = yield call(Api.createSession, { data, token: accessToken })

    yield put({ type: CREATE_SESSION.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: CREATE_SESSION.FAILURE,
      payload: errosMsg,
    })
  }
}

function* createSessionFlow() {
  yield takeLatest(CREATE_SESSION.REQUEST, createSession)
}

function* updateSession({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data } = payload
  try {
    const response = yield call(Api.updateSession, {
      data,
      token: accessToken,
      id: data._id,
    })

    yield put({ type: UPDATE_SESSION.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: UPDATE_SESSION.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateSessionFlow() {
  yield takeLatest(UPDATE_SESSION.REQUEST, updateSession)
}

function* deleteSession({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { id, isDraft } = payload
  const query = isDraft ? "?type=draft" : ""
  try {
    const response = yield call(Api.deleteSession, {
      token: accessToken,
      id,
      query,
    })

    yield put({ type: DELETE_SESSION.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: DELETE_SESSION.FAILURE,
      payload: errosMsg,
    })
  }
}

function* deleteSessionFlow() {
  yield takeLatest(DELETE_SESSION.REQUEST, deleteSession)
}

function* activateSession({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { id } = payload
  try {
    const response = yield call(Api.activateSession, { token: accessToken, id })

    yield put({ type: ACTIVATE_SESSION.SUCCESS, payload: response })
  } catch (error) {
    onToastError()
    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: ACTIVATE_SESSION.FAILURE,
      payload: errosMsg,
    })
  }
}

function* activateSessionFlow() {
  yield takeLatest(ACTIVATE_SESSION.REQUEST, activateSession)
}

function* fetchSession({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { id } = payload
  try {
    const response = yield call(Api.fetchSession, { token: accessToken, id })

    yield put({ type: FETCH_SALE_SESSION.SUCCESS, payload: response })
  } catch (error) {
    onToastError()
    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_SALE_SESSION.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchSessionFlow() {
  yield takeLatest(FETCH_SALE_SESSION.REQUEST, fetchSession)
}

function* inviteClients({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { clients, sessionId, brandId } = payload
  try {
    const response = yield call(Api.inviteClients, {
      token: accessToken,
      clients,
      sessionId,
      brandId,
    })

    yield put({ type: INVITE_CLIENTS.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: INVITE_CLIENTS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* inviteClientsFlow() {
  yield takeLatest(INVITE_CLIENTS.REQUEST, inviteClients)
}

function* sendFollowups({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { sessionId, clients } = payload
  try {
    const response = yield call(Api.sendFollowups, {
      token: accessToken,
      sessionId,
      clients,
    })

    yield put({ type: SEND_FOLLOWUPS.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: SEND_FOLLOWUPS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* sendFollowupsFlow() {
  yield takeLatest(SEND_FOLLOWUPS.REQUEST, sendFollowups)
}

function* updateAvailabilities({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth

  const { data, sessionId, staffId } = payload
  try {
    const response = yield call(Api.updateAvailabilities, {
      token: accessToken,
      data,
      sessionId,
      staffId,
    })

    yield put({ type: UPDATE_AVAILABILITIES.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: UPDATE_AVAILABILITIES.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateAvailabilitiesFlow() {
  yield takeLatest(UPDATE_AVAILABILITIES.REQUEST, updateAvailabilities)
}

function* fetchSessionCapacity({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  try {
    const response = yield call(Api.fetchCapacity, {
      token: accessToken,
      sessionId: payload,
    })

    yield put({ type: FETCH_CAPACITY.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_CAPACITY.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchSessionCapacityFlow() {
  yield takeLatest(FETCH_CAPACITY.REQUEST, fetchSessionCapacity)
}

function* fetchSessionReports({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  try {
    const response = yield call(Api.fetchSessionReport, {
      token: accessToken,
      sessionId: payload,
    })

    XLSX.writeFile(response.wb, response.name)
  } catch (error) {
    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    onToastError(errosMsg)
  }
}

function* fetchSessionReportsFlow() {
  yield takeLatest(FETCH_SALE_SESSION_REPORTS.REQUEST, fetchSessionReports)
}

function* addAttachment({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { id, file } = payload
  try {
    const response = yield call(Api.addAttachemntToInvite, {
      token: accessToken,
      sessionId: id,
      file,
    })

    yield put({ type: ADD_ATTACHMENT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: ADD_ATTACHMENT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* addAttachmentFlow() {
  yield takeLatest(ADD_ATTACHMENT.REQUEST, addAttachment)
}

function* deleteAttachment({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data } = payload
  try {
    const response = yield call(Api.deleteAttachment, {
      token: accessToken,
      data,
    })

    yield put({ type: DELETE_ATTACHMENT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: DELETE_ATTACHMENT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* deleteAttachmentFlow() {
  yield takeLatest(DELETE_ATTACHMENT.REQUEST, deleteAttachment)
}

function* fetchTimezone({ payload }) {
  const latLng = payload
  try {
    const timestamp = moment().unix()
    const { lat, lng } = latLng
    const response = yield call(Api.fetchTimezone, { timestamp, lat, lng })

    yield put({ type: FETCH_TIMEZONE.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_TIMEZONE.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchTimezoneFlow() {
  yield takeLatest(FETCH_TIMEZONE.REQUEST, fetchTimezone)
}

export default [
  createSessionFlow,
  updateSessionFlow,
  deleteSessionFlow,
  fetchSessionFlow,
  activateSessionFlow,
  sendFollowupsFlow,
  inviteClientsFlow,
  updateAvailabilitiesFlow,
  fetchSessionCapacityFlow,
  fetchSessionReportsFlow,
  addAttachmentFlow,
  deleteAttachmentFlow,
  fetchTimezoneFlow,
]

import React, { memo, useCallback } from "react"
import { Column, Table } from "react-virtualized"

import { marketsByValue, modaResaRoles } from "../../../config"
import areasAndTerritoriesToString from "../../../helpers/areasAndTerritories/areasAndTerritoriesToString"

type Props = {
  width: number,
  height: number,
  buyers: [],
  onRowClick: Function,
  onRenderActions: Function,
  toggleRoomBasedAreas: boolean,
}

const TableStaffs = ({ width, height, staffs, onRowClick, onRenderActions, toggleRoomBasedAreas }: Props) => {
  const handleRowGetter = useCallback(({ index }) => staffs[index], [staffs])
  const handleRowClassName = useCallback(({ index }) => (index % 2 === 0 ? "oddRow" : "evenRow"), [])
  const handleRenderOffice = useCallback(({ cellData }) => cellData?.name, [])
  const handleRenderModaresaRole = useCallback(({ cellData }) => modaResaRoles[cellData]?.name || cellData, [])
  const handleRenderMarket = useCallback(({ cellData }) => marketsByValue[cellData], [])
  const handleRenderAreasTerritories = useCallback(
    ({ rowData: { geographicResponsibility } }) => {
      if (!geographicResponsibility) return ""

      return areasAndTerritoriesToString({
        areasAndTerritories: geographicResponsibility,
        toggleRoomBasedAreas,
      })
    },
    [toggleRoomBasedAreas],
  )
  const handleRenderEditorRights = useCallback(
    ({ cellData, rowData }) =>
      cellData || ["owner", "admin", "editor"].indexOf(rowData.modaresaRole) > -1 ? (
        <ion-icon name="md-checkmark" style={{ color: "green" }}></ion-icon>
      ) : (
        <ion-icon name="md-close" style={{ color: "red" }}></ion-icon>
      ),
    [],
  )
  const handleRenderActions = useCallback(
    ({ rowData }) => {
      if (!onRenderActions) return null
      return onRenderActions(rowData)
    },
    [onRenderActions],
  )
  return (
    <Table
      headerHeight={60}
      height={height}
      rowClassName={handleRowClassName}
      rowCount={staffs.length}
      rowGetter={handleRowGetter}
      rowHeight={50}
      width={width}
      onRowClick={onRowClick}
    >
      <Column dataKey="fullName" label="Full name" width={160} />
      <Column dataKey="companyPosition" label="Position" width={150} />
      <Column dataKey="office" label="Office" width={100} cellRenderer={handleRenderOffice} />
      <Column dataKey="modaresaRole" label="Role" width={100} cellRenderer={handleRenderModaresaRole} />
      <Column dataKey="market" label="Market" width={100} cellRenderer={handleRenderMarket} />
      <Column
        dataKey="areasAndTerritories"
        label="Areas & Territories"
        flexGrow={1}
        width={100}
        cellRenderer={handleRenderAreasTerritories}
      />
      <Column dataKey="hasEditorRights" label="Editor Rights" width={140} cellRenderer={handleRenderEditorRights} />
      <Column className="visible" dataKey={"no"} width={50} cellRenderer={handleRenderActions} />
    </Table>
  )
}

export default memo(TableStaffs)

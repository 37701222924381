import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { deleteSession, fetchSaleSessionReport } from "../../../../../store/actions/salesSessions"
import { getBrand, getUserData } from "../../../../../store/selectors"

import Component from "./ArchivedSessions"

const selector = createStructuredSelector({
  user: getUserData,
  brand: getBrand,
})

const actions = {
  onDeleteSession: deleteSession,
  onExportReports: fetchSaleSessionReport,
}

export const ArchivedSessions = connect(selector, actions)(Component)

import styled from "styled-components"

export const ContainerFieldLayout = styled.div`
  width: 100%;
  margin-bottom: 16px;
  ${p =>
    p.direction === "row"
      ? `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
 `
      : ""}
  ${p => (p.direction === "column" ? `` : "")}
`
export const ContainerChildren = styled.div`
  flex: 1;
`

export const FieldLabel = styled.label`
  font-weight: 400;
  font-size: 15px;
  ${p =>
    p.direction === "row"
      ? `
      width: ${p.labelWidth || "100px"};
      min-height: 38px;
      padding-right: 8px;
      margin-bottom: 0;
      align-items: center;
      display: inline-flex;
  `
      : `
      margin-bottom: 8px;
      `}
`

export const LabelTooltip = styled.span`
  width: 31px;
`
export const LabelText = styled.span``

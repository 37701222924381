import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import { Col, Row, Table, TableBody, TableHeader } from "./styles"

export class Overview extends Component {
  renderBody = () => {
    const { capacity, selectedSession } = this.props
    const today = moment
      .utc()
      .set({ hour: "00", minute: "00", second: "00" })
      .unix()
    const todayTime = moment().unix()
    return capacity.map((data, index) => {
      const dayDate = data[0].date
      const { buyingAppointment, walkthrough, businessMeeting } = data[0].appointments
      const { arrived, cancelled, delayed } = data[0].appointmentsStatus
      const dayEnd = selectedSession.openingHours[index].to
      const isDayEnd = todayTime > dayEnd
      return (
        <Row current={today === dayDate} key={index}>
          <Col style={{ width: "10%" }}> {moment.utc(data[0].date, "X").format("ddd, MMM Do")}</Col>
          <Col textAl="center" width="14%">
            {buyingAppointment + walkthrough + businessMeeting}
          </Col>
          <Col textAl="center" width="12%">
            {buyingAppointment}
          </Col>
          <Col textAl="center" width="12%">
            {walkthrough}
          </Col>
          <Col textAl="center" width="12%">
            {businessMeeting}
          </Col>
          <Col textAl="center" width="14%">
            {isDayEnd && delayed}
          </Col>
          <Col textAl="center" width="13%">
            {isDayEnd && cancelled}
          </Col>
          <Col textAl="center" width="13%">
            {isDayEnd && arrived}
          </Col>
        </Row>
      )
    })
  }

  render() {
    return (
      <Table className="divinder">
        <TableHeader>
          <Col style={{ width: "10%" }}>Date</Col>
          <Col textAl="center" width="14%">
            Total Scheduled Appointments
          </Col>
          <Col textAl="center" width="12%">
            Buying Appointment
          </Col>
          <Col textAl="center" width="12%">
            Walkthrough
          </Col>
          <Col textAl="center" width="12%">
            Business Meeting
          </Col>
          <Col className="special" textAl="center" width="14%">
            Delayed
          </Col>
          <Col className="special" textAl="center" width="13%">
            Cancelled
          </Col>
          <Col className="special" textAl="center" width="13%">
            Completed
          </Col>
        </TableHeader>
        <TableBody>{this.renderBody()}</TableBody>
      </Table>
    )
  }
}

Overview.propTypes = {
  capacity: PropTypes.array,
  selectedSession: PropTypes.object,
}

export default Overview

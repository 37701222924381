import React, { Component } from "react"
import PropTypes from "prop-types"
import auth0 from "auth0-js"

import { auth } from "../../elements/Auth/Auth"

import loading from "static/loading.svg"

class Callback extends Component {
  componentDidMount() {
    const { location, history, onFinishLogin } = this.props
    if (/access_token|id_token|error/.test(location.hash)) {
      auth.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          const manAuth0 = new auth0.Management({
            domain: "modaresa.eu.auth0.com",
            token: authResult.accessToken,
            scope: "read:users read:current_user",
          })
          manAuth0.getUser(authResult.idTokenPayload.sub, (err, user) => {
            onFinishLogin({
              accessToken: authResult.accessToken,
              idToken: authResult.idToken,
              userId: user.app_metadata.staffId,
              emailVerified: authResult.idTokenPayload.email_verified,
            })
            history.replace("/calendars")
          })
        } else if (err) {
          history.replace("/")
        }
      })
    }
  }

  render() {
    return (
      <div>
        <img alt="loading" className="loading-circle" src={loading} />
      </div>
    )
  }
}

Callback.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onFinishLogin: PropTypes.func,
}

export default Callback

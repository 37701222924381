import { gql } from "@apollo/client"

import AreaFragments from "../queries/area.fragments"
import RemainingAreaFragments from "../queries/remainingArea.fragments"
import RoomFragments from "../queries/room.fragments"

export const UPDATE_SALES_SESSION_AREAS = gql`
  mutation UpdateSalesSessionAreas(
    $salesSessionId: ID!
    $areas: [SalesSessionAreaInput]
    $remainingArea: RemainingAreaInput
  ) {
    updateSalesSessionAreas(salesSessionId: $salesSessionId, areas: $areas, remainingArea: $remainingArea) {
      id
      remainingArea {
        ...RemainingAreaStaffs
        notificationMeetingConfirmation
        notificationMeetingReminder
      }
      areas {
        ...AreaStaffs
        ...AreaTerritory
        name
        color
        notificationMeetingConfirmation
        notificationMeetingReminder
      }
    }
  }
  ${AreaFragments.fragments.staffs}
  ${AreaFragments.fragments.territory}
  ${RemainingAreaFragments.fragments.staffs}
`

export const UPDATE_SALES_SESSION_ROOMS_AREAS = gql`
  mutation UpdateSalesSessionAreas($salesSessionId: ID!, $rooms: [SalesSessionRoomUpdateInput]) {
    updateSalesSessionRoomsAreas(salesSessionId: $salesSessionId, rooms: $rooms) {
      id
      rooms {
        ...RoomAreas
      }
    }
  }
  ${RoomFragments.fragments.areas}
`

export const DO_OPENING_HOURS_HAVE_ALL_EXISTING_BOOKING_EVENTS_WITHIN = gql`
  mutation DoOpeningHoursHaveAllExistingBookingEventsWithin($salesSessionId: ID!, $openingHours: [OpeningHourInput!]) {
    doOpeningHoursHaveAllExistingBookingEventsWithin(salesSessionId: $salesSessionId, openingHours: $openingHours) {
      haveAll
      missingBookingEventsByOpeningHour {
        openingHour {
          available
          date
          from
          to
        }
        events {
          id
          clients {
            id
            fullName
          }
          startTime
          endTime
        }
      }
    }
  }
`

export const SEND_SALES_SESSION_BOOKING_INVITATIONS = gql`
  mutation SendSalesSessionBookingInvitations($salesSessionId: ID!, $buyersIds: [ID!]!) {
    sendSalesSessionBookingInvitations(salesSessionId: $salesSessionId, buyersIds: $buyersIds) {
      id
      status
      inviteStatus
      buyerId
    }
  }
`

export const SEND_SALES_SESSION_BOOKING_FOLLOWUPS = gql`
  mutation SendSalesSessionBookingFollowUps($salesSessionId: ID!, $invitedBuyersIds: [ID!]!) {
    sendSalesSessionBookingFollowUps(salesSessionId: $salesSessionId, invitedBuyersIds: $invitedBuyersIds) {
      id
      status
      inviteStatus
      buyerId
    }
  }
`

import { useQuery } from "@apollo/client"
import { LayoutWithSideMenu } from "modaresa-commons"
import React, { memo, useMemo } from "react"

import { ME_LOGGED_STAFF_AVAILABLE_SESSIONS } from "../../../../../graphql/queries/user"

type Props = {
  activeSessions: [
    {
      id: string,
      name: string,
    },
  ],
  hasEditorRights: boolean,
  onUnselectSession: Function,
}
const SessionsLayout = ({ hasEditorRights, onUnselectSession, children }: Props) => {
  const { data } = useQuery(ME_LOGGED_STAFF_AVAILABLE_SESSIONS, {
    variables: {
      sessionStatus: "active",
      sessionIsPast: false,
    },
  })
  const activeSessions = useMemo(() => data?.me.staff.availableSessions || [], [data])
  const menuItems = useMemo(() => {
    const items = []
    if (hasEditorRights) {
      items.push({
        key: "new",
        name: "+ New Session",
        to: `/sessions/new`,
        onClick: onUnselectSession,
      })
    }
    items.push(
      {
        key: "actives",
        name: "Active Sale Sessions",
        to: `/sessions/active`,
        alwaysOpen: true,
        subItems: activeSessions.map(session => ({
          key: session.id,
          name: session.name,
          to: `/sessions/${session.id}`,
        })),
      },
      {
        key: "draft",
        name: "Draft Sessions",
        to: `/sessions/draft`,
      },
      {
        key: "archived",
        name: "Archived",
        to: `/sessions/archived`,
      },
    )
    return items
  }, [activeSessions, hasEditorRights, onUnselectSession])

  return <LayoutWithSideMenu menuItems={menuItems}>{children}</LayoutWithSideMenu>
}

export default memo(SessionsLayout)

import { useMutation, useQuery } from "@apollo/client"
import React, { useCallback, memo, useState } from "react"
import { Error, Loading } from "modaresa-commons"

import { UPDATE_BRAND_COMPETITOR_GROUPS } from "../../../../../graphql/mutations/brand"
import { BRAND_COMPETITOR_GROUPS } from "../../../../../graphql/queries/brand"
import { getGraphQLErrorAsString } from "../../../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"

import FormCompetitorGroups from "./FormCompetitorGroups"

type Props = {
  brandId: string,
  canEdit: boolean,
  restrictToStaffIdIfAgent: string,
}

const CompetitorGroups = ({ brandId, canEdit, restrictToStaffIdIfAgent }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [updateBrandCompetitorGroups] = useMutation(UPDATE_BRAND_COMPETITOR_GROUPS)
  const handleSubmit = useCallback(
    async values => {
      setIsLoading(true)
      const variables = {
        brandId,
        competitorGroups: values.competitorGroups.map(group => ({
          id: group.id,
          name: group.name,
          retailersIds: group.retailers.map(retailer => retailer.id),
        })),
      }
      try {
        await updateBrandCompetitorGroups({ variables })
        onToastSuccess("Competitors groups updated successfully")
      } catch (e) {
        console.error(e)
        onToastError("An error occured when updating competitor groups." + getGraphQLErrorAsString(e))
      }
      setIsLoading(false)
    },
    [brandId, updateBrandCompetitorGroups],
  )
  const { loading, error, data } = useQuery(BRAND_COMPETITOR_GROUPS, {
    variables: { id: brandId },
    onCompleted: () => setInitialLoading(false),
  })
  if (loading && initialLoading) return <Loading />
  if (error) return <Error />
  const { competitorGroups } = data.Brand
  return (
    <FormCompetitorGroups
      loadingSubmit={isLoading}
      onSubmit={handleSubmit}
      brandId={brandId}
      competitorGroups={competitorGroups}
      readOnly={!canEdit}
      restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
    />
  )
}

export default memo(CompetitorGroups)

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  checkNewBookingTime,
  fetchAvailableClients,
  fetchAvailableStaff,
  unselectBooking,
  updateBooking,
} from "../../../../../store/actions/schedules"
import {
  getAvailableStaffFetchingStatus,
  getBookingAvailableHosts,
  getBookingAvailableStaff,
  getBookingOtherHosts,
  getClients,
  getRequestError,
  getRequestStatus,
  getRetailers,
  getSelectedSession,
  getStaff,
  getTimeCheckError,
  getTimeCheckStatus,
} from "../../../../../store/selectors"

import Component from "./EditBookingModal"

const selector = createStructuredSelector({
  retailers: getRetailers,
  allStaff: getStaff,
  clients: getClients,
  availableHosts: getBookingAvailableHosts,
  otherHosts: getBookingOtherHosts,
  availableStaff: getBookingAvailableStaff,
  isFetchingMeetingStaff: getAvailableStaffFetchingStatus,
  isRequestingTimeCheck: getTimeCheckStatus,
  timeCheckError: getTimeCheckError,
  isRequestPending: getRequestStatus,
  requestError: getRequestError,
  selectedSession: getSelectedSession,
})

const actions = {
  onCheckNewBookingTime: checkNewBookingTime,
  onUpdateBooking: updateBooking,
  onFetchAvailableClients: fetchAvailableClients,
  onFetchAvailableStaff: fetchAvailableStaff,
  onUnselectBooking: unselectBooking,
}

export const EditBookingModal = connect(selector, actions)(Component)

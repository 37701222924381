import React, { memo, useCallback } from "react"
import styled from "styled-components"
import { Input } from "modaresa-commons"

const Budget = styled.div`
  border: 1px solid ${props => props.theme.gray50};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  margin-bottom: 20px;
`
const StyledInput = styled(Input)`
  max-width: 130px;
  width: 100%;
  margin-left: 12px;
`

const MeetingBudget = ({ value, onChange, disabled }) => {
  const handleChange = useCallback(e => onChange(e.target.value), [onChange])
  return (
    <Budget>
      <span>Expected Budget</span>
      <StyledInput
        disabled={disabled}
        name="budget"
        placeholder="Optional €"
        value={value}
        width="50%"
        onChange={handleChange}
      />
    </Budget>
  )
}
export default memo(MeetingBudget)

import React, { useCallback, memo } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Tabs, TabPane, LayoutWithSideMenu } from "modaresa-commons"

import PageTitle from "../../elements/PageTitle"

import { Attendance } from "./blocks/Attendance"
import { Capacity } from "./blocks/Capacity"
import { Notifications } from "./blocks/Notifications"

const Dashboard = () => {
  const { section } = useParams()
  const history = useHistory()
  const handleChangeTab = useCallback(
    newSection => {
      history.replace(`/dashboard/${newSection}`)
    },
    [history],
  )
  return (
    <PageTitle title="Dashboard">
      <LayoutWithSideMenu hideSideMenu>
        <Tabs defaultActiveKey={section} onChange={handleChangeTab}>
          <TabPane tabKey="attendance" tab="Attendance">
            <Attendance />
          </TabPane>
          <TabPane tabKey="capacity" tab="Capacity">
            <Capacity />
          </TabPane>
          <TabPane tabKey="requests" tab="Notifications">
            <Notifications />
          </TabPane>
        </Tabs>
      </LayoutWithSideMenu>
    </PageTitle>
  )
}

export default memo(Dashboard)

import { gql } from "@apollo/client"

export const GET_BUYER_BOOKING_EVENTS = gql`
  query Buyer($id: ID!, $timePeriod: TIME_PERIOD) {
    Buyer(id: $id) {
      id
      bookingEvents(timePeriod: $timePeriod) {
        id
        room
        dayDate
        startTime
        endTime
        session {
          id
          name
        }
      }
    }
  }
`

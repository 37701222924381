import { useQuery } from "@apollo/client"
import moment from "moment-timezone"
import React, { memo, useCallback, useMemo, useState } from "react"
import Select from "react-select"

import { BRAND_SALES_SESSION } from "../../../graphql/queries/brand"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

type Props = {
  brandId: string,
  statuses?: [string],
  multiple: boolean,
  placeholder?: boolean,
  disabled: boolean,
  defaultValue: any,
  value: any,
}

const SalesSessionPicker = ({
  brandId,
  statuses,
  multiple,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
  selectFirstValue,
}: Props) => {
  const [isDefaultFirstValue, setIsDefaultFirstValue] = useState(false)
  const handleGetOptionValue = useCallback(option => option.id, [])
  const handleGetOptionLabel = useCallback(option => option.name, [])
  const { loading, error, data } = useQuery(BRAND_SALES_SESSION, {
    variables: { id: brandId, statuses },
    fetchPolicy: "cache-first",
    onCompleted: data => {
      const [firstSession] = data.Brand.sessions
      if (!isDefaultFirstValue && selectFirstValue) {
        setIsDefaultFirstValue(true)
        onChange(firstSession)
      }
    },
  })
  const options = useMemo(() => {
    if (!data?.Brand?.sessions?.length) return []
    const { sessions } = data.Brand

    const today = moment.utc()
    return sessions.reduce(
      (optionsDividers, session) => {
        const lastDay = moment.utc(session.closingDay, "X")
        if (lastDay.isBefore(today, "day")) {
          optionsDividers[1].options.push(session)
        } else {
          optionsDividers[0].options.push(session)
        }
        return optionsDividers
      },
      [
        { label: "Active", options: [] },
        { label: "Archived", options: [] },
      ],
    )
  }, [data])
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading sales sessions</ErrorLiveChatHelp>
  return (
    <Select
      isLoading={loading}
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      options={options}
      isMulti={multiple}
      placeholder={placeholder}
      onChange={onChange}
      getOptionValue={handleGetOptionValue}
      getOptionLabel={handleGetOptionLabel}
    />
  )
}

SalesSessionPicker.defaultProps = {
  placeholder: "Select sales session",
  statuses: ["active"],
}
export default memo(SalesSessionPicker)

import { gql } from "@apollo/client"

import AreaFragments from "./area.fragments"
import RemainingAreaFragments from "./remainingArea.fragments"
import RoomFragments from "./room.fragments"
import SalesSessionFragments from "./salesSession.fragments"

export const SALES_SESSION_AREAS = gql`
  query SalesSession($id: ID!) {
    SalesSession(id: $id) {
      id
      status
      brand {
        id
      }
      remainingArea {
        ...RemainingAreaStaffs
        notificationMeetingConfirmation
        notificationMeetingReminder
      }
      ...SalesSessionAreas
    }
  }
  ${AreaFragments.fragments.staffs}
  ${AreaFragments.fragments.territory}
  ${RemainingAreaFragments.fragments.staffs}
  ${SalesSessionFragments.fragments.areas}
`

export const SALES_SESSION_ROOMS_AREAS = gql`
  query SalesSessionRoomsAreas($id: ID!) {
    SalesSession(id: $id) {
      id
      status
      brand {
        id
      }
      rooms {
        ...RoomAreas
      }
    }
  }
  ${RoomFragments.fragments.areas}
`

export const SALES_SESSION_ROOM_AREAS_AND_RETAILERS = gql`
  query SalesSessionRoomAreasAndRetailers($id: ID!) {
    SalesSession(id: $id) {
      id
      status
      toggleRoomBasedAreas
      brand {
        id
        retailers(status: active) {
          id
          name
        }
      }
      ...SalesSessionAreas
      rooms {
        ...RoomAreas
      }
    }
  }
  ${SalesSessionFragments.fragments.areas}
  ${RoomFragments.fragments.areas}
`

export const SALES_SESSION_ROOMS = gql`
  query SalesSessionRooms($id: ID!) {
    SalesSession(id: $id) {
      id
      rooms {
        id
        name
        hasBlockingList
        typeBlockingList
      }
    }
  }
`

export const SALES_SESSION_INVITED_BUYERS = gql`
  query SalesSessionRooms($salesSessionId: ID!, $brandId: ID!, $restrictToStaffId: ID, $market: MARKET_CATEGORY) {
    SalesSession(id: $salesSessionId) {
      id
      invitedBuyers {
        id
        status
        inviteStatus
        buyerId
      }
    }
    Brand(id: $brandId) {
      id
      buyers(
        status: active
        restrictToStaffId: $restrictToStaffId
        restrictToSalesSessionId: $salesSessionId
        market: $market
      ) {
        id
        fullName
        position
        email
        market
        retailer {
          id
          name
          priorityGroup
          status
          storeAddress {
            city
            country
            continent
          }
        }
      }
    }
    getSalesSessionBookings(sessionId: $salesSessionId) {
      id
      clients {
        id
      }
      status
      appointmentStatus
      dayDate
      startTime
      endTime
      room
      meetingApproach
      session {
        id
        status
      }
    }
  }
`

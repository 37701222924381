import debounce from "lodash/debounce"
import React, { useMemo, memo, useCallback, useState } from "react"

import RoomBasedAreasManagerView from "./RoomBasedAreasManagerView"

type Props = {
  loading: boolean,
  brandId: string,
  rooms?: [],
  onChangeRooms: Function,
  errors: {
    id: {
      areas: {
        id: string,
      },
    },
  },
  canAreasBeModified: boolean,
  canStaffsBeModified: boolean,
  retailers: [],
}

const RoomBasedAreasManager = ({
  loading,
  brandId,
  rooms,
  onChangeRooms,
  errors,
  canAreasBeModified,
  canStaffsBeModified,
  retailers,
}: Props) => {
  const [filterValue, setFilterValue] = useState("")
  const handleFilter = useMemo(() => debounce(e => setFilterValue(e.target.value), 300), [])
  const handleChangeFilter = useCallback(
    e => {
      e.persist()
      handleFilter(e)
    },
    [handleFilter],
  )
  const handleChangeRoom = useCallback(
    updatedRoom => {
      const newRooms = rooms.map(room => (updatedRoom.id === room.id ? updatedRoom : room))
      onChangeRooms(newRooms)
    },
    [onChangeRooms, rooms],
  )
  const filteredRooms = useMemo(
    () => rooms.filter(({ name }) => name.toLowerCase().includes(filterValue.toLowerCase())),
    [filterValue, rooms],
  )
  return (
    <RoomBasedAreasManagerView
      loading={loading}
      brandId={brandId}
      rooms={filteredRooms}
      filterValue={filterValue}
      onChangeRoom={handleChangeRoom}
      onChangeFilter={handleChangeFilter}
      errors={errors}
      canAreasBeModified={canAreasBeModified}
      canStaffsBeModified={canStaffsBeModified}
      retailers={retailers}
    />
  )
}

RoomBasedAreasManager.defaultProps = {
  rooms: [],
}
export default memo(RoomBasedAreasManager)

import { useMutation } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import styled from "styled-components"
import { Section } from "modaresa-commons"

import { UPDATE_STAFF_VIDEO_IDS } from "../../../../graphql/mutations/staff"
import { getGraphQLErrorAsString } from "../../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../../helpers/toast"
import { countObjKeysDefined } from "../../../../helpers/utils"

import FormVirtualMeetingIds from "./FormVirtualMeetingIds"
import ModalWarningIdsDeleted from "./ModalWarningIdsDeleted"

const ContainerForm = styled.div`
  width: 100%;
  max-width: 734px;
  padding: 30px 24px 30px 30px;
`
const Container = styled.div``

type Props = {
  staff: {
    videoIDs: {
      facetime: string,
      skype: string,
      zoom: string,
      googlemeet: string,
      msteams: string,
      whereby: string,
    },
  },
  readOnly: boolean,
}

const VirtualMeetingIds = ({ staff, readOnly }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModalWarningIdsDeleted, setShowModalWarningIdsDeleted] = useState(false)
  const [updateStaffVideoIDs] = useMutation(UPDATE_STAFF_VIDEO_IDS)
  const handleCloseModal = useCallback(() => setShowModalWarningIdsDeleted(false), [])
  const handleSubmit = useCallback(
    async values => {
      setIsLoading(true)
      const oldVideoIdsCount = countObjKeysDefined(staff.videoIDs)
      const newVideoIdsCount = countObjKeysDefined(values)
      if (oldVideoIdsCount > newVideoIdsCount) {
        setShowModalWarningIdsDeleted(true)
      }
      const variables = {
        id: staff.id,
        videoIDs: {
          whatsapp: values.whatsapp,
          facetime: values.facetime,
          skype: values.skype,
          zoom: values.zoom,
          googlemeet: values.googlemeet,
          whereby: values.whereby,
          msteams: values.msteams,
        },
      }
      try {
        await updateStaffVideoIDs({ variables })
        onToastSuccess("Your Virtual Meeting IDs have been saved successfully.")
      } catch (err) {
        console.error(err)
        onToastError(`An error occured while saving video ids ${getGraphQLErrorAsString(err)}`)
      }
      setIsLoading(false)
    },
    [staff.videoIDs, staff.id, updateStaffVideoIDs],
  )
  return (
    <Container>
      <Section title="">These IDs will be used for virtual meetings during sales sessions.</Section>
      <ContainerForm>
        <FormVirtualMeetingIds
          videoIds={staff.videoIDs}
          onSubmit={handleSubmit}
          readOnly={readOnly}
          loadingSubmit={isLoading}
        />
      </ContainerForm>
      {showModalWarningIdsDeleted && <ModalWarningIdsDeleted onClose={handleCloseModal} />}
    </Container>
  )
}

export default memo(VirtualMeetingIds)

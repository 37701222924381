import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 54px);
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  min-height: 45px;
  padding-right: 15px;
  justify-content: space-between;
  background: #f5f5f5;
`

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;

  ${({ image }) => {
    if (image) {
      return `
    font-size: 14px;
    flex-direction: column
    `
    }
  }}

  & img {
    object-fit: contain;
    width: 75%;
    height: 75%;
  }
`

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`

export const CloseModal = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.strawberry};
`

export const ModalBody = styled.div`
  & .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255);
  }
`
export const Bold = styled.span`
  font-weight: bold;
`
export const Text = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`
export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Icon = styled.img`
  display: block;
  width: auto;
  height: 18px;
  margin: 0 10px;
  ${props => (props.rotate ? "transform: rotate(180deg)" : "")};
  ${props => (props.hidden ? "visibility: hidden" : "")};
`
export const Date = styled.span`
  color: #fff;
`

export const Appointment = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px solid ${({ theme }) => theme.veryLightGray};
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 20px 20px 15px 30px;
  min-height: 100px;
  font-size: 16px;
`

export const Appointments = styled.div`
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 60px;
  padding-right: 30px;
`
export const AppointmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: space-between;
`
export const AppointmentActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  max-width: 430px;
  justify-content: space-between;
`

export const Phone = styled.span`
  padding-left: 15px;
`

export const Time = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 490px;
`
export const TimeDivinder = styled.div`
  position: relative;
  border: 1px solid #dadfea;
  margin-bottom: 10px;

  & span {
    font-size: 16px;
    position: absolute;
    left: -8px;
    transform: translate(-100%, -50%);
  }
`
export const SeleteContainer = styled.div`
  width: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`
export const Tab = styled.div`
  display: flex;
  width: 33%;
  min-height: 45px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #f7f7f7;
  color: #666666;
  padding-left: 10px;
  padding-right: 10px;

  &.active {
    background: ${({ theme }) => theme.primary};
    color: #fff;
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const String = styled.div`
  display: flex;
  align-items: center;
`

export const Table = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.divinder {
    position: relative;
    &::before {
      content: "";
      height: 100%;
      position: absolute;
      width: 1px;
      right: 40%;
      top: 0;
      background: #efebeb;
      z-index: 1;
    }
  }
`

export const TableBody = styled.div`
  height: 100%;
`

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid #efebeb;
  background: #fff;
  padding-left: 30px;
  padding-right: 30px;
  position: sticky;
  top: 0;
  font-weight: bold;

  & > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;

    & > span {
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  border-bottom: 1px solid #efebeb;
  padding-left: 30px;
  padding-right: 30px;
  ${({ current }) => current && "background: #e0eafe;"}

  &>div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }
`

export const Col = styled.div`
  width: ${({ width }) => width};
  text-align: ${({ textAl }) => textAl || "left"};

  &.special {
    font-style: italic;
    color: #abaaab;
  }
`
export const Status = styled.span`
  display: block;
  max-width: 130px;
  color: #fff;
  text-align: center;
  line-height: 37px;
  height: 38px;
  border-radius: 3px;
  background: ${({ bgcolor }) => bgcolor || "#e3e3e3"};
  cursor: ${({ isButton }) => (isButton ? "pointer" : "default")};
`

export const BookingDate = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
`
export const Flex = styled.div`
  display: flex;
  justify-content: ${props => props.justify || "space-between"};
  flex-direction: ${props => (props.column ? "column" : "")};
  align-items: ${props => props.align || "center"};
  margin: ${props => props.margin};

  & .startTime {
    margin-bottom: 5px;
  }
`

export const MeetingDate = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${props => props.margin || "20px"};

  & img {
    display: block;
    margin: 10px;
    height: 25px;
    width: auto;
  }
`
export const MeetingInfo = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.gray50};
  border-radius: 8px;
  padding: 10px;
  margin-bottom: ${props => props.margin || "20px"};

  & .meetingTypes {
    margin-bottom: 5px;
  }
`

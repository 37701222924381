import React, { useCallback, useState, memo } from "react"
import { useMutation, useQuery } from "@apollo/client"
import isEmpty from "lodash/isEmpty"

import { UPDATE_BRAND_SETTINGS } from "../../../../../graphql/mutations/brand"
import { BRAND_ROOMS_AREAS } from "../../../../../graphql/queries/brand"
import { formatAreaInput, formatRemainingAreaInput } from "../../../../../helpers/areas"
import isEmptyTerritory from "../../../../../helpers/areasAndTerritories/isEmptyTerritories"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"

import CompanyAreasSkeleton from "./CompanyAreasSkeleton"
import CompanyAreasView from "./CompanyAreasView"

type Props = {
  brandId: string,
  canEdit: boolean,
}

const CompanyAreas = ({ brandId, canEdit }: Props) => {
  const [errors, setErrors] = useState({})
  const [isChanged, setIsChanged] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRoomBasedAreas, setIsRoomBasedAreas] = useState(false)
  const [rooms, setRooms] = useState([])
  const [areas, setAreas] = useState([])
  const [remainingArea, setRemainingArea] = useState([])
  const [updateBrandSettings] = useMutation(UPDATE_BRAND_SETTINGS)

  const handleRoomsChange = useCallback(newRooms => {
    setRooms(newRooms)
    setIsChanged(true)
  }, [])

  const handleChangeAreas = useCallback(newAreas => {
    setAreas(newAreas)
    setIsChanged(true)
  }, [])

  const handleChangeRemainingArea = useCallback(newRemainingArea => {
    setRemainingArea(newRemainingArea)
    setIsChanged(true)
  }, [])

  const handleToggleRoomBasedAreas = useCallback(checked => {
    setIsRoomBasedAreas(checked)
    setIsChanged(true)
  }, [])

  const validateAreas = useCallback(areasToCheck => {
    const areasErrors = {}
    areasToCheck.forEach((area, index) => {
      if (!area.name) {
        areasErrors[index] = "Please enter name for area"
      } else if (isEmptyTerritory(area.territories)) {
        areasErrors[index] = "Please enter at least one territory"
      }
    })
    return isEmpty(areasErrors) ? null : areasErrors
  }, [])
  const handleCheckErrors = useCallback(() => {
    const checkErrors = {}
    if (isRoomBasedAreas) {
      checkErrors.rooms = {}
      rooms.forEach(room => {
        const areasErrors = validateAreas(room.areas)
        if (areasErrors) {
          checkErrors.rooms[room.id] = {
            areas: areasErrors,
          }
        }
      })
      if (isEmpty(checkErrors.rooms)) {
        delete checkErrors.rooms
      }
    } else {
      const areasErrors = validateAreas(areas)
      if (areasErrors) {
        checkErrors.areas = areasErrors
      }
    }
    return checkErrors
  }, [areas, isRoomBasedAreas, rooms, validateAreas])

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    const checkErrors = handleCheckErrors()
    if (!isEmpty(checkErrors)) {
      setIsLoading(false)
      onToastError("Please check fields in areas")
      return setErrors(checkErrors)
    }
    setErrors({})
    const variables = {
      brandId,
      toggleRoomBasedAreas: isRoomBasedAreas,
      rooms: rooms.map(room => ({
        id: room.id,
        name: room.name,
        areas: room.areas.map(formatAreaInput),
        remainingArea: room.remainingArea && formatRemainingAreaInput(room.remainingArea),
      })),
    }
    if (!isRoomBasedAreas) {
      variables.areas = areas.map(formatAreaInput)
      variables.remainingArea = remainingArea && formatRemainingAreaInput(remainingArea)
    }
    try {
      await updateBrandSettings({ variables })
      onToastSuccess(
        "New company structure settings saved successfully! These changes will be effective for future sales sessions",
      )
    } catch (e) {
      console.error(e)
      onToastError("Error while updating company structure")
    }
    setIsLoading(false)
  }, [handleCheckErrors, brandId, isRoomBasedAreas, rooms, areas, remainingArea, updateBrandSettings])
  const { loading, error } = useQuery(BRAND_ROOMS_AREAS, {
    variables: { id: brandId },
    onCompleted: ({ Brand: { toggleRoomBasedAreas, rooms } }) => {
      setInitialLoading(true)
      setIsRoomBasedAreas(toggleRoomBasedAreas)
      setRooms(rooms)
    },
  })
  if (loading && !initialLoading) return <CompanyAreasSkeleton />
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error occured while loading rooms</ErrorLiveChatHelp>

  return (
    <CompanyAreasView
      brandId={brandId}
      disableSaveChanges={!canEdit || !isChanged || isLoading}
      isRoomBasedAreas={isRoomBasedAreas}
      canEdit={canEdit}
      rooms={rooms}
      onChangeRooms={handleRoomsChange}
      onToggleRoomBasedAreas={handleToggleRoomBasedAreas}
      onChangeAreas={handleChangeAreas}
      onChangeRemainingArea={handleChangeRemainingArea}
      onSubmit={handleSubmit}
      errors={errors}
    />
  )
}
export default memo(CompanyAreas)

import { useMutation, useQuery } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import { AlertDanger } from "modaresa-commons"

import { UPDATE_SALES_SESSION_AREAS } from "../../../../../graphql/mutations/salesSession"
import { SALES_SESSION_AREAS } from "../../../../../graphql/queries/salesSession"
import { formatRemainingAreaInput, formatSalesSessionAreaInput } from "../../../../../helpers/areas"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"

import SalesSessionAreasView from "./SalesSessionAreasDefaultView"

type Props = {
  salesSessionId: string,
  canEdit: boolean,
  onSaveChanges: Function,
}

const SalesSessionAreas = ({ salesSessionId, canEdit, onSaveChanges }: Props) => {
  const [isChanged, setIsChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [areas, setAreas] = useState(null)
  const [remainingArea, setRemainingArea] = useState(null)
  const [updateSalesSessionAreas] = useMutation(UPDATE_SALES_SESSION_AREAS)

  const handleChangeAreas = useCallback(newAreas => {
    setAreas(newAreas)
    setIsChanged(true)
  }, [])
  const handleChangeRemainingArea = useCallback(newRemainingArea => {
    setRemainingArea(newRemainingArea)
    setIsChanged(true)
  }, [])

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    const variables = {
      salesSessionId,
      areas: areas.map(formatSalesSessionAreaInput),
      remainingArea: remainingArea && formatRemainingAreaInput(remainingArea),
    }
    try {
      await updateSalesSessionAreas({ variables })
      onToastSuccess("Areas settings updated successfully!")
      setIsLoading(false)
      if (onSaveChanges) {
        onSaveChanges()
      }
    } catch (e) {
      console.error(e)
      onToastError("Error while updating areas settings")
      setIsLoading(false)
    }
  }, [areas, remainingArea, salesSessionId, updateSalesSessionAreas, onSaveChanges])

  const { loading, error, data } = useQuery(SALES_SESSION_AREAS, {
    variables: { id: salesSessionId },
    onCompleted: ({ SalesSession: { areas: loadedAreas, remainingArea: loadedRemainingArea } }) => {
      setAreas(loadedAreas || [])
      setRemainingArea(loadedRemainingArea || {})
    },
  })
  if (loading && !data) return <SalesSessionAreasView isLoadingData />
  if (error) return <AlertDanger>Error while loading sales session</AlertDanger>
  const { brand, areas: firstLoadAreas, remainingArea: firstLoadRemainingArea } = data.SalesSession
  const disableSaveChanges = isLoading
  const labelSaveChanges = isChanged ? "Save changes" : "Next"
  return (
    <SalesSessionAreasView
      brandId={brand.id}
      disableSaveChanges={disableSaveChanges}
      labelSaveChanges={labelSaveChanges}
      canAreaStaffsBeModified={canEdit}
      areas={areas || firstLoadAreas || []}
      remainingArea={remainingArea || firstLoadRemainingArea || {}}
      onChangeAreas={handleChangeAreas}
      onChangeRemainingArea={handleChangeRemainingArea}
      onSaveChanges={handleSubmit}
    />
  )
}

export default memo(SalesSessionAreas)

import Tooltip from "rc-tooltip"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import moment from "moment-timezone"
import { Flex } from "modaresa-commons"

import CancellationsIcon from "../../../../../static/Cancelled.svg"
import { countries } from "../../../../../config/countries"

import { BookingDate, Col, Container, Icon, NotFound, Row, Table, TableBody, TableHeader } from "./styles"

const nameString = array => {
  let str = ""
  array.forEach(obj => {
    str += `${obj.firstName} ${obj.lastName}, `
  })
  return str.slice(0, -2)
}

export class CancellationsList extends Component {
  renderBody = () => {
    const { cancellations } = this.props
    return cancellations.map((data, index) => (
      <Row key={index}>
        <Col width="15%"> {data.cancelledAt && moment.utc(data.cancelledAt, "X").format("ddd, MMM Do, HH:mm")}</Col>
        <Col style={{ padding: "5px 0", whiteSpace: "initial" }} width="14%">
          {" "}
          {nameString(data.clients)}
        </Col>
        <Col width="16%"> {`${data.retailer.name} (${countries[data.retailer.origin.country].name})`}</Col>
        <Col width="14%"> {data.areaManager.fullName || "-"}</Col>
        <Col width="20%">
          <BookingDate>
            <div style={{ whiteSpace: "pre" }}>{moment.utc(data.dayDate, "X").format("ddd, DD MMM YYYY, ")} </div>
            <div>{`${moment.utc(data.startTime, "X").format("HH:mm")} - ${moment
              .utc(data.endTime, "X")
              .format("HH:mm")}`}</div>
          </BookingDate>
          <div style={{ fontSize: "12px" }}>{data.room}</div>
        </Col>
        <Col width="21%">
          {data.cancelledBy && data.cancelledBy.type === "client" && (
            <Flex justify="flex-start" style={{ width: "100%" }}>
              <Tooltip overlay={data.comment || "-"}>
                <Icon src={CancellationsIcon} style={{ height: "25px" }} />
              </Tooltip>
            </Flex>
          )}
          {data.cancelledBy && data.cancelledBy.type === "staff" && <span> - </span>}
        </Col>
      </Row>
    ))
  }

  render() {
    const { cancellations, requestFetchPending } = this.props

    if (requestFetchPending) {
      return (
        <Container style={{ height: "100%" }}>
          <NotFound>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </NotFound>
        </Container>
      )
    }

    if (!cancellations || cancellations.length === 0) {
      return (
        <Container style={{ height: "100%", paddingTop: 64 }}>
          <NotFound>
            <span>NO CANCELLATIONS</span>
          </NotFound>
        </Container>
      )
    }

    return (
      <Table>
        <TableHeader>
          <Col width="15%">Cancellation Time Stamp</Col>
          <Col width="14%">Full Name</Col>
          <Col width="16%">Retailer (Country)</Col>
          <Col width="14%">Area Manager</Col>
          <Col width="20%">Date of Original Appointment</Col>
          <Col width="21%">Comments from client</Col>
        </TableHeader>
        <TableBody>{this.renderBody()}</TableBody>
      </Table>
    )
  }
}

CancellationsList.propTypes = {
  cancellations: PropTypes.array,
  requestFetchPending: PropTypes.bool,
}

export default CancellationsList

import { countries, inviteStatuses, marketsByValue } from "../../../../../config"
import continents from "../../../../../config/continents"
import { doesStringMatch } from "../../../../../helpers/filter"

const buyerInviteSearch = {
  fullName: {
    match: (buyerFullname = "", searchValue) => {
      if (doesStringMatch(buyerFullname.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: buyerFullname,
            key: "fullName",
            label: `${buyerFullname} (full name)`,
          },
        ]
      }
      return null
    },
    filter: (buyers, value) => buyers.filter(buyer => buyer.fullName === value),
  },
  email: {
    match: (buyerEmail = "", searchValue) => {
      if (doesStringMatch(buyerEmail.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: buyerEmail,
            key: "email",
            label: `${buyerEmail} (email)`,
          },
        ]
      }
      return null
    },
    filter: (buyers, value) => buyers.filter(buyer => buyer.email === value),
  },
  position: {
    match: (buyerPosition = "", searchValue) => {
      const lowerCasePosition = buyerPosition.toLowerCase()
      if (doesStringMatch(lowerCasePosition, searchValue.toLowerCase())) {
        return [
          {
            value: lowerCasePosition,
            key: "position",
            label: `${buyerPosition} (position)`,
          },
        ]
      }
      return null
    },
    filter: (buyers, value) => buyers.filter(buyer => buyer.position.toLowerCase() === value),
  },
  invitation: {
    match: (buyerInvitation, searchValue) => {
      const statusData = (buyerInvitation && inviteStatuses[buyerInvitation.status]) || inviteStatuses.notInvited
      if (doesStringMatch(statusData.label.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: statusData.value,
            key: "invitation.status",
            label: `${statusData.label} (status)`,
          },
        ]
      }
    },
  },
  "invitation.status": {
    filter: (buyers, value) => {
      let filterFunction = buyer => buyer.invitation?.status === value
      if (value === "notInvited") {
        filterFunction = buyer => {
          const buyerStatus = buyer.invitation?.status
          return buyerStatus === value || !buyerStatus
        }
      }
      return buyers.filter(filterFunction)
    },
  },
  retailer: {
    match: (buyerRetailer, searchValue) => {
      if (!buyerRetailer) return null
      const searchValueLowercase = searchValue.toLowerCase()
      const matchedOptions = []
      const { name, storeAddress, priorityGroup, status } = buyerRetailer
      if (doesStringMatch(name.toLowerCase(), searchValueLowercase)) {
        matchedOptions.push({
          value: name,
          key: "retailer.name",
          label: `${name} (retailer)`,
        })
      }
      if (doesStringMatch(`priority group ${priorityGroup}`, searchValue.toLowerCase())) {
        return [
          {
            value: priorityGroup,
            key: "retailer.priorityGroup",
            label: `Priority Group ${priorityGroup}`,
          },
        ]
      }
      if (doesStringMatch(status, searchValue.toLowerCase())) {
        return [
          {
            value: status,
            key: "retailer.status",
            label: `${status} (retailer status)`,
          },
        ]
      }
      if (storeAddress) {
        const { country, city, continent } = storeAddress
        const countryObj = countries[country]
        if (doesStringMatch(countryObj?.name.toLowerCase(), searchValueLowercase)) {
          matchedOptions.push({
            value: country,
            key: "retailer.country",
            label: `${countryObj.name} (country)`,
          })
        }
        if (doesStringMatch(city.toLowerCase(), searchValueLowercase)) {
          matchedOptions.push({
            value: city,
            key: "retailer.city",
            label: `${city} (city)`,
          })
        }
        const continentLabel = continent && continents[continent]
        if (continentLabel && doesStringMatch(continentLabel.toLowerCase(), searchValueLowercase)) {
          matchedOptions.push({
            value: continent,
            key: "retailer.continent",
            label: `${continentLabel} (continent)`,
          })
        }
      }
      return matchedOptions
    },
  },
  "retailer.name": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.name === value),
  },
  "retailer.country": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.storeAddress?.country === value),
  },
  "retailer.city": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.storeAddress?.city === value),
  },
  "retailer.continent": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.storeAddress?.continent === value),
  },
  "retailer.priorityGroup": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.priorityGroup === value),
  },
  "retailer.status": {
    filter: (buyers, value) => buyers.filter(buyer => buyer.retailer?.status === value),
  },
  market: {
    match: (buyerMarket, searchValue) => {
      const buyerMarketLabel = marketsByValue[buyerMarket]
      if (!buyerMarketLabel) return null
      if (doesStringMatch(buyerMarketLabel.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: buyerMarket,
            key: "market",
            label: `${buyerMarketLabel} (market)`,
          },
        ]
      }
      return null
    },
    filter: (buyers, value) => buyers.filter(buyer => buyer.market === value),
  },
}

export const buyerInviteSearchMatchKeys = Object.keys(buyerInviteSearch).filter(key => buyerInviteSearch[key].match)
export default buyerInviteSearch

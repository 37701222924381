import moment from "moment"
import React, { memo } from "react"
import { B } from "modaresa-commons"

import BookingEventPicker from "./BookingEventPicker"
import { ContainerBookings, Flex, IconSwitch, Date, MainBooking } from "./ModalBody.styles"

type Props = {
  booking: {
    _id: string,
    dayDate: number,
    startTime: number,
    endTime: number,
  },
  parallelBookingEvents: [],
  onChangeBookingEventToSwitch: Function,
}

const ModalBody = ({ booking, parallelBookingEvents, onChangeBookingEventToSwitch }: Props) => {
  const day = moment.utc(booking.dayDate, "X").format("DD MMM")
  const start = moment.utc(booking.startTime, "X").format("HH:mm")
  const end = moment.utc(booking.endTime, "X").format("HH:mm")
  return (
    <>
      <Date>
        <B>{day}</B>, {start} - {end}
      </Date>
      <ContainerBookings>
        <Flex>
          <MainBooking booking={booking} />
        </Flex>
        <IconSwitch />
        <Flex>
          {parallelBookingEvents.length > 0 ? (
            <BookingEventPicker bookingEvents={parallelBookingEvents} onChange={onChangeBookingEventToSwitch} />
          ) : (
            <div>No other bookings</div>
          )}
        </Flex>
      </ContainerBookings>
    </>
  )
}

export default memo(ModalBody)

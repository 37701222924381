import styled from "styled-components"

import Toggler from "../../Toggler/Toggler"

export const ContainerNotifications = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid rgb(204, 204, 204);
  position: relative;
`

export const MargedToggler = styled(Toggler)`
  padding: 20px;
`

export const ContainerClose = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.primary};
  cursor: pointer;
  padding: 20px 20px 0 20px;
`

export const Title = styled.h3`
  margin: 0;
  color: ${p => p.theme.black};
`

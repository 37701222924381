import PropTypes from "prop-types"
import React from "react"

import { Filter } from "../CalendarFilter"

import { Container, Tab, Tabs } from "./styles"
const CalendarTabs = ({ onSelect, rooms, selectedTab }) => (
  <Container>
    <Tabs>
      <Tab
        className={selectedTab.type === "master" ? "calendar-tab-active" : ""}
        onClick={() => onSelect({ type: "master", label: "Master Calendar" })}
      >
        Master Calendar
      </Tab>
      {rooms.map((room, idx) => (
        <Tab
          className={selectedTab.type === "custom" && selectedTab.label === room.name ? "calendar-tab-active" : ""}
          key={room.name + idx}
          onClick={() => onSelect({ type: "custom", label: room.name })}
        >
          {room.name}
        </Tab>
      ))}
      <Tab
        className={selectedTab.type === "joker" ? "calendar-tab-active" : ""}
        onClick={() => onSelect({ type: "joker", label: "Joker" })}
      >
        Relou
      </Tab>
    </Tabs>
    <Filter />
  </Container>
)

CalendarTabs.propTypes = {
  rooms: PropTypes.array,
  selectedTab: PropTypes.object,
  onSelect: PropTypes.func,
}

export { CalendarTabs }

import { useQuery } from "@apollo/client"
import React, { memo } from "react"
import { AlertWarning, B } from "modaresa-commons"
import styled from "styled-components"

import { BRAND_OWNER } from "../../../../../graphql/queries/brand"

export const MargedAlertWarning = styled(AlertWarning)`
  margin: 0;
  max-width: 100%;
  border-radius: 0;
  border-right: 0;
  border-left: 0;
  border-top: 0;
`

type Props = {
  brandId: string,
}

const CheckBrandOwnerEmailVerified = ({ brandId }: Props) => {
  const { loading, error, data } = useQuery(BRAND_OWNER, {
    variables: {
      id: brandId,
    },
  })
  if (loading && !data) return null
  if (error) return null
  const { owner, name } = data.Brand
  if (!owner || owner.emailVerified) return null
  return (
    <MargedAlertWarning>
      Please verify {name}'s owner{" "}
      <B>
        {owner.firstName} {owner.lastName}
      </B>{" "}
      email to allow Modaresa sending invitations on his behalf
    </MargedAlertWarning>
  )
}

export default memo(CheckBrandOwnerEmailVerified)

import { FETCH_DB_DATA, FETCH_DB_USER, FETCH_USER, UPDATE_USER } from "../actions/user"
import { LOGIN, LOGOUT } from "../actions/auth"
import { createReducer } from "../../helpers/redux"

const initialState = {
  user: null,
  dbUser: null,
  dataLoaded: false,
  requestLoginPending: false,
  requestLoginError: false,
}

const handlers = {
  [LOGIN.REQUEST]: state => ({
    ...state,
    requestLoginPending: true,
    requestLoginError: null,
  }),
  [LOGIN.SUCCESS]: state => ({
    ...state,
    requestLoginPending: false,
  }),
  [LOGIN.FAILURE]: (state, { payload }) => ({
    ...state,
    requestLoginPending: false,
    requestLoginError: payload,
  }),
  [FETCH_USER.SUCCESS]: (state, { payload }) => ({
    ...state,
    user: payload.staffMember,
  }),
  [FETCH_DB_USER.SUCCESS]: (state, { payload }) => ({
    ...state,
    dbUser: payload.user,
  }),
  [FETCH_DB_DATA.SUCCESS]: state => ({
    ...state,
    dataLoaded: true,
  }),
  [UPDATE_USER]: (state, { payload }) => ({
    ...state,
    user: payload,
  }),
  [LOGOUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import moment from "moment-timezone"
import React, { memo } from "react"
import styled from "styled-components"
import { Flex } from "modaresa-commons"

import TimeIcon from "../../../../static/clock.svg"

type Props = {
  dayDate: number,
  startTime: number,
  endTime: number,
}

export const Date = styled.div`
  display: flex;
  min-height: 38px;
  align-items: center;
  justify-content: center;
  width: 50px;
  background: #f1f3f5;
`
export const MeetingDate = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  border: 1px solid ${props => props.theme.gray50};
  border-radius: 8px;
  padding: 10px;

  & img {
    display: block;
    margin: 10px;
    height: 25px;
    width: auto;
  }
`

const BookingDate = ({ dayDate, startTime, endTime }: Props) => (
  <MeetingDate>
    <div>
      <img alt="icon" src={TimeIcon} />
    </div>
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: "5px" }}>{moment.utc(dayDate, "X").format("dddd, MMMM Do Y")}</div>
      <Flex justify="flex-start">
        <Date>
          <span>{moment.utc(startTime, "X").format("HH:mm")}</span>
        </Date>
        <span style={{ padding: "0 10px" }}>to</span>
        <Date>
          <span>{moment.utc(endTime, "X").format("HH:mm")}</span>
        </Date>
      </Flex>
    </div>
  </MeetingDate>
)

export default memo(BookingDate)

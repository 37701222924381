import Raven from "raven-js"

import { EmptyAddress } from "services/address"

export class Retailer {
  constructor(
    _id = "",
    name = "",
    type = "",
    phoneNumber = "",
    clientId = "",
    priorityGroup = "",
    website = "",
    dbStatus = "",
    status = "",
    sharedRetailerId = "",
    brandId = "",
    shippingAddress = EmptyAddress,
    storeAddress = EmptyAddress,
  ) {
    this._id = _id
    this.name = name
    this.type = type
    this.phoneNumber = phoneNumber
    this.clientId = clientId
    this.priorityGroup = priorityGroup
    this.website = website
    this.dbStatus = dbStatus
    this.status = status
    this.sharedRetailerId = sharedRetailerId
    this.brandId = brandId
    this.shippingAddress = shippingAddress
    this.storeAddress = storeAddress
  }
}

export const EmptyRetailer = new Retailer()

export function onMissingRetailerIdForClient(client) {
  const onMissingRetailerId = retailerId => {
    // TODO: Ideally we would have a Logger module which redirects logs to console in dev, and to Raven in staging/prod
    console.error(
      `Buyer "${client.firstName} ${client.lastName}" (${client._id}) is attached to retailer with ID (${retailerId}) but the retailer is not present in the brand with ID (${client.brandId})`,
    )
    Raven.captureMessage(
      `Buyer "${client.firstName} ${client.lastName}" (${client._id}) is attached to retailer with ID (${retailerId}) but the retailer is not present in the brand with ID (${client.brandId})`,
      "error",
    )
  }
  return onMissingRetailerId
}

export function getRetailerByIdOrEmpty(retailerId, retailers, onMissingRetailerId) {
  if (!retailerId || !retailers || !("byId" in retailers)) {
    return EmptyRetailer
  }
  if (!(retailerId in retailers.byId)) {
    if (onMissingRetailerId) onMissingRetailerId(retailerId, retailers)
    return EmptyRetailer
  }

  return retailers.byId[retailerId]
}

import React, { memo } from "react"
import { Link } from "react-router-dom"
import Dropdown, { DropdownContent, DropdownTrigger } from "react-simple-dropdown"
import { Button } from "modaresa-commons"

import { DropdownMenu, DropdownMenuItem } from "./ButtonAddClients.styles"

const ButtonAddClients = () => (
  <Dropdown>
    <DropdownTrigger>
      <Button>+ Add Buyers / Retailers</Button>
    </DropdownTrigger>
    <DropdownContent>
      <DropdownMenu border style={{ minWidth: "300px" }}>
        <DropdownMenuItem>
          <Link to={"/clients/retailers/new"}>
            <span
              style={{
                minWidth: "30px",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              +
            </span>{" "}
            Add Retailer
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <Link to={`/clients/new`}>
            <span
              style={{
                minWidth: "30px",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              +
            </span>{" "}
            Add Buyer
          </Link>
        </DropdownMenuItem>
      </DropdownMenu>
    </DropdownContent>
  </Dropdown>
)

export default memo(ButtonAddClients)

import { useQuery } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { AlertDanger } from "modaresa-commons"

import { STAFF_FULL_PROFILE } from "../../../graphql/queries/staff"
import PageTitle from "../../elements/PageTitle/PageTitle"
import StaffProfile from "../../elements/StaffProfile"
import ErrorLiveChatHelp from "../../ui/ErrorLiveChatHelp"

const Staff = () => {
  const { id, section } = useParams()
  const history = useHistory()
  const [activeSection, setActiveSection] = useState(section || "general")

  const handleChangeTab = useCallback(
    newSection => {
      setActiveSection(newSection)
      history.replace(`/staff/${id}/${newSection}`)
    },
    [history, id],
  )
  const { loading, error, data } = useQuery(STAFF_FULL_PROFILE, {
    variables: { id },
  })
  if (loading && !data)
    return (
      <PageTitle title="Staff profile">
        <StaffProfile skeleton activeSection={activeSection} onChangeTab={handleChangeTab} />
      </PageTitle>
    )
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading staff</ErrorLiveChatHelp>
  const { Staff } = data
  if (!Staff) return <AlertDanger>This staff doesn't exist or has been deleted</AlertDanger>
  return (
    <PageTitle title={`${Staff.firstName} ${Staff.lastName}`}>
      <StaffProfile activeSection={activeSection} staff={Staff} onChangeTab={handleChangeTab} />
    </PageTitle>
  )
}

export default memo(Staff)

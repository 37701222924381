import { useField } from "formik"
import React, { useCallback, useEffect, useMemo, memo } from "react"
import Select from "react-select"
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete"
import styled from "styled-components"

import { formatAddressFromAddressComponents, loadGoogleMapsApi } from "../../../../../../helpers/googleMapsApi"
import FormFieldLayout from "../../../../../elements/FormFieldLayout/FormFieldLayout"

const CityCountry = styled.div`
  line-height: 40px;
`

const RetailerStoreAddress = () => {
  const [field, meta, helpers] = useField("storeAddress")
  const { suggestions, setValue: setPlacesAutoCompleteValue } = usePlacesAutocomplete({
    debounce: 500,
    callbackName: "initMap",
    requestOptions: {
      types: ["(cities)"],
    },
  })

  const handleChangeSelectInput = useCallback(
    inputValue => {
      setPlacesAutoCompleteValue(inputValue)
    },
    [setPlacesAutoCompleteValue],
  )
  const handleSelectPlace = useCallback(
    async option => {
      const { place_id: placeId } = option.value
      const place = await getDetails({ placeId })
      const storeAddress = formatAddressFromAddressComponents(place.address_components)
      storeAddress.formattedAddress = place.formatted_address
      helpers.setValue(storeAddress)
    },
    [helpers],
  )

  useEffect(() => {
    loadGoogleMapsApi()
  }, [])
  const displayedValue = useMemo(
    () => (field.value ? { value: field.value, label: field.value.formattedAddress } : undefined),
    [field.value],
  )
  const storeAddressOptions = useMemo(() => {
    if (!suggestions || !suggestions.data) return []
    return suggestions.data.map(suggestion => ({
      value: suggestion,
      label: suggestion.description,
    }))
  }, [suggestions])
  return (
    <>
      <FormFieldLayout
        label="Store Address*"
        labelWidth="140px"
        name="storeAddress"
        error={meta.touched && meta.error?.city && "Must have a city"}
      >
        <Select
          value={displayedValue}
          options={storeAddressOptions}
          isLoading={suggestions.loading}
          placeholder="Enter city of retailer"
          onChange={handleSelectPlace}
          onInputChange={handleChangeSelectInput}
        />
      </FormFieldLayout>
      {(field.value.country || field.value.city) && (
        <FormFieldLayout label="City, Country" labelWidth="140px" name="storeAddressInfos">
          <CityCountry>
            {field.value.city}, {field.value.country}
          </CityCountry>
        </FormFieldLayout>
      )}
    </>
  )
}

export default memo(RetailerStoreAddress)

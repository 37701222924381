import { useFormikContext } from "formik"
import moment from "moment"
import React, { useCallback, memo, useMemo } from "react"
import DatePicker from "react-datepicker"
import styled from "styled-components"

import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

const ContainerRange = styled.div`
  display: flex;
  flex-direction: row;
`

const ContainerDatePicker = styled.div`
  margin: 0 16px;
  flex: 1;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

type Props = {
  label: string,
  labelWidth: string,
  nameStart: string,
  nameEnd: string,
  direction?: "row" | "column",
  onChangeStart: Function,
  onChangeEnd: Function,
  disabled: boolean,
  minDate: string,
  placeholderStart: string,
  placeholderEnd: string,
}

const FormikDateRangePicker = ({
  onChangeStart,
  onChangeEnd,
  label,
  labelWidth,
  nameStart,
  nameEnd,
  direction,
  disabled,
  minDate,
  placeholderStart,
  placeholderEnd,
}: Props) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormikContext()
  const handleChangeMinDate = useCallback(
    value => {
      setFieldValue(nameStart, value)
      setFieldValue(nameEnd, null)
      if (onChangeStart) {
        onChangeStart(value)
      }
    },
    [setFieldValue, nameStart, nameEnd, onChangeStart],
  )
  const handleChangeMaxDate = useCallback(
    value => {
      setFieldValue(nameEnd, value)
      if (onChangeEnd) {
        onChangeEnd(value, values[nameStart])
      }
    },
    [setFieldValue, nameEnd, onChangeEnd, values, nameStart],
  )
  const handleBlur = useCallback(field => () => setFieldTouched(field, true), [setFieldTouched])
  const fieldError = useMemo(() => {
    if (touched[nameStart] && errors[nameStart]) {
      return errors[nameStart]
    }
    if (touched[nameEnd] && errors[nameEnd]) {
      return errors[nameEnd]
    }
  }, [touched, errors, nameStart, nameEnd])
  return (
    <FormFieldLayout label={label} labelWidth={labelWidth} name={nameStart} direction={direction} error={fieldError}>
      <ContainerRange>
        <ContainerDatePicker>
          <DatePicker
            dateFormat="DD/MM/YYYY"
            minDate={minDate || moment()}
            placeholderText={placeholderStart}
            selected={values[nameStart]}
            utcOffset={0}
            withPortal
            onBlur={handleBlur(nameStart)}
            onChange={handleChangeMinDate}
            disabled={disabled}
          />
        </ContainerDatePicker>
        <ContainerDatePicker>
          <DatePicker
            dateFormat="DD/MM/YYYY"
            minDate={values[nameStart] || moment()}
            placeholderText={placeholderEnd}
            selected={values[nameEnd]}
            utcOffset={0}
            withPortal
            onBlur={handleBlur(nameEnd)}
            onChange={handleChangeMaxDate}
            disabled={disabled}
          />
        </ContainerDatePicker>
      </ContainerRange>
    </FormFieldLayout>
  )
}

FormikDateRangePicker.defaultProps = {
  direction: "row",
}
export default memo(FormikDateRangePicker)

import { useQuery } from "@apollo/client"
import React, { useMemo, useRef, memo, useCallback } from "react"
import { AlertDanger } from "modaresa-commons"

import { BRAND_ROOMS_AREAS } from "../../../../../graphql/queries/brand"
import RoomBasedAreasManager from "../../../../elements/RoomBasedAreasManager/RoomBasedAreasManager"

type Props = {
  brandId: string,
  rooms: string,
  onChangeRooms: Function,
  errors: {
    id: {
      areas: {
        id: string,
      },
    },
  },
  canEdit: boolean,
}

const mapRoomsWithDataToCurrentRooms = (currentRooms, roomsWithData) =>
  currentRooms.reduce((allRooms, room) => {
    const roomWithData = roomsWithData.find(({ id }) => id === room.id)
    if (roomWithData) {
      allRooms.push(roomWithData)
    } else if (room.isNew) {
      allRooms.push(room)
    }
    return allRooms
  }, [])

const BrandRoomBasedAreas = ({ brandId, rooms, onChangeRooms, errors, canEdit }: Props) => {
  const roomsRef = useRef(null)
  const handleChangeRooms = useCallback(
    updatedRooms => {
      roomsRef.current = updatedRooms
      onChangeRooms(updatedRooms)
    },
    [onChangeRooms],
  )
  const { loading, error, data } = useQuery(BRAND_ROOMS_AREAS, {
    variables: { id: brandId },
  })
  const roomsWithData = useMemo(() => {
    if (!data || !data.Brand) return []
    const mappedRooms = mapRoomsWithDataToCurrentRooms(rooms, roomsRef.current || data.Brand.rooms)
    roomsRef.current = mappedRooms
    return mappedRooms
  }, [data, rooms])
  if (loading && !data) return <RoomBasedAreasManager loading />
  if (error) return <AlertDanger>Error while loading room based areas</AlertDanger>
  const { retailers } = data.Brand
  return (
    <RoomBasedAreasManager
      retailers={retailers}
      brandId={brandId}
      rooms={roomsWithData}
      onChangeRooms={handleChangeRooms}
      errors={errors}
      canAreasBeModified={canEdit}
      canStaffsBeModified={canEdit}
    />
  )
}

export default memo(BrandRoomBasedAreas)

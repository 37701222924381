import React, { memo } from "react"

import Areas from "../Areas/Areas"
import AreasManager from "../AreasManager/AreasManager"

import { RoomHeader, RoomName, ToggleDisplay, RoomNameSkeleton } from "./RoomAreasView.styles"

type Props = {
  showAreas: boolean,
  room: {
    name: string,
    areas: [],
    remainingArea: [],
  },
  onChangeAreas: Function,
  onChangeRemainingArea: Function,
  onToggleShowAreas: Function,
  errors: {},
  canAreasBeModified: boolean,
  canStaffsBeModified: boolean,
  retailers: [],
}

const RoomAreasView = ({
  skeleton,
  brandId,
  room: { name, areas, remainingArea },
  showAreas,
  onChangeAreas,
  onChangeRemainingArea,
  onToggleShowAreas,
  errors,
  canAreasBeModified,
  canStaffsBeModified,
  retailers,
}: Props) =>
  skeleton ? (
    <div>
      <RoomHeader>
        <RoomNameSkeleton />
      </RoomHeader>
      <Areas loading />
    </div>
  ) : (
    <div>
      <RoomHeader onClick={onToggleShowAreas}>
        <RoomName>{name}</RoomName>
        <ToggleDisplay>{showAreas ? "Hide" : "Show"}</ToggleDisplay>
      </RoomHeader>
      {showAreas && (
        <AreasManager
          brandId={brandId}
          defaultAreas={areas}
          defaultRemainingArea={remainingArea}
          onChangeAreas={onChangeAreas}
          onChangeRemainingArea={onChangeRemainingArea}
          errors={errors}
          canAreasBeModified={canAreasBeModified}
          canStaffsBeModified={canStaffsBeModified}
          retailers={retailers}
        />
      )}
    </div>
  )

export default memo(RoomAreasView)

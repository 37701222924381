export const isValidDate = dateString => {
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return false
  }
  const parts = dateString.split("/")
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const year = parseInt(parts[2], 10)
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false
  }
  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29
  }
  return day > 0 && day <= monthLength[month - 1]
}

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false
  arr1.sort()
  arr2.sort()
  for (let i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false
  }

  return true
}

export const freelancerAccess = ["/clients", "/settings", "/calendars"]

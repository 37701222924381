import styled from "styled-components"
import { AlertWarning, Input } from "modaresa-commons"

export const ContainerFilterInput = styled.div`
  background-color: ${p => p.theme.gray75};
  padding: 16px 30px;
`

export const FilterInput = styled(Input)`
  max-width: 300px;
`

export const ErrorEmpty = styled(AlertWarning)`
  margin: 16px 30px;
`

import styled from "styled-components"

export const Container = styled.div`
  min-height: 60px;
  align-items: center;

  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.gray};
`

export const Tabs = styled.div`
  display: flex;
  flex: 1;
  min-height: inherit;
`
const active = "calendar-tab-active"
export const Tab = styled.div.attrs({
  active,
})`
  border-right: 1px solid ${props => props.theme.gray100};
  max-width: 200px;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 10px;
  align-items: center;
  &.${active} {
    background-color: #fff;
  }
  cursor: pointer;
`

import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const TextareaText = styled.textarea`
  background-color: #ffffff;
  height: 80px;
  min-height: 80px;
  width: 100%;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
  box-sizing: border-box;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  &:disabled {
    background-color: #f2f2f2;
    border: 1px solid #e6e6e6;
    color: hsl(0, 0%, 60%);
  }
  border: 1px solid ${props => (props.isError ? props.theme.strawberry : "transparent")} &[disabled] {
    color: ${props => props.theme.veryLightGray};
  }
`

export const TextareaContainer = styled.div`
  position: relative;
  color: ${props => props.theme.textBlack};
  width: ${props => props.width || "100%"};
`

export const ValidationError = styled.div`
  position: absolute;
  color: ${props => props.theme.primary};
  font-size: 12px;
  bottom: 0;
  transform: translateY(100%);
  left: 0px;
  z-index: 0;
`

export const Textarea = props => (
  <TextareaContainer width={props.width}>
    <TextareaText isError={props.errors && props.touched} {...props} />
    {props.errors && props.touched && <ValidationError>{props.errors}</ValidationError>}
  </TextareaContainer>
)

Textarea.propTypes = {
  errors: PropTypes.string,
  touched: PropTypes.bool,
  width: PropTypes.string,
}

import { all, fork } from "redux-saga/effects"

import authSagas from "./auth"
import brandSagas from "./brand"
import calendarSagas from "./calendar"
import clientSags from "./clients"
import retailersSagas from "./retailers"
import scheduleSagas from "./schedule"
import sessionSagas from "./session"
import staffSagas from "./staff"

export default function* root(params) {
  const sagas = [
    ...authSagas,
    ...sessionSagas,
    ...calendarSagas,
    ...brandSagas,
    ...staffSagas,
    ...clientSags,
    ...scheduleSagas,
    ...retailersSagas,
  ]
  yield all([...sagas].map(saga => fork(saga, params)))
}

import React, { useCallback, memo } from "react"
import { Button } from "modaresa-commons"

import Toggler from "../../../../elements/Toggler"
import { DeleteButton, Footer, FooterLeft, FooterRight } from "../../../../elements/SessionFooter"

import { AlgorithmSection, AlgosContainer, Container, Content, RoomBasedAreasToggler } from "./AlgorithmView.styles"

type Props = {
  readOnly: boolean,
  buttonText: string,
  onSubmit: Function,
  onChangeAlgo: Function,
  algorithms: {
    room: boolean,
    geo: boolean,
    lang: boolean,
  },
  onClickPrev: Function,
  onDeleteSession: Function,
}
const AlgorithmView = ({
  readOnly,
  algorithms,
  buttonText,
  onSubmit,
  onChangeAlgo,
  onClickPrev,
  onDeleteSession,
  isRoomBasedAreas,
}: Props) => {
  const handleChangeToggle = useCallback(field => checked => onChangeAlgo(field, checked), [onChangeAlgo])
  return (
    <Container>
      <Content>
        {isRoomBasedAreas && (
          <AlgorithmSection color="#516bef" title="NB! Room based areas enabled">
            <p>Meeting responsibles, Signees and Notified Staff(s) are associated to Areas defined within Rooms</p>
          </AlgorithmSection>
        )}
        <AlgorithmSection title="Automatic Meeting Host Allocation">
          <p>How do you want ModaResa to allocate your appointments to your vendors?</p>
          <p>You can choose multiple, and the order below will be applied.</p>
        </AlgorithmSection>
        <AlgosContainer>
          {isRoomBasedAreas ? (
            <RoomBasedAreasToggler
              title="Areas & Territories"
              description="Automatically allocate bookings to meeting hosts based on areas assigned to rooms"
              disabled={readOnly}
              checked={algorithms.geo}
              onChange={handleChangeToggle("geo")}
              showBorder
            />
          ) : (
            <>
              <Toggler
                title="Room Responsibility"
                description="Automatically allocate bookings to meeting hosts based on their assigned room(s)"
                disabled={readOnly}
                checked={algorithms.room}
                onChange={handleChangeToggle("room")}
                showBorder
              />
              <Toggler
                title="Areas & Territories"
                description="Automatically allocate bookings to meeting hosts based on their assigned area(s) / territory(ies)"
                disabled={readOnly}
                checked={algorithms.geo}
                onChange={handleChangeToggle("geo")}
                showBorder
              />
            </>
          )}

          <Toggler
            title="Language spoken"
            description="Allow buyers to request a vendor based on a specific language"
            disabled={readOnly}
            checked={algorithms.lang}
            onChange={handleChangeToggle("lang")}
            showBorder
          />
        </AlgosContainer>
      </Content>
      {!readOnly && (
        <Footer>
          <FooterLeft>
            <Button type="button" onClick={onClickPrev}>
              Previous
            </Button>
          </FooterLeft>
          <FooterRight>
            <DeleteButton type="button" onClick={onDeleteSession}>
              Delete Sales Session
            </DeleteButton>
            <Button type="button" onClick={onSubmit}>
              {buttonText}
            </Button>
          </FooterRight>
        </Footer>
      )}
    </Container>
  )
}

export default memo(AlgorithmView)

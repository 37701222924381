import React from "react"
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import withLoggedUser from "../hoc/withLoggedUser"
import useComponentDidMount from "../hooks/useComponentDidMount"
import { Admin } from "../screens/Admin"
import { Calendars } from "../screens/Calendars"
import { Callback } from "../screens/Callback"
import ConnectAs from "../screens/ConnectAs"
import Logout from "../screens/Logout"
import { CreatePassword } from "../screens/ResetPassword/CreatePasswordPage"
import Dashboard from "../screens/Dashboard"
import RouteClients from "../screens/RouteClients/RouteClients"
import RouteSettings from "../screens/RouteSettings/RouteSettings"
import RouteStaff from "../screens/RouteStaff"
import { Sessions } from "../screens/Sessions"
import { Login } from "../screens/LoginSignup"
import { ResetPassword } from "../screens/ResetPassword/ResetPasswordPage"
import { ResetPasswordEmail } from "../screens/ResetPassword/EmailForm"
import { Topbar } from "../elements/Topbar"
import { auth } from "../elements/Auth/Auth"

import ButtonStopConnectAs from "./ButtonStopConnectAs"

import { fetchDBData } from "store/actions/user"
import { freelancerAccess } from "config/helpers"
import loading from "static/loading.svg"
import { logout } from "store/actions/auth"

const ProctectedRoute = ({ component: Component, userRole, isAuthed, ...rest }) => {
  const RoleRoute = () => (
    <Route
      {...rest}
      render={props =>
        isAuthed && rest.loaded ? (
          <Component {...props} {...rest} />
        ) : !isAuthed ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        ) : (
          <div className="loading-container">
            <img alt="loading" className="loading-circle" src={loading} />
          </div>
        )
      }
    />
  )

  if (userRole === "freelancer" && freelancerAccess.includes(rest.path)) {
    return RoleRoute()
  } else if (userRole !== "freelancer") {
    return RoleRoute()
  }
  return <Redirect to={"/calendars"} />
}

ProctectedRoute.propTypes = {
  component: PropTypes.any,
  isAuthed: PropTypes.bool,
  location: PropTypes.object,
  userRole: PropTypes.string,
}

const TopbarRoute = ({ component: Component, isAuthed, ...rest }) => (
  <Route {...rest} render={props => isAuthed && rest.loaded && <Component {...props} {...rest} />} />
)

TopbarRoute.propTypes = {
  component: PropTypes.any,
  isAuthed: PropTypes.bool,
  location: PropTypes.object,
}

const isLogoutRoute = window.location.pathname === "/logout"
const _Routes = ({ onFetchDBData, isAuthed, isDataLoaded, user, dbUser }) => {
  useComponentDidMount(() => {
    if (isAuthed && !isDataLoaded && !isLogoutRoute) {
      onFetchDBData()
    }
  })
  const dbUserRole = dbUser?.roles[0]
  const staffRole = user?.modaresaRole
  const path =
    staffRole === "freelancer"
      ? "/(/|clients|calendars|settings)?/"
      : "/(/|clients|calendars|staff|settings|sessions|admin|dashboard)?/"
  if (!dbUserRole && !staffRole && isAuthed) {
    return (
      <div className="loading-container">
        <img alt="loading" className="loading-circle" src={loading} />
      </div>
    )
  }
  return (
    <Router>
      <div>
        <TopbarRoute auth={auth} component={Topbar} isAuthed={isAuthed} loaded={isDataLoaded} path={path} />
      </div>
      {dbUserRole === "superAdmin" ? (
        <Switch>
          <Route component={Admin} path="/admin" />
          <Route component={ConnectAs} path="/connect-as/:token?" />
          <Route exact component={Logout} path="/logout" />
          <Redirect to="/admin" />
        </Switch>
      ) : (
        <Switch>
          <Route component={ConnectAs} path="/connect-as/:token?" />
          <Route exact component={Logout} path="/logout" />
          <ProctectedRoute
            component={Dashboard}
            exact
            isAuthed={isAuthed}
            loaded={isDataLoaded}
            path="/"
            userRole={staffRole}
          />
          <ProctectedRoute
            component={Dashboard}
            exact
            isAuthed={isAuthed}
            loaded={isDataLoaded}
            path="/dashboard/:section"
            userRole={staffRole}
          />
          <ProctectedRoute
            component={RouteClients}
            isAuthed={isAuthed}
            loaded={isDataLoaded}
            path="/clients"
            userRole={staffRole}
          />
          <ProctectedRoute
            component={Calendars}
            isAuthed={isAuthed}
            loaded={isDataLoaded}
            path="/calendars"
            userRole={staffRole}
          />
          <ProctectedRoute
            component={RouteStaff}
            isAuthed={isAuthed}
            loaded={isDataLoaded}
            path="/staff"
            userRole={staffRole}
          />
          <ProctectedRoute
            component={RouteSettings}
            isAuthed={isAuthed}
            loaded={isDataLoaded}
            path="/settings"
            userRole={staffRole}
          />
          <ProctectedRoute
            component={Sessions}
            isAuthed={isAuthed}
            loaded={isDataLoaded}
            path="/sessions"
            userRole={staffRole}
          />
          <Route exact path="/login-success" render={() => <div>SUCCESS</div>} />
          <Route exact path="/login" render={props => <Login isAuthed={isAuthed} {...props} />} />
          <Route
            exact
            path="/user/resetpassword"
            render={props => (isAuthed ? <Redirect to="/calendars" /> : <ResetPassword {...props} />)}
          />
          <Route
            exact
            path="/user/createpassword"
            render={props => (isAuthed ? <Redirect to="/calendars" /> : <CreatePassword {...props} />)}
          />
          <Route
            exact
            path="/user/email"
            render={props => (isAuthed ? <Redirect to="/calendars" /> : <ResetPasswordEmail {...props} />)}
          />
          <Route
            exact
            path="/callback"
            render={props => (isAuthed ? <Redirect to="/calendars" /> : <Callback {...props} />)}
          />
          <Redirect to={staffRole !== "freelancer" ? "/" : "/calendars"} />
        </Switch>
      )}
      <ButtonStopConnectAs />
    </Router>
  )
}

_Routes.propTypes = {
  isAuthed: PropTypes.bool,
  isDataLoaded: PropTypes.bool,
  user: PropTypes.object,
  onFetchDBData: PropTypes.func,
  onLogout: PropTypes.func,
}

const mapStateToProps = ({ auth, user }) => ({
  isDataLoaded: user.dataLoaded,
  isAuthed: auth.isAuthed,
  user: user.user,
  dbUser: user.dbUser,
})

const actions = {
  onFetchDBData: fetchDBData,
  onLogout: logout,
}

export const Routes = withLoggedUser(connect(mapStateToProps, actions)(_Routes))

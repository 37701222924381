import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { getBrand, getBrandRequestError, getBrandRequestStatus } from "../../../store/selectors"
import { updateBrand } from "../../../store/actions/brand"

import Component from "./CompanyProfile"

const selector = createStructuredSelector({
  brand: getBrand,
  requestPending: getBrandRequestStatus,
  requestError: getBrandRequestError,
})

const actions = {
  onUpdateBrand: updateBrand,
}

const CompanyProfile = connect(selector, actions)(Component)
export default CompanyProfile

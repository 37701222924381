import React, { memo, useState } from "react"
import { Formik, Form } from "formik"
import * as yup from "yup"
import { Divider, Button } from "modaresa-commons"

import { markets } from "../../../../config"
import { yupSelectOption } from "../../../../helpers/yup"
import FormFieldLayout from "../../FormFieldLayout/FormFieldLayout"
import FormikSelect from "../../FormikFields/FormikSelect"

import FieldAffinityBuyers from "./FieldAffinityBuyers"
import { ContainerFields } from "./FormBuyerResponsibility.styles"

const buyerResponsibilitySchema = yup.object().shape({
  market: yupSelectOption.required(),
  affinityBuyers: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      fullName: yup.string(),
    }),
  ),
})

const labelWidth = "192px"

type Props = {
  staff: {
    brand: {
      id: string,
    },
    affinityBuyers: [
      {
        id: string,
        fullName: string,
      },
    ],
  },
  onSubmit: {},
  loadingSubmit: boolean,
  readOnly: boolean,
  restrictBuyersToStaffAreas: boolean,
}
const FormBuyerResponsibility = ({ staff, onSubmit, loadingSubmit, readOnly, restrictBuyersToStaffAreas }: Props) => {
  const [initialValues] = useState(() => ({
    market: staff.market && markets.find(market => market.value === staff.market),
    affinityBuyers: staff.affinityBuyers || [],
  }))
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validationSchema={buyerResponsibilitySchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <ContainerFields>
            <FormikSelect
              label="Market"
              disabled={readOnly}
              selectWidth="100%"
              labelWidth={labelWidth}
              name="market"
              options={markets}
              helpText={
                <span>
                  By default we set all staff members up for Women + Men. However, if you split
                  <br />
                  your team by market responsibility please specify accordingly.
                </span>
              }
            />
          </ContainerFields>
          <Divider>Exceptions - Affinity</Divider>
          <ContainerFields>
            <FieldAffinityBuyers
              staffId={staff.id}
              brandId={staff.brand.id}
              disabled={readOnly}
              labelWidth={labelWidth}
              restrictBuyersToStaffAreas={restrictBuyersToStaffAreas}
            />
            <FormFieldLayout label=" " labelWidth={labelWidth}>
              <Button minWidth="100%" type="submit" disabled={readOnly || loadingSubmit}>
                Save changes
              </Button>
            </FormFieldLayout>
          </ContainerFields>
        </Form>
      )}
    </Formik>
  )
}

export default memo(FormBuyerResponsibility)

import React, { useState, useCallback, memo } from "react"
import { useParams, useHistory } from "react-router-dom"
import { AlertDanger, Tabs } from "modaresa-commons"

import { isStaffAtLeastManager, isStaffEditor } from "../../../helpers/users"
import PageTitle from "../../elements/PageTitle/PageTitle"
import withLoggedUser from "../../hoc/withLoggedUser"

import { CompanyGeneral } from "./blocks/CompanyGeneral"
import CompanyRooms from "./blocks/CompanyRooms/CompanyRooms"
import CompanyAreas from "./blocks/CompanyAreas"
import { Container } from "./CompanyProfile.styles"

type Props = {
  onUpdateBrand: Function,
  brand: {},
  requestError: boolean,
  requestPending: boolean,
  loggedUser: {
    staff: {
      brand: {
        id: string,
      },
    },
  },
  restrictToStaffIdIfAgent: string,
}
const CompanyProfile = ({
  onUpdateBrand,
  brand,
  requestError,
  requestPending,
  loggedUser,
  restrictToStaffIdIfAgent,
}: Props) => {
  const { section } = useParams()
  const history = useHistory()
  const [activeSection, setActiveSection] = useState(section || "general")
  const handleTabClick = useCallback(
    newTab => {
      history.push(`/settings/company/${newTab}`)
      setActiveSection(newTab)
    },
    [history],
  )
  const handleSaveChanges = useCallback(data => onUpdateBrand(data, brand._id), [brand._id, onUpdateBrand])
  if (!loggedUser?.staff?.brand) {
    return <AlertDanger>This user doesn't belong to a brand</AlertDanger>
  }
  const userIsAgent = loggedUser.staff.modaresaRole === "agent"
  const isEditor = isStaffEditor(loggedUser.staff)
  const isAtLeastManager = isStaffAtLeastManager(loggedUser.staff.modaresaRole)
  return (
    <PageTitle title="Company profile">
      <Container>
        <Tabs activeKey={activeSection} onChange={handleTabClick}>
          <Tabs.TabPane tabKey="general" tab="General">
            <CompanyGeneral
              brand={brand}
              requestError={requestError}
              requestPending={requestPending}
              userIsAgent={!isAtLeastManager}
              onSaveChanges={handleSaveChanges}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tabKey="rooms" tab="Rooms">
            <CompanyRooms
              canEdit={isEditor || userIsAgent}
              canView={isAtLeastManager || userIsAgent}
              brandId={loggedUser.staff.brand.id}
              restrictToStaffIdIfAgent={restrictToStaffIdIfAgent}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tabKey="areas" tab="Areas">
            <CompanyAreas canEdit={isEditor} brandId={loggedUser.staff.brand.id} />
          </Tabs.TabPane>
        </Tabs>
      </Container>
    </PageTitle>
  )
}

export default withLoggedUser(memo(CompanyProfile))

import { useField } from "formik"
import React, { memo, useCallback } from "react"

import BrandRoomAreaAndTerritoryPicker from "../BrandRoomAreaAndTerritoryPicker/BrandRoomAreaAndTerritoryPicker"
import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

type Props = {
  labelWidth: string,
  disabled: boolean,
}

const FieldAreasAndTerritories = ({ brandId, labelWidth, disabled }: Props) => {
  const [field, meta, helpers] = useField("areasAndTerritories")
  const handleChange = useCallback(
    values => {
      helpers.setValue(values || [])
    },
    [helpers],
  )

  return (
    <FormFieldLayout
      label="Areas & Territories Host Responsibility"
      labelWidth={labelWidth}
      name="areasAndTerritories"
      direction="row"
      error={meta.touched && meta.error}
    >
      <BrandRoomAreaAndTerritoryPicker
        brandId={brandId}
        multiple
        disabled={disabled}
        placeholder="Select areas & territories"
        defaultValue={field.value}
        onChange={handleChange}
      />
    </FormFieldLayout>
  )
}

export default memo(FieldAreasAndTerritories)

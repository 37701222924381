import { useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { Loading, Button } from "modaresa-commons"

import { ME_LOGGED_STAFF_AVAILABLE_SESSIONS } from "../../../../../graphql/queries/user"
import PageTitle from "../../../../elements/PageTitle/PageTitle"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"
import { SessionList } from "../SessionList"
import noSessionImage from "../../../../../static/Modaresa_VTC-01.png"

import { Container, NotFound } from "./styles.js"

const ActiveSessions = ({ match, brand, onDeleteSession, user, ...props }) => {
  const { data, loading, error } = useQuery(ME_LOGGED_STAFF_AVAILABLE_SESSIONS, {
    variables: {
      sessionStatus: "active",
      sessionIsPast: false,
    },
  })
  const sessions = useMemo(() => data?.me.staff.availableSessions || [], [data])
  const {
    requestError,
    requestPending,
    onAddFile,
    addAttachementRequestStatus,
    addAttachementRequestError,
    onDeleteAttachment,
    onExportReports,
    deleteAttachementRequestStatus,
    deleteAttachementRequestError,
  } = props
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading active sessions</ErrorLiveChatHelp>
  if (loading && !data) return <Loading />
  if (sessions.length === 0 && !loading) {
    return (
      <PageTitle title="Active sessions">
        <Container>
          <NotFound image>
            <img alt="modaresa" src={noSessionImage} />
            <Link to="/sessions/new">
              <Button>Create my first Sales Session</Button>
            </Link>
          </NotFound>
        </Container>
      </PageTitle>
    )
  }
  const userIsAgent = user.modaresaRole === "agent"
  return (
    <PageTitle title="Active sessions">
      <SessionList
        addAttachementRequestError={addAttachementRequestError}
        addAttachementRequestStatus={addAttachementRequestStatus}
        deleteAttachementRequestError={deleteAttachementRequestError}
        deleteAttachementRequestStatus={deleteAttachementRequestStatus}
        match={match}
        offices={brand.offices}
        requestError={requestError}
        requestPending={requestPending}
        sessions={sessions}
        type="active"
        userIsAgent={userIsAgent}
        onAddFile={onAddFile}
        onExportReports={onExportReports}
        onDeleteAttachment={onDeleteAttachment}
      />
    </PageTitle>
  )
}

ActiveSessions.propTypes = {
  addAttachementRequestError: PropTypes.any,
  addAttachementRequestStatus: PropTypes.bool,
  brand: PropTypes.object,
  deleteAttachementRequestError: PropTypes.any,
  deleteAttachementRequestStatus: PropTypes.bool,
  match: PropTypes.object,
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  sessions: PropTypes.array,
  user: PropTypes.object,
  onAddFile: PropTypes.func,
  onDeleteAttachment: PropTypes.func,
  onExportReports: PropTypes.func,
}

export default ActiveSessions

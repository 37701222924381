import * as R from "ramda"
import cloneDeep from "lodash/cloneDeep"
import { createSelector } from "reselect"

import {
  filterByBuyerName,
  filterByCountry,
  filterByMeetingApproach,
  filterByPriorityGroup,
  filterByRetailer,
} from "../../helpers/filter"

import { getClients } from "./clients"
import { getRetailers } from "./retailers"
import { getStaff } from "./staff"
import { getUserData } from "./user"

export const getSchedules = R.path(["schedules", "schedules"])
export const getSelectedSchedule = R.path(["schedules", "selectedSchedule"])
export const getSelectedBooking = R.path(["schedules", "selectedBooking"])

export const getBookingAvailableClients = R.path(["schedules", "bookingAvailableClients"])
export const getBookingAvailableHostsIds = R.path(["schedules", "bookingAvailableHosts"])
export const getBookingOtherHostsIds = R.path(["schedules", "bookingOtherHosts"])
export const getBookingAvailableStaffIds = R.path(["schedules", "bookingAvailableStaff"])
export const getAvailableClientsFetchingStatus = R.path(["schedules", "isFetchingAvailableClients"])
export const getAvailableStaffFetchingStatus = R.path(["schedules", "isFetchingAvailableMeetingStaff"])
export const getBookingFilterType = R.path(["schedules", "bookingFilterType"])
export const getBookingFilterValue = R.path(["schedules", "bookingFilterValue"])
export const getAvailableHostsFetchingStatus = R.path(["schedules", "isFetchingAvailableHosts"])
export const getAppointmentAvailableHostsIds = R.path(["schedules", "appointmentAvailableHosts"])

export const getRequestStatus = R.path(["schedules", "requestPending"])
export const getRequestError = R.path(["schedules", "requestError"])

export const getAppointmentRequestStatus = R.path(["schedules", "appointmentRequestPending"])
export const getAppointmentRequestError = R.path(["schedules", "appointmentRequestError"])

export const getScheduleRequestStatus = R.path(["schedules", "requestSchedulePending"])
export const getScheduleRequestError = R.path(["schedules", "requestScheduleError"])

export const getTimeCheckStatus = R.path(["schedules", "requestCheckTime"])
export const getTimeCheckError = R.path(["schedules", "requestCheckTimeError"])

export const getAppointmentFilter = R.path(["schedules", "appointmentFilter"])

export const getJokerRequests = R.path(["schedules", "jokerRequests"])
export const getRequestFetchJokerRequestsPending = R.path(["schedules", "requestFetchJokerRequestsPending"])
export const getRequestFetchJokerRequestsError = R.path(["schedules", "requestFetchJokerRequestsError"])

export const getRequestJokerRequestsPending = R.path(["schedules", "requestJokerRequestsPending"])
export const getRequestJokerRequestsError = R.path(["schedules", "requestJokerRequestsError"])

export const getJokerAvailableHostsIds = R.path(["schedules", "jokerAvailableHosts"])
export const getJokerOtherHostsIds = R.path(["schedules", "jokerOtherHosts"])
export const getJokergAvailableStaffIds = R.path(["schedules", "jokerAvailableStaff"])

export const getClientCancellations = R.path(["schedules", "cancellations"])
export const getRequestFetchCancellationsPending = R.path(["schedules", "requestFetchCancellationsPending"])
export const getRequestFetchCancellationsError = R.path(["schedules", "requestFetchCancellationsError"])

export const getBookingAvailableHosts = createSelector([getStaff, getBookingAvailableHostsIds], (staff, ids) =>
  ids.map(id => staff.byId[id]),
)

export const getBookingOtherHosts = createSelector([getStaff, getBookingOtherHostsIds], (staff, ids) =>
  ids.map(id => staff.byId[id]),
)

export const getAppointmentAvailableHosts = createSelector(
  [getStaff, getAppointmentAvailableHostsIds],
  (staff, hosts) => hosts.map(({ id, status }) => ({ host: staff.byId[id], status })),
)

export const getBookingAvailableStaff = createSelector([getStaff, getBookingAvailableStaffIds], (staff, ids) =>
  ids.map(id => staff.byId[id]),
)

export const getJokerAvailableHosts = createSelector([getStaff, getJokerAvailableHostsIds], (staff, ids) =>
  ids.map(id => staff.byId[id]),
)

export const getJokerOtherHosts = createSelector([getStaff, getJokerOtherHostsIds], (staff, ids) =>
  ids.map(id => staff.byId[id]),
)

export const getJokerAvailableStaff = createSelector([getStaff, getJokergAvailableStaffIds], (staff, ids) =>
  ids.map(id => staff.byId[id]),
)

export const getStaffCalendarSchedule = createSelector([getSelectedSchedule], selectedSchedule => {
  const staffCalendarSchedule = {}
  const createStaffSchedule = (day, dayindex) => {
    day.daySchedule.forEach(daySchedule => {
      daySchedule.tables.forEach(item => {
        item.forEach(booking => {
          if (staffCalendarSchedule[booking.host]) {
            if (!staffCalendarSchedule[booking.host].rooms[booking.room]) {
              staffCalendarSchedule[booking.host].rooms[booking.room] = []
              staffCalendarSchedule[booking.host].rooms[booking.room][dayindex] = []
            }
            if (staffCalendarSchedule[booking.host].rooms[booking.room][dayindex]) {
              staffCalendarSchedule[booking.host].bookings[booking._id] = booking
              staffCalendarSchedule[booking.host].rooms[booking.room][dayindex] = [
                ...staffCalendarSchedule[booking.host].rooms[booking.room][dayindex],
                booking._id,
              ]
            } else {
              staffCalendarSchedule[booking.host].rooms[booking.room][dayindex] = []
              staffCalendarSchedule[booking.host].rooms[booking.room][dayindex] = [booking._id]
              staffCalendarSchedule[booking.host].bookings[booking._id] = booking
            }
          } else {
            staffCalendarSchedule[booking.host] = {}
            staffCalendarSchedule[booking.host].bookings = {}
            staffCalendarSchedule[booking.host].rooms = {}
            staffCalendarSchedule[booking.host].rooms[booking.room] = []
            staffCalendarSchedule[booking.host].rooms[booking.room][dayindex] = [booking._id]
            staffCalendarSchedule[booking.host].bookings[booking._id] = booking
          }
        })
      })
    })
  }
  if (selectedSchedule) {
    selectedSchedule.schedule.forEach((day, dayindex) => createStaffSchedule(day, dayindex))
    selectedSchedule.jokerSchedule.forEach((day, dayindex) => createStaffSchedule(day, dayindex))
    return staffCalendarSchedule
  }
  return {}
})

export const getFilteredSchedule = createSelector(
  [getSelectedSchedule, getBookingFilterType, getBookingFilterValue, getClients, getRetailers, getUserData],
  (selectedSchedule, type, value, clients, retailers, user) => {
    const isAffinity = user.modaresaRole === "agent" && user.affinityClients.length > 0
    if (
      selectedSchedule &&
      (type === "name" ||
        type === "origin" ||
        type === "buyer" ||
        type === "priorityGroup" ||
        type === "meetingApproach" ||
        isAffinity)
    ) {
      const filterSchedule = cloneDeep(selectedSchedule)
      selectedSchedule.schedule.forEach((day, dayIndex) => {
        day.daySchedule.forEach((room, roomIndex) => {
          room.tables.forEach((table, index) => {
            filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index] = []
            table.forEach(booking => {
              if (isAffinity) {
                const clients = booking.clients
                const affinityClients = user.affinityClients
                const length = clients.length
                for (let i = 0; i < length; i++) {
                  booking.isDisabled = true
                  if (affinityClients.includes(clients[i])) {
                    booking.isDisabled = false
                    break
                  }
                }
                if (!type) {
                  filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                    ...filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index],
                    booking,
                  ]
                }
              }
              if (type === "name" && filterByRetailer(value, booking, clients, retailers)) {
                filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                  ...filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index],
                  booking,
                ]
              } else if (type === "origin" && filterByCountry(value, booking, clients, retailers)) {
                filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                  ...filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index],
                  booking,
                ]
              } else if (type === "priorityGroup" && filterByPriorityGroup(value, booking, clients, retailers)) {
                filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                  ...filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index],
                  booking,
                ]
              } else if (type === "meetingApproach" && filterByMeetingApproach(value, booking, clients, retailers)) {
                filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                  ...filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index],
                  booking,
                ]
              } else if (type === "buyer") {
                booking.clients.forEach(client => {
                  if (filterByBuyerName(value, clients, client)) {
                    filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                      ...filterSchedule.schedule[dayIndex].daySchedule[roomIndex].tables[index],
                      booking,
                    ]
                  }
                })
              }
            })
          })
        })
      })
      selectedSchedule.jokerSchedule.forEach((day, dayIndex) => {
        day.daySchedule.forEach((room, roomIndex) => {
          room.tables.forEach((table, index) => {
            filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index] = []
            table.forEach(booking => {
              if (isAffinity) {
                const clients = booking.clients
                const affinityClients = user.affinityClients
                const length = clients.length
                for (let i = 0; i < length; i++) {
                  booking.isDisabled = true
                  if (affinityClients.includes(clients[i])) {
                    booking.isDisabled = false
                    break
                  }
                }
                if (!type) {
                  filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                    ...filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index],
                    booking,
                  ]
                }
              }
              if (type === "name" && filterByRetailer(value, booking, clients, retailers)) {
                filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                  ...filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index],
                  booking,
                ]
              } else if (type === "origin" && filterByCountry(value, booking, clients, retailers)) {
                filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                  ...filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index],
                  booking,
                ]
              } else if (type === "priorityGroup" && filterByPriorityGroup(value, booking, clients, retailers)) {
                filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                  ...filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index],
                  booking,
                ]
              } else if (type === "buyer") {
                booking.clients.forEach(client => {
                  if (filterByBuyerName(value, clients, client)) {
                    filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index] = [
                      ...filterSchedule.jokerSchedule[dayIndex].daySchedule[roomIndex].tables[index],
                      booking,
                    ]
                  }
                })
              }
            })
          })
        })
      })
      return filterSchedule
    }
    return selectedSchedule
  },
)

export const getAttendanceSchedule = createSelector([getFilteredSchedule], selectedSchedule => {
  const attendanceSchedule = []
  if (selectedSchedule) {
    const { schedule, jokerSchedule } = selectedSchedule
    for (let i = 0; i < schedule.length; i++) {
      let allIds = []
      let byId = {}

      schedule[i].daySchedule.forEach(daySchedule => {
        daySchedule.tables.forEach(item => {
          item.forEach(booking => {
            if (attendanceSchedule[i]) {
              byId[booking._id] = booking
              allIds = [...allIds, booking._id]
            } else {
              attendanceSchedule[i] = {}
              allIds = [booking._id]
              byId = {}
              byId[booking._id] = booking
            }
          })
        })
      })
      jokerSchedule[i].daySchedule.forEach(daySchedule => {
        daySchedule.tables.forEach(item => {
          item.forEach(booking => {
            if (attendanceSchedule[i]) {
              byId[booking._id] = booking
              allIds = [...allIds, booking._id]
            } else {
              attendanceSchedule[i] = {}
              allIds = [booking._id]
              byId = {}
              byId[booking._id] = booking
            }
          })
        })
      })

      const sortedIds = allIds.sort((idA, idB) => {
        const timeA = byId[idA].startTime
        const timeB = byId[idB].startTime
        if (timeA > timeB) return 1
        if (timeA < timeB) return -1
        return 0
      })

      attendanceSchedule[i] = {}
      attendanceSchedule[i].allIds = sortedIds
      attendanceSchedule[i].byId = byId
    }
    return attendanceSchedule
  }
  return []
})

export const getFiltertAttendanceSchedule = createSelector(
  [getAttendanceSchedule, getAppointmentFilter],
  (attendanceSchedule, filter) => {
    let filteredAttendanceSchedule = null
    for (const key in filter) {
      if (filter[key] === "all") {
        continue
      }
      let filterKey = null

      if (key === "staffMember") {
        filterKey = "host"
      } else if (key === "selectedRoom") {
        filterKey = "room"
      } else if (key === "appointmentStatus") {
        filterKey = "appointmentStatus"
      } else {
        continue
      }
      if (!filteredAttendanceSchedule) {
        filteredAttendanceSchedule = attendanceSchedule.map(dayAttendance => ({
          allIds: dayAttendance.allIds.filter(attndId => {
            if (dayAttendance.byId[attndId][filterKey] === filter[key]) {
              return true
            }
            return false
          }),
          byId: dayAttendance.byId,
        }))
      } else {
        filteredAttendanceSchedule = filteredAttendanceSchedule.map(dayAttendance => ({
          allIds: dayAttendance.allIds.filter(attndId => {
            if (dayAttendance.byId[attndId][filterKey] === filter[key]) {
              return true
            }
            return false
          }),
          byId: { ...dayAttendance.byId },
        }))
      }
    }
    return filteredAttendanceSchedule || attendanceSchedule
  },
)

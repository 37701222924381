import { countries } from "./../config/countries"

// TODO: You don't understand why this function does or how it does it? Welcome to the club.
// If I were you I'd refactor this function and the code that uses it.
export const getOneOf = (list, query) => {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (item.key === "country") {
      if (countries[item.value] && countries[item.value].name.toLowerCase().indexOf(query) === 0) {
        return {
          value: countries[item.value].name,
          column: { label: item.label, key: item.key, iso: item.value },
        }
      }
    } else if (item.key === "priorityGroup") {
      return {
        value: item.value,
        column: { label: item.label, key: item.key },
      }
    } else if (
      (typeof item.value === "string" && item.value.toLowerCase().indexOf(query) === 0) ||
      (typeof item.label === "string" && item.label.toLowerCase().indexOf(query) === 0)
    ) {
      return {
        value: item.value,
        column: { label: item.label, key: item.key },
      }
    }
  }
}

export const setOptionList = list => {
  const fullUnique = {}
  const qtyValue = {}
  const filteredOptionsList = list.filter(item => {
    const value = item.value.toString().toLowerCase()
    const key = item.key.toString().toLowerCase()
    if (fullUnique[value + key]) {
      qtyValue[value + key] = qtyValue[value + key] + 1
    } else {
      fullUnique[value + key] = true
      qtyValue[value + key] = 1
      return true
    }
    return false
  })
  const optionsList = filteredOptionsList.map(item => {
    const value = item.value.toString().toLowerCase()
    const key = item.key.toString().toLowerCase()
    item.label = `${item.label} (${qtyValue[value + key]})`
    return item
  })
  return optionsList
}

export const uniquifyAndCountElements = setOptionList

export const uniqueList = list => {
  const valueUnique = {}
  const optionsList = list.filter(item => {
    if (valueUnique[item.label]) {
      return false
    }
    valueUnique[item.label] = true
    return true
  })

  return optionsList
}

export const filterByRetailer = (value, booking, clients, retailers) => {
  if (clients.byId[booking.clients[0]]) {
    const retailerId = clients.byId[booking.clients[0]].retailerId
    if (value.toLowerCase() === retailers.byId[retailerId]?.name.toLowerCase()) {
      return true
    }
  }
}

export const filterByPriorityGroup = (value, booking, clients, retailers) => {
  if (clients.byId[booking.clients[0]]) {
    const retailerId = clients.byId[booking.clients[0]].retailerId
    return value === retailers.byId[retailerId]?.priorityGroup
  }
}

export const filterByMeetingApproach = (value, booking, clients, retailers) => value === booking.meetingApproach

export const filterByCountry = (value, booking, clients, retailers) => {
  if (clients.byId[booking.clients[0]]) {
    const retailerId = clients.byId[booking.clients[0]].retailerId
    if (value.toLowerCase() === retailers.byId[retailerId]?.storeAddress.country) {
      return true
    }
  }
}

export const filterByBuyerName = (value, clients, id) => {
  const firstName = clients.byId[id].firstName
  if (firstName.toLowerCase().indexOf(value) === 0) {
    return true
  }
}

export const doesStringMatch = (str, valueToSearch) => str?.indexOf(valueToSearch) > -1

export const removeKeysAlreadySearched = (keys, optionsSearch) => {
  const filteredKeys = [...keys]
  optionsSearch.forEach(opt => {
    const keyIndex = filteredKeys.indexOf(opt.key)
    if (keyIndex > -1) {
      filteredKeys.splice(keyIndex, 1)
    }
  })
  return filteredKeys
}

import { useMutation } from "@apollo/client"
import React, { useCallback, useState } from "react"
import { Button } from "modaresa-commons"

import { UPLOAD_BUYERS_LIST } from "../../../../../graphql/mutations/script"
import { getGraphQLErrorAsString } from "../../../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../../../helpers/toast"
import BrandPicker from "../../../../elements/BrandPicker/BrandPicker"

import { ContainerAddClients, ContainerButtons, ContainerBrandPicker, FileUploading } from "./AddClients.style"
import AddClientsModifications from "./AddClientsModifications"

const AddClients = () => {
  const [brand, setBrand] = useState(null)
  const [modifications, setModifications] = useState(null)
  const [file, setFile] = useState(null)
  const [percentUpload, setPercentUpload] = useState(null)
  const [loading, setLoading] = useState(null)
  const [showApplyChanges, setShowApplyChanges] = useState(null)
  const [uploadBuyersList] = useMutation(UPLOAD_BUYERS_LIST)
  const handleSelectBrand = useCallback(brand => {
    setShowApplyChanges(false)
    setBrand(brand)
  }, [])
  const handleUploadFile = useCallback(({ target: { validity, files } }) => {
    setShowApplyChanges(false)
    if (validity.valid) {
      setFile(files[0])
    } else {
      onToastError("Invalid file selected")
    }
  }, [])

  const handleAddClients = useCallback(
    async onlyPreviewChanges => {
      setPercentUpload(0)
      setModifications(null)
      setLoading(true)
      if (!file || !brand) {
        onToastError("Please select a brand and upload a file")
        setLoading(false)
        return
      }
      try {
        const {
          data: { uploadBuyersList: payload },
        } = await uploadBuyersList({
          variables: { brandId: brand.id, file, onlyPreviewChanges },
          context: {
            fetchOptions: {
              useUpload: true,
              onProgress: ({ loaded, total }) => setPercentUpload((loaded / total) * 100),
              onAbortPossible: () => undefined,
            },
          },
        })
        setModifications(payload)
        if (onlyPreviewChanges) {
          setShowApplyChanges(true)
        } else {
          onToastSuccess("Import has been applied successfully")
        }
      } catch (e) {
        console.error(e)
        onToastError("An error occured while uploading buyers list", getGraphQLErrorAsString(e))
      }
      setLoading(false)
    },
    [file, brand, uploadBuyersList],
  )
  const handleClickPreview = useCallback(() => handleAddClients(true), [handleAddClients])
  const handleClickApply = useCallback(() => handleAddClients(false), [handleAddClients])

  return (
    <ContainerAddClients>
      <ContainerBrandPicker>
        <h2>Select Brand to upload new buyers</h2>
        <BrandPicker onChange={handleSelectBrand} />
      </ContainerBrandPicker>
      <input type="file" onChange={handleUploadFile} />
      {!modifications && percentUpload > 0 && (
        <FileUploading>
          Uploading {percentUpload}%. {percentUpload === 100 ? "Generating changes..." : ""}
        </FileUploading>
      )}
      <ContainerButtons>
        <Button onClick={handleClickPreview} disabled={loading}>
          Preview Add Clients
        </Button>
        {showApplyChanges && (
          <Button margin="0 0 0 16px" onClick={handleClickApply} disabled={loading}>
            Apply Changes
          </Button>
        )}
      </ContainerButtons>
      {modifications && <AddClientsModifications modifications={modifications} />}
    </ContainerAddClients>
  )
}

export default AddClients

import styled from "styled-components"

export const ContainerRequest = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    background-color: ${p => p.theme.gray75};
  }
  ${p =>
    p.isSelected
      ? `
    border: 2px solid ${p.theme.main};
    border-radius: 4px
  `
      : `
    border-bottom: 1px solid ${p.theme.gray50};
    border-top: 1px solid ${p.theme.gray50};
  `}
`
export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
`

export const ContainerBuyerInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const ContainerTime = styled.div`
  width: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const ButtonStatus = styled.span`
  margin: 8px 0;
  display: block;
  width: 100%;
  max-width: 80px;
  text-align: center;
  line-height: 37px;
  height: 38px;
  border-radius: 3px;
  ${p =>
    p.onClick
      ? `
    background: ${p.bgcolor || "#e3e3e3"};
    color: #fff;
    cursor: pointer;
  `
      : `
    background: transparent;
    color: ${p.bgcolor || "#e3e3e3"};
    cursor: default;  
  `}
`

export const RetailerName = styled.div`
  font-size: 14px;
  color: ${p => p.theme.gray200};
`
export const Fullname = styled.div`
  color: ${p => p.theme.black};
  font-size: 15px;
`

export const DateSent = styled.div`
  color: ${p => p.theme.gray200};
  font-size: 10px;
  border: 1px solid ${p => p.theme.gray};
  padding: 2px 4px;
  border-radius: 2px;
  margin: 0 auto;
`
export const Room = styled.div`
  margin-top: 12px;
`
export const Hours = styled.div`
  font-size: 14px;
`
export const Date = styled.div`
  margin: 0 0 4px 0;
  font-size: 15px;
  font-weight: bold;
  color: ${p => p.theme.black};
`

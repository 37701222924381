import {
  ADD_RETAILER,
  ADD_RETAILER_BY_SRETAILER_ID,
  DELETE_RETAILER,
  FETCH_CITIES,
  FETCH_RETAILERS,
  FETCH_SHARED_RETAILERS,
  SELECT_RETAILER_BY_ID,
  UNSELECT_RETAILER,
  UPDATE_RETAILER,
} from "../actions/retailers"
import { createReducer } from "../..//helpers/redux"

const initialState = {
  retailers: {},
  retailersRequestPending: false,
  sharedRetailers: [],
  selected: null,
  requestPending: false,
  requestError: true,
  requestSharedRetailersPending: false,
  requestSharedRetailersError: null,
  cities: [],
  requestCitiesPending: false,
}

const handlers = {
  [FETCH_RETAILERS.REQUEST]: state => ({
    ...state,
    retailersRequestPending: true,
  }),
  [FETCH_RETAILERS.SUCCESS]: (state, { payload }) => ({
    ...state,
    retailers: payload,
    retailersRequestPending: false,
  }),
  [FETCH_RETAILERS.FAILURE]: state => ({
    ...state,
    retailersRequestPending: false,
  }),
  [FETCH_SHARED_RETAILERS.REQUEST]: state => ({
    ...state,
    requestSharedRetailersPending: true,
    requestSharedRetailersError: null,
  }),
  [FETCH_SHARED_RETAILERS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestSharedRetailersPending: false,
    requestSharedRetailersError: payload,
  }),
  [FETCH_SHARED_RETAILERS.SUCCESS]: (state, { payload }) => ({
    ...state,
    sharedRetailers: payload,
    requestSharedRetailersPending: false,
  }),
  [SELECT_RETAILER_BY_ID]: (state, { payload }) => ({
    ...state,
    selected: state.retailers.byId[payload.id],
  }),
  [UNSELECT_RETAILER]: state => ({
    ...state,
    selected: null,
  }),
  [ADD_RETAILER.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [ADD_RETAILER.SUCCESS]: (state, { payload }) => ({
    ...state,
    retailers: {
      ...state.retailers,
      byId: {
        ...state.retailers.byId,
        [payload._id]: {
          ...payload,
        },
      },
      allIds: [payload._id, ...state.retailers.allIds],
    },
    selected: payload,
    requestPending: false,
  }),
  [ADD_RETAILER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [ADD_RETAILER_BY_SRETAILER_ID.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [ADD_RETAILER_BY_SRETAILER_ID.SUCCESS]: (state, { payload }) => ({
    ...state,
    retailers: {
      ...state.retailers,
      byId: {
        ...state.retailers.byId,
        [payload._id]: {
          ...payload,
        },
      },
      allIds: [payload._id, ...state.retailers.allIds],
    },
    selected: payload,
    requestPending: false,
  }),
  [ADD_RETAILER_BY_SRETAILER_ID.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [UPDATE_RETAILER.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_RETAILER.SUCCESS]: (state, { payload }) => {
    const selected = state.selected && state.selected._id === payload._id ? payload : state.selected
    return {
      ...state,
      retailers: {
        ...state.retailers,
        byId: {
          ...state.retailers.byId,
          [payload._id]: {
            ...payload,
          },
        },
      },
      selected,
      requestPending: false,
    }
  },
  [UPDATE_RETAILER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FETCH_CITIES.REQUEST]: state => ({
    ...state,
    requestPending: true,
  }),
  [FETCH_CITIES.FAILURE]: state => ({
    ...state,
    requestPending: false,
    requestError: null,
  }),
  [FETCH_CITIES.SUCCESS]: (state, { payload }) => ({
    ...state,
    cities: payload,
    requestPending: false,
  }),
  [DELETE_RETAILER.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [DELETE_RETAILER.SUCCESS]: (state, { payload }) => {
    const { updatedRetailer } = payload
    const selected = state.selected && state.selected._id === updatedRetailer._id ? updatedRetailer : state.selected
    return {
      ...state,
      retailers: {
        ...state.retailers,
        byId: {
          ...state.retailers.byId,
          [updatedRetailer._id]: {
            ...updatedRetailer,
          },
        },
      },
      selected,
      requestPending: false,
    }
  },
  [DELETE_RETAILER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
}

export default createReducer(initialState, handlers)

import { useField } from "formik"
import React, { memo, useCallback, useMemo, useState } from "react"
import Select from "react-select"
import usePlacesAutocomplete from "use-places-autocomplete"

import { loadGoogleMapsApi } from "../../../helpers/googleMapsApi"
import useComponentDidMount from "../../hooks/useComponentDidMount"
import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

type Props = {
  label: string,
  labelWidth: string,
  name: string,
  direction?: "row" | "column",
  onChange: Function,
  disabled: boolean,
  helpText: string,
  placeholder: string,
}

const removeSelectArrow = {
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
}
const FormikLocationAutocomplete = ({
  onChange,
  label,
  labelWidth,
  name,
  direction,
  disabled,
  helpText,
  placeholder,
}: Props) => {
  const [field, meta, helpers] = useField(name)
  const [isGmapLoaded, setIsGmapLoaded] = useState(false)
  const { suggestions, setValue: setPlacesAutoCompleteValue } = usePlacesAutocomplete({
    debounce: 500,
    callbackName: "initMap",
    requestOptions: {
      types: ["address"],
    },
  })

  useComponentDidMount(() => {
    loadGoogleMapsApi(() => setIsGmapLoaded(true))
  })

  const handleChangeSelectInput = useCallback(
    (inputValue, action) => {
      if (["input-blur", "menu-close", "set-value"].indexOf(action.action) === -1) {
        helpers.setValue(inputValue)
        setPlacesAutoCompleteValue(inputValue)
      }
    },
    [helpers, setPlacesAutoCompleteValue],
  )
  const handleSelectOption = useCallback(
    option => {
      helpers.setValue(option.value.description)
      if (onChange) {
        onChange(option.value)
      }
    },
    [helpers, onChange],
  )
  const optionsSuggestions = useMemo(() => {
    if (!suggestions.data) return []
    return suggestions.data.map(suggestion => ({
      label: suggestion.description,
      value: suggestion,
    }))
  }, [suggestions])
  const locationValue = useMemo(() => field.value && { label: field.value, value: field.value }, [field])
  return (
    isGmapLoaded && (
      <FormFieldLayout
        label={label}
        labelWidth={labelWidth}
        name={name}
        direction={direction}
        error={meta.touched && meta.error}
        helpText={helpText}
      >
        <Select
          value={locationValue}
          components={removeSelectArrow}
          isDisabled={disabled}
          options={optionsSuggestions}
          isLoading={suggestions.loading}
          placeholder={placeholder}
          onChange={handleSelectOption}
          onBlur={field.onBlur}
          onInputChange={handleChangeSelectInput}
        />
      </FormFieldLayout>
    )
  )
}

FormikLocationAutocomplete.defaultProps = {
  direction: "row",
}
export default memo(FormikLocationAutocomplete)

import React, { memo } from "react"
import { AutoSizer } from "react-virtualized"
import styled from "styled-components"
import { Input, Button } from "modaresa-commons"
import SearchIcon from "@material-ui/icons/Search"

import DataFilter from "../../../../elements/DataFilter/DataFilter"
import TableBuyers from "../../../../elements/TableBuyers"
import ButtonAddClients from "../ButtonAddClients"

import { Container, FilterContainer, TableContainer } from "./BuyersListView.styles"
import buyerSearch, { buyerSearchMatchKeys } from "./search"

import { Select } from "components/ui"

const StyledSearchBar = styled.div`
  display: flex;
  width: 75%;
`

const SearchButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledSearchIcon = styled(SearchIcon)`
  color: white;
  font-size: 22px;
  margin-right: 8px;
`

const marketOptions = [
  { label: "All Markets", value: "" },
  { label: "Men", value: "men" },
  { label: "Women", value: "women" },
]

const priorityOptions = [
  { label: "All Priorities", value: "" },
  { label: "Priority 1", value: "1" },
  { label: "Priority 2", value: "2" },
  { label: "Priority 3", value: "3" },
]

type Props = {
  buyers: [],
  filteredBuyers: [],
  onRowClick: Function,
  onChangeFilter: Function,
  onRenderActions: Function,
  onScroll?: Function,
  withFilterAutocomplete?: boolean,
  onClickSearch?: Function,
  onChangeSearch?: Function,
  searchString?: String,
}

const BuyersListView = ({
  filteredBuyers,
  buyers,
  onRowClick,
  onChangeFilter,
  onRenderActions,
  onScroll,
  withFilterAutocomplete = true,
  onClickSearch,
  onChangeSearch,
  searchString,
}: Props) => (
  <Container>
    <FilterContainer>
      {withFilterAutocomplete && (
        <DataFilter
          data={buyers}
          dataKeysToSearch={buyerSearchMatchKeys}
          searchObj={buyerSearch}
          onChange={onChangeFilter}
          placeholder="Search by retailer, fullname, email, manager, origin, market, priority group"
        />
      )}
      {!withFilterAutocomplete && (
        <StyledSearchBar>
          <Input
            type="text"
            value={searchString.text || ""}
            onChange={e => onChangeSearch({ ...searchString, text: e.target.value }, "TEXT")}
            onKeyUp={function(e) {
              if (e.keyCode === 13) {
                onClickSearch()
              }
            }}
            placeholder="Search by retailer, fullname, email, manager, origin"
          />
          <div style={{ width: "200px", margin: "0 8px" }}>
            <Select
              options={marketOptions}
              value={marketOptions.filter(p => p.value === searchString.market)}
              onChange={e => onChangeSearch({ ...searchString, market: e.value }, "MARKET")}
              placeholder="Market"
            />
          </div>
          <div style={{ width: "230px", margin: "0 8px" }}>
            <Select
              options={priorityOptions}
              value={priorityOptions.filter(p => p.value === searchString.priorityGroup)}
              onChange={e => onChangeSearch({ ...searchString, priorityGroup: e.value }, "PRIORITY_GROUP")}
              placeholder="Priority Group"
            />
          </div>
          <SearchButton onClick={e => onClickSearch(e)}>
            <StyledSearchIcon />
            Search
          </SearchButton>
        </StyledSearchBar>
      )}
      <ButtonAddClients />
    </FilterContainer>
    <TableContainer>
      <AutoSizer>
        {({ width, height }) => (
          <TableBuyers
            width={width}
            height={height}
            buyers={filteredBuyers || buyers}
            onRowClick={onRowClick}
            onRenderActions={onRenderActions}
            onScroll={onScroll}
          />
        )}
      </AutoSizer>
    </TableContainer>
  </Container>
)

export default memo(BuyersListView)

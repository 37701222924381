import React, { useCallback, useMemo, useState } from "react"
import { Button } from "modaresa-commons"

import BuyerPicker from "../BuyerPicker"

import { ClientItem, Clients, ContainerBuyerPicker, StyledCloseIcon, ContainerButtons } from "./ClientsList.styles"

type Props = {
  clientsArray: [
    {
      _id: string,
      firstName: string,
      lastName: string,
      position: string,
      email: string,
    },
  ],
  canEdit: boolean,
  brandId: string,
}
const ClientsList = ({ clientsArray, canEdit, brandId, onChangeNewClients }: Props) => {
  const [newClients, setNewClients] = useState([])
  const handleClickAddClient = useCallback(() => setNewClients([...newClients, null]), [newClients])
  const handleChangeNewClient = useCallback(
    index => buyer => {
      const updatedNewClients = [...newClients]
      updatedNewClients[index] = buyer
      setNewClients(updatedNewClients)
      if (onChangeNewClients) {
        onChangeNewClients(updatedNewClients)
      }
    },
    [newClients, onChangeNewClients],
  )
  const handleRemoveNewClient = useCallback(
    index => () => {
      const updatedNewClients = [...newClients]
      updatedNewClients.splice(index, 1)
      setNewClients(updatedNewClients)
      if (onChangeNewClients) {
        onChangeNewClients(updatedNewClients)
      }
    },
    [newClients, onChangeNewClients],
  )
  const [mainBuyer] = clientsArray
  const excludeBuyersIdsFromPicker = useMemo(() => {
    const idsToExclude = clientsArray.map(client => client._id)
    newClients.forEach(newClient => {
      if (newClient) {
        idsToExclude.push(newClient.id)
      }
    })
    return idsToExclude
  }, [clientsArray, newClients])
  return (
    <Clients>
      <p>Attending clients:</p>
      <div>
        {clientsArray.map(client => (
          <ClientItem key={client._id}>
            <p>{`${client.firstName} ${client.lastName}, ${client.position} - ${client.email}`}</p>
            <p>{client.phoneNumber}</p>
          </ClientItem>
        ))}
      </div>
      <div>
        {newClients.map((newClient, index) => (
          <ClientItem key={newClient ? newClient.id : index}>
            {newClient ? (
              <>
                <p>{`${newClient.fullName}, ${newClient.position} - ${newClient.email}`}</p>
                <p>{newClient.phoneNumber}</p>
              </>
            ) : (
              <ContainerBuyerPicker>
                <BuyerPicker
                  brandId={brandId}
                  restrictToRetailerId={mainBuyer.retailerId}
                  excludeBuyerIds={excludeBuyersIdsFromPicker}
                  onChange={handleChangeNewClient(index)}
                />
              </ContainerBuyerPicker>
            )}
            <StyledCloseIcon onClick={handleRemoveNewClient(index)} />
          </ClientItem>
        ))}
      </div>
      <ContainerButtons>{canEdit && <Button onClick={handleClickAddClient}>Add client</Button>}</ContainerButtons>
    </Clients>
  )
}

export { ClientsList }

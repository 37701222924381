import React, { memo } from "react"
import { NavLink } from "react-router-dom"

type Props = {
  isCurrentPageCalendar: boolean,
}
export const MenuDefault = memo(({ isCurrentPageCalendar }: Props) => (
  <>
    <NavLink activeClassName="route-active" className="route tab" to="/sessions">
      SESSIONS
    </NavLink>
    <NavLink
      activeClassName="route-active"
      className="route tab"
      to={isCurrentPageCalendar ? "/calendars?reset=true" : "/calendars"}
    >
      CALENDARS
    </NavLink>
    <NavLink activeClassName="route-active" className="route tab" to="/clients">
      CLIENTS
    </NavLink>
    <NavLink activeClassName="route-active" className="route tab" to="/staff">
      STAFF
    </NavLink>
    <NavLink activeClassName="route-active" className="route tab" to="/settings">
      SETTINGS
    </NavLink>
  </>
))

import { marketsByValue, modaResaRoles } from "../../../config"
import { doesStringMatch } from "../../../helpers/filter"

const staffSearch = {
  fullName: {
    match: (staffFullname = "", searchValue) => {
      if (doesStringMatch(staffFullname.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: staffFullname,
            key: "fullName",
            label: `${staffFullname} (full name)`,
          },
        ]
      }
      return null
    },
    filter: (staffs, value) => staffs.filter(staff => staff.fullName === value),
  },
  companyPosition: {
    match: (companyPosition = "", searchValue) => {
      if (doesStringMatch(companyPosition.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: companyPosition,
            key: "companyPosition",
            label: `${companyPosition} (position)`,
          },
        ]
      }
      return null
    },
    filter: (staffs, value) => staffs.filter(staff => staff.companyPosition === value),
  },
  office: {
    match: (office, searchValue) => {
      if (!office) return null
      if (doesStringMatch(office.name.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: office.name,
            key: "office",
            label: `${office.name} (office)`,
          },
        ]
      }
      return null
    },
    filter: (staffs, value) => staffs.filter(staff => staff.office?.name === value),
  },
  market: {
    match: (market, searchValue) => {
      const marketLabel = marketsByValue[market]
      if (!marketLabel) return null
      if (doesStringMatch(marketLabel.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: market,
            key: "market",
            label: `${marketLabel} (market)`,
          },
        ]
      }
      return null
    },
    filter: (staffs, value) => staffs.filter(staff => staff.market === value),
  },
  modaresaRole: {
    match: (modaresaRole, searchValue) => {
      const modaresaRoleObj = modaResaRoles[modaresaRole]
      if (!modaresaRoleObj) return null
      if (doesStringMatch(modaresaRoleObj.name.toLowerCase(), searchValue.toLowerCase())) {
        return [
          {
            value: modaresaRole,
            key: "modaresaRole",
            label: `${modaresaRoleObj.name} (role)`,
          },
        ]
      }
      return null
    },
    filter: (staffs, value) => staffs.filter(staff => staff.modaresaRole === value),
  },
  hasEditorRights: {
    match: (hasEditorRights, searchValue) => {
      if (searchValue.toLowerCase() === "yes" && hasEditorRights) {
        return [
          {
            value: true,
            key: "hasEditorRights",
            label: `Yes (editor rights)`,
          },
        ]
      } else if (searchValue.toLowerCase() === "no" && !hasEditorRights) {
        return [
          {
            value: false,
            key: "hasEditorRights",
            label: `No (editor rights)`,
          },
        ]
      }
      return null
    },
    filter: (staffs, value) => staffs.filter(staff => staff.hasEditorRights === value),
  },
}

export const staffSearchMatchKeys = Object.keys(staffSearch).filter(key => staffSearch[key].match)
export default staffSearch

import React, { memo, useCallback, useState } from "react"
import { ModalConfirmation } from "modaresa-commons"
import styled from "styled-components"

import { Textarea } from "../../ui/Textarea"

export const ContainerToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  & input {
    margin: 0;
  }
`
export const Label = styled.label`
  margin: 0 0 0 8px;
  color: ${p => p.theme.black};
`

type Props = {
  isOpen: boolean,
  onCancelMeeting: Function,
  onClose: Function,
  loading: boolean,
}

const ModalCancelMeeting = ({ isOpen, onCancelMeeting, onClose, loading }: Props) => {
  const [sendBuyerCancellationEmail, setSendBuyerCancellationEmail] = useState(true)
  const [cancellationReason, setCancellationReason] = useState("")
  const handleClickOk = useCallback(() => {
    onCancelMeeting({ sendBuyerCancellationEmail, cancellationReason })
  }, [onCancelMeeting, sendBuyerCancellationEmail, cancellationReason])
  const handleToggleSendBuyerCancellationEmail = useCallback(e => {
    setSendBuyerCancellationEmail(e.target.checked)
  }, [])
  const handleChangeCancellationReason = useCallback(e => {
    setCancellationReason(e.target.value)
  }, [])
  return (
    <ModalConfirmation
      title="Confirm Cancellation"
      isOpen={isOpen}
      hideCancel
      disableOK={sendBuyerCancellationEmail && cancellationReason === ""}
      buttonLabelOk="Yes, cancel appointment"
      onClickOk={handleClickOk}
      onClose={onClose}
      loading={loading}
    >
      <p>Are you sure you want to cancel this appointment?</p>
      <p>{`Cancelling this appointment will delete it from the calendar view, but don't worry, we keep a track of all cancelled appointments in your report dashboard.`}</p>
      <ContainerToggle>
        <input
          id="buyer-cancellation-email"
          type="checkbox"
          onChange={handleToggleSendBuyerCancellationEmail}
          checked={sendBuyerCancellationEmail}
        />
        <Label htmlFor="buyer-cancellation-email">Notify the buyer(s) of the cancellation</Label>
      </ContainerToggle>
      {sendBuyerCancellationEmail && (
        <div>
          <p>Add a reason for cancellation (required)</p>
          <Textarea width="100%" rows={4} onChange={handleChangeCancellationReason} />
        </div>
      )}
    </ModalConfirmation>
  )
}

export default memo(ModalCancelMeeting)

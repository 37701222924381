import forEach from "lodash/forEach"
import forIn from "lodash/forIn"
import io from "socket.io-client"

import { ADD_CLIENT, CHECK_IN, CHECK_OUT, DELETE_CLIENT, UPDATE_CLIENT } from "../actions/clients"
import { ADD_RETAILER, ADD_RETAILER_BY_SRETAILER_ID, DELETE_RETAILER, UPDATE_RETAILER } from "../actions/retailers"
import { ADD_SOCKET_ROOM, REMOVE_SOCKET_ROOM } from "../actions/sockets"
import {
  ARRIVED_TO_APPOINTMENT,
  CHECK_NEW_BOOKING_TIME,
  CREATE_BOOKING,
  DELAY_APPOINTMENT,
  DELETE_APPOINTMENT,
  DELETE_BOOKING,
  REPLACE_EVENT,
  RESERVE_BOOKING,
  UPDATE_APPOINTMENT_HOST,
  UPDATE_BOOKING,
  UPDATE_BOOKING_TIME,
} from "../actions/schedules"
import { SOCKET_URL } from "../../config"
import { UPDATE_STAFF_MEMBER } from "../actions/staff"

import { onToastError, onToastSuccess } from "./../../helpers/toast"
import * as events from "./messageTypes"

const successActionTypeBySocketEvent = {
  [events.BOOKING_CREATED]: CREATE_BOOKING,
  [events.REPLACE_EVENT]: REPLACE_EVENT,
  [events.BOOKING_RESERVED]: RESERVE_BOOKING.SUCCESS,
  [events.BOOKING_CONFIRMED]: UPDATE_BOOKING.SUCCESS,
  [events.BOOKING_UPDATED]: UPDATE_BOOKING.SUCCESS,
  [events.BOOKING_TIME_UPDATED]: UPDATE_BOOKING_TIME.SUCCESS,
  [events.CONFIRMED_EVENT_TIME_UPDATE]: CHECK_NEW_BOOKING_TIME.SUCCESS,
  [events.HOST_REALLOCATED]: UPDATE_APPOINTMENT_HOST.SUCCESS,
  [events.MEETING_CANCELLED]: {
    action: DELETE_APPOINTMENT.SUCCESS,
    successMessage: "Appointment was successfully canceled",
  },
  [events.ARRIVED_TO_APPOINTMENT]: ARRIVED_TO_APPOINTMENT.SUCCESS,
  [events.APPOINTMENT_DELAYED]: DELAY_APPOINTMENT.SUCCESS,
  [events.HOST_CHECKED_IN]: {
    action: CHECK_IN.SUCCESS,
    successMessage: "Successfully checked in!",
  },
  [events.HOST_CHECKED_OUT]: {
    action: CHECK_OUT.SUCCESS,
    successMessage: "Successfully checked out!",
  },
  [events.BUYER_UPDATED]: UPDATE_CLIENT.SUCCESS,
  [events.BUYER_DELETED]: DELETE_CLIENT.SUCCESS,
  [events.RETAILER_CREATED]: ADD_RETAILER.SUCCESS,
  [events.RETAILER_UPDATED]: UPDATE_RETAILER.SUCCESS,
  [events.RETAILER_DELETED]: DELETE_RETAILER.SUCCESS,
  [events.RETAILER_CREATED_BY_SHARED_RETAILER]: {
    action: ADD_RETAILER_BY_SRETAILER_ID.SUCCESS,
    successMessage: "Retailer successfully added to your CRM",
  },
}

const actionTypeBySocketEvent = {
  [events.RESERVE_BOOKING]: RESERVE_BOOKING.FAILURE,
  [events.UPDATE_CONFIRMED_EVENT_TIME]: CHECK_NEW_BOOKING_TIME.FAILURE,
  [events.CONFIRM_BOOKING]: UPDATE_BOOKING.FAILURE,
  [events.DELETE_BOOKING]: DELETE_BOOKING.FAILURE,
  [events.UPDATE_BOOKING_TIME]: UPDATE_BOOKING_TIME.FAILURE,
  [events.REALLOCATE]: UPDATE_APPOINTMENT_HOST.FAILURE,
  [events.CANCEL_MEETING]: DELETE_APPOINTMENT.FAILURE,
  [events.SET_ARRIVED_TO_APPOINTMENT]: ARRIVED_TO_APPOINTMENT.FAILURE,
  [events.DELAY_APPOINTMENT]: DELAY_APPOINTMENT.FAILURE,
  [events.CHECKOUT_HOST]: CHECK_IN.FAILURE,
  [events.CHECKIN_HOST]: CHECK_OUT.FAILURE,
  [events.UPDATE_BUYER]: UPDATE_CLIENT.FAILURE,
  [events.CREATE_BUYER]: ADD_CLIENT.FAILURE,
  [events.DELETE_BUYER]: DELETE_CLIENT.FAILURE,
  [events.DELETE_RETAILER]: DELETE_RETAILER.FAILURE,
  [events.CREATE_RETAILER]: ADD_RETAILER.FAILURE,
  [events.UPDATE_RETAILER]: UPDATE_RETAILER.FAILURE,
  [events.CREATE_RETAILER_BY_SHARED_RETAILER]: ADD_RETAILER_BY_SRETAILER_ID.FAILURE,
}

function createSocketEventHandler(socket, storeAPI, socketEvent, actionType, options = {}) {
  socket.on(socketEvent, payload => {
    if (options.successMessage) {
      onToastSuccess(options.successMessage)
    }
    storeAPI.dispatch({
      type: actionType,
      payload,
    })
  })
}

function assignErrorEventsToActions(socket, storeAPI) {
  socket.on(events.API_ERROR, error => {
    const { eventType, message } = error
    let customError = "Oops, something went wrong"
    if (error && (error.errorType === "custom" || error.errorType === "db")) {
      customError = error.message
    }
    onToastError(customError)
    console.error(error)
    const actionType = actionTypeBySocketEvent[eventType]
    storeAPI.dispatch({
      type: actionType,
      payload: message,
    })
  })
}

function assignSuccessActionsToEvents(socket, storeAPI) {
  forIn(successActionTypeBySocketEvent, (actionOrOptions, event) => {
    if (typeof actionOrOptions === "string") {
      createSocketEventHandler(socket, storeAPI, event, actionOrOptions)
    } else {
      const { action, ...options } = actionOrOptions
      createSocketEventHandler(socket, storeAPI, event, action, options)
    }
  })
}

const createMySocketMiddleware = () => storeAPI => {
  const socket = io(SOCKET_URL, {
    transports: ["websocket"],
  })
  socket.on("connect", () => {
    const {
      sockets: { rooms },
    } = storeAPI.getState()
    forEach(rooms, (room, type) => {
      socket.emit(events.JOIN_SOCKET_ROOM, {
        type,
        room,
      })
    })
  })

  socket.on(events.BOOKING_DELETED, payload => {
    if (payload.removedReserved) {
      const {
        schedules: { selectedBooking },
      } = storeAPI.getState()
      if (selectedBooking && selectedBooking._id === payload.removedReserved)
        onToastError("Oops, time's up. Slot will be released")
    }
    storeAPI.dispatch({
      type: DELETE_BOOKING.SUCCESS,
      payload,
    })
  })

  assignSuccessActionsToEvents(socket, storeAPI)

  // Clients
  socket.on(events.BUYER_CREATED, payload => {
    const { newBuyer, updateAgent } = payload
    if (updateAgent) {
      const {
        user: { user },
      } = storeAPI.getState()
      if (user.modaresaRole === "agent" && user.affinityClients.length > 0) {
        storeAPI.dispatch({
          type: UPDATE_STAFF_MEMBER.REQUEST,
          payload: {
            data: {
              ...user,
              affinityClients: [...user.affinityClients, newBuyer._id],
            },
            id: user._id,
          },
        })
      }
    }
    storeAPI.dispatch({
      type: ADD_CLIENT.SUCCESS,
      payload,
    })
  })
  assignErrorEventsToActions(socket, storeAPI)

  return next => action => {
    if (action.type === "SEND_WEBSOCKET_MESSAGE") {
      const { eventType, body } = action.payload

      if (eventType === events.JOIN_SOCKET_ROOM) {
        storeAPI.dispatch({
          type: ADD_SOCKET_ROOM,
          payload: body,
        })
      } else if (eventType === events.LEAVE_SOCKET_ROOM) {
        storeAPI.dispatch({
          type: REMOVE_SOCKET_ROOM,
          payload: body,
        })
      }
      socket.emit(eventType, body)
      return
    }

    return next(action)
  }
}

export default createMySocketMiddleware

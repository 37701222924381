import React, { memo, useCallback, useMemo } from "react"
import { Column, Table } from "react-virtualized"

type Props = {
  width: number,
  height: number,
  buyers: [],
  onRowClick: Function,
  onRenderActions: Function,
  onScroll?: Function,
}

const TableBuyers = ({ width, height, buyers, onRowClick, onRenderActions, onScroll }: Props) => {
  const sortByRetailerName = useCallback((a, b) => {
    if (a?.retailer?.name > b?.retailer?.name) return 1
    else if (a?.retailer?.name < b?.retailer?.name) return -1
    return 0
  }, [])
  const sortedBuyers = useMemo(() => [...buyers].sort(sortByRetailerName), [buyers, sortByRetailerName])
  const handleRowGetter = useCallback(({ index }) => sortedBuyers[index], [sortedBuyers])
  const handleRowClassName = useCallback(({ index }) => (index % 2 === 0 ? "oddRow" : "evenRow"), [])
  const handleGetRetailerNameData = useCallback(({ rowData, dataKey }) => rowData[dataKey]?.name, [])
  const handleRenderManager = useCallback(({ rowData: { retailer } }) => retailer?.meetingResponsible?.fullName, [])
  const handleRenderOrigin = useCallback(
    ({ rowData: { retailer } }) =>
      retailer?.storeAddress && `${retailer.storeAddress.city}, ${retailer.storeAddress.country.toUpperCase()}`,
    [],
  )
  const handleRenderActions = useCallback(
    ({ rowData }) => {
      if (!onRenderActions) return null
      return onRenderActions(rowData)
    },
    [onRenderActions],
  )
  return (
    <Table
      className="Buyers-Table"
      gridClassName="Buyers-Table__grid"
      headerClassName="Buyers-Table__header"
      headerHeight={60}
      height={height}
      rowClassName={handleRowClassName}
      rowCount={sortedBuyers.length}
      rowGetter={handleRowGetter}
      rowHeight={50}
      width={width}
      onRowClick={onRowClick}
      onScroll={onScroll}
    >
      <Column cellDataGetter={handleGetRetailerNameData} dataKey="retailer" label="Retailer" width={250} />
      <Column dataKey="fullName" label="Full name" width={250} />
      <Column dataKey="email" label="E-mail" width={350} />
      <Column dataKey="position" label="Position" width={250} />
      <Column cellRenderer={handleRenderManager} dataKey="manager" label="Manager" width={250} />
      <Column cellRenderer={handleRenderOrigin} dataKey="origin" label="Origin" width={250} />
      <Column dataKey="phoneNumber" label="Phone nbr." width={250} />
      <Column className="visible" dataKey={"no"} width={100} cellRenderer={handleRenderActions} />
    </Table>
  )
}

export default memo(TableBuyers)

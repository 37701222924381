import moment from "moment-timezone"
import React, { memo, useCallback, useMemo, useState } from "react"
import Select from "react-select"
import { Error } from "modaresa-commons"

import { hoursFormat24Options } from "../../../helpers/date"
import { CustomSwitch } from "../../ui/Switch"

import { ContainerDateSelect, ContainerRange, ContainerRow, Date, To } from "./DayHoursRangePicker.styles"

type Props = {
  defaultDay: {
    date: number,
    available: boolean,
    from: { value: string, label: string },
    to: { value: string, label: string },
  },
  disabled: boolean,
  error: string,
  minHour?: any,
  maxHour?: any,
}

const DayHoursRangePicker = ({ defaultDay, disabled, error, minHour, maxHour, onChange }: Props) => {
  const [day, setDay] = useState(defaultDay)
  const handleUpdateDay = useCallback(
    field => value => {
      const updatedDay = {
        ...day,
        [field]: value,
      }
      setDay(updatedDay)
      if (onChange) {
        onChange(updatedDay)
      }
    },
    [day, onChange],
  )
  const isStartOptionDisabled = useCallback(
    option => option.value.numerical < minHour || option.value.numerical > maxHour,
    [minHour, maxHour],
  )
  const isEndOptionDisabled = useCallback(
    option => option.value.numerical < day.from.value?.numerical || option.value.numerical > maxHour,
    [day, maxHour],
  )
  const date = useMemo(() => {
    const dayDate = moment.utc(day.date, "X")
    let format = "ddd, MMM Do"
    if (dayDate.year() !== moment().year()) {
      format += " YYYY"
    }
    return dayDate.format(format)
  }, [day.date])
  return (
    <div>
      <ContainerRow>
        <Date>{date}</Date>
        <CustomSwitch cheked={day.available} disabled={disabled} onswitch={handleUpdateDay("available")} />
        <ContainerRange>
          <ContainerDateSelect>
            <Select
              isDisabled={disabled || !day.available}
              isOptionDisabled={isStartOptionDisabled}
              options={hoursFormat24Options}
              value={day.from}
              onChange={handleUpdateDay("from")}
            />
          </ContainerDateSelect>
          <To>to</To>
          <ContainerDateSelect>
            <Select
              isDisabled={disabled || !day.available}
              isOptionDisabled={isEndOptionDisabled}
              options={hoursFormat24Options}
              value={day.to}
              onChange={handleUpdateDay("to")}
            />
          </ContainerDateSelect>
        </ContainerRange>
      </ContainerRow>
      {error && <Error>{error}</Error>}
    </div>
  )
}

DayHoursRangePicker.defaultProps = {
  minHour: 0,
  maxHour: 24,
  defaultDay: {},
}
export default memo(DayHoursRangePicker)

import React, { memo } from "react"
import { useLocation, Link } from "react-router-dom"
import Tooltip from "rc-tooltip"
import styled from "styled-components"
import { EditIcon } from "modaresa-commons/dist/components/icons"

import { stopPropagation } from "../../../../../helpers/utils"

const ContainerActions = styled.div`
  white-space: nowrap;
  font-size: 16px;
`
const Action = styled.span`
  margin: 0 8px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`
const Edit = styled(EditIcon)`
  color: ${p => p.theme.black};
`

type Props = {
  brandId: string,
  room: {
    id: string,
  },
}

const SalesSessionTableRoomsActions = ({ room }: Props) => {
  const location = useLocation()
  return (
    <ContainerActions onClick={stopPropagation}>
      <Tooltip overlay="Edit" placement="top">
        <Action>
          <Link to={`${location.pathname}/${room.id}`}>
            <Edit />
          </Link>
        </Action>
      </Tooltip>
    </ContainerActions>
  )
}

export default memo(SalesSessionTableRoomsActions)

import { gql } from "@apollo/client"

import AreaFragments from "./area.fragments"
import RemainingAreaFragments from "./remainingArea.fragments"
import RoomFragments from "./room.fragments"

export const FRAGMENT_BRAND_AREAS = gql`
  fragment BrandAreas on Brand {
    remainingArea {
      ...RemainingAreaStaffs
      notificationMeetingConfirmation
      notificationMeetingReminder
    }
    areas {
      ...AreaStaffs
      ...AreaTerritory
      id
      name
      color
      notificationMeetingConfirmation
      notificationMeetingReminder
    }
  }
  ${AreaFragments.fragments.staffs}
  ${AreaFragments.fragments.territory}
  ${RemainingAreaFragments.fragments.staffs}
`

export const FRAGMENT_BRAND_ROOMS_AREAS = gql`
  fragment BrandRoomsAreas on Brand {
    rooms {
      ...RoomAreas
    }
  }
  ${RoomFragments.fragments.areas}
`

const BrandFragments = {
  fragments: {
    areas: FRAGMENT_BRAND_AREAS,
    roomsAreas: FRAGMENT_BRAND_ROOMS_AREAS,
  },
}

export default BrandFragments

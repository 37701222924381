import React, { memo } from "react"
import styled from "styled-components"
import { SkeletonBlock } from "modaresa-commons"

const ContainerForm = styled.div`
  padding-left: ${p => p.labelWidth};
`

type Props = {
  labelWidth: string,
}

const FormAvailabilitiesSkeleton = ({ labelWidth }: Props) => (
  <ContainerForm labelWidth={labelWidth}>
    <SkeletonBlock height="38px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" margin="0 0 16px 0" />
  </ContainerForm>
)

export default memo(FormAvailabilitiesSkeleton)

import * as R from "ramda"
import { createSelector } from "reselect"

import { getAvailableRetailers, getRetailers } from "./retailers"
import { getSelectedSession } from "./saleSessions"
import { getUserData } from "./user"

export const getSelectedBuyer = R.path(["clients", "selected"])
export const getClients = R.path(["clients", "clients"])
export const getClientsRequestStatus = R.path(["clients", "clientsRequestPending"])

export const getClientRequestStatus = R.path(["clients", "requestPending"])
export const getClientRequestError = R.path(["clients", "requestError"])
export const getClientsStatus = R.path(["clients", "filterByStatus"])

export const getReports = R.path(["clients", "reports"])
export const getReport = R.path(["clients", "selectedReport"])
export const getClientBookings = R.path(["clients", "clientBookings"])

export const getReportRequestStatus = R.path(["clients", "reportRequestPending"])
export const getReportRequestError = R.path(["clients", "reportRequestError"])

export const getBookingsRequestStatus = R.path(["clients", "bookingsRequestPending"])
export const getBookingsRequestError = R.path(["clients", "bookingsRequestError"])

export const getRequestUpdateEmailPending = R.path(["clients", "requestUpdateEmailPending"])
export const getRequestUpdateEmailError = R.path(["clients", "requestUpdateEmailError"])

export const getAvailableClientsIds = createSelector(
  [getClients, getUserData, getAvailableRetailers],
  (clients, user, availableRetailers) => {
    const { modaresaRole } = user
    let filteredClients = []
    if (modaresaRole === "agent") {
      filteredClients = clients.allIds.filter(id => {
        const client = clients.byId[id]
        return availableRetailers?.includes(client.retailerId)
      })
    } else {
      filteredClients = clients.allIds
    }
    return filteredClients
  },
)

export const getAvailableClientsArray = createSelector(
  [getClients, getRetailers, getClientsStatus, getAvailableClientsIds, getUserData],
  (clients, retailers, status, availableClients, user) => {
    if (!retailers.data) {
      return []
    }

    const { modaresaRole } = user
    let filteredClients = []
    if (modaresaRole === "agent" && user.affinityClients.length > 0 && !status) {
      filteredClients = user.affinityClients
    } else if (modaresaRole === "agent" && user.affinityClients.length > 0 && status) {
      filteredClients = user.affinityClients.filter(id => {
        const client = clients.byId[id]
        return client.retailer && client.retailer.status === status
      })
    } else if (status) {
      filteredClients = availableClients.filter(id => {
        const client = clients.byId[id]
        return client.retailer && client.retailer.status === status
      })
    } else {
      filteredClients = availableClients
    }
    const sortedClients = filteredClients
      ? filteredClients
          .sort((idA, idB) => {
            const clientA = clients.byId[idA]
            const clientB = clients.byId[idB]
            if (!clientA || !clientB) return 0
            const retailerA = retailers.byId[clientA.retailerId]
            const retailerB = retailers.byId[clientB.retailerId]
            if (retailerA && retailerB) {
              if (retailerA.name > retailerB.name) return 1
              if (retailerA.name < retailerB.name) return -1
            }
            return 0
          })
          .map(id => clients.byId[id])
      : []
    return [...sortedClients]
    // return sortedClients
  },
)

export const getActiveClientsArray = createSelector([getAvailableClientsArray], clients =>
  clients.filter(client => client && client.dbStatus === "active"),
)

export const getArchivedClientsArray = createSelector([getAvailableClientsArray], clients =>
  clients.filter(client => client && client.dbStatus === "archived"),
)

export const getFilteredSessionClients = createSelector(
  [getActiveClientsArray, getSelectedSession],
  (sortedClients, session) => {
    let filteredClients = []
    if (!session) return sortedClients
    const { market } = session
    if (market === "womenMen") return sortedClients

    filteredClients = sortedClients.filter(client => client.market === "womenMen" || client.market === market)
    return filteredClients
  },
)

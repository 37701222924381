export default {
  geoLimitMaxed: {
    headerText: "Geographic Limitation maxed out",
    mainText: `Oops, looks like we cannot move the appointment to that time, as the Geo.Limitation for the area of your client has been reached to the maximum. Keep the original time, or find another time slot.
    Contact your showroom manager/wholesale coordinator if you have any questions regarding Geo.Limitation.`,
  },
  noFreelancers: {
    headerText: "Oops, looks like there are no Meeting Hosts available.",
    mainText: `NB! Your staff might be locked to a specific language or area - edit this or add more staff in your sales session's "Allocated Staff" to unlock more availability.`,
  },
}

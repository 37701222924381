import { useField } from "formik"
import React, { memo, useCallback } from "react"

import DayHoursRangePicker from "../../DayHoursRangePicker/DayHoursRangePicker"

type Props = {
  name: string,
  disabled: string,
}

const SubFieldAvailibility = ({ disabled, name }: Props) => {
  const [field, meta, helpers] = useField(name)
  const handleChangeHours = useCallback(
    updatedDay => {
      helpers.setValue(updatedDay)
    },
    [helpers],
  )
  return (
    <DayHoursRangePicker
      key={field.value.date}
      defaultDay={field.value}
      disabled={disabled}
      error={meta.touched && meta.error}
      onChange={handleChangeHours}
    />
  )
}

export default memo(SubFieldAvailibility)

import React, { memo } from "react"
import { Button } from "modaresa-commons"

import TableRooms from "../../../../elements/TableRooms"

import { ContainerRoomsView, HeaderRoomsView, TotalRooms, ContainerButtons } from "./RoomsView.styles"
import RoomsViewSkeleton from "./RoomsViewSkeleton"

type Props = {
  rooms: [],
  skeleton: boolean,
  onRenderTableRoomsActions: Function,
  onClickTableRoomsRow: Function,
  onClickNext: Function,
}
const RoomsView = ({ rooms, skeleton, onRenderTableRoomsActions, onClickTableRoomsRow, onClickNext }: Props) =>
  skeleton ? (
    <RoomsViewSkeleton />
  ) : (
    <ContainerRoomsView>
      <HeaderRoomsView>
        <TotalRooms>{rooms.length} Rooms</TotalRooms>
      </HeaderRoomsView>
      <TableRooms data={rooms} renderActions={onRenderTableRoomsActions} onClickRow={onClickTableRoomsRow} />
      <ContainerButtons>
        <Button onClick={onClickNext}>Next</Button>
      </ContainerButtons>
    </ContainerRoomsView>
  )

export default memo(RoomsView)

import React, { memo } from "react"
import { ModalConfirmation } from "modaresa-commons"

import { Text } from "./ModalConfirmDeleteStaffView.styles"
import StaffCurrentAssignments from "./StaffCurrentAssignments"

type Props = {
  loading: boolean,
  error: string,
  onClose: Function,
  onConfirmDelete: Function,
  assignments: [],
  staff: {},
}

const ModalConfirmDeleteStaff = ({ loading, error, onClose, onConfirmDelete, assignments, staff }: Props) => (
  <ModalConfirmation
    isOpen
    width="550px"
    loading={loading}
    error={error}
    title="Delete Staff Member"
    buttonLabelOk="Delete"
    onClickOk={onConfirmDelete}
    onClose={onClose}
  >
    {assignments ? (
      <StaffCurrentAssignments staff={staff} assignments={assignments} />
    ) : (
      <Text>
        <p>{"Are you sure that you want to delete this staff member?"}</p>
      </Text>
    )}
  </ModalConfirmation>
)
export default memo(ModalConfirmDeleteStaff)

import Raven from "raven-js"
import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { ThemeProvider } from "styled-components"
import { theme } from "modaresa-commons"

import App from "./components/App"
import * as serviceWorker from "./serviceWorker"
import configureStore from "./store/configureStore"
import rootReducer from "./store/reducers"
import rootSaga from "./store/sagas"

const { store, persistor } = configureStore(rootReducer, rootSaga)
if (process.env.NODE_ENV === "production") {
  Raven.config("https://d176c5997ba941e7b5125afe4ab1846d@sentry.io/1267540").install()
}

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
)

serviceWorker.unregister()

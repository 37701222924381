import { useQuery } from "@apollo/client"
import React, { useMemo, memo } from "react"
import { AlertDanger } from "modaresa-commons"

import { SALES_SESSION_ROOM_AREAS_AND_RETAILERS } from "../../../graphql/queries/salesSession"
import RoomAreaAndTerritoryPicker from "../RoomAreaAndTerritoryPicker/RoomAreaAndTerritoryPicker"

type Props = {
  withAreas: boolean | null,
  withRooms: boolean | null,
  withGoogleSuggestion: boolean | null,
  salesSessionId: string,
  multiple: boolean,
  placeholder: string,
  disabled: boolean,
  onChange: Function,
  defaultValue: any,
  value: any,
}

const SalesSessionRoomAreaAndTerritoryPicker = ({
  withAreas = true,
  withRooms = true,
  withGoogleSuggestion = true,
  salesSessionId,
  multiple,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
}: Props) => {
  const { error, data } = useQuery(SALES_SESSION_ROOM_AREAS_AND_RETAILERS, {
    variables: { id: salesSessionId },
  })
  const salesSession = useMemo(() => {
    if (!data || !data.SalesSession) return {}
    const { toggleRoomBasedAreas, areas, rooms, brand } = data.SalesSession
    return toggleRoomBasedAreas ? { rooms, brand } : { areas, brand }
  }, [data])
  if (error) return <AlertDanger>Error</AlertDanger>
  return (
    <RoomAreaAndTerritoryPicker
      withAreas={withAreas}
      withRooms={withRooms}
      withGoogleSuggestion={withGoogleSuggestion}
      multiple={multiple}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      rooms={withRooms ? salesSession.rooms : []}
      areas={salesSession.areas}
      retailers={salesSession.brand?.retailers}
    />
  )
}

SalesSessionRoomAreaAndTerritoryPicker.defaultProps = {
  placeholder: "Select area or territory",
  multiple: false,
}
export default memo(SalesSessionRoomAreaAndTerritoryPicker)

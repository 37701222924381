import React from "react"
import { toast } from "react-toastify"
import { Toast } from "modaresa-commons"

export const onToastError = error => {
  toast.error(`${error || "Error !"}`, {
    position: toast.POSITION.TOP_RIGHT,
  })
}

export const onToastSuccess = message => {
  toast.success(`${message || ""}`, {
    position: toast.POSITION.TOP_RIGHT,
  })
}

export const onToastInfo = data => {
  toast(<Toast {...data} />, {
    hideProgressBar: true,
    className: "def-toast",
    autoClose: 7000,
  })
}

export const onToastInfoPermanent = data => {
  toast(<Toast {...data} />, {
    hideProgressBar: true,
    className: "def-toast",
    autoClose: false,
  })
}

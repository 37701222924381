import React, { memo, useMemo } from "react"
import { B, Ul, ModalConfirmation } from "modaresa-commons"

import { ContainerInvites } from "./ModalConfirmSendingInvitations.styles"

type Props = {
  title: string,
  buttonLabelOk: string,
  onClickOk: Function,
  onClose: Function,
  buyers: [],
  selectedBuyers: {},
}

const ModalConfirmSendingInvitations = ({
  title,
  buttonLabelOk,
  onClickOk,
  onClose,
  buyers,
  selectedBuyers,
}: Props) => {
  const buyersList = useMemo(() => buyers.filter(buyer => selectedBuyers[buyer.id]), [buyers, selectedBuyers])
  return (
    <ModalConfirmation title={title} buttonLabelOk={buttonLabelOk} isOpen onClickOk={onClickOk} onClose={onClose}>
      <div>{buyersList.length} emails will be sent.</div>
      <ContainerInvites>
        <Ul>
          {buyersList.map(buyer => (
            <li key={buyer.id}>
              <B>{buyer.fullName}</B> - {buyer.retailer.name} - {buyer.position}
            </li>
          ))}
        </Ul>
      </ContainerInvites>
    </ModalConfirmation>
  )
}

export default memo(ModalConfirmSendingInvitations)

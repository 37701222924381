import React, { memo } from "react"
import Switch from "react-switch"
import { B, HelpTooltip, theme } from "modaresa-commons"

import { ContainerToggle, ToggleDescription } from "./Toggler.styles"

type Props = {
  title: string,
  description: string,
  helpText: string,
  disabled: boolean,
  checked: boolean,
  onChange: Function,
  showBorder?: boolean,
  className: string,
}

const Toggler = ({ title, description, helpText, disabled, checked, onChange, showBorder, className }: Props) => (
  <ContainerToggle className={className} showBorder={showBorder}>
    <ToggleDescription>
      <B>{title}</B>
      {title && description && ` - `}
      {description}
      {helpText && <HelpTooltip text={helpText} />}
    </ToggleDescription>
    <Switch
      aria-label={`${title} switch`}
      checked={checked}
      checkedIcon={false}
      disabled={disabled}
      handleDiameter={22}
      offColor={theme.secondary}
      uncheckedIcon={false}
      onChange={onChange}
      onColor={theme.primary}
    />
  </ContainerToggle>
)

Toggler.defaultProps = {
  title: "Toggle",
  description: "this toggle does change state",
  showBorder: false,
}
export default memo(Toggler)

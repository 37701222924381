import React, { memo, useCallback } from "react"
import { Column, Table } from "react-virtualized"

import { storeType } from "../../../config/retailers"
import { capitalizeFirstLetter } from "../../../helpers/string"

type Props = {
  width: number,
  height: number,
  retailers: [],
  onRowClick: Function,
  onRenderActions: Function,
}

const TableRetailers = ({ width, height, retailers, onRowClick, onRenderActions }: Props) => {
  const handleRowGetter = useCallback(({ index }) => retailers[index], [retailers])
  const handleRowClassName = useCallback(({ index }) => (index % 2 === 0 ? "oddRow" : "evenRow"), [])
  const handleGetRetailerTypeData = useCallback(({ rowData, dataKey }) => storeType[rowData[dataKey]], [])
  const handleGetRetailerStatusData = useCallback(({ rowData, dataKey }) => capitalizeFirstLetter(rowData[dataKey]), [])
  const handleRenderOrigin = useCallback(
    ({ rowData: { storeAddress } }) => storeAddress && `${storeAddress.city}, ${storeAddress.country.toUpperCase()}`,
    [],
  )
  const handleRenderActions = useCallback(
    ({ rowData }) => {
      if (!onRenderActions) return null
      return onRenderActions(rowData)
    },
    [onRenderActions],
  )
  return (
    <Table
      className="Buyers-Table"
      gridClassName="Buyers-Table__grid"
      headerClassName="Buyers-Table__header"
      headerHeight={60}
      height={height}
      rowClassName={handleRowClassName}
      rowCount={retailers.length}
      rowGetter={handleRowGetter}
      rowHeight={50}
      width={width}
      onRowClick={onRowClick}
    >
      <Column dataKey="name" label="Company Name" width={350} />
      <Column dataKey="origin" label="Origin" width={250} cellRenderer={handleRenderOrigin} />
      <Column dataKey="nbBuyers" label="Buyers" width={125} />
      <Column dataKey="clientId" label="Client ID" width={125} />
      <Column dataKey="type" label="Store Type" width={250} cellRenderer={handleGetRetailerTypeData} />
      <Column dataKey="status" label="Status" width={250} cellRenderer={handleGetRetailerStatusData} />
      <Column dataKey="priorityGroup" label="Priority Group" width={125} />
      <Column dataKey="actions" label="" width={50} cellRenderer={handleRenderActions} />
    </Table>
  )
}

export default memo(TableRetailers)

import { gql } from "@apollo/client"

import RoomFragments from "./room.fragments"

export const BRAND_ROOM = gql`
  query GetBrandRoom($brandId: ID!, $roomId: ID!) {
    getBrandRoom(brandId: $brandId, roomId: $roomId) {
      ...SingleRoom
    }
  }
  ${RoomFragments.fragments.single}
`

export const SALES_SESSION_ROOM = gql`
  query GetSalesSessionRoom($salesSessionId: ID!, $roomId: ID!) {
    getSalesSessionRoom(salesSessionId: $salesSessionId, roomId: $roomId) {
      ...SingleRoom
    }
  }
  ${RoomFragments.fragments.single}
`

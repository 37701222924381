import { gql } from "@apollo/client"

import StaffFragments from "./staff.fragments"

export const FRAGMENT_REMAINING_AREA_STAFFS = gql`
  fragment RemainingAreaStaffs on RemainingArea {
    id
    meetingResponsible {
      ...StaffBasicInfos
    }
    signee {
      ...StaffBasicInfos
    }
    notifiedStaffs {
      ...StaffBasicInfos
    }
  }
  ${StaffFragments.fragments.basicInfos}
`

const RemainingAreaFragments = {
  fragments: {
    staffs: FRAGMENT_REMAINING_AREA_STAFFS,
  },
}

export default RemainingAreaFragments

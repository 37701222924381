export const standardDuration = [
  { value: 15, label: "15 min" },
  { value: 30, label: "30 min" },
  { value: 45, label: "45 min" },
  { value: 60, label: "1 H" },
  { value: 75, label: "1 H 15" },
  { value: 90, label: "1 H 30" },
  { value: 105, label: "1 H 45" },
  { value: 120, label: "2 H" },
  { value: 135, label: "2 H 15" },
  { value: 150, label: "2 H 30" },
  { value: 165, label: "2 H 45" },
  { value: 180, label: "3 H" },
  { value: 195, label: "3 H 15" },
  { value: 210, label: "3 H 30" },
  { value: 225, label: "3 H 45" },
  { value: 240, label: "4 H" },
  { value: 255, label: "4 H 15" },
  { value: 270, label: "4 H 30" },
  { value: 285, label: "4 H 45" },
  { value: 300, label: "5 H" },
  { value: 315, label: "5 H 15" },
  { value: 330, label: "5 H 30" },
  { value: 345, label: "5 H 45" },
]

export const mealTypes = [
  { type: "breakfast", label: "Breakfast" },
  { type: "lunch", label: "Lunch" },
  { type: "snacks", label: "Snacks" },
]

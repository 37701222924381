import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
  padding-top: 0px;
  box-sizing: border-box;
`

export const Content = styled.div`
  overflow-y: auto;
  height: 100%;
  margin-bottom: 10px;
`

export const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: white;
  padding: 5px 10px 5px 10px;
`
export const Footer = styled.div`
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 60px 30px 30px;
`
export const DeleteIcon = styled.img`
  display: block;
  height: 18px;
  width: auto;
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`

export const CloseModal = styled.span`
  position: absolute;
  color: ${props => props.theme.strawberry};
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
`
export const List = styled.ol`
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 35px;
  max-height: 300px;
`
export const Bold = styled.span`
  font-weight: bold;
`
export const ModalBody = styled.div`
  & .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255);
  }
`
export const StaffRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #efebeb;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  & .select__indicators {
    display: none;
  }

  & .select__placeholder {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding-right: 12px;
  }

  & .select__multi-value__label {
    font-size: 14px;
  }

  & > div {
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }
`

export const Table = styled.div`
  position: relative;
`
export const TableHeader = styled.div`
  min-height: 50px;
  background: #f5f5f5;
  border-bottom: 3px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 2;

  & > div {
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    font-weight: bold;
  }
`
export const TableBody = styled.div``
export const Col = styled.div`
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  min-width: ${({ width }) => width};

  ${({ text }) => {
    if (text) {
      return `padding-top: 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
    `
    }
  }};

  &.special {
    font-style: italic;
    color: #abaaab;
  }
`
export const Icon = styled.img`
  display: inline-block;
  width: ${props => props.width};
  height: ${props => props.height};
`
export const GeoLimit = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
  padding-bottom: 15px;
  padding-left: 0;
  padding-top: 0;
  padding-right: calc(8% + 35px);
  border-bottom: 1px solid #efebeb;
`

export const RoomGeo = styled.div`
  display: flex;
  min-width: 400px;
  max-width: 47%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  justify-content: space-between;
`

import { useMutation } from "@apollo/client"
import React, { useState, memo, useCallback } from "react"
import { Button } from "modaresa-commons"

import { DELETE_STAFF } from "../../../graphql/mutations/staff"
import { BRAND_STAFFS_FULL } from "../../../graphql/queries/brand"
import { onToastError } from "../../../helpers/toast"
import { stopPropagation } from "../../../helpers/utils"
import { DeleteButton } from "../../ui/DeleteButton"

import ModalConfirmDeleteStaff from "./ModalConfirmDeleteStaff"

type Props = {
  disabled: boolean,
  staff: {},
  onDelete: Function,
}

const ButtonDeleteStaff = ({ disabled, staff, children, onDelete }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [deleteStaff] = useMutation(DELETE_STAFF, {
    update: (cache, { data: { deleteStaff: deletedStaff } }) => {
      const cacheData = cache.readQuery({
        query: BRAND_STAFFS_FULL,
        variables: {
          id: deletedStaff.brand.id,
        },
      })
      if (cacheData?.Brand?.staffs) {
        const { Brand } = cacheData
        cache.writeQuery({
          query: BRAND_STAFFS_FULL,
          data: {
            Brand: {
              ...Brand,
              staffs: Brand.staffs.filter(staff => staff.id !== deletedStaff.id),
            },
          },
        })
      }
    },
  })
  const [showModal, setShowModal] = useState(false)
  const handleToggleModal = useCallback(state => () => setShowModal(state), [])
  const handleDelete = useCallback(async () => {
    try {
      setIsLoading(true)
      await deleteStaff({ variables: { id: staff.id } })
      setShowModal(false)
      if (onDelete) {
        onDelete()
      }
    } catch (e) {
      console.error(e)
      onToastError("An error occured while deleting staff")
    }
    setIsLoading(false)
  }, [deleteStaff, staff, onDelete])
  return (
    <span onClick={stopPropagation}>
      {children ? (
        <Button disabled={disabled} onClick={handleToggleModal(true)}>
          {children}
        </Button>
      ) : (
        <DeleteButton disabled={disabled} onClick={handleToggleModal(true)} />
      )}
      {showModal && (
        <ModalConfirmDeleteStaff
          isLoadingDeletion={isLoading}
          staff={staff}
          onConfirmDelete={handleDelete}
          onClose={handleToggleModal(false)}
        />
      )}
    </span>
  )
}

export default memo(ButtonDeleteStaff)

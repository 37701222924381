import { useField } from "formik"
import React, { memo } from "react"

import FormFieldLayout from "../../FormFieldLayout"

import SubFieldAvailibility from "./SubFieldAvailibility"

type Props = {
  disabled: boolean,
  labelWidth: string,
}

const FieldAvailabilities = ({ disabled, labelWidth }: Props) => {
  const [field, meta] = useField("availabilities")
  return (
    <FormFieldLayout
      label="Availabilities"
      name="availabilities"
      labelWidth={labelWidth}
      error={meta.touched && meta.error}
    >
      {field.value.map(
        (availability, index) =>
          availability && (
            <SubFieldAvailibility key={availability.date} disabled={disabled} name={`availabilities[${index}]`} />
          ),
      )}
    </FormFieldLayout>
  )
}

export default memo(FieldAvailabilities)

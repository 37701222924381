import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import { Flex } from "modaresa-commons"

import AppleIcon from "../../../../../static/apple.svg"
import BrkfstIcon from "../../../../../static/bkfst.svg"
import LunchIcon from "../../../../../static/lunch.svg"

import { Col, Icon, Row, Table, TableBody, TableHeader } from "./styles"

export class Catering extends Component {
  renderBody = () => {
    const { capacity } = this.props
    return capacity.map((data, index) => {
      const today = moment
        .utc()
        .set({ hour: "00", minute: "00", second: "00" })
        .unix()
      const dayDate = data[0].date
      return (
        <Row current={today === dayDate} key={index}>
          <Col width="12%"> {moment.utc(data[0].date, "X").format("ddd, MMM Do")}</Col>
          <Col width="12%"> {data[0].breakfast}</Col>
          <Col width="12%"> {data[0].lunch}</Col>
          <Col width="12%"> {data[0].snacks}</Col>
          <Col textAl="center" width="12%">
            {" "}
            {data[0].models}
          </Col>
        </Row>
      )
    })
  }

  render() {
    return (
      <Table>
        <TableHeader>
          <Col width="12%">Date</Col>
          <Flex justify="flex-start" style={{ width: "12%" }}>
            Breakfast <Icon height="25px" src={BrkfstIcon} width="25px" />
          </Flex>
          <Flex justify="flex-start" style={{ width: "12%" }}>
            Lunch <Icon height="25px" src={LunchIcon} width="25px" />
          </Flex>
          <Flex justify="flex-start" style={{ width: "12%" }}>
            Snacks <Icon height="25px" src={AppleIcon} width="25px" />
          </Flex>
          <Col textAl="center" width="12%">
            Model Estimation
          </Col>
        </TableHeader>
        <TableBody>{this.renderBody()}</TableBody>
      </Table>
    )
  }
}

Catering.propTypes = {
  capacity: PropTypes.array,
}

export default Catering

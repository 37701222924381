import { gql } from "@apollo/client"

export const DECLINE_RELOU_REQUEST = gql`
  mutation DeclineRelouRequest($id: ID!) {
    declineRelouRequest(id: $id) {
      id
      status
    }
  }
`

export const CONFIRM_RELOU_REQUEST = gql`
  mutation ConfirmRelouRequest($id: ID!, $hostId: ID, $budget: Int) {
    confirmRelouRequest(id: $id, hostId: $hostId, budget: $budget) {
      relouRequest {
        id
        status
      }
      bookingEvent {
        id
        createdAt
        updatedAt
        schedule {
          id
        }
        brand {
          id
        }
        clientTimezone
        language
        clients {
          id
        }
        meetingResp {
          id
        }
        startTime
        endTime
        dayDate
        host {
          id
        }
        tableIndex
        room
        budget
        hostStatus
        status
        isRequested
        type
        appointmentStatus
        meetingType
        meetingApproach
        videoTool
      }
    }
  }
`

import styled from "styled-components"
import { Tabs } from "modaresa-commons"
import { CloseIcon, MeetingRequestIcon } from "modaresa-commons/dist/components/icons"

export const ContainerRelouRequests = styled.div`
  position: fixed;
  box-shadow: 0 2px 4px 0 rgba(85, 77, 86, 0.5);
  z-index: 3;
  bottom: 100px;
  right: 24px;
  background-color: white;
  transition: 0.2s all ease-in;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  ${p =>
    p.minimize
      ? `
    height: 60px;
    width: 60px;
    border-radius: 50%;
  `
      : `
    height: calc(100vh - 180px);
    width: 300px;
    border-radius: 4px;
  `}
`
export const Title = styled.h3`
  margin: 16px 0;
  width: 100%;
  text-align: center;
  font-size: 20px;
`
export const TopRightCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  color: ${p => p.theme.primary};
  cursor: pointer;
`

export const ContainerReopen = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`

export const IconMeetingRequest = styled(MeetingRequestIcon)`
  font-size: 36px;
  color: ${p => p.theme.primary};
`

export const FlexTabs = styled(Tabs)`
  flex: 1;
`

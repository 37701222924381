import { Link } from "react-router-dom"
import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
`

export const ContainerForm = styled.div`
  width: 50%;
`

export const Divider = styled.div`
  font-weight: 600;
  border-bottom: 2px solid ${props => props.theme.veryLightGray};
  margin-bottom: 15px;
  margin-top: 20px;
  padding-bottom: 2px;
`

export const CompetitorGroup = styled.div`
  line-height: 40px;
  font-size: 15px;
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledLink = styled(Link)`
  width: 49%;
`

type Props = {
  areasAndTerritories: {
    roomAreas: [],
    areas: [],
    territories: {},
  },
  toggleRoomBasedAreas: boolean,
}
const areasAndTerritoriesToString = ({ areasAndTerritories, toggleRoomBasedAreas }: Props) => {
  const areasAndTerritoriesStrings = []
  const { territories, roomAreas, areas } = areasAndTerritories
  if (toggleRoomBasedAreas) {
    roomAreas.forEach(({ room, area }) => areasAndTerritoriesStrings.push(`${room.name} > ${area.name}`))
  } else {
    areas.forEach(area => areasAndTerritoriesStrings.push(area.name))
  }
  if (territories) {
    const { continents, countries, regions, counties, cities, retailers } = territories
    continents.forEach(continent => areasAndTerritoriesStrings.push(continent))
    countries.forEach(country => areasAndTerritoriesStrings.push(country))
    regions.forEach(region => areasAndTerritoriesStrings.push(region.name))
    counties.forEach(county => areasAndTerritoriesStrings.push(county.name))
    cities.forEach(city => areasAndTerritoriesStrings.push(city.name))
    retailers.forEach(retailer => areasAndTerritoriesStrings.push(retailer.name))
  }

  return areasAndTerritoriesStrings.join(", ")
}

export default areasAndTerritoriesToString

import React, { useEffect, useCallback, useState } from "react"
import Modal from "react-modal"
import moment from "moment-timezone"
import forOwn from "lodash/forOwn"
import isNull from "lodash/isNull"
import isEmpty from "lodash/isEmpty"
import Select from "react-select"
import DatePicker from "react-datepicker"
import { Button, Input } from "modaresa-commons"
import DateRangeIcon from "@material-ui/icons/DateRange"
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined"
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined"
import GridOffIcon from "@material-ui/icons/GridOff"
import DetailsIcon from "@material-ui/icons/Details"
import TextFieldsIcon from "@material-ui/icons/TextFields"

import useForm from "../../../../hooks/useForm"

import { Bold, ButtonsRow, CloseModal, ModalBody } from "./RestrictionModal.styles"

import { getHourList } from "helpers/date"
import SalesSessionRoomAreaAndTerritoryPicker from "components/elements/SalesSessionRoomAreaAndTerritoryPicker"
import retailerSearch from "components/screens/Clients/blocks/AllRetailers/retailer.search"

const customStyles = {
  content: {
    width: "650px",
    height: "auto",
    minHeight: "440px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 2,
  },
}

const restrictionTypes = [
  { value: "canBook", label: "Can Book" },
  { value: "canNotBook", label: "Can not book" },
]

const hoursList = getHourList("24")

const initialState = {
  areas: [],
  retailers: [],
  name: null,
  type: null,
  startTime: null,
  endTime: null,
  startDate: null,
  endDate: null,
  areasMerged: [],
}

function validate(values) {
  const errors = {}
  if (!values.name) {
    errors.name = "This field is is required."
  }
  if (!values.type) {
    errors.type = "This field is is required."
  }
  if (!values.startTime) {
    errors.startTime = "This field is is required."
  }
  if (!values.endTime) {
    errors.endTime = "This field is is required."
  }
  if (!values.startDate) {
    errors.startDate = "This field is is required."
  }
  if (!values.endDate) {
    errors.endDate = "This field is is required."
  }
  if (!values.areas) {
    errors.areas = "This field is is required."
  }

  return errors
}

const RestrictionModal = ({ session, restrictItem, close, save }) => {
  const [item, setItem] = useState(initialState)

  const saveForm = () => {
    const { name, id, type, areas, retailers, startDate, endDate, startTime, endTime } = item

    const restriction = {
      id,
      name,
      type: type?.value,
      areas,
      retailers,
      startTime: startTime?.timestamp,
      endTime: endTime?.timestamp,
      startDate: startDate?.unix(),
      endDate: endDate?.unix(),
    }
    setItem(initialState)
    save(restriction)
  }

  const { errors, setInitialState, handleChange, handleSubmit } = useForm(saveForm, validate)

  const loadItem = useCallback(async () => {
    if (!restrictItem) return

    const {
      id,
      name,
      type: itemType,
      startDate: itemStartDate,
      endDate: itemEndDate,
      startTime: itemStartTime,
      endTime: itemEndTime,
      areas,
      retailers: itemRetailers,
    } = restrictItem

    const startTimeFormat = moment(itemStartTime, "X")
      .utc()
      .format("HH:mm")
    const endTimeFormat = moment(itemEndTime, "X")
      .utc()
      .format("HH:mm")
    const startDate = moment(itemStartDate * 1000)
    const endDate = moment(itemEndDate * 1000)
    const type = restrictionTypes.find(t => t.value === itemType)
    const startTime = hoursList.find(h => h.value === startTimeFormat)
    const endTime = hoursList.find(h => h.value === endTimeFormat)

    const retailers = itemRetailers.map(r => ({
      label: r.name,
      title: r.name,
      value: r.id,
      type: "retailer",
    }))

    setItem({
      ...item,
      id,
      areas,
      retailers: itemRetailers,
      name,
      type,
      startDate,
      endDate,
      startTime,
      endTime,
      areasMerged: [...areas, ...retailers],
    })

    setInitialState({
      values: {
        areas: [...areas, ...retailers],
        name,
        type,
        startDate,
        endDate,
        startTime,
        endTime,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictItem])

  useEffect(() => {
    if (restrictItem) {
      loadItem()
    }
  }, [loadItem, restrictItem])

  const geoHandleChange = async (values = []) => {
    const retailers = []
    const areas = []
    values?.forEach(item => {
      switch (item.type) {
        case "retailer":
          retailers.push({ id: item.value, name: item.label })
          break
        default:
          areas.push(item)
          break
      }
    })

    setItem({
      ...item,
      retailers,
      areas,
    })
    handleChange({
      name: "areas",
      value: [...areas, ...retailers],
    })
  }

  return (
    <Modal isOpen={true} style={customStyles}>
      <form onSubmit={handleSubmit} noValidate>
        <ModalBody>
          <div>
            <Bold style={{ fontSize: "16px" }}>Add a new restriction</Bold>
          </div>
          <div style={{ margin: "28px 0 10px 0" }}>
            <div style={{ margin: "18px 0" }}>
              <div>
                <Bold style={{ fontSize: "14px" }}>
                  <GridOffIcon style={{ fontSize: "14px" }} />
                  <span style={{ margin: "0 4px" }}>Only these territories or retailers</span>
                </Bold>
              </div>
              <div style={{ width: "70%" }}>
                <SalesSessionRoomAreaAndTerritoryPicker
                  withAreas={false}
                  withRooms={false}
                  withGoogleSuggestion={false}
                  salesSessionId={session._id}
                  multiple
                  placeholder="Select territories or retailers"
                  defaultValue={item.areasMerged}
                  onChange={values => geoHandleChange(values)}
                />
                {errors.areas && <p style={{ fontSize: "12px", color: "red" }}>{errors.areas}</p>}
              </div>
            </div>
            <div style={{ margin: "22px 0" }}>
              <div>
                <Bold style={{ fontSize: "14px" }}>
                  <DetailsIcon style={{ fontSize: "14px" }} />
                  <span style={{ margin: "0 4px" }}>Restriction type</span>
                </Bold>
              </div>
              <div style={{ width: "70%" }}>
                <Select
                  name="type"
                  options={restrictionTypes}
                  value={item.type}
                  onChange={value => {
                    setItem({
                      ...item,
                      type: value,
                    })
                    handleChange({
                      name: "type",
                      value,
                    })
                  }}
                />
                {errors.type && <p style={{ fontSize: "12px", color: "red" }}>{errors.type}</p>}
              </div>
            </div>
            <div style={{ margin: "22px 0" }}>
              <div>
                <Bold style={{ fontSize: "14px" }}>
                  <DateRangeIcon style={{ fontSize: "14px" }} />
                  <span style={{ margin: "0 4px" }}>On this date range</span>
                </Bold>
              </div>
              <div style={{ width: "70%" }}>
                <span style={{ width: "45%" }}>
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    utcOffset={0}
                    selectsStart
                    selected={item.startDate ?? null}
                    startDate={item.startDate ?? null}
                    endDate={item.endDate ?? null}
                    minDate={moment()}
                    onChange={date => {
                      setItem({
                        ...item,
                        startDate: date,
                      })
                      handleChange({
                        name: "startDate",
                        value: date,
                      })
                    }}
                  />
                </span>
                <span style={{ margin: "0 10px" }}>
                  <ArrowForwardOutlinedIcon />
                </span>
                <span style={{ width: "45%" }}>
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    utcOffset={0}
                    selectsEnd
                    selected={item.endDate ?? null}
                    minDate={item.startDate ?? moment()}
                    startDate={item.startDate ?? null}
                    endDate={item.endDate ?? null}
                    onChange={date => {
                      setItem({
                        ...item,
                        endDate: date,
                      })
                      handleChange({
                        name: "endDate",
                        value: date,
                      })
                    }}
                  />
                </span>
              </div>
              {(errors.startDate || errors.endDate) && (
                <p style={{ fontSize: "12px", color: "red" }}>{errors.startDate || errors.endDate}</p>
              )}
            </div>
            <div style={{ margin: "22px 0", height: "64px" }}>
              <div>
                <Bold style={{ fontSize: "14px" }}>
                  <ScheduleOutlinedIcon style={{ fontSize: "14px" }} />
                  <span style={{ margin: "0 4px" }}>For any slots with a start-time between</span>
                </Bold>
              </div>
              <div style={{ height: "40px" }}>
                <div style={{ width: "130px", float: "left" }}>
                  <Select
                    name="startTime"
                    options={hoursList}
                    placeholder=""
                    value={item.startTime}
                    onChange={time => {
                      setItem({
                        ...item,
                        startTime: time,
                      })
                      handleChange({
                        name: "startTime",
                        value: time,
                      })
                    }}
                  />
                </div>
                <div style={{ margin: "0 10px", float: "left" }}>
                  <ArrowForwardOutlinedIcon />
                </div>
                <div style={{ width: "130px", float: "left" }}>
                  <Select
                    name="endTime"
                    options={hoursList}
                    placeholder=""
                    value={item.endTime}
                    onChange={time => {
                      setItem({
                        ...item,
                        endTime: time,
                      })
                      handleChange({
                        name: "endTime",
                        value: time,
                      })
                    }}
                  />
                </div>
              </div>
              {(errors.startTime || errors.endTime) && (
                <div style={{ display: "block", float: "none", fontSize: "12px", color: "red" }}>
                  {errors.startTime || errors.endTime}
                </div>
              )}
            </div>
            <div style={{ margin: "18px 0" }}>
              <div>
                <Bold style={{ fontSize: "14px" }}>
                  <TextFieldsIcon style={{ fontSize: "14px" }} />
                  <span style={{ margin: "0 4px" }}>Restriction name</span>
                </Bold>
              </div>
              <div style={{ width: "70%" }}>
                <Input
                  name="name"
                  placeholder="Enter restriction name"
                  type="text"
                  value={item.name ?? ""}
                  onChange={e => {
                    setItem({
                      ...item,
                      name: e.target.value,
                    })
                    handleChange({ event: e })
                  }}
                />
                {errors.name && <p style={{ fontSize: "12px", color: "red" }}>{errors.name}</p>}
              </div>
            </div>
          </div>
        </ModalBody>
        <ButtonsRow>
          <Button type="submit">Save Restriction</Button>
        </ButtonsRow>
        <CloseModal onClick={() => close()}>X</CloseModal>
      </form>
    </Modal>
  )
  //}
}

export default RestrictionModal

import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import { Flex } from "modaresa-commons"

import { Col, Row, Table } from "./styles"

const mocka = [
  { date: 1548374400, breakfast: "", lunch: "", snacks: "", model: "" },
  { date: 1548460800, breakfast: "", lunch: "", snacks: "", model: "" },
  { date: 1548547200, breakfast: "", lunch: "", snacks: "", model: "" },
  { date: 1548633600, breakfast: "", lunch: "", snacks: "", model: "" },
  { date: 1548720000, breakfast: "", lunch: "", snacks: "", model: "" },
]

export class Showroom extends Component {
  renderBody = () =>
    mocka.map((data, index) => (
      <Row key={index}>
        <Col style={{ width: "10%" }}> {moment.utc(data.date, "X").format("ddd, MMM Do")}</Col>
        <Col textAl="center" width="12%">
          {data.breakfast}
        </Col>
        <Col textAl="center" width="12%">
          {data.lunch}
        </Col>
        <Col textAl="center" width="12%">
          {data.snacks}
        </Col>
        <Col textAl="center" width="12%">
          {data.model}
        </Col>
        <Col textAl="center" width="12%">
          {data.lunch}
        </Col>
        <Col textAl="center" width="12%">
          {data.snacks}
        </Col>
      </Row>
    ))

  renderHeader = () => {
    const { brand } = this.props
    return brand.areas.map((area, index) => (
      <Flex align="stretch" column key={index} style={{ width: "12%" }}>
        <span style={{ fontWeight: "bold" }}>{area.name}</span>
        <span>Geo. Limit. | Av. Meeting Hosts</span>
      </Flex>
    ))
  }

  render() {
    return (
      <Table>
        <div>Geo.Limititation</div>
      </Table>
    )
  }
}

Showroom.propTypes = {
  brand: PropTypes.object,
  capacity: PropTypes.array,
}

export default Showroom

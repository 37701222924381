import { ADD_BRAND, FETCH_BRAND, UPDATE_BRAND, UPDATE_COMPETITOR_GROUPS } from "../actions/brand"
import { createReducer } from "../..//helpers/redux"

const initialState = {
  brand: {},
  allBrands: [],
  requestPending: false,
  requestError: null,
}

const handlers = {
  [FETCH_BRAND.SUCCESS]: (state, { payload }) => ({
    ...state,
    brand: payload.brand,
    allBrands: payload.allBrands,
  }),
  [UPDATE_BRAND.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_BRAND.SUCCESS]: (state, { payload }) => ({
    ...state,
    brand: payload,
    requestPending: false,
  }),
  [UPDATE_BRAND.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [UPDATE_COMPETITOR_GROUPS.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_COMPETITOR_GROUPS.SUCCESS]: (state, { payload }) => ({
    ...state,
    brand: payload,
    requestPending: false,
  }),
  [UPDATE_COMPETITOR_GROUPS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [ADD_BRAND.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [ADD_BRAND.SUCCESS]: (state, { payload }) => ({
    ...state,
    allBrands: [...state.allBrands, payload],
    requestPending: false,
  }),
  [ADD_BRAND.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
}

export default createReducer(initialState, handlers)

import { useMutation } from "@apollo/client"
import React, { memo, useCallback, useState } from "react"

import { UPDATE_STAFF_BUYER_RESPONSIBILITY } from "../../../../graphql/mutations/staff"
import { getGraphQLErrorAsString } from "../../../../helpers/error"
import { onToastError, onToastSuccess } from "../../../../helpers/toast"

import { ContainerBuyerResponsibility } from "./BuyerResponsibility.styles"
import FormBuyerResponsibility from "./FormBuyerResponsibility"

type Props = {
  staff: {},
  readOnly: boolean,
  restrictBuyersToStaffAreas: boolean,
}

const BuyerResponsibility = ({ staff, readOnly, restrictBuyersToStaffAreas }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [updateStaffBuyerResponsibility] = useMutation(UPDATE_STAFF_BUYER_RESPONSIBILITY)
  const handleSubmit = useCallback(
    async values => {
      setIsLoading(true)
      const variables = {
        id: staff.id,
        market: values.market.value,
        affinityBuyersIds: values.affinityBuyers.map(buyer => buyer.id),
      }
      try {
        await updateStaffBuyerResponsibility({ variables })
        onToastSuccess("Buyer responsibility settings saved successfully")
      } catch (err) {
        console.error(err)
        onToastError(`An error occured while saving Buyer responsibility settings. ${getGraphQLErrorAsString(err)}`)
      }
      setIsLoading(false)
    },
    [staff.id, updateStaffBuyerResponsibility],
  )

  return (
    <ContainerBuyerResponsibility>
      <FormBuyerResponsibility
        staff={staff}
        onSubmit={handleSubmit}
        loadingSubmit={isLoading}
        readOnly={readOnly}
        restrictBuyersToStaffAreas={restrictBuyersToStaffAreas}
      />
    </ContainerBuyerResponsibility>
  )
}

export default memo(BuyerResponsibility)

import React, { memo } from "react"
import { HelpTooltip, Error } from "modaresa-commons"

import { ContainerFieldLayout, ContainerChildren, FieldLabel, LabelText, LabelTooltip } from "./FormFieldLayout.styles"

type Props = {
  children: any,
  name: string,
  label: string,
  labelWidth: number,
  error: string,
  direction?: "row" | "column",
  helpText: string,
}

const FormFieldLayout = ({ children, name, label, labelWidth, error, direction, helpText }: Props) => (
  <ContainerFieldLayout direction={direction}>
    {label && (
      <FieldLabel labelWidth={labelWidth} direction={direction} htmlFor={name}>
        <LabelText>{label}</LabelText>
        {helpText && (
          <LabelTooltip>
            <HelpTooltip text={helpText} />
          </LabelTooltip>
        )}
      </FieldLabel>
    )}
    <ContainerChildren>
      {children}
      {error && <Error>{error}</Error>}
    </ContainerChildren>
  </ContainerFieldLayout>
)

FormFieldLayout.defaultProps = {
  direction: "row",
}
export default memo(FormFieldLayout)

import React, { memo } from "react"
import Tooltip from "rc-tooltip"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { EditIcon, TrashIcon } from "modaresa-commons/dist/components/icons"

import ButtonDeleteBrandRoom from "../../../../elements/ButtonDeleteBrandRoom"

const ContainerActions = styled.div`
  white-space: nowrap;
  font-size: 16px;
`
const Action = styled.span`
  margin: 0 8px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`
const Edit = styled(EditIcon)`
  color: ${p => p.theme.black};
`

type Props = {
  canView: boolean,
  canEdit: boolean,
  brandId: string,
  room: {
    id: string,
  },
}

const BrandTableRoomsActions = ({ canView, canEdit, brandId, room }: Props) => (
  <ContainerActions>
    {canView && (
      <Tooltip overlay="Edit" placement="top">
        <Action>
          <Link to={`/settings/company/rooms/${room.id}`}>
            <Edit />
          </Link>
        </Action>
      </Tooltip>
    )}
    {canEdit && (
      <Action>
        <ButtonDeleteBrandRoom brandId={brandId} roomId={room.id}>
          <Tooltip overlay="Delete" placement="top">
            <TrashIcon />
          </Tooltip>
        </ButtonDeleteBrandRoom>
      </Action>
    )}
  </ContainerActions>
)

export default memo(BrandTableRoomsActions)

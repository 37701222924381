import countriesContinents from "../config/countriesContinents"

export const loadGoogleMapsApi = callback => {
  const defaultInitFunction = () => undefined
  if (!document.getElementById("gmaps-api")) {
    window.initMap = defaultInitFunction
    const gmapScriptEl = document.createElement(`script`)
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC3boOLqNgMBUDAzCemBK_B0-yO8u7VfmU&libraries=places&callback=initMap&language=en`
    gmapScriptEl.id = "gmaps-api"
    gmapScriptEl.defer = true
    document.head.appendChild(gmapScriptEl)
  } else {
    if (callback) {
      callback()
    }
  }
}

export const formatAddressFromAddressComponents = addressComponents =>
  addressComponents.reduce((fullAddress, addressComponent) => {
    const placeTypes = addressComponent.types

    placeTypes.forEach(placeType => {
      switch (placeType) {
        case "locality":
          fullAddress.city = addressComponent.long_name
          break
        case "administrative_area_level_2":
          fullAddress.county = addressComponent.long_name
          break
        case "administrative_area_level_1":
          fullAddress.region = addressComponent.long_name
          break
        case "country": {
          const acronymCountry = addressComponent.short_name.toLowerCase()
          fullAddress.country = acronymCountry
          fullAddress.continent = countriesContinents[acronymCountry]
          break
        }
        default:
          break
      }
    })

    return fullAddress
  }, {})

/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable extra-rules/no-commented-out-code */
import { connect } from "react-redux"
import { createSelector } from "reselect"
import { theme } from "modaresa-commons"

import findSessionAreaFromRetailer from "../../../../../helpers/bookingSlots/findSessionAreaFromRetailer"
import { getSelectedSession, getRetailers, getUserData } from "../../../../../store/selectors"

import Component from "./Booking"

import { getRetailerByIdOrEmpty, onMissingRetailerIdForClient } from "services/retailers"

const getClientById = (state, clientId) => state.clients.clients.byId[clientId] || null

const getRetailer = (state, retailer) => {
  if (retailer) return retailer
  return null
}

const getRoom = (state, retailer, room) => room

const actions = {}

const makeGetRetailerByClient = () =>
  createSelector([getRetailers, getClientById], (retailers, client) => {
    if (client) {
      const { retailerId } = client
      return getRetailerByIdOrEmpty(retailerId, retailers, onMissingRetailerIdForClient(client))
    }
    return null
  })

const makeGetAreaColor = () =>
  createSelector([getSelectedSession, getRetailer, getUserData, getRoom], (session, retailer, user, roomName) => {
    if (retailer) {
      const room = session.rooms.find(sessionRoom => sessionRoom.name === roomName)
      const area = findSessionAreaFromRetailer(session, retailer, room._id)
      const staff = session.staff.find(member => member.staffId === user._id)
      const isUserAgent = user.modaresaRole === "agent"
      if (isUserAgent) {
        if (
          staff.geographicResponsibility.areas.some(geoArea => geoArea === (area?.id || area?._id)) ||
          user.geographicResponsibility.areas.some(geoArea => geoArea === (area?.id || area?._id))
        ) {
          return area ? area.color : theme.defaultBookingColor
        }
        return theme.disabledBooking
      }
      return area ? area.color : theme.defaultBookingColor
    }
    return null
  })

const makeMapStateToProps = () => {
  const getRetailerByClient = makeGetRetailerByClient()
  const getAreaColor = makeGetAreaColor()
  const mapStateToProps = (state, props) => {
    const {
      booking: { clients, room, isDisabled },
      canEdit,
    } = props
    const clientId = clients[0]
    const retailer = getRetailerByClient(state, clientId)
    const color = getAreaColor(state, retailer, room)
    return {
      retailer,
      color,
      user: state.user.user,
      canEdit: canEdit && color !== theme.disabledBooking && !isDisabled,
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps, actions)(Component)

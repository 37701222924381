import generateTerritoriesOptionsValuesFromObject from "./generateTerritoriesOptionsValuesFromObject"

type Props = {
  geographicResponsibility: {
    areas: [],
    roomAreas: [],
  },
  toggleRoomBasedAreas: boolean,
  areas: [{ _id: string, name: string }],
  rooms: [
    {
      _id: string,
      name: string,
      areas: [
        {
          _id: string,
          name: string,
        },
      ],
    },
  ],
}

const getAreasAndTerritoriesOptionsValuesFromStaff = ({
  geographicResponsibility = {},
  toggleRoomBasedAreas,
  areas = [],
  rooms = [],
}: Props = {}) => {
  if (!geographicResponsibility) return []
  let options = []
  if (toggleRoomBasedAreas) {
    if (geographicResponsibility.roomAreas) {
      geographicResponsibility.roomAreas.forEach(roomArea => {
        const room = rooms.find(room => roomArea?.roomId === room?._id)
        if (!room) return
        const area = room.areas.find(area => roomArea?.areaId === area?._id)
        if (!area) return
        options.push({
          value: roomArea,
          label: `${room.name} > ${area.name}`,
          title: `${room.name} > ${area.name}`,
          type: "roomArea",
        })
      })
    }
  } else {
    if (geographicResponsibility.areas) {
      geographicResponsibility.areas.forEach(areaId => {
        const area = areas.find(area => areaId === area._id)
        if (area) {
          options.push({
            label: area.name,
            title: area.name,
            value: area._id,
            type: "area",
          })
        }
      })
    }
  }
  if (geographicResponsibility.territories) {
    const territoriesOpts = generateTerritoriesOptionsValuesFromObject(geographicResponsibility.territories)
    options = options.concat(territoriesOpts)
  }
  return options
}

export default getAreasAndTerritoriesOptionsValuesFromStaff

import { useField } from "formik"
import React, { useMemo, memo, useCallback } from "react"
import PhoneInput from "react-phone-input-2"
import styled from "styled-components"

import FormFieldLayout from "../FormFieldLayout/FormFieldLayout"

const ContainerFormInput = styled.div`
  width: 100%;
  .react-tel-input .form-control {
    width: 100%;
    margin-right: 0;
    height: 38px;
    box-shadow: none;
    &:disabled {
      background-color: #f2f2f2;
      border: 1px solid #e6e6e6;
      color: hsl(0, 0%, 60%);
    }
    &:focus {
      outline: none;
      border: 2px solid rgb(0, 95, 204);
      box-shadow: none;
    }
  }
`

type Props = {
  label: string,
  labelWidth: string,
  name: string,
  direction?: "row" | "column",
  disabled: boolean,
}

const FormikPhoneNumber = ({ label, labelWidth, name, direction, disabled }: Props) => {
  const [field, meta] = useField(name)
  const inputExtraProps = useMemo(() => ({ id: name, name }), [name])
  const handleChange = useCallback((value, country, e, formattedValue) => e.type && field.onChange(e), [field])
  return (
    <FormFieldLayout
      label={label}
      labelWidth={labelWidth}
      name={name}
      direction={direction}
      error={meta.touched && meta.error}
    >
      <ContainerFormInput>
        <PhoneInput
          disabled={disabled}
          country={"fr"}
          inputProps={inputExtraProps}
          value={field.value}
          onBlur={field.onBlur}
          onChange={handleChange}
          enableSearch
          disableSearchIcon
          searchStyle={{ width: "95%" }}
        />
      </ContainerFormInput>
    </FormFieldLayout>
  )
}

FormikPhoneNumber.defaultProps = {
  direction: "row",
}
export default memo(FormikPhoneNumber)

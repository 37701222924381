export const formatAreaStaffs = area => ({
  meetingResponsibleId: area.meetingResponsible?.id,
  signeeId: area.signee?.id,
  notifiedStaffsIds: area.notifiedStaffs ? area.notifiedStaffs.map(staff => staff.id) : [],
})

const removeTypename = ({ __typename, ...obj }) => obj
export const formatAreaTerritories = ({ continents, countries, regions, counties, cities, retailers }) => ({
  continents: continents || [],
  countries: countries || [],
  regions: (regions || []).map(removeTypename),
  counties: (counties || []).map(removeTypename),
  cities: (cities || []).map(removeTypename),
  retailers: (retailers || []).map(({ id }) => id),
})

export const formatAreaInput = areaData => {
  const { name, color, id, territories, notificationMeetingConfirmation, notificationMeetingReminder } = areaData
  return {
    id,
    color,
    name,
    territories: formatAreaTerritories(territories),
    ...formatAreaStaffs(areaData),
    notificationMeetingConfirmation,
    notificationMeetingReminder,
  }
}

export const formatSalesSessionAreaInput = areaData => {
  const { color, id, notificationMeetingConfirmation, notificationMeetingReminder } = areaData
  return {
    id,
    color,
    ...formatAreaStaffs(areaData),
    notificationMeetingConfirmation,
    notificationMeetingReminder,
  }
}

export const formatRemainingAreaInput = remainingAreaData => {
  const { id, notificationMeetingConfirmation, notificationMeetingReminder } = remainingAreaData
  return {
    id,
    ...formatAreaStaffs(remainingAreaData),
    notificationMeetingConfirmation,
    notificationMeetingReminder,
  }
}

import React, { memo, useMemo } from "react"
import { Link } from "react-router-dom"
import Tooltip from "rc-tooltip"
import styled from "styled-components"
import { ReportIcon } from "modaresa-commons/dist/components/icons"

export const StyledReportIcon = styled(ReportIcon)`
  color: white;
`

type Props = {
  booking: {
    _id: string,
    clients: [string],
    report: string,
  },
}

const ButtonGoToMeetingReport = ({ booking }: Props) => {
  const reportUrl = useMemo(
    () =>
      booking.report
        ? `/clients/${booking.clients[0]}/meetings-reports/${booking.report}`
        : `/clients/${booking.clients[0]}/meetings-reports?booking=${booking._id}`,
    [booking],
  )
  return (
    <Tooltip overlay="Meeting Report" placement="top">
      <Link to={reportUrl}>
        <StyledReportIcon />
      </Link>
    </Tooltip>
  )
}

export default memo(ButtonGoToMeetingReport)

import "./styles.css"
import qs from "qs"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import Select from "react-select"
import { SyncLoader } from "react-spinners"
import debounce from "lodash/debounce"
import moment from "moment-timezone"
import times from "lodash/times"
import { Button, Flex } from "modaresa-commons"

import { isStaffAtLeastManager } from "../../../helpers/users"
import PageTitle from "../../elements/PageTitle/PageTitle"
import ArrowIcon from "../../../static/back.svg"
import noSessionImage from "../../../static/Modaresa_VTC-01.png"

import { RelouCalendar } from "./blocks/RelouCalendar"
import RelouRequests from "./blocks/RelouRequests"
import CalendarsProvider from "./CalendarsProvider"
import {
  CalendarContent,
  CalendarHeader,
  Container,
  Date,
  DateContainer,
  Hour,
  Icon,
  Loader,
  NotFound,
  Placeholder,
  Timeline,
} from "./styles.js"
import { CalendarTabs } from "./blocks/CalendarTabs"
import { EditBookingModal } from "./blocks/EditBookingModal"
import { MasterCalendar } from "./blocks/MasterCalendar"
import { NewBookingModal } from "./blocks/NewBookingModal"
import { RoomCalendar } from "./blocks/RoomCalendar"
import { StaffCalendar } from "./blocks/StaffCalendar/index"
import { WeeklyCalendar } from "./blocks/WeeklyCalendar"

import { getUrlParameter } from "helpers/urls"

const calendarTypes = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "staff", label: "Staff" },
]

class Calendars extends Component {
  constructor(props) {
    super(props)

    const { user, selectedSession } = this.props
    const userRole = user.modaresaRole

    this.state = {
      placeholderStyle: null,
      calendarType: userRole === "freelancer" ? calendarTypes[2] : calendarTypes[0],
      selectedTab: { type: "master", label: "Master Calendar" },
      selectedDayIndex: 0,
      showEditModal: false,
      showNewBookingModal: false,
      staffMember:
        userRole === "freelancer"
          ? { value: user._id, label: `${user.firstName} ${user.lastName}` }
          : { value: "all", label: "All Staff" },
      selectedRoom: { value: "all", label: "All Rooms" },
      firstDayofRange: true,
      firstDayDate: null,
      lastDayofRange: false,
      firstDayofRandIndex: 0,
      selectedSessionValue: selectedSession ? { value: selectedSession._id, label: selectedSession.name } : null,
    }
    this.placeholder = null
  }

  static getDerivedStateFromProps(nextProps) {
    const { history } = nextProps
    const staffId = getUrlParameter("staffId")
    const sessionId = getUrlParameter("sessionId")
    const reset = getUrlParameter("reset")
    const { staff } = nextProps
    if (staffId && !sessionId) {
      const selectedStaff = staff.byId[staffId]
      history.replace("/calendars")
      return {
        calendarType: calendarTypes[2],
        staffMember: {
          value: selectedStaff._id,
          label: `${selectedStaff.firstName} ${selectedStaff.lastName}`,
        },
      }
    }
    if (reset === "true") {
      history.replace("/calendars")
      return {
        calendarType: calendarTypes[0],
        selectedTab: { type: "master", label: "Master Calendar" },
      }
    }
    return null
  }

  componentDidMount() {
    const {
      sessions,
      onFetchSchedule,
      onFetchRetailers,
      onFetchClients,
      onFetchStaffs,
      retailersRequestPending,
      clientsRequestPending,
      staffsRequestPending,
      selectedSession,
      staff,
      retailers,
      clients,
      user,
      location,
      sessionOptions,
    } = this.props

    const { staffId, sessionId } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    if (!retailersRequestPending && !retailers?.data) {
      onFetchRetailers(user.brandId)
    }

    if (!clientsRequestPending && !clients?.data) {
      onFetchClients(user.brandId)
    }

    if (!staffsRequestPending && !staff?.data) {
      onFetchStaffs(user.brandId)
    }

    if (staff?.data && sessionId && sessions.find(session => session._id === sessionId)) {
      const selectedStaff = staff.byId[staffId]
      const session = sessions.find(session => session._id === sessionId)
      let newStateObj = {
        selectedSessionValue: { value: session._id, label: session.name },
      }
      if (staffId) {
        newStateObj = {
          ...newStateObj,
          calendarType: calendarTypes[2],
          staffMember: {
            value: selectedStaff._id,
            label: `${selectedStaff.firstName} ${selectedStaff.lastName}`,
          },
        }
      }
      this.setState(newStateObj)
      onFetchSchedule(sessionId, this.updateDayIndex)
    } else if (selectedSession && selectedSession.status === "active") {
      onFetchSchedule(selectedSession._id, this.updateDayIndex)
    } else if (sessionOptions[0].options.length > 0) {
      onFetchSchedule(sessionOptions[0].options[0].value, this.updateDayIndex)
      this.setState({ selectedSessionValue: sessionOptions[0].options[0] })
    }
  }

  componentWillUnmount() {
    const { onUnselectSchedule, onLeaveSocketRoom, selectedSchedule } = this.props
    if (selectedSchedule) {
      const id = selectedSchedule._id
      onUnselectSchedule()
      onLeaveSocketRoom({
        room: id,
        type: "calendar",
      })
    }
  }

  updateDayIndex = openingHours => {
    const today = moment
      .utc()
      .set({ hour: "00", minute: "00", second: "00" })
      .unix()
    const index = openingHours.findIndex(day => day.date === today)
    if (index !== -1) {
      this.setState({ selectedDayIndex: index })
    }
  }

  onDeleteBooking = (booking, optionsCancelBooking) => {
    const { onDeleteBooking, user } = this.props
    onDeleteBooking({ bookingEventId: booking._id, loggedStaffId: user._id, options: optionsCancelBooking })
  }

  onBookingClick = (booking, e) => {
    const { onSelectBooking } = this.props
    if (e === "userHasAccess" || !e.target.classList.contains("disabled")) {
      onSelectBooking(booking)
    }
    this.setState({ showEditModal: true })
  }

  onWeeklyBookingClick = (booking, e) => {
    const { onSelectBooking, selectedSession } = this.props
    const selectedDayIndex = selectedSession.openingHours.findIndex(openingHour => openingHour.date === booking.dayDate)
    if (e === "userHasAccess" || !e.target.classList.contains("disabled")) {
      onSelectBooking(booking)
    }
    this.setState({ showEditModal: true, selectedDayIndex })
  }

  goToReport = (link, e) => {
    if (e === "userHasAccess" || !e.target.classList.contains("disabled")) {
      this.props.history.push(link)
    }
  }

  onSlotClick = ({ tableIndex, roomName, timeStamp, type }, weekDayIndex) => {
    const { selectedSchedule, onReserveBookingSlot, selectedSession } = this.props
    const { selectedDayIndex } = this.state
    const dayIndex = weekDayIndex || selectedDayIndex

    const day = moment
      .utc(timeStamp, "X")
      .set({ hour: 0, minute: 0 })
      .unix()
    const start = moment.utc(timeStamp, "X")
    const end = start.add(30, "m").unix()
    const bookingData = {
      sessionId: selectedSession._id,
      dayDate: day,
      room: roomName,
      tableIndex,
      startTime: timeStamp,
      endTime: end,
      meetingType: "buyingAppointment",
      language: "en",
      clients: [],
      meetingResp: [],
      host: "reservedForHost",
      status: "reserved",
      scheduleId: selectedSchedule._id,
      brandId: selectedSession.brand,
    }
    if (type !== "joker") {
      onReserveBookingSlot(selectedSchedule._id, {
        roomName,
        dayDate: day.date,
        tableIndex,
        bookingData,
      })
    } else {
      onReserveBookingSlot(selectedSchedule._id, {
        roomName,
        dayDate: day.date,
        tableIndex,
        bookingData: { ...bookingData, type: "joker" },
      })
    }
    this.setState({ selectedDayIndex: dayIndex, showNewBookingModal: true })
  }

  onCloseNewBookModal = () => {
    const { selectedBooking, user, onDeleteBooking } = this.props

    this.setState({ showNewBookingModal: false })
    if (selectedBooking.status === "reserved") {
      onDeleteBooking({
        bookingEventId: selectedBooking._id,
        loggedStaffId: user._id,
        sendBuyerCancellationEmail: false,
      })
    }
  }

  deboncedCloseNewBookModal = debounce(this.onCloseNewBookModal, 1000, {
    leading: true,
    trailing: false,
  })

  onCloseEditBookModal = () => {
    this.setState({ showEditModal: false })
    this.props.onUnselectBooking()
  }

  onCalendarTypeChange = calendarType => {
    this.setState({ calendarType })
  }

  onStaffSelect = staffMember => {
    this.setState({ staffMember })
  }

  onRoomChange = selectedRoom => {
    this.setState({ selectedRoom })
  }

  onSelectTab = selectedTab => {
    this.setState({ selectedTab })
  }

  onSessionChange = session => {
    this.props.history.replace(`/calendars?sessionId=${session.value}`)
    this.props.onFetchSchedule(session.value, this.updateDayIndex)
    this.setState({
      selectedDayIndex: 0,
      firstDayofRange: true,
      firstDayDate: null,
      lastDayofRange: false,
      firstDayofRandIndex: 0,
      selectedSessionValue: session,
    })
  }

  onSlotEnter = e => {
    const { width, height, x: left, y: top } = e.target.getBoundingClientRect()
    this.setState({
      placeholderStyle: {
        width,
        height: height * 2,
        top: top + window.pageYOffset,
        left: left + window.window.pageXOffset,
      },
    })
  }

  // eslint-disable-next-line no-empty-function
  onScheduleLeave = () => {}

  onPrevClick = () => {
    const { selectedDayIndex } = this.state

    if (selectedDayIndex - 1 >= 0) {
      this.setState({ selectedDayIndex: selectedDayIndex - 1 })
    }
  }

  onSetDayIndex = dayIndex => {
    this.setState({ selectedDayIndex: dayIndex })
  }

  onRangeChange = dir => {
    const { selectedSession } = this.props
    let { firstDayofRange, firstDayDate, lastDayofRange, firstDayofRandIndex } = this.state
    const firstDay = (firstDayDate && moment.utc(firstDayDate, "X")) || moment.utc(selectedSession.openingDay, "X")
    const lastDay = moment.utc(selectedSession.closingDay, "X")

    if (dir === "plus") {
      firstDay.add(7, "days")
      firstDayofRandIndex += 7
      if (firstDay.unix() > lastDay.unix()) {
        lastDayofRange = true
      } else {
        lastDayofRange = false
      }
      firstDayofRange = false
    } else if (dir === "minus") {
      firstDay.add(-7, "days")
      firstDayofRandIndex -= 7
      if (firstDay.unix() <= selectedSession.openingDay) {
        firstDayofRange = true
      } else {
        firstDayDate = false
      }
      lastDayofRange = false
    }

    this.setState({
      firstDayDate: firstDay.unix(),
      lastDayofRange,
      firstDayofRange,
      firstDayofRandIndex,
    })
  }

  onNextClick = () => {
    const { selectedDayIndex } = this.state
    const { selectedSchedule } = this.props
    if (selectedDayIndex + 1 < selectedSchedule.schedule.length) {
      this.setState({ selectedDayIndex: selectedDayIndex + 1 })
    }
  }

  renderHours = ({ from, to }) => {
    let start = moment.utc(from, "X").hour()
    let startMinutes = moment.utc(from, "X").minutes()
    startMinutes = startMinutes === 0 ? "00" : startMinutes
    const duration = moment.duration(moment.utc(to, "X").diff(moment.utc(from, "X")))
    const diffHour = (duration._data.hours * 60 + duration._data.minutes) / 60
    const diff = Math.ceil(diffHour)
    const isHalfHour = !((diffHour ^ 0) === diffHour)
    start--
    return times(diff * 2, idx => {
      if (isHalfHour && idx === diff * 2 - 1) {
        return null
      }
      if ((idx + 1) % 2 !== 0) {
        start++
        return <Hour key={idx}>{`${start}:${startMinutes}`}</Hour>
      }
      return <Hour key={idx} />
    })
  }

  renderRangeDays = () => {
    const { selectedSession } = this.props
    const { firstDayofRange, firstDayDate, lastDayofRange } = this.state
    const firstDay = (firstDayDate && moment.utc(firstDayDate, "X")) || moment.utc(selectedSession.openingDay, "X")
    let lastDay = moment.utc(selectedSession.closingDay, "X")
    const diff = lastDay.diff(firstDay, "days")
    let rangeDays

    if (diff > 6) {
      lastDay = firstDay.clone().add(6, "days")
      const diffYears = lastDay.year() - firstDay.year()
      if (diffYears) {
        rangeDays = `${firstDay.format("ddd, Do MMM YYYY")} - ${lastDay.format("ddd, Do, MMM YYYY")}`
      } else {
        rangeDays = `${firstDay.format("ddd, Do MMM")} - ${lastDay.format("ddd, Do, MMM")}`
      }
    } else {
      rangeDays = `${firstDay.format("ddd, Do MMM")} - ${lastDay.format("ddd, Do MMM YYYY")}`
    }

    return (
      <DateContainer>
        <Icon hidden={firstDayofRange} src={ArrowIcon} onClick={() => this.onRangeChange("minus")} />
        <Date>{rangeDays}</Date>
        <Icon
          hidden={lastDayofRange || diff < 6}
          rotate="true"
          src={ArrowIcon}
          onClick={() => this.onRangeChange("plus")}
        />
      </DateContainer>
    )
  }

  render() {
    const {
      sessions,
      selectedSession,
      selectedSchedule,
      selectedBooking,
      staff,
      clients,
      retailers,
      user,
      attendanceSchedule,
      scheduleRequestStatus,
      staffsRequestPending,
      clientsRequestPending,
      retailersRequestPending,
      sessionOptions,
    } = this.props
    const {
      placeholderStyle,
      calendarType,
      selectedTab,
      selectedDayIndex,
      showEditModal,
      staffMember,
      selectedRoom,
      firstDayofRandIndex,
      selectedSessionValue,
    } = this.state
    const activeSessionLength = sessionOptions[0].options.length

    if (sessions.length === 0) {
      return (
        <PageTitle title="Calendars">
          <Container>
            <NotFound image>
              <img alt="modaresa" src={noSessionImage} />
              <Link to="/sessions/new">
                <Button>Create my first Sales Session</Button>
              </Link>
            </NotFound>
          </Container>
        </PageTitle>
      )
    }

    if (
      activeSessionLength > 0 &&
      selectedSession &&
      selectedSchedule &&
      selectedSession._id !== selectedSchedule.sessionId
    ) {
      return null
    }

    let placeholderProps = {}
    if (placeholderStyle) {
      placeholderProps = placeholderStyle
    }
    const schedule = selectedSchedule && selectedSchedule.schedule
    const date = schedule && schedule[selectedDayIndex] && moment.utc(schedule[selectedDayIndex].date, "X")
    const openingHours = selectedSession && selectedSession.openingHours[selectedDayIndex]
    const userRole = user.modaresaRole
    const isStaffManagerOrMore = isStaffAtLeastManager(userRole)

    const isLoading = scheduleRequestStatus || staffsRequestPending || clientsRequestPending || retailersRequestPending

    return (
      <PageTitle title="Calendars">
        <CalendarsProvider>
          <Container>
            {isLoading && (
              <Loader style={{ zIndex: 2 }}>
                <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
              </Loader>
            )}
            {!isLoading && (
              <>
                <CalendarHeader>
                  <Flex>
                    {userRole !== "freelancer" && (
                      <div style={{ width: 200, marginRight: "10px" }}>
                        <Select
                          isDisabled={!selectedSession}
                          options={calendarTypes}
                          placeholder="Select Type"
                          value={calendarType}
                          onChange={this.onCalendarTypeChange}
                        />
                      </div>
                    )}
                    {calendarType.value === "staff" && userRole !== "freelancer" && (
                      <React.Fragment>
                        <div style={{ width: 200, marginRight: "10px" }}>
                          <Select
                            isDisabled={!selectedSession}
                            options={
                              selectedSession
                                ? [{ value: "all", label: "All Staff" }].concat(
                                    selectedSession.staff.reduce((staffOpts, member) => {
                                      const staffMember = staff.byId[member.staffId]
                                      if (staffMember) {
                                        staffOpts.push({
                                          value: staffMember._id,
                                          label: `${staffMember.firstName} ${staffMember.lastName}`,
                                        })
                                      }
                                      return staffOpts
                                    }, []),
                                  )
                                : []
                            }
                            placeholder="Select Staff"
                            value={staffMember}
                            onChange={this.onStaffSelect}
                          />
                        </div>
                        <div style={{ width: 200 }}>
                          {staffMember.value === "all" && (
                            <Select
                              isDisabled={selectedSession.rooms.length < 2}
                              options={
                                selectedSession
                                  ? [{ value: "all", label: "All Rooms" }].concat(
                                      selectedSession.rooms.map(room => ({
                                        value: room._id,
                                        label: room.name,
                                      })),
                                    )
                                  : []
                              }
                              placeholder="Select Room"
                              value={selectedRoom}
                              onChange={this.onRoomChange}
                            />
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </Flex>
                  {(calendarType.value === "daily" ||
                    (calendarType.value === "staff" && staffMember.value === "all")) && (
                    <DateContainer>
                      {date && (
                        <React.Fragment>
                          <Icon hidden={selectedDayIndex === 0} src={ArrowIcon} onClick={this.onPrevClick} />
                          <Date>{date.format("dddd Do, MMMM")}</Date>
                          <Icon
                            hidden={selectedDayIndex === selectedSchedule.schedule.length - 1}
                            rotate="true"
                            src={ArrowIcon}
                            onClick={this.onNextClick}
                          />
                        </React.Fragment>
                      )}
                    </DateContainer>
                  )}
                  {calendarType.value === "weekly" && this.renderRangeDays()}
                  <Flex justify="flex-end">
                    <div style={{ width: 200 }}>
                      <Select
                        options={sessionOptions}
                        placeholder="Select Session"
                        value={selectedSessionValue}
                        onChange={this.onSessionChange}
                      />
                    </div>
                  </Flex>
                </CalendarHeader>
                {activeSessionLength === 0 && !selectedSession && (
                  <div style={{ height: "calc(100vh - 114px)" }}>
                    <NotFound>
                      <span>NO ACTIVE SALE SESSION</span>
                    </NotFound>
                  </div>
                )}
                {!isLoading && selectedSchedule && selectedSession && calendarType.value === "daily" && (
                  <React.Fragment>
                    <CalendarTabs rooms={selectedSession.rooms} selectedTab={selectedTab} onSelect={this.onSelectTab} />
                    <CalendarContent>
                      <Flex
                        align="stretch"
                        justify="flex-start"
                        style={{
                          flex: 1,
                          maxWidth: "100%",
                          overflowX: "auto",
                        }}
                      >
                        <Timeline>{this.renderHours(openingHours)}</Timeline>
                        {selectedTab.type === "master" && (
                          <MasterCalendar
                            attendanceSchedule={attendanceSchedule}
                            goDay={this.onSetDayIndex}
                            openingHours={openingHours}
                            schedule={selectedSchedule.schedule[selectedDayIndex]}
                            relouSchedule={selectedSchedule.jokerSchedule[selectedDayIndex]}
                            clients={clients}
                            retailers={retailers}
                            selectedDayIndex={selectedDayIndex}
                            selectedSession={selectedSession}
                            onBookingClick={this.onBookingClick}
                            onScheduleLeave={this.onScheduleLeave}
                            onSlotClick={this.onSlotClick}
                            onSlotEnter={this.onSlotEnter}
                            canEditBooking={isStaffManagerOrMore}
                          />
                        )}
                        {selectedTab.type === "joker" && (
                          <RelouCalendar
                            attendanceSchedule={attendanceSchedule}
                            goDay={this.onSetDayIndex}
                            openingHours={openingHours}
                            schedule={selectedSchedule.jokerSchedule[selectedDayIndex]}
                            clients={clients}
                            retailers={retailers}
                            selectedDayIndex={selectedDayIndex}
                            selectedSession={selectedSession}
                            onBookingClick={this.onBookingClick}
                            onScheduleLeave={this.onScheduleLeave}
                            onSlotClick={this.onSlotClick}
                            onSlotEnter={this.onSlotEnter}
                            canEditBooking={isStaffManagerOrMore}
                          />
                        )}
                        {selectedTab.type === "custom" && (
                          <RoomCalendar
                            meetingLength={
                              selectedSession.rooms.find(room => room.name === selectedTab.label).structure[
                                selectedDayIndex
                              ].length
                            }
                            openingHours={openingHours}
                            room={this.props.selectedSchedule.schedule[selectedDayIndex].daySchedule.find(
                              room => room.roomName === selectedTab.label,
                            )}
                            onBookingClick={this.onBookingClick}
                            onScheduleLeave={this.onScheduleLeave}
                            onSlotClick={this.onSlotClick}
                            onSlotEnter={this.onSlotEnter}
                            canEditBooking={isStaffManagerOrMore}
                          />
                        )}
                        <Placeholder
                          ref={ref => {
                            this.placeholder = ref
                          }}
                          {...placeholderProps}
                        />
                      </Flex>
                    </CalendarContent>
                  </React.Fragment>
                )}
                {!isLoading && selectedSchedule && selectedSession && calendarType.value === "weekly" && (
                  <React.Fragment>
                    <CalendarTabs rooms={selectedSession.rooms} selectedTab={selectedTab} onSelect={this.onSelectTab} />
                    <CalendarContent>
                      <WeeklyCalendar
                        firstDayofRandIndex={firstDayofRandIndex}
                        openingHours={openingHours}
                        schedule={
                          selectedTab.type === "joker" ? selectedSchedule.jokerSchedule : selectedSchedule.schedule
                        }
                        selectedDayIndex={selectedDayIndex}
                        selectedRoom={selectedTab}
                        selectedSchedule={selectedSchedule}
                        selectedSession={selectedSession}
                        onBookingClick={this.onWeeklyBookingClick}
                        onScheduleLeave={this.onScheduleLeave}
                        onSlotClick={this.onSlotClick}
                        onSlotEnter={this.onSlotEnter}
                        canEditBooking={isStaffManagerOrMore}
                      />
                      <Placeholder
                        ref={ref => {
                          this.placeholder = ref
                        }}
                        {...placeholderProps}
                      />
                    </CalendarContent>
                  </React.Fragment>
                )}
                {!isLoading && selectedSchedule && selectedSession && calendarType.value === "staff" && (
                  <React.Fragment>
                    <CalendarContent>
                      <StaffCalendar
                        allocatedStaff={selectedSession && selectedSession.staff}
                        openingHours={openingHours}
                        room={selectedRoom}
                        selectedDay={selectedSchedule.schedule[selectedDayIndex].date}
                        selectedDayIndex={selectedDayIndex}
                        selectedStaff={staffMember}
                        sessionSchedule={selectedSession.openingHours}
                        staff={staff}
                        onBookingClick={this.goToReport}
                        canEditBooking={isStaffManagerOrMore}
                      />
                    </CalendarContent>
                  </React.Fragment>
                )}
                {selectedBooking && selectedBooking.status === "reserved" && (
                  <NewBookingModal
                    booking={selectedBooking}
                    isOpen={!!selectedBooking}
                    openingHours={openingHours}
                    scheduleId={selectedSchedule._id}
                    session={selectedSession}
                    onRequestClose={this.deboncedCloseNewBookModal}
                  />
                )}
                {showEditModal && selectedBooking && selectedBooking.status === "booked" && (
                  <EditBookingModal
                    user={user}
                    attendanceSchedule={attendanceSchedule}
                    booking={selectedBooking}
                    isOpen={showEditModal}
                    openingHours={openingHours}
                    scheduleId={selectedSchedule._id}
                    sessionId={selectedSession._id}
                    onDeleteBooking={this.onDeleteBooking}
                    onRequestClose={this.onCloseEditBookModal}
                  />
                )}
                {selectedSession && isStaffManagerOrMore && (
                  <RelouRequests salesSessionId={selectedSession._id} brandId={selectedSession.brand} />
                )}
              </>
            )}
          </Container>
        </CalendarsProvider>
      </PageTitle>
    )
  }
}

Calendars.propTypes = {
  attendanceSchedule: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  scheduleRequestStatus: PropTypes.bool,
  selectedBooking: PropTypes.object,
  selectedSchedule: PropTypes.object,
  selectedSession: PropTypes.object,
  sessionOptions: PropTypes.array,
  sessions: PropTypes.array,
  staff: PropTypes.object,
  user: PropTypes.object,
  staffsRequestPending: PropTypes.bool,
  clientsRequestPending: PropTypes.bool,
  retailersRequestPending: PropTypes.bool,
  onDeleteBooking: PropTypes.func,
  onFetchSchedule: PropTypes.func,
  onFetchStaffs: PropTypes.func,
  onFetchClients: PropTypes.func,
  onFetchRetailers: PropTypes.func,
  onLeaveSocketRoom: PropTypes.func,
  onReserveBookingSlot: PropTypes.func,
  onReserveJokerBookingSlot: PropTypes.func,
  onSelectBooking: PropTypes.func,
  onUnselectBooking: PropTypes.func,
  onUnselectSchedule: PropTypes.func,
}

export default Calendars

import { useField } from "formik"
import React, { useCallback, memo } from "react"
import styled from "styled-components"

import BrandOfficePicker from "../../../../elements/BrandOfficePicker/BrandOfficePicker"
import FormFieldLayout from "../../../../elements/FormFieldLayout/FormFieldLayout"

const ContainerSelect = styled.div`
  width: ${p => p.width || "200px"};
`

type Props = {
  brandId: string,
  labelWidth: string,
  options: [any],
  selectWidth: string,
  disabled: boolean,
}

const FieldOffices = ({ brandId, labelWidth, selectWidth, disabled }: Props) => {
  const [field, meta, helpers] = useField("office")
  const handleChangeSelect = useCallback(value => helpers.setValue(value), [helpers])
  return (
    <FormFieldLayout
      label="Office*"
      labelWidth={labelWidth}
      name="office"
      direction="row"
      error={meta.touched && meta.error}
    >
      <ContainerSelect width={selectWidth}>
        <BrandOfficePicker
          brandId={brandId}
          value={field.value}
          onChange={handleChangeSelect}
          onBlur={field.onBlur}
          disabled={disabled}
        />
      </ContainerSelect>
    </FormFieldLayout>
  )
}

export default memo(FieldOffices)

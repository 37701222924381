import { arrayMosesify } from "../utils"

const priorityFields = ["retailers", "cities", "counties", "regions", "countries", "continents"]

const isRetailerLocationInTerritories = (territories, retailer, territoryField, addressField) =>
  territories[territoryField]?.indexOf(retailer.storeAddress[addressField]) > -1

const isRetailerLocationInTerritoriesPlaces = (territories, retailer, territoryField, addressField) =>
  territories[territoryField] &&
  territories[territoryField].some(
    place => place.name === retailer.storeAddress[addressField] && place.country === retailer.storeAddress.country,
  )

export const doesTerritoryMatchRetailer = {
  retailers: (territories, retailer) =>
    territories.retailers?.find(territoryRetailer => territoryRetailer.id === retailer._id),
  cities: (territories, retailer) => isRetailerLocationInTerritoriesPlaces(territories, retailer, "cities", "city"),
  counties: (territories, retailer) =>
    isRetailerLocationInTerritoriesPlaces(territories, retailer, "counties", "county"),
  regions: (territories, retailer) => isRetailerLocationInTerritoriesPlaces(territories, retailer, "regions", "region"),
  countries: (territories, retailer) => isRetailerLocationInTerritories(territories, retailer, "countries", "country"),
  continents: (territories, retailer) =>
    isRetailerLocationInTerritories(territories, retailer, "continents", "continent"),
}

export const isRetailerFoundInTerritories = (territories, retailer) =>
  !!priorityFields.find(field => doesTerritoryMatchRetailer[field](territories, retailer))

const searchRetailerInAreas = (areas, retailer) => {
  for (const field of priorityFields) {
    const foundArea = areas.find(area => doesTerritoryMatchRetailer[field](area.territories, retailer))
    if (foundArea) return foundArea
  }
  return null
}

const findSessionAreaFromRetailer = (session = {}, retailer = {}, roomIdToSearch) => {
  const { toggleRoomBasedAreas } = session
  if (!retailer.storeAddress) return null
  if (toggleRoomBasedAreas) {
    const foundRoom = session.rooms.find(room => room._id === roomIdToSearch)
    return foundRoom && searchRetailerInAreas(foundRoom.areas, retailer)
  }
  return searchRetailerInAreas(session.areas, retailer)
}

export default findSessionAreaFromRetailer

export const searchRetailerInAreasFilteredByTerritoryPrecision = (areas, retailer) => {
  for (const field of priorityFields) {
    const foundAreas = areas.filter(area => doesTerritoryMatchRetailer[field](area.territories, retailer))
    if (foundAreas.length) {
      const sortedAreas = foundAreas.sort((a, b) => (a[field].length > b[field].length ? 1 : -1))
      return sortedAreas[0]
    }
  }
  return null
}

export const orderAreasByRetailerTerritoryPrecision = (areas, retailer) => {
  const areasOrdered = []
  let areasUnordered = [...areas]
  for (const field of priorityFields) {
    const [match, noMatch] = arrayMosesify(areasUnordered, area =>
      doesTerritoryMatchRetailer[field](area.territories, retailer),
    )
    if (match.length) {
      match.sort((a, b) => (a[field].length > b[field].length ? 1 : -1)).forEach(area => areasOrdered.push(area))
    }
    areasUnordered = noMatch
  }
  return areasOrdered
}

import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import {
  confirmBooking,
  fetchAvailableClients,
  fetchAvailableStaff,
  updateBookingTime,
} from "../../../../../store/actions/schedules"
import {
  getAvailableClientsFetchingStatus,
  getAvailableStaffFetchingStatus,
  getBookingAvailableClients,
  getBookingAvailableHosts,
  getBookingAvailableStaff,
  getBookingOtherHosts,
  getRetailers,
} from "../../../../../store/selectors"

import Component from "./NewBookingModal"

const selector = createStructuredSelector({
  retailers: getRetailers,
  availableClients: getBookingAvailableClients,
  availableHosts: getBookingAvailableHosts,
  availableStaff: getBookingAvailableStaff,
  otherHosts: getBookingOtherHosts,
  isFetchingClients: getAvailableClientsFetchingStatus,
  isFetchingMeetingStaff: getAvailableStaffFetchingStatus,
})

const actions = {
  onUpdateTime: updateBookingTime,
  onConfirmBooking: confirmBooking,
  onFetchAvailableClients: fetchAvailableClients,
  onFetchAvailableStaff: fetchAvailableStaff,
}

export const NewBookingModal = connect(selector, actions)(Component)

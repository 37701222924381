export class Address {
  constructor(formattedAddress = "", city = "", country = "", state = "", zip = "", street = "", streetNumber = "") {
    this.formattedAddress = formattedAddress
    this.city = city
    this.country = country
    this.state = state
    this.zip = zip
    this.street = street
    this.streetNumber = streetNumber
  }
}

export const EmptyAddress = new Address()

import styled from "styled-components"

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

export const Date = styled.div`
  flex: 1;
`

export const ContainerRange = styled.div`
  display: inline-flex;
  align-items: center;
  width: 350px;
  padding-left: 16px;
`
export const ContainerDateSelect = styled.div`
  flex: 1;
`
export const To = styled.div`
  width: 48px;
  text-align: center;
`

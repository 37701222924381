import styled from "styled-components"
import { Tabs } from "modaresa-commons"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255);
  }

  & .dropdown__content {
    top: calc (100% + 20px);
    background: #fff;
    width: 200px;
    padding: 20px;
    border: 1px solid #f5f5f5;

    & button {
      height: 25px;
      margin-top: 10px;
      padding: 0 15px;
    }
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-left: 45px;
  padding-right: 25px;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.primary};
  padding: 15px 30px;
`
export const FormGroup = styled.div`
  &:not(:nth-child(1)) {
    margin-top: 20px;
  }
`
export const FormRow = styled.div`
  display: flex;
  width: 50%;
  min-width: 500px;
  align-items: center;
  margin-bottom: 17px;
  font-size: 15px;
  min-height: 40px;
  color: ${props => props.theme.textBlack};

  & > span {
    min-width: 140px;
  }

  & .BuyerMultiselect {
    min-width: 200px;
  }

  & .react-tel-input .selected-flag .arrow {
    display: none;
  }
`
export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
`

export const Divinder = styled.div`
  font-weight: 600;
  border-bottom: 2px solid ${props => props.theme.veryLightGray};
  margin-bottom: 15px;
  margin-top: 20px;
  padding-bottom: 2px;
`
export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  font-size: 30px;
  padding: 32px;
`
export const Table = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const LoaderContainer = styled.div`
  height: 100%;
  position: relative;
`

export const TableBody = styled.div`
  height: 100%;
`

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  background-color: #f7f7f7;
  padding-left: 30px;
  padding-right: 30px;
  position: sticky;
  top: 0;
  z-index: 2;

  & > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }
`

export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "25px"};
  width: auto;
  margin: 0 auto;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  border-bottom: 1px solid #efebeb;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;

  & > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }

  & ${Icon} {
    opacity: 0.5;
  }

  &:hover {
    background: #f7f7f7;

    & ${Icon} {
      opacity: 1;
    }
  }
`

export const MeetingStaff = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  width: 300px;
  border-radius: 3px;
  padding: 20px 30px 20px 20px;
  margin-top: 20px;
  margin-right: 20px;
`
export const Bold = styled.div`
  font-weight: bold;
`
export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255);
`

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const ButtonsRow = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`
export const CloseModal = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.strawberry};
`

export const Text = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`
export const ModalLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`
export const Deadline = styled.div`
  margin-top: 10px;
  color: #666dc6;
`

export const FullHeightTabs = styled(Tabs)`
  height: 100%;
`

export const BuyerIsArchived = styled.div`
  color: ${p => p.theme.main};
  padding: 30px 30px 0 30px;
`

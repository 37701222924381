import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import times from "lodash/times"

import Booking from "../Booking"
import { Book, Booklot, Table, Timeslot } from "../../styles"

const FIFTEENMINUTES = 900

class Schedule extends Component {
  onSlotClick = ({ tableIndex, roomName, timeStamp, type }, dayIndex) => {
    const { onSlotClick } = this.props
    onSlotClick({ tableIndex, roomName, timeStamp, type }, dayIndex)
  }

  renderRoom = () => {
    const { sessionSchedule, range, room, roomType, hasRelous } = this.props
    const fromStamp = sessionSchedule.from
    const toStamp = sessionSchedule.to
    const start = moment.utc(fromStamp, "X")
    const end = moment.utc(toStamp, "X")
    const startRange = moment.utc(range.from, "X").set({ year: start.year(), month: start.month(), date: start.date() })
    const endRange = moment.utc(range.to, "X").set({ year: end.year(), month: end.month(), date: end.date() })
    const startTimeStamp = startRange.unix()

    const diff = endRange.diff(startRange, "hours", true)

    return room.tables.map((table, idx, arr) => {
      let bookings
      if (table.length > 0) {
        table.forEach(booking => {
          bookings = { ...bookings, [booking.startTime]: booking }
        })
      }
      return (
        <Table key={idx} last={idx === arr.length - 1} hasRelous={hasRelous}>
          {this.renderTable(
            bookings,
            idx,
            room.roomName,
            {
              diff,
              fromStamp,
              startTimeStamp,
              toStamp,
              endRange,
            },
            roomType,
          )}
        </Table>
      )
    })
  }

  renderTable = (bookings, tableIndex, roomName, { diff, fromStamp, startTimeStamp, toStamp, endRange }, type) => {
    const { dayIndex, canEditBooking } = this.props
    let parts = diff * 4
    let itemIndex = 0
    return times(diff * 4, idx => {
      if (parts <= 0) return null
      if (fromStamp > startTimeStamp || toStamp === startTimeStamp) {
        let diff
        if (fromStamp - startTimeStamp > 0) {
          diff = fromStamp - startTimeStamp
        } else {
          diff = endRange.unix() - startTimeStamp
        }
        const flex = diff / FIFTEENMINUTES
        parts -= flex
        startTimeStamp += diff
        itemIndex += flex
        return <Booklot blocked="true" flex={flex} key={idx + Date.now()} />
      }
      if (bookings) {
        const element = bookings[startTimeStamp]
        if (element) {
          const newStart = moment.utc(element.startTime, "X").format("H:mm")
          const end = moment.utc(element.endTime, "X").format("H:mm")
          const newTime = moment
            .utc(newStart, "HH:mm")
            .add(element.delayTime, "minutes")
            .format("HH:mm")
          let top = 0
          if (
            element.appointmentStatus === "delayed" &&
            element.delayTime % 30 === 0 &&
            this.props.meetingLength > 30 &&
            moment.utc(newTime, "HH:mm").isBefore(moment.utc(end, "HH:mm"))
          ) {
            const newtime = moment
              .utc(newStart, "HH:mm")
              .add(element.delayTime, "minutes")
              .unix()
            const newend = moment.utc(end, "HH:mm").unix()
            diff = newend - newtime
            top = element.delayTime / 30
            const array = []
            for (let i = 0; i < top; i++) {
              array.push(i)
            }
            const flex = diff / FIFTEENMINUTES
            parts -= flex + top
            const timeStampUnit = startTimeStamp
            startTimeStamp += diff
            const indexEmpty = flex + 1
            itemIndex += flex + top

            return (
              <React.Fragment key={idx + Date.now()}>
                {array.map(n => {
                  const timeStamp = timeStampUnit + n * FIFTEENMINUTES
                  const itemEmptyIndex = indexEmpty + n
                  idx = itemEmptyIndex + Date.now()
                  return (
                    <Timeslot
                      type={type}
                      even={itemEmptyIndex % 2 === 0}
                      key={idx}
                      onClick={event => this.onSlotClick({ event, tableIndex, roomName, timeStamp, type }, dayIndex)}
                    >
                      <Book>Book</Book>
                    </Timeslot>
                  )
                })}
                <Booklot flex={flex} key={idx + Date.now()}>
                  <Booking
                    booking={element}
                    position="relative"
                    onClick={e => this.props.onBookingClick(element, e)}
                    canEdit={canEditBooking}
                  />
                </Booklot>
              </React.Fragment>
            )
          }
          diff = element.endTime - element.startTime
          const flex = diff / FIFTEENMINUTES
          parts -= flex
          startTimeStamp += diff
          itemIndex += flex

          return (
            <Booklot flex={flex} key={idx + Date.now()}>
              <Booking
                booking={element}
                position="relative"
                onClick={e => this.props.onBookingClick(element, e)}
                canEdit={canEditBooking}
              />
            </Booklot>
          )
        }
        if (moment.utc(startTimeStamp).minutes() >= 0) startTimeStamp += FIFTEENMINUTES
        parts -= 1
        itemIndex++
        const timeStamp = startTimeStamp - FIFTEENMINUTES
        return (
          <Timeslot
            type={type}
            even={itemIndex % 4 === 0}
            key={idx + Date.now()}
            onClick={event => this.onSlotClick({ event, tableIndex, roomName, timeStamp, type }, dayIndex)}
          >
            <Book>Book</Book>
          </Timeslot>
        )
      }
      if (moment.utc(startTimeStamp).minutes() >= 0) startTimeStamp += FIFTEENMINUTES
      parts -= 1
      itemIndex++
      const timeStamp = startTimeStamp - FIFTEENMINUTES
      return (
        <Timeslot
          type={type}
          even={itemIndex % 4 === 0}
          key={idx + Date.now()}
          onClick={event => this.onSlotClick({ event, tableIndex, roomName, timeStamp, type }, dayIndex)}
        >
          <Book>Book</Book>
        </Timeslot>
      )
    })
  }

  render() {
    return this.renderRoom()
  }
}

Schedule.propTypes = {
  dayIndex: PropTypes.number,
  meetingLength: PropTypes.number,
  range: PropTypes.object,
  room: PropTypes.object,
  sessionSchedule: PropTypes.object,
  onBookingClick: PropTypes.func,
  onSlotClick: PropTypes.func,
}

export { Schedule }

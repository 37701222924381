export const login = async ({ token, email, client }) => {
  localStorage.setItem("modaresa-access-token", token)
  if (email !== undefined) {
    localStorage.setItem("remember-email", email || null)
  }
  if (client) {
    await client.resetStore()
  }
}

export const logout = async client => {
  localStorage.removeItem("modaresa-access-token")
  await client.clearStore()
  await client.cache.reset()
}

export const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split(".")[1]))
  } catch (e) {
    return null
  }
}

import styled from "styled-components"

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 54px;
  padding-left: 30px;
  padding-right: 30px;
  color: ${props => props.theme.textBlack};
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`
export const FormRow = styled.div`
  display: flex;
  width: 50%;
  max-width: 600px;
  min-width: 500px;
  align-items: center;
  margin-bottom: 17px;
  font-size: 15px;
  min-height: 40px;
  color: ${props => props.theme.textBlack};

  & > span {
    min-width: 140px;
    max-width: 140px;
  }

  & .form-control {
    width: 100% !important;
    height: 40px !important;
  }

  & .react-tel-input .selected-flag .arrow {
    display: none;
  }

  & .PhoneError {
    border-color: ${props => props.theme.strawberry};
  }
`
export const Divinder = styled.div`
  font-weight: 600;
  border-bottom: 2px solid ${props => props.theme.veryLightGray};
  margin-bottom: 15px;
  margin-top: 30px;
  padding-bottom: 2px;
`
export const InputImageLabel = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 200px;
  background: #f8f8f8;
  border: 2px dashed ${props => props.noborder || "#7d7d7d"};
  cursor: pointer;
  color: #999;
  font-size: 14px;
  margin-right: 30px;
  margin-top: 15px;

  &:hover .control-container {
    opacity: 1;
    transition: transform 0.5s ease;
  }
`

export const ImageContainer = styled.div`
  position: absolute;
  background: #f8f8f8;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
`

export const ControlBlock = styled.div`
  position: absolute;
  background: rgba(40, 40, 40, 0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  opacity: 0;

  &.hidden {
    visibility: hidden;
  }
`
export const ControlButton = styled.span`
  display: inline-block;
  height: 30px;
  min-width: 60px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  line-height: 30px;
  background: #282828;
  border-radius: 15px;
  color: #fff;

  &:hover {
    background: #fff;
    color: #282828;
  }
`

export const InputImage = styled.input`
  display: none !important;
`

export const stopPropagation = e => {
  e.preventDefault()
  e.stopPropagation()
}

export const removeArrayDuplicateByProperty = (arr, property) =>
  arr.filter((v, index, a) => a.findIndex(t => t[property] === v[property]) === index)

export const mapMongoIdsToObjectIds = obj => obj.map(({ _id: id, ...obj }) => ({ ...obj, id }))

export const isUndefined = variable => variable === undefined

export const mapRetailersToGeo = (geographicResponsibility, retailers) => {
  if (!geographicResponsibility) return null
  const newGeographicResponsibility = { ...geographicResponsibility }
  if (geographicResponsibility.territories?.retailers) {
    newGeographicResponsibility.territories.retailers = newGeographicResponsibility.territories.retailers.reduce(
      (geoRetailers, geoRetailerId) => {
        const foundRetailer = retailers.byId[geoRetailerId]
        if (foundRetailer) {
          geoRetailers.push({ id: foundRetailer._id, name: foundRetailer.name })
        }
        return geoRetailers
      },
      [],
    )
  }
  return newGeographicResponsibility
}

export const pad = (n, width, z) => {
  z = z || "0"
  n += ""
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const arrayMosesify = (srcArray, filterFunction) =>
  srcArray.reduce(
    (doesMatch, element) => {
      const [match, noMatch] = doesMatch
      if (filterFunction(element)) {
        match.push(element)
      } else {
        noMatch.push(element)
      }
      return doesMatch
    },
    [[], []],
  )

export const countObjKeysDefined = obj => {
  let count = 0
  for (const key in obj) {
    if (obj[key]) count++
  }
  return count
}

export const getTooltipCoordinatesFromEvent = event => {
  const containerCoords = event.target.getBoundingClientRect()
  const windowHeight = window.innerHeight
  const windowWidth = window.innerWidth
  const scrollTop = document.documentElement.scrollTop
  const tooltipCoords = {}
  if (windowWidth - containerCoords.right + containerCoords.width < 400) {
    tooltipCoords.right = "0px"
  } else {
    tooltipCoords.left = "0px"
  }

  if (windowHeight - containerCoords.bottom < 400) {
    if (containerCoords.top - Math.abs(54 + (162 - scrollTop)) > 400) {
      tooltipCoords.bottom = `100%`
    } else {
      tooltipCoords.top = `0px`
      if (windowWidth - containerCoords.right - 400 > 0) {
        delete tooltipCoords.right
        tooltipCoords.left = "100%"
      } else {
        delete tooltipCoords.left
        tooltipCoords.right = "100%"
      }
    }
  } else {
    tooltipCoords.top = `100%`
  }
  return tooltipCoords
}

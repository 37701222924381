import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
  padding-bottom: 20px;
  position: relative;
  box-sizing: border-box;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
`

export const TableContainer = styled.div`
  height: 100%;
  padding-bottom: 20px;
`

import styled from "styled-components"

export const ContainerFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const Field = styled.div`
  margin: 0 16px;
  flex: 1;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

import { Button, Flex, InputWithValidation } from "modaresa-commons"
import React, { Component } from "react"
import ReactPhoneInput from "react-phone-input-2"
import isEmpty from "lodash/isEmpty"

import {
  ControlBlock,
  ControlButton,
  Divinder,
  Form,
  FormRow,
  Image,
  ImageContainer,
  InputImage,
  InputImageLabel,
} from "./styles"

import { emailRegex } from "helpers/regex"

class Brand extends Component {
  constructor(props) {
    super(props)
    this.state = {
      values: {
        name: "",
        address: "",
        phoneNumber: "",
        contactEmail: "",
        orgNumber: "",
        directions: "",
        closestStations: "",
      },
      errors: {},
      touched: {},
      imageUrl: "",
      isChanged: false,
      isRequest: false,
    }
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "name":
      case "address":
      case "orgNumber":
        if (value.trim().length > 0) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Required`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "contactEmail":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (emailRegex.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "phoneNumber":
        if (value.trim().length < 3) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (value.trim().length < 8) {
          errors[name] = `Invalid phone number`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "directions":
      case "closestStations":
        return {
          values: { ...values, [name]: value },
        }
      default:
        break
    }
  }

  onInputChange = e => {
    if (typeof e === "string") {
      this.setState({ ...this.validate(e, "phoneNumber"), isChanged: true })
    } else {
      const name = e.target.name
      const value = e.target.value
      this.setState({ ...this.validate(value, name), isChanged: true })
    }
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  addImage = e => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.setState({ imageUrl: reader.result })
      }
    }
    e.target.value = ""
  }

  removeImage = e => {
    e.preventDefault()
    this.setState({ imageUrl: "" })
  }

  submitForm = e => {
    e.preventDefault()
    const { values, errors, touched, imageUrl } = this.state

    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    const offices = [
      {
        contactEmail: values.contactEmail,
        name: values.name,
        phoneNumber: values.phoneNumber,
        address: values.address,
        city: "city",
        country: "country",
      },
    ]

    this.setState({ touched })
    if (isEmpty(errors)) {
      this.props.onAddBrand({ ...values, imageUrl, offices })
    }
  }

  render() {
    const { errors, values, touched, imageUrl } = this.state
    return (
      <div style={{ padding: 32 }}>
        <Flex align="flex-start" justify="flex-start">
          <InputImageLabel noborder={imageUrl ? "transparent" : ""}>
            <span>Upload Image</span>
            <InputImage accept="image/*" type="file" onChange={this.addImage} />
            {imageUrl && (
              <ImageContainer>
                <Image src={imageUrl} />
              </ImageContainer>
            )}
            <ControlBlock className={imageUrl ? "control-container" : "control-container hidden"}>
              <ControlButton>Edit</ControlButton>
              <ControlButton onClick={this.removeImage}>Delete</ControlButton>
            </ControlBlock>
          </InputImageLabel>
          <div>
            <Form>
              <FormRow>
                <span>Company Name</span>
                <InputWithValidation
                  errors={errors.name}
                  name="name"
                  touched={touched.name}
                  value={values.name}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
              <FormRow>
                <span>Address (Office 1)</span>
                <InputWithValidation
                  errors={errors.address}
                  name="address"
                  touched={touched.address}
                  value={values.address}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
              <FormRow>
                <span>Phone Number</span>
                <InputWithValidation.InputContainer>
                  <ReactPhoneInput
                    buttonStyle={errors.phoneNumber && touched.phoneNumber && { borderColor: "red" }}
                    country={"fr"}
                    inputExtraProps={{
                      name: "phoneNumber",
                    }}
                    enableSearch
                    disableSearchIcon
                    searchStyle={{ width: "95%" }}
                    inputStyle={errors.phoneNumber && touched.phoneNumber && { borderColor: "red" }}
                    value={values.phoneNumber}
                    onBlur={this.onBlur}
                    onChange={this.onInputChange}
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <InputWithValidation.ValidationError>{errors.phoneNumber}</InputWithValidation.ValidationError>
                  )}
                </InputWithValidation.InputContainer>
              </FormRow>
              <FormRow>
                <span>Admin Email</span>
                <InputWithValidation
                  errors={errors.contactEmail}
                  name="contactEmail"
                  touched={touched.contactEmail}
                  value={values.contactEmail}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
              <FormRow>
                <span>Organisation Number</span>
                <InputWithValidation
                  errors={errors.orgNumber}
                  name="orgNumber"
                  touched={touched.orgNumber}
                  value={values.orgNumber}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
            </Form>
          </div>
        </Flex>
        <div>
          <Divinder>Directions to Showroom</Divinder>
          <FormRow>
            <span>Door codes, directions</span>
            <InputWithValidation
              errors={errors.directions}
              name="directions"
              touched={touched.directions}
              value={values.directions}
              width="100%"
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow>
            <span>Closest Station</span>
            <InputWithValidation
              errors={errors.closestStations}
              name="closestStations"
              touched={touched.closestStations}
              value={values.closestStations}
              width="100%"
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
        </div>
        <Button align="flex-end" margin="0 35px 35px 0" minWidth="150px" onClick={this.submitForm}>
          Add brand
        </Button>
      </div>
    )
  }
}

export { Brand }

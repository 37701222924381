import styled from "styled-components"

export const ContainerToggle = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || "space-between"};
  align-items: center;
  ${p =>
    p.showBorder
      ? `
  border-bottom: 1px solid #dedede;  
  `
      : ""}
`
export const ToggleDescription = styled.div`
  margin: 0 24px 0 0;
`

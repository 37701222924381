import React, { memo } from "react"
import styled from "styled-components"

export const Clients = styled.div`
  padding: 15px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.gray50};
  margin-bottom: 20px;
`

export const ClientItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 10px 7px;
  border-radius: 4px;
  background-color: ${props => props.theme.gray50};
  margin-bottom: 7px;

  & p {
    margin: 0;
  }
`
type Props = {
  buyers: [],
}
const BuyersList = ({ buyers }: Props) => (
  <Clients>
    <p>Attending buyers:</p>
    <div>
      {buyers.map(buyer => (
        <ClientItem key={buyer.id}>
          <p>{`${buyer.fullName}, ${buyer.position} - ${buyer.email}`}</p>
          <p>{buyer.phoneNumber}</p>
        </ClientItem>
      ))}
    </div>
  </Clients>
)

export default memo(BuyersList)

import axios from "axios"

import { REST_API_URL } from "../../config"

export const fetchJokerRequests = async ({ brandId, sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  let response
  if (sessionId) {
    response = await axios.get(`${REST_API_URL}/jokerRequests?brandId=${brandId}&sessionId=${sessionId}`, config)
  } else {
    response = await axios.get(`${REST_API_URL}/jokerRequests?brandId=${brandId}`, config)
  }

  return response.data
}

export const confirmJokerRequest = async ({ data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/jokerRequests/${data._id}/confirm`, data, config)

  return response.data
}

export const declineJokerRequest = async ({ requestId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(`${REST_API_URL}/jokerRequests/${requestId}/decline/`, config)

  return response.data
}

export const fetchJokerAvailableStaff = async ({ requestId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/jokerRequests/${requestId}/meeting_responsibles`, config)

  return response.data
}

export const fetchClientCancellation = async ({ brandId, sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  let response
  if (sessionId) {
    response = await axios.get(
      `${REST_API_URL}/dashboard/cancellations?brandId=${brandId}&sessionId=${sessionId}`,
      config,
    )
  } else {
    response = await axios.get(`${REST_API_URL}/dashboard/cancellations?brandId=${brandId}`, config)
  }

  return response.data
}

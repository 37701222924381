/* eslint-disable extra-rules/no-commented-out-code */
import { useQuery } from "@apollo/client"
import React, { useCallback, useMemo, useState } from "react"
import { Loading } from "modaresa-commons"

import { SALES_SESSION_INVITED_BUYERS } from "../../../../../graphql/queries/salesSession"
import { normalizeData } from "../../../../../helpers/normalize"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"

import buyerInviteSearch from "./BuyersInvites.search"
import InviteClientsView from "./InviteClientsView"

type Props = {
  salesSessionId: string,
  onClickPrev: Function,
  readOnly: boolean,
  onClickDeleteSession: Function,
  onClickActivateSession: Function,
  isSessionActive: boolean,
  restrictToStaffId: string,
  market: "men" | "women" | "womenMen",
}

const canBuyerBeSelected = (buyer, session, salesSessionBookings) => {
  const { invitation } = buyer

  const alreadyBooked =
    (invitation && (invitation.status === "booked" || invitation.status === "joker")) ||
    salesSessionBookings.some(
      bookings => session.status === "active" && bookings.clients.some(client => client.id === buyer.id),
    )
  const isBuyerBookable = session.rooms.some(
    room =>
      !room.hasBlockingList ||
      (room.hasBlockingList &&
        room.typeBlockingList === "whitelist" &&
        room.retailersIds.includes(buyer.retailer.id)) ||
      (room.hasBlockingList && room.typeBlockingList === "blacklist" && !room.retailersIds.includes(buyer.retailer.id)),
  )

  if (alreadyBooked) {
    return {
      isBookable: false,
      reason: "This buyer already has a booking.",
    }
  }

  if (!alreadyBooked && isBuyerBookable) {
    return { isBookable: true }
  }

  if (!isBuyerBookable) {
    return {
      isBookable: false,
      reason:
        "This buyer belongs to a retailer that is currently not allowed to book any room. Please check blocking list settings on step 3. Rooms.",
    }
  }
  return !alreadyBooked && { isBookable: true }
}

const InviteClients = ({
  salesSessionId,
  brandId,
  onClickPrev,
  onClickActivateSession,
  onClickDeleteSession,
  readOnly,
  isSessionActive,
  restrictToStaffId,
  market,
  session,
}: Props) => {
  const [filteringOptions, setFilteringOptions] = useState([])
  const [selectedBuyers, setSelectedBuyers] = useState({})
  const [selectedBuyersToInvite, setSelectedBuyersToInvite] = useState({})
  const [selectedBuyersToFollowUp, setSelectedBuyersToFollowUp] = useState({})

  const handleChangeFilter = useCallback(newFilterOptions => setFilteringOptions(newFilterOptions), [])
  const handleToggleSelectAll = useCallback((checked, buyers) => {
    const newSelectedBuyers = {}
    const newSelectedBuyersToInvite = {}
    const newSelectedBuyersToFollowUp = {}
    if (checked) {
      buyers.forEach(buyer => {
        const { invitation, isBookableToSession } = buyer
        if (!isBookableToSession.isBookable) return false
        if (invitation) {
          newSelectedBuyersToFollowUp[buyer.id] = true
        } else {
          newSelectedBuyersToInvite[buyer.id] = true
        }
        newSelectedBuyers[buyer.id] = true
      })
    }
    setSelectedBuyers(newSelectedBuyers)
    setSelectedBuyersToInvite(newSelectedBuyersToInvite)
    setSelectedBuyersToFollowUp(newSelectedBuyersToFollowUp)
  }, [])
  const handleClickRowBuyer = useCallback((buyer, isSelected) => {
    const toggleSelection = !isSelected
    if (toggleSelection) {
      const { invitation, isBookableToSession } = buyer
      if (!isBookableToSession.isBookable) return false
      if (invitation && invitation.status !== "cancelled") {
        setSelectedBuyersToFollowUp(prevFollowUps => ({
          ...prevFollowUps,
          [buyer.id]: true,
        }))
      } else {
        setSelectedBuyersToInvite(prevInvites => ({
          ...prevInvites,
          [buyer.id]: true,
        }))
      }
    } else {
      setSelectedBuyersToInvite(prevInvites => {
        const { [buyer.id]: deletedInvite, ...invitesWithoutBuyer } = prevInvites
        return invitesWithoutBuyer
      })
      setSelectedBuyersToFollowUp(prevFollowUps => {
        const { [buyer.id]: deletedFollowUp, ...followUpsWithoutBuyer } = prevFollowUps
        return followUpsWithoutBuyer
      })
    }
    setSelectedBuyers(prevSelectedBuyers => ({
      ...prevSelectedBuyers,
      [buyer.id]: toggleSelection,
    }))
  }, [])
  const handleSendInvites = useCallback(() => {
    const selectedBuyersWithoutInvited = Object.keys(selectedBuyersToInvite).reduce((selecteds, buyerId) => {
      selecteds[buyerId] = false
      return selecteds
    }, selectedBuyers)
    setSelectedBuyers(selectedBuyersWithoutInvited)
    setSelectedBuyersToInvite({})
  }, [selectedBuyers, selectedBuyersToInvite])
  const handleSendFollowUps = useCallback(() => {
    const selectedBuyersWithoutFollowUps = Object.keys(selectedBuyersToFollowUp).reduce((selecteds, buyerId) => {
      selecteds[buyerId] = false
      return selecteds
    }, selectedBuyers)
    setSelectedBuyers(selectedBuyersWithoutFollowUps)
    setSelectedBuyersToFollowUp({})
  }, [selectedBuyers, selectedBuyersToFollowUp])

  const { loading, error, data } = useQuery(SALES_SESSION_INVITED_BUYERS, {
    variables: {
      salesSessionId,
      brandId,
      restrictToStaffId: restrictToStaffId || undefined,
      market,
    },
  })
  const invitedBuyersByBuyerId = useMemo(() => {
    if (!data?.SalesSession) return {}
    const { byId } = normalizeData(data.SalesSession.invitedBuyers, "buyerId")
    return byId
  }, [data])
  const salesSessionBookings = useMemo(() => {
    if (!data?.getSalesSessionBookings) return []
    const sessionBookings = data.getSalesSessionBookings
    return sessionBookings
  }, [data])
  const buyers = useMemo(() => {
    if (!data?.Brand) return []
    return data.Brand.buyers
      .map(buyer => {
        const buyerWithInvitation = {
          ...buyer,
          invitation: invitedBuyersByBuyerId[buyer.id],
        }
        buyerWithInvitation.isBookableToSession = canBuyerBeSelected(buyerWithInvitation, session, salesSessionBookings)
        return buyerWithInvitation
      })
      .sort((a, b) => (a.retailer.name > b.retailer.name ? 1 : -1))
  }, [data, invitedBuyersByBuyerId, session, salesSessionBookings])
  const filteredBuyers = useMemo(() => {
    if (!buyers) return []
    let newFilteredBuyers = [...buyers]
    filteringOptions.forEach(opt => {
      newFilteredBuyers = buyerInviteSearch[opt.key].filter(newFilteredBuyers, opt.value)
    })
    return newFilteredBuyers
  }, [buyers, filteringOptions])

  if (loading && !data) {
    return <Loading />
  }
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading invited buyers</ErrorLiveChatHelp>
  return (
    <InviteClientsView
      salesSessionId={salesSessionId}
      readOnly={readOnly}
      isSessionActive={isSessionActive}
      buyers={buyers}
      filteredBuyers={filteredBuyers}
      selectedBuyers={selectedBuyers}
      selectedBuyersToInvite={selectedBuyersToInvite}
      selectedBuyersToFollowUp={selectedBuyersToFollowUp}
      salesSessionBookings={salesSessionBookings}
      onClickRowBuyer={handleClickRowBuyer}
      onClickPrev={onClickPrev}
      onClickActivateSession={onClickActivateSession}
      onClickDeleteSession={onClickDeleteSession}
      onToggleSelectAll={handleToggleSelectAll}
      onChangeFilter={handleChangeFilter}
      onSendInvites={handleSendInvites}
      onSendFollowUps={handleSendFollowUps}
    />
  )
}

export default InviteClients

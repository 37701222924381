import React, { memo } from "react"
import { ModalConfirmation } from "modaresa-commons"

type Props = {
  onClose: Function,
}

const ModalWarningIdsDeleted = ({ onClose }: Props) => (
  <ModalConfirmation isOpen title="" onClose={onClose} hideCancel>
    <p>
      <strong>NB!</strong> If you have an active sales session ongoing, the deleted virtual meeting ID will only affect
      future sales sessions. The ongoing active sales session will pursue with the initial deleted ID.
    </p>
    <p>However, updates of IDs will be taken into account for ongoing sales sessions, and effective immediately.</p>
  </ModalConfirmation>
)

export default memo(ModalWarningIdsDeleted)

import React, { useState, memo, useCallback } from "react"
import debounce from "lodash/debounce"

import { removeKeysAlreadySearched, uniquifyAndCountElements } from "../../../helpers/filter"
import { Filter } from "../../ui/Filter"

type Props = {
  data: [],
  dataKeysToSearch: [string],
  onChange: Function,
  placeholder: string,
  searchObj: {
    any: {
      match: Function,
    },
  },
}

const DataFilter = ({ data, onChange, dataKeysToSearch, searchObj, placeholder }: Props) => {
  const [tags, setTags] = useState([])
  const [options, setOptions] = useState([])
  const isSearchable = !(tags.length >= 3)
  const handleAutocomplete = debounce(
    useCallback(
      value => {
        let newOptions = []
        const searchKeys = removeKeysAlreadySearched(dataKeysToSearch, tags)
        data.forEach(element => {
          searchKeys.forEach(searchKey => {
            const matchedOptions = searchObj[searchKey].match(element[searchKey], value)
            if (matchedOptions) {
              newOptions = newOptions.concat(matchedOptions)
            }
          })
        })
        setOptions(uniquifyAndCountElements(newOptions))
      },
      [data, dataKeysToSearch, searchObj, tags],
    ),
    200,
    {
      leading: false,
      trailing: true,
    },
  )
  const handleChange = useCallback(
    newTags => {
      const newTagsValue = newTags || []
      setTags(newTagsValue)
      onChange(newTagsValue)
    },
    [onChange],
  )
  return (
    <Filter
      placeholder={placeholder}
      className={"filterSelect"}
      isMulti
      isSearchable={isSearchable}
      options={options}
      value={tags}
      onChange={handleChange}
      onFilter={handleAutocomplete}
    />
  )
}

export default memo(DataFilter)

import * as R from "ramda"
import { createSelector } from "reselect"

import { getBrand } from "./brand"
import { getUserData } from "./user"

export const getRetailers = R.path(["retailers", "retailers"])
export const getRetailersRequestPending = R.path(["retailers", "retailersRequestPending"])
export const getSelectedRetailer = R.path(["retailers", "selected"])
export const getSharedRetailers = R.path(["retailers", "sharedRetailers"])
export const getSharedRetailerRequestStatus = R.path(["retailers", "requestSharedRetailersPending"])
export const getRetailerRequestStatus = R.path(["retailers", "requestPending"])
export const getRetailerRequestError = R.path(["retailers", "requestError"])

export const getAvailableRetailers = createSelector([getUserData, getRetailers, getBrand], (user, retailers, brand) => {
  const { modaresaRole, geographicResponsibility } = user
  if (!retailers.data) {
    return []
  }
  if (modaresaRole === "agent") {
    const { areas } = brand
    const area = areas.find(area => area._id === geographicResponsibility.areas[0])
    if (area) {
      const { countries } = area
      const filteredRetailer = retailers.allIds.filter(retailerId => {
        const retailer = retailers.byId[retailerId]
        return countries.includes(retailer.storeAddress.country)
      })
      return filteredRetailer
    }
  } else {
    return [...retailers.allIds]
  }
})

export const getCities = R.path(["retailers", "cities"])
export const getCitiesRequestStatus = R.path(["retailers", "requestCitiesPending"])
export const getCitiesOptions = createSelector([getCities, getRetailers, getBrand], cities =>
  cities.map(city => ({
    value: city,
    label: city,
  })),
)

import React, { memo } from "react"
import { Divider, SkeletonBlock, Tabs, TabPane } from "modaresa-commons"

import { Container, SkeletonContainerTabContent, SkeletonContainerHeader } from "./StaffProfile.styles"

type Props = {
  isLoggedUserFreelancer: boolean,
  activeSection: string,
  showNotificationsTab: boolean,
  onChangeTab: Function,
}

const SkeletonTabContent = memo(() => (
  <SkeletonContainerTabContent>
    <SkeletonBlock height="38px" width="680px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" width="680px" margin="0 0 16px 0" />
    <Divider />
    <SkeletonBlock height="38px" width="340px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" width="680px" margin="0 0 16px 0" />
    <Divider />
    <SkeletonBlock height="38px" width="340px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" width="340px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" width="680px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" width="680px" margin="0 0 16px 0" />
    <SkeletonBlock height="38px" width="340px" margin="0 0 16px 340px" />
  </SkeletonContainerTabContent>
))

const StaffProfileSkeleton = ({ isLoggedUserFreelancer, activeSection, showNotificationsTab, onChangeTab }: Props) => (
  <Container>
    <SkeletonContainerHeader>
      <SkeletonBlock height="27px" width="400px" />
    </SkeletonContainerHeader>
    <Tabs activeKey={activeSection} onChange={onChangeTab}>
      {!isLoggedUserFreelancer && [
        <TabPane key="1" tabKey="general" tab="General">
          <SkeletonTabContent />
        </TabPane>,
        <TabPane key="2" tabKey="buyer-responsibility" tab="Buyer Responsibility">
          <SkeletonTabContent />
        </TabPane>,
        showNotificationsTab && (
          <TabPane key="3" tabKey="notifications" tab="Notifications">
            <SkeletonTabContent />
          </TabPane>
        ),
      ]}
      <TabPane tabKey="availabilities" tab="Availabilities">
        <SkeletonTabContent />
      </TabPane>
      <TabPane tabKey="video-ids" tab="Virtual Meeting IDs">
        <SkeletonTabContent />
      </TabPane>
    </Tabs>
  </Container>
)

export default memo(StaffProfileSkeleton)

import React, { Component } from "react"
import PropTypes from "prop-types"

import { priorityGroupList, statusList } from "../../../../../../config/session"
import { storeTypeOptions } from "../../../../../../config/retailers"

import ModalRetailerCreated from "./ModalRetailerCreated"
import RetailerInformationForm from "./RetailerInformationForm"

class RetailerInformation extends Component {
  constructor(props) {
    super(props)
    const { brand, selectedRetailer } = this.props

    const competitorGroup =
      selectedRetailer && brand.competitorGroups.find(group => group.retailers.includes(selectedRetailer._id))

    this.state = {
      values: {
        retailerId: selectedRetailer?._id,
        name: (selectedRetailer && selectedRetailer.name) || "",
        type: (selectedRetailer?.type && storeTypeOptions.find(opt => opt.value === selectedRetailer.type)) || null,
        storeAddress: (selectedRetailer && {
          formattedAddress: selectedRetailer.storeAddress.formattedAddress,
          city: selectedRetailer.storeAddress.city,
          country: selectedRetailer.storeAddress.country,
        }) || { formattedAddress: "", city: "", country: "" },
        phoneNumber: (selectedRetailer && selectedRetailer.phoneNumber) || "",
        competitorGroup,
        clientId: (selectedRetailer && selectedRetailer.clientId) || "",
        status: (selectedRetailer?.status && statusList.find(opt => opt.value === selectedRetailer.status)) || null,
        priorityGroup:
          (selectedRetailer?.priorityGroup &&
            priorityGroupList.find(priorityGroup => priorityGroup.value === selectedRetailer.priorityGroup)) ||
          null,
        website: (selectedRetailer && selectedRetailer.website) || "",
      },
      errors: {},
      touched: {},
      isChanged: false,
      savingRequest: false,
      modalIsOpen: false,
      heuristicSearch: [],
    }
  }

  componentDidUpdate() {
    const { savingRequest } = this.state
    const { requestPending, requestError, isNew } = this.props
    if (!isNew && savingRequest && !requestPending && !requestError) {
      this.props.history.push("/clients/retailers")
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { savingRequest } = prevState
    const { isNew, requestPending, requestError } = nextProps

    if (isNew && savingRequest && !requestPending && !requestError) {
      return { savingRequest: false, modalIsOpen: true }
    }
    return null
  }

  submitForm = values => {
    const { selectedRetailer, brand, addRetailer, updateRetailer, isNew } = this.props

    const retailerData = {
      phoneNumber: values.phoneNumber === "+" ? " " : values.phoneNumber,
      name: values.name,
      type: values.type.value,
      storeAddress: values.storeAddress,
      clientId: values.clientId,
      status: values.status.value,
      priorityGroup: values.priorityGroup.value,
      website: values.website,
    }
    this.setState({ isChanged: false, savingRequest: true })
    if (isNew) {
      addRetailer({
        ...retailerData,
        brandId: brand._id,
      })
    } else {
      updateRetailer(
        {
          ...selectedRetailer,
          ...retailerData,
        },
        selectedRetailer._id,
      )
    }
  }

  render() {
    const { values, modalIsOpen } = this.state
    const { isNew, retailers, selectedRetailer, sharedRetailers } = this.props
    return (
      <div>
        <RetailerInformationForm
          isNew={isNew}
          brandRetailers={retailers.data}
          sharedRetailers={sharedRetailers}
          initialValues={values}
          onSubmit={this.submitForm}
        />
        {modalIsOpen && <ModalRetailerCreated retailer={selectedRetailer} />}
      </div>
    )
  }
}

RetailerInformation.propTypes = {
  addRetailer: PropTypes.func,
  updateRetailer: PropTypes.func,
  brand: PropTypes.object,
  history: PropTypes.object,
  isNew: PropTypes.bool,
  requestError: PropTypes.any,
  requestPending: PropTypes.bool,
  retailers: PropTypes.object,
  selectedRetailer: PropTypes.object,
}

export default RetailerInformation

import { useQuery } from "@apollo/client"
import styled from "styled-components"
import React, { memo } from "react"

import { ALL_RELOU_REQUESTS } from "../../../../../graphql/queries/relouRequest"
import ErrorLiveChatHelp from "../../../../ui/ErrorLiveChatHelp"

import RelouRequest from "./RelouRequest"
import RelouRequestSkeleton from "./RelouRequestSkeleton"

const ContainerList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const Placeholder = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.gray200};
  font-size: 18px;
`

type Props = {
  brandId: string,
  salesSessionId: string,
  statuses: [string],
  placeholderEmpty?: string,
}

const RelouRequestsList = ({ brandId, salesSessionId, statuses, placeholderEmpty }: Props) => {
  const { loading, error, data } = useQuery(ALL_RELOU_REQUESTS, {
    variables: {
      brandId,
      sessionId: salesSessionId,
      statuses,
    },
  })

  if (loading && !data)
    return (
      <ContainerList>
        {[...Array(5)].map((_, nb) => (
          <RelouRequestSkeleton key={nb} />
        ))}
      </ContainerList>
    )
  if (error) return <ErrorLiveChatHelp technicalError={error}>Error while loading relou requests</ErrorLiveChatHelp>

  const { allRelouRequests } = data
  return (
    <ContainerList>
      {allRelouRequests.length ? (
        allRelouRequests.map(request => <RelouRequest key={request.id} relouRequest={request} />)
      ) : (
        <Placeholder>{placeholderEmpty}</Placeholder>
      )}
    </ContainerList>
  )
}

RelouRequestsList.defaultProps = {
  placeholderEmpty: "No relou requests",
}
export default memo(RelouRequestsList)

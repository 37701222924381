import generateTerritoriesValuesFromOptions from "./generateTerritoriesValuesFromOptions"

export default async areasAndTerritoriesOpts => {
  const areasAndTerritories = {
    areasIds: [],
    roomAreaIds: [],
    territories: {},
  }
  const territoriesOpts = []
  areasAndTerritoriesOpts.forEach(item => {
    switch (item.type) {
      case "area":
        areasAndTerritories.areasIds.push(item.value)
        break
      case "roomArea":
        areasAndTerritories.roomAreaIds.push(item.objValue)
        break
      default:
        territoriesOpts.push(item)
        break
    }
  })
  const generatedTerritories = await generateTerritoriesValuesFromOptions(territoriesOpts)
  generatedTerritories.retailers = generatedTerritories.retailers.map(retailer => retailer.id)
  areasAndTerritories.territories = generatedTerritories
  return areasAndTerritories
}

import React, { memo } from "react"
import { HelpTooltip } from "modaresa-commons"
import { MeetingConfirmationIcon, MeetingReminderIcon } from "modaresa-commons/dist/components/icons"

import { TerritoriesDefinition, ThIcon } from "../Areas.styles"

const TableAreasHeaders = () => (
  <thead>
    <tr>
      <th>Area name</th>
      <th>
        <span>
          Territories{" "}
          <TerritoriesDefinition>
            <HelpTooltip
              text={`Defined by continents, countries, regions, counties, cities and/or retailers
            `}
            />
          </TerritoriesDefinition>
        </span>
      </th>
      <th>
        <span>
          Responsible <HelpTooltip text={<span>Main owner of the area (Area Manager).</span>} />
        </span>
      </th>
      <th>
        <span>
          Signee{" "}
          <HelpTooltip
            text={
              <span>
                A Staff who will be signing the invitation emails and receive potential replies from all the buyers
                linked to the area.
              </span>
            }
          />
        </span>
      </th>
      <th>Notified Staff(s)</th>
      <ThIcon>
        <MeetingConfirmationIcon />
      </ThIcon>
      <ThIcon>
        <MeetingReminderIcon />
      </ThIcon>
      <th />
    </tr>
  </thead>
)

export default memo(TableAreasHeaders)

import React, { memo } from "react"
import { B, Ul } from "modaresa-commons"

import StaffAssignmentsList from "./StaffAssignmentsList"

type Props = {
  staff: {
    _id: string,
    firstname: string,
    lastname: string,
  },
  assignments: {
    settingsAreas: [],
    settingsRemainingArea: {},
    activeSessions: [],
  },
}

const StaffCurrentAssignments = ({
  staff: { id, firstName, lastName },
  assignments: { settingsAreas, settingsRemainingArea, settingsRooms, activeSessions },
}: Props) => (
  <div>
    <p>
      OOPS!{" "}
      <B>
        {firstName} {lastName}
      </B>{" "}
      is currently assigned:
    </p>
    {(settingsAreas.length > 0 || settingsRemainingArea || settingsRooms.length > 0) && (
      <div>
        <div>In Settings > company profile > company structure:</div>
        <StaffAssignmentsList
          staffId={id}
          areas={settingsAreas}
          remainingArea={settingsRemainingArea}
          rooms={settingsRooms}
        />
      </div>
    )}
    {activeSessions.length > 0 && (
      <div>
        <div>In active sessions:</div>
        <Ul>
          {activeSessions.map(session => (
            <li key={session.id}>
              {session.name}
              <StaffAssignmentsList
                staffId={id}
                areas={session.areas}
                remainingArea={session.remainingArea}
                rooms={session.rooms}
              />
            </li>
          ))}
        </Ul>
      </div>
    )}
    <p>Are you sure you want to proceed with the deletion ?</p>
  </div>
)

export default memo(StaffCurrentAssignments)

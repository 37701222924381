export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

type Props = {
  array: [],
  compareKey: string,
  query: string,
}
export const searchByStringValueInArray = ({ array, query, compareKey }: Props) => {
  const queryValueSplitted = query.trim().split("")
  if (queryValueSplitted.length === 0) {
    return []
  }
  const foundValuesByFirstLetter = array.filter(item => {
    const itemValueSplitted = item[compareKey].split("")
    return (
      itemValueSplitted[0] &&
      queryValueSplitted[0] &&
      itemValueSplitted[0].toUpperCase() === queryValueSplitted[0].toUpperCase()
    )
  })

  return queryValueSplitted.reduce(
    (arrayToFilter, value, index) =>
      arrayToFilter.filter(item => {
        const splitShared = item[compareKey].split("")
        if (
          splitShared[index] &&
          queryValueSplitted[index] &&
          splitShared[index].toUpperCase() === queryValueSplitted[index].toUpperCase()
        ) {
          return splitShared
        } else if (
          (splitShared[index - 1] &&
            queryValueSplitted[index - 1] &&
            splitShared[index - 1].toUpperCase() === queryValueSplitted[index - 1].toUpperCase()) ||
          (splitShared[index - 2] &&
            queryValueSplitted[index - 2] &&
            splitShared[index - 2].toUpperCase() === queryValueSplitted[index - 2].toUpperCase())
        ) {
          return splitShared
        }
        return null
      }),
    foundValuesByFirstLetter,
  )
}

import styled from "styled-components"

export const DropdownMenuItem = styled.div`
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.gray75};
    text-decoration: none;
  }

  & a {
    color: ${props => props.theme.black};
  }
`
export const DropdownMenu = styled.div`
  background-color: #fff;
  min-width: 200px;
  text-decoration: none;
  ${({ border, theme }) =>
    border &&
    `border: 2px solid ${theme.veryLightGray};
  border-radius: 3px;

  & ${DropdownMenuItem}:not(:last-child) {
    border-bottom: 1px solid ${theme.veryLightGray}
  }`}
`

import { useQuery } from "@apollo/client"
import React, { useMemo, memo } from "react"
import { AlertDanger } from "modaresa-commons"

import { BRAND_ROOMS_AND_AREAS } from "../../../graphql/queries/brand"
import RoomAreaAndTerritoryPicker from "../RoomAreaAndTerritoryPicker/RoomAreaAndTerritoryPicker"

type Props = {
  brandId: string,
  multiple: boolean,
  placeholder: string,
  disabled: boolean,
  onChange: Function,
  defaultValue: any,
  value: any,
}

const BrandRoomAreaAndTerritoryPicker = ({
  brandId,
  multiple,
  placeholder,
  disabled,
  onChange,
  defaultValue,
  value,
}: Props) => {
  const { error, data } = useQuery(BRAND_ROOMS_AND_AREAS, {
    variables: { id: brandId },
  })
  const brandData = useMemo(() => {
    if (!data || !data.Brand) return {}
    const { toggleRoomBasedAreas, areas, rooms, retailers } = data.Brand
    return toggleRoomBasedAreas ? { rooms, retailers } : { areas, retailers }
  }, [data])
  if (error) return <AlertDanger>Error</AlertDanger>
  return (
    <RoomAreaAndTerritoryPicker
      multiple={multiple}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      rooms={brandData.rooms}
      areas={brandData.areas}
      retailers={brandData.retailers}
    />
  )
}

BrandRoomAreaAndTerritoryPicker.defaultProps = {
  placeholder: "Select area or territory",
  multiple: false,
}
export default memo(BrandRoomAreaAndTerritoryPicker)

import { connect } from "react-redux"

import { logout } from "../../../store/actions/auth"

import Logout from "./Logout"

const mapStateToProps = () => ({})
const actions = {
  onRESTLogout: logout,
}

export default connect(mapStateToProps, actions)(Logout)

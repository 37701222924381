import React, { memo } from "react"
import styled from "styled-components"
import { Button } from "modaresa-commons"

export const Footer = styled.div`
  min-height: 37px;
  padding: 10px 50px 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const FooterLeft = styled.div``
export const FooterRight = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 60px;
`

export const DeleteButton = styled(Button)`
  margin-right: 10px;
  background-color: ${props => props.theme.secondary};
  border-color: ${props => props.theme.secondary};
`

type Props = {
  onPrev: Function,
  onNext: Function,
  labelNext: string,
  hideNext: boolean,
  onDelete: Function,
  hideDelete: boolean,
}

const TabFooterButtons = ({ onPrev, onNext, labelNext, hideNext, onDelete, hideDelete }: Props) => (
  <Footer>
    <FooterLeft>
      <Button type="button" onClick={onPrev}>
        Previous
      </Button>
    </FooterLeft>
    <FooterRight>
      {!hideDelete && (
        <DeleteButton type="button" onClick={onDelete}>
          Delete Sales Session
        </DeleteButton>
      )}
      {!hideNext && (
        <Button type="button" onClick={onNext}>
          {labelNext}
        </Button>
      )}
    </FooterRight>
  </Footer>
)

TabFooterButtons.defaultProps = {
  labelNext: "Next",
}
export default memo(TabFooterButtons)

import { useMutation } from "@apollo/client"
import Raven from "raven-js"
import React, { useCallback, useState, memo } from "react"
import { PulseLoader } from "react-spinners"
import { Button } from "modaresa-commons"

import { RESTORE_BUYER } from "../../../../graphql/mutations/buyer"
import { BRAND_BUYERS } from "../../../../graphql/queries/brand"
import { onToastError, onToastSuccess } from "../../../../helpers/toast"

type Props = {
  brandId: string,
  buyer: {
    id: string,
  },
  onRestore: Function,
}
const ButtonRestoreBuyer = ({ buyer: { id }, brandId, onRestore }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [updateBuyerStatus] = useMutation(RESTORE_BUYER, {
    update: (cache, { data: { restoreBuyer: updatedBuyer } }) => {
      const cacheVariables = {
        id: brandId,
        status: "archived",
      }
      const dataBrand = cache.readQuery({
        query: BRAND_BUYERS,
        variables: cacheVariables,
      })
      if (dataBrand) {
        const { Brand } = dataBrand
        cache.writeQuery({
          query: BRAND_BUYERS,
          variables: cacheVariables,
          data: {
            Brand: {
              ...Brand,
              buyers: Brand.buyers.filter(buyer => buyer.id !== updatedBuyer.id),
            },
          },
        })
      }
    },
  })
  const handleClick = useCallback(
    async event => {
      event.stopPropagation()
      setIsLoading(true)
      try {
        await updateBuyerStatus({ variables: { id, status: "active" } })
        onToastSuccess("Buyer successfully restored.")
        if (onRestore) {
          onRestore()
        }
      } catch (error) {
        console.error(error)
        onToastError("Could not restore buyer.")
        Raven.captureException(error)
      }
      setIsLoading(false)
    },
    [id, updateBuyerStatus, onRestore],
  )

  return (
    <>
      {isLoading ? (
        <PulseLoader color={"#a60c46"} loading size={10} sizeUnit={"px"} />
      ) : (
        <Button bgcolor="#516CEF" borderColor="#516CEF" minWidth="0" onClick={handleClick}>
          Restore
        </Button>
      )}
    </>
  )
}

export default memo(ButtonRestoreBuyer)

import axios from "axios"

import { REST_API_URL } from "../../config"

export const createRetailer = async ({ data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(`${REST_API_URL}/retailers`, data, config)

  return response.data
}

export const updateRetailer = async ({ data, token, id }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(`${REST_API_URL}/retailers/${id}`, data, config)

  return response.data
}

export const fetchSharedRetailers = async ({ token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/sharedRetailers`, config)

  return response.data
}

export const fetchCities = async ({ token, countryCode }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      countryCode,
    },
  }
  const response = await axios.get(`${REST_API_URL}/cities`, config)

  return response.data
}

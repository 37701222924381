import { gql } from "@apollo/client"

export const ALL_USERS = gql`
  query GetAllUsers($filter: UserFilter, $skip: Int, $limit: Int) {
    allUsers(filter: $filter, skip: $skip, limit: $limit) {
      id
      firstName
      lastName
      email
      roles
      staff {
        firstName
        lastName
        modaresaRole
        companyPosition
        hasEditorRights
        emailVerified
        brand {
          id
          name
        }
      }
    }
    _allUsersCount(filter: $filter)
  }
`

export const ME_LOGGED_STAFF = gql`
  query {
    me {
      id
      staff {
        id
        fullName
        email
        modaresaRole
        hasEditorRights
        brand {
          id
          name
        }
      }
    }
  }
`

export const ME_LOGGED_STAFF_AVAILABLE_SESSIONS = gql`
  query MeLoggedStaffAvailableSessions($sessionStatus: SALES_SESSION_STATUS, $sessionIsPast: Boolean) {
    me {
      id
      staff {
        id
        availableSessions(status: $sessionStatus, isPast: $sessionIsPast) {
          id
          name
          season
          market
          address
          openingDay
          closingDay
          timezone
          office
          canBeEditedByLoggedUser
          attachments {
            fileName
            url
          }
        }
      }
    }
  }
`

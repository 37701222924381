import { FieldArray, useField, useFormikContext } from "formik"
import React, { memo } from "react"

import FormFieldLayout from "../../../../elements/FormFieldLayout/FormFieldLayout"

import RowOpeningDay from "./RowOpeningDay"

type Props = {
  disabled: boolean,
}

const maxOpeningHour = 22
const minClosingHour = 2
const FieldOpeningHours = ({ disabled }: Props) => {
  const { errors } = useFormikContext()
  const [field, meta] = useField("openingHours")
  return (
    <FormFieldLayout label=" " name="openingHours" labelWidth="208px" error={meta.touched && meta.error}>
      <FieldArray name="openingHours">
        {arrayHelpers =>
          field.value.map((day, idx) => {
            const error = errors[`openingHours.${idx}`]
            return (
              <RowOpeningDay
                key={idx}
                index={idx}
                day={day}
                error={error}
                disabled={disabled}
                arrayHelpers={arrayHelpers}
                minHour={minClosingHour}
                maxHour={maxOpeningHour}
              />
            )
          })
        }
      </FieldArray>
    </FormFieldLayout>
  )
}

export default memo(FieldOpeningHours)

export const JOIN_SOCKET_ROOM = "JOIN_SOCKET_ROOM"
export const LEAVE_SOCKET_ROOM = "LEAVE_SOCKET_ROOM"

export const UPDATED_CALENDAR = "UPDATED_CALENDAR"
export const BOOKING_CREATED = "BOOKING_CREATED"
export const RESERVE_BOOKING = "RESERVE_BOOKING"
export const BOOKING_RESERVED = "BOOKING_RESERVED"
export const CONFIRM_BOOKING = "CONFIRM_BOOKING"
export const BOOKING_CONFIRMED = "BOOKING_CONFIRMED"
export const DELETE_BOOKING = "DELETE_BOOKING"
export const BOOKING_DELETED = "BOOKING_DELETED"
export const UPDATE_BOOKING = "UPDATE_BOOKING"
export const BOOKING_UPDATED = "BOOKING_UPDATED"
export const UPDATE_BOOKING_TIME = "UPDATE_BOOKING_TIME"
export const BOOKING_TIME_UPDATED = "BOOKING_TIME_UPDATED"
export const UPDATE_CONFIRMED_EVENT_TIME = "UPDATE_CONFIRMED_EVENT_TIME"
export const CONFIRMED_EVENT_TIME_UPDATE = "CONFIRMED_EVENT_TIME_UPDATE"
export const REPLACE_EVENT = "REPLACE_EVENT"

export const UPDATE_CALENDAR_BOOKING = "UPDATE_CALENDAR_BOOKING"
export const UPDATE_CLIENT_BOOKING = "UPDATE_CLIENT_BOOKING"

export const UPDATED_EVENTS = "UPDATED_EVENTS"

// Dashboard
export const REALLOCATE = "REALLOCATE"
export const HOST_REALLOCATED = "HOST_REALLOCATED"
export const CANCEL_MEETING = "CANCEL_MEETING"
export const MEETING_CANCELLED = "MEETING_CANCELLED"
export const SET_ARRIVED_TO_APPOINTMENT = "SET_ARRIVED_TO_APPOINTMENT"
export const ARRIVED_TO_APPOINTMENT = "ARRIVED_TO_APPOINTMENT"
export const DELAY_APPOINTMENT = "DELAY_APPOINTMENT"
export const APPOINTMENT_DELAYED = "APPOINTMENT_DELAYED"

export const APPOINTMENTS_DELAYED = "APPOINTMENTS_DELAYED"

export const API_ERROR = "API_ERROR"

export const CHECKOUT_HOST = "CHECKOUT_HOST"
export const HOST_CHECKED_OUT = "HOST_CHECKED_OUT"
export const CHECKIN_HOST = "CHECKIN_HOST"
export const HOST_CHECKED_IN = "HOST_CHECKED_IN"

// Buyer
export const CREATE_BUYER = "CREATE_BUYER"
export const BUYER_CREATED = "BUYER_CREATED"
export const UPDATE_BUYER = "UPDATE_BUYER"
export const BUYER_UPDATED = "BUYER_UPDATED"
export const DELETE_BUYER = "DELETE_BUYER"
export const BUYER_DELETED = "BUYER_DELETED"

// Retailer
export const CREATE_RETAILER = "CREATE_RETAILER"
export const RETAILER_CREATED = "RETAILER_CREATED"
export const UPDATE_RETAILER = "UPDATE_RETAILER"
export const DELETE_RETAILER = "DELETE_RETAILER"
export const RETAILER_DELETED = "RETAILER_DELETED"
export const RETAILER_UPDATED = "RETAILER_UPDATED"
export const CREATE_RETAILER_BY_SHARED_RETAILER = "CREATE_RETAILER_BY_SHARED_RETAILER"
export const RETAILER_CREATED_BY_SHARED_RETAILER = "RETAILER_CREATED_BY_SHARED_RETAILER"
